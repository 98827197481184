// import logo from '../../assets/img/XOFIALogo.png';

export const selectClientName = () => {
  const client = window.location.hostname;
  if (client.includes('localhost')) {
    return 'local';
  }
  if (client.includes('give')) {
    return 'give';
  }

  return 'give';
};

const titles = {
  give: 'Bonus',
  local: 'Bonus',
};

const favicons = {
  give: '/favicon/give/favicon.ico',
  local: '/favicon/give/favicon.ico',
};

const brands = {
  give: 'Bonus',
  local: 'Bonus',
};

const descriptions = {
  give: 'Bonus',
  giveqa: 'Bonus QA',
  local: 'Bonus, Develop',
};

export const setTitleIcon = () => {
  const client = selectClientName();
  const title = client ? titles[client] : titles.give;
  const favico = client ? favicons[client] : favicons.give;
  const brand = client ? brands[client] : brands.give;
  const description = client ? descriptions[client] : descriptions.give;
  const titleElement = document.querySelector('title') as any;
  titleElement.innerHTML = `${title}`;

  const favicon = document.querySelector("link[rel~='icon']") as any;
  favicon.href = favico;

  const brandExist = document.getElementById('brandExist');
  brandExist?.setAttribute('content', brand);

  const descriptionMeta = document.getElementById('descriptionMeta');
  descriptionMeta?.setAttribute('content', description);
};

// const xofiaPdfImg = {
//   logo,
// };

// export default xofiaPdfImg;
