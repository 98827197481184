import styled from 'styled-components';
import theme from '../../theme';

export const TitleComponent = styled.text<{ validate?: boolean }>`
  width: 100%;
  font-weight: bold;
  border-bottom: ${({ validate = true }) =>
    validate ? `1px solid ${theme.palette.primary.main}` : ''};
  margin-bottom: 5px;
  color: ${theme.palette.primary.main};
  font-size: 25px;
  width: 100%;
  text-align: left;
  font-family: Poppins-Regular;
`;
