import 'reflect-metadata';
import { container } from 'tsyringe';
import { RequestLogin } from '../../domain/security/model/request/requestLogin';
import { ResponseUser } from '../../domain/userPermissions/user/model/responseUser';
import { IRefreshApplication } from '../../domain/interface/application/security/IRefreshApplication';
import { IRefreshInfrastructure } from '../../domain/interface/infrastructure/security/IRefreshInfrastructure';

export class RefreshApplication implements IRefreshApplication {
  private RefreshInfrastructure: IRefreshInfrastructure;

  public constructor() {
    this.RefreshInfrastructure =
      container.resolve<IRefreshInfrastructure>('IRefreshInfrastructure');
  }

  public async handle(request: RequestLogin): Promise<ResponseUser> {
    const result = await this.RefreshInfrastructure.handleAPI(request);
    return result;
  }
}
