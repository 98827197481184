import { AxiosError } from 'axios';
import { ResponseUser } from '../../core/domain/userPermissions/user/model/responseUser';

export const actionsLogin = {
  LOGIN_PAGE_BEGIN: 'LOGIN_PAGE_BEGIN',
  LOGIN_PAGE_SUCCESS: 'LOGIN_PAGE_SUCCESS',
  LOGIN_PAGE_SUCCESS_DATA: 'LOGIN_PAGE_SUCCESS_DATA',
  LOGIN_PAGE_ERR: 'LOGIN_PAGE_ERR',

  loginPageBegin: (): { type: string } => {
    return {
      type: actionsLogin.LOGIN_PAGE_BEGIN,
    };
  },

  loginPageSuccess: (
    data: ResponseUser | undefined
  ): { type: string; data: ResponseUser | undefined } => {
    return {
      type: actionsLogin.LOGIN_PAGE_SUCCESS,
      data,
    };
  },
  loginPageSuccessData: (
    data: ResponseUser | undefined
  ): { type: string; data: ResponseUser | undefined } => {
    return {
      type: actionsLogin.LOGIN_PAGE_SUCCESS_DATA,
      data,
    };
  },

  loginPageErr: (err: AxiosError | undefined): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsLogin.LOGIN_PAGE_ERR,
      err,
    };
  },
};

export const actionsLogout = {
  LOGOUT_PAGE_BEGIN: 'LOGOUT_PAGE_BEGIN',
  LOGOUT_PAGE_SUCCESS: 'LOGOUT_PAGE_SUCCESS',
  LOGOUT_PAGE_ERR: 'LOGOUT_PAGE_ERR',

  logoutPageBegin: (): { type: string } => {
    return {
      type: actionsLogout.LOGOUT_PAGE_BEGIN,
    };
  },

  logoutPageSuccess: (
    data: ResponseUser | undefined
  ): { type: string; data: ResponseUser | undefined } => {
    return {
      type: actionsLogout.LOGOUT_PAGE_SUCCESS,
      data,
    };
  },

  logoutPageErr: (err: AxiosError | undefined): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsLogout.LOGOUT_PAGE_ERR,
      err,
    };
  },
};

export const actionsRefresh = {
  REFRESH_PAGE_BEGIN: 'REFRESH_PAGE_BEGIN',
  REFRESH_PAGE_SUCCESS: 'REFRESH_PAGE_SUCCESS',
  REFRESH_PAGE_ERR: 'REFRESH_PAGE_ERR',

  refreshPageBegin: (): { type: string } => {
    return {
      type: actionsRefresh.REFRESH_PAGE_BEGIN,
    };
  },

  refreshPageSuccess: (
    data: ResponseUser | undefined
  ): { type: string; data: ResponseUser | undefined } => {
    return {
      type: actionsRefresh.REFRESH_PAGE_SUCCESS,
      data,
    };
  },

  refreshPageErr: (err: AxiosError | undefined): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsRefresh.REFRESH_PAGE_ERR,
      err,
    };
  },
};
