import { AxiosResponse } from 'axios';
import { IBranchsOfficesInfrastructure } from '../../domain/interface/infrastructure/branchsOffices/IBranchsOfficesInfrastructure';
import { processDataBackend } from '../../../common/utils/functions';
import { ResponseBranchsOffices } from '../../domain/branchsOffices/model/branchsOfficesResponse';
import { RequestBranchsOffices } from '../../domain/branchsOffices/model/branchsOfficesRequest';

export class BranchsOfficesInfrastructure implements IBranchsOfficesInfrastructure {
  private a = '';

  public async getBranchsOfficesAPI(
    request: RequestBranchsOffices
  ): Promise<ResponseBranchsOffices[]> {
    this.a = 'aaa';
    const res: AxiosResponse = await processDataBackend(
      'GET',
      '/api/branchsOffices/getAll',
      {},
      { request }
    );
    const branchsOffices: ResponseBranchsOffices[] = res.data;
    return Promise.resolve(branchsOffices);
  }

  public async getBranchOfficeByIdAPI(
    request: RequestBranchsOffices
  ): Promise<ResponseBranchsOffices> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to getById not found');
    const res: AxiosResponse = await processDataBackend(
      'POST',
      '/api/branchsOffices/getById',
      {},
      { request }
    );
    const result: ResponseBranchsOffices = res.data;

    return Promise.resolve(result);
  }

  public async createBranchsOfficesAPI(
    request: RequestBranchsOffices[]
  ): Promise<ResponseBranchsOffices[]> {
    this.a = 'aaa';
    const res: AxiosResponse = await processDataBackend(
      'POST',
      '/api/branchsOffices/create',
      {},
      { request }
    );
    const BranchsOffices: ResponseBranchsOffices[] = res.data;
    return Promise.resolve(BranchsOffices);
  }

  public async updateBranchsOfficesAPI(
    request: RequestBranchsOffices
  ): Promise<ResponseBranchsOffices> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to update not found');
    const res: AxiosResponse = await processDataBackend(
      'PUT',
      '/api/branchsOffices/update',
      {},
      { request }
    );
    const result: ResponseBranchsOffices = res.data;
    return Promise.resolve(result);
  }

  public async deleteBranchsOfficesAPI(
    request: RequestBranchsOffices
  ): Promise<ResponseBranchsOffices> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to delete not found');
    const res: AxiosResponse = await processDataBackend(
      'PUT',
      '/api/branchsOffices/delete',
      {},
      { request }
    );
    const result: ResponseBranchsOffices = res.data;
    return Promise.resolve(result);
  }
}
