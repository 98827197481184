import styled from 'styled-components';
import { TStyle } from './types.d';

interface ButtonProps{
  backgroundColor?: string;
  hoverStyles?: string;
}

export const styles: TStyle = {
  container: {
    width: '100%',
    height: '100%',
  },
  logoContainer: {
    width:"100%",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: '#001f46',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f5f4f4',
    texAlign: 'center',
    minWidth: "400px",
    '@media (max-width: 750px)': {
      width:"100%",
      minWidth: "100%"
    }
  },
};

export const Logo = styled.img`
  height: 20%;
  width: 30%;
  max-height: 150px;
  min-height:50px;
  // @media (max-width: 997px) {
  //   max-width: 330px;
  // }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background-color: #f5f4f4;
  width: 75%;
  height: 75%;
  @media (max-height: 69%) {
    gap: 15px;
  }
`;

export const TextFloating = styled.span`
  position: fixed;
  bottom: 10px;
  z-index: 1600;
  right: 5px;
  width: 300px;
  color: #fff;
`;
export const TramaFloating = styled.img`
  position: fixed;
  bottom: 0px;
  z-index: 1600;
  left: 0px;
  width: 60%;
  opacity: 0.06;
`;
export const ButtonSubmit = styled.button<ButtonProps>`
  width: 148px;
  height: 35px;
  background-color: ${props => props.backgroundColor || '#243EBD'};
  &:hover {
    background-color: ${props => props.hoverStyles || '#062A57'};
  }
  justify-content: center;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;
export const ButtonPassword = styled.button`
  width: 200px;
  /* height: 25px; */
  background-color: transparent;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;
