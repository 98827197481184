import 'reflect-metadata';
import { container } from 'tsyringe';
import { ICountriesApplication } from '../../../domain/interface/application/addresses/ICountriesApplication';
import { ICountriesInfrastructure } from '../../../domain/interface/infrastructure/addresses/ICountriesInfrastructure';
import { RequestCountries } from '../../../domain/addresses/countries/model/requestCountries';
import { ResponseCountries } from '../../../domain/addresses/countries/model/responseCountries';

export class CountriesApplication implements ICountriesApplication {
  private countriesInfrastructure: ICountriesInfrastructure;

  public constructor() {
    this.countriesInfrastructure = container.resolve<ICountriesInfrastructure>(
      'ICountriesInfrastructure'
    );
  }

  public async getCountries(request: RequestCountries): Promise<ResponseCountries[]> {
    const result = await this.countriesInfrastructure.getCountriesAPI(request);
    return result;
  }
}
