import React from 'react';
import { SellersListC } from '../../../containers/sellers/sellerList';
import LayoutC from '../../../containers/Layout';

const SellersListP = () => {
  return (
    <LayoutC>
      <SellersListC />
    </LayoutC>
  );
};

export default SellersListP;
