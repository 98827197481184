import * as yup from 'yup';

const schema = yup
  .object({
    idDocumentType: yup.number().required('Campo requerido'),
    identificationNumber: yup
      .number()
      .required('Número de identificación es requerido')
      .typeError('Este campo sólo puede contener números y una longitud de 11 dígitos')
      .test(
        'is-between-8-and-11-digits',
        'Número de identificación debe tener entre 8 y 11 dígitos',
        (value) => {
          if (typeof value !== 'number') {
            return false;
          }
          const stringValue = String(Math.floor(value));
          const digitCount = stringValue.length;
          return digitCount >= 8 && digitCount <= 11;
        }
      ),
    idGender: yup.number().required('Campo requerido'),
    thirdPartyBeneficiary: yup.object({
      idThirdParty: yup.number().required('Campo requerido'),
    }),
    firstName: yup
      .string()
      .required('El primer nombre es requerido')
      .matches(/^[A-Za-zñáéíóúÁÉÍÓÚÑ\s]+$/, 'Sólo se permiten letras'),
    middleName: yup
      .string()
      .matches(/^[A-Za-zñáéíóúÁÉÍÓÚÑ\s]*$/, 'Sólo se permiten letras')
      .nonNullable()
      .optional(),
    firstSurname: yup
      .string()
      .required('El primer apellido es requerido')
      .matches(/^[A-Za-zñáéíóúÁÉÍÓÚÑ\s]+$/, 'Sólo se permiten letras'),
    secondSurname: yup.string().matches(/^[A-Za-zñáéíóúÁÉÍÓÚÑ\s]*$/, 'Sólo se permiten letras'),
    address: yup.object({
      neighBorhood: yup
        .string()
        .required('Campo requerido')
        .matches(/^[A-Za-zñáéíóúÁÉÍÓÚÑ\s\d]+$/u, 'Sólo se permiten letras y números'),
      idAddressSecondStreetType: yup.number().required('Campo requerido'),
      idAddressFirstStreetType: yup.number().required('Campo requerido'),
      secondStreetNumber: yup.string().required('Campo requerido'),
      thirdStreetNumber: yup.string().required('Campo requerido'),
      firstStreetNumber: yup.string().required('Campo requerido'),
      idDepartment: yup.number().required('Campo requerido'),
      description: yup.string().required('Campo requerido'),
      idCity: yup.number().required('Campo requerido'),
    }),
    cellPhone: yup
      .number()
      .required()
      .test('is-between-10-and-10-digits', 'El celular debe tener 10 dígitos', (value) => {
        if (typeof value !== 'number') {
          return false;
        }
        const stringValue = String(Math.floor(value));
        const digitCount = stringValue.length;
        return digitCount >= 10 && digitCount <= 10;
      })
      .typeError('Este campo sólo puede contener números'),
    email: yup.string().required('Correo requerido').email('El correo electrónico no es válido'),
    telephone: yup.string().optional(),
    password: yup
      .string()
      .required('La contraseña es requerida')
      .min(8, '8 caracteres')
      .test('has-uppercase', 'Una mayúscula', (value) => /[A-Z]/.test(value))
      .test('has-number', 'Un número', (value) => /(?=.*\d)/.test(value))
      .matches(/^(?=.*[@$!%*_.?&])/, 'Un carácter especial'),
  })
  .required();

export default schema;
