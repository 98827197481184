import 'reflect-metadata';
import { container } from 'tsyringe';
import { RequestBenefitValidity } from '../../../domain/benefits/benefitValidities/model/requestBenefitValidity';
import { ResponseBenefitValidity } from '../../../domain/benefits/benefitValidities/model/responseBenefitValidity';
import { IBenefitValidityApplication } from '../../../domain/interface/application/benefits/IBenefitValidityApplication';
import { IBenefitValidityInfrastructure } from '../../../domain/interface/infrastructure/benefits/IBenefitValidityInfrastructure';

export class BenefitValidityApplication implements IBenefitValidityApplication {
  private benefitValidityInfrastructure: IBenefitValidityInfrastructure;

  public constructor() {
    this.benefitValidityInfrastructure = container.resolve<IBenefitValidityInfrastructure>(
      'IBenefitValidityInfrastructure'
    );
  }

  public async createBenefitValidity(
    request: RequestBenefitValidity
  ): Promise<ResponseBenefitValidity> {
    const result = await this.benefitValidityInfrastructure.createBenefitValidityAPI(request);
    return result;
  }
}
