import { AxiosError } from 'axios';
import { ResponseKinships } from '../../../core/domain/contacts/kinships/model/responseKinships';
import { KinshipsState } from '../../../core/domain/contacts/kinships/state/kinshipsState';
import actions from './actions';
import { TKinshipsReducer } from './types';

const { KINSHIPS_LIST_PAGE_BEGIN, KINSHIPS_LIST_PAGE_SUCCESS, KINSHIPS_LIST_PAGE_ERR } = actions;

const initialKinships: KinshipsState = new KinshipsState();

const KinshipsReducer = (
  state = initialKinships,
  action: { type: string; err: AxiosError; data: ResponseKinships[] }
): TKinshipsReducer => {
  const { type, data, err } = action;
  switch (type) {
    case KINSHIPS_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case KINSHIPS_LIST_PAGE_SUCCESS:
      return {
        ...state,
        kinships: data,
        loading: false,
      };
    case KINSHIPS_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default KinshipsReducer;
