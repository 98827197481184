import React, { ReactNode } from 'react';
// import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import {
  // GridToolbarColumnsButton,
  GridToolbarContainer,
  // GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';

export type TToolbar = {
  children?: ReactNode;
};

export const ToolbarList: React.FC<TToolbar> = ({ children }) => {
  // const theme = useTheme();

  return (
    <GridToolbarContainer sx={{ width: '100%', display: 'flex', padding: '5px' }}>
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', pr: '10px' }}>
        <Box>
          {/* <GridToolbarColumnsButton sx={{ color: theme.palette.primary.main }} />
          <GridToolbarDensitySelector sx={{ color: theme.palette.primary.main }} /> */}
        </Box>
        <Box>{children}</Box>
        <div>
          <GridToolbarQuickFilter />
        </div>
      </Box>
    </GridToolbarContainer>
  );
};
