import { AxiosError } from 'axios';
import { ResponseCities } from '../../../core/domain/addresses/cities/model/responseCities';

const actions = {
  CITIES_LIST_PAGE_BEGIN: 'CITIES_LIST_PAGE_BEGIN',
  CITIES_LIST_PAGE_SUCCESS: 'CITIES_LIST_PAGE_SUCCESS',
  CITIES_LIST_PAGE_ERR: 'CITIES_LIST_PAGE_ERR',

  citiesListPageBegin: (): { type: string } => {
    return {
      type: actions.CITIES_LIST_PAGE_BEGIN,
    };
  },

  citiesListPageSuccess: (
    data: ResponseCities[]
  ): { type: string; data: ResponseCities[] | ResponseCities } => {
    return {
      type: actions.CITIES_LIST_PAGE_SUCCESS,
      data,
    };
  },

  citiesListPageErr: (err: AxiosError): { type: string; err: AxiosError } => {
    return {
      type: actions.CITIES_LIST_PAGE_ERR,
      err,
    };
  },
};

export default actions;
