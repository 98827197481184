import { ResponseEconomicActivities } from '../model/responseEconomicActivities';

export class EconomicActivitiesState {
  public constructor() {
    this.economicActivities = [new ResponseEconomicActivities()];
  }

  public name = '';

  public economicActivities: ResponseEconomicActivities[];
}
