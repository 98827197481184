import React from 'react';
import { ProvidersRegisterC } from '../../../containers/providers';
import LayoutC from '../../../containers/Layout';

const ProvidersRegisterP = () => {
  return (
    <LayoutC>
      <ProvidersRegisterC />
    </LayoutC>
  );
};

export default ProvidersRegisterP;
