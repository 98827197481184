import * as XLSX from 'xlsx';
// import * as XLSX from 'xlsx-js-style';
import moment from 'moment';

export const exportToExcel = (data: any, filename: string) => {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(data);

  // 1. Calcular anchos de columna en base a los datos
  const columnWidths: { [key: string]: number } = {};
  data.forEach((row: any) => {
    Object.keys(row).forEach((cell) => {
      const cellData = row[cell];
      const cellLength = cellData ? String(cellData).length : 0;
      const columnWidth = columnWidths[cell] || 0;
      if (cellLength > columnWidth) {
        columnWidths[cell] = cellLength;
      }
    });
  });

  if (worksheet) {
    // const adjustmentFactor = 1.2;
    worksheet['!cols'] = Object.keys(columnWidths).map((column) => ({
      wch: 30,
    }));
  }

  // Establecer un ancho de columna fijo (puedes ajustar este valor)
  // const defaultColumnWidth = 15;

  // // Obtener las claves de las columnas
  // const columns = Object.keys(data[0] || {});

  // // Establecer un ancho predeterminado para todas las columnas
  // const columnWidths = columns.map(() => ({ width: defaultColumnWidth }));

  // // Aplicar anchos de columna al objeto de hoja de cálculo
  // worksheet['!cols'] = columnWidths;

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  const url = URL.createObjectURL(blob);

  const currentDate = `${moment().format('D-MM-YYYY-hh:mm:ss')}`;

  const a = document.createElement('a');
  a.href = url;
  a.download = `${filename}_${currentDate}.xlsx`;
  a.click();

  URL.revokeObjectURL(url);
};

export const exportToExcelToVertical = (data: any, filename: string) => {
  // Crear un objeto para almacenar los datos transpuestos
  const transposedData: any = {};

  // Recorrer cada objeto en los datos
  data.forEach((row: any, index: number) => {
    // Recorrer cada clave en el objeto
    Object.keys(row).forEach((key) => {
      // Si el encabezado no existe en los datos transpuestos, crear un nuevo array
      if (!transposedData[key]) {
        transposedData[key] = [];
      }
      // Añadir el valor al array correspondiente en los datos transpuestos
      transposedData[key][index] = row[key];
    });
  });

  // Convertir los datos transpuestos a un array de arrays para XLSX
  const outputData = Object.keys(transposedData).map((key) => [key, ...transposedData[key]]);

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(outputData);

  // Establecer el ancho de todas las columnas a 245 píxeles (aproximadamente 35 caracteres)
  worksheet['!cols'] = outputData[0].map(() => ({ wch: 30 }));

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  const url = URL.createObjectURL(blob);

  const currentDate = `${moment().format('D-MM-YYYY-hh:mm:ss')}`;

  const a = document.createElement('a');
  a.href = url;
  a.download = `${filename}_${currentDate}.xlsx`;
  a.click();

  URL.revokeObjectURL(url);
};
