import React from 'react';
import { Box, CircularProgress, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { DataGrid, GridColDef, GridColumnHeaderParams, esES } from '@mui/x-data-grid';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Link } from 'react-router-dom';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { ToolbarList } from '../../../../../common/components/toolbar';
import { IUserList } from '../../../containers/user/userList/types.d';
import { TransitionsModalAlert } from '../../../../../common/components/modalAlert';
import { numberFormat } from '../../../../../common/utils/functions';
import { TitleComponent } from '../../../../../common/components/titles/styled';

const UserListV: React.FC<IUserList> = ({
  data,
  loading,
  handleDelete,
  openDelete,
  handleClose,
  buttonDelete,
  deleteLoading,
}) => {
  const theme = useTheme();
  const styles = {
    header: {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      fontSize: '30px',
      textAlign: 'left',
      fontFamily: 'Poppins-Regular',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    headerName: {
      fontWeight: 'bold',
      fontSize: '14px',
      color: theme.palette.primary.main,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  };
  const columns: GridColDef[] = [
    {
      field: 'identificationNumber',
      headerName: 'N° IDENTIFICACIÓN',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>N° IDENTIFICACIÓN</Typography>
      ),
      renderCell: ({ row }) => {
        return (
          <Box sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
            {numberFormat(row?.identificationNumber)}
          </Box>
        );
      },
    },
    {
      field: 'name',
      headerName: 'NOMBRES',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>NOMBRES</Typography>
      ),
      renderCell: ({ row }) => {
        return (
          <Box sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
            {row?.name?.toUpperCase()}
          </Box>
        );
      },
    },
    {
      field: 'lastName',
      headerName: 'APELLIDOS',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>APELLIDOS</Typography>
      ),
      renderCell: ({ row }) => {
        return (
          <Box sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
            {row?.lastName?.toUpperCase()}
          </Box>
        );
      },
    },
    {
      field: 'email',
      headerName: 'CORREO',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>CORREO</Typography>
      ),
      renderCell: ({ row }) => {
        return (
          <Box sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
            {row?.email?.toUpperCase()}
          </Box>
        );
      },
    },
    {
      field: 'userType',
      headerName: 'TIPO DE USUARIO',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>TIPO DE USUARIO</Typography>
      ),
    },
    {
      field: 'actions',
      headerName: 'ACCIONES',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>ACCIONES</Typography>
      ),
      renderCell({ row }) {
        return (
          <>
            <Tooltip title="Editar información del usuario">
              <Link to={`/usuarios/editar/${row.idUser}`}>
                <IconButton>
                  <BorderColorIcon fontSize="small" color="primary" />
                </IconButton>
              </Link>
            </Tooltip>
            <Tooltip title="Eliminar usuario">
              <IconButton onClick={() => buttonDelete(row?.idUser)}>
                <HighlightOffIcon fontSize="small" color="secondary" />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];
  return (
    <Box
      style={{
        display: 'flex',
        width: '100%',
      }}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          background: '#fff',
          borderRadius: '16px',
          padding: '20px',
          boxShadow: '0px 3px 6px #00000029',
          minHeight: '400px',
        }}>
        <div style={{ width: '100%', height: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}>
            <TitleComponent>Lista de usuarios</TitleComponent>
          </Box>
          <div style={{ height: '85%' }}>
            <DataGrid
              rows={data || []}
              getRowId={(row) => row?.idUser}
              columns={columns}
              rowsPerPageOptions={[25, 50, 100]}
              density="compact"
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              components={{ Toolbar: ToolbarList }}
              loading={!data?.length && loading}
            />
          </div>
        </div>
      </Box>
      <Box>
        <TransitionsModalAlert
          state={openDelete}
          handleCloseModal={handleClose}
          width="25%"
          height="25%"
          title="Confirmar acción"
          subTitle="Antes de continuar!"
          paragraph="¿Estás seguro de realizar esta acción?"
          nameAccept={!deleteLoading ? 'SI' : <CircularProgress size={15} />}
          nameDecline="NO"
          onClickAccept={handleDelete}
        />
      </Box>
    </Box>
  );
};

export default UserListV;
