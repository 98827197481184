import 'reflect-metadata';
import { AxiosResponse } from 'axios';
import { IMaritalStatusInfrastructure } from '../../../domain/interface/infrastructure/documentsPersons/IMaritalInfrastructure';
import { RequestMaritalStatus } from '../../../domain/documentsPersons/maritalStatus/model/requestMaritalStatus';
import { ResponseMaritalStatus } from '../../../domain/documentsPersons/maritalStatus/model/responseMaritalStatus';
import { processDataBackend } from '../../../../common/utils/functions';

export class MaritalStatusInfrastructure implements IMaritalStatusInfrastructure {
  private a = '';

  public async getMaritalStatusAPI(
    request: RequestMaritalStatus
  ): Promise<ResponseMaritalStatus[]> {
    this.a = 'aaa';
    const res: AxiosResponse = await processDataBackend(
      'GET',
      '/api/maritalStatus/getAll',
      {},
      { request }
    );

    const maritalStatus: ResponseMaritalStatus[] = res.data;

    return Promise.resolve(maritalStatus);
  }
}
