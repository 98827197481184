import { AxiosResponse } from 'axios';
import { IBenefitCategoryInfrastructure } from '../../../domain/interface/infrastructure/benefits/IBenefitCategoryInfrastructure';
import { RequestBenefitCategory } from '../../../domain/benefits/benefitCategories/model/requestBenefitCategory';
import { ResponseBenefitCategory } from '../../../domain/benefits/benefitCategories/model/responseBenefitCategory';
import { processDataBackend } from '../../../../common/utils/functions';

export class BenefitCategoriesInfrastructure implements IBenefitCategoryInfrastructure {
  private a = '';

  public async getBenefitCategoryAPI(
    request: RequestBenefitCategory
  ): Promise<ResponseBenefitCategory[]> {
    this.a = 'aaa';
    const res: AxiosResponse = await processDataBackend(
      'GET',
      '/api/benefitCategory/getAll',
      {},
      { request }
    );
    const BenefitCategory: ResponseBenefitCategory[] = res.data;
    return Promise.resolve(BenefitCategory);
  }
}
