import 'reflect-metadata';
import { container } from 'tsyringe';
import { ResponseBenefit } from '../../../domain/benefits/benefits/model/responseBenefit';
import { RequestBenefit } from '../../../domain/benefits/benefits/model/requestBenefit';
import { IBenefitApplication } from '../../../domain/interface/application/benefits/IBenefitApplication';
import { IBenefitInfrastructure } from '../../../domain/interface/infrastructure/benefits/IBenefitInfrastructure';
import { TPagination } from '../../../../common/utils/types.d';

export class BenefitApplication implements IBenefitApplication {
  private benefitInfrastructure: IBenefitInfrastructure;

  public constructor() {
    this.benefitInfrastructure =
      container.resolve<IBenefitInfrastructure>('IBenefitInfrastructure');
  }

  public async getBenefits(request: RequestBenefit): Promise<ResponseBenefit[]> {
    const result = await this.benefitInfrastructure.getBenefitsAPI(request);
    return result;
  }

  public async getBenefitsForExcel(request: RequestBenefit): Promise<ResponseBenefit[]> {
    const result = await this.benefitInfrastructure.getBenefitsForExcelAPI(request);
    return result;
  }

  public async getBenefitsWithPagination(payload: TPagination): Promise<ResponseBenefit[]> {
    const result = await this.benefitInfrastructure.getBenefitsWithPaginationAPI(payload);
    return result;
  }

  public async getBenefitsByIdThirdParty(idThirdParty: number): Promise<ResponseBenefit[]> {
    const result = await this.benefitInfrastructure.getBenefitsbyIdThirdPartyAPI(idThirdParty);
    return result;
  }

  public async getBenefitsRandom(request: RequestBenefit): Promise<ResponseBenefit[]> {
    const result = await this.benefitInfrastructure.getBenefitsRandomAPI(request);
    return result;
  }

  public async getByIdBenefit(request: RequestBenefit): Promise<ResponseBenefit> {
    const result = await this.benefitInfrastructure.getByIdBenefitAPI(request);
    return result;
  }

  public async createBenefit(request: RequestBenefit): Promise<ResponseBenefit> {
    const result = await this.benefitInfrastructure.createBenefitAPI(request);
    return result;
  }

  public async updateBenefit(request: RequestBenefit): Promise<ResponseBenefit> {
    const result = await this.benefitInfrastructure.updateBenefitAPI(request);
    return result;
  }

  public async deleteBenefit(request: RequestBenefit): Promise<ResponseBenefit> {
    const result = await this.benefitInfrastructure.deleteBenefitAPI(request);
    return result;
  }

  public async getAllByMembership(request: RequestBenefit): Promise<ResponseBenefit[]> {
    const result = await this.benefitInfrastructure.getByMembershipAPI(request);
    return result;
  }

  public async getAllBenefitByBeneficiaries(request: RequestBenefit): Promise<ResponseBenefit[]> {
    const result = await this.benefitInfrastructure.getBenefitByBeneficiariesAPI(request);
    return result;
  }
}
