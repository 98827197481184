import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { LoginV } from '../../components/login/index';
import { IFormValues, TLoginDispatch, TUserData, TMessage } from './types';
import { RootState } from '../../../../redux/store';
import { LoginUser, clearLogin } from '../../../../redux/security/actionCreator';
import { TDispatch } from '../../../../common/utils/types.d';
import { Context } from '../../../../common/utils/userContext';
import { encryptData } from '../../../../common/utils/functions';

export const LoginC = () => {
  const [loadingLogin, setLoadingLogin] = useState(false);
  const navigate = useNavigate();
  // const [waitingAnswerLogin, setWaitingAnswerLogin] = useState(false);

  /**
   * Dispatch
   */
  const dispatch = useDispatch();

  /**
   * UserSession
   */
  // const { userSession } = useContext(Context);

  /**
   * schema para validar los campos que sean requeridos
   */
  const schema = yup
    .object({
      email: yup
        .string()
        .required('El correo es requerido.')
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Ingrese un correo válido.')
        .matches(/^\S*$/, 'El correo electrónico no debe contener espacios.'),
      password: yup.string().required('La contraseña es requerida.'),
    })
    .required();

  const { setLogged } = useContext(Context);

  /**
   * UseForm
   */
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  /**
   * función para ejecutar el método login user para que inicie sección el usuario
   */
  const login = async (data: IFormValues) => {
    const userRegister: TUserData = {
      email: data?.email,
      password: data?.password,
    };
    // setWaitingAnswerLogin(true);
    dispatch(clearLogin() as TDispatch);
    dispatch(LoginUser(userRegister) as TDispatch);
    setLoadingLogin(true);
  };

  /**
   * Dispatch de userLogin
   */
  // const userLoginDispatch: TUserLoginDispatch = useSelector(
  //   (dataState: RootState) => dataState.userLogin
  // );

  /**
   * Dispatch de Login
   */
  const loginDispatch: TLoginDispatch = useSelector((dataState: RootState) => dataState?.login);
  // useEffect para validar si el usuario existe e inicie sección
  useEffect(() => {
    if (loadingLogin) {
      if (loginDispatch?.login && !loginDispatch?.error) {
        localStorage.setItem('vs1-tk', loginDispatch?.login?.token as string);
        localStorage.setItem(
          'vs1-tux',
          encryptData(loginDispatch?.login?.userType, '3573n035uN70K3n') as string
        );
        localStorage.setItem(
          'vs1-unx',
          encryptData(loginDispatch?.login?.userName, '3573n035uN70K3n') as string
        );
        setLogged(true);
        setLoadingLogin(false);
        toast.success(loginDispatch?.login?.message, {
          hideProgressBar: true,
        });
        dispatch(clearLogin() as TDispatch);
        navigate('/admin');
      } else if (!loginDispatch?.login && loginDispatch?.error) {
        const message = loginDispatch?.error?.response
          ? (loginDispatch?.error?.response?.data as TMessage)?.message
          : 'Ha ocurrido una incidencia.';
        setLoadingLogin(false);
        toast.error(`${message}`, {
          hideProgressBar: true,
        });
        dispatch(clearLogin() as TDispatch);
      }
    }
  }, [loginDispatch?.login, loginDispatch?.error, loadingLogin, dispatch, navigate, setLogged]);

  /**
   * UseEffect para validad usuario
   */
  // useEffect(() => {
  //   if (waitingAnswerLogin) {
  //     if (userLoginDispatch?.userLogin?.token) {
  //       userSession(userLoginDispatch?.userLogin);
  //       navigate('dashboard');
  //       setWaitingAnswerLogin(false);
  //     }
  //   }
  // }, [
  //   userLoginDispatch?.userLogin,
  //   userLoginDispatch?.error,
  //   dispatch,
  //   waitingAnswerLogin,
  //   userSession,
  //   navigate,
  // ]);

  /**
   * UseEffect para llamar a los dispatch
   */
  useEffect(() => {
    dispatch(clearLogin() as TDispatch);
  }, [dispatch]);

  return (
    <LoginV
      login={login}
      control={control}
      errors={errors}
      schema={schema}
      register={register}
      handleSubmit={handleSubmit}
      loading={loadingLogin}
    />
  );
};
