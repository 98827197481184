import 'reflect-metadata';
import { container } from 'tsyringe';
import { AxiosError } from 'axios';
import { ICitiesApplication } from '../../../core/domain/interface/application/addresses/ICitiesApplication';
import { AppDispatch } from '../../store';
import { RequestCities } from '../../../core/domain/addresses/cities/model/requestCities';
import actions from './actions';

const { citiesListPageBegin, citiesListPageSuccess, citiesListPageErr } = actions;

const citiesListPage = (): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(citiesListPageBegin());
      const citiesApplication = container.resolve<ICitiesApplication>('ICitiesApplication');

      const request = new RequestCities();
      const result = await citiesApplication.getCities(request);
      dispatch(citiesListPageSuccess(result));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(citiesListPageErr(error));
    }
  };
};

export { citiesListPage };
