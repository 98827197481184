import 'reflect-metadata';
import { container } from 'tsyringe';
import { RequestLogin } from '../../domain/security/model/request/requestLogin';
import { ResponseUser } from '../../domain/userPermissions/user/model/responseUser';
import { ILoginProviderApplication } from '../../domain/interface/application/security/ILoginProviderApplication';
import { ILoginProviderInfrastructure } from '../../domain/interface/infrastructure/security/ILoginProviderInfrastructure';

export class LoginProviderApplication implements ILoginProviderApplication {
  private loginProviderInfrastructure: ILoginProviderInfrastructure;

  public constructor() {
    this.loginProviderInfrastructure = container.resolve<ILoginProviderInfrastructure>(
      'ILoginProviderInfrastructure'
    );
  }

  public async handle(request: RequestLogin): Promise<ResponseUser> {
    const result = await this.loginProviderInfrastructure.handleAPI(request);
    return result;
  }
}
