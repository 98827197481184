import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import UserListV from '../../../components/user/userList';
import { RootState } from '../../../../../redux/store';
import { TDispatch, TodoSuccessResponse } from '../../../../../common/utils/types.d';
import {
  clearUserDeleteListPage,
  userDeletePage,
  usersListPage,
} from '../../../../../redux/userPermissions/user/actionCreator';
import { IUserListMap } from './types.d';
import {
  TUserDeleteReducer,
  TUsersReducer,
} from '../../../../../redux/userPermissions/user/types.d';

export const UserListC = () => {
  /**
   * UseStates
   */
  const [userData, setUserData] = useState<IUserListMap[]>([]);
  const [dataId, setDataId] = useState<number>();
  const [openDelete, setOpenDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  /**
   *  Dispatch
   */
  const dispatch = useDispatch();

  /**
   * Dispatch de getAllUsers
   */
  const getAllUsersDispatch: TUsersReducer = useSelector(
    (dataState: RootState) => dataState?.users
  );

  /**
   * Dispatch de deleteUser
   */
  const deleteUserDispatch: TUserDeleteReducer = useSelector(
    (dataState: RootState) => dataState?.userDeleted
  );

  /**
   * Función para elimnar un registro
   */
  const handleDelete = async () => {
    dispatch(userDeletePage({ idUser: Number(dataId) }) as TDispatch);
    setOpenDelete(false);
    setLoading(true);
  };

  /**
   * Función que se encarga de cerrar el modal de confirmación
   */
  const handleClose = () => {
    setOpenDelete(false);
  };
  /**
   * Función que se encarga de abrir el modal de confirmación para poder eliminar un registro
   */
  const buttonDelete = async (datos: number) => {
    setDataId(datos);
    setOpenDelete(true);
  };

  /**
   * UseEffect pendiente al momento de eliminar un usuario existente
   */
  useEffect(() => {
    if (dataId && loading) {
      if (deleteUserDispatch?.userDeleted && !deleteUserDispatch?.error) {
        toast.success('Usuario eliminado exitosamente', {
          hideProgressBar: true,
        });
        dispatch(clearUserDeleteListPage() as TDispatch);
        dispatch(usersListPage() as TDispatch);
      } else if (!deleteUserDispatch?.userDeleted && deleteUserDispatch?.error) {
        const message = deleteUserDispatch?.error?.response
          ? (deleteUserDispatch?.error?.response?.data as TodoSuccessResponse)?.message
          : 'Ha ocurrido una incidencia';
        toast.error(`${message}`, {
          hideProgressBar: true,
        });
        dispatch(clearUserDeleteListPage() as TDispatch);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteUserDispatch?.userDeleted]);

  /**
   * UseEffect que se encarga de mostrar la información en la Lista de Usuarios
   */
  useEffect(() => {
    if (getAllUsersDispatch?.users instanceof Array) {
      const data = getAllUsersDispatch?.users?.map((x: IUserListMap, i: number) => {
        return {
          ...x,
          userType: x.userType?.split(';').join(' - ').toUpperCase(),
        };
      });
      setUserData(data);
    }
  }, [getAllUsersDispatch?.users]);

  /**
   * UseEffect que se encarga de llamar a todos los dispatches
   */
  useEffect(() => {
    dispatch(clearUserDeleteListPage() as TDispatch);
    dispatch(usersListPage() as TDispatch);
  }, [dispatch]);

  return (
    <UserListV
      data={userData}
      loading={getAllUsersDispatch?.loading}
      handleDelete={handleDelete}
      openDelete={openDelete}
      handleClose={handleClose}
      buttonDelete={buttonDelete}
      deleteLoading={deleteUserDispatch?.loading}
    />
  );
};
