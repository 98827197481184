import 'reflect-metadata';
import { AxiosResponse } from 'axios';
import { ResponseTaxRegime } from '../../../domain/taxInformation/taxRegime/model/responseTaxRegime';
import { RequestTaxRegime } from '../../../domain/taxInformation/taxRegime/model/requestTaxRegime';
import { ITaxRegimeInfrastructure } from '../../../domain/interface/infrastructure/taxInformation/ItaxRegimeInfrastructure';
import { processDataBackend } from '../../../../common/utils/functions';

export class TaxRegimeInfrastructure implements ITaxRegimeInfrastructure {
  private a = '';

  public async getTaxRegimeAPI(request: RequestTaxRegime): Promise<ResponseTaxRegime[]> {
    this.a = 'aaa';

    const res: AxiosResponse = await processDataBackend(
      'GET',
      '/api/taxRegimens/getAll',
      {},
      { request }
    );

    const TaxRegime: ResponseTaxRegime[] = res.data;
    return Promise.resolve(TaxRegime);
  }
}
