import 'reflect-metadata';
import { container } from 'tsyringe';
import { ResponseUser } from '../../domain/userPermissions/user/model/responseUser';
import { ILogoutApplication } from '../../domain/interface/application/security/ILogoutApplication';
import { ILogoutInfrastructure } from '../../domain/interface/infrastructure/security/ILogoutInfrastructure';

export class LogoutApplication implements ILogoutApplication {
  private LogoutInfrastructure: ILogoutInfrastructure;

  public constructor() {
    this.LogoutInfrastructure = container.resolve<ILogoutInfrastructure>('ILogoutInfrastructure');
  }

  public async handle(token: string): Promise<ResponseUser> {
    const result = await this.LogoutInfrastructure.handleAPI(token);
    return result;
  }
}
