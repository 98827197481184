import { AxiosError } from 'axios';
import { ResponseBeneficiaries } from '../../core/domain/beneficiaries/model/responseBeneficiaries';

const actionsBeneficiaries = {
  BENEFICIARIES_LIST_PAGE_BEGIN: 'BENEFICIARIES_PAGE_BEGIN',
  BENEFICIARIES_LIST_PAGE_SUCCESS: 'BENEFICIARIES_PAGE_SUCCESS',
  BENEFICIARIES_LIST_PAGE_ERR: 'BENEFICIARIES_PAGE_ERR',

  beneficiariesListPageBegin: (): { type: string } => {
    return {
      type: actionsBeneficiaries.BENEFICIARIES_LIST_PAGE_BEGIN,
    };
  },

  beneficiariesListPageSuccess: (
    data: ResponseBeneficiaries[]
  ): { type: string; data: ResponseBeneficiaries[] | undefined } => {
    return {
      type: actionsBeneficiaries.BENEFICIARIES_LIST_PAGE_SUCCESS,
      data,
    };
  },

  beneficiariesListPageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsBeneficiaries.BENEFICIARIES_LIST_PAGE_ERR,
      err,
    };
  },
};

const actionsBeneficiariesCustomers = {
  BENEFICIARIES_CUSTOMERS_LIST_PAGE_BEGIN: 'BENEFICIARIES_CUSTOMERS_PAGE_BEGIN',
  BENEFICIARIES_CUSTOMERS_LIST_PAGE_SUCCESS: 'BENEFICIARIES_CUSTOMERS_PAGE_SUCCESS',
  BENEFICIARIES_CUSTOMERS_LIST_PAGE_ERR: 'BENEFICIARIES_CUSTOMERS_PAGE_ERR',

  beneficiariesCustomersListPageBegin: (): { type: string } => {
    return {
      type: actionsBeneficiariesCustomers.BENEFICIARIES_CUSTOMERS_LIST_PAGE_BEGIN,
    };
  },

  beneficiariesCustomersListPageSuccess: (
    data: ResponseBeneficiaries[]
  ): { type: string; data: ResponseBeneficiaries[] | undefined } => {
    return {
      type: actionsBeneficiariesCustomers.BENEFICIARIES_CUSTOMERS_LIST_PAGE_SUCCESS,
      data,
    };
  },

  beneficiariesCustomersListPageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsBeneficiariesCustomers.BENEFICIARIES_CUSTOMERS_LIST_PAGE_ERR,
      err,
    };
  },
};

const actionsBeneficiary = {
  BENEFICIARY_LIST_PAGE_BEGIN: 'BENEFICIARY_PAGE_BEGIN',
  BENEFICIARY_LIST_PAGE_SUCCESS: 'BENEFICIARY_PAGE_SUCCESS',
  BENEFICIARY_LIST_PAGE_ERR: 'BENEFICIARY_PAGE_ERR',

  beneficiaryListPageBegin: (): { type: string } => {
    return {
      type: actionsBeneficiary.BENEFICIARY_LIST_PAGE_BEGIN,
    };
  },

  beneficiaryListPageSuccess: (
    data: ResponseBeneficiaries | undefined
  ): { type: string; data: ResponseBeneficiaries | undefined } => {
    return {
      type: actionsBeneficiary.BENEFICIARY_LIST_PAGE_SUCCESS,
      data,
    };
  },

  beneficiaryListPageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsBeneficiary.BENEFICIARY_LIST_PAGE_ERR,
      err,
    };
  },
};

const actionsBeneficiaryCreate = {
  BENEFICIARY_CREATE_PAGE_BEGIN: 'BENEFICIARY_CREATE_PAGE_BEGIN',
  BENEFICIARY_CREATE_PAGE_SUCCESS: 'BENEFICIARY_CREATE_PAGE_SUCCESS',
  BENEFICIARY_CREATE_PAGE_ERR: 'BENEFICIARY_CREATE_PAGE_ERR',

  beneficiaryCreatePageBegin: (): { type: string } => {
    return {
      type: actionsBeneficiaryCreate.BENEFICIARY_CREATE_PAGE_BEGIN,
    };
  },

  beneficiaryCreatePageSuccess: (
    data: ResponseBeneficiaries | undefined
  ): { type: string; data: ResponseBeneficiaries | undefined } => {
    return {
      type: actionsBeneficiaryCreate.BENEFICIARY_CREATE_PAGE_SUCCESS,
      data,
    };
  },

  beneficiaryCreatePageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsBeneficiaryCreate.BENEFICIARY_CREATE_PAGE_ERR,
      err,
    };
  },
};

const actionsBeneficiaryUpdate = {
  BENEFICIARY_UPDATE_PAGE_BEGIN: 'BENEFICIARY_UPDATE_PAGE_BEGIN',
  BENEFICIARY_UPDATE_PAGE_SUCCESS: 'BENEFICIARY_UPDATE_PAGE_SUCCESS',
  BENEFICIARY_UPDATE_PAGE_ERR: 'BENEFICIARY_UPDATE_PAGE_ERR',
  beneficiaryUpdatePageBegin: (): { type: string } => {
    return {
      type: actionsBeneficiaryUpdate.BENEFICIARY_UPDATE_PAGE_BEGIN,
    };
  },

  beneficiaryUpdatePageSuccess: (
    data: ResponseBeneficiaries | undefined
  ): { type: string; data: ResponseBeneficiaries | undefined } => {
    return {
      type: actionsBeneficiaryUpdate.BENEFICIARY_UPDATE_PAGE_SUCCESS,
      data,
    };
  },

  beneficiaryUpdatePageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsBeneficiaryUpdate.BENEFICIARY_UPDATE_PAGE_ERR,
      err,
    };
  },
};

const actionsBeneficiaryDelete = {
  BENEFICIARY_DELETE_PAGE_BEGIN: 'BENEFICIARY_DELETE_PAGE_BEGIN',
  BENEFICIARY_DELETE_PAGE_SUCCESS: 'BENEFICIARY_DELETE_PAGE_SUCCESS',
  BENEFICIARY_DELETE_PAGE_ERR: 'BENEFICIARY_DELETE_PAGE_ERR',
  beneficiaryDeletePageBegin: (): { type: string } => {
    return {
      type: actionsBeneficiaryDelete.BENEFICIARY_DELETE_PAGE_BEGIN,
    };
  },

  beneficiaryDeletePageSuccess: (
    data: ResponseBeneficiaries | undefined
  ): { type: string; data: ResponseBeneficiaries | undefined } => {
    return {
      type: actionsBeneficiaryDelete.BENEFICIARY_DELETE_PAGE_SUCCESS,
      data,
    };
  },

  beneficiaryDeletePageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsBeneficiaryDelete.BENEFICIARY_DELETE_PAGE_ERR,
      err,
    };
  },
};

const actionsCreateBulkBeneficiaryList = {
  BENEFICIARY_CREATE_BULK_LIST_PAGE_BEGIN: 'BENEFICIARY_CREATE_BULK_LIST_PAGE_BEGIN',
  BENEFICIARY_CREATE_BULK_LIST_PAGE_SUCCESS: 'BENEFICIARY_CREATE_BULK_LIST_PAGE_SUCCESS',
  BENEFICIARY_CREATE_BULK_LIST_PAGE_ERR: 'BENEFICIARY_CREATE_BULK_LIST_PAGE_ERR',

  createBulkBeneficiaryListPageBegin: (): { type: string } => {
    return {
      type: actionsCreateBulkBeneficiaryList.BENEFICIARY_CREATE_BULK_LIST_PAGE_BEGIN,
    };
  },

  createBulkBeneficiaryListPageSuccess: (
    data: ResponseBeneficiaries[] | undefined
  ): { type: string; data: ResponseBeneficiaries[] | undefined } => {
    return {
      type: actionsCreateBulkBeneficiaryList.BENEFICIARY_CREATE_BULK_LIST_PAGE_SUCCESS,
      data,
    };
  },

  createBulkBeneficiaryListPageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsCreateBulkBeneficiaryList.BENEFICIARY_CREATE_BULK_LIST_PAGE_ERR,
      err,
    };
  },
};

const actionsBeneficiaryForBenefits = {
  BENEFICIARY_BENEFITS_LIST_PAGE_BEGIN: 'BENEFICIARY_BENEFITS_LIST_PAGE_BEGIN',
  BENEFICIARY_BENEFITS_LIST_PAGE_SUCCESS: 'BENEFICIARY_BENEFITS_LIST_PAGE_SUCCESS',
  BENEFICIARY_BENEFITS_LIST_PAGE_ERR: 'BENEFICIARY_BENEFITS_LIST_PAGE_ERR',

  beneficiaryForBenefitsPageBegin: (): { type: string } => {
    return {
      type: actionsBeneficiaryForBenefits.BENEFICIARY_BENEFITS_LIST_PAGE_BEGIN,
    };
  },

  beneficiaryForBenefitsPageSuccess: (
    data: ResponseBeneficiaries | undefined
  ): { type: string; data: ResponseBeneficiaries | undefined } => {
    return {
      type: actionsBeneficiaryForBenefitsList.BENEFICIARY_BENEFITS_PAGE_SUCCESS,
      data,
    };
  },

  beneficiaryForBenefitsPageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsBeneficiaryForBenefits.BENEFICIARY_BENEFITS_LIST_PAGE_ERR,
      err,
    };
  },
};
const actionsBeneficiaryValidityBenefits = {
  BENEFICIARY_BENEFITS_LIST_PAGE_BEGIN: 'BENEFICIARY_BENEFITS_LIST_PAGE_BEGIN',
  BENEFICIARY_BENEFITS_LIST_PAGE_SUCCESS: 'BENEFICIARY_BENEFITS_LIST_PAGE_SUCCESS',
  BENEFICIARY_BENEFITS_LIST_PAGE_ERR: 'BENEFICIARY_BENEFITS_LIST_PAGE_ERR',

  beneficiaryValidityBegin: (): { type: string } => {
    return {
      type: actionsBeneficiaryValidityBenefits.BENEFICIARY_BENEFITS_LIST_PAGE_BEGIN,
    };
  },

  beneficiaryValiditySuccess: (
    data: ResponseBeneficiaries | undefined
  ): { type: string; data: ResponseBeneficiaries | undefined } => {
    return {
      type: actionsBeneficiaryValidityBenefits.BENEFICIARY_BENEFITS_LIST_PAGE_SUCCESS,
      data,
    };
  },

  beneficiaryValidityErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsBeneficiaryValidityBenefits.BENEFICIARY_BENEFITS_LIST_PAGE_ERR,
      err,
    };
  },
};

const actionsBeneficiaryForBenefitsList = {
  BENEFICIARY_BENEFITS_PAGE_BEGIN: 'BENEFICIARY_BENEFITS_PAGE_BEGIN',
  BENEFICIARY_BENEFITS_PAGE_SUCCESS: 'BENEFICIARY_BENEFITS_PAGE_SUCCESS',
  BENEFICIARY_BENEFITS_PAGE_ERR: 'BENEFICIARY_BENEFITS_PAGE_ERR',

  beneficiaryForBenefitsListPageBegin: (): { type: string } => {
    return {
      type: actionsBeneficiaryForBenefitsList.BENEFICIARY_BENEFITS_PAGE_BEGIN,
    };
  },

  beneficiaryForBenefitsListPageSuccess: (
    data: ResponseBeneficiaries | undefined
  ): { type: string; data: ResponseBeneficiaries | undefined } => {
    return {
      type: actionsBeneficiaryForBenefitsList.BENEFICIARY_BENEFITS_PAGE_SUCCESS,
      data,
    };
  },

  beneficiaryForBenefitsListPageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsBeneficiaryForBenefitsList.BENEFICIARY_BENEFITS_PAGE_ERR,
      err,
    };
  },
};
export {
  actionsBeneficiaries,
  actionsBeneficiariesCustomers,
  actionsBeneficiary,
  actionsBeneficiaryCreate,
  actionsBeneficiaryUpdate,
  actionsBeneficiaryDelete,
  actionsCreateBulkBeneficiaryList,
  actionsBeneficiaryForBenefits,
  actionsBeneficiaryForBenefitsList,
  actionsBeneficiaryValidityBenefits,
};
