import 'reflect-metadata';
import { AxiosResponse } from 'axios';
import { IAcademicLevelsInfrastructure } from '../../../domain/interface/infrastructure/documentsPersons/IAcademicInfrastructure';
import { RequestAcademicLevels } from '../../../domain/documentsPersons/academicLevels/model/requestAcademicLevels';
import { ResponseAcademicLevels } from '../../../domain/documentsPersons/academicLevels/model/responseAcademicLevels';
import { processDataBackend } from '../../../../common/utils/functions';

export class AcademicLevelsInfrastructure implements IAcademicLevelsInfrastructure {
  private a = '';

  public async getAcademicLevelsAPI(
    request: RequestAcademicLevels
  ): Promise<ResponseAcademicLevels[]> {
    this.a = 'aaa';

    const res: AxiosResponse = await processDataBackend(
      'GET',
      '/api/academicLevel/getAll',
      {},
      { request }
    );

    const academicLevels: ResponseAcademicLevels[] = res.data;

    return Promise.resolve(academicLevels);
  }
}
