import React from 'react';
import { BenefitsRegisterC } from '../../../containers/benefits/benefitRegister';
import LayoutC from '../../../containers/Layout';

const BenefitsRegisterP = () => {
  return (
    <LayoutC>
      <BenefitsRegisterC />
    </LayoutC>
  );
};

export default BenefitsRegisterP;
