import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Yup from 'yup';

// @mui/material TextField adapter component for react-hook-form and yup validation schema

export const TextAreaAdapter = (
  props: TextFieldProps & {
    name: string;
    control: Control;
    schema: Yup.AnySchema;
    error?: string;
    rows?: string | number | undefined;
  }
) => {
  const { name, control, schema, rows, ...rest } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { invalid, error: err },
  } = useController({
    name,
    control,
    rules: {
      validate: (val) => schema.validate(val).catch((error) => error.message),
    },
    defaultValue: '',
  });

  return (
    <TextField
      {...rest}
      name={name}
      inputRef={ref}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      size="small"
      multiline
      rows={rows}
      error={invalid}
      helperText={t(err?.message || '')}
      sx={{ mb: theme.spacing(2), width: '100%' }}
    />
  );
};
