import { AxiosError } from 'axios';
import 'reflect-metadata';
import { container } from 'tsyringe';
import { AppDispatch } from '../../store';
import { IUserApplication } from '../../../core/domain/interface/application/userPermissions/IUserApplication';
import { RequestUser } from '../../../core/domain/userPermissions/user/model/requestUser';
import {
  actionsUpdateRoleToUser,
  actionsUser,
  actionsUserCreate,
  actionsUserDelete,
  actionsUserLoggedUpdatePassword,
  actionsUserLogin,
  actionsUserRecoveryPassword,
  actionsUsers,
  actionsUsersByRole,
  actionsUserUpdate,
  actionsUserUpdatePassword,
} from './actions';

const { usersListPageBegin, usersListPageSuccess, usersListPageErr } = actionsUsers;
const { userListPageBegin, userListPageSuccess, userListPageErr } = actionsUser;
const { userLoginListPageBegin, userLoginListPageSuccess, userLoginListPageErr } = actionsUserLogin;
const { userCreatePageBegin, userCreatePageSuccess, userCreatePageErr } = actionsUserCreate;
const { userUpdatePageBegin, userUpdatePageSuccess, userUpdatePageErr } = actionsUserUpdate;
const { userUpdatePasswordPageBegin, userUpdatePasswordPageSuccess, userUpdatePasswordPageErr } =
  actionsUserUpdatePassword;

const {
  userLoggedUpdatePasswordPageBegin,
  userLoggedUpdatePasswordPageSuccess,
  userLoggedUpdatePasswordPageErr,
} = actionsUserLoggedUpdatePassword;

const { userDeletePageBegin, userDeletePageSuccess, userDeletePageErr } = actionsUserDelete;
const { updateRoleToUserPageBegin, updateRoleToUserPageSuccess, updateRoleToUserPageErr } =
  actionsUpdateRoleToUser;
const { usersGetByRoleBegin, usersGetByRoleSuccess, usersGetByRoleErr } = actionsUsersByRole;

const {
  userRecoveryPasswordPageBegin,
  userRecoveryPasswordPageErr,
  userRecoveryPasswordPageSuccess,
} = actionsUserRecoveryPassword;

export const usersListPage = (): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(usersListPageBegin());
      const userApplication = container.resolve<IUserApplication>('IUserApplication');

      const request = new RequestUser();
      const result = await userApplication.getUser(request);

      dispatch(usersListPageSuccess(result));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(usersListPageErr(error));
    }
  };
};

export const userCreatePage = (
  listState: RequestUser
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(userCreatePageBegin());
      const userApplication = container.resolve<IUserApplication>('IUserApplication');

      const resultCreate = await userApplication.createUser(listState);
      dispatch(userCreatePageSuccess(resultCreate));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(userCreatePageErr(error));
    }
  };
};
export const userUpdatePage = (
  listState: RequestUser
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(userUpdatePageBegin());
      const userApplication = container.resolve<IUserApplication>('IUserApplication');

      const resultCreate = await userApplication.updateUser(listState);
      dispatch(userUpdatePageSuccess(resultCreate));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(userUpdatePageErr(error));
    }
  };
};

export const userRecoveryPasswordPage = (
  listState: RequestUser
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(userRecoveryPasswordPageBegin());
      const userApplication = container.resolve<IUserApplication>('IUserApplication');

      const resultCreate = await userApplication.RecoveryPassword(listState);
      dispatch(userRecoveryPasswordPageSuccess(resultCreate));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(userRecoveryPasswordPageErr(error));
    }
  };
};

export const userPasswordUpdatePage = (
  listState: RequestUser
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(userUpdatePasswordPageBegin());
      const userApplication = container.resolve<IUserApplication>('IUserApplication');

      const resultUpdate = await userApplication.updatePassword(listState);
      dispatch(userUpdatePasswordPageSuccess(resultUpdate));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(userUpdatePasswordPageErr(error));
    }
  };
};
export const userLoggedPasswordUpdatePage = (
  listState: RequestUser
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(userLoggedUpdatePasswordPageBegin());
      const userApplication = container.resolve<IUserApplication>('IUserApplication');

      const resultUpdate = await userApplication.loggedUpdatePassword(listState);
      dispatch(userLoggedUpdatePasswordPageSuccess(resultUpdate));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(userLoggedUpdatePasswordPageErr(error));
    }
  };
};

export const updateRoleToUserPage = (
  listState: RequestUser
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(updateRoleToUserPageBegin());
      const userApplication = container.resolve<IUserApplication>('IUserApplication');

      const result = await userApplication.updateRoleToUser(listState);
      dispatch(updateRoleToUserPageSuccess(result));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(updateRoleToUserPageErr(error));
    }
  };
};

export const userDeletePage = (
  listState: RequestUser
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(userDeletePageBegin());
      const userApplication = container.resolve<IUserApplication>('IUserApplication');

      const resultDelete = await userApplication.deleteUser(listState);
      dispatch(userDeletePageSuccess(resultDelete));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(userDeletePageErr(error));
    }
  };
};

export const getByIdUserListPage = (
  listState: RequestUser
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(userListPageBegin());
      const userApplication = container.resolve<IUserApplication>('IUserApplication');

      const resultGetById = await userApplication.getByIdUser(listState);
      dispatch(userListPageSuccess(resultGetById));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(userListPageErr(error));
    }
  };
};

export const userLogin = (
  userLoginState: RequestUser
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(userLoginListPageBegin());
      const userApplication = container.resolve<IUserApplication>('IUserApplication');

      const resultUserLogin = await userApplication.userLogin(userLoginState);
      dispatch(userLoginListPageSuccess(resultUserLogin));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(userLoginListPageErr(error));
    }
  };
};

export const usersByRolePage = (
  userLoginState: RequestUser
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(usersGetByRoleBegin());
      const userApplication = container.resolve<IUserApplication>('IUserApplication');

      const result = await userApplication.getUsersByRole(userLoginState);

      dispatch(usersGetByRoleSuccess(result));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(usersGetByRoleErr(error));
    }
  };
};

export const clearUserLoginListPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(userLoginListPageBegin());
    dispatch(userLoginListPageErr(undefined));
    dispatch(userLoginListPageSuccess(undefined));
  };
};

export const clearUsersListPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(usersListPageBegin());
    dispatch(usersListPageErr(undefined));
    dispatch(usersListPageSuccess([]));
  };
};

export const clearUserCreateListPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(userCreatePageBegin());
    dispatch(userCreatePageErr(undefined));
    dispatch(userCreatePageSuccess(undefined));
  };
};

export const clearUserUpdateListPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(userUpdatePageBegin());
    dispatch(userUpdatePageErr(undefined));
    dispatch(userUpdatePageSuccess(undefined));
  };
};

export const clearUserUpdatePasswordListPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(userUpdatePasswordPageBegin());
    dispatch(userUpdatePasswordPageErr(undefined));
    dispatch(userUpdatePasswordPageSuccess(undefined));
  };
};
export const clearUserLoggedUpdatePasswordListPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(userLoggedUpdatePasswordPageBegin());
    dispatch(userLoggedUpdatePasswordPageErr(undefined));
    dispatch(userLoggedUpdatePasswordPageSuccess(undefined));
  };
};
export const clearUserDeleteListPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(userDeletePageBegin());
    dispatch(userDeletePageErr(undefined));
    dispatch(userDeletePageSuccess(undefined));
  };
};

export const clearUpdateRoleToUserListPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(updateRoleToUserPageBegin());
    dispatch(updateRoleToUserPageErr(undefined));
    dispatch(updateRoleToUserPageSuccess(undefined));
  };
};

export const clearUserListPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(userListPageBegin());
    dispatch(userListPageErr(undefined));
    dispatch(userListPageSuccess(undefined));
  };
};

export const clearUsersByRoleListPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(usersGetByRoleBegin());
    dispatch(usersGetByRoleErr(undefined));
    dispatch(usersGetByRoleSuccess([]));
  };
};

export const clearUserRecoveryPasswordPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(userRecoveryPasswordPageBegin());
    dispatch(userRecoveryPasswordPageErr(undefined));
    dispatch(userRecoveryPasswordPageSuccess(undefined));
  };
};
