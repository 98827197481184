import { ResponseThirdParties } from '../model/thirdPartiesResponse';

export class ThirdPartiesState {
  public constructor() {
    this.thirdPartiesProviders = [new ResponseThirdParties()];
    this.thirdPartyProvider = new ResponseThirdParties();
    this.thirdPartyProviderCreated = new ResponseThirdParties();
    this.thirdPartyProviderUpdated = new ResponseThirdParties();
    this.thirdPartiesCustomers = [new ResponseThirdParties()];
    this.thirdPartyCustomer = new ResponseThirdParties();
    this.thirdPartyCustomerCreated = new ResponseThirdParties();
    this.thirdPartyCustomerUpdated = new ResponseThirdParties();
    this.thirdPartyCustomerDeleted = new ResponseThirdParties();
  }
  // Providers

  public thirdPartiesProviders?: ResponseThirdParties[];

  public thirdPartyProvider?: ResponseThirdParties;

  public thirdPartyProviderCreated?: ResponseThirdParties;

  public thirdPartyProviderUpdated?: ResponseThirdParties;

  // Customers

  public thirdPartiesCustomers?: ResponseThirdParties[];

  public thirdPartyCustomer?: ResponseThirdParties;

  public thirdPartyCustomerCreated?: ResponseThirdParties;

  public thirdPartyCustomerUpdated?: ResponseThirdParties;

  public thirdPartyCustomerDeleted: ResponseThirdParties;
}
