import React from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  Box,
  useTheme,
  IconButton,
  Typography,
  Grid,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import { IDataBenefit, IProviderDashboard } from './types.d';
import { ButtonSubmit } from './styles';
import image from '../../../../../common/components/images';
import { capitalize } from '../../../../../common/utils/functions';
import '../../couponsPage/styles.css';

export const ProviderDashboardV: React.FC<IProviderDashboard> = ({
  handleSession,
  disabled,
  acceptedBenefit,
  dataBenefit,
  dataDisabled,
  id,
}) => {
  const theme = useTheme();
  const LogoBonus = image('logoBonus');
  const style = {
    boxContentScrollable: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'scroll',
    },
    contentBoxCategory: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '66px',
      boxShadow: '0px 5px 10px 0px #0000001A',
      backgroundColor: '#ffffff',
      borderRadius: '10px',
      boxSizing: 'border-box',
      padding: '20px',
      marginBottom: '20px',
    },
    circularProgress: {
      width: '100%',
      height: '75vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    contentText: {
      width: '70%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      flexDirection: 'column',
      display: 'flex',
      height: '40px',
    },
    title: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '14px',
      color: '#3e5159d6',
      font: 'Poppins',
      fontWeight: 600,
    },
    bubTitle: {
      color: '#3E5159',
      fontSize: '12px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        background: 'lightgray',
      }}>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          maxWidth: '425px',
          background: '#F7F7F7',
          boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);',
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '60px',
            background: theme.palette.primary.main,
            width: 'auto',
            boxSizing: 'border-box',
            padding: '10px 15px',
            borderRadius: '0px 0px 8px 8px',
          }}>
          <img src={LogoBonus} alt="Bonus plus" style={{ height: '40px', width: '120px' }} />
          <IconButton onClick={handleSession} sx={{ padding: '0px' }}>
            <LogoutIcon sx={{ color: 'white', width: '40px' }} />
          </IconButton>
        </Box>
        <Grid
          container
          sx={{
            height: 'calc(100% - 70px)',
            boxSizing: 'border-box',
            padding: '20px',
            gap: '16px',
          }}>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              fontWeight={600}
              sx={{ color: theme.palette.primary.main, fontSize: '19px' }}>
              Aprobación de beneficios
            </Typography>
          </Grid>
          {!!id && !!dataBenefit?.length ? (
            dataDisabled ? (
              <Box sx={style.circularProgress}>
                <CircularProgress size={15} sx={{ color: 'black' }} />
              </Box>
            ) : (
              <Grid container className="hide-scrollbar" sx={style.boxContentScrollable}>
                {dataBenefit?.map((x: IDataBenefit) => {
                  return (
                    <Box sx={style.contentBoxCategory}>
                      <Box sx={style.contentText}>
                        <Typography sx={style.title}>{capitalize(x.name)}</Typography>
                        <Typography sx={style.bubTitle}>Total de consumos: {x.consumed}</Typography>
                      </Box>
                      <Tooltip title="Aceptar beneficio">
                        <ButtonSubmit
                          key={x.idBenefitBeneficiary}
                          type="button"
                          disabled={disabled}
                          backgroundColor={theme.palette.gradientBackground.main}
                          onClick={() => acceptedBenefit(x?.idBenefitBeneficiary)}>
                          {disabled ? (
                            <CircularProgress size={15} sx={{ color: 'white' }} />
                          ) : (
                            <Typography fontSize="15px" sx={{ color: 'white' }}>
                              Aceptar
                            </Typography>
                          )}
                        </ButtonSubmit>
                      </Tooltip>
                    </Box>
                  );
                })}
              </Grid>
            )
          ) : (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#788081',
                textAlign: 'center',
              }}>
              <Typography fontSize={18}>No hay beneficios para mostrar</Typography>
            </Box>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
