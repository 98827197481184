import { AxiosError } from 'axios';
import { ResponseEconomicActivities } from '../../../core/domain/taxInformation/economicActivities/model/responseEconomicActivities';
import { EconomicActivitiesState } from '../../../core/domain/taxInformation/economicActivities/state/economicActivities';
import actions from './actions';
import { TEconomicActivitiesReducer } from './types';
import { dataSorted } from '../../../common/utils/functions';

const {
  ECONOMIC_ACTIVITIES_LIST_PAGE_BEGIN,
  ECONOMIC_ACTIVITIES_PAGE_SUCCESS,
  ECONOMIC_ACTIVITIES_LIST_PAGE_ERR,
} = actions;

const initialEconomicACtivities: EconomicActivitiesState = new EconomicActivitiesState();

const EconomicActivitiesReducer = (
  state = initialEconomicACtivities,
  action: {
    type: string;
    err: AxiosError;
    data: ResponseEconomicActivities[];
  }
): TEconomicActivitiesReducer => {
  const { type, data, err } = action;

  switch (type) {
    case ECONOMIC_ACTIVITIES_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ECONOMIC_ACTIVITIES_PAGE_SUCCESS:
      return {
        ...state,
        economicActivities: dataSorted(data),
        loading: false,
      };
    case ECONOMIC_ACTIVITIES_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default EconomicActivitiesReducer;
