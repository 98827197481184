import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { TDispatch } from '../../../../../common/utils/types.d';
import {
  LoginProvider,
  clearProviderLogin,
} from '../../../../../redux/loginProvider/actionCreator';
import { RootState } from '../../../../../redux/store';
import { ProviderLoginV } from '../../../components/providers/login';
import { IFormValues, TLoginProviderDispatch, TMessage, TProviderData } from './types.d';
import { encryptData } from '../../../../../common/utils/functions';

export const ProviderLoginC = () => {
  const [loadingLogin, setLoadingLogin] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  /**
   * dispatch
   */
  const dispatch = useDispatch();

  /**
   * Schema
   */
  const schema = yup
    .object({
      email: yup.string().required('El correo es requerido').email('Ingrese un correo válido'),
      password: yup.string().required('La contraseña es requerida'),
    })
    .required();

  /**
   * useForm
   */
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  /**
   * Función que permite al comercio iniciar sesión
   */
  const login = async (data: IFormValues) => {
    setLoadingLogin(true);
    const providerRegister: TProviderData = {
      email: data?.email,
      password: data?.password,
    };
    dispatch(LoginProvider(providerRegister) as TDispatch);
  };

  const goToDashboard = () => {
    navigate(`/comercio/user/dashboard/${id}`);
  };

  /**
   * Dispatch de Login
   */
  const loginProviderDispatch: TLoginProviderDispatch = useSelector(
    (dataState: RootState) => dataState?.providerLoggedIn
  );

  // useEffect para validar si el usuario existe e inicie sección
  useEffect(() => {
    if (loadingLogin)
      if (loginProviderDispatch?.login && !loginProviderDispatch?.error) {
        toast.success(loginProviderDispatch?.login?.message, {
          hideProgressBar: true,
        });
        localStorage.setItem('vs3-tk', loginProviderDispatch?.login?.token);
        localStorage.setItem('vs3-id', `${loginProviderDispatch?.login?.idThirdParty}`);
        localStorage.setItem('vs3-tux', encryptData('Comercio', '3573n035uN70K3n') as string);
        setLoadingLogin(false);
        goToDashboard();
      } else if (!loginProviderDispatch?.login && loginProviderDispatch?.error) {
        const message = loginProviderDispatch?.error?.response
          ? (loginProviderDispatch?.error?.response?.data as TMessage)?.message
          : 'Ha ocurrido una incidencia.';
        setLoadingLogin(false);
        toast.error(`${message}`, {
          hideProgressBar: true,
        });
        dispatch(clearProviderLogin() as TDispatch);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginProviderDispatch?.login, loginProviderDispatch?.error]);

  /**
   * UseEffect para llamar a los dispatch
   */
  useEffect(() => {
    dispatch(clearProviderLogin() as TDispatch);
  }, [dispatch]);

  return (
    <ProviderLoginV
      schema={schema}
      control={control}
      errors={errors}
      register={register}
      goToDashboard={goToDashboard}
      login={login}
      loading={loadingLogin}
      handleSubmit={handleSubmit}
    />
  );
};
