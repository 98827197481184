import 'reflect-metadata';
import { AxiosResponse } from 'axios';
import { RequestEconomicSectors } from '../../../domain/taxInformation/economicSectors/model/requestEconomicSectors';
import { ResponseEconomicSectors } from '../../../domain/taxInformation/economicSectors/model/responseEconomicSectors';
import { IEconomicSectorsInfrastructure } from '../../../domain/interface/infrastructure/taxInformation/IeconomicSectorsInfrastructure';
import { processDataBackend } from '../../../../common/utils/functions';

export class EconomicSectorsInfrastructure implements IEconomicSectorsInfrastructure {
  private a = '';

  public async getEconomicSectorsAPI(
    request: RequestEconomicSectors
  ): Promise<ResponseEconomicSectors[]> {
    this.a = 'aaa';

    const res: AxiosResponse = await processDataBackend(
      'GET',
      '/api/economicSector/getAll',
      {},
      { request }
    );

    const economicSector: ResponseEconomicSectors[] = res.data;
    return Promise.resolve(economicSector);
  }
}
