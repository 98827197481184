import * as yup from 'yup';

const schema = yup
  .object({
    identificationNumber: yup
      .number()
      .required('Número de NIT es requerido')
      .typeError('Este campo sólo puede contener números y una longitud menor a 12')
      .test('is-between-9-and-11-digits', 'NIT debe tener entre 9 y 11 dígitos', (value) => {
        if (typeof value !== 'number') {
          return false;
        }
        const stringValue = String(Math.floor(value));
        const digitCount = stringValue.length;
        return digitCount >= 9 && digitCount <= 11;
      }),
    dv: yup.string(),
    businessName: yup.string().required('Campo requerido'),
    firstName: yup
      .string()
      .required('El primer nombre es requerido')
      .matches(/^[A-Za-zñáéíóúÁÉÍÓÚÑ\s]+$/, 'Sólo se permiten letras'),
    middleName: yup.string().matches(/^[A-Za-zñáéíóúÁÉÍÓÚÑ\s]*$/, 'Sólo se permiten letras'),
    firstSurname: yup
      .string()
      .required('El primer apellido es requerido')
      .matches(/^[A-Za-zñáéíóúÁÉÍÓÚÑ\s]+$/, 'Sólo se permiten letras'),
    secondSurname: yup
      .string()
      .matches(/^[A-Za-zñáéíóúÁÉÍÓÚÑ\s]*$/, 'Sólo se permiten letras')
      .required('Campo requerido'),
    address: yup.object({
      idDepartment: yup.number().required('Campo requerido'),
      idCity: yup.number().required('Campo requerido'),
      neighBorhood: yup
        .string()
        .required('Campo requerido')
        .matches(/^[A-Za-zñáéíóúÁÉÍÓÚÑ\s\d]+$/u, 'Sólo se permiten letras y números'),
      idAddressFirstStreetType: yup.number().required('Campo requerido'),
      idAddressSecondStreetType: yup.number().required('Campo requerido'),
      firstStreetNumber: yup.string().required('Campo requerido'),
      secondStreetNumber: yup.string().required('Campo requerido'),
      thirdStreetNumber: yup.string().required('Campo requerido'),
      description: yup.string().optional(),
    }),
    cellPhone: yup
      .number()
      .required()
      .test('is-between-10-and-10-digits', 'El celular debe tener 10 dígitos', (value) => {
        if (typeof value !== 'number') {
          return false;
        }
        const stringValue = String(Math.floor(value));
        const digitCount = stringValue.length;
        return digitCount >= 10 && digitCount <= 10;
      })
      .typeError('Este campo sólo puede contener números'),
    telephone: yup
      .number()
      .typeError('Este campo sólo puede contener números')
      .nullable()
      .transform((_, val) => (val !== '' ? Number(val) : null)),
    email: yup.string().required('Correo requerido').email('El correo electrónico no es válido'),
  })
  .required();

export default schema;
