import styled from 'styled-components';

export const Logo = styled.img`
  width: 80%;
  height: 80%;
`;
export const WelcomeArea = styled.h1`
  text-align: center;
  color: black;
`;
