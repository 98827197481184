import { ResponseRole } from '../model/responseRole';

export class RoleState {
  public constructor() {
    this.roles = [new ResponseRole()];

    this.roleCreated = new ResponseRole();
  }

  public roles?: ResponseRole[];

  public roleCreated?: ResponseRole;

  public idRole? = '';

  public name? = '';

  public description? = '';
}
