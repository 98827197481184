import { AxiosError } from 'axios';
import { ResponseTaxInfo } from '../../../core/domain/taxInformation/taxInfo/model/responseTaxInfo';
import { TaxInfoState } from '../../../core/domain/taxInformation/taxInfo/state/taxInfo';
import actions from './actions';
import { TTaxInfoReducer } from './types';

const { TAX_INFO_LIST_PAGE_BEGIN, TAX_INFO_PAGE_SUCCESS, TAX_INFO_LIST_PAGE_ERR } = actions;

const initialTaxInfo: TaxInfoState = new TaxInfoState();

const TaxInfoReducer = (
  state = initialTaxInfo,
  action: { type: string; err: AxiosError; data: ResponseTaxInfo[] }
): TTaxInfoReducer => {
  const { type, data, err } = action;
  switch (type) {
    case TAX_INFO_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case TAX_INFO_PAGE_SUCCESS:
      return {
        ...state,
        taxInfo: data,
        loading: false,
      };
    case TAX_INFO_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default TaxInfoReducer;
