import * as yup from 'yup';

const schema = (edit: boolean) =>
  yup
    .object({
      idThirdParty: yup.number().required('Este campo es requerido'),
      typeBeneficiary: yup.string().required('Este campo es requerido'),
      number: yup.string().optional(),
      name: yup.string().required('Este campo es requerido'),
      idCategory: yup
        .number()
        .required('Este campo es requerido')
        .transform((_, val) => (val !== '' ? Number(val) : null)),
      description: yup
        .string()
        .required('Este campo es requerido')
        .max(1000, 'No puedes escribir más de 1000 caracteres'),
      benefitCity: yup.array().of(
        yup
          .object()
          .shape({
            idDepartment: yup.number().required('Campo requerido'),
            idCities: yup
              .array()
              .of(yup.number().required('El perfil es requerido'))
              .min(1, 'Debe seleccionar al menos un perfil')
              .required('El perfil es requerido'),
          })
          .required()
      ),
      benefitValidity: yup.object({
        usagePerson: yup
          .number()
          .typeError('Este campo sólo puede contener números')
          .nullable()
          .transform((_, val) => (val !== '' ? Number(val) : null))
          .required('Este campo es requerido')
          .test('non-zero-check', 'El valor no puede ser cero', function (value) {
            return value !== 0;
          })
          .test(
            'usage-limit-check',
            'Uso por cupón no puede ser mayor que total de beneficios',
            function () {
              const { usagePerson, useLimit } = this.parent;
              if (useLimit === null || useLimit === undefined) {
                return true; // No hay validación si useLimit es nulo o indefinido
              }
              return usagePerson <= useLimit;
            }
          ),

        useLimit: yup
          .number()
          .typeError('Este campo sólo puede contener números')
          .optional()
          .nullable()
          .transform((_, val) => (val !== '' ? Number(val) : null)),
        periodicity: yup
          .mixed()
          .required('Este campo es requerido')
          .transform((_, val) => (val !== '' ? Number(val) : null)),
        startDate: edit
          ? yup.string().optional().nullable().notRequired()
          : yup
              .string()
              .required('Este campo es requerido')
              .test('is-date', 'Fecha inválida', (value) => !Number.isNaN(Date.parse(value)))
              .test(
                'is-today-or-future',
                'La fecha debe ser igual o mayor a la fecha actual',
                (value) => {
                  const partesFecha = value.split('-').map(Number);
                  const [day, month, year] = partesFecha;
                  const inputDate = new Date(day, month - 1, year);
                  const today = new Date();
                  today.setHours(0, 0, 0, 0);
                  return inputDate >= today;
                }
              ),
        endDate: yup
          .string()
          .required('Este campo es requerido')
          .test('is-date', 'Fecha inválida', (value) => !Number.isNaN(Date.parse(value)))
          .test(
            'is-greater',
            'La fecha final no debe ser menor o igual a la fecha inicial',
            (value, context) => {
              const startDates = context.parent.startDate;
              return new Date(startDates) < new Date(value);
            }
          ),
      }),
      path: yup.string().optional(),
      visibility: yup.number().optional(),
    })
    .required();

export default schema;
