import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { GridSelectionModel } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { TDispatch } from '../../../../../common/utils/types.d';
import BenefitsListV from '../../../components/benefits/benefitsList';
import { RootState } from '../../../../../redux/store';
import {
  TBenefitDeleteReducer,
  TBenefitsForExcelReducer,
  TBenefitsReducer,
} from '../../../../../redux/benefits/benefits/types.d';
import {
  benefitDeletePage,
  benefitsForExcelListPage,
  benefitsListPage,
  clearBenefitDeleteListPage,
} from '../../../../../redux/benefits/benefits/actionCreator';
import { TBenefitValidityCreateReducer } from '../../../../../redux/benefits/benefitValidity/types.d';
import { Item, TDataCategorySelected, TDataListBenefits, TSchema } from './types.d';
import {
  benefitValidityCreatePage,
  clearBenefitValidityCreatePage,
} from '../../../../../redux/benefits/benefitValidity/actionCreator';
import { exportToExcel } from '../../../../../common/components/excelGererate';
import { TLoginDispatch } from '../../login/types';
import { constructorName } from '../../../../../common/utils/functions';

export const BenefitsListC = () => {
  /**
   * States
   */
  const [benefitsData, setBenefitsData] = useState<TDataListBenefits[]>([]);
  const [dataId, setDataId] = useState<number>();
  const [checkId, setCheckId] = useState<GridSelectionModel>();
  const [selectionModel, setSelectionModel] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [benefitsDataExcel, setBenefitsDataExcel] = useState<Item[]>([]);

  const navigate = useNavigate();

  /**
   * Constante con array de objetos para asignar un benefitCategory string a benefit con idCategory
   */
  const contentBenefitCategory: TDataCategorySelected[] = [
    {
      idCategorySelect: '2',
      labelCategory: 'RESTAURANTE',
    },
    {
      idCategorySelect: '3',
      labelCategory: 'SALUD Y BELLEZA',
    },
    {
      idCategorySelect: '4',
      labelCategory: 'ENTRETENIMIENTO',
    },
    {
      idCategorySelect: '5',
      labelCategory: 'EDUCACIÓN',
    },
    {
      idCategorySelect: '6',
      labelCategory: 'TURISMO',
    },
    {
      idCategorySelect: '7',
      labelCategory: 'COMPRAS',
    },
  ];

  /*
    dispatch
    */
  const dispatch = useDispatch();

  /**
   * Dispatch de Login
   */
  // const loginDispatch: TLoginDispatch = useSelector((dataState: RootState) => dataState?.login);
  const loginDispatch: TLoginDispatch = useSelector((dataState: RootState) => dataState?.loginData);

  /**
   * Obtener todos los beneficios
   */
  const getAllBenefitsDispatch: TBenefitsReducer = useSelector(
    (dataState: RootState) => dataState?.benefits
  );
  /**
   * Obtener todos los beneficios
   */
  const getAllBenefitsForExcelDispatch: TBenefitsForExcelReducer = useSelector(
    (dataState: RootState) => dataState?.benefitsForExcel
  );
  /**
   * Dispatch de deleteBenefit
   */
  const deleteBenefitDispatch: TBenefitDeleteReducer = useSelector(
    (dataState: RootState) => dataState?.benefitDeleted
  );

  /**
   * Dispatch de crear beneficios
   */
  const createBenefitValidityDispatch: TBenefitValidityCreateReducer = useSelector(
    (dataState: RootState) => dataState?.benefitValidityCreated
  );

  /**
   * Schema de validaciones de campos
   */

  const schema = yup
    .object({
      startDate: yup
        .string()
        .required('Este campo es requerido')
        .test('is-date', 'Fecha inválida', (value) => !Number.isNaN(Date.parse(value))),
      endDate: yup
        .string()
        .required('Este campo es requerido')
        .test('is-date', 'Fecha inválida', (value) => !Number.isNaN(Date.parse(value)))
        .test(
          'is-greater',
          'La fecha final no debe ser menor o igual a la fecha inicial',
          (value, context) => {
            const { startDate } = context.parent;
            return new Date(startDate) < new Date(value);
          }
        ),
    })
    .required();

  /**
   * useForm
   */
  const {
    // register,
    handleSubmit,
    control,
    watch,
    resetField,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  /*
   * Función para retroceder.
   */
  const goBack = () => {
    setTimeout(() => {
      navigate('/beneficios/lista');
    });
  };

  /**
   * Función para editar un registro
   */
  const handleUpdateValidation = handleSubmit(async () => {
    if (watch('startDate') < watch('endDate')) {
      if (!checkId?.length && !selectionModel.length) {
        toast('Es necesario seleccionar un beneficio', {
          type: 'warning',
          hideProgressBar: true,
        });
        setLoading(false);
      } else {
        dispatch(
          benefitValidityCreatePage({
            ...getValues(),
            idsBenefit: checkId,
            consumedBenefit: 2,
            condition: 1,
          }) as TDispatch
        );
        setLoading(true);
      }
    } else {
      toast('La fecha final debe ser mayor a la fecha de inicio', {
        type: 'error',

        hideProgressBar: true,
      });
    }
  });

  /**
   * Función para eliminar un registro
   */
  const handleDelete = async () => {
    dispatch(benefitDeletePage({ idBenefit: Number(dataId) }) as TDispatch);
    setOpenDelete(false);
    setLoading(true);
  };

  /**
   * Función que se encarga de cerrar el modal de confirmación
   */
  const handleClose = () => {
    setOpenDelete(false);
  };
  /**
   * Función que se encarga de abrir el modal de confirmación para poder eliminar un registro
   */
  const buttonDelete = async (datos: number) => {
    setDataId(datos);
    setOpenDelete(true);
  };

  /*
   * Función para asignar un label al idCategory.
   */
  const getItemByNumber = (x: number | undefined) => {
    return contentBenefitCategory.find((item) => item?.idCategorySelect === x?.toString());
  };

  const getItemByCheckbox = (x: GridSelectionModel) => {
    setCheckId(x);
  };

  const handleExportExcel = () => {
    exportToExcel(benefitsDataExcel, 'ListadoDeBeneficios');
    toast.success('Descargado correctamente', {
      hideProgressBar: true,
      autoClose: 1000,
    });
  };

  /**
   * UseEffect que se encarga de mostrar la información en la Lista de Beneficios
   */
  useEffect(() => {
    if (
      getAllBenefitsDispatch?.benefits instanceof Array &&
      (loginDispatch?.login?.userType === 'Administrador' ||
        loginDispatch?.login?.userType === 'Usuario' ||
        loginDispatch?.login?.userType === 'Comercio')
    ) {
      const data = getAllBenefitsDispatch?.benefits?.map((x, i: number) => {
        const benefitCategory = getItemByNumber(x?.idCategory)?.labelCategory;
        const endOfBenefit: Date = new Date(x!.benefitValidity!.endDate! as string);
        const currentDate = new Date();
        return {
          ...x,
          idCategory: benefitCategory,
          isActive: currentDate < endOfBenefit ? 'ACTIVO' : 'EXPIRADO',
          description: x?.description?.toUpperCase(),
          name: x?.name?.toUpperCase(),
          number: x?.number?.toUpperCase(),
        };
      });
      setBenefitsData(data);
    } else if (
      getAllBenefitsDispatch?.benefits instanceof Array &&
      loginDispatch?.login?.idThirdParty !== null
    ) {
      const filteredData = getAllBenefitsDispatch?.benefits
        ?.filter((item) => item?.idThirdParty === loginDispatch?.login?.idThirdParty)
        ?.map((item, i) => {
          const ID = i + 1;
          const getItem = getItemByNumber(item?.idCategory);
          const benefitCategory = getItem?.labelCategory;
          return {
            ...item,
            ID: !item ? null : ID,
            idCategory: benefitCategory,
            a: item?.benefitValidity?.endDate,
          };
        });

      setBenefitsData(filteredData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllBenefitsDispatch?.benefits, loginDispatch]);

  /**
   * UseEffect que se encarga de mostrar la información en el archivo excel
   */
  useEffect(() => {
    if (
      getAllBenefitsForExcelDispatch.benefitsForExcel instanceof Array &&
      loginDispatch?.login?.idThirdParty === null
    ) {
      const data = getAllBenefitsForExcelDispatch.benefitsForExcel?.map((x, i: number) => {
        const ID = i + 1;
        return {
          Item: ID,
          Comercio:
            x?.thirdParty?.idNature === 1
              ? constructorName([
                  x?.thirdParty?.firstName,
                  x?.thirdParty?.middleName,
                  x?.thirdParty?.firstSurname,
                  x?.thirdParty?.secondSurname,
                ]) || undefined
              : x?.thirdParty?.businessName || undefined,
          Código: x?.number,
          Nombre: x?.name,
          Descripción: x?.description,
          Categoría: String(getItemByNumber(x?.idCategory)?.labelCategory),
          'Uso por cupón': x?.benefitValidity?.usagePerson,
          'Número de cupones adquiridos': x?.benefitValidity?.useLimit,
          'Fecha de inicio': x?.benefitValidity?.startDate,
          'Fecha final': x?.benefitValidity?.endDate,
        };
      });
      setBenefitsDataExcel(data);
    } else if (
      getAllBenefitsForExcelDispatch.benefitsForExcel instanceof Array &&
      loginDispatch?.login?.idThirdParty !== null
    ) {
      const filteredDataForExcel = getAllBenefitsForExcelDispatch.benefitsForExcel
        ?.filter((item) => item.idThirdParty === loginDispatch.login?.idThirdParty)
        ?.map((x, i) => {
          const ID = i + 1;
          return {
            Item: ID,
            Código: x?.number,
            Nombre: x?.name,
            Descripción: x?.description,
            Categoría: String(getItemByNumber(x?.idCategory)?.labelCategory),
            'Uso por cupón': x?.benefitValidity?.usagePerson,
            'Número de cupones adquiridos': x?.benefitValidity?.useLimit,
            'Fecha de inicio': x?.benefitValidity?.startDate,
            'Fecha final': x?.benefitValidity?.endDate,
          };
        });

      setBenefitsDataExcel(filteredDataForExcel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllBenefitsForExcelDispatch.benefitsForExcel, loginDispatch?.login?.idThirdParty]);

  /**
   * UseEffect pendiente al momento de actualizar un beneficio existente
   */
  useEffect(() => {
    if (loading) {
      if (
        createBenefitValidityDispatch?.benefitValidityCreated &&
        !createBenefitValidityDispatch?.error
      ) {
        // Itera los campos para asignar resetear el valor por defecto
        const dataForm = Object.keys(getValues()) as TSchema[];
        dataForm?.forEach((x) => resetField(x));
        if (selectionModel && checkId) {
          setSelectionModel([]);
          setCheckId(undefined);
        }
        dispatch(clearBenefitValidityCreatePage() as TDispatch);
        dispatch(benefitsForExcelListPage() as TDispatch);
        dispatch(benefitsListPage() as TDispatch);
        toast.success('Beneficio actualizado exitosamente', {
          hideProgressBar: true,
        });
        goBack();
      } else if (
        !createBenefitValidityDispatch?.benefitValidityCreated &&
        createBenefitValidityDispatch?.error
      ) {
        const message = createBenefitValidityDispatch?.error?.response
          ? createBenefitValidityDispatch?.error?.response?.data?.message
          : 'Ha ocurrido una incidencia';
        toast.error(`${message}`, {
          hideProgressBar: true,
        });
        dispatch(clearBenefitValidityCreatePage() as TDispatch);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createBenefitValidityDispatch?.benefitValidityCreated, createBenefitValidityDispatch?.error]);

  /**
   * UseEffect pendiente al momento de eliminar un beneficio existente
   */
  useEffect(() => {
    if (dataId && loading) {
      if (deleteBenefitDispatch?.benefitDeleted && !deleteBenefitDispatch?.error) {
        toast.success('Beneficio eliminado exitosamente', {
          hideProgressBar: true,
        });
        dispatch(clearBenefitDeleteListPage() as TDispatch);
        dispatch(benefitsForExcelListPage() as TDispatch);
        dispatch(benefitsListPage() as TDispatch);
      } else if (!deleteBenefitDispatch?.benefitDeleted && deleteBenefitDispatch?.error) {
        const message = deleteBenefitDispatch?.error?.response
          ? deleteBenefitDispatch?.error?.response?.data?.message
          : 'Ha ocurrido una incidencia';
        toast.error(`${message}`, {
          hideProgressBar: true,
        });
        dispatch(clearBenefitDeleteListPage() as TDispatch);
      }
    }
  }, [
    deleteBenefitDispatch?.benefitDeleted,
    deleteBenefitDispatch?.error,
    loading,
    dispatch,
    dataId,
  ]);

  /**
   * UseEffect para llamar todos los dispatch
   */
  useEffect(() => {
    dispatch(benefitsListPage() as TDispatch);
    dispatch(benefitsForExcelListPage() as TDispatch);
    dispatch(clearBenefitValidityCreatePage() as TDispatch);
    dispatch(clearBenefitDeleteListPage() as TDispatch);
  }, [dispatch]);

  return (
    <BenefitsListV
      benefitsData={benefitsData}
      getItemByCheckbox={getItemByCheckbox}
      control={control}
      errors={errors}
      schema={schema}
      handleUpdateValidation={handleUpdateValidation}
      handleDelete={handleDelete}
      openDelete={openDelete}
      handleClose={handleClose}
      buttonDelete={buttonDelete}
      deleteLoading={deleteBenefitDispatch?.loading}
      updateLoading={createBenefitValidityDispatch?.loading}
      selectionModel={selectionModel}
      setSelectionModel={setSelectionModel}
      handleExportExcel={handleExportExcel}
      loading={getAllBenefitsDispatch?.loading}
    />
  );
};
