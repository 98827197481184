import React from 'react';
import { Box, CircularProgress, Grid, Typography, useTheme } from '@mui/material';
import { Logo, styles, Form, TextFloating, TramaFloating, ButtonSubmit } from './styled';
import { IPropsLogin } from './types.d';
import pkg from '../../../../../package.json';
import { InputField } from '../../../../common/components/formAdapters';
import image from '../../../../common/components/images';
import { Link } from 'react-router-dom';

export const LoginV: React.FC<IPropsLogin> = ({
  login,
  control,
  schema,
  handleSubmit,
  loading,
}) => {
  const theme = useTheme();
  const LogoBonus = image('logoBonus');
  const tramaFondoLogin = image('tramaFondoLogin');

  return (
    <>
      <Grid container sx={styles.container}>
        <Grid
          flex={0.6}
          item
          sx={{
            ...styles.logoContainer,
            backgroundColor: theme.palette.primary.main,
            // backgroundColor: {theme.palette.primary.main},
            '@media (max-width: 750px)': {
              display: 'none',
            },
          }}>
          <Logo src={LogoBonus} />
          <TramaFloating src={tramaFondoLogin} />
        </Grid>
        <Grid
          sx={styles.formContainer}
          flex={0.4}
          item
          display={{ xs: 'none', sm: 'none', md: 'block' }} // Oculta el grid en dispositivos extra pequeños y pequeños, pero lo muestra en dispositivos medianos y más grandes
        >
          <Form>
            <Typography
              variant="h3"
              sx={{ fontFamily: 'Poppins-Regular', fontWeight: '0.9vw' }}
              color={theme.palette.primary.main}>
              Bienvenido
            </Typography>
            <InputField
              name="email"
              control={control}
              type="email"
              label="Usuario"
              schema={schema.fields.email}
            />
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
              }}>
              <InputField
                iconType="showPasswordEye"
                control={control}
                label="Contraseña"
                type="password"
                name="password"
                schema={schema.fields.password}
              />
              <Box>
                <Link to="/recuperar">
                  <Typography fontSize="11px" color={theme.palette.primary.main}>
                    ¿Olvidó su contraseña?
                  </Typography>
                </Link>
              </Box>
            </Box>

            <ButtonSubmit
              backgroundColor={theme.palette.primary.main}
              hoverStyles={theme.palette.primary.dark}
              disabled={loading}
              type="submit"
              style={{ color: '#fff' }}
              onClick={handleSubmit(login)}>
              {loading ? <CircularProgress size={25} color="inherit" /> : 'Ingresar'}
            </ButtonSubmit>
          </Form>
          <Box
            sx={{
              width: '80%',
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
              opacity: 1,
            }}>
            <Typography fontSize="12px" color={theme.palette.primary.main}>
              Al hacer clic en iniciar, estás aceptando nuestros{' '}
              <span style={{ fontWeight: 'bold' }}>términos y condiciones de servicio</span> y estás
              aceptando nuestras{' '}
              <span style={{ fontWeight: 'bold' }}>políticas de privacidad de datos.</span>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <TextFloating>
        <Typography
          color={theme.palette.primary.main}
          sx={{
            fontSize: '11px',
            padding: '5px',
            width: '100%',
            textAlign: 'center',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}>
          ® WOW Desarrollos Digitales - Bonus plus {pkg.version}
        </Typography>
      </TextFloating>
    </>
  );
};
