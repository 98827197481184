import { AxiosError } from 'axios';
import { actionsSeller, actionsSellerCreate, actionsSellerDelete, actionsSellers, actionsSellerUpdate } from './actions';
import {
  TSellerCreateReducer,
  TSellerDeleteReducer,
  TSellerReducer,
  TSellerUpdateReducer,
  TSellersReducer,
} from './types.d';
import { ResponseSeller } from '../../core/domain/sellers/sellers/model/responseSeller';
import { SellerState } from '../../core/domain/sellers/sellers/state/sellerState';

const { SELLER_CREATE_PAGE_BEGIN, SELLER_CREATE_PAGE_SUCCESS, SELLER_CREATE_PAGE_ERR } =
  actionsSellerCreate;
const { SELLER_LIST_PAGE_BEGIN, SELLER_LIST_PAGE_SUCCESS, SELLER_LIST_PAGE_ERR } =
  actionsSeller;
const { SELLER_UPDATE_PAGE_BEGIN, SELLER_UPDATE_PAGE_SUCCESS, SELLER_UPDATE_PAGE_ERR } =
  actionsSellerUpdate;
const { SELLER_DELETE_PAGE_BEGIN, SELLER_DELETE_PAGE_SUCCESS, SELLER_DELETE_PAGE_ERR } =
  actionsSellerDelete;
const { SELLERS_LIST_PAGE_BEGIN, SELLERS_LIST_PAGE_SUCCESS, SELLERS_LIST_PAGE_ERR } =
  actionsSellers;

const initialSeller: SellerState = new SellerState();

export const SellerCreateReducer = (
  state = initialSeller,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseSeller | undefined;
  }
): TSellerCreateReducer => {
  const { type, data, err } = action;
  switch (type) {
    case SELLER_CREATE_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case SELLER_CREATE_PAGE_SUCCESS:
      return {
        ...state,
        sellerCreated: data,
        loading: false,
      };
    case SELLER_CREATE_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const SellerReducer = (
  state = initialSeller,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseSeller | undefined;
  }
): TSellerReducer => {
  const { type, data, err } = action;
  switch (type) {
    case SELLER_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case SELLER_LIST_PAGE_SUCCESS:
      return {
        ...state,
        seller: data,
        loading: false,
      };
    case SELLER_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const SellerUpdateReducer = (
  state = initialSeller,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseSeller | undefined;
  }
): TSellerUpdateReducer => {
  const { type, data, err } = action;
  switch (type) {
    case SELLER_UPDATE_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case SELLER_UPDATE_PAGE_SUCCESS:
      return {
        ...state,
        sellerUpdated: data,
        loading: false,
      };
    case SELLER_UPDATE_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const SellerDeleteReducer = (
  state = initialSeller,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseSeller | undefined;
  }
): TSellerDeleteReducer => {
  const { type, data, err } = action;
  switch (type) {
    case SELLER_DELETE_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case SELLER_DELETE_PAGE_SUCCESS:
      return {
        ...state,
        sellerDeleted: data,
        loading: false,
      };
    case SELLER_DELETE_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const SellersReducer = (
  state = initialSeller,
  action: { type: string; err: AxiosError | undefined; data: ResponseSeller[] }
): TSellersReducer => {
  const { type, data, err } = action;
  switch (type) {
    case SELLERS_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case SELLERS_LIST_PAGE_SUCCESS:
      return {
        ...state,
        sellers: data,
        loading: false,
      };
    case SELLERS_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};
