import { AxiosError } from 'axios';
import { TConsecutiveReducer } from './types';
import { actionsConsecutive } from './actions';
import { ResponseConsecutive } from '../../core/domain/consecutive/model/responseConsecutive';
import { ConsecutiveState } from '../../core/domain/consecutive/state/consecutiveState';

const { CONSECUTIVE_LIST_PAGE_BEGIN, CONSECUTIVE_LIST_PAGE_SUCCESS, CONSECUTIVE_LIST_PAGE_ERR } =
  actionsConsecutive;

const initialConsecutive: ConsecutiveState = new ConsecutiveState();

export const ConsecutiveReducer = (
  state = initialConsecutive,
  action: {
    type: string;
    err: AxiosError | undefined;
    data?: ResponseConsecutive | undefined;
  }
): TConsecutiveReducer => {
  const { type, data, err } = action;
  switch (type) {
    case CONSECUTIVE_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CONSECUTIVE_LIST_PAGE_SUCCESS:
      return {
        ...state,
        consecutive: data,
        loading: false,
      };
    case CONSECUTIVE_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};
