import { AxiosError } from 'axios';
import { ResponseStreetTypes } from '../../../core/domain/addresses/streetTypes/model/responseStreetTypes';

const actions = {
  STREET_TYPES_LIST_PAGE_BEGIN: 'STREETTyPES_LIST_PAGE_BEGIN',
  STREET_TYPES_LIST_PAGE_SUCCESS: 'STREETTyPES_LIST_PAGE_SUCCESS',
  STREET_TYPES_LIST_PAGE_ERR: 'STREETTyPES_LIST_PAGE_ERR',

  streetTypesListPageBegin: (): { type: string } => {
    return {
      type: actions.STREET_TYPES_LIST_PAGE_BEGIN,
    };
  },

  streetTypesListPageSuccess: (
    data: ResponseStreetTypes[] | ResponseStreetTypes
  ): { type: string; data: ResponseStreetTypes[] | ResponseStreetTypes } => {
    return {
      type: actions.STREET_TYPES_LIST_PAGE_SUCCESS,
      data,
    };
  },

  streetTypesListPageErr: (err: AxiosError): { type: string; err: AxiosError } => {
    return {
      type: actions.STREET_TYPES_LIST_PAGE_ERR,
      err,
    };
  },
};

export default actions;
