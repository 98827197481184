import React, { ChangeEvent, useState } from 'react';
import { SelectChangeEvent, TextField, TextFieldProps, Tooltip, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { styled, useTheme } from '@mui/material/styles';
import Yup from 'yup';

const CustomTextField = styled(TextField)(({ theme: any }) => ({
  '& .MuiInputLabel-root': {
    fontSize: '0.9em',
  },
  '& .MuiInputBase-input': {
    fontSize: '0.9em',
    paddingBottom: '3px',
  },
  '& .MuiInputBase-root': {
    height: '35px',
    paddingBottom: '3px',
  },
}));

// @mui/material TextField adapter component for react-hook-form and yup validation schema
export const InputAdapter = (
  props: TextFieldProps & {
    name: string;
    control: Control;
    schema: Yup.AnySchema;
    error?: string;
    onBlur?: any;
    customOnChange?: any;
    hideHelperText?: boolean;
    showPasswordEye?: boolean;
    iconType?: string;
    hideToolType?: string;
    type?: string;
    mb?: string | number;
    functionIcon?: () => void;
  }
) => {
  const {
    name,
    control,
    schema,
    disabled,
    defaultValue,
    hideHelperText,
    customOnChange,
    iconType,
    onBlur,
    functionIcon,
    type,
    mb,
    ...rest
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    field: { onChange, value, ref },
    fieldState: { invalid, error: err },
  } = useController({
    name,
    control,
    rules: {
      validate: (val) => schema.validate(val).catch((error) => error.message),
    },
    defaultValue: '',
  });

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<any>
  ) => {
    onChange(e);
    if (customOnChange) customOnChange(e);
  };
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const iconSelector = [
    {
      type: 'showPasswordEye',
      render: (
        <Tooltip
          title={showPassword ? 'ocultar contraseña' : 'mostrar contraseña'}
          arrow
          placement="right">
          <IconButton
            color={err ? 'error' : 'default'}
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}>
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </Tooltip>
      ),
    },
    {
      type: 'search',
      render: (
        <Tooltip title="Buscar beneficio">
          <IconButton onClick={functionIcon}>
            <SearchIcon fontSize="medium" sx={{ color: theme.palette.primary.main }} />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return (
    <CustomTextField
      {...rest}
      name={name}
      inputRef={ref}
      InputLabelProps={{
        shrink: true,
      }}
      disabled={disabled}
      value={defaultValue || value}
      onChange={(e) => (customOnChange ? handleChange(e) : !disabled && onChange(e))}
      onBlur={onBlur}
      size="small"
      error={invalid}
      type={showPassword ? 'text' : type}
      helperText={!hideHelperText && t(err?.message || '')}
      sx={{ width: '100%' }}
      InputProps={{
        endAdornment: iconSelector?.find((x) => x?.type === iconType)?.render,
      }}
    />
  );
};
