import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorIcon from '@mui/icons-material/Error';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { BugBox, MenuOptionLink } from './styled';
import { TDataWithErrors, TErrorDetailBox } from './types.d';

export const ErrorDetailBoxV: FC<TErrorDetailBox> = ({ children, bulkResponse }) => {
  const theme = useTheme();

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [dataResume, setDataResume] = useState({
    errorRows: 0,
    successRows: 0,
    errorsTotal: 0,
  });

  const label = { inputProps: { 'aria-label': 'Size switch demo' } };

  const handleChange = (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const styles = {
    title: {
      fontWeight: 'bold',
      fontSize: '1rem',
      textAlign: 'left',
      marginTop: '2px',
      color: 'red',
    },
    titleWarning: {
      fontWeight: 'bold',
      fontSize: '1rem',
      textAlign: 'left',
      marginTop: '2px',
      color: 'red',
    },
    titleErrors: {
      fontWeight: 'bold',
      fontSize: '0.9em',
      textAlign: 'left',
      marginTop: '2px',
      color: 'red',
    },
    subTitle: {
      fontWeight: 'regular',
      fontSize: '1em',
      textAlign: 'left',
      width: '100%',
      color: '#fff',
    },
    fieldColumn: {
      fontWeight: 'bold',
      fontSize: '1em',
      textAlign: 'left',
      width: '25%',
      color: 'red',
      margin: '2px 5px',
      padding: '0 5px',
    },
    messageColumn: {
      fontWeight: 'regular',
      fontSize: '0.9em',
      textAlign: 'left',
      width: '75%',
      padding: '0 5px',
      margin: '2px 5px',
    },
    icon: {
      marginRight: '10px',
      fontSize: '24px',
    },
  };

  const ToggleChange = () => {
    setShowSuccess(!showSuccess);
  };

  useEffect(() => {
    let rowsWithError = 0;
    let totalColumnsWithError = 0;
    let rowsWithOutError = 0;
    if (bulkResponse) {
      bulkResponse.forEach((x) => {
        if (x?.errors?.length) {
          rowsWithError += 1;
          totalColumnsWithError += x.errors.length;
        } else {
          rowsWithOutError += 1;
        }
      });
    }

    setDataResume({
      errorRows: rowsWithError,
      errorsTotal: totalColumnsWithError,
      successRows: rowsWithOutError,
    });
    if (totalColumnsWithError === 0 && rowsWithOutError > 0) setShowSuccess(true);
    if (rowsWithOutError === 0 && totalColumnsWithError > 0) setShowSuccess(false);
  }, [bulkResponse]);

  return (
    <>
      {dataResume?.errorsTotal > 0 && dataResume?.successRows > 0 && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '24px',
            width: '100%',
            justifyContent: 'flex-end',
            alignItems: 'center',
            rowGap: '10px',
            margin: '10px 0px 10px',
          }}>
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: '1em',
              marginTop: '2px',
              color: '#6e7781',
              margin: '0px 10px ',
            }}>
            Ver {showSuccess ? 'errores encontrados' : 'registros exitosos'}:
          </Typography>
          <Tooltip placement="left" title="Activar">
            <Switch checked={showSuccess} size="small" onClick={() => ToggleChange()} {...label} />
          </Tooltip>
        </div>
      )}
      <BugBox
        style={{
          margin: dataResume?.errorsTotal > 0 && dataResume?.successRows ? '0px' : ' 0px',
        }}>
        {!!bulkResponse?.length && (
          <MenuOptionLink>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center',
                '@media screen and (max-width: 1060px)': {
                  flexDirection: 'column',
                },
              }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {showSuccess ? (
                  <CheckBoxIcon sx={{ color: '#25b346', marginRight: '5px' }} />
                ) : (
                  <ErrorIcon sx={{ color: 'red', marginRight: '5px' }} />
                )}
                <Typography
                  sx={{
                    ...styles.titleWarning,
                    color: showSuccess ? '#25b346' : 'red',
                  }}>
                  {showSuccess ? 'Datos correctos' : 'Por favor verifique la información'}
                </Typography>
              </Box>
              <Typography
                sx={{
                  ...styles.title,
                  color: showSuccess ? '#25b346' : 'red',
                }}>
                Filas:
                {showSuccess ? dataResume?.successRows : dataResume.errorRows}
                &nbsp;
                {dataResume?.errorRows > 0 && !showSuccess && `Total: ${dataResume.errorsTotal}`}
              </Typography>
            </Box>
          </MenuOptionLink>
        )}
        {!bulkResponse?.length && dataResume?.errorsTotal > 0 && (
          <MenuOptionLink>
            <ErrorIcon sx={{ color: 'red', marginRight: '5px' }} />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}>
              <Typography sx={styles.title}>Por favor verifique la información</Typography>
              <Typography sx={styles.title}>
                Filas: {dataResume.errorRows} &nbsp; Total:
                {dataResume.errorsTotal}
              </Typography>
            </div>
          </MenuOptionLink>
        )}
        {!!bulkResponse?.length && showSuccess
          ? bulkResponse
              ?.filter((x: TDataWithErrors) => !x?.errors?.length)
              ?.map((x: TDataWithErrors, i: number) => {
                const key = `key${i}`;
                return (
                  <Accordion
                    key={key}
                    expanded={expanded === `panel${i}`}
                    onChange={handleChange(`panel${i}`)}
                    disableGutters
                    sx={{
                      width: '100%',
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: '0px',
                      marginBottom: '5px',
                    }}>
                    <AccordionSummary
                      aria-controls={`panel${i}bh-content`}
                      sx={{
                        height: '30px',
                        minHeight: '25px',
                        borderRadius: '5px',
                      }}
                      id={`panel${i}bh-header`}>
                      <Typography sx={styles.subTitle}>
                        {x?.customIndex ? `${x?.customIndex} ` : <b>Fila N° {x.rowIndex} - </b>}
                        Errores encontrados:
                        {x?.errors?.length}
                      </Typography>
                    </AccordionSummary>
                  </Accordion>
                );
              })
          : bulkResponse
              ?.filter((x: TDataWithErrors) => x?.errors?.length)
              ?.map((x: TDataWithErrors, i: number) => {
                const key = `key${i}`;
                return (
                  <Accordion
                    key={key}
                    expanded={expanded === `panel${i}`}
                    onChange={handleChange(`panel${i}`)}
                    disableGutters
                    sx={{
                      width: '100%',
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: '0px',
                      marginBottom: '5px',
                    }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                      aria-controls={`panel${i}bh-content`}
                      sx={{
                        height: '30px',
                        minHeight: '25px',
                        borderRadius: '5px',
                      }}
                      id={`panel${i}bh-header`}>
                      <Typography sx={styles.subTitle}>
                        {x?.customIndex ? `${x?.customIndex} ` : <b>Fila N° {x.rowIndex} - </b>}
                        Errores encontrados:
                        {x?.errors?.length}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        padding: '0',
                        backgroundColor: '#fff',
                        borderRadius: '5px',
                      }}>
                      {x?.errors?.map((y, j: number) => {
                        const secondKey = `secondKey${j}`;
                        return (
                          <div
                            key={secondKey}
                            style={{
                              display: 'flex',
                            }}>
                            <Typography sx={styles.fieldColumn}>
                              <b>{y?.fieldName}:</b>
                            </Typography>
                            <Typography sx={styles.messageColumn}>{y?.errorMessage}</Typography>
                          </div>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
        {!bulkResponse?.length &&
          bulkResponse?.map((x: TDataWithErrors, i: number) => {
            const key = `key${i}`;
            return x?.errors?.length ? (
              <Accordion
                key={key}
                expanded={expanded === `panel${i}`}
                onChange={handleChange(`panel${i}`)}
                disableGutters
                sx={{
                  width: '100%',
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: '0px',
                  marginBottom: '5px',
                }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                  aria-controls={`panel${i}bh-content`}
                  sx={{
                    height: '30px',
                    minHeight: '25px',
                    borderRadius: '5px',
                  }}
                  id={`panel${i}bh-header`}>
                  <Typography sx={styles.subTitle}>
                    {x?.customIndex ? x?.customIndex : <b>Fila N° {x.rowIndex} - </b>}
                    Errores encontrados:
                    {x?.errors?.length}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    padding: '0',
                    backgroundColor: '#fff',
                    borderRadius: '5px',
                  }}>
                  {x?.errors?.map((y, j: number) => {
                    const secondKey = `secondKey${j}`;
                    return (
                      <div
                        key={secondKey}
                        style={{
                          display: 'flex',
                        }}>
                        <Typography sx={styles.fieldColumn}>
                          <b>{y?.fieldName}:</b>
                        </Typography>
                        <Typography sx={styles.messageColumn}>{y?.errorMessage}</Typography>
                      </div>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            ) : (
              ''
            );
          })}
        {children}
      </BugBox>
    </>
  );
};
