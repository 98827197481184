import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { exportToExcel } from '../../../../../common/components/excelGererate';
import { constructorName } from '../../../../../common/utils/functions';
import { TDispatch } from '../../../../../common/utils/types.d';
import { getAllByMembershipListPage } from '../../../../../redux/benefits/benefits/actionCreator';
import { TBenefitsByMembershipReducer } from '../../../../../redux/benefits/benefits/types.d';
import { RootState } from '../../../../../redux/store';
import BenefitsByProvidersReportV from '../../../components/benefits/providersReport';
import { TLoginDispatch } from '../../login/types';
import { IBenefitsByMembershipMap } from './types.d';
import { ResponseBenefit } from '../../../../domain/benefits/benefits/model/responseBenefit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

export const BenefitsByProvidersReportC = () => {
  /**
   * state
   */
  const [benefitsByMembershipData, setBenefitsByMembershipData] = useState<
    IBenefitsByMembershipMap[]
  >([]);

  /**
   * dispatch
   */
  const dispatch = useDispatch();

  const schema = yup
    .object({
      startDate: yup
        .string()
        .required('Este campo es requerido')
        .test('is-date', 'Fecha inválida', (value) => !Number.isNaN(Date.parse(value))),
      endDate: yup
        .string()
        .required('Este campo es requerido')
        .test('is-date', 'Fecha inválida', (value) => !Number.isNaN(Date.parse(value)))
        .test(
          'is-greater',
          'La fecha final no debe ser menor o igual a la fecha inicial',
          (value, context) => {
            const { startDate } = context.parent;
            return new Date(startDate) < new Date(value);
          }
        ),
    })
    .required();

  /**
   * Dispatch de todos los beneficios por cada membresía
   */
  const getBenefitsByMembership: TBenefitsByMembershipReducer = useSelector(
    (dataState: RootState) => dataState?.benefitsByMembership
  );

  /**
   *
   */
  const loginDispatch: TLoginDispatch = useSelector((dataState: RootState) => dataState?.loginData);

  /**
   * useForm
   */
  const { /* reset, */ /* setValue, */ /* getValues, */ handleSubmit, control, watch } = useForm({
    resolver: yupResolver(schema),
  });

  /**
   * Función para buscar un beneficio del proveedor y su membresía por el rango de fecha
   */
  const handleSearch = handleSubmit(() => {
    dispatch(
      getAllByMembershipListPage({
        idThirdParty: loginDispatch?.login?.idThirdParty,
        startDate: watch('startDate'),
        endDate: watch('endDate'),
      }) as TDispatch
    );
  });

  const handleExportExcel = () => {
    const processBenefits = benefitsByMembershipData?.map((item) => {
      return {
        'NOMBRE DEL BENEFICIO': item?.benefitName?.toUpperCase(),
        'NOMBRE DE LA MEMBRESÍA': item?.membershipName?.toUpperCase(),
        'BENEFICIOS CONSUMIDOS': item?.consumedByMembership,
        ESTATUS: item?.state,
        DESDE: item?.startDate,
        HASTA: item?.endDate,
      };
    });
    exportToExcel(processBenefits, 'ListadoDeBeneficios');
    toast.success('Descargado correctamente', {
      hideProgressBar: true,
      autoClose: 1000,
    });
  };

  /**
   * UseEffect que muestra la informacion de sucursales en la lista
   */
  useEffect(() => {
    if (
      getBenefitsByMembership?.benefitsByMembership instanceof Array &&
      getBenefitsByMembership?.benefitsByMembership
    ) {
      const data = (getBenefitsByMembership?.benefitsByMembership || [])?.reduce(
        (acum: ResponseBenefit[], item: ResponseBenefit) => {
          return [
            ...acum,
            ...(item?.benefitBeneficiaries?.reduce(
              (acumBeneficiaries: ResponseBenefit[], itemBeneficiary) => {
                const benefitBeneficiaryFound = acumBeneficiaries?.find(
                  (x) =>
                    x?.beneficiary?.thirdPartyBeneficiary?.thirdParty?.idThirdParty ===
                    itemBeneficiary?.beneficiary?.thirdPartyBeneficiary?.thirdParty?.idThirdParty
                );

                if (benefitBeneficiaryFound) {
                  return acumBeneficiaries?.map((y: any) => {
                    if (benefitBeneficiaryFound)
                      return {
                        ...itemBeneficiary,
                        ID: Math.random(),
                        benefitName: item?.name?.toUpperCase(),
                        membershipName:
                          y?.beneficiary?.thirdPartyBeneficiary?.thirdParty?.idNature === 2
                            ? y?.beneficiary?.thirdPartyBeneficiary?.thirdParty?.businessName?.toUpperCase()
                            : constructorName([
                                y?.beneficiary?.thirdPartyBeneficiary?.thirdParty?.firstName,
                                y?.beneficiary?.thirdPartyBeneficiary?.thirdParty?.middleName,
                                y?.beneficiary?.thirdPartyBeneficiary?.thirdParty?.firstSurname,
                                y?.beneficiary?.thirdPartyBeneficiary?.thirdParty?.secondSurname,
                              ])?.toUpperCase(),
                        consumedByMembership:
                          Number(y?.consumedByMembership) + Number(itemBeneficiary?.consumed),
                        state: itemBeneficiary?.state === 1 ? 'ACTIVO' : 'INACTIVO',
                      };

                    return y;
                  });
                }

                return [
                  ...acumBeneficiaries,
                  {
                    ...itemBeneficiary,
                    ID: Math.random(),
                    benefitName: item?.name?.toUpperCase(),
                    membershipName:
                      itemBeneficiary?.beneficiary?.thirdPartyBeneficiary?.thirdParty?.idNature ===
                      2
                        ? itemBeneficiary?.beneficiary?.thirdPartyBeneficiary?.thirdParty?.businessName?.toUpperCase()
                        : constructorName([
                            itemBeneficiary?.beneficiary?.thirdPartyBeneficiary?.thirdParty
                              ?.firstName!,
                            itemBeneficiary?.beneficiary?.thirdPartyBeneficiary?.thirdParty
                              ?.middleName!,
                            itemBeneficiary?.beneficiary?.thirdPartyBeneficiary?.thirdParty
                              ?.firstSurname!,
                            itemBeneficiary?.beneficiary?.thirdPartyBeneficiary?.thirdParty
                              ?.secondSurname!,
                          ])?.toUpperCase(),
                    consumedByMembership: Number(itemBeneficiary?.consumed),
                    state: item?.state === 1 ? 'ACTIVO' : 'INACTIVO',
                  },
                ];
              },
              []
            ) || []),
          ];
        },
        []
      );
      // console.log(data, 123);

      setBenefitsByMembershipData(data);
    }
  }, [getBenefitsByMembership?.benefitsByMembership]);

  /**
   * UseEffect para llamar todos los dispatch
   */
  useEffect(() => {
    dispatch(
      getAllByMembershipListPage({
        idThirdParty: loginDispatch?.login?.idThirdParty,
      }) as TDispatch
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <BenefitsByProvidersReportV
      control={control}
      schema={schema}
      data={benefitsByMembershipData}
      handleSearch={handleSearch}
      handleExportExcel={handleExportExcel}
    />
  );
};
