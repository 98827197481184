import styled from 'styled-components';
import { TStyle } from './types.d';
import image from '../../../../../common/components/images';

const tramaFondoLogin = image('tramaFondoLogin');
interface ButtonProps{
  backgroundColor?: string;
  hoverStyles?: string;
}

export const styles: TStyle = {
  container: {
    width: '100vw',
    height: '100vh',
    '&::before': {
      content: '""',
      backgroundImage: `url(${tramaFondoLogin})`,
      opacity: 0.1,
      position: 'absolute', // Lo posiciona con respecto al elemento padre
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  logoSendEmail: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f5f4f4',
    texAlign: 'center',
    width: '650px',
    height: '513px',
    borderRadius: '5px',
  },
  formSendEmail: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f5f4f4',
    width: '600px',
    height: '350px',
    borderRadius: '5px',
  },
};

export const Logo = styled.img`
  width: 245px;
  height: 124px;
  opacity: 1;
  @media (max-width: 997px) {
    max-width: 330px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background-color: #f5f4f4;
  width: 75%;
  height: 75%;
  @media (max-height: 69%) {
    gap: 15px;
  }
`;
export const FormSendEmail = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  background-color: #f5f4f4;
  width: 75%;
  height: 75%;
  @media (max-height: 69%) {
    gap: 15px;
  }
`;

export const TextFloating = styled.span`
  position: fixed;
  bottom: 10px;
  z-index: 1600;
  right: 5px;
  width: 300px;
  color: #fff;
`;
export const TramaFloating = styled.img`
  position: fixed;
  bottom: 0px;
  z-index: 1600;
  left: 0px;
  width: 60%;
  opacity: 0.06;
`;
export const ButtonSubmit = styled.button<ButtonProps>`
  width: 148px;
  height: 35px;
  background-color: ${props => props.backgroundColor || '#243EBD'};
  &:hover {
    background-color: ${props => props.hoverStyles || '#062A57'};
  }
  color: white;
  justify-content: center;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;
export const ButtonToBack = styled.button`
  display: flex;
  width: 100px;
  margin-top: 10px;
  justify-content: center;
  background-color: transparent;
  align-items: center;
  border: none;
  cursor: pointer;
`;
