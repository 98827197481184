import AWS, { S3 } from 'aws-sdk';
import moment from 'moment';

export const calculateCheckDigit = (value: number): string => {
  // variables necesarias
  let nit = `${value}`;
  if (nit?.split('')?.length >= 11) return '';
  const vpri: Array<number> = [1, 3, 7, 13, 17, 19, 23, 29, 37, 41, 43, 47, 53, 59, 67, 71];
  // Se limpia el Nit
  nit = nit.replace(/\s/g, ''); // Espacios
  nit = nit.replace(/,/g, ''); // Comas
  nit = nit.replace(/\./g, ''); // Puntos
  nit = nit.replace(/-/g, ''); // Guiones
  // Se valida el nit
  if (!Number(nit)) return '';
  // Procedimiento
  let x = 0;
  let i = 0;
  let y = 0;
  const z = nit?.length;
  for (i; i < z; i += 1) {
    y = Number(nit.substr(i, 1));
    x += y * vpri[z - i];
  }
  y = x % 11;
  if (y > 1 ? 11 - y : y) {
    return String(y > 1 ? 11 - y : y);
  }
  return '';
};

export const formatDate = (date: Date | string, format: string) =>
  moment(date).utcOffset(+300).format(format);

export const Submodule = [
  { name: 'Parámetros', value: 1 },
  { name: 'Proceso', value: 2 },
  { name: 'Reportes', value: 3 },
];

/**
 * Unidad en letra
 * @version 0.0.1
 * @param {number} value numero
 * @return {string} numero el letra
 */
const Unidades = (value: number): string => {
  switch (value) {
    case 1:
      return 'UN';
    case 2:
      return 'DOS';
    case 3:
      return 'TRES';
    case 4:
      return 'CUATRO';
    case 5:
      return 'CINCO';
    case 6:
      return 'SEIS';
    case 7:
      return 'SIETE';
    case 8:
      return 'OCHO';
    case 9:
      return 'NUEVE';
    default:
      return '';
  }
};

/**
 * Decena en letra
 * @version 0.0.1
 * @param {string} strSin numero en letra
 * @param {string} numUnit numero en letras
 * @return {string} concatena al cantidad
 */
const DecenasY = (strSin: string, numUnit: string): string => {
  if (parseInt(numUnit, 10) > 0) return `${strSin} Y ${Unidades(parseInt(numUnit, 10))}`;
  return strSin;
};

/**
 * Decena en letra
 * @version 0.0.1
 * @param {number} value numero
 * @return {string} cantidad en letra
 */
const Decenas = (value: number): string => {
  const ten = Math.floor(value / 10);
  const Unit = value - ten * 10;

  switch (ten) {
    case 1:
      switch (Unit) {
        case 0:
          return 'DIEZ';
        case 1:
          return 'ONCE';
        case 2:
          return 'DOCE';
        case 3:
          return 'TRECE';
        case 4:
          return 'CATORCE';
        case 5:
          return 'QUINCE';
        default:
          return `DIECI${Unidades(Unit)}`;
      }
    case 2:
      switch (Unit) {
        case 0:
          return 'VEINTE';
        default:
          return `VEITI${Unidades(Unit)}`;
      }
    case 3:
      return DecenasY('TREINTA', String(Unit));
    case 4:
      return DecenasY('CUARENTA', String(Unit));
    case 5:
      return DecenasY('CINCUENTA', String(Unit));
    case 6:
      return DecenasY('SESENTA', String(Unit));
    case 7:
      return DecenasY('SETENTA', String(Unit));
    case 8:
      return DecenasY('OCHENTA', String(Unit));
    case 9:
      return DecenasY('NOVENTA', String(Unit));
    case 0:
      return Unidades(Unit);
    default:
      return '';
  }
};

/**
 * Centenas en letra
 * @version 0.0.1
 * @param {number} value numero
 * @return {string} cantidad en letra
 */
const Centenas = (value: number): string => {
  const hundreds = Math.floor(value / 100);
  const tens = value - hundreds * 100;

  switch (hundreds) {
    case 1:
      if (tens > 0) return `CIENTO${Decenas(tens)}`;
      return 'CIEN';
    case 2:
      return `DOSCIENTOS ${Decenas(tens)}`;
    case 3:
      return `TRESCIENTOS ${Decenas(tens)}`;
    case 4:
      return `CUATROCIENTOS ${Decenas(tens)}`;
    case 5:
      return `QUINIENTOS ${Decenas(tens)}`;
    case 6:
      return `SEISCIENTOS ${Decenas(tens)}`;
    case 7:
      return `SETECIENTOS ${Decenas(tens)}`;
    case 8:
      return `OCHOCIENTOS ${Decenas(tens)}`;
    case 9:
      return `NOVECIENTOS ${Decenas(tens)}`;
    default:
      return Decenas(tens);
  }
};

/**
 * Sección en letra
 * @version 0.0.1
 * @param {number} value numero del valor
 * @param {number} divider numero de division
 * @param {string} strSingular numero en letras
 * @param {string} strPlural numero en letras
 * @return {string} cantidad en letra
 */
const Seccion = (
  value: number,
  divider: number,
  strSingular: string,
  strPlural: string
): string => {
  const hundreds = Math.floor(value / divider);
  const rest = value - hundreds * divider;
  let letters = '';

  if (hundreds > 0) {
    if (hundreds > 1) letters = `${Centenas(hundreds)} ${strPlural}`;
    else letters = strSingular;
  }

  if (rest > 0) letters += '';

  return letters;
};

/**
 * Miles en letra
 * @version 0.0.1
 * @param {number} value numero del valor
 * @return {string} cantidad en letra
 */
const Miles = (value: number): string => {
  const divider = 1000;
  const hundreds = Math.floor(value / divider);
  const rest = value - hundreds * divider;
  const strThousands = Seccion(value, divider, 'UN MIL', 'MIL');
  const strHundreds = Centenas(rest);

  if (strThousands === '') return strHundreds;

  return `${strThousands} ${strHundreds}`;
};

/**
 * Millones en letra
 * @version 0.0.1
 * @param {number} value numero del valor
 * @return {string} cantidad en letra
 */
const Millones = (value: number): string => {
  const divider = 1000000;
  const hundreds = Math.floor(value / divider);
  const rest = value - hundreds * divider;
  const strMillions = Seccion(value, divider, 'UN MILLÓN ', 'MILLONES ');
  const strThousands = Miles(rest);

  if (strMillions === '') return strThousands;

  return `${strMillions} ${strThousands}`;
};

/**
 * Formato de transformar numero a Letras
 * @version 0.0.1
 * @param {number} value numero del valor
 * @param {number} format activar formato de pesos
 * @return {string} cantidad en letra
 */
export const NumeroALetras = (value: number, format = false): string => {
  const data = {
    number: value,
    integers: Math.floor(value),
    letterPennies: '',
    letterCoinPlural: format ? '' : 'PESOS COLOMBIANOS',
    letterCoinSingular: format ? '' : 'PESO COLOMBIANO',
    letterCoinPenniesPlural: 'CENTAVOS',
    letterCoinPennySingular: 'CENTAVO',
    pennies: Math.round(value * 100) - Math.floor(value) * 100,
  };

  if (data.pennies > 0) {
    data.letterPennies = `CON ${(() => {
      if (data.pennies === 1) {
        return `${Millones(data.pennies)} ${data.letterCoinPennySingular}`;
      }
      return `${Millones(data.pennies)} ${data.letterCoinPenniesPlural}`;
    })()}`;
  }

  if (data.integers === 0) {
    return `CERO ${data.letterCoinPlural} ${data.letterPennies}`;
  }
  if (data.integers === 1) {
    return `${Millones(data.integers)} ${data.letterCoinSingular} ${data.letterPennies}`;
  }
  return `${Millones(data.integers)} ${data.letterCoinPlural} ${data.letterPennies}`;
};

export const checkLifeInsurance = (value: number): boolean => value === 1;

export const validateAllLifeInsurance = (lifeInsurance: any): boolean => {
  const keys = Object?.keys(lifeInsurance || {})?.filter((x) => lifeInsurance[x] === 1);
  return !!keys.length;
};

/**
 * Se conecta a Aws3
 * @version 0.0.1
 * @return {boolean} la conexión
 */
export const AWS3 = (): S3 => {
  return new AWS.S3({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_AWS,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY_AWS,
  });
};

export const putImgS3 = (Key: string, Body: ArrayBuffer | boolean): void => {
  AWS3().upload({ Bucket: 'btbonus', Key: `benefits/${Key}`, Body }, (response) => response);
};

/**
 * Transforma una cadena de texto en minúsculas.
 * @param value
 * @returns String
 */
export const lowercase = (value: string) => `${value || ''}`.toLowerCase();
/**
 * Busca un documento en S3
 * @param {String} Key variable de búsqueda
 * @return {String} url del documento
 */
export const getFileUrlS3 = (Key: string): string => {
  return AWS3().getSignedUrl('getObject', {
    Bucket: 'btbonus',
    Key,
  });
};
