import styled from 'styled-components';

interface BackgroundProps {
  backgroundColor?: string;
}
export const Img = styled.img`
  height: 200px;
  width: 375px;
  border-radius: 5px;
  margin-top: 15px;
  min-width: 300px;
  @media screen and (max-width: 375px) {
    width: 100%;
  }
  @media screen and (max-width: 320px) {
    width: 100%;
  }
`;

export const ButtonSubmit = styled.button<BackgroundProps>`
  width: 77px;
  height: 32px;
  display: flex;
  background: ${(props) => props.backgroundColor || '#243EBD'};
  justify-content: center;
  align-items: center;
  display: flex;
  border: none;
  border-radius: 5px;
  border-radius: 23px;
  cursor: pointer;
`;
