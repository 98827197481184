import { ResponseTaxInfo } from '../model/responseTaxInfo';

export class TaxInfoState {
  public constructor() {
    this.taxInfo = [new ResponseTaxInfo()];
  }

  public name = '';

  public taxInfo: ResponseTaxInfo[];
}
