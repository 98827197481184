import 'reflect-metadata';
import { AxiosResponse } from 'axios';
import { IGenderInfrastructure } from '../../../domain/interface/infrastructure/documentsPersons/IGenderInfrastructure';
import { RequestGender } from '../../../domain/documentsPersons/gender/model/requestGender';
import { ResponseGender } from '../../../domain/documentsPersons/gender/model/responseGender';
import { processDataBackend } from '../../../../common/utils/functions';

export class GenderInfrastructure implements IGenderInfrastructure {
  private a = '';

  public async getGenderAPI(request: RequestGender): Promise<ResponseGender[]> {
    this.a = 'aaa';

    const res: AxiosResponse = await processDataBackend(
      'GET',
      '/api/gender/getAll',
      {},
      { request }
    );

    const gender: ResponseGender[] = res.data;

    return Promise.resolve(gender);
  }
}
