export class RequestBenefitValidity {
  public idBenefitValidity? = 0;

  public idBenefit? = [];

  public usagePerson? = 0;

  public consumedBenefit? = 0;

  public acquiredBenefit? = 0;

  public useLimit? = 0;

  public periodicity? = 0;

  public purchase? = 0;

  public startDate = '';

  public endDate = '';

  public condition? = 0;
}
