import 'reflect-metadata';
import { container } from 'tsyringe';
import { IStreetTypesApplication } from '../../../domain/interface/application/addresses/IStreetTypesApplication';
import { IStreetTypesInfrastructure } from '../../../domain/interface/infrastructure/addresses/IStreetTypesInfrastructure';
import { RequestStreetTypes } from '../../../domain/addresses/streetTypes/model/requestStreetTypes';
import { ResponseStreetTypes } from '../../../domain/addresses/streetTypes/model/responseStreetTypes';

export class StreetTypesApplication implements IStreetTypesApplication {
  private streetTypesInfrastructure: IStreetTypesInfrastructure;

  public constructor() {
    this.streetTypesInfrastructure = container.resolve<IStreetTypesInfrastructure>(
      'IStreetTypesInfrastructure'
    );
  }

  public async getStreetTypes(request: RequestStreetTypes): Promise<ResponseStreetTypes[]> {
    const result = await this.streetTypesInfrastructure.getStreetTypesAPI(request);
    return result;
  }
}
