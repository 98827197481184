import { AxiosError } from 'axios';
import { ResponseEconomicSectors } from '../../../core/domain/taxInformation/economicSectors/model/responseEconomicSectors';
import { EconomicSectorsState } from '../../../core/domain/taxInformation/economicSectors/state/economicSectors';
import actions from './actions';
import { TEconomicSectorsReducer } from './types';

const {
  ECONOMIC_SECTORS_LIST_PAGE_BEGIN,
  ECONOMIC_SECTORS_PAGE_SUCCESS,
  ECONOMIC_SECTORS_LIST_PAGE_ERR,
} = actions;

const initialEconomicSectors: EconomicSectorsState = new EconomicSectorsState();

const EconomicSectorsReducer = (
  state = initialEconomicSectors,
  action: { type: string; err: AxiosError; data: ResponseEconomicSectors[] }
): TEconomicSectorsReducer => {
  const { type, data, err } = action;
  switch (type) {
    case ECONOMIC_SECTORS_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ECONOMIC_SECTORS_PAGE_SUCCESS:
      return {
        ...state,
        economicSectors: data,
        loading: false,
      };
    case ECONOMIC_SECTORS_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default EconomicSectorsReducer;
