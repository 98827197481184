import { AxiosError } from 'axios';
import { ResponseAcademicLevels } from '../../../core/domain/documentsPersons/academicLevels/model/responseAcademicLevels';
import { AcademicLevelsState } from '../../../core/domain/documentsPersons/academicLevels/state/academicLevelsState';
import actions from './actions';
import { TAcademicLevelsReducer } from './types';

const {
  ACADEMIC_LEVELS_LIST_PAGE_BEGIN,
  ACADEMIC_LEVELS_LIST_PAGE_SUCCESS,
  ACADEMIC_LEVELS_LIST_PAGE_ERR,
} = actions;

const initialAcademicLevels: AcademicLevelsState = new AcademicLevelsState();

const AcademicLevelsReducer = (
  state = initialAcademicLevels,
  action: { type: string; err: AxiosError; data: ResponseAcademicLevels[] }
): TAcademicLevelsReducer => {
  const { type, data, err } = action;
  switch (type) {
    case ACADEMIC_LEVELS_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ACADEMIC_LEVELS_LIST_PAGE_SUCCESS:
      return {
        ...state,
        academicLevels: data,
        loading: false,
      };
    case ACADEMIC_LEVELS_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default AcademicLevelsReducer;
