import { AxiosError } from 'axios';
import { ResponseUserSession } from '../../../core/domain/userPermissions/userSession/model/responseUserSession';
import { UserSessionState } from '../../../core/domain/userPermissions/userSession/state/userSessionState';
import { TDeleteUserSessionReducer, TUserSessionsReducer } from './types';
import { actionsDeleteUserSession, actionsValidateUserSession } from './actions';

const { VALIDATE_USER_SESSION_BEGIN, VALIDATE_USER_SESSION_SUCCESS, VALIDATE_USER_SESSION_ERR } =
  actionsValidateUserSession;

const {
  DELETE_USER_SESSION_BEGIN,
  DELETE_USER_SESSION_SUCCESS,
  DELETE_USER_SESSION_ERR,
  DELETE_USER_SESSION_CLEAR,
} = actionsDeleteUserSession;
const initialUserSessions: UserSessionState = new UserSessionState();

const UserSessionsReducer = (
  state = initialUserSessions,
  action: { type: string; err?: AxiosError; data?: ResponseUserSession }
): TUserSessionsReducer => {
  const { type, data, err } = action;
  switch (type) {
    case VALIDATE_USER_SESSION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VALIDATE_USER_SESSION_SUCCESS:
      return {
        ...state,
        sessionValidated: data,
        loading: false,
      };
    case VALIDATE_USER_SESSION_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const DeleteUserSessionReducer = (
  state = initialUserSessions,
  action: { type: string; err?: AxiosError; data?: ResponseUserSession }
): TDeleteUserSessionReducer => {
  const { type, data, err } = action;
  switch (type) {
    case DELETE_USER_SESSION_BEGIN:
      return {
        ...state,
        loading: true,
        called: false,
      };
    case DELETE_USER_SESSION_SUCCESS:
      return {
        ...state,
        sessionDeleted: data,
        loading: false,
        called: true,
      };
    case DELETE_USER_SESSION_ERR:
      return {
        ...state,
        error: err,
        loading: false,
        called: true,
      };
    case DELETE_USER_SESSION_CLEAR:
      return {
        error: err,
        sessionDeleted: data,
        loading: false,
        called: false,
      };

    default:
      return state;
  }
};

export { UserSessionsReducer, DeleteUserSessionReducer };
