import 'reflect-metadata';
import { container } from 'tsyringe';
import { AxiosError } from 'axios';
import { AppDispatch } from '../../store';
import { RequestUserType } from '../../../core/domain/userPermissions/userType/model/requestUserType';
import { IUserTypeApplication } from '../../../core/domain/interface/application/userPermissions/IUserTypeApplication';
import { actionsUserTypes } from './actions';

const { userTypeListPageBegin, userTypeListPageSuccess, userTypeListPageErr } = actionsUserTypes;
const userTypeListPage = (): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(userTypeListPageBegin());
      const userTypeApplication = container.resolve<IUserTypeApplication>('IUserTypeApplication');

      const request = new RequestUserType();
      const result = await userTypeApplication.getUserType(request);

      dispatch(userTypeListPageSuccess(result));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(userTypeListPageErr(error));
    }
  };
};

export { userTypeListPage };
