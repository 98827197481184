import { AxiosError } from 'axios';
import { ResponseUserPermission } from '../../../core/domain/userPermissions/userPermission/model/responseUserPermission';
import { UserPermissionState } from '../../../core/domain/userPermissions/userPermission/state/conventionState';
import { TUserPermissionsReducer } from './types';
import actions from './actions';

const {
  USER_PERMISSION_LIST_PAGE_BEGIN,
  USER_PERMISSION_PAGE_SUCCESS,
  USER_PERMISSION_LIST_PAGE_ERR,
} = actions;
const initialUserPermissions: UserPermissionState = new UserPermissionState();

const UserPermissionsReducer = (
  state = initialUserPermissions,
  action: { type: string; err: AxiosError; data: ResponseUserPermission[] }
): TUserPermissionsReducer => {
  const { type, data, err } = action;
  switch (type) {
    case USER_PERMISSION_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USER_PERMISSION_PAGE_SUCCESS:
      return {
        ...state,
        userPermissions: data,
        loading: false,
      };
    case USER_PERMISSION_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default UserPermissionsReducer;
