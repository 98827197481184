import { RequestThirdPartiesBeneficiaries } from '../../thirdParties/thirdPartiesBeneficiaries/model/thirdPartiesBeneficiariesRequest';

export class RequestBeneficiaries {
  public idBeneficiary? = 0;

  public idDocumentType? = 0;

  public idGender?: number | null = null;

  public identificationNumber? = 0;

  public firstName?: string | null | undefined = '';

  public middleName?: string | null | undefined = '';

  public firstSurname?: string | null | undefined = '';

  public secondSurname?: string | null | undefined = '';

  public email?: string | null | undefined = '';

  public cellPhone?: string | null = '' || null;

  public telephone?: string | null = '' || null;

  public token? = '';

  public qrCode? = '';

  public thirdPartyBeneficiary? = new RequestThirdPartiesBeneficiaries();

  public rowIndex? = 0;
}
