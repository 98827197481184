import { ResponseEconomicSectors } from '../model/responseEconomicSectors';

export class EconomicSectorsState {
  public constructor() {
    this.economicSectors = [new ResponseEconomicSectors()];
  }

  public name = '';

  public economicSectors: ResponseEconomicSectors[];
}
