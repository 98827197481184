import React, { useEffect, useState } from 'react';
import { ProfileInformationV } from '../../components/profileInformation';
import schema from './yupSchema';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  clearUpdateThirdPartyCustomerPage,
  clearUpdateThirdPartyProviderPage,
  getThirdPartyCustomerByIdListPage,
  getThirdPartyProviderByIdListPage,
  updateThirdPartyCustomerPage,
  updateThirdPartyProviderPage,
} from '../../../../redux/thirdParties/actionCreator';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { TLoginDispatch } from '../login/types';
import { RootState } from '../../../../redux/store';
import { TDispatch, TodoSuccessResponse } from '../../../../common/utils/types';
import {
  TThirdPartyCustomerReducer,
  TThirdPartyProviderReducer,
  TUpdateThirdPartyCustomerReducer,
  TUpdateThirdPartyProviderReducer,
} from '../../../../redux/thirdParties/types';
import { calculateCheckDigit } from '../../../../lib';
import { filterKeyObject } from '../../../../common/utils/functions';
import { toast } from 'react-toastify';
import { departmentsListPage } from '../../../../redux/addresses/departments/actionCreator';
import { citiesListPage } from '../../../../redux/addresses/cities/actionCreator';
import { streetTypesListPage } from '../../../../redux/addresses/streetTypes/actionCreator';
import { genderListPage } from '../../../../redux/documentsPersons/gender/actionCreator';
import { TCities, TDataDepartments, TStreetTypes } from '../couponsPage/types';
import { TDepartmentsReducer } from '../../../../redux/addresses/departments/types';
import { TCitiesReducer } from '../../../../redux/addresses/cities/types';
import { TStreetTypesReducer } from '../../../../redux/addresses/streetTypes/types';
import { TSellersReducer } from '../../../../redux/sellers/types';
import { TSellers } from '../membership/legalMembership/type';

export const ProfileInformationC = () => {
  /**
   * STATE
   */
  const [dataDepartments, setDataDepartments] = useState<TDataDepartments>([]);
  const [dataCities, setDataCities] = useState<TCities>([]);
  const [dataStreetTypes, setDataStreetTypes] = useState<TStreetTypes>([]);
  const [dataSellers, setDataSellers] = useState<TSellers>([]);
  const [disable, setDisable] = useState<boolean>(false);

  /**
   * DISPATCH
   */
  const dispatch = useDispatch();
  /**
   * DISPATCH DE LOGIN
   */
  const loginDispatch: TLoginDispatch = useSelector((dataState: RootState) => dataState?.loginData);

  /**
   * DISPATCH DE OBTENER COMERCIO DE TERCEROS POR ID
   */
  const getThirdPartyProviderByIdDispatch: TThirdPartyProviderReducer = useSelector(
    (dataState: RootState) => dataState?.thirdPartyProvider
  );

  /**
   * DISPATCH DE OBTENER MEMBRESIA DE TERCEROS PORT ID
   */
  const getThirdPartyCustomerByIdDispatch: TThirdPartyCustomerReducer = useSelector(
    (dataState: RootState) => dataState?.thirdPartyCustomer
  );

  /**
   * Dispatch de actualizar comercio de terceros
   */
  const updateThirdPartyProviderDispatch: TUpdateThirdPartyProviderReducer = useSelector(
    (dataState: RootState) => dataState?.thirdPartyProviderUpdated
  );

  /**
   * DATA DE ACTUALIZACIÓN DE TERCEROS
   */
  const updateThirdPartyCustomerDispatch: TUpdateThirdPartyCustomerReducer = useSelector(
    (dataState: RootState) => dataState?.thirdPartyCustomerUpdated
  );

  /**
   * DATA DEPARTAMENTO
   */
  const departmentsDispatch: TDepartmentsReducer = useSelector(
    (dataState: RootState) => dataState?.departments
  );
  /**
   * DATA DE CIUDAD
   */
  const citiesDispatch: TCitiesReducer = useSelector((dataState: RootState) => dataState?.cities);

  /**
   * DATA DE VENDEDOR
   */
  const sellersDispatch: TSellersReducer = useSelector(
    (dataState: RootState) => dataState?.sellers
  );

  /**
   * DATA DE TIPO DE CALLES
   */
  const streetTypesDispatch: TStreetTypesReducer = useSelector(
    (dataState: RootState) => dataState?.streetTypes
  );

  // ID DE TERCEROS
  const idThirdParty = loginDispatch?.login?.idThirdParty;

  // USE FORM
  const { reset, handleSubmit, control, watch } = useForm({
    resolver: yupResolver(schema),
  });

  // ARMANDO DATA
  const getValuesData = (data: any, type: number) => {
    return {
      dv: calculateCheckDigit(data?.identificationNumber),
      identificationNumber: data?.identificationNumber,
      businessName: data?.businessName,
      firstName: data?.firstName,
      middleName: data?.middleName,
      firstSurname: data?.firstSurname,
      secondSurname: data?.secondSurname,
      address: {
        ...data.address,
        idDepartment: data?.address?.idDepartment,
        idCity: data?.address?.idCity,
        neighBorhood: data?.address?.neighBorhood,
        firstStreetNumber: data?.address?.firstStreetNumber,
        idAddressFirstStreetType: data?.address?.idAddressFirstStreetType,
        idAddressSecondStreetType: data?.address?.idAddressSecondStreetType,
        secondStreetNumber: data?.address?.secondStreetNumber,
        thirdStreetNumber: data?.address?.thirdStreetNumber,
        description: data?.address?.description ? data?.address?.description : '',
      },
      customerValidity: type && {
        ...data?.customerValidity,
        idSeller: data?.customerValidity?.idSeller,
        acquiredBeneficiary:
          data?.customerValidity?.acquiredBeneficiary !== 0
            ? data?.customerValidity?.acquiredBeneficiary
            : undefined,
        consumedBeneficiary:
          data?.customerValidity?.consumedBeneficiary !== 0
            ? data?.customerValidity?.consumedBeneficiary
            : undefined,
        startDate: data?.customerValidity?.startDate,
        endDate: data?.customerValidity?.endDate,
        amountPaid:
          data?.customerValidity?.amountPaid !== 0 ? data?.customerValidity?.amountPaid : undefined,
        detail: data?.customerValidity?.detail,
      },
      cellPhone: data?.cellPhone,
      telephone: data?.telephone !== 0 ? data?.telephone : null,
      email: data?.email,
    };
  };

  /**
   * FUNCIÓN QUE SAE ENCARGA DE ACTUALIZAR UN COMERCIO JURÍDICO
   */
  const handleUpdate = handleSubmit(async (data) => {
    dispatch(
      loginDispatch?.login?.userType === 'Membresia'
        ? (updateThirdPartyCustomerPage({
            ...filterKeyObject(data, [
              'idDocumentType',
              'identificationNumber',
              'idGender',
              'customerValidity',
            ]),
            idThirdPartyType: 2,
            idNature: 1,
            idThirdParty,
          }) as TDispatch)
        : loginDispatch?.login?.userType === 'Comercio' &&
            (updateThirdPartyProviderPage({
              ...filterKeyObject(data, [
                'identificationNumber',
                'businessName',
                'customerValidity',
              ]),
              idDocumentType: 4,
              idThirdPartyType: 1,
              idNature: 2,
              idThirdParty,
              telephone: Number(data?.telephone),
            }) as TDispatch)
    );
    setDisable(true);
  });

  /**
   * ARMANDO DATA DEPARTAMENTO
   */
  useEffect(() => {
    setDataDepartments(departmentsDispatch?.departments || []);
  }, [departmentsDispatch?.departments]);

  /**
   * ARMANDO DATA CIUDADES
   */
  useEffect(() => {
    setDataCities(citiesDispatch?.cities || []);
  }, [citiesDispatch?.cities]);

  /**
   * ARMANDO DATA StreetTypes
   */
  useEffect(() => {
    setDataStreetTypes(streetTypesDispatch?.streetTypes || []);
  }, [streetTypesDispatch?.streetTypes]);

  /**
   * LLAMADO DATA DE VENDEDOR
   */
  useEffect(() => {
    setDataSellers(sellersDispatch?.sellers || []);
  }, [sellersDispatch?.sellers]);

  /**
   * MOSTRAR LA INFORMACIÓN CORRESPONDIENTE EN LOS CAMPOS MEDIANTE EL ID
   */
  useEffect(() => {
    if (idThirdParty && loginDispatch?.login?.userType === 'Comercio') {
      if (getThirdPartyProviderByIdDispatch?.thirdPartyProvider?.idThirdParty) {
        const data = getThirdPartyProviderByIdDispatch?.thirdPartyProvider;
        if (data?.idNature === 2) {
          reset(getValuesData(data, 1));
        }
      }
    } else if (idThirdParty && loginDispatch?.login?.userType === 'Membresia') {
      if (getThirdPartyCustomerByIdDispatch?.thirdPartyCustomer?.idThirdParty) {
        const data = getThirdPartyCustomerByIdDispatch?.thirdPartyCustomer;
        reset(getValuesData(data, 2));
      }
    }
  }, [
    getThirdPartyProviderByIdDispatch,
    getThirdPartyCustomerByIdDispatch,
    reset,
    idThirdParty,
    loginDispatch,
  ]);

  /**
   * COMERCIO ACTUALIZADO
   */
  useEffect(() => {
    if (idThirdParty && disable) {
      if (
        updateThirdPartyProviderDispatch?.thirdPartyProviderUpdated &&
        !updateThirdPartyProviderDispatch?.error
      ) {
        toast('Comercio actualizado exitosamente.', {
          type: 'success',
          hideProgressBar: true,
        });
        dispatch(clearUpdateThirdPartyCustomerPage() as TDispatch);
        setDisable(false);
      } else if (
        !updateThirdPartyProviderDispatch?.thirdPartyProviderUpdated &&
        updateThirdPartyProviderDispatch?.error
      ) {
        const message = updateThirdPartyProviderDispatch?.error?.response
          ? (updateThirdPartyProviderDispatch?.error?.response?.data as TodoSuccessResponse)
              ?.message
          : 'Ha ocurrido una incidencia.';
        toast(`${message}`, {
          type: 'error',
          hideProgressBar: true,
        });
        dispatch(clearUpdateThirdPartyCustomerPage() as TDispatch);
        setDisable(false);
      }
    }
  }, [updateThirdPartyProviderDispatch, idThirdParty, dispatch, disable]);

  /**
   * MEMBRESIA ACTUALIZADO
   */
  useEffect(() => {
    if (idThirdParty && disable) {
      if (
        updateThirdPartyCustomerDispatch?.thirdPartyCustomerUpdated &&
        !updateThirdPartyCustomerDispatch?.error
      ) {
        toast('Membresia actualizado exitosamente.', {
          type: 'success',
          hideProgressBar: true,
        });
        dispatch(clearUpdateThirdPartyCustomerPage() as TDispatch);
        setDisable(false);
      } else if (
        !updateThirdPartyCustomerDispatch?.thirdPartyCustomerUpdated &&
        updateThirdPartyCustomerDispatch?.error
      ) {
        const message = updateThirdPartyCustomerDispatch?.error?.response
          ? (updateThirdPartyCustomerDispatch?.error?.response?.data as TodoSuccessResponse)
              ?.message
          : 'Ha ocurrido una incidencia';
        toast(`${message}`, {
          type: 'error',
          hideProgressBar: true,
        });
        dispatch(clearUpdateThirdPartyCustomerPage() as TDispatch);
        setDisable(false);
      }
    }
  }, [updateThirdPartyCustomerDispatch, idThirdParty, dispatch, disable]);

  /**
   * LIMPIANDO DATA
   */
  useEffect(() => {
    if (loginDispatch?.login?.userType === 'Comercio') {
      dispatch(getThirdPartyProviderByIdListPage({ idThirdParty }) as TDispatch);
    } else if (loginDispatch?.login?.userType === 'Membresia') {
      dispatch(getThirdPartyCustomerByIdListPage({ idThirdParty }) as TDispatch);
    } else {
      reset({});
    }
  }, [idThirdParty, dispatch, reset, loginDispatch]);

  useEffect(() => {
    dispatch(clearUpdateThirdPartyProviderPage() as TDispatch);
    dispatch(clearUpdateThirdPartyCustomerPage() as TDispatch);
    dispatch(departmentsListPage() as TDispatch);
    dispatch(citiesListPage() as TDispatch);
    dispatch(streetTypesListPage() as TDispatch);
    dispatch(genderListPage() as TDispatch);
  }, [dispatch]);

  return (
    <ProfileInformationV
      control={control}
      schema={schema}
      dataDepartments={dataDepartments}
      dataCities={dataCities}
      dataStreetTypes={dataStreetTypes}
      dataSellers={dataSellers}
      handleUpdate={handleUpdate}
      updateLoading={
        updateThirdPartyProviderDispatch?.loading || updateThirdPartyCustomerDispatch?.loading
      }
      watchDepartment={watch('address.idDepartment')}
      watchIdStreetTypeOne={watch('address.idAddressFirstStreetType')}
      type={loginDispatch?.login?.userType}
    />
  );
};
