import React, { FC } from 'react';
import {
  Box,
  // IconButton,
  Typography,
  Grid,
  useTheme,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { ButtonSubmit } from './styled';
import { InputField, NormalSelectField } from '../../../../../common/components/formAdapters';
import { TUserRegister } from './types.d';
import { onlyLetters, onlyNumbers } from '../../../../../common/utils/functions';
import { TitleComponent } from '../../../../../common/components/titles/styled';

export const UserRegisterV: FC<TUserRegister> = ({
  schema,
  control,
  createLoading,
  updateLoading,
  valueSelectUserType,
  dataUserTypes,
  thirdPartyProvider,
  dataDocumentTypes,
  handleRegister,
  handleShowSelected,
  idUser,
  handleUpdate,
  membership,
}) => {
  const theme = useTheme();
  const styles = {
    cardUser: {
      width: '180px',
      height: '50px',
      display: 'flex',
      padding: '5px',
      justifyContent: 'center',
      border: `1px solid ${theme.palette.primary.main}`,
      alignItems: 'center',
      borderRadius: '5px',
    },
    formBox: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      background: '#fff',
      borderRadius: '16px',
      boxShadow: '0px 3px 6px #00000029',
      minWidth: '305px',
    },
    upperCardUser: {
      width: '100%',
      display: 'flex',
      '@media screen and (max-width: 899px)': {
        display: 'flex',
        justifyContent: 'center',
      },
      marginTop: '20px',
      gap: '10px',
      flexWrap: 'wrap',
    },
    buttonBox: {
      display: 'flex',
      justifyContent: 'flex-end',
      '@media screen and (max-width: 899px)': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
  };

  return (
    <Box
      style={{
        width: '100%',
      }}>
      <Box sx={styles.formBox}>
        <Box
          sx={{
            padding: '25px',
            height: '100%',
            width: '100%',
          }}>
          <div style={{ width: '100%', display: 'flex' }}>
            <TitleComponent>{!idUser ? 'Registro de usuarios' : 'Editar usuario'}</TitleComponent>
          </div>
          <Typography variant="h5" color={theme.palette.primary.main} sx={{ pb: '15px' }}>
            Información del usuario
          </Typography>
          <Box>
            <Grid container spacing={{ xs: 2, sm: 2, md: 2 }} sx={{ marginBottom: '10px' }}>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <NormalSelectField
                  name="idDocumentType"
                  label="Tipo de identificación"
                  key="idDocumentType"
                  schema={schema?.fields?.idDocumentType}
                  control={control}
                  options={
                    (dataDocumentTypes || [])?.map((x) => ({
                      value: x?.idDocumentType,
                      label: x?.name,
                    })) || []
                  }
                  disabled={!!idUser}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <InputField
                  onKeyDown={onlyNumbers}
                  label="Número de identificación"
                  name="identificationNumber"
                  schema={schema.fields?.identificationNumber}
                  control={control}
                  disabled={!!idUser}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <InputField
                  onKeyDown={onlyLetters}
                  label="Nombre"
                  name="name"
                  schema={schema.fields?.name}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <InputField
                  onKeyDown={onlyLetters}
                  label="Apellido"
                  name="lastName"
                  schema={schema.fields?.lastName}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <InputField
                  label="Correo electrónico"
                  type="email"
                  name="email"
                  schema={schema.fields?.email}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <InputField
                  label="Celular"
                  name="cellPhone"
                  schema={schema.fields?.cellPhone}
                  control={control}
                  onKeyDown={onlyNumbers}
                />
              </Grid>
            </Grid>
            <Box>
              <Typography
                variant="h5"
                color={theme.palette.primary.main}
                sx={{ marginBottom: '10px' }}>
                Tipos de usuarios
              </Typography>
              <Grid container spacing={{ xs: 2, sm: 2, md: 2 }} sx={{ marginBottom: '10px' }}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <NormalSelectField
                    name="userType"
                    label="Tipo de Usuario"
                    key="idUserType"
                    value={dataUserTypes}
                    customOnChange={handleShowSelected}
                    schema={schema?.fields?.userType}
                    control={control}
                    options={
                      (dataUserTypes || [])?.map((x) => ({
                        value: x?.idUserType,
                        label: x?.name,
                      })) || []
                    }
                  />
                </Grid>
              </Grid>
            </Box>
            {valueSelectUserType !== 0 && (
              <Box key={Math.random()}>
                {(valueSelectUserType === 2 || valueSelectUserType === 3) && (
                  <Typography
                    variant="h5"
                    color={theme.palette.primary.main}
                    sx={{ pb: '15px', marginTop: '20px' }}>
                    {(valueSelectUserType === 3 && 'Comercio asociado') ||
                      (valueSelectUserType === 2 && 'Membresía asociada')}
                  </Typography>
                )}
                {(valueSelectUserType === 2 || valueSelectUserType === 3) && (
                  <Grid container spacing={{ xs: 2, sm: 2, md: 2 }} sx={{ marginBottom: '10px' }}>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                      {valueSelectUserType === 3 && (
                        <NormalSelectField
                          name="idThirdParty"
                          label="Comercio"
                          key="idThirdParty"
                          schema={schema?.fields?.idThirdParty}
                          control={control}
                          options={
                            (thirdPartyProvider || [])?.map((x) => ({
                              value: x?.idThirdParty,
                              label: x?.businessName || `${x?.firstName} ${x?.firstSurname}`,
                            })) || []
                          }
                        />
                      )}
                      {valueSelectUserType === 2 && (
                        <NormalSelectField
                          name="idThirdParty"
                          label="Membresía"
                          key="idThirdParty"
                          schema={schema?.fields?.idThirdParty}
                          control={control}
                          options={
                            (membership || [])?.map((x) => ({
                              value: x?.idThirdParty,
                              label: x?.businessName || `${x?.firstName} ${x?.firstSurname}`,
                            })) || []
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                )}
              </Box>
            )}

            <Grid item xs={12} sm={12} md={6} lg={4} xl={3} sx={styles.buttonBox}>
              <Tooltip title={!idUser ? 'Registrar usuario' : 'Actualizar usuario'}>
                <ButtonSubmit
                  disabled={createLoading || updateLoading}
                  type="button"
                  onClick={idUser ? handleUpdate : handleRegister}
                  backgroundColor={theme.palette.primary.main}
                  hoverStyles={theme.palette.primary.dark}>
                  {createLoading || updateLoading ? (
                    <CircularProgress size={15} sx={{ color: 'white' }} />
                  ) : (
                    <SaveIcon />
                  )}
                  <Typography fontSize="14px">{!idUser ? 'CREAR' : 'ACTUALIZAR'}</Typography>
                </ButtonSubmit>
              </Tooltip>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
