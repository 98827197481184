import 'reflect-metadata';
import { container } from 'tsyringe';
import { AxiosError } from 'axios';
import { RequestGender } from '../../../core/domain/documentsPersons/gender/model/requestGender';
import { IGenderApplication } from '../../../core/domain/interface/application/documentsPersons/IGenderApplication';
import { AppDispatch } from '../../store';
import actions from './actions';

const { genderListPageBegin, genderListPageSuccess, genderListPageErr } = actions;

const genderListPage = (): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(genderListPageBegin());
      const genderApplication = container.resolve<IGenderApplication>('IGenderApplication');

      const request = new RequestGender();
      const result = await genderApplication.getGender(request);

      dispatch(genderListPageSuccess(result));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(genderListPageErr(error));
    }
  };
};

export { genderListPage };
