import styled from 'styled-components';

export const BugBox = styled.div`
  list-style: none;
  overflow-y: auto;
  scroll-behavior: smooth;
  width: 100%;
  height: 280px;
  background-color: #2a3042;
  border-radius: 5px;
  color: #ff6d6d;
  font-weight: bold;
  transition: 0.1s;
`;

type TMenuOptionLink = {
  width?: string;
  height?: string;
  bgColor?: string;
  isOpen?: boolean;
  widthCollapsed?: string;
  widthExpanded?: string;
};

export const Root = styled.span`
  font-size: 0;
  position: relative;
  float: right;
  width: 40px;
  height: 24px;
  cursor: pointer;
  .track {
    background-color: #6e7781;
    border-radius: 16px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  .thumb {
    display: block;
    width: 16px;
    height: 16px;
    top: 4px;
    left: 4px;
    border-radius: 16px;
    background-color: #fff;
    position: relative;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
  }

  &:focus-visible .thumb {
    background-color: #8c959f;
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.checked {
    .thumb {
      left: 20px;
      top: 4px;
      background-color: #fff;
    }

    .track {
      background-color: #319f86;
    }
  }

  .input {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
`;

export const MenuOptionLink = styled.div<TMenuOptionLink>`
  width: ${({ isOpen, widthCollapsed, widthExpanded }) =>
    isOpen ? widthExpanded : widthCollapsed};
  display: flex;
  height: 30px;
  padding: 2px 5px;
  border-radius: 5px;
  background: #fff;
  margin: 0 0 5px 0;
  @media (max-width: 1060px) {
    flex-direction: column;
    height: auto;
  }
  /* :hover {
        background: ${({ bgColor }) => bgColor || '#eee'};
    } */
`;
