import React, { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { filterKeyObject } from '../../../../common/utils/functions';
import { OpenBeneficiaryRegisterFormV } from '../../components/couponsPage/OpenBeneficiaryRegisterForm';
import schema from './yupSchemaRegisterBeneficiary';
import { TDataDocumentType } from '../user/userRegister/types.d';
import { RootState } from '../../../../redux/store';
import {
  clearBeneficiaryPage,
  clearUpdateBeneficiaryPage,
  getBeneficiaryByIdListPage,
  updateBeneficiaryPage,
} from '../../../../redux/beneficiaries/actionCreator';
import { TCities, TDataDepartments, TGender, TStreetTypes } from './types.d';
import { TDispatch } from '../../../../common/utils/types.d';
import { citiesListPage } from '../../../../redux/addresses/cities/actionCreator';
import { genderListPage } from '../../../../redux/documentsPersons/gender/actionCreator';
import { departmentsListPage } from '../../../../redux/addresses/departments/actionCreator';
import { streetTypesListPage } from '../../../../redux/addresses/streetTypes/actionCreator';
import { documentsTypesListPage } from '../../../../redux/documentsPersons/documentsTypes/actionCreator';
import { TCitiesReducer } from '../../../../redux/addresses/cities/types';
import { TGenderReducer } from '../../../../redux/documentsPersons/gender/types';
import { TDocumentTypesReducer } from '../../../../redux/documentsPersons/documentsTypes/types.d';
import {
  TBeneficiaryReducer,
  TUpdateBeneficiaryReducer,
} from '../../../../redux/beneficiaries/types.d';
import { TDepartmentsReducer } from '../../../../redux/addresses/departments/types';
import { TStreetTypesReducer } from '../../../../redux/addresses/streetTypes/types';
import { dataReset } from './data';

export const OpenBeneficiaryRegisterFormC = () => {
  /**
   * navigate
   */
  const navigate = useNavigate();

  /*
    dispatch
    */
  const dispatch = useDispatch();

  /*
    useSearchParams
    */

  /*
    variables globales
    */

  /**
   * UseForm
   */
  const { control, reset, getValues, handleSubmit, watch } = useForm({
    resolver: yupResolver(schema),
  });

  /**
   * Watch del departamento seleccionado
   */
  const watchDepartment = watch('address.idDepartment');
  /**
   * Watch del tipo de dirección seleccionado
   */
  const watchIdStreetTypeOne = watch('address.idAddressFirstStreetType');

  /**
   * identificationNumber
   */
  const identificationNumber = watch('identificationNumber');

  /**
   * States
   */
  const [dataDepartments, setDataDepartments] = useState<TDataDepartments>([]);
  const [dataStreetTypes, setDataStreetTypes] = useState<TStreetTypes>([]);
  const [dataCities, setDataCities] = useState<TCities>([]);
  const [dataGender, setDataGender] = useState<TGender>([]);
  const [dataDocumentType, setDataDocumentType] = useState<TDataDocumentType>([]);
  const [loadingData, setLoadingData] = useState<boolean>(true);

  /**
   * Dispatch de actualizar beneficiario
   */
  const updateBeneficiaryDispatch: TUpdateBeneficiaryReducer = useSelector(
    (dataState: RootState) => dataState?.beneficiaryUpdated
  );

  /**
   * Dispatch de tipos de documentos
   */
  const userDocumentTypeDispatch: TDocumentTypesReducer = useSelector(
    (dataState: RootState) => dataState?.documentsTypes
  );

  /**
   * Dispatch de beneficiario
   */
  const getBeneficiaryByIdDispatch: TBeneficiaryReducer = useSelector(
    (dataState: RootState) => dataState?.beneficiary
  );

  /**
   * Dispatch de ciudades
   */
  const citiesDispatch: TCitiesReducer = useSelector((dataState: RootState) => dataState?.cities);

  /**
   * Dispatch de género
   */
  const genderDispatch: TGenderReducer = useSelector((dataState: RootState) => dataState?.gender);

  /**
   * Dispatch de departamentos
   */
  const departmentsDispatch: TDepartmentsReducer = useSelector(
    (dataState: RootState) => dataState?.departments
  );

  /**
   * Dispatch de tipo de calles
   */
  const streetTypesDispatch: TStreetTypesReducer = useSelector(
    (dataState: RootState) => dataState?.streetTypes
  );

  /**
   * Functions
   */

  /**
   * Función que se encarga de actualizar un beneficiario
   */
  const handleUpdate = handleSubmit(async () => {
    dispatch(
      updateBeneficiaryPage({
        ...filterKeyObject(getValues(), []),
        identificationNumber,
        // idBeneficiary: getBeneficiaryByIdDispatch?.beneficiary?.idBeneficiary,
        telephone: watch('telephone') ? String(watch('telephone')) : null,
      }) as TDispatch
    );
  });

  /**
   * UseEffect para llamar documentTypes
   */
  useEffect(() => {
    setDataDocumentType(
      userDocumentTypeDispatch?.documentsTypes?.filter((item) => item?.idDocumentType !== 4) || []
    );
  }, [userDocumentTypeDispatch?.documentsTypes]);

  /**
   * UseEffect para llamar a Departments
   */
  useEffect(() => {
    setDataDepartments(departmentsDispatch?.departments || []);
  }, [departmentsDispatch?.departments]);

  /**
   * UseEffect para llamar a Cities
   */
  useEffect(() => {
    setDataCities(citiesDispatch?.cities || []);
  }, [citiesDispatch?.cities]);

  /**
   * UseEffect para llamar a StreetTypes
   */
  useEffect(() => {
    setDataStreetTypes(streetTypesDispatch?.streetTypes || []);
  }, [streetTypesDispatch?.streetTypes]);

  /**
   * UseEffect para llamar a Genders
   */
  useEffect(() => {
    setDataGender(genderDispatch?.gender || []);
  }, [genderDispatch?.gender]);

  const callBeneficiary = () => {
    if (identificationNumber) {
      dispatch(clearBeneficiaryPage() as TDispatch);
      dispatch(getBeneficiaryByIdListPage({ identificationNumber }) as TDispatch);
    } else {
      dispatch(clearBeneficiaryPage() as TDispatch);
      reset(dataReset);
    }
  };

  /**
   * UseEffect que desbloquea el formulario si se encuentra un usuario
   */
  useEffect(() => {
    if (!getBeneficiaryByIdDispatch.loading) {
      if (getBeneficiaryByIdDispatch?.beneficiary) {
        setLoadingData(false);
        toast('Beneficiario encontrado exitosamente.', {
          type: 'success',
          hideProgressBar: true,
        });
      } else if (getBeneficiaryByIdDispatch.error) {
        setLoadingData(true);
        toast('Beneficiario no encontrado.', {
          type: 'error',
          hideProgressBar: true,
        });
      } else {
        toast.dismiss();
        setLoadingData(true);
      }
    }
  }, [getBeneficiaryByIdDispatch]);

  /**
   * UseEffect pendiente al momento de actualizar un beneficiario jurídico existente
   */
  useEffect(() => {
    if (
      updateBeneficiaryDispatch?.beneficiaryUpdated?.identificationNumber &&
      !updateBeneficiaryDispatch?.error
    ) {
      reset(dataReset);
      dispatch(clearUpdateBeneficiaryPage() as TDispatch);
      dispatch(clearBeneficiaryPage() as TDispatch);
      toast.success('Beneficiario actualizado exitosamente', { hideProgressBar: true });
      navigate('/');
    } else if (!updateBeneficiaryDispatch?.beneficiaryUpdated && updateBeneficiaryDispatch?.error) {
      const message = updateBeneficiaryDispatch?.error?.response
        ? updateBeneficiaryDispatch?.error?.response?.data?.message
        : 'Ha ocurrido una incidencia';
      toast.error(`${message}`);
      dispatch(clearUpdateBeneficiaryPage() as TDispatch);
    }
  }, [
    dispatch,
    reset,
    navigate,
    updateBeneficiaryDispatch?.beneficiaryUpdated,
    updateBeneficiaryDispatch?.error,
  ]);

  /**
   * useEffect que se encarga de mostrar la información correspondiente en los campos mediante el ID
   */
  useEffect(() => {
    if (getBeneficiaryByIdDispatch?.beneficiary?.idBeneficiary) {
      const data = getBeneficiaryByIdDispatch?.beneficiary;
      reset({
        idGender: data?.idGender,
        idDocumentType: data?.idDocumentType,
        identificationNumber: data?.identificationNumber,
        thirdPartyBeneficiary: {
          ...data.thirdPartyBeneficiary,
          idThirdParty: data?.thirdPartyBeneficiary?.thirdParty?.idThirdParty,
        },
        firstName: data?.firstName,
        middleName: data?.middleName,
        firstSurname: data?.firstSurname,
        secondSurname: data?.secondSurname,
        address: {
          ...data.address,
          idCity: data?.address?.idCity,
          idDepartment: data?.address?.idDepartment,
          neighBorhood: data?.address?.neighBorhood,
          thirdStreetNumber: data?.address?.thirdStreetNumber,
          firstStreetNumber: data?.address?.firstStreetNumber,
          secondStreetNumber: data?.address?.secondStreetNumber,
          idAddressFirstStreetType: data?.address?.idAddressFirstStreetType,
          idAddressSecondStreetType: data?.address?.idAddressSecondStreetType,
          description: data?.address?.description ? data?.address?.description : '',
        },
        email: data?.email,
        cellPhone: data?.cellPhone !== 0 ? data?.cellPhone : undefined,
        telephone: data?.telephone !== 0 ? data?.telephone?.toString() : undefined,
      });
    } else if (
      !getBeneficiaryByIdDispatch?.beneficiary?.idBeneficiary &&
      getBeneficiaryByIdDispatch?.error
    ) {
      reset({
        ...dataReset,
        identificationNumber,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBeneficiaryByIdDispatch?.beneficiary, getBeneficiaryByIdDispatch?.error]);

  /**
   * UseEffect para llamar todos los dispatch
   */
  useEffect(() => {
    dispatch(citiesListPage() as TDispatch);
    dispatch(genderListPage() as TDispatch);
    dispatch(departmentsListPage() as TDispatch);
    dispatch(streetTypesListPage() as TDispatch);
    dispatch(clearBeneficiaryPage() as TDispatch);
    dispatch(documentsTypesListPage() as TDispatch);
    dispatch(clearUpdateBeneficiaryPage() as TDispatch);
    reset(dataReset);
  }, [dispatch, reset]);

  return (
    <OpenBeneficiaryRegisterFormV
      schema={schema}
      control={control}
      dataCities={dataCities}
      dataGender={dataGender}
      handleUpdate={handleUpdate}
      dataDepartments={dataDepartments}
      dataStreetTypes={dataStreetTypes}
      watchDepartment={watchDepartment}
      callBeneficiary={callBeneficiary}
      dataDocumentType={dataDocumentType}
      watchIdStreetTypeOne={watchIdStreetTypeOne}
      updateLoading={updateBeneficiaryDispatch?.loading}
      loadingData={loadingData}
    />
  );
};
