import 'reflect-metadata';
import { container } from 'tsyringe';
import { IAcademicLevelsApplication } from '../../../domain/interface/application/documentsPersons/IAcademicLevelsApplication';
import { IAcademicLevelsInfrastructure } from '../../../domain/interface/infrastructure/documentsPersons/IAcademicInfrastructure';
import { RequestAcademicLevels } from '../../../domain/documentsPersons/academicLevels/model/requestAcademicLevels';
import { ResponseAcademicLevels } from '../../../domain/documentsPersons/academicLevels/model/responseAcademicLevels';

export class AcademicLevelsApplication implements IAcademicLevelsApplication {
  private academicLevelsInfrastructure: IAcademicLevelsInfrastructure;

  public constructor() {
    this.academicLevelsInfrastructure = container.resolve<IAcademicLevelsInfrastructure>(
      'IAcademicLevelsInfrastructure'
    );
  }

  public async getAcademicLevels(
    request: RequestAcademicLevels
  ): Promise<ResponseAcademicLevels[]> {
    const result = await this.academicLevelsInfrastructure.getAcademicLevelsAPI(request);
    return result;
  }
}
