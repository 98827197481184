import 'reflect-metadata';
import { AxiosResponse } from 'axios';
import { IModuleInfrastructure } from '../../../domain/interface/infrastructure/userPermissions/IModuleInfrastructure';
import { ResponseModule } from '../../../domain/userPermissions/module/model/responseModule';
import { RequestModule } from '../../../domain/userPermissions/module/model/requestModule';
import { processDataBackend } from '../../../../common/utils/functions';

export class ModuleInfrastructure implements IModuleInfrastructure {
  private a = '';

  public async getModuleAPI(request: RequestModule): Promise<ResponseModule[]> {
    this.a = 'aaa';

    const res: AxiosResponse = await processDataBackend(
      'GET',
      '/api/modules/getAll',
      {},
      { request }
    );

    const module: ResponseModule[] = res.data;

    return Promise.resolve(module);
  }
}
