import { AxiosError } from 'axios';
import actions from './actions';
import { ResponseResponsibilities } from '../../../core/domain/taxInformation/responsibilities/model/responseResponsibilities';
import { ResponsibilitiesState } from '../../../core/domain/taxInformation/responsibilities/state/responsibilities';
import { TResponsibilitiesReducer } from './types';

const {
  RESPONSIBILITIES_LIST_PAGE_BEGIN,
  RESPONSIBILITIES_PAGE_SUCCESS,
  RESPONSIBILITIES_LIST_PAGE_ERR,
} = actions;

const initialResponsibilities: ResponsibilitiesState = new ResponsibilitiesState();

const ResponsibilitiesReducer = (
  state = initialResponsibilities,
  action: { type: string; err: AxiosError; data: ResponseResponsibilities[] }
): TResponsibilitiesReducer => {
  const { type, data, err } = action;
  switch (type) {
    case RESPONSIBILITIES_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case RESPONSIBILITIES_PAGE_SUCCESS:
      return {
        ...state,
        responsibilities: data,
        loading: false,
      };
    case RESPONSIBILITIES_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default ResponsibilitiesReducer;
