import { ResponseSeller } from '../model/responseSeller';

export class SellerState {
  public constructor() {
    this.sellers = [new ResponseSeller()];
    this.seller = new ResponseSeller();
    this.sellerCreated = new ResponseSeller();
    this.sellerUpdated = new ResponseSeller();
    this.sellerDeleted = new ResponseSeller();
  }

  public sellers?: ResponseSeller[];

  public seller?: ResponseSeller;

  public sellerCreated?: ResponseSeller;

  public sellerUpdated?: ResponseSeller;

  public sellerDeleted?: ResponseSeller;
}
