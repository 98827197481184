import { AxiosResponse } from 'axios';
import { IThirdPartiesBeneficiariesInfrastructure } from '../../domain/interface/infrastructure/thirdParties/IThirdPartiesBeneficiariesInfrastructure';
import { RequestThirdPartiesBeneficiaries } from '../../domain/thirdParties/thirdPartiesBeneficiaries/model/thirdPartiesBeneficiariesRequest';
import { ResponseThirdPartiesBeneficiaries } from '../../domain/thirdParties/thirdPartiesBeneficiaries/model/thirdPartiesBeneficiariesResponse';
import { processDataBackend } from '../../../common/utils/functions';

export class ThirdPartiesBeneficiariesInfrastructure
  implements IThirdPartiesBeneficiariesInfrastructure
{
  private a = '';

  public async getThirdPartiesBeneficiariesAPI(
    request: RequestThirdPartiesBeneficiaries
  ): Promise<ResponseThirdPartiesBeneficiaries[]> {
    this.a = 'aaa';

    const res: AxiosResponse = await processDataBackend(
      'GET',
      '/api/thirdPartiesBeneficiaries/getAll',
      {},
      { request }
    );

    const thirdPartiesBeneficiaries: ResponseThirdPartiesBeneficiaries[] = res.data;

    return Promise.resolve(thirdPartiesBeneficiaries);
  }
}
