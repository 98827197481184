import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearCreateThirdPartyCustomerPage,
  clearUpdateThirdPartyCustomerPage,
  createThirdPartyCustomerPage,
  getThirdPartyCustomerByIdListPage,
  updateThirdPartyCustomerPage,
} from '../../../../../redux/thirdParties/actionCreator';
import { TCities, TDataDepartments, TSellers, TStreetTypes } from './type.d';
import { TDepartmentsReducer } from '../../../../../redux/addresses/departments/types';
import { TCitiesReducer } from '../../../../../redux/addresses/cities/types';
import { TStreetTypesReducer } from '../../../../../redux/addresses/streetTypes/types';
import { RootState } from '../../../../../redux/store';
import { TDispatch, TodoSuccessResponse } from '../../../../../common/utils/types.d';
import { LegalMembershipV } from '../../../components/membership/legalMembership';
import {
  TCreateThirdPartyCustomerReducer,
  TThirdPartyCustomerReducer,
  TUpdateThirdPartyCustomerReducer,
} from '../../../../../redux/thirdParties/types.d';
import { calculateCheckDigit } from '../../../../../lib';
import { departmentsListPage } from '../../../../../redux/addresses/departments/actionCreator';
import { citiesListPage } from '../../../../../redux/addresses/cities/actionCreator';
import { streetTypesListPage } from '../../../../../redux/addresses/streetTypes/actionCreator';
import { genderListPage } from '../../../../../redux/documentsPersons/gender/actionCreator';
import { TSellersReducer } from '../../../../../redux/sellers/types.d';
import { sellersListPage } from '../../../../../redux/sellers/actionCreator';
import { filterKeyObject } from '../../../../../common/utils/functions';
import { TLoginDispatch } from '../../login/types';

export const LegalMembershipRegisterC = () => {
  /**
   * States
   */
  const [dataDepartments, setDataDepartments] = useState<TDataDepartments>([]);
  const [dataCities, setDataCities] = useState<TCities>([]);
  const [dataStreetTypes, setDataStreetTypes] = useState<TStreetTypes>([]);
  const [dataSellers, setDataSellers] = useState<TSellers>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  /**
   * dispatch
   */
  const dispatch = useDispatch();

  /**
   * Dispatch de obtener Membresia de terceros por ID
   */
  const getThirdPartyCustomerByIdDispatch: TThirdPartyCustomerReducer = useSelector(
    (dataState: RootState) => dataState?.thirdPartyCustomer
  );

  /**
   * Dispatch de crear Membresias de terceros
   */
  const createThirdPartyCustomerDispatch: TCreateThirdPartyCustomerReducer = useSelector(
    (dataState: RootState) => dataState?.thirdPartyCustomerCreated
  );

  /**
   * Dispatch de actualizar Membresias de terceros
   */
  const updateThirdPartyCustomerDispatch: TUpdateThirdPartyCustomerReducer = useSelector(
    (dataState: RootState) => dataState?.thirdPartyCustomerUpdated
  );
  /**
   * Dispatch de departamentos
   */
  const departmentsDispatch: TDepartmentsReducer = useSelector(
    (dataState: RootState) => dataState?.departments
  );
  /**
   * Dispatch de ciudades
   */
  const citiesDispatch: TCitiesReducer = useSelector((dataState: RootState) => dataState?.cities);

  /**
   * Dispatch de tipo de calles
   */
  const streetTypesDispatch: TStreetTypesReducer = useSelector(
    (dataState: RootState) => dataState?.streetTypes
  );

  /**
   * Dispatch de vendedores
   */
  const sellersDispatch: TSellersReducer = useSelector(
    (dataState: RootState) => dataState?.sellers
  );

  /**
   * Dispatch de Login
   */
  const loginDispatch: TLoginDispatch = useSelector((dataState: RootState) => dataState?.loginData);

  /**
   * Schema
   */
  const schema = yup
    .object({
      identificationNumber: yup
        .number()
        .required('Número de NIT es requerido')
        .typeError('Este campo sólo puede contener números y una longitud menor a 11')
        .test('is-between-8-and-11-digits', 'NIT debe tener entre 8 y 11 dígitos', (value) => {
          if (typeof value !== 'number') {
            return false;
          }
          const stringValue = String(Math.floor(value));
          const digitCount = stringValue.length;
          return digitCount >= 9 && digitCount <= 11;
        }),
      dv: yup.string(),
      businessName: yup.string().required('El campo razón social es requerido'),
      firstName: yup
        .string()
        .required('El primer nombre es requerido')
        .matches(/^[A-Za-zñáéíóúÁÉÍÓÚÑ\s]+$/, 'Sólo se permiten letras'),
      middleName: yup.string().matches(/^[A-Za-zñáéíóúÁÉÍÓÚÑ\s]*$/, 'Sólo se permiten letras'),
      firstSurname: yup
        .string()
        .required('El primer apellido es requerido')
        .matches(/^[A-Za-zñáéíóúÁÉÍÓÚÑ\s]+$/, 'Sólo se permiten letras'),
      secondSurname: yup
        .string()
        .required('El segundo apellido es requerido')
        .matches(/^[A-Za-zñáéíóúÁÉÍÓÚÑ\s]*$/, 'Sólo se permiten letras'),
      address: yup.object({
        idDepartment: yup.number().required('Campo requerido'),
        idCity: yup.number().required('Campo requerido'),
        neighBorhood: yup
          .string()
          .required('El barrio es requerido')
          .matches(/^[A-Za-zñáéíóúÁÉÍÓÚÑ\s\d]+$/u, 'Sólo se permiten letras y números'),
        idAddressFirstStreetType: yup.number().required('Campo requerido'),
        idAddressSecondStreetType: yup.number().required('Campo requerido'),
        firstStreetNumber: yup.string().required('El número es requerido'),
        secondStreetNumber: yup.string().required('El número 2 requerido'),
        thirdStreetNumber: yup.string().required('El número 3 requerido'),
        description: yup.string(),
      }),
      customerValidity: yup.object({
        idSeller: yup
          .number()
          .required('Campo requerido')
          .typeError('Este campo sólo puede contener números'),
        acquiredBeneficiary: yup
          .number()
          .required('Campo requerido')
          .typeError('Este campo sólo puede contener números'),
        consumedBeneficiary: yup
          .mixed()
          .test('is-valid', 'Este campo sólo puede contener números', (value) => {
            if (value === undefined || value === null) {
              return true; // Permite valores nulos o no definidos
            }
            if (Number.isNaN(value)) {
              return false; // Rechaza valores que no son números
            }
            return true;
          }),
        startDate: yup
          .string()
          .required('Este campo es requerido')
          .test('is-date', 'Fecha inválida', (value) => !Number.isNaN(Date.parse(value))),
        endDate: yup
          .string()
          .required('Este campo es requerido')
          .test('is-date', 'Fecha inválida', (value) => !Number.isNaN(Date.parse(value)))
          .test(
            'is-greater',
            'La fecha final no debe ser menor o igual a la fecha inicial',
            (value, context) => {
              const { startDate } = context.parent;
              return new Date(startDate) < new Date(value);
            }
          ),
        amountPaid: yup
          .number()
          .required('Campo requerido')
          .typeError('Este campo sólo puede contener números'),
        detail: yup.string().required('El detalle es requerido'),
      }),
      cellPhone: yup
        .number()
        .required()
        .test('is-between-10-and-10-digits', 'El celular debe tener 10 dígitos', (value) => {
          if (typeof value !== 'number') {
            return false;
          }
          const stringValue = String(Math.floor(value));
          const digitCount = stringValue.length;
          return digitCount >= 10 && digitCount <= 10;
        })
        .typeError('Este campo sólo puede contener números'),
      telephone: yup
        .number()
        .typeError('Este campo sólo puede contener números')
        .nullable()
        .transform((_, val) => (val !== '' ? Number(val) : null)),
      email: yup
        .string()
        .required('El correo requerido')
        .email('El correo electrónico no es válido'),
    })
    .required();

  /**
   * useForm
   */
  const { reset, setValue, getValues, handleSubmit, control, watch } = useForm({
    resolver: yupResolver(schema),
  });

  /**
   * useParams del id de tercero
   */
  const { id } = useParams();
  const idThirdParty: number = Number(id);
  const location = useLocation();

  /**
   * Watch del departamento seleccionado
   */
  const watchDepartment = watch('address.idDepartment');

  /**
   * Watch del tipo de dirección seleccionado
   */
  const watchIdStreetTypeOne = watch('address.idAddressFirstStreetType');

  /*
   * Función para retroceder.
   */
  const goBack = () => {
    setTimeout(() => {
      navigate('/membresias/lista');
    });
  };

  /**
   * Función que se encarga de crear una membresía jurídica
   */
  const handleRegister = handleSubmit(async () => {
    const values = getValues();
    dispatch(
      createThirdPartyCustomerPage({
        ...getValues(),
        customerValidity: {
          ...getValues()?.customerValidity,
          idSeller: getValues()?.customerValidity?.idSeller
            ? Number(getValues()?.customerValidity?.idSeller)
            : null,
          acquiredBeneficiary: Number(getValues()?.customerValidity?.acquiredBeneficiary),
          consumedBeneficiary: Number(getValues()?.customerValidity?.consumedBeneficiary),
          amountPaid: Number(getValues()?.customerValidity?.amountPaid),
        },
        idDocumentType: 4,
        idThirdPartyType: 2,
        idNature: 2,
        telephone: Number(values?.telephone),
      }) as TDispatch
    );
    setLoading(true);
  });

  const handleUpdate = handleSubmit(async () => {
    if (watch('customerValidity.startDate')! < watch('customerValidity.endDate')!) {
      dispatch(
        updateThirdPartyCustomerPage({
          ...filterKeyObject(getValues(), []),
          idDocumentType: 4,
          idThirdPartyType: 2,
          idNature: 2,
          idThirdParty,
        }) as TDispatch
      );
      setLoading(true);
    } else {
      toast('La fecha final debe ser mayor a la fecha de inicio', {
        type: 'error',

        hideProgressBar: true,
      });
    }
  });

  /**
   * UseEffect para llamar a Departments
   */
  useEffect(() => {
    setDataDepartments(departmentsDispatch?.departments || []);
  }, [departmentsDispatch?.departments]);

  /**
   * UseEffect para llamar a Cities
   */
  useEffect(() => {
    setDataCities(citiesDispatch?.cities || []);
  }, [citiesDispatch?.cities]);

  /**
   * UseEffect para llamar a StreetTypes
   */
  useEffect(() => {
    setDataStreetTypes(streetTypesDispatch?.streetTypes || []);
  }, [streetTypesDispatch?.streetTypes]);

  /**
   * UseEffect para llamar a StreetTypes
   */
  useEffect(() => {
    setDataSellers(sellersDispatch?.sellers || []);
  }, [sellersDispatch?.sellers]);

  /**
   * Función que se encarga de mostrar el DV generado en el input
   */
  const onChangeInput = () => {
    setValue('dv', calculateCheckDigit(getValues().identificationNumber));
  };

  /**
   * UseEffect pendiente al momento de crear una membresía jurídica existente
   */
  useEffect(() => {
    if (loading) {
      if (
        createThirdPartyCustomerDispatch?.thirdPartyCustomerCreated &&
        !createThirdPartyCustomerDispatch?.error
      ) {
        toast.success('Membresía Jurídica registrada exitosamente', {
          hideProgressBar: true,
        });
        reset({
          dv: '',
          identificationNumber: undefined,
          businessName: '',
          firstName: '',
          middleName: '',
          firstSurname: '',
          secondSurname: '',
          address: {
            idDepartment: undefined,
            idCity: undefined,
            neighBorhood: '',
            firstStreetNumber: '',
            idAddressFirstStreetType: undefined,
            idAddressSecondStreetType: undefined,
            secondStreetNumber: '',
            thirdStreetNumber: '',
            description: '',
          },
          customerValidity: {
            idSeller: undefined,
            acquiredBeneficiary: undefined,
            consumedBeneficiary: undefined,
            startDate: '',
            endDate: '',
            amountPaid: undefined,
            detail: '',
          },
          cellPhone: undefined,
          telephone: undefined,
          email: '',
        });
        dispatch(clearCreateThirdPartyCustomerPage() as TDispatch);
        goBack();
      } else if (
        !createThirdPartyCustomerDispatch?.thirdPartyCustomerCreated &&
        createThirdPartyCustomerDispatch?.error
      ) {
        const message = createThirdPartyCustomerDispatch?.error?.response
          ? (createThirdPartyCustomerDispatch?.error?.response?.data as TodoSuccessResponse)
              ?.message
          : 'Ha ocurrido una incidencia';
        toast.error(`${message}`, {
          hideProgressBar: true,
        });
        dispatch(clearCreateThirdPartyCustomerPage() as TDispatch);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createThirdPartyCustomerDispatch]);

  /**
   * Muestra la información de los documentos según los parámetros de la URL
   */
  useEffect(() => {
    if (idThirdParty) {
      dispatch(getThirdPartyCustomerByIdListPage({ idThirdParty }) as TDispatch);
    } else {
      reset({});
    }
  }, [idThirdParty, reset, dispatch]);

  useEffect(() => {
    if (location?.pathname === '/membresias/registro') {
      reset({});
    }
  }, [location, reset]);

  /**
   * UseEffect pendiente al momento de actualizar una membresía jurídica existente
   */
  useEffect(() => {
    if (idThirdParty && loading) {
      if (
        updateThirdPartyCustomerDispatch?.thirdPartyCustomerUpdated &&
        !updateThirdPartyCustomerDispatch?.error
      ) {
        reset({
          dv: '',
          identificationNumber: undefined,
          businessName: '',
          firstName: '',
          middleName: '',
          firstSurname: '',
          secondSurname: '',
          address: {
            idDepartment: undefined,
            idCity: undefined,
            neighBorhood: '',
            firstStreetNumber: '',
            idAddressFirstStreetType: undefined,
            idAddressSecondStreetType: undefined,
            secondStreetNumber: '',
            thirdStreetNumber: '',
            description: '',
          },
          customerValidity: {
            idSeller: undefined,
            acquiredBeneficiary: undefined,
            consumedBeneficiary: undefined,
            startDate: '',
            endDate: '',
            amountPaid: undefined,
            detail: '',
          },
          cellPhone: undefined,
          telephone: undefined,
          email: '',
        });
        dispatch(clearUpdateThirdPartyCustomerPage() as TDispatch);
        dispatch(getThirdPartyCustomerByIdListPage({ idThirdParty }) as TDispatch);
        toast.success('Membresía Jurídica actualizada exitosamente', { hideProgressBar: true });
        goBack();
      } else if (
        !updateThirdPartyCustomerDispatch?.thirdPartyCustomerUpdated &&
        updateThirdPartyCustomerDispatch?.error
      ) {
        const message = updateThirdPartyCustomerDispatch?.error?.response
          ? (updateThirdPartyCustomerDispatch?.error?.response?.data as TodoSuccessResponse)
              ?.message
          : 'Ha ocurrido una incidencia';
        toast.error(`${message}`);
        dispatch(clearUpdateThirdPartyCustomerPage() as TDispatch);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    updateThirdPartyCustomerDispatch?.thirdPartyCustomerUpdated,
    updateThirdPartyCustomerDispatch?.error,
  ]);

  /**
   * useEffect que se encarga de mostrar la información correspondiente en los campos mediante el ID
   */
  useEffect(() => {
    if (idThirdParty) {
      if (getThirdPartyCustomerByIdDispatch?.thirdPartyCustomer?.idThirdParty) {
        const data = getThirdPartyCustomerByIdDispatch?.thirdPartyCustomer;
        if (data?.idNature === 2) {
          reset({
            dv: calculateCheckDigit(data?.identificationNumber),
            identificationNumber: data?.identificationNumber,
            businessName: data?.businessName,
            firstName: data?.firstName,
            middleName: data?.middleName,
            firstSurname: data?.firstSurname,
            secondSurname: data?.secondSurname,
            address: {
              ...data.address,
              idDepartment: data?.address?.idDepartment,
              idCity: data?.address?.idCity,
              neighBorhood: data?.address?.neighBorhood,
              firstStreetNumber: data?.address?.firstStreetNumber,
              idAddressFirstStreetType: data?.address?.idAddressFirstStreetType,
              idAddressSecondStreetType: data?.address?.idAddressSecondStreetType,
              secondStreetNumber: data?.address?.secondStreetNumber,
              thirdStreetNumber: data?.address?.thirdStreetNumber,
              description: data?.address?.description ? data?.address?.description : '',
            },
            customerValidity: {
              ...data.customerValidity,
              idSeller: data?.customerValidity?.idSeller,
              acquiredBeneficiary:
                data?.customerValidity?.acquiredBeneficiary !== 0
                  ? data?.customerValidity?.acquiredBeneficiary
                  : undefined,
              consumedBeneficiary:
                data?.customerValidity?.consumedBeneficiary !== 0
                  ? data?.customerValidity?.consumedBeneficiary
                  : undefined,
              startDate: data?.customerValidity?.startDate,
              endDate: data?.customerValidity?.endDate,
              amountPaid:
                data?.customerValidity?.amountPaid !== 0
                  ? data?.customerValidity?.amountPaid
                  : undefined,
              detail: data?.customerValidity?.detail,
            },
            cellPhone: data?.cellPhone !== 0 ? data?.cellPhone : undefined,
            telephone: data?.telephone !== 0 ? data?.telephone : undefined,
            email: data?.email,
          });
        }
      }
    }
  }, [getThirdPartyCustomerByIdDispatch, idThirdParty, reset]);

  /**
   * UseEffect para llamar a todos los dispatches
   */
  useEffect(() => {
    dispatch(clearCreateThirdPartyCustomerPage() as TDispatch);
    dispatch(clearUpdateThirdPartyCustomerPage() as TDispatch);
    dispatch(sellersListPage() as TDispatch);
    dispatch(departmentsListPage() as TDispatch);
    dispatch(citiesListPage() as TDispatch);
    dispatch(streetTypesListPage() as TDispatch);
    dispatch(genderListPage() as TDispatch);
  }, [dispatch]);
  return (
    <LegalMembershipV
      handleRegister={handleRegister}
      handleUpdate={handleUpdate}
      dataDepartments={dataDepartments}
      dataCities={dataCities}
      dataStreetTypes={dataStreetTypes}
      schema={schema}
      control={control}
      onChangeInput={onChangeInput}
      watchDepartment={watchDepartment}
      watchIdStreetTypeOne={watchIdStreetTypeOne}
      createLoading={createThirdPartyCustomerDispatch?.loading}
      updateLoading={updateThirdPartyCustomerDispatch?.loading}
      idThirdParty={idThirdParty}
      dataSellers={dataSellers}
      loginDispatch={loginDispatch?.login?.userType === 'Administrador'}
    />
  );
};
