import 'reflect-metadata';
import { container } from 'tsyringe';
import { IRoleApplication } from '../../../domain/interface/application/userPermissions/IRolesApplication';
import { IRoleInfrastructure } from '../../../domain/interface/infrastructure/userPermissions/IRoleInfrastructure';
import { RequestRole } from '../../../domain/userPermissions/role/model/requestRole';
import { ResponseRole } from '../../../domain/userPermissions/role/model/responseRole';

export class RoleApplication implements IRoleApplication {
  private RoleInfrastructure: IRoleInfrastructure;

  public constructor() {
    this.RoleInfrastructure = container.resolve<IRoleInfrastructure>('IRoleInfrastructure');
  }

  public async getRole(request: RequestRole): Promise<ResponseRole[]> {
    const result = await this.RoleInfrastructure.getRoleAPI(request);
    return result;
  }

  public async createRole(request: RequestRole): Promise<ResponseRole> {
    const result = await this.RoleInfrastructure.createRoleAPI(request);
    return result;
  }
}
