import React, { ChangeEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import BenefitsRegisterV from '../../../components/benefits/benefitRegister';
import {
  TLogo,
  TBenefitCategory,
  TDataBenefitCategory,
  TDataThirdPartyProvider,
  TDataBeneficiaryTypeSelected,
  TDataCity,
  TDataDepartments,
} from '../../../components/benefits/benefitRegister/types.d';
import {
  extFile,
  toBase64,
  filterKeyObject,
  base64ToArrayBuffer,
} from '../../../../../common/utils/functions';
import { RootState } from '../../../../../redux/store';
import { TDispatch } from '../../../../../common/utils/types.d';
import { benefitCategoryListPage } from '../../../../../redux/benefits/benefitCategory/actionCreator';
import { consecutiveListPage } from '../../../../../redux/consecutives/actionCreator';
import {
  TBenefitReducer,
  TBenefitCreateReducer,
  TBenefitUpdateReducer,
} from '../../../../../redux/benefits/benefits/types.d';
import { TConsecutiveReducer } from '../../../../../redux/consecutives/types';
import {
  clearBenefitById,
  benefitUpdatePage,
  benefitCreatePage,
  clearBenefitsListPage,
  clearBenefitCreatePage,
  getByIdBenefitListPage,
  clearBenefitUpdateListPage,
} from '../../../../../redux/benefits/benefits/actionCreator';
import { getFileUrlS3, putImgS3 } from '../../../../../lib';
import { TLoginDispatch } from '../../login/types';
import { TThirdPartiesProvidersReducer } from '../../../../../redux/thirdParties/types.d';
import { thirdPartiesProvidersListPage } from '../../../../../redux/thirdParties/actionCreator';
import schema from './yupSchema';
import { TCitiesReducer } from '../../../../../redux/addresses/cities/types';
import { TDepartmentsReducer } from '../../../../../redux/addresses/departments/types';
import { departmentsListPage } from '../../../../../redux/addresses/departments/actionCreator';
import { citiesListPage } from '../../../../../redux/addresses/cities/actionCreator';

export const BenefitsRegisterC = () => {
  /**
   * STATES
   */
  const [getLogo, setGetLogo] = useState<TLogo>();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<TLogo>();
  const [loadGetById, setLoadGetById] = useState<boolean>(false);
  const [stringResult, setStringResult] = useState<string | undefined>();
  const [fileExtend, setFileExtend] = useState<string | undefined>(undefined);
  const [dataBenefitCategory, setDataBenefitCategory] = useState<TDataBenefitCategory>();
  const [thirdPartyProvider, setThirdPartyProvider] = useState<TDataThirdPartyProvider>([]);
  const [dataCities, setDataCities] = useState<TDataCity>([]);
  const [dataDepartments, setDataDepartments] = useState<TDataDepartments>([]);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [cityGroups, setCityGroups] = useState<any[]>([{}]);

  /**
   * CONSTANTE CON ARRAY DE OBJETOS PARA ASIGNAR UN userType STRING A userType CON idUserType
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contentBeneficiaryType: TDataBeneficiaryTypeSelected[] = [
    {
      idBeneficiaryTypeSelect: 1,
      labelBeneficiaryType: 'Empresa',
    },
    {
      idBeneficiaryTypeSelect: 2,
      labelBeneficiaryType: 'Persona',
    },
  ];

  const navigate = useNavigate();

  /*
    DISPATCH
    */
  const dispatch = useDispatch();

  /**
   * DISPATCH DE benefitCategory
   */
  const benefitCategoryDispatch: TBenefitCategory = useSelector(
    (dataState: RootState) => dataState?.benefitCategories
  );

  /**
   * DISPATCH DE CREAR BENEFICIOS
   */
  const createBenefitDispatch: TBenefitCreateReducer = useSelector(
    (dataState: RootState) => dataState?.benefitCreated
  );
  /**
   * DISPATCH DE ACTUALIZAR BENEFICIOS
   */
  const updateBenefitDispatch: TBenefitUpdateReducer = useSelector(
    (dataState: RootState) => dataState.benefitUpdated
  );

  /**
   * DISPATCH DE getBenefitById
   */
  const getBenefitByIdDispatch: TBenefitReducer = useSelector(
    (dataState: RootState) => dataState?.benefit
  );

  /**
   * Dispatch de ciudades
   */
  const citiesDispatch: TCitiesReducer = useSelector((dataState: RootState) => dataState?.cities);

  /**
   * Dispatch de departamentos
   */
  const departmentsDispatch: TDepartmentsReducer = useSelector(
    (dataState: RootState) => dataState?.departments
  );

  /**
   * DISPATCH DE LOGIN
   */
  const loginDispatch: TLoginDispatch = useSelector((dataState: RootState) => dataState?.loginData);

  /**
   * DISPATCH PARA OBTENER TODOS LOS COMERCIOS
   */
  const getAllThirdPartiesProviders: TThirdPartiesProvidersReducer = useSelector(
    (dataState: RootState) => dataState?.thirdPartiesProviders
  );

  /**
   * DISPATCH DE getConsecutiveById
   */
  const getConsecutiveByIdDispatch: TConsecutiveReducer = useSelector(
    (dataState: RootState) => dataState?.consecutive
  );

  /**
   * OBTENIENDO El ID POR LOS PARÁMETROS
   */
  const { id } = useParams();
  const location = useLocation();
  const idBenefit: number = Number(id);

  /**
   * useForm
   */
  const {
    watch,
    reset,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema(!!id)),
  });
  const watchDepartment = watch('benefitCity') || [];

  const handleVisibilityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVisibility = event.target.checked ? 1 : 0;
    setIsVisible(event.target.checked);
    setValue('visibility', newVisibility);
  };

  /**
   * FUNCIÓN PARA CREAR UN BENEFICIO
   */
  const handleRegister = handleSubmit(async () => {
    if (watch('benefitValidity.startDate')! < watch('benefitValidity.endDate')!) {
      if (!selectedImage) {
        toast('Es necesario cargar una imagen', {
          type: 'warning',
          hideProgressBar: true,
        });
      } else {
        dispatch(
          benefitCreatePage({
            ...getValues(),
            path: `${selectedImage?.logo}`,
            idCategory: !watch('idCategory') ? null : watch('idCategory'),
            idThirdParty: loginDispatch?.login?.idThirdParty
              ? loginDispatch?.login?.idThirdParty
              : watch('idThirdParty'),
            typeBeneficiary: stringResult,
            benefitValidity: {
              usagePerson: getValues('benefitValidity.usagePerson'),
              useLimit: getValues('benefitValidity.useLimit'),
              periodicity: getValues('benefitValidity.periodicity'),
              // purchase: getValues('benefitValidity.purchase'),
              consumedBenefit: 0,
              condition: 1,
              startDate: getValues('benefitValidity.startDate'),
              endDate: getValues('benefitValidity.endDate'),
            },
            visibility: isVisible ? 1 : 0,
          }) as TDispatch
        );

        putImgS3(selectedImage?.logo || '', selectedImage?.file || false);
      }
      setLoading(true);
    } else {
      toast('La fecha final debe ser mayor a la fecha de inicio', {
        type: 'error',

        hideProgressBar: true,
      });
    }
  });

  /**
   * FUNCIÓN PARA EDITAR UN BENEFICIO
   */
  const handleUpdate = handleSubmit(async () => {
    dispatch(
      benefitUpdatePage({
        ...filterKeyObject(getValues(), ['usagePerson', 'useLimit', 'periodicity']),
        idBenefit,
        path: `${selectedImage?.logo || getLogo?.logo}`,
        idThirdParty: watch('idThirdParty'),
        typeBeneficiary: stringResult,
        visibility: watch('visibility'),
      }) as TDispatch
    );
    if (selectedImage !== undefined) {
      putImgS3(selectedImage?.logo || '', selectedImage?.file || false);
    }
    setLoading(true);
  });

  /*
   * FUNCIÓN PARA RETROCEDER
   */
  const goBack = () => {
    setTimeout(() => {
      navigate('/beneficios/lista');
    });
  };

  /**
   * FUNCIÓN handleChange
   */
  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files) {
      const resFile = files[0];
      // VALIDAR LA EXTENSIÓN DEL ARCHIVO
      const allowedExtensions: string[] = ['jpg', 'jpeg', 'png'];
      const fileExtension = resFile?.name.split('.').pop()?.toLowerCase();
      setFileExtend(fileExtension);
      if (fileExtension && !allowedExtensions.includes(fileExtension)) {
        toast('Sólo se permiten archivos de imágenes.', {
          type: 'info',
          hideProgressBar: true,
        });
        setFileExtend(fileExtend);
      } else if (resFile?.size >= 5000000) {
        toast(
          'La imagen que estás intentando cargar supera el límite de 5MB. Por favor, reduce el tamaño de la imagen y vuelve a intentarlo.',
          {
            type: 'warning',
            hideProgressBar: true,
          }
        );
      } else {
        const base64 = await toBase64(resFile);
        const arrayBuffer = base64ToArrayBuffer(base64);
        const res = {
          file: arrayBuffer,
          logo: `${new Date().getTime()}.${extFile(resFile?.name)}`,
        };

        setSelectedImage(res);
        setGetLogo(undefined);
        toast('Imagen agregada', {
          type: 'success',
          hideProgressBar: true,
        });
      }
    }
  };

  /**
   * FUNCIÓN PARA EL BOTÓN DE AGREGAR IMAGEN
   */
  const handleLogo = (idImage: string) => {
    const element = document?.getElementById(idImage);
    if (element) {
      element.click();
    }
  };

  /*
   * FUNCIÓN PARA ASIGNAR UN LABEL AL idUserType
   */
  const getItemByNumber = (x: number) => {
    return contentBeneficiaryType.find((item) => item?.idBeneficiaryTypeSelect === x);
  };

  /**
   * PENDIENTE AL MOMENTO DE CREAR UN BENEFICIO EXISTENTE
   */
  useEffect(() => {
    if (loading) {
      if (createBenefitDispatch?.benefitCreated && !createBenefitDispatch?.error) {
        toast.success('Beneficio registrado exitosamente', {
          hideProgressBar: true,
        });
        reset({
          name: '',
          number: '',
          description: '',
          idCategory: undefined,
          idThirdParty: undefined,
          typeBeneficiary: '2',
          benefitValidity: {
            endDate: '',
            startDate: '',
            useLimit: undefined,
            periodicity: undefined,
            usagePerson: undefined,
          },
          visibility: undefined,
        });
        setIsVisible(false);
        setSelectedImage(undefined);
        dispatch(clearBenefitCreatePage() as TDispatch);
        // goBack();
      } else if (!createBenefitDispatch?.benefitCreated && createBenefitDispatch?.error) {
        const message = createBenefitDispatch?.error?.response
          ? createBenefitDispatch?.error?.response?.data?.message
          : 'Ha ocurrido una incidencia';
        toast.error(`${message}`, {
          hideProgressBar: true,
        });
        dispatch(clearBenefitCreatePage() as TDispatch);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createBenefitDispatch?.benefitCreated, createBenefitDispatch?.error]);

  /**
   * PARA ASIGNAR userType
   */
  const typeBeneficiary = watch('typeBeneficiary');
  useEffect(() => {
    const stringBeneficiaryType: string | undefined = getItemByNumber(
      Number(typeBeneficiary)
    )?.labelBeneficiaryType;
    setStringResult(stringBeneficiaryType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeBeneficiary]);

  /**
   * PENDIENTE AL MOMENTO DE ACTUALIZAR UN BENEFICIO EXISTENTE
   */
  useEffect(() => {
    if (idBenefit && loading) {
      if (updateBenefitDispatch?.benefitUpdated && !updateBenefitDispatch?.error) {
        toast.success('Beneficio actualizado exitosamente', {
          hideProgressBar: true,
        });
        reset({
          name: '',
          number: '',
          description: '',
          idCategory: undefined,
          idThirdParty: undefined,
          typeBeneficiary: '',
          benefitValidity: {
            endDate: '',
            startDate: '',
            useLimit: undefined,
            periodicity: undefined,
            usagePerson: undefined,
          },
          visibility: 0,
        });
        dispatch(clearBenefitUpdateListPage() as TDispatch);
        dispatch(getByIdBenefitListPage({ idBenefit }) as TDispatch);

        goBack();
      } else if (!updateBenefitDispatch?.benefitUpdated && updateBenefitDispatch?.error) {
        const message = updateBenefitDispatch?.error?.response
          ? updateBenefitDispatch?.error?.response?.data?.message
          : 'Ha ocurrido una incidencia';
        toast.error(`${message}`, {
          hideProgressBar: true,
        });
        dispatch(clearBenefitUpdateListPage() as TDispatch);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateBenefitDispatch?.benefitUpdated, updateBenefitDispatch?.error]);

  /**
   * MUESTRA LA INFORMACIÓN SEGÚN LOS PARÁMETROS DE LA URL
   */
  useEffect(() => {
    if (idBenefit) {
      dispatch(
        getByIdBenefitListPage({
          idBenefit,
        }) as TDispatch
      );
    } else {
      reset({
        name: '',
        number: '',
        description: '',
        idCategory: undefined,
        idThirdParty: undefined,
        typeBeneficiary: '',
        benefitValidity: {
          endDate: '',
          startDate: '',
          usagePerson: undefined,
          periodicity: undefined,
        },
      });
      setSelectedImage(undefined);
      setGetLogo(undefined);
    }
  }, [idBenefit, dispatch, reset]);

  useEffect(() => {
    if (location?.pathname === '/beneficios/registro') {
      reset({
        name: '',
        number: '',
        description: '',
        idCategory: undefined,
        idThirdParty: undefined,
        typeBeneficiary: '2',
        benefitValidity: {
          endDate: '',
          startDate: '',
          usagePerson: undefined,
          periodicity: undefined,
        },
      });
      setSelectedImage(undefined);
      setGetLogo(undefined);
    }
  }, [location, reset]);

  /**
   * SE ENCARGA DE MOSTRAR LA INFORMACIÓN CORRESPONDIENTE EN LOS CAMPOS MEDIANTE EL ID
   */
  useEffect(() => {
    if (loadGetById) {
      const data = getBenefitByIdDispatch?.benefit;
      const newValue = data?.typeBeneficiary;
      const newBeneficiaryType = contentBeneficiaryType?.find(
        (x) => x?.labelBeneficiaryType === newValue
      )?.idBeneficiaryTypeSelect;

      if (data) {
        setIsVisible(data.visibility === 1);
        reset({
          name: data?.name,
          number: data?.number,
          idCategory: data?.idCategory,
          description: data?.description,
          idThirdParty: data?.idThirdParty,
          typeBeneficiary: newBeneficiaryType?.toString(),
          benefitValidity: {
            ...data?.benefitValidity,
            endDate: data?.benefitValidity?.endDate,
            useLimit:
              data?.benefitValidity?.useLimit === 0 ? undefined : data?.benefitValidity?.useLimit,
            periodicity: data?.benefitValidity?.periodicity,
            startDate: data?.benefitValidity?.startDate,
            usagePerson: data?.benefitValidity?.usagePerson,
          },
          visibility: data?.visibility,
          benefitCity:
            data?.benefitCity instanceof Array
              ? data?.benefitCity?.reduce(
                  (acc: { idDepartment: number; idCities: number[] }[], curr) => {
                    const existing = acc.find((x) => x.idDepartment === curr?.city?.idDepartment);
                    if (existing) {
                      existing.idCities.push(curr?.idCity);
                    } else {
                      acc.push({
                        idDepartment: curr?.city?.idDepartment ?? 0,
                        idCities: [curr?.idCity],
                      });
                    }
                    return acc;
                  },
                  []
                )
              : [],
        });
        // Solo actualizar cityGroups si hay más de un grupo
        const updatedCityGroups =
          data?.benefitCity instanceof Array
            ? data?.benefitCity.reduce(
                (acc: { idDepartment: number; idCities: number[] }[], curr) => {
                  const existing = acc.find((x) => x.idDepartment === curr?.city?.idDepartment);
                  if (existing) {
                    existing.idCities.push(curr?.idCity);
                  } else {
                    acc.push({
                      idDepartment: curr?.city?.idDepartment ?? 0,
                      idCities: [curr?.idCity],
                    });
                  }
                  return acc;
                },
                []
              )
            : [];

        // Solo actualizar cityGroups si tiene más de un grupo
        if (updatedCityGroups.length > 0) {
          setCityGroups(updatedCityGroups);
        }

        const img: string = getBenefitByIdDispatch?.benefit?.path || '';
        const result: string = getFileUrlS3(`benefits/${img}`);
        setGetLogo({
          logo: img,
          file: result,
        });
        setLoadGetById(false);
      }
    }
  }, [loadGetById, getBenefitByIdDispatch?.benefit, reset, contentBeneficiaryType]);

  const handleAddCity = () => {
    setCityGroups([...cityGroups, cityGroups?.length]);
  };

  const handleRemoveCityGroup = (index: number) => {
    setValue(
      'benefitCity',
      watchDepartment?.filter((_, id) => id !== index)
    );
    setCityGroups(cityGroups.filter((x: any, i: number) => i !== index));
  };

  /**
   * LLAMA LA INFORMACIÓN DEPENDIENDO DEL idThirdparty
   */
  useEffect(() => {
    if (!getBenefitByIdDispatch?.benefit && !getBenefitByIdDispatch?.error) {
      if (
        loginDispatch?.login?.idThirdParty !== undefined &&
        loginDispatch?.login?.idThirdParty !== 0
      ) {
        dispatch(
          getByIdBenefitListPage({
            idBenefit,
            idThirdParty: Number(setValue('idThirdParty', loginDispatch?.login?.idThirdParty!)),
          }) as TDispatch
        );
      }
      setLoadGetById(true);
    }
  }, [
    getBenefitByIdDispatch?.benefit,
    getBenefitByIdDispatch?.error,
    setValue,
    loginDispatch,
    dispatch,
    idBenefit,
  ]);

  /**
   * LLAMA benefitCategories
   */
  useEffect(() => {
    setDataBenefitCategory(benefitCategoryDispatch?.benefitCategories || []);
  }, [benefitCategoryDispatch?.benefitCategories]);

  /**
   * LLAMA COMERCIOS
   */
  useEffect(() => {
    setThirdPartyProvider(getAllThirdPartiesProviders?.thirdPartiesProviders || []);
  }, [getAllThirdPartiesProviders?.thirdPartiesProviders]);
  /**
   * UseEffect para llamar a Departments
   */
  useEffect(() => {
    setDataDepartments(departmentsDispatch?.departments || []);
  }, [departmentsDispatch?.departments]);

  /**
   * UseEffect para llamar a Cities
   */
  useEffect(() => {
    setDataCities(citiesDispatch?.cities || []);
  }, [citiesDispatch?.cities]);

  /**
   * VERIFICA SI TIENE UN COMERCIO ASOCIADO
   */
  useEffect(() => {
    if (
      loginDispatch?.login?.idThirdParty !== undefined &&
      loginDispatch?.login?.idThirdParty !== 0
    ) {
      setValue('idThirdParty', loginDispatch?.login?.idThirdParty);
    }
  }, [loginDispatch?.login?.idThirdParty, setValue]);

  /**
   * LLAMA A TODOS LOS DISPATCH
   */
  useEffect(() => {
    dispatch(clearBenefitsListPage() as TDispatch);
    dispatch(clearBenefitCreatePage() as TDispatch);
    dispatch(benefitCategoryListPage() as TDispatch);
    dispatch(clearBenefitUpdateListPage() as TDispatch);
    dispatch(departmentsListPage() as TDispatch);
    dispatch(citiesListPage() as TDispatch);
    dispatch(thirdPartiesProvidersListPage() as TDispatch);
    dispatch(thirdPartiesProvidersListPage() as TDispatch);
    dispatch(clearBenefitById() as TDispatch);
    if (idBenefit) {
      dispatch(clearBenefitById() as TDispatch);
    }
  }, [dispatch, idBenefit]);

  // OBTENER EL VALOR ACTUAL DEL CAMPO 'idCategory' DEL FORMULARIO
  const idCategory = watch('idCategory');

  // SE DISPARA CUANDO CAMBIA 'idCategory'
  useEffect(() => {
    dispatch(consecutiveListPage(idCategory) as TDispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idCategory, dispatch, setValue]);

  //SE DISPARA CUANDO CAMBIA 'setValue', 'getConsecutiveByIdDispatch' o 'idCategory'
  useEffect(() => {
    setValue('number', idCategory ? getConsecutiveByIdDispatch?.consecutive?.benefitsCode : '');
  }, [setValue, getConsecutiveByIdDispatch, idCategory]);

  return (
    <BenefitsRegisterV
      errors={errors}
      schema={schema}
      goBack={goBack}
      getLogo={getLogo}
      control={control}
      isVisible={isVisible}
      idBenefit={idBenefit}
      dataCities={dataCities}
      dataDepartments={dataDepartments}
      handleVisibilityChange={handleVisibilityChange}
      handleLogo={handleLogo}
      handleChange={handleChange}
      handleUpdate={handleUpdate}
      selectedImage={selectedImage}
      handleRegister={handleRegister}
      thirdPartyProvider={thirdPartyProvider}
      dataBenefitCategory={dataBenefitCategory}
      createLoading={createBenefitDispatch?.loading}
      updateLoading={updateBenefitDispatch?.loading}
      idThirdPartyLogin={!!loginDispatch?.login?.idThirdParty}
      watchDepartment={watchDepartment}
      cityGroups={cityGroups}
      handleRemoveCityGroup={handleRemoveCityGroup}
      handleAddCity={handleAddCity}
    />
  );
};
