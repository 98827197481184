import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { constructorName } from '../../../../../common/utils/functions';

import { citiesListPage } from '../../../../../redux/addresses/cities/actionCreator';
import { TCitiesReducer } from '../../../../../redux/addresses/cities/types';
import { departmentsListPage } from '../../../../../redux/addresses/departments/actionCreator';
import { TDepartmentsReducer } from '../../../../../redux/addresses/departments/types';
import {
  branchsOfficesListPage,
  clearCreateBranchOfficePage,
  clearDeleteBranchOfficePage,
  clearUpdateBranchOfficePage,
  createBranchOfficePage,
  deleteBranchOfficePage,
  getBranchOfficeByIdListPage,
  updateBranchOfficePage,
} from '../../../../../redux/branchsOffices/actionCreator';

import { TDispatch, TodoSuccessResponse } from '../../../../../common/utils/types.d';
import {
  TBranchOfficeReducer,
  TBranchsOfficesReducer,
  TCreateBranchOfficeReducer,
  TDeleteBranchOfficeReducer,
  TUpdateBranchOfficeReducer,
} from '../../../../../redux/branchsOffices/type.d';
import { RootState } from '../../../../../redux/store';
import { BranchesV } from '../../../components/benefits/branches';
import { TLoginDispatch } from '../../login/types';
import { TCities, TDataDepartments, TDataProviders } from '../../providers/naturalProvider/types.d';
import { TThirdPartiesProvidersReducer } from '../../user/userRegister/types.d';
import { IBranchsOfficesMap } from './types.d';

export const BranchesC = () => {
  /**
   * states
   */
  const [dataId, setDataId] = useState<number>();
  const [openDelete, setOpenDelete] = useState(false);
  const [dataDepartments, setDataDepartments] = useState<TDataDepartments>([]);
  const [dataProviders, setDataProviders] = useState<TDataProviders>([]);
  const [dataCities, setDataCities] = useState<TCities>([]);
  const [idBranchOffice, setIdBranchOffice] = useState<number>(0);
  const [branchsOfficesData, setBranchOfficesData] = useState<IBranchsOfficesMap[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [state, setState] = useState(false);
  const valueSchema = {
    idThirdParty: '',
    idDepartment: '',
    idCity: '',
    description: '',
  };

  /**
   * dispatch
   */
  const dispatch = useDispatch();

  /**
   * useParams del id de sucursal
   */
  // const { id } = useParams();
  // const idBranchOffice: number = Number(id);
  // const location = useLocation();

  /**
   * Dispatch de obtener todos las sucursales
   */
  const getAllBranchsOfficesDispatch: TBranchsOfficesReducer = useSelector(
    (dataState: RootState) => dataState?.branchsOffices
  );

  /**
   * Dispatch de obtener todos las sucursales
   */
  const getBranchsOfficesByIdDispatch: TBranchOfficeReducer = useSelector(
    (dataState: RootState) => dataState?.branchOffice
  );

  /**
   * Dispatch de crear sucursal
   */
  const createBranchOfficeDispatch: TCreateBranchOfficeReducer = useSelector(
    (dataState: RootState) => dataState?.branchOfficeCreated
  );

  /**
   * Dispatch de actualizar sucursal
   */
  const updateBranchOfficeDispatch: TUpdateBranchOfficeReducer = useSelector(
    (dataState: RootState) => dataState?.branchsOfficesUpdated
  );

  /**
   * Dispatch de eliminar sucursal
   */
  const deleteBranchOfficeDispatch: TDeleteBranchOfficeReducer = useSelector(
    (dataState: RootState) => dataState?.branchsOfficesDeleted
  );

  /**
   * Dispatch de Obtener todos los comercios
   */
  const getAllThirdPartiesProviders: TThirdPartiesProvidersReducer = useSelector(
    (dataState: RootState) => dataState?.thirdPartiesProviders
  );

  /**
   * Dispatch de departamentos
   */
  const departmentsDispatch: TDepartmentsReducer = useSelector(
    (dataState: RootState) => dataState?.departments
  );

  /**
   * Dispatch de Login
   */
  const loginDispatch: TLoginDispatch = useSelector((dataState: RootState) => dataState?.loginData);

  /**
   * Dispatch de ciudades
   */
  const citiesDispatch: TCitiesReducer = useSelector((dataState: RootState) => dataState?.cities);

  const schema = yup
    .object({
      branches: yup.array().of(
        yup
          .object()
          .shape({
            idBranchOffice: yup.number().notRequired(),
            idThirdParty: yup
              .string()
              .required('Campo requerido')
              .matches(/^\d+$/, 'Debe ser un número entero positivo'),
            idDepartment: yup
              .string()
              .required('Campo requerido')
              .matches(/^\d+$/, 'Debe ser un número entero positivo'),
            idCity: yup
              .string()
              .required('Campo requerido')
              .matches(/^\d+$/, 'Debe ser un número entero positivo'),
            description: yup.string().required('Campo requerido'),
            state: yup.number(),
          })
          .required()
      ),
    })
    .required();

  /**
   * useForm
   */
  const { reset, setValue, getValues, handleSubmit, control, watch } = useForm({
    resolver: yupResolver(schema),
  });

  /**
   * Watch del departamento seleccionado
   */
  const dataForm = watch();

  useEffect(() => {
    // Resetear el valor de la ciudad cuando el departamento cambia
    if (
      !(
        typeof dataForm?.branches?.[0]?.idDepartment === 'string' &&
        !isNaN(Number(dataForm?.branches?.[0]?.idDepartment))
      ) &&
      dataForm
    ) {
      setValue(`branches.${0}.idCity`, ''); // Asigna una cadena vacía al campo de ciudad
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForm?.branches?.[0]?.idDepartment]);

  /**
   * Función que se encarga de crear una sucursal
   */
  const handleRegister = handleSubmit(async () => {
    if (getValues()?.branches)
      dispatch(
        createBranchOfficePage(
          getValues()?.branches?.map((item) => {
            return {
              ...item,
              idThirdParty: loginDispatch?.login?.idThirdParty
                ? loginDispatch?.login?.idThirdParty
                : item?.idThirdParty,
            };
          })
        ) as TDispatch
      );
    setLoading(true);
  });

  // CERRAR MODAL
  const closeModal = () => {
    setState(false);
  };

  // ABRIR MODAL
  const active = () => {
    setState(true);
    setIdBranchOffice(0);
    setValue('branches', [
      {
        ...valueSchema,
        idThirdParty: loginDispatch?.login?.idThirdParty
          ? String(loginDispatch?.login?.idThirdParty)
          : '',
        idDepartment: '',
        idCity: '',
        description: '',
        state: 1,
      },
    ]);
  };

  /**
   * Función que se encarga de crear una sucursal
   */
  const handleUpdate = handleSubmit(async () => {
    if (dataForm?.branches?.length)
      dispatch(
        updateBranchOfficePage({
          idBranchOffice,
          idThirdParty: Number(dataForm?.branches[0]?.idThirdParty),
          idDepartment: Number(dataForm?.branches[0]?.idDepartment),
          idCity: Number(dataForm?.branches[0]?.idCity),
          description: dataForm?.branches[0]?.description,
        }) as TDispatch
      );
    setState(false);
    setLoading(true);
  });

  /**
   * Función para eliminar un registro
   */
  const handleDelete = async () => {
    dispatch(
      deleteBranchOfficePage({
        idBranchOffice: Number(dataId),
      }) as TDispatch
    );
    setOpenDelete(false);
  };

  /**
   * Función que se encarga de generar nuevos inputs
   */
  const handleAddInput = () => {
    setValue('branches', [
      ...(dataForm?.branches || []),
      {
        ...valueSchema,
        idThirdParty: loginDispatch?.login?.idThirdParty
          ? String(loginDispatch?.login?.idThirdParty)
          : '',
        state: 1,
      },
    ]);
  };

  /**
   * Función que se encarga de cerrar el modal de confirmación
   */
  const handleClose = () => {
    setOpenDelete(false);
  };
  /**
   * Función que se encarga de abrir el modal de confirmación para poder eliminar un registro
   */
  const buttonDelete = async (datos: number) => {
    setDataId(datos);
    setOpenDelete(true);
  };

  /**
   * ACTUALIZANDO SUCURSAL
   */
  const buttonUpdate = async (idBranchOffice: number) => {
    setIdBranchOffice(idBranchOffice);
    dispatch(getBranchOfficeByIdListPage({ idBranchOffice }) as TDispatch);
    setState(true);
  };

  /**
   * Función que se encarga de borrar inputs no deseados
   */
  const handleRemoveInput = (index: number) => {
    const newInputs = dataForm?.branches?.filter((_, newIndex: number) => newIndex !== index);
    setValue('branches', newInputs);
  };

  /**
   * UseEffect para llamar a Departments
   */
  useEffect(() => {
    setDataProviders(getAllThirdPartiesProviders?.thirdPartiesProviders || []);
  }, [getAllThirdPartiesProviders?.thirdPartiesProviders]);
  /**
   * UseEffect para llamar a Departments
   */
  useEffect(() => {
    setDataDepartments(departmentsDispatch?.departments || []);
  }, [departmentsDispatch?.departments]);

  /**
   * UseEffect para llamar a Cities
   */
  useEffect(() => {
    setDataCities(citiesDispatch?.cities || []);
  }, [citiesDispatch?.cities]);

  /**
   * UseEffect que muestra la informacion de sucursales en la lista
   */
  useEffect(() => {
    if (
      getAllBranchsOfficesDispatch?.branchsOffices instanceof Array &&
      loginDispatch?.login?.userType === 'Administrador'
    ) {
      const data = getAllBranchsOfficesDispatch?.branchsOffices?.map((item, i) => {
        const ID = i + 1;
        return {
          ...item,
          ID: !item ? null : ID,
          department: item?.department?.name,
          city: item?.city?.name,
          commerceName:
            item?.thirdParty?.idNature === 1
              ? constructorName([
                  item?.thirdParty?.firstName,
                  item?.thirdParty?.middleName,
                  item?.thirdParty?.firstSurname,
                  item?.thirdParty?.secondSurname,
                ]).toUpperCase()
              : item?.thirdParty?.businessName.toUpperCase(),
          description: item?.description?.toUpperCase(),
        };
      });
      setBranchOfficesData(data);
    } else if (
      getAllBranchsOfficesDispatch?.branchsOffices instanceof Array &&
      loginDispatch?.login?.idThirdParty !== 0
    ) {
      const filteredData = getAllBranchsOfficesDispatch?.branchsOffices
        ?.filter((item) => item?.idThirdParty === loginDispatch?.login?.idThirdParty)
        .map((item, i) => {
          const ID = i + 1;
          return {
            ...item,
            ID: !item ? null : ID,
            department: item?.department?.name,
            city: item?.city?.name,
            commerceName:
              item?.thirdParty?.idNature === 1
                ? constructorName([
                    item?.thirdParty?.firstName,
                    item?.thirdParty?.middleName,
                    item?.thirdParty?.firstSurname,
                    item?.thirdParty?.secondSurname,
                  ]).toUpperCase()
                : item?.thirdParty?.businessName.toUpperCase(),
            description: item?.description?.toUpperCase(),
          };
        });
      setBranchOfficesData(filteredData);
    }
  }, [getAllBranchsOfficesDispatch?.branchsOffices, loginDispatch]);

  /**
   * UseEffect pendiente al momento de crear una sucursal
   */
  useEffect(() => {
    if (loading) {
      if (createBranchOfficeDispatch?.branchsOfficesCreated && !createBranchOfficeDispatch?.error) {
        toast.success('Sucursal registrada exitosamente', {
          hideProgressBar: true,
        });
        setState(false);
        reset({
          branches: [
            {
              idThirdParty: '',
              idDepartment: '',
              idCity: '',
              description: '',
              state: 1,
            },
          ],
        });
        dispatch(clearCreateBranchOfficePage() as TDispatch);
        dispatch(branchsOfficesListPage() as TDispatch);
      } else if (
        !createBranchOfficeDispatch?.branchsOfficesCreated &&
        createBranchOfficeDispatch?.error
      ) {
        const message = createBranchOfficeDispatch?.error?.response
          ? (createBranchOfficeDispatch?.error?.response?.data as TodoSuccessResponse)?.message
          : 'Ha ocurrido una incidencia';
        toast.error(`${message}`, {
          hideProgressBar: true,
        });
        dispatch(clearCreateBranchOfficePage() as TDispatch);
      }
    }
  }, [
    createBranchOfficeDispatch?.branchsOfficesCreated,
    createBranchOfficeDispatch?.error,
    reset,
    dispatch,
    loading,
  ]);

  /**
   * UseEffect pendiente al momento de actualizar una sucursal existente
   */
  useEffect(() => {
    if (idBranchOffice && loading) {
      if (updateBranchOfficeDispatch?.branchsOfficesUpdated && !updateBranchOfficeDispatch?.error) {
        reset({
          branches: [
            {
              idDepartment: '',
              idThirdParty: '',
              idCity: '',
              description: '',
              state: 1,
            },
          ],
        });
        dispatch(clearUpdateBranchOfficePage() as TDispatch);
        dispatch(getBranchOfficeByIdListPage({ idBranchOffice }) as TDispatch);
        dispatch(branchsOfficesListPage() as TDispatch);
        toast.success('Sucursal actualizada exitosamente', { hideProgressBar: true });
        setState(false);
      } else if (
        !updateBranchOfficeDispatch?.branchsOfficesUpdated &&
        updateBranchOfficeDispatch?.error
      ) {
        const message = updateBranchOfficeDispatch?.error?.response
          ? updateBranchOfficeDispatch?.error?.response?.data?.message
          : 'Ha ocurrido una incidencia';
        toast.error(`${message}`);
        dispatch(clearUpdateBranchOfficePage() as TDispatch);
      }
    }
  }, [
    updateBranchOfficeDispatch?.branchsOfficesUpdated,
    updateBranchOfficeDispatch?.error,
    reset,
    dispatch,
    idBranchOffice,
    loading,
  ]);

  /**
   * UseEffect pendiente al momento de eliminar una sucursal existente
   */
  useEffect(() => {
    if (dataId) {
      if (deleteBranchOfficeDispatch?.branchsOfficesDeleted && !deleteBranchOfficeDispatch?.error) {
        toast.success('Sucursal eliminada exitosamente', {
          hideProgressBar: true,
          autoClose: 3000,
        });
        dispatch(clearDeleteBranchOfficePage() as TDispatch);
        dispatch(branchsOfficesListPage() as TDispatch);
      } else if (
        !deleteBranchOfficeDispatch?.branchsOfficesDeleted &&
        deleteBranchOfficeDispatch?.error
      ) {
        const message = deleteBranchOfficeDispatch?.error?.response
          ? deleteBranchOfficeDispatch?.error?.response?.data?.message
          : 'Ha ocurrido una incidencia';
        toast.error(`${message}`, {
          hideProgressBar: true,
        });
        dispatch(clearDeleteBranchOfficePage() as TDispatch);
      }
    }
  }, [
    deleteBranchOfficeDispatch?.branchsOfficesDeleted,
    deleteBranchOfficeDispatch?.error,
    dataId,
    dispatch,
  ]);

  /**
   * useEffect que se encarga de mostrar la información correspondiente en los campos mediante el ID
   */
  useEffect(() => {
    if (idBranchOffice) {
      if (getBranchsOfficesByIdDispatch?.branchOffice?.idBranchOffice) {
        const data = getBranchsOfficesByIdDispatch?.branchOffice;
        reset({
          branches: [
            {
              idThirdParty: String(data?.idThirdParty),
              idCity: String(data?.idCity),
              idDepartment: String(data?.idDepartment),
              description: data?.description || '',
              state: 1,
            },
          ],
        });
      }
    }
  }, [getBranchsOfficesByIdDispatch, idBranchOffice, reset]);

  useEffect(() => {
    setValue('branches', [
      {
        ...valueSchema,
        idThirdParty: loginDispatch?.login?.idThirdParty
          ? String(loginDispatch?.login?.idThirdParty)
          : '',
        idDepartment: idBranchOffice
          ? String(getBranchsOfficesByIdDispatch?.branchOffice?.idDepartment)
          : '',
        idCity: idBranchOffice ? String(getBranchsOfficesByIdDispatch?.branchOffice?.idCity) : '',
        description: idBranchOffice
          ? String(getBranchsOfficesByIdDispatch?.branchOffice?.description)
          : '',
        state: 1,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * UseEffect para llamar a todos los dispatches
   */
  useEffect(() => {
    dispatch(branchsOfficesListPage() as TDispatch);
    dispatch(departmentsListPage() as TDispatch);
    dispatch(citiesListPage() as TDispatch);
    dispatch(clearCreateBranchOfficePage() as TDispatch);
    dispatch(clearUpdateBranchOfficePage() as TDispatch);
    dispatch(clearDeleteBranchOfficePage() as TDispatch);
  }, [dispatch]);

  return (
    <BranchesV
      schema={schema}
      control={control}
      handleAddInput={handleAddInput}
      dataForm={dataForm}
      handleRemoveInput={handleRemoveInput}
      handleRegister={handleRegister}
      dataProviders={dataProviders}
      dataDepartments={dataDepartments}
      dataCities={dataCities}
      data={branchsOfficesData}
      createLoading={createBranchOfficeDispatch?.loading}
      updateLoading={updateBranchOfficeDispatch?.loading}
      deleteLoading={deleteBranchOfficeDispatch?.loading}
      loading={getAllBranchsOfficesDispatch?.loading}
      idThirdPartyLogin={!!loginDispatch?.login?.idThirdParty}
      handleUpdate={handleUpdate}
      idBranchOffice={idBranchOffice}
      openDelete={openDelete}
      handleClose={handleClose}
      buttonDelete={buttonDelete}
      handleDelete={handleDelete}
      buttonUpdate={buttonUpdate}
      closeModal={closeModal}
      active={active}
      state={state}
    />
  );
};
