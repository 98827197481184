import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@mui/material/styles';
import theme from './common/theme';
import './common/css/style.css';
import './assets/fonts/index.css';
import App from './App';
import { setTitleIcon } from './common/utils';
import { store } from './redux/store';
import PersistProvider from './redux/providers/persist-provider';

setTitleIcon();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <PersistProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ToastContainer />
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </PersistProvider>
  </Provider>
);
