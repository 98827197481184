import 'reflect-metadata';
import { container } from 'tsyringe';
import { IBanksApplication } from '../../../domain/interface/application/banks/IBanksApplication';
import { IBanksInfrastructure } from '../../../domain/interface/infrastructure/banks/IBanksInfrastructure';
import { RequestBanks } from '../../../domain/banks/banks/model/requestBanks';
import { ResponseBanks } from '../../../domain/banks/banks/model/responseBanks';

export class BanksApplication implements IBanksApplication {
  private banksInfrastructure: IBanksInfrastructure;

  public constructor() {
    this.banksInfrastructure = container.resolve<IBanksInfrastructure>('IBanksInfrastructure');
  }

  public async getBanks(request: RequestBanks): Promise<ResponseBanks[]> {
    const result = await this.banksInfrastructure.getBanksAPI(request);
    return result;
  }
}
