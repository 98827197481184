import { AxiosError } from 'axios';
import {
  actionsBenefitBeneficiaryCreate,
  actionsBenefitBeneficiaryGetById,
  actionsBenefitBeneficiaryValidateBenefit,
} from './actions';
import { BenefitBeneficiariesState } from '../../core/domain/benefitBeneficiaries/state/benefitBeneficiariesState';
import {
  TCreateBenefitBeneficiaryReducer,
  TGetByIdBenefitBeneficiaryReducer,
  TValidateBenefitBeneficiaryReducer,
} from './types.d';
import { ResponseBenefitBeneficiaries } from '../../core/domain/benefitBeneficiaries/model/responseBenefitBeneficiaries';

const {
  BENEFIT_BENEFICIARY_CREATE_PAGE_BEGIN,
  BENEFIT_BENEFICIARY_CREATE_PAGE_SUCCESS,
  BENEFIT_BENEFICIARY_CREATE_PAGE_ERR,
} = actionsBenefitBeneficiaryCreate;
const {
  BENEFIT_BENEFICIARY_GET_BY_ID_PAGE_BEGIN,
  BENEFIT_BENEFICIARY_GET_BY_ID_PAGE_SUCCESS,
  BENEFIT_BENEFICIARY_GET_BY_ID_PAGE_ERR,
} = actionsBenefitBeneficiaryGetById;
const {
  BENEFIT_BENEFICIARY_VALIDATE_BENEFIT_PAGE_BEGIN,
  BENEFIT_BENEFICIARY_VALIDATE_BENEFIT_PAGE_ERR,
  BENEFIT_BENEFICIARY_VALIDATE_BENEFIT_PAGE_SUCCESS,
} = actionsBenefitBeneficiaryValidateBenefit;

const initialBeneficiary: BenefitBeneficiariesState = new BenefitBeneficiariesState();

export const CreateBenefitBeneficiaryReducer = (
  state = initialBeneficiary,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseBenefitBeneficiaries | undefined;
  }
): TCreateBenefitBeneficiaryReducer => {
  const { type, data, err } = action;
  switch (type) {
    case BENEFIT_BENEFICIARY_CREATE_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BENEFIT_BENEFICIARY_CREATE_PAGE_SUCCESS:
      return {
        ...state,
        benefitBeneficiaryCreated: data,
        loading: false,
      };
    case BENEFIT_BENEFICIARY_CREATE_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};
export const GetByIdBenefitBeneficiaryReducer = (
  state = initialBeneficiary,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseBenefitBeneficiaries[] | undefined;
  }
): TGetByIdBenefitBeneficiaryReducer => {
  const { type, data, err } = action;
  switch (type) {
    case BENEFIT_BENEFICIARY_GET_BY_ID_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BENEFIT_BENEFICIARY_GET_BY_ID_PAGE_SUCCESS:
      return {
        ...state,
        benefitBeneficiaryById: data,
        loading: false,
      };
    case BENEFIT_BENEFICIARY_GET_BY_ID_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};
export const ValidateBenefitBeneficiaryReducer = (
  state = initialBeneficiary,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseBenefitBeneficiaries | undefined;
  }
): TValidateBenefitBeneficiaryReducer => {
  const { type, data, err } = action;
  switch (type) {
    case BENEFIT_BENEFICIARY_VALIDATE_BENEFIT_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BENEFIT_BENEFICIARY_VALIDATE_BENEFIT_PAGE_SUCCESS:
      return {
        ...state,
        benefitBeneficiaryValidate: data,
        loading: false,
      };
    case BENEFIT_BENEFICIARY_VALIDATE_BENEFIT_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};
