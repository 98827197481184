import { ResponseBenefit } from '../model/responseBenefit';

export class BenefitState {
  public constructor() {
    this.benefits = [new ResponseBenefit()];
    this.benefitsForExcel = [new ResponseBenefit()];
    this.getBenefitsWithPagination = [new ResponseBenefit()];
    this.benefitsRandom = [new ResponseBenefit()];
    this.benefitsByMembership = [new ResponseBenefit()];
    this.benefitByBeneficiaries = [new ResponseBenefit()];
    this.benefit = new ResponseBenefit();
    this.benefitCreated = new ResponseBenefit();
    this.benefitUpdated = new ResponseBenefit();
    this.benefitDeleted = new ResponseBenefit();
    this.benefitByThirdParty = [new ResponseBenefit()];
  }

  public benefits: ResponseBenefit[];

  public benefitsForExcel: ResponseBenefit[];

  public getBenefitsWithPagination: ResponseBenefit[];

  public benefitsRandom: ResponseBenefit[];

  public benefitsByMembership: ResponseBenefit[];

  public benefitByBeneficiaries: ResponseBenefit[];

  public benefitByThirdParty: ResponseBenefit[];

  public benefit: ResponseBenefit;

  public benefitCreated: ResponseBenefit;

  public benefitUpdated: ResponseBenefit;

  public benefitDeleted: ResponseBenefit;

}
