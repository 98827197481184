import { AxiosError } from 'axios';
import { ResponseResponsibilities } from '../../../core/domain/taxInformation/responsibilities/model/responseResponsibilities';

const actions = {
  RESPONSIBILITIES_LIST_PAGE_BEGIN: 'RESPONSIBILITIES_LIST_PAGE_BEGIN',
  RESPONSIBILITIES_PAGE_SUCCESS: 'RESPONSIBILITIES_LIST_PAGE_SUCCESS',
  RESPONSIBILITIES_LIST_PAGE_ERR: 'RESPONSIBILITIES_LIST_PAGE_ERR',

  responsibilitiesListPageBegin: (): { type: string } => {
    return {
      type: actions.RESPONSIBILITIES_LIST_PAGE_BEGIN,
    };
  },

  responsibilitiesListPageSuccess: (
    data: ResponseResponsibilities[]
  ): { type: string; data: ResponseResponsibilities[] } => {
    return {
      type: actions.RESPONSIBILITIES_PAGE_SUCCESS,
      data,
    };
  },

  responsibilitiesListPageErr: (err: AxiosError): { type: string; err: AxiosError } => {
    return {
      type: actions.RESPONSIBILITIES_LIST_PAGE_ERR,
      err,
    };
  },
};

export default actions;
