import { AxiosError } from 'axios';
import { ResponseDocumentsTypes } from '../../../core/domain/documentsPersons/documentTypes/model/responseDocumentsTypes';

const actionsDocumentTypes = {
  DOCUMENTS_TYPES_LIST_PAGE_BEGIN: 'DOCUMENTS_TYPES_LIST_PAGE_BEGIN',
  DOCUMENTS_TYPES_LIST_PAGE_SUCCESS: 'DOCUMENTS_TYPES_LIST_PAGE_SUCCESS',
  DOCUMENTS_TYPES_LIST_PAGE_ERR: 'DOCUMENTS_TYPES_LIST_PAGE_ERR',

  documentsTypesListPageBegin: (): { type: string } => {
    return {
      type: actionsDocumentTypes.DOCUMENTS_TYPES_LIST_PAGE_BEGIN,
    };
  },

  documentsTypesListPageSuccess: (
    data: ResponseDocumentsTypes[]
  ): { type: string; data: ResponseDocumentsTypes[] } => {
    return {
      type: actionsDocumentTypes?.DOCUMENTS_TYPES_LIST_PAGE_SUCCESS,
      data,
    };
  },

  documentsTypesListPageErr: (err: AxiosError): { type: string; err: AxiosError } => {
    return {
      type: actionsDocumentTypes?.DOCUMENTS_TYPES_LIST_PAGE_ERR,
      err,
    };
  },
};

export { actionsDocumentTypes };
