import { AxiosError } from 'axios';
import { ResponseDepartments } from '../../../core/domain/addresses/departments/model/responseDepartments';

const actions = {
  DEPARTMENTS_LIST_PAGE_BEGIN: 'DEPARTMENTS_LIST_PAGE_BEGIN',
  DEPARTMENTS_LIST_PAGE_SUCCESS: 'DEPARTMENTS_LIST_PAGE_SUCCESS',
  DEPARTMENTS_LIST_PAGE_ERR: 'DEPARTMENTS_LIST_PAGE_ERR',

  departmentsListPageBegin: (): { type: string } => {
    return {
      type: actions.DEPARTMENTS_LIST_PAGE_BEGIN,
    };
  },

  departmentsListPageSuccess: (
    data: ResponseDepartments[] | ResponseDepartments
  ): { type: string; data: ResponseDepartments[] | ResponseDepartments } => {
    return {
      type: actions.DEPARTMENTS_LIST_PAGE_SUCCESS,
      data,
    };
  },

  departmentsListPageErr: (err: AxiosError): { type: string; err: AxiosError } => {
    return {
      type: actions.DEPARTMENTS_LIST_PAGE_ERR,
      err,
    };
  },
};

export default actions;
