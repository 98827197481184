import 'reflect-metadata';
import { AxiosResponse } from 'axios';
import { processDataBackend } from '../../../common/utils/functions';
import { RequestBenefitBeneficiaries } from '../../domain/benefitBeneficiaries/model/requestBenefitBeneficiaries';
import { ResponseBenefitBeneficiaries } from '../../domain/benefitBeneficiaries/model/responseBenefitBeneficiaries';
import { IBenefitBeneficiariesInfrastructure } from '../../domain/interface/infrastructure/benefitBeneficiaries/IBenefitBeneficiariesInfrastructure';

export class BenefitBeneficiariesInfrastructure implements IBenefitBeneficiariesInfrastructure {
  private a = '';

  public async createBenefitBeneficiaryAPI(
    request: RequestBenefitBeneficiaries
  ): Promise<ResponseBenefitBeneficiaries> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to create not found');
    const res: AxiosResponse = await processDataBackend(
      'POST',
      '/api/benefitBeneficiary/create',
      {},
      { request }
    );
    const result: ResponseBenefitBeneficiaries = res.data;
    return Promise.resolve(result);
  }

  public async getByIdBenefitBeneficiaryAPI(
    request: RequestBenefitBeneficiaries
  ): Promise<ResponseBenefitBeneficiaries[]> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to create not found');
    const res: AxiosResponse = await processDataBackend(
      'POST',
      '/api/benefitBeneficiary/getById',
      {},
      { request }
    );
    const result: ResponseBenefitBeneficiaries[] = res.data;
    return Promise.resolve(result);
  }

  public async validateBenefitAPI(request: {
    idBenefitBeneficiary: number;
  }): Promise<ResponseBenefitBeneficiaries> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to create not found');
    const res: AxiosResponse = await processDataBackend(
      'PUT',
      '/api/benefitBeneficiary/validateBenefit',
      {},
      { request }
    );
    const result: ResponseBenefitBeneficiaries = res.data;
    return Promise.resolve(result);
  }
}
