import { selectClientName } from '../../utils';
import { TImages, TName } from './types.d';
import { logoBonusColor, logoBonus,tramaFondoLogin } from '../../../assets/img/bonus';
// import logoHeaderGive from '../../../assets/img/Give + logo.svg';

// const client = 'mili'
const client =
  selectClientName().toLowerCase() === 'local' || selectClientName().toLowerCase() === 'miliqa'
    ? 'mili'
    : selectClientName().toLowerCase();

const img: TImages = {
  logo: `https://images.mili.com.co/wp-content/uploads/${client}-logo.svg`,
  fondoLogin: `https://images.mili.com.co/wp-content/uploads/${client}-Login-Fondo.svg`,
  logoBonusColor: `${logoBonusColor}`,
  logoBonus: `${logoBonus}`,
  tramaFondoLogin: `${tramaFondoLogin}`,
  loading: `https://images.mili.com.co/wp-content/uploads/${client}-logoM.svg`,
  // logoPdf: `https://images.mili.com.co/wp-content/uploads/${client}-1.jpg`,
  footerPdf: `https://images.mili.com.co/wp-content/uploads/${client}-footerPdf.png`,
  headerPdf: `https://images.mili.com.co/wp-content/uploads/${client}-headerPdf.png`,
  logoM: `https://images.mili.com.co/wp-content/uploads/${client}-logoM.svg`,
  marca: `https://images.mili.com.co/wp-content/uploads/${client}-marca.png`,
  paperPlane: 'https://images.mili.com.co/wp-content/uploads/mili-paper-plane.png',
  eslogan: `https://images.mili.com.co/wp-content/uploads/${client}-eslogan.png`,
};

const image = (name: TName) => img[name];
export default image;
