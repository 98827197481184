import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { DataGrid, GridColDef, GridColumnHeaderParams, esES } from '@mui/x-data-grid';
import { ToolbarList } from '../../../../../common/components/toolbar';
import { TitleComponent } from '../../../../../common/components/titles/styled';
import { IBenefitsRedeemed } from './types';

const RedeemedBenefitsV: React.FC<IBenefitsRedeemed> = ({ allDataBenefits, loading }) => {
  const theme = useTheme();

  const styles = {
    header: {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      fontSize: '30px',
      textAlign: 'left',
      fontFamily: 'Poppins-Regular',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
    },
    headerName: {
      fontWeight: 'bold',
      fontSize: '14px',
      color: theme.palette.primary.main,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    icon: {
      display: 'flex',
      fontSize: '20px',
      color: '#fff',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        display: 'flex',
        color: '#fff',
      },
    },
    textButton: {
      fontSize: '13px',
      color: '#fff',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    button: {
      background: theme.palette.success.light,
      display: 'flex',
      height: '35px',
      justifyContent: 'center',
      gap: '10px',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        width: '30px',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:hover': {
        backgroundColor: theme.palette.success.main,
      },
      boxShadow: '0px 3px 5px 0px #dcdcdc',
    },
  };
  const columns: GridColDef[] = [
    {
      field: 'number',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>CÓDIGO</Typography>
      ),
    },
    {
      field: 'thirdParty',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>COMERCIO</Typography>
      ),
    },
    {
      field: 'name',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>NOMBRE</Typography>
      ),
    },
    {
      field: 'totalConsumed',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>TOTAL CONSUMIDO</Typography>
      ),
    },
    {
      field: 'isActive',
      headerName: 'ESTADO',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>ESTADO</Typography>
      ),
    },
  ];
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}>
      <Box
        sx={{
          height: 'auto',
          width: '98%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          background: '#fff',
          borderRadius: '16px',
          padding: '20px',
          pb: '30px',
          boxShadow: '0px 3px 6px #00000029',
          minHeight: '400px',
        }}>
        <div style={{ width: '100%', height: '80vh' }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}>
            <TitleComponent>Lista de beneficios redimidos</TitleComponent>
          </Box>
          <div style={{ background: '#fff', height: '82%' }}>
            <DataGrid
              rows={allDataBenefits || []}
              getRowId={(row) => row?.idBenefit}
              columns={columns}
              density="compact"
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              components={{ Toolbar: ToolbarList }}
              rowsPerPageOptions={[25, 50, 100]}
              loading={!allDataBenefits?.length && loading}
            />
          </div>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              marginTop: '20px',
            }}></Box>
        </div>
      </Box>
    </Box>
  );
};

export default RedeemedBenefitsV;
