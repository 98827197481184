import { AxiosError } from 'axios';
import { ResponseBranchsOffices } from '../../core/domain/branchsOffices/model/branchsOfficesResponse';
import { BranchsOfficesState } from '../../core/domain/branchsOffices/state/branchsOffices';
import { actionsBranchOffice, actionsBranchsOffices, actionsBranchsOfficesCreate, actionsBranchsOfficesDelete, actionsBranchsOfficesUpdate } from './actions';
import {
  TBranchOfficeReducer,
  TBranchsOfficesReducer,
  TCreateBranchOfficeReducer,
  TDeleteBranchOfficeReducer,
  TUpdateBranchOfficeReducer,
} from './type.d';

const {
  BRANCHSOFFICES_CREATE_PAGE_BEGIN,
  BRANCHSOFFICES_CREATE_PAGE_SUCCESS,
  BRANCHSOFFICES_CREATE_PAGE_ERR,
} = actionsBranchsOfficesCreate;
const {
  BRANCHSOFFICES_LIST_PAGE_BEGIN,
  BRANCHSOFFICES_LIST_PAGE_SUCCESS,
  BRANCHSOFFICES_LIST_PAGE_ERR,
} = actionsBranchsOffices;
const {
  BRANCHSOFFICES_UPDATE_PAGE_BEGIN,
  BRANCHSOFFICES_UPDATE_PAGE_SUCCESS,
  BRANCHSOFFICES_UPDATE_PAGE_ERR,
} = actionsBranchsOfficesUpdate;
const { BRANCHOFFICE_LIST_PAGE_BEGIN, BRANCHOFFICE_LIST_PAGE_SUCCESS, BRANCHOFFICE_LIST_PAGE_ERR } =
  actionsBranchOffice;
const {
  BRANCHSOFFICES_DELETE_PAGE_BEGIN,
  BRANCHSOFFICES_DELETE_PAGE_SUCCESS,
  BRANCHSOFFICES_DELETE_PAGE_ERR,
} = actionsBranchsOfficesDelete;

const initialBranchsOffices: BranchsOfficesState = new BranchsOfficesState();

export const CreateBranchOfficeReducer = (
  state = initialBranchsOffices,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseBranchsOffices[] | undefined;
  }
): TCreateBranchOfficeReducer => {
  const { type, data, err } = action;
  switch (type) {
    case BRANCHSOFFICES_CREATE_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BRANCHSOFFICES_CREATE_PAGE_SUCCESS:
      return {
        ...state,
        branchsOfficesCreated: data,
        loading: false,
      };
    case BRANCHSOFFICES_CREATE_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const DeleteBranchOfficeReducer = (
  state = initialBranchsOffices,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseBranchsOffices | undefined;
  }
): TDeleteBranchOfficeReducer => {
  const { type, data, err } = action;
  switch (type) {
    case BRANCHSOFFICES_DELETE_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BRANCHSOFFICES_DELETE_PAGE_SUCCESS:
      return {
        ...state,
        branchsOfficesDeleted: data,
        loading: false,
      };
    case BRANCHSOFFICES_DELETE_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const UpdateBranchOfficeReducer = (
  state = initialBranchsOffices,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseBranchsOffices | undefined;
  }
): TUpdateBranchOfficeReducer => {
  const { type, data, err } = action;
  switch (type) {
    case BRANCHSOFFICES_UPDATE_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BRANCHSOFFICES_UPDATE_PAGE_SUCCESS:
      return {
        ...state,
        branchsOfficesUpdated: data,
        loading: false,
      };
    case BRANCHSOFFICES_UPDATE_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const BranchsOfficesReducer = (
  state = initialBranchsOffices,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseBranchsOffices[] | undefined;
  }
): TBranchsOfficesReducer => {
  const { type, data, err } = action;
  switch (type) {
    case BRANCHSOFFICES_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BRANCHSOFFICES_LIST_PAGE_SUCCESS:
      return {
        ...state,
        branchsOffices: data,
        loading: false,
      };
    case BRANCHSOFFICES_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const BranchOfficeReducer = (
  state = initialBranchsOffices,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseBranchsOffices | undefined;
  }
): TBranchOfficeReducer => {
  const { type, data, err } = action;
  switch (type) {
    case BRANCHOFFICE_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BRANCHOFFICE_LIST_PAGE_SUCCESS:
      return {
        ...state,
        branchOffice: data,
        loading: false,
      };
    case BRANCHOFFICE_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};
