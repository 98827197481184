import 'reflect-metadata';
import { container } from 'tsyringe';
import { IDepartmentsApplication } from '../../../../domain/interface/application/addresses/IDepartmentsApplication';
import { IDepartmentsInfrastructure } from '../../../../domain/interface/infrastructure/addresses/IDepartmentsInfrastructure';
import { RequestDepartments } from '../../../../domain/addresses/departments/model/requestDepartments';
import { ResponseDepartments } from '../../../../domain/addresses/departments/model/responseDepartments';

export class DepartmentsApplication implements IDepartmentsApplication {
  private departmentsInfrastructure: IDepartmentsInfrastructure;

  public constructor() {
    this.departmentsInfrastructure = container.resolve<IDepartmentsInfrastructure>(
      'IDepartmentsInfrastructure'
    );
  }

  public async getDepartments(request: RequestDepartments): Promise<ResponseDepartments[]> {
    const result = await this.departmentsInfrastructure.getDepartmentsAPI(request);
    return result;
  }
}
