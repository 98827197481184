import { container } from 'tsyringe';
import { IGenderApplication } from '../../../domain/interface/application/documentsPersons/IGenderApplication';
import { IGenderInfrastructure } from '../../../domain/interface/infrastructure/documentsPersons/IGenderInfrastructure';
import { RequestGender } from '../../../domain/documentsPersons/gender/model/requestGender';
import { ResponseGender } from '../../../domain/documentsPersons/gender/model/responseGender';
import 'reflect-metadata';

export class GenderApplication implements IGenderApplication {
  private genderInfrastructure: IGenderInfrastructure;

  public constructor() {
    this.genderInfrastructure = container.resolve<IGenderInfrastructure>('IGenderInfrastructure');
  }

  public async getGender(request: RequestGender): Promise<ResponseGender[]> {
    const result = await this.genderInfrastructure.getGenderAPI(request);
    return result;
  }
}
