import { AxiosError } from 'axios';
import {
  actionsBeneficiaries,
  actionsBeneficiariesCustomers,
  actionsBeneficiary,
  actionsBeneficiaryCreate,
  actionsBeneficiaryDelete,
  actionsBeneficiaryForBenefitsList,
  actionsBeneficiaryUpdate,
  actionsBeneficiaryValidityBenefits,
  actionsCreateBulkBeneficiaryList,
} from './actions';
import { BeneficiariesState } from '../../core/domain/beneficiaries/state/beneficiariesState';
import { ResponseBeneficiaries } from '../../core/domain/beneficiaries/model/responseBeneficiaries';
import {
  TBeneficiariesCustomersReducer,
  TBeneficiariesReducer,
  TBeneficiaryReducer,
  TCreateBeneficiaryReducer,
  TCreateBulkBeneficiaryListReducer,
  TDeleteBeneficiaryReducer,
  TUpdateBeneficiaryReducer,
} from './types.d';

const {
  BENEFICIARIES_LIST_PAGE_BEGIN,
  BENEFICIARIES_LIST_PAGE_SUCCESS,
  BENEFICIARIES_LIST_PAGE_ERR,
} = actionsBeneficiaries;

const {
  BENEFICIARIES_CUSTOMERS_LIST_PAGE_BEGIN,
  BENEFICIARIES_CUSTOMERS_LIST_PAGE_SUCCESS,
  BENEFICIARIES_CUSTOMERS_LIST_PAGE_ERR,
} = actionsBeneficiariesCustomers;

const { BENEFICIARY_LIST_PAGE_BEGIN, BENEFICIARY_LIST_PAGE_SUCCESS, BENEFICIARY_LIST_PAGE_ERR } =
  actionsBeneficiary;
const {
  BENEFICIARY_CREATE_PAGE_BEGIN,
  BENEFICIARY_CREATE_PAGE_SUCCESS,
  BENEFICIARY_CREATE_PAGE_ERR,
} = actionsBeneficiaryCreate;
const {
  BENEFICIARY_UPDATE_PAGE_BEGIN,
  BENEFICIARY_UPDATE_PAGE_SUCCESS,
  BENEFICIARY_UPDATE_PAGE_ERR,
} = actionsBeneficiaryUpdate;

const {
  BENEFICIARY_DELETE_PAGE_BEGIN,
  BENEFICIARY_DELETE_PAGE_SUCCESS,
  BENEFICIARY_DELETE_PAGE_ERR,
} = actionsBeneficiaryDelete;

const {
  BENEFICIARY_CREATE_BULK_LIST_PAGE_BEGIN,
  BENEFICIARY_CREATE_BULK_LIST_PAGE_ERR,
  BENEFICIARY_CREATE_BULK_LIST_PAGE_SUCCESS,
} = actionsCreateBulkBeneficiaryList;
const {
  BENEFICIARY_BENEFITS_PAGE_BEGIN,
  BENEFICIARY_BENEFITS_PAGE_SUCCESS,
  BENEFICIARY_BENEFITS_PAGE_ERR,
} = actionsBeneficiaryForBenefitsList;

const {
  BENEFICIARY_BENEFITS_LIST_PAGE_BEGIN,
  BENEFICIARY_BENEFITS_LIST_PAGE_SUCCESS,
  BENEFICIARY_BENEFITS_LIST_PAGE_ERR,
} = actionsBeneficiaryValidityBenefits;

const initialBeneficiary: BeneficiariesState = new BeneficiariesState();

export const BeneficiaryValidityListReducer = (
  state = initialBeneficiary,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseBeneficiaries;
  }
): TBeneficiaryReducer => {
  const { type, data, err } = action;
  switch (type) {
    case BENEFICIARY_BENEFITS_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BENEFICIARY_BENEFITS_LIST_PAGE_SUCCESS:
      return {
        ...state,
        beneficiary: data,
        loading: false,
      };
    case BENEFICIARY_BENEFITS_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const BeneficiariesByBenefitsListReducer = (
  state = initialBeneficiary,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseBeneficiaries;
  }
): TBeneficiaryReducer => {
  const { type, data, err } = action;
  switch (type) {
    case BENEFICIARY_BENEFITS_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BENEFICIARY_BENEFITS_PAGE_SUCCESS:
      return {
        ...state,
        beneficiary: data,
        loading: false,
      };
    case BENEFICIARY_BENEFITS_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const BeneficiariesReducer = (
  state = initialBeneficiary,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseBeneficiaries[];
  }
): TBeneficiariesReducer => {
  const { type, data, err } = action;
  switch (type) {
    case BENEFICIARIES_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BENEFICIARIES_LIST_PAGE_SUCCESS:
      return {
        ...state,
        beneficiaries: data,
        loading: false,
      };
    case BENEFICIARIES_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const BeneficiariesCustomersReducer = (
  state = initialBeneficiary,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseBeneficiaries[];
  }
): TBeneficiariesCustomersReducer => {
  const { type, data, err } = action;
  switch (type) {
    case BENEFICIARIES_CUSTOMERS_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BENEFICIARIES_CUSTOMERS_LIST_PAGE_SUCCESS:
      return {
        ...state,
        beneficiariesCustomers: data,
        loading: false,
      };
    case BENEFICIARIES_CUSTOMERS_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const BeneficiaryReducer = (
  state = initialBeneficiary,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseBeneficiaries | undefined;
  }
): TBeneficiaryReducer => {
  const { type, data, err } = action;
  switch (type) {
    case BENEFICIARY_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BENEFICIARY_LIST_PAGE_SUCCESS:
      return {
        ...state,
        beneficiary: data,
        loading: false,
      };
    case BENEFICIARY_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const CreateBeneficiaryReducer = (
  state = initialBeneficiary,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseBeneficiaries | undefined;
  }
): TCreateBeneficiaryReducer => {
  const { type, data, err } = action;
  switch (type) {
    case BENEFICIARY_CREATE_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BENEFICIARY_CREATE_PAGE_SUCCESS:
      return {
        ...state,
        beneficiaryCreated: data,
        loading: false,
      };
    case BENEFICIARY_CREATE_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const UpdateBeneficiaryReducer = (
  state = initialBeneficiary,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseBeneficiaries | undefined;
  }
): TUpdateBeneficiaryReducer => {
  const { type, data, err } = action;
  switch (type) {
    case BENEFICIARY_UPDATE_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BENEFICIARY_UPDATE_PAGE_SUCCESS:
      return {
        ...state,
        beneficiaryUpdated: data,
        loading: false,
      };
    case BENEFICIARY_UPDATE_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const DeleteBeneficiaryReducer = (
  state = initialBeneficiary,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseBeneficiaries | undefined;
  }
): TDeleteBeneficiaryReducer => {
  const { type, data, err } = action;
  switch (type) {
    case BENEFICIARY_DELETE_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BENEFICIARY_DELETE_PAGE_SUCCESS:
      return {
        ...state,
        beneficiaryDeleted: data,
        loading: false,
      };
    case BENEFICIARY_DELETE_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const CreateBulkBeneficiaryListReducer = (
  state = initialBeneficiary,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseBeneficiaries[];
  }
): TCreateBulkBeneficiaryListReducer => {
  const { type, data, err } = action;
  switch (type) {
    case BENEFICIARY_CREATE_BULK_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BENEFICIARY_CREATE_BULK_LIST_PAGE_SUCCESS:
      return {
        ...state,
        beneficiaryList: data,
        loading: false,
      };
    case BENEFICIARY_CREATE_BULK_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};
