import 'reflect-metadata';
import { container } from 'tsyringe';
import { ICitiesInfrastructure } from '../../../domain/interface/infrastructure/addresses/ICitiesInfrastructure';
import { ICitiesApplication } from '../../../domain/interface/application/addresses/ICitiesApplication';
import { RequestCities } from '../../../domain/addresses/cities/model/requestCities';
import { ResponseCities } from '../../../domain/addresses/cities/model/responseCities';

export class CitiesApplication implements ICitiesApplication {
  private citiesInfrastructure: ICitiesInfrastructure;

  public constructor() {
    this.citiesInfrastructure = container.resolve<ICitiesInfrastructure>('ICitiesInfrastructure');
  }

  public async getCities(request: RequestCities): Promise<ResponseCities[]> {
    const result = await this.citiesInfrastructure.getCitiesAPI(request);
    return result;
  }
}
