import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import {
  TBeneficiariesReducer,
  TDeleteBeneficiaryReducer,
} from '../../../../../redux/beneficiaries/types.d';
import { BeneficiariesListV } from '../../../components/beneficiaries/beneficiariesList';
import { TDispatch } from '../../../../../common/utils/types.d';
import { RootState } from '../../../../../redux/store';
import {
  beneficiariesListPage,
  deleteBeneficiaryPage,
  clearDeleteBeneficiaryPage,
} from '../../../../../redux/beneficiaries/actionCreator';
import { IBeneficiariesMap, Item, TDataDocumentType } from './types.d';
import {
  capitalize,
  constructorName,
  numberFormat,
  phoneFormat,
} from '../../../../../common/utils/functions';
import { exportToExcel } from '../../../../../common/components/excelGererate';
import { TLoginDispatch } from '../../login/types';

export const BeneficiariesListC = () => {
  /**
   * UseStates
   */
  const [dataId, setDataId] = useState<number>();
  const [openDelete, setOpenDelete] = useState(false);
  const [disable, setDisable] = useState(false);
  const [beneficiariesDataExcel, setBeneficiariesDataExcel] = useState<Item[]>([]);
  const [beneficiariesData, setBeneficiariesData] = useState<IBeneficiariesMap[]>([]);

  /**
   * Dispatch
   */
  const dispatch = useDispatch();

  /**
   * Dispatch de Login
   */
  const loginDispatch: TLoginDispatch = useSelector((dataState: RootState) => dataState?.loginData);

  /**
   * Dispatch de Obtener todos los beneficiarios
   */
  const getAllBeneficiariesDispatch: TBeneficiariesReducer = useSelector(
    (dataState: RootState) => dataState?.beneficiaries
  );

  /**
   * Dispatch de Eliminar beneficiario
   */
  const deleteBeneficiaryDispatch: TDeleteBeneficiaryReducer = useSelector(
    (dataState: RootState) => dataState?.beneficiaryDeleted
  );

  /**
   * Constante con array de objetos para asignar un label string a beneficiaries con idDocumentType
   */
  const contentDocumentType: TDataDocumentType[] = [
    {
      idDocumentType: 1,
      name: 'CÉDULA DE CIUDADANÍA',
    },
    {
      idDocumentType: 2,
      name: 'TARJETA DE IDENTIDAD',
    },
    {
      idDocumentType: 3,
      name: 'REGISTRO CIVIL DE NACIMIENTO',
    },
    {
      idDocumentType: 4,
      name: 'NIT',
    },
    {
      idDocumentType: 5,
      name: 'CÉDULA EXTRANJERÍA',
    },
    {
      idDocumentType: 6,
      name: 'TARJETA EXTRANJERÍA',
    },
    {
      idDocumentType: 7,
      name: 'PASAPORTE',
    },
    {
      idDocumentType: 8,
      name: 'TIPO DE DOCUMENTO EXTRANJERO',
    },
    {
      idDocumentType: 9,
      name: 'SIN USO DEFINIDO POR LA DIAN',
    },
  ];

  /**
   * Función para eliminar un registro
   */
  const handleDelete = async () => {
    dispatch(
      deleteBeneficiaryPage({
        idBeneficiary: Number(dataId),
      }) as TDispatch
    );
    setOpenDelete(false);
    setDisable(true);
  };

  /**
   * Función que se encarga de cerrar el modal de confirmación
   */
  const handleClose = () => {
    setOpenDelete(false);
  };
  /**
   * Función que se encarga de abrir el modal de confirmación para poder eliminar un registro
   */
  const buttonDelete = async (datos: number) => {
    setDataId(datos);
    setOpenDelete(true);
  };

  /*
   * Función para asignar un label al idDocumentType.
   */
  const getItemByNumber = (x: number | undefined) => {
    return contentDocumentType.find((item) => item?.idDocumentType === x);
  };

  const handleExportExcel = () => {
    exportToExcel(beneficiariesDataExcel, 'ListadoDeBeneficiarios');
    toast.success('Descargado correctamente', {
      hideProgressBar: true,
      autoClose: 1000,
    });
  };

  /**
   * UseEffect pendiente al momento de eliminar un usuario existente
   */
  useEffect(() => {
    if (dataId) {
      if (deleteBeneficiaryDispatch?.beneficiaryDeleted && !deleteBeneficiaryDispatch?.error) {
        toast.success('Beneficiario eliminado exitosamente', {
          hideProgressBar: true,
          autoClose: 3000,
        });
        dispatch(clearDeleteBeneficiaryPage() as TDispatch);
        dispatch(beneficiariesListPage() as TDispatch);
        setTimeout(() => {
          setDisable(false);
        }, 2000);
      } else if (
        !deleteBeneficiaryDispatch?.beneficiaryDeleted &&
        deleteBeneficiaryDispatch?.error
      ) {
        const message = deleteBeneficiaryDispatch?.error?.response
          ? deleteBeneficiaryDispatch?.error?.response?.data?.message
          : 'Ha ocurrido una incidencia';
        toast.error(`${message}`, {
          hideProgressBar: true,
        });
        setDisable(false);
        dispatch(clearDeleteBeneficiaryPage() as TDispatch);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteBeneficiaryDispatch?.beneficiaryDeleted, deleteBeneficiaryDispatch?.error]);

  /**
   * UseEffect que se encarga de mostrar la información en la Lista de Beneficiarios
   */
  useEffect(() => {
    if (
      getAllBeneficiariesDispatch?.beneficiaries instanceof Array &&
      (loginDispatch?.login?.userType === 'Administrador' ||
        loginDispatch?.login?.userType === 'Usuario' ||
        loginDispatch?.login?.userType === 'Membresia')
    ) {
      const data = getAllBeneficiariesDispatch?.beneficiaries?.map((x, i: number) => {
        const ID = i + 1;
        return {
          ...x,
          cellPhone: phoneFormat(String(x?.cellPhone)),
          names:
            x?.thirdPartyBeneficiary?.thirdParty?.idNature === 1
              ? constructorName([
                  x?.thirdPartyBeneficiary?.thirdParty?.firstName,
                  x?.thirdPartyBeneficiary?.thirdParty?.middleName,
                  x?.thirdPartyBeneficiary?.thirdParty?.firstSurname,
                  x?.thirdPartyBeneficiary?.thirdParty?.secondSurname,
                ]) || undefined
              : x?.thirdPartyBeneficiary?.thirdParty?.businessName || undefined,
          ID: !x ? null : ID,
        };
      });
      setBeneficiariesData(data);
    } else if (
      getAllBeneficiariesDispatch?.beneficiaries instanceof Array &&
      loginDispatch?.login?.idThirdParty !== 0
    ) {
      const filterData = getAllBeneficiariesDispatch?.beneficiaries
        ?.filter(
          (item) => item?.thirdPartyBeneficiary?.idThirdParty === loginDispatch?.login?.idThirdParty
        )
        .map((x, i) => {
          const ID = i + 1;
          return {
            ...x,
            cellPhone: phoneFormat(String(x?.cellPhone)),
            names:
              x?.thirdPartyBeneficiary?.thirdParty?.idNature === 1
                ? constructorName([
                    x?.thirdPartyBeneficiary?.thirdParty?.firstName,
                    x?.thirdPartyBeneficiary?.thirdParty?.middleName,
                    x?.thirdPartyBeneficiary?.thirdParty?.firstSurname,
                    x?.thirdPartyBeneficiary?.thirdParty?.secondSurname,
                  ]) || undefined
                : x?.thirdPartyBeneficiary?.thirdParty?.businessName || undefined,
            ID: !x ? null : ID,
          };
        });
      setBeneficiariesData(filterData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllBeneficiariesDispatch?.beneficiaries]);

  /**
   * UseEffect que se encarga de mostrar la información en el archivo excel
   */
  useEffect(() => {
    if (
      getAllBeneficiariesDispatch?.beneficiaries instanceof Array &&
      (loginDispatch?.login?.userType === 'Administrador' ||
        loginDispatch?.login?.userType === 'Usuario')
    ) {
      const data = getAllBeneficiariesDispatch?.beneficiaries?.map((x, i: number) => {
        const ID = i + 1;
        return {
          Item: ID,
          Correo: x?.email?.toLowerCase(),
          Nombre: capitalize(String(x?.firstName), true),
          Teléfono: x?.cellPhone?.toString() || undefined,
          Apellido: capitalize(String(x?.firstSurname), true),
          Membresía:
            x?.thirdPartyBeneficiary?.thirdParty?.idNature === 1
              ? constructorName([
                  x?.thirdPartyBeneficiary?.thirdParty?.firstName,
                  x?.thirdPartyBeneficiary?.thirdParty?.middleName,
                  x?.thirdPartyBeneficiary?.thirdParty?.firstSurname,
                  x?.thirdPartyBeneficiary?.thirdParty?.secondSurname,
                ]) || undefined
              : x?.thirdPartyBeneficiary?.thirdParty?.businessName || undefined,
          'Número de identificación': numberFormat(Number(x?.identificationNumber)),
          'Tipo de identificación': String(getItemByNumber(x.idDocumentType)?.name),
        };
      });
      setBeneficiariesDataExcel(data);
    } else if (
      getAllBeneficiariesDispatch?.beneficiaries instanceof Array &&
      loginDispatch?.login?.idThirdParty !== 0
    ) {
      const filterDataForExcel = getAllBeneficiariesDispatch?.beneficiaries
        ?.filter(
          (item) => item?.thirdPartyBeneficiary?.idThirdParty === loginDispatch?.login?.idThirdParty
        )
        .map((x, i) => {
          const ID = i + 1;
          return {
            Item: ID,
            'Tipo de identificación': String(getItemByNumber(x.idDocumentType)?.name),
            'Número de identificación': numberFormat(Number(x?.identificationNumber)),
            Nombre: capitalize(String(x?.firstName), true),
            Apellido: capitalize(String(x?.firstSurname), true),
            Teléfono: x?.cellPhone?.toString() || undefined,
            Correo: x?.email?.toLowerCase(),
            Membresía:
              x?.thirdPartyBeneficiary?.thirdParty?.idNature === 1
                ? constructorName([
                    x?.thirdPartyBeneficiary?.thirdParty?.firstName,
                    x?.thirdPartyBeneficiary?.thirdParty?.middleName,
                    x?.thirdPartyBeneficiary?.thirdParty?.firstSurname,
                    x?.thirdPartyBeneficiary?.thirdParty?.secondSurname,
                  ]) || undefined
                : x?.thirdPartyBeneficiary?.thirdParty?.businessName || undefined,
          };
        });
      setBeneficiariesDataExcel(filterDataForExcel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllBeneficiariesDispatch?.beneficiaries]);

  /**
   * UseEffect que se encarga de llamar a todos los dispatches
   */
  useEffect(() => {
    dispatch(beneficiariesListPage() as TDispatch);
  }, [dispatch]);

  return (
    <BeneficiariesListV
      data={beneficiariesData}
      openDelete={openDelete}
      handleClose={handleClose}
      handleDelete={handleDelete}
      buttonDelete={buttonDelete}
      handleExportExcel={handleExportExcel}
      deleteLoading={disable}
    />
  );
};
