import { FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { InputField, NormalSelectField } from '../../../../common/components/formAdapters';
import { TLocationContact } from './type';

export const LocationContact: FC<TLocationContact> = ({
  schema,
  control,
  theme,
  dataDepartments,
  dataCities,
  dataStreetTypes,
  watchDepartment,
  watchIdStreetTypeOne,
}) => {
  return (
    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
      <Box
        sx={{
          height: 'auto',
          width: '99%',
          background: '#fff',
          borderRadius: '16px',
          padding: '5px',
          boxShadow: '0px 3px 5px 0px #dcdcdc',
          marginTop: '20px',
        }}>
        <Box sx={{ padding: '10px', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ marginBottom: '15px' }}>
            <Typography
              variant="h5"
              color={theme.palette.primary.main}
              sx={{
                borderBottom: `1px solid ${theme.palette.primary.dark}`,
              }}>
              Ubicación y contacto
            </Typography>
          </Box>
          <Grid container spacing={{ xs: 2, sm: 2, md: 2 }} sx={{ marginBottom: '10px' }}>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <NormalSelectField
                name="address.idDepartment"
                label="Departamento"
                key="address.idDepartment"
                schema={schema?.fields?.address?.idDepartment}
                control={control}
                options={
                  (dataDepartments || [])?.map((x) => ({
                    value: x?.idDepartment,
                    label: x?.name?.toUpperCase(),
                  })) || []
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <NormalSelectField
                name="address.idCity"
                label="Ciudad"
                key="address.idCity"
                schema={schema?.fields?.address?.idCity}
                control={control}
                options={
                  (dataCities || [])
                    ?.filter((x) => x?.idDepartment === Number(watchDepartment))
                    .map((x) => {
                      return {
                        value: x?.idCity,
                        label: x?.name?.toUpperCase(),
                      };
                    }) || []
                }
                disabled={!watchDepartment}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <InputField
                label="Barrio"
                name="address.neighBorhood"
                schema={schema.fields?.neighBorhood}
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <NormalSelectField
                name="address.idAddressFirstStreetType"
                label="Tipo de dirección 1"
                schema={schema?.fields?.idAddressFirstStreetType}
                control={control}
                options={
                  (dataStreetTypes || [])?.map((x) => ({
                    value: x?.idStreetType,
                    label: x?.name,
                  })) || []
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <InputField
                label="Número"
                name="address.firstStreetNumber"
                schema={schema.fields?.address.firstStreetNumber}
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <NormalSelectField
                name="address.idAddressSecondStreetType"
                label="Tipo de dirección 2"
                schema={schema?.fields?.idAddressSecondStreetType}
                control={control}
                options={
                  (dataStreetTypes || [])
                    ?.filter((x) => x?.idStreetType !== Number(watchIdStreetTypeOne))
                    .map((x) => ({
                      value: x?.idStreetType,
                      label: x?.name,
                    })) || []
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <InputField
                label="Número 2"
                name="address.secondStreetNumber"
                schema={schema.fields?.address?.secondStreetNumber}
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <InputField
                label="Número 3"
                name="address.thirdStreetNumber"
                schema={schema.fields?.address?.thirdStreetNumber}
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <InputField
                label="Detalle"
                name="address.description"
                schema={schema.fields?.address?.description}
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <InputField
                label="Celular"
                name="cellPhone"
                schema={schema.fields?.cellPhone}
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <InputField
                label="Teléfono"
                name="telephone"
                schema={schema.fields?.telephone}
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <InputField
                label="Correo electrónico"
                type="email"
                name="email"
                schema={schema.fields?.email}
                control={control}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
