import React from 'react';
import ValidateCouponC from '../../../containers/benefits/validateCoupon';
import LayoutC from '../../../containers/Layout';

const ValidateCouponP = () => {
  return (
    <LayoutC>
      <ValidateCouponC />
    </LayoutC>
  );
};

export default ValidateCouponP;
