import 'reflect-metadata';
import { container } from 'tsyringe';
import { ISellerApplication } from '../../domain/interface/application/sellers/ISellerApplication';
import { ISellerInfrastructure } from '../../domain/interface/infrastructure/sellers/ISellerInfrastructure';
import { RequestSeller } from '../../domain/sellers/sellers/model/requestSeller';
import { ResponseSeller } from '../../domain/sellers/sellers/model/responseSeller';

export class SellerApplication implements ISellerApplication {
  private sellerInfrastructure: ISellerInfrastructure;

  public constructor() {
    this.sellerInfrastructure = container.resolve<ISellerInfrastructure>('ISellerInfrastructure');
  }
  // Sellers

  public async getSellers(request: RequestSeller): Promise<ResponseSeller[]> {
    const result = await this.sellerInfrastructure.getSellersAPI(request);
    return result;
  }

  public async getSellerById(request: RequestSeller): Promise<ResponseSeller> {
    const result = await this.sellerInfrastructure.getSellerByIdAPI(request);
    return result;
  }

  public async createSeller(request: RequestSeller): Promise<ResponseSeller> {
    const result = await this.sellerInfrastructure.createSellerAPI(request);
    return result;
  }

  public async updateSeller(request: RequestSeller): Promise<ResponseSeller> {
    const result = await this.sellerInfrastructure.updateSellerAPI(request);
    return result;
  }

  public async deleteSeller(request: RequestSeller): Promise<ResponseSeller> {
    const result = await this.sellerInfrastructure.deleteSellerAPI(request);
    return result;
  }
}
