import { AxiosError } from 'axios';
import { ResponseDocumentsTypes } from '../../../core/domain/documentsPersons/documentTypes/model/responseDocumentsTypes';
import { DocumentsTypesState } from '../../../core/domain/documentsPersons/documentTypes/state/documentsTypesState';
import { actionsDocumentTypes } from './actions';
import { TDocumentTypesReducer } from './types.d';

const {
  DOCUMENTS_TYPES_LIST_PAGE_BEGIN,
  DOCUMENTS_TYPES_LIST_PAGE_SUCCESS,
  DOCUMENTS_TYPES_LIST_PAGE_ERR,
} = actionsDocumentTypes;

const initialDocumentsTypes: DocumentsTypesState = new DocumentsTypesState();

const DocumentsTypesReducer = (
  state = initialDocumentsTypes,
  action: { type: string; err: AxiosError; data: ResponseDocumentsTypes[] }
): TDocumentTypesReducer => {
  const { type, data, err } = action;
  switch (type) {
    case DOCUMENTS_TYPES_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DOCUMENTS_TYPES_LIST_PAGE_SUCCESS:
      return {
        ...state,
        documentsTypes: data,
        loading: false,
      };
    case DOCUMENTS_TYPES_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { DocumentsTypesReducer };
