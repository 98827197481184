import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Drawer, Typography, useTheme } from '@mui/material';
import { Home } from '@mui/icons-material';
// import BusinessIcon from '@mui/icons-material/Business';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
// import CardMembershipIcon from '@mui/icons-material/CardMembership';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LabelIcon from '@mui/icons-material/Label';
import Diversity3Icon from '@mui/icons-material/Diversity3';
// import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
// import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import GroupsIcon from '@mui/icons-material/Groups';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import StoreIcon from '@mui/icons-material/Store';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PlumbingIcon from '@mui/icons-material/Plumbing';
import WidgetsIcon from '@mui/icons-material/Widgets';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DvrIcon from '@mui/icons-material/Dvr';
// import tramaAside from '../../../../assets/img/Trama.svg';
import { TMenu, TNewAside, TOptions, TSubMenus } from './types.d';
import { MenuOptionV } from './MenuOption';
import tramaAside from '../../../../assets/img/Trama.svg';

type TAside = {
  width?: string;
  height?: string;
  bgColor?: string;
  isOpen?: boolean;
  widthCollapsed?: string;
  widthExpanded?: string;
  marginTop: string;
};

const Aside = styled.aside<TAside>`
  background: ${({ bgColor }) => bgColor || '#eee'};
  width: ${({ isOpen, widthCollapsed, widthExpanded }) =>
    isOpen ? widthExpanded : widthCollapsed};
  height: 100%;
  padding-top: 2px;
  margin-top: ${({ marginTop }) => marginTop || '0px'};
  overflow: auto;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(0px)')};
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out; // Agrega una transición suave al transform y la anchura
  // Estilos para la trama del aside
  &::before {
    content: '';
    background-image: url(${tramaAside});
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    pointer-events: none;
  }
`;

type TMenuOptionLink = {
  width?: string;
  height?: string;
  bgColor?: string;
  isOpen?: boolean;
  widthCollapsed?: string;
  widthExpanded?: string;
};

const MenuOptionLink = styled.div<TMenuOptionLink>`
  width: ${({ isOpen, widthCollapsed, widthExpanded }) =>
    isOpen ? widthExpanded : widthCollapsed};
  /* height: 37px; */
  background: ${({ bgColor }) => bgColor || '#fff'};
  padding: 10px 15px 10px 25px;
  :hover {
    cursor: pointer;
    background: ${({ bgColor }) => bgColor || '#eee'};
  }
`;

// componente footer del aside
// const Footer = styled.div`
//   width: 100%;
//   height: 50px;
//   background: #fff;
//   position: absolute;
//   bottom: 0;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

export const NewAsideV: React.FC<TNewAside> = ({
  isOpen,
  userInfo,
  data,
  headerType,
  drawerOpen,
  toggleAside,
  handleDrawerOpen,
  gradientAside,
  layoutType,
  closeExpansions,
  closeAllExpansion,
}: TNewAside) => {
  const theme = useTheme();

  const styles = {
    subTitle: {
      fontSize: '0.8em',
      textAlign: 'left',
      width: '100%',
      color: theme.palette.primary.main,
    },
    subTitleModule: {
      fontWeight: 'regular',
      fontSize: '0.8em',
      textAlign: 'left',
      width: '100%',
      color: '#fff',
    },
    icon: {
      fontSize: isOpen ? '20px' : '24px',
      color: '#fff',
      marginTop: '6px',
    },
    iconDot: {
      fontSize: '14px',
      color: '#fff',
      marginTop: '5px',
    },
  };
  return (
    <Aside
      isOpen={isOpen}
      marginTop={headerType === 3 && layoutType !== 2 ? '15px' : '0px'}
      widthCollapsed="80px" /* Anchura del aside cuando está oculto */
      widthExpanded="240px" /* Anchura del aside cuando está visible */
      bgColor={
        gradientAside
          ? `linear-gradient(160deg, rgba(1,18,41,1) 10%, rgba(0,20,46,1) 30%, rgba(0,31,71,1) 50%, rgba(0,31,71,1) 70%, rgba(6,47,85,1) 90%, rgba(6,78,142,1) 100%)`
          : theme.palette.primary.main
      }>
      <div
        style={{
          display: 'flex',
          padding: '30px 6px 0 3px',
          flexDirection: 'column',
          opacity: 1,
        }}>
        {data?.map((x: TMenu, indexX: number) => {
          const key = `keyX-${indexX}`;
          return (
            <MenuOptionV
              key={key}
              closeExpansions={closeExpansions}
              closeAllExpansion={closeAllExpansion}
              toggleAside={toggleAside}
              colorBg={theme.palette.primary.main}
              textColor="#fff"
              title={isOpen ? x?.name : ''}
              titlePopper={x?.name}
              isOpen={isOpen}
              icon={
                (x?.name === 'INICIO' && <Home style={styles.icon} />) ||
                (x?.name === 'Beneficiarios' && <Inventory2OutlinedIcon style={styles.icon} />) ||
                (x?.name === 'Beneficios' && <FormatListBulletedIcon style={styles.icon} />) ||
                (x?.name === 'ETAPAS' && <LabelIcon style={styles.icon} />) ||
                (x?.name === 'Admin de usuarios' && <ManageAccountsIcon style={styles.icon} />) ||
                (x?.name === 'Comercios' && <Diversity3Icon style={styles.icon} />) ||
                (x?.name === 'Membresía' && <LoyaltyIcon style={styles.icon} />) ||
                (x?.name === 'Vendedores' && <StoreIcon style={styles.icon} />) ||
                (x?.name === 'BENEFICIARIOS' && <GroupsIcon style={styles.icon} />) ||
                (x?.name === 'COMERCIALES' && <FolderSharedIcon style={styles.icon} />) ||
                (x?.name === 'USUARIOS' && <PeopleAltIcon style={styles.icon} />) ||
                (x?.name === 'CARACTERÍSTICAS' && <ScatterPlotIcon style={styles.icon} />) ||
                (x?.name === 'UTILITARIOS' && <HomeRepairServiceIcon style={styles.icon} />) ||
                (x?.name === 'CARTERA' && <AccountBalanceWalletIcon style={styles.icon} />) ||
                (x?.name === 'PARÁMETROS' && <PlumbingIcon style={styles.icon} />) ||
                (x?.name === 'LOTES' && <WidgetsIcon style={styles.icon} />) ||
                (x?.name === 'ADMINISTRACIÓN' && <AdminPanelSettingsIcon style={styles.icon} />) ||
                (x?.name === 'PAGOS ELECTRÓNICOS' && <DvrIcon style={styles.icon} />)
              }>
              {x?.subMenus && x?.subMenus?.length > 0 ? (
                x?.subMenus?.map((y: TSubMenus, indexY: number) => {
                  const keySubModule = `keyY-${indexY}`;
                  return y.options?.length ? (
                    <MenuOptionV
                      key={keySubModule}
                      isOpen={isOpen}
                      closeExpansions={closeExpansions}
                      closeAllExpansion={closeAllExpansion}
                      toggleAside={toggleAside}
                      colorBg={theme.palette.primary.light}
                      textColor={theme.palette.primary.dark}
                      title={y.name}
                      principalMargin="0">
                      {y?.options && y?.options?.length > 0 ? (
                        y?.options?.map((z: TOptions, indexZ: number) => {
                          const keyOption = `keyZ-${indexZ}`;
                          return (
                            <Link
                              to={z?.route}
                              style={{
                                textDecoration: 'none',
                              }}
                              key={keyOption}>
                              <MenuOptionLink>
                                <Typography sx={styles.subTitle}>{z?.name}</Typography>
                              </MenuOptionLink>
                            </Link>
                          );
                        })
                      ) : (
                        <Link
                          to={y?.route || ''}
                          style={{
                            textDecoration: 'none',
                          }}>
                          <MenuOptionLink bgColor={theme.palette.primary.light}>
                            <Typography sx={styles.subTitle}>{y?.name}</Typography>
                          </MenuOptionLink>
                        </Link>
                      )}
                    </MenuOptionV>
                  ) : (
                    <Link
                      to={y?.route || ''}
                      key={keySubModule}
                      style={{
                        textDecoration: 'none',
                      }}>
                      <MenuOptionLink bgColor={theme.palette.primary.light}>
                        <Typography sx={styles.subTitle}>{y?.name}</Typography>
                      </MenuOptionLink>
                    </Link>
                  );
                })
              ) : (
                <Link
                  key={key}
                  to={x?.route || ''}
                  style={{
                    textDecoration: 'none',
                  }}>
                  <MenuOptionLink bgColor={theme.palette.secondary.main}>
                    <Typography sx={styles.subTitle}>{x?.name}</Typography>
                  </MenuOptionLink>
                </Link>
              )}
            </MenuOptionV>
          );
        })}
      </div>
      <Drawer
        open={drawerOpen}
        onClose={() => handleDrawerOpen()}
        sx={{
          width: '270px',
          flexShrink: 0,
          left: '270px',
          '& .MuiDrawer-paper': {
            left: '270px',
            width: '270px',
            top: '80px',
            zIndex: 2,
            backgroundColor: '#fff',
          },
        }}>
        as
      </Drawer>
    </Aside>
  );
};
