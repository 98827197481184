import * as yup from 'yup';

const schema = yup
  .object({
    idDocumentType: yup.number().required('Campo requerido'),
    identificationNumber: yup
      .number()
      .typeError('Este campo es requerido')
      .required('Este campo es requerido')
      .test(
        'is-between-8-and-11-digits',
        'Número de identificación debe tener entre 8 y 11 dígitos',
        (value) => {
          if (typeof value !== 'number') {
            return false;
          }
          const stringValue = String(Math.floor(value));
          const digitCount = stringValue.length;
          return digitCount >= 8 && digitCount <= 11;
        }
      ),

    idGender: yup.number().nullable().notRequired().optional(),
    thirdPartyBeneficiary: yup.object({
      idThirdParty: yup.number().required('Campo requerido'),
    }),
    firstName: yup.string().nullable().notRequired().optional(),
    middleName: yup.string().nullable().notRequired().optional(),
    firstSurname: yup.string().nullable().notRequired().optional(),
    secondSurname: yup.string().nullable().notRequired().optional(),
    address: yup.object({
      neighBorhood: yup.string().nullable().notRequired().optional(),
      idAddressSecondStreetType: yup.number().nullable().notRequired().optional(),
      idAddressFirstStreetType: yup.number().nullable().notRequired().optional(),
      secondStreetNumber: yup.string().nullable().notRequired().optional(),
      thirdStreetNumber: yup.string().nullable().notRequired().optional(),
      firstStreetNumber: yup.string().nullable().notRequired().optional(),
      idDepartment: yup.number().nullable().notRequired().optional(),
      description: yup.string().nullable().notRequired().optional(),
      idCity: yup.number().nullable().notRequired().optional(),
    }),
    cellPhone: yup
      .number()
      .required()
      .test('is-between-10-and-10-digits', 'El celular debe tener 10 dígitos', (value) => {
        if (typeof value !== 'number') {
          return false;
        }
        const stringValue = String(Math.floor(value));
        const digitCount = stringValue.length;
        return digitCount >= 10 && digitCount <= 10;
      })
      .typeError('Este campo debe contener números'),
    email: yup.string().nullable().notRequired().optional(),
    telephone: yup.string().optional(),
    searchBenefits: yup.string().optional(),
  })
  .required();

export default schema;
