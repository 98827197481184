import { AxiosError } from 'axios';
import { ResponseUserPermission } from '../../../core/domain/userPermissions/userPermission/model/responseUserPermission';

const actions = {
  USER_PERMISSION_LIST_PAGE_BEGIN: 'USER_PERMISSION_LIST_PAGE_BEGIN',
  USER_PERMISSION_PAGE_SUCCESS: 'USER_PERMISSION_LIST_PAGE_SUCCESS',
  USER_PERMISSION_LIST_PAGE_ERR: 'USER_PERMISSION_LIST_PAGE_ERR',

  userPermissionListPageBegin: (): { type: string } => {
    return {
      type: actions.USER_PERMISSION_LIST_PAGE_BEGIN,
    };
  },

  userPermissionListPageSuccess: (
    data: ResponseUserPermission[] | ResponseUserPermission
  ): {
    type: string;
    data: ResponseUserPermission[] | ResponseUserPermission;
  } => {
    return {
      type: actions.USER_PERMISSION_PAGE_SUCCESS,
      data,
    };
  },

  userPermissionListPageErr: (err: AxiosError): { type: string; err: AxiosError } => {
    return {
      type: actions.USER_PERMISSION_LIST_PAGE_ERR,
      err,
    };
  },
};

export default actions;
