import { container } from 'tsyringe';
import { IDocumentsTypesInfrastructure } from '../../../domain/interface/infrastructure/documentsPersons/IDocumentsTypesInfrastructure';
import { IDocumentsTypesApplication } from '../../../domain/interface/application/documentsPersons/IDocumentsTypesApplication';
import { RequestDocumentsTypes } from '../../../domain/documentsPersons/documentTypes/model/requestDocumentsTypes';
import { ResponseDocumentsTypes } from '../../../domain/documentsPersons/documentTypes/model/responseDocumentsTypes';

export class DocumentsTypesApplication implements IDocumentsTypesApplication {
  private documentsTypesInfrastructure: IDocumentsTypesInfrastructure;

  public constructor() {
    this.documentsTypesInfrastructure = container.resolve<IDocumentsTypesInfrastructure>(
      'IDocumentsTypesInfrastructure'
    );
  }

  public async getDocumentsTypes(
    request: RequestDocumentsTypes
  ): Promise<ResponseDocumentsTypes[]> {
    const result = await this.documentsTypesInfrastructure.getDocumentsTypesAPI(request);
    return result;
  }
}
