import { Theme } from '@mui/material';
import { TStyle } from './types.d';

export const styleSheet = (theme: Theme) => {
  const style: TStyle = {
    modal: {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      borderRadius: '5px',
      width: '50%',
      height: '30%',
      [theme.breakpoints.down('sm')]: {
        width: '90%',
        height: '30%',
      },
      [theme.breakpoints.up('md')]: {
        width: '45%',
        height: '30%',
      },
      [theme.breakpoints.up('lg')]: {
        width: '27%',
        height: '30%',
      },
    },
    title: {
      fontSize: '26px',
      opacity: '0.8',
      fontWeight: 'bold',
      display: 'flex',
      width: '100%',
      flexGrow: 1,
      textAlign: 'center',
      justifyContent: 'center',
      color: theme.palette.primary.main,
    },
    subTitle: {
      display: 'flex',
      flexGrow: 1,
      textAlign: 'center',
      justifyContent: 'center',
      color: 'black',
      [theme.breakpoints.down('sm')]: {
        fontSize: '15px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '17px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '17px',
      },
    },
    buttonOption: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '5px',
      marginLeft: '20px',
      color: 'white',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        color: 'black',
        cursor: 'pointer',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
        marginLeft: '10px',
      },
    },
    boxIcon: {
      backgroundColor: theme.palette.primary.main,
      width: '30%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '5px 0  0 5px',
      [theme.breakpoints.down('sm')]: {
        width: '40%',
        borderRadius: '5px 0 0 5px',
      },
    },
    boxInfo: {
      backgroundColor: '#ffff',
      width: '70%',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      borderRadius: '0 5px 5px 0',
      [theme.breakpoints.down('sm')]: {
        width: '60%',
        borderRadius: '0 5px 5px 0',
      },
    },
  };
  return style;
};
