import { AxiosError } from 'axios';
import { ResponseGender } from '../../../core/domain/documentsPersons/gender/model/responseGender';

const actions = {
  GENDER_LIST_PAGE_BEGIN: 'GENDER_TYPES_LIST_PAGE_BEGIN',
  GENDER_LIST_PAGE_SUCCESS: 'GENDER_TYPES_LIST_PAGE_SUCCESS',
  GENDER_LIST_PAGE_ERR: 'GENDER_TYPES_LIST_PAGE_ERR',

  genderListPageBegin: (): { type: string } => {
    return {
      type: actions.GENDER_LIST_PAGE_BEGIN,
    };
  },

  genderListPageSuccess: (data: ResponseGender[]): { type: string; data: ResponseGender[] } => {
    return {
      type: actions.GENDER_LIST_PAGE_SUCCESS,
      data,
    };
  },

  genderListPageErr: (err: AxiosError): { type: string; err: AxiosError } => {
    return {
      type: actions.GENDER_LIST_PAGE_ERR,
      err,
    };
  },
};

export default actions;
