import { Box, Button, CircularProgress, Grid, Tooltip, Typography, useTheme } from '@mui/material';
import React from 'react';
import SaveIcon from '@mui/icons-material/Save';
import { InputField, NormalSelectField } from '../../../../../common/components/formAdapters';
import { ILegalProviderRegister } from './types.d';
import { onlyLetters, onlyNumbers } from '../../../../../common/utils/functions';

export const LegalProviderV: React.FC<ILegalProviderRegister> = ({
  schema,
  control,
  dataCities,
  dataDepartments,
  dataStreetTypes,
  watchDepartment,
  watchIdStreetTypeOne,
  handleRegister,
  createLoading,
  updateLoading,
  onChangeInput,
  idThirdParty,
  handleUpdate,
  idNature,
}) => {
  const theme = useTheme();
  const styles = {
    header: {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      fontSize: '30px',
      textAlign: 'left',
      fontFamily: 'Poppins-Regular',
      pl: '50px',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    formBox: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      background: '#fff',
      borderRadius: '0px 16px 16px',
      boxShadow: '0px 3px 6px #00000029',
      minWidth: '305px',
    },
    buttonSubmit: {
      width: '135px',
      height: '35px',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      justifyContent: 'center',
      justifyItems: 'center',
      display: 'flex',
      '@media (max-width: 899px)': {
        width: '200px',
      },
      alignItems: 'center',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);',
    },
    buttonBox: {
      display: 'flex',
      justifyContent: 'flex-end',
      '@media screen and (max-width: 899px)': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
  };
  return (
    <Box
      style={{
        width: '100%',
      }}>
      <Box sx={styles.formBox}>
        <Box
          sx={{
            padding: '25px',
            height: '100%',
            width: '100%',
          }}>
          <Typography variant="h5" color={theme.palette.primary.main} sx={{ pb: '15px' }}>
            Información del comercio
          </Typography>
          <Box>
            <Grid container spacing={{ xs: 2, sm: 2, md: 2 }} sx={{ marginBottom: '10px' }}>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <InputField
                  onKeyDown={onlyNumbers}
                  label="NIT"
                  inputProps={{ maxLength: 11, minLength: 8 }}
                  name="identificationNumber"
                  schema={schema.fields?.identificationNumber}
                  customOnChange={onChangeInput}
                  onBlur={onChangeInput}
                  control={control}
                  disabled={!!idNature}
                />
              </Grid>
              <Grid item xs={5} sm={3} md={2} lg={1} xl={1} sx={{ width: '100%' }}>
                <InputField
                  disabled
                  label="DV"
                  name="dv"
                  schema={schema.fields?.dv}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <InputField
                  label="Razón social"
                  name="businessName"
                  schema={schema.fields?.businessName}
                  control={control}
                  disabled={!!idNature}
                />
              </Grid>
            </Grid>
            <Box>
              <Typography variant="h5" color={theme.palette.primary.main} sx={{ pb: '15px' }}>
                Representante legal
              </Typography>
              <Grid container spacing={{ xs: 2, sm: 2, md: 2 }} sx={{ marginBottom: '10px' }}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <InputField
                    label="Primer nombre"
                    name="firstName"
                    schema={schema.fields?.firstName}
                    control={control}
                    disabled={!!idNature}
                    onKeyDown={onlyLetters}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <InputField
                    label="Segundo nombre"
                    name="middleName"
                    schema={schema.fields?.middleName}
                    control={control}
                    disabled={!!idNature}
                    onKeyDown={onlyLetters}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <InputField
                    label="Primer apellido"
                    name="firstSurname"
                    schema={schema.fields?.firstSurname}
                    control={control}
                    disabled={!!idNature}
                    onKeyDown={onlyLetters}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <InputField
                    label="Segundo apellido"
                    name="secondSurname"
                    schema={schema.fields?.secondSurname}
                    control={control}
                    disabled={!!idNature}
                    onKeyDown={onlyLetters}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Typography variant="h5" color={theme.palette.primary.main} sx={{ pb: '15px' }}>
                Ubicación y contacto
              </Typography>
              <Grid container spacing={{ xs: 2, sm: 2, md: 2 }} sx={{ marginBottom: '10px' }}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <NormalSelectField
                    name="address.idDepartment"
                    label="Departamento"
                    key="address.idDepartment"
                    schema={schema?.fields?.address?.idDepartment}
                    control={control}
                    options={
                      (dataDepartments || [])?.map((x) => ({
                        value: x?.idDepartment,
                        label: x?.name?.toUpperCase(),
                      })) || []
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <NormalSelectField
                    name="address.idCity"
                    label="Ciudad"
                    key="address.idCity"
                    schema={schema?.fields?.address?.idCity}
                    control={control}
                    options={
                      (dataCities || [])
                        ?.filter((x) => x?.idDepartment === Number(watchDepartment))
                        .map((x) => {
                          return {
                            value: x?.idCity,
                            label: x?.name?.toUpperCase(),
                          };
                        }) || []
                    }
                    disabled={!watchDepartment}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <InputField
                    label="Barrio"
                    name="address.neighBorhood"
                    schema={schema.fields?.neighBorhood}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <NormalSelectField
                    name="address.idAddressFirstStreetType"
                    label="Tipo de dirección 1"
                    schema={schema?.fields?.idAddressFirstStreetType}
                    control={control}
                    options={
                      (dataStreetTypes || [])?.map((x) => ({
                        value: x?.idStreetType,
                        label: x?.name,
                      })) || []
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <InputField
                    label="Número"
                    name="address.firstStreetNumber"
                    schema={schema.fields?.address.firstStreetNumber}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <NormalSelectField
                    name="address.idAddressSecondStreetType"
                    label="Tipo de dirección 2"
                    schema={schema?.fields?.idAddressSecondStreetType}
                    control={control}
                    options={
                      (dataStreetTypes || [])
                        ?.filter((x) => x?.idStreetType !== Number(watchIdStreetTypeOne))
                        .map((x) => ({
                          value: x?.idStreetType,
                          label: x?.name,
                        })) || []
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <InputField
                    label="Número 2"
                    name="address.secondStreetNumber"
                    schema={schema.fields?.address?.secondStreetNumber}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <InputField
                    label="Número 3"
                    name="address.thirdStreetNumber"
                    schema={schema.fields?.address?.thirdStreetNumber}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <InputField
                    label="Detalle"
                    name="address.description"
                    schema={schema.fields?.address?.description}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <InputField
                    label="Celular"
                    name="cellPhone"
                    schema={schema.fields?.cellPhone}
                    control={control}
                    onKeyDown={onlyNumbers}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <InputField
                    label="Teléfono"
                    name="telephone"
                    schema={schema.fields?.telephone}
                    control={control}
                    onKeyDown={onlyNumbers}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <InputField
                    label="Correo electrónico"
                    type="email"
                    name="email"
                    schema={schema.fields?.email}
                    control={control}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box sx={styles.buttonBox}>
            <Tooltip
              title={idThirdParty ? 'Actualizar comercio jurídico' : 'Registrar comercio jurídico'}>
              <Button
                sx={styles.buttonSubmit}
                disabled={createLoading || updateLoading}
                type="button"
                onClick={idThirdParty ? handleUpdate : handleRegister}
                style={{ color: '#fff' }}
                startIcon={createLoading || updateLoading ? null : <SaveIcon fontSize="small" />}>
                {createLoading || updateLoading ? (
                  <CircularProgress size={15} sx={{ color: 'white' }} />
                ) : (
                  <Typography fontSize="14px" sx={{ display: 'flex', alignItems: 'center' }}>
                    {idThirdParty ? 'ACTUALIZAR' : 'CREAR'}
                  </Typography>
                )}
              </Button>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
