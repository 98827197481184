import { useEffect, useState } from 'react';
import RedeemedBenefitsV from '../../../components/benefits/redeemedBenefits';
import {
  TBenefitByThirdPartyReducer,
  TBenefitsForExcelReducer,
} from '../../../../../redux/benefits/benefits/types';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { TLoginDispatch } from '../../login/types';
import {
  benefitsForExcelListPage,
  benefitsWithThirdPartyListPage,
} from '../../../../../redux/benefits/benefits/actionCreator';
import { TDispatch } from '../../../../../common/utils/types';

export const RedeemedBenefitsC = () => {
  /**
   * States
   * TdataBenefits
   */

  const [allDataBenefits, setAllDataBenefits] = useState<any>([]);

  /**
   * Dispatch
   */
  const dispatch = useDispatch();

  /**
   * Dispatch de login
   */
  const loginDispatch: TLoginDispatch = useSelector((dataState: RootState) => dataState?.loginData);

  /**
   * Obtener todos los beneficios
   */
  const getAllBenefitsForExcelDispatch: TBenefitsForExcelReducer = useSelector(
    (dataState: RootState) => dataState?.benefitsForExcel
  );

  /**
   * Obtener todos los beneficios por comercio
   */
  const getAllBenefitsByThirdPartyDispatch: TBenefitByThirdPartyReducer = useSelector(
    (dataState: RootState) => dataState.BenefitByThirdPartyReducer
  );

  /**
   * UseEffect para mostrar todos los beneficiarios
   */
  useEffect(() => {
    if (
      getAllBenefitsForExcelDispatch?.benefitsForExcel instanceof Array &&
      loginDispatch?.login?.userType === 'Administrador'
    ) {
      const data = getAllBenefitsForExcelDispatch?.benefitsForExcel?.map((x, i) => {
        const endOfBenefit = x?.benefitsValidities?.map(
          (validity: any) => new Date(validity?.endDate)
        )[0];
        const currentDate = new Date();
        const periodicities = x?.benefitsValidities?.map((validity: any) => validity?.periodicity);
        periodicities.forEach((p: number) => {
          return p;
        });
        let totalConsumed = 0;
        x?.benefitBeneficiaries?.forEach((element: any) => {
          totalConsumed += element?.consumed;
        });
        return {
          ...x,
          code: x?.number?.toUpperCase(),
          thirdParty: x?.thirdParty?.businessName?.toUpperCase(),
          benefitName: x?.name?.toUpperCase(),
          totalConsumed,
          isActive: currentDate < endOfBenefit ? 'ACTIVO' : 'EXPIRADO',
        };
      });
      setAllDataBenefits(data);
    } else if (
      getAllBenefitsByThirdPartyDispatch?.benefitByThirdParty instanceof Array &&
      loginDispatch?.login?.userType === 'Comercio'
    ) {
      const filteredData = getAllBenefitsByThirdPartyDispatch?.benefitByThirdParty?.map(
        (value, i) => {
          const endOfBenefit = value?.benefitsValidities?.map(
            (validity: any) => new Date(validity?.endDate)
          )[0];
          const currentDate = new Date();
          let totalConsumed = 0;
          value?.benefitBeneficiaries?.forEach((element: any) => {
            totalConsumed += element?.consumed;
          });
          return {
            ...value,
            code: value?.number,
            thirdParty: value?.thirdParty?.businessName?.toUpperCase(),
            benefitName: value?.name?.toUpperCase(),
            totalConsumed,
            isActive: currentDate < endOfBenefit ? 'ACTIVO' : 'EXPIRADO',
          };
        }
      );
      setAllDataBenefits(filteredData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllBenefitsByThirdPartyDispatch, getAllBenefitsForExcelDispatch, loginDispatch]);

  /**
   * UseEffect para llamar todos los dispatch
   */
  useEffect(() => {
    if (loginDispatch?.login?.idThirdParty)
      dispatch(benefitsWithThirdPartyListPage(loginDispatch?.login?.idThirdParty) as TDispatch);

    dispatch(benefitsForExcelListPage() as TDispatch);
  }, [dispatch, loginDispatch]);

  return (
    <RedeemedBenefitsV
      allDataBenefits={allDataBenefits}
      loading={getAllBenefitsByThirdPartyDispatch.loading}
    />
  );
};
