import styled from 'styled-components';
interface ButtonProps{
  backgroundColor?: string;
  hoverStyles?: string;
}

export const ButtonSubmit = styled.button<ButtonProps>`
  width: 135px;
  height: 35px;
  justify-content: center;
  justify-items: center;
  gap: 5px;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 5px;
  opacity: 1;
  cursor: pointer;
  color: white;
  background-color: ${props => props.backgroundColor || '#243EBD'};
  transition: 0.3s;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  &:hover {
    background-color: ${props => props.hoverStyles || '#062A57'};
  }
  @media (max-width: 899px) {
    width: 200px;
  }
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;
