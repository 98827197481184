export class ResponseDepartments {
  public idDepartment? = 0;

  public idCountry? = 0;

  public code? = '';

  public name = '';

  public state? = 0;
}
