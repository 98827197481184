import 'reflect-metadata';
import { AxiosResponse } from 'axios';
import { IDepartmentsInfrastructure } from '../../../domain/interface/infrastructure/addresses/IDepartmentsInfrastructure';
import { RequestDepartments } from '../../../domain/addresses/departments/model/requestDepartments';
import { ResponseDepartments } from '../../../domain/addresses/departments/model/responseDepartments';
import { processDataBackend } from '../../../../common/utils/functions';

export class DepartmentsInfrastructure implements IDepartmentsInfrastructure {
  private a = '';

  public async getDepartmentsAPI(request: RequestDepartments): Promise<ResponseDepartments[]> {
    this.a = 'aaa';
    const res: AxiosResponse = await processDataBackend(
      'GET',
      '/api/departments/getAll',
      {},
      { request }
    );

    const departments: ResponseDepartments[] = res.data;

    return Promise.resolve(departments);
  }
}
