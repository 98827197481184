import { AxiosError } from 'axios';
import 'reflect-metadata';
import { container } from 'tsyringe';
import { AppDispatch } from '../store';
import { actionsLogin, actionsLogout, actionsRefresh } from './actions';
import { ILoginApplication } from '../../core/domain/interface/application/security/ILoginApplication';
import { RequestLogin } from '../../core/domain/security/model/request/requestLogin';
import { ILogoutApplication } from '../../core/domain/interface/application/security/ILogoutApplication';
import { IRefreshApplication } from '../../core/domain/interface/application/security/IRefreshApplication';

const { loginPageBegin, loginPageSuccess, loginPageSuccessData, loginPageErr } = actionsLogin;
const { logoutPageBegin, logoutPageSuccess, logoutPageErr } = actionsLogout;
const { refreshPageBegin, refreshPageSuccess, refreshPageErr } = actionsRefresh;

export const LoginUser = (loginState: RequestLogin): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(loginPageBegin());
      const loginApplication = container.resolve<ILoginApplication>('ILoginApplication');

      const resultLogin = await loginApplication.handle(loginState);
      dispatch(loginPageSuccess(resultLogin));
      dispatch(loginPageSuccessData(resultLogin));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(loginPageErr(error));
    }
  };
};
export const LogoutUser = (token: string): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(logoutPageBegin());
      const logoutApplication = container.resolve<ILogoutApplication>('ILogoutApplication');

      const resultLogout = await logoutApplication.handle(token);
      dispatch(logoutPageSuccess(resultLogout));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(logoutPageErr(error));
    }
  };
};
export const RefreshUser = (
  loginState: RequestLogin
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(refreshPageBegin());
      const loginApplication = container.resolve<IRefreshApplication>('IRefreshApplication');

      const resultLogin = await loginApplication.handle(loginState);
      dispatch(refreshPageSuccess(resultLogin));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(refreshPageErr(error));
    }
  };
};

export const clearLogin = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(loginPageBegin());
    dispatch(loginPageErr(undefined));
    dispatch(loginPageSuccess(undefined));
  };
};
export const clearLogout = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(logoutPageBegin());
    dispatch(logoutPageErr(undefined));
    dispatch(logoutPageSuccess(undefined));
  };
};
export const clearRefresh = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(refreshPageBegin());
    dispatch(refreshPageErr(undefined));
    dispatch(refreshPageSuccess(undefined));
  };
};
