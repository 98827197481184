import { AxiosError } from 'axios';
import { ResponseUserType } from '../../../core/domain/userPermissions/userType/model/responseUserType';
import { UserTypeState } from '../../../core/domain/userPermissions/userType/state/userTypeState';
import { actionsUserTypes } from './actions';
import { TUserTypesReducer } from './types.d';

const { USER_TYPES_LIST_PAGE_BEGIN, USER_TYPES_LIST_PAGE_SUCCESS, USER_TYPES_LIST_PAGE_ERR } =
  actionsUserTypes;

const initialUserType: UserTypeState = new UserTypeState();

const UserTypesReducer = (
  state = initialUserType,
  action: { type: string; err: AxiosError | undefined; data: ResponseUserType[] | undefined }
): TUserTypesReducer => {
  const { type, data, err } = action;
  switch (type) {
    case USER_TYPES_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USER_TYPES_LIST_PAGE_SUCCESS:
      return {
        ...state,
        userTypes: data,
        loading: false,
      };
    case USER_TYPES_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { UserTypesReducer };
