import React from 'react';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
// import tramaFondoLogin from '../../../../../assets/img/trama4.svg';
import pkg from '../../../../../../package.json';
import { IProviderLogin } from './types.d';
import { ButtonPassword, Img, ButtonSubmit } from './styled';
import { InputField } from '../../../../../common/components/formAdapters';
import image from '../../../../../common/components/images';

export const ProviderLoginV: React.FC<IProviderLogin> = ({
  control,
  errors,
  handleSubmit,
  schema,
  register,
  goToDashboard,
  login,
  loading,
}) => {
  const theme = useTheme();
  const LogoBonusColor = image('logoBonusColor');
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        background: 'lightgray',
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '425px',
          '@media screen and (max-width: 425px)': {
            minWidth: '320px',
          },
          background: 'white',
          boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);',
          overflow: 'auto',
        }}>
        <Box
          sx={{
            height: '85%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Img src={LogoBonusColor} alt="Bonus plus" />
          <Box
            sx={{
              height: '270px',
              width: '80%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Typography
              variant="h4"
              sx={{ fontFamily: 'Poppins-Regular', fontWeight: '600' }}
              color={theme.palette.primary.main}>
              Bienvenido
            </Typography>
            <InputField
              name="email"
              control={control}
              label="Usuario"
              schema={schema.fields.email}
            />
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
              }}>
              <InputField
                iconType="showPasswordEye"
                control={control}
                label="Contraseña"
                type="password"
                name="password"
                schema={schema.fields.password}
              />
              <Box>
                <ButtonPassword type="button">
                  <Typography fontSize="11px" color={theme.palette.primary.main}>
                    ¿Olvidó su contraseña?
                  </Typography>
                </ButtonPassword>
              </Box>
            </Box>
            <ButtonSubmit
              disabled={loading}
              type="submit"
              style={{ color: '#fff' }}
              onClick={handleSubmit(login)}>
              {loading ? <CircularProgress size={25} color="primary" /> : 'Ingresar'}
            </ButtonSubmit>
          </Box>
          <Box
            sx={{
              width: '90%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: '15px',
              textAlign: 'center',
              opacity: 1,
            }}>
            <Typography fontSize="14px" color={theme.palette.primary.main}>
              Al hacer clic en iniciar, estás aceptando nuestros{' '}
              <span style={{ fontWeight: 'bold' }}>términos y condiciones de servicio</span> y estás
              aceptando nuestras{' '}
              <span style={{ fontWeight: 'bold' }}>políticas de privacidad de datos.</span>
            </Typography>
            <Typography
              color={theme.palette.primary.main}
              sx={{
                fontSize: '11px',
                padding: '5px',
                width: '100%',
                textAlign: 'center',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}>
              ® WOW Desarrollos Digitales - Bonus plus {pkg.version}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
