import { AxiosError } from 'axios';
import { ResponseAddress } from '../../../core/domain/addresses/address/model/responseAddress';
import { AddressesState } from '../../../core/domain/addresses/address/state/addressesState';
import actions from './actions';
import { TAddressesReducer } from './types';

const { ADDRESSES_LIST_PAGE_BEGIN, ADDRESSES_LIST_PAGE_SUCCESS, ADDRESSES_LIST_PAGE_ERR } = actions;

const initialAddresses: AddressesState = new AddressesState();

const AddressesReducer = (
  state = initialAddresses,
  action: { type: string; err: AxiosError; data: ResponseAddress[] }
): TAddressesReducer => {
  const { type, data, err } = action;

  switch (type) {
    case ADDRESSES_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADDRESSES_LIST_PAGE_SUCCESS:
      return {
        ...state,
        addresses: data,
        loading: false,
      };
    case ADDRESSES_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default AddressesReducer;
