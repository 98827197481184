import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import ValidateCouponV from '../../../components/benefits/validateCoupon';

const ValidateCouponC = () => {
  /**
   * Schema
   */
  const schema = yup
    .object({
      validationCode: yup.number().required('Campo requerido'),
    })
    .required();

  /**
   * useForm
   */
  const { /* reset, setValue, getValues, handleSubmit, */ control, watch } = useForm({
    resolver: yupResolver(schema),
  });

  const handleValidation = () => {
    if (!watch('validationCode')) {
      toast.error('No has ingresado el código de validación');
    } else {
      toast.error('No has ingresado el código de validación');
    }
  };

  return <ValidateCouponV schema={schema} control={control} handleValidation={handleValidation} />;
};

export default ValidateCouponC;
