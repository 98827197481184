import 'reflect-metadata';
import { AxiosResponse } from 'axios';
import { IBankAccountTypesInfrastructure } from '../../../domain/interface/infrastructure/banks/IBankAccountTypesInfrastructure';
import { RequestBankAccountTypes } from '../../../domain/banks/bankAccountTypes/model/requestBankAccountTypes';
import { ResponseBankAccountTypes } from '../../../domain/banks/bankAccountTypes/model/responseBankAccountTypes';
import { processDataBackend } from '../../../../common/utils/functions';

export class BankAccountTypesInfrastructure implements IBankAccountTypesInfrastructure {
  private a = '';

  public async getBankAccountTypesAPI(
    request: RequestBankAccountTypes
  ): Promise<ResponseBankAccountTypes[]> {
    this.a = 'aaa';

    const res: AxiosResponse = await processDataBackend(
      'GET',
      '/api/bankAccountTypes/getAll',
      {},
      { request }
    );

    const bankAccountTypes: ResponseBankAccountTypes[] = res.data;

    return Promise.resolve(bankAccountTypes);
  }
}
