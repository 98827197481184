import React from 'react';
import LayoutC from '../../../containers/Layout';
import { RedeemedBenefitsC } from '../../../containers/benefits/redeemedBenefits';

const RedeemedBenefitsP = () => {
  return (
    <LayoutC>
      <RedeemedBenefitsC />
    </LayoutC>
  );
};

export default RedeemedBenefitsP;
