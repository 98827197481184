export class ResponseBenefitValidity {
  public idBenefitValidity = 0;

  public usagePerson = 0;

  public acquiredBenefit = 0;

  public useLimit? = 0;

  public periodicity? = 0;

  public purchase? = 0;

  public totalConsumed? = 0;

  public startDate = '';

  public endDate = '';

  public name = '';
}
