import React from 'react';
import { BeneficiariesListC } from '../../../containers/beneficiaries/beneficiariesList';
import LayoutC from '../../../containers/Layout';

const BeneficiariesListP = () => {
  return (
    <LayoutC>
      <BeneficiariesListC />
    </LayoutC>
  );
};

export default BeneficiariesListP;
