import React from 'react';
import { ProviderDashboardC } from '../../../containers/providers/providerDashboard';
// import LayoutC from '../../../containers/Layout';

const ProviderDashboardP = () => {
  return (
    // <LayoutC>
    <ProviderDashboardC />
    // </LayoutC>
  );
};

export default ProviderDashboardP;
