import React, { useContext, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate /* useParams */ } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTheme } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { TDispatch, TodoSuccessResponse } from '../../../../common/utils/types.d';
import { clearBenefitsWithPaginationListPage } from '../../../../redux/benefits/benefits/actionCreator';
import { benefitCategoryListPage } from '../../../../redux/benefits/benefitCategory/actionCreator';
import { PersonalInfoV } from '../../components/couponsPage/PersonalInfo';
import schema from './yupSchemaPersonalInfo';
import { Context } from '../../../../common/utils/userContext';
import { decryptData, filterKeyObject } from '../../../../common/utils/functions';
import { RootState } from '../../../../redux/store';
import {
  beneficiariesByBenefitsPage,
  clearUpdateBeneficiaryPage,
  getBeneficiaryByIdListPage,
  updateBeneficiaryPage,
} from '../../../../redux/beneficiaries/actionCreator';
import {
  TBeneficiaryReducer,
  TUpdateBeneficiaryReducer,
} from '../../../../redux/beneficiaries/types.d';
import {
  TCities,
  TDataDepartments,
  TDataDocumentType,
  TGender,
  TLoginDispatch,
  TStreetTypes,
} from '../../components/couponsPage/types.d';
import { TDocumentTypesReducer } from '../../../../redux/documentsPersons/documentsTypes/types.d';
import { TDepartmentsReducer } from '../../../../redux/addresses/departments/types';
import { TStreetTypesReducer } from '../../../../redux/addresses/streetTypes/types';
import { TCitiesReducer } from '../../../../redux/addresses/cities/types';
import { TGenderReducer } from '../../../../redux/documentsPersons/gender/types';
import { documentsTypesListPage } from '../../../../redux/documentsPersons/documentsTypes/actionCreator';
import { streetTypesListPage } from '../../../../redux/addresses/streetTypes/actionCreator';
import { departmentsListPage } from '../../../../redux/addresses/departments/actionCreator';
import { genderListPage } from '../../../../redux/documentsPersons/gender/actionCreator';
import { citiesListPage } from '../../../../redux/addresses/cities/actionCreator';
import { TLoginBeneficiaryReducer } from '../../../../redux/loginBeneficiary/types';
import { TDataBenefit } from './types';
import { getFileUrlS3 } from '../../../../lib';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import schemaUpdatePassword from './yupSchemaUpdatePassword';
import {
  clearUserLoggedUpdatePasswordListPage,
  userLoggedPasswordUpdatePage,
} from '../../../../redux/userPermissions/user/actionCreator';
import { TUpdateUserLoggedPasswordReducer } from '../../../../redux/userPermissions/user/types';

export const PersonalInfoC = () => {
  /**
   * navigate
   */
  const navigate = useNavigate();

  /*
    dispatch
    */
  const dispatch = useDispatch();

  /*
    useSearchParams
    */

  /*
    variables globales
    */

  /**
   * UseForm
   */
  const { control, reset, getValues, handleSubmit, watch } = useForm({
    resolver: yupResolver(schema),
  });

  /**
   * Watch del departamento seleccionado
   */
  const watchDepartment = watch('address.idDepartment');
  /**
   * Watch del tipo de dirección seleccionado
   */
  const watchIdStreetTypeOne = watch('address.idAddressFirstStreetType');

  /**
   * States
   */
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null);
  const { handleSessionCouponsPage } = useContext(Context);
  const [userCredentials, setUserCredentials] = useState({ userName: '', userType: '' });
  const [dataDepartments, setDataDepartments] = useState<TDataDepartments>([]);
  const [dataStreetTypes, setDataStreetTypes] = useState<TStreetTypes>([]);
  const [dataCities, setDataCities] = useState<TCities>([]);
  const [dataGender, setDataGender] = useState<TGender>([]);
  const [dataDocumentType, setDataDocumentType] = useState<TDataDocumentType>([]);
  const [benefitsDataMapped, setBenefitsDataMapped] = useState<TDataBenefit[]>([]);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [openModalPassword, setOpenModalPassword] = useState(false);

  /**
   * Dispatch de Login
   */
  const loginDispatch: TLoginBeneficiaryReducer = useSelector(
    (dataState: RootState) => dataState?.beneficiaryLoggedIn
  );

  const identificationNumber: number = Number(loginDispatch?.login?.identificationNumber);
  /**
   * Dispatch de actualizar beneficiario
   */
  const updateBeneficiaryDispatch: TUpdateBeneficiaryReducer = useSelector(
    (dataState: RootState) => dataState?.beneficiaryUpdated
  );

  const beneficiaryByBenefitCategoryDispatch: TBeneficiaryReducer = useSelector(
    (dataState: RootState) => dataState?.beneficiaryByBenefits
  );
  /**
   * Dispatch de tipos de documentos
   */
  const userDocumentTypeDispatch: TDocumentTypesReducer = useSelector(
    (dataState: RootState) => dataState?.documentsTypes
  );

  /**
   * Dispatch de beneficiario
   */
  const getBeneficiaryByIdDispatch: TBeneficiaryReducer = useSelector(
    (dataState: RootState) => dataState?.beneficiary
  );

  /**
   * Dispatch de ciudades
   */
  const citiesDispatch: TCitiesReducer = useSelector((dataState: RootState) => dataState?.cities);

  /**
   * Dispatch de género
   */
  const genderDispatch: TGenderReducer = useSelector((dataState: RootState) => dataState?.gender);

  /**
   * Dispatch de departamentos
   */
  const departmentsDispatch: TDepartmentsReducer = useSelector(
    (dataState: RootState) => dataState?.departments
  );

  /**
   * Dispatch de tipo de calles
   */
  const streetTypesDispatch: TStreetTypesReducer = useSelector(
    (dataState: RootState) => dataState?.streetTypes
  );

  /**
   * Dispatch de usuraios
   */
  const loginBeneficiaryDispatch: TLoginDispatch = useSelector(
    (dataState: RootState) => dataState?.loginBeneficiary
  );

  /**
   * Dispatch de updatePassword
   */
  const userLoggedUpdatePasswordDispatch: TUpdateUserLoggedPasswordReducer = useSelector(
    (dataState: RootState) => dataState?.loggedPasswordUpdated
  );

  /**
   * Functions
   */

  /**
   * Función que se encarga de actualizar un beneficiario
   */
  const handleUpdate = handleSubmit(async () => {
    dispatch(
      updateBeneficiaryPage({
        ...filterKeyObject(getValues(), ['idDocumentType', 'identificationNumber']),
        identificationNumber,
        idBeneficiary: getBeneficiaryByIdDispatch?.beneficiary?.idBeneficiary,
        telephone: watch('telephone') ? String(watch('telephone')) : null,
      }) as TDispatch
    );
    setLoading(true);
  });

  /*
   * Función para cerrar sección en el portal.
   */
  const handleMouseEnter = (event: React.SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleMouseLeave = () => {
    setOpen(false);
  };

  const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const stringAvatar = (name: string, location?: string) => {
    const avatarName = name || 'Usuario Bonus plus';
    return {
      sx: {
        bgcolor: location === 'header' ? theme.palette.primary.main : stringToColor(name),
        color: location === 'header' ? theme.palette.common.white : '#fff',
        fontSize: location === 'header' ? '0.95rem' : '1.25',
        width: location === 'header' ? '35px' : '45px',
        height: location === 'header' ? '35px' : '45px',
        marginTop: location === 'header' && '0',
        fontWeight: 'bold',
      },
      children: `${avatarName.split(' ')[0][0].toUpperCase()}${
        avatarName.split(' ')[1] ? avatarName.split(' ')[1][0].toUpperCase() : ''
      }`,
    };
  };
  const goToLoginBeneficiary = () => {
    navigate('/loginBeneficiario');
  };
  /*
   * Función para ver información personal.
   */
  const goToPersonalInfo = () => {
    navigate('/infoPersonal');
  };

  /*
   * Función ir al portal cupones.
   */
  const goBack = () => {
    setTimeout(() => {
      navigate('/');
    });
  };

  /*
   * Función para asignar imagen a la path por cada objeto.
   */
  const processBenefitsData = (benefits: TDataBenefit[]) => {
    return benefits.map((x: TDataBenefit) => {
      const img: string = x?.path || '';
      const result: string = getFileUrlS3(`benefits/${img}`);
      return { ...x, path: result || ImageNotSupportedIcon };
    });
  };

  // FUNCIÓN PARA OBTENER BENEFICIOS
  const handleBenefitCategory = () => {
    dispatch(
      beneficiariesByBenefitsPage(
        loginBeneficiaryDispatch?.login?.identificationNumber
      ) as TDispatch
    );
    navigate('/');
  };

  /**
   * useForm
   */
  const {
    handleSubmit: handleSubmitUpdatePassword,
    control: controlUpdatePassword,
    watch: watchUpdatePassword,
    reset: resetUpdatePassword,
  } = useForm({
    resolver: yupResolver(schemaUpdatePassword),
  });

  //funcion para enviar el cambio de contraseña y cerrar el modal
  const sendChangePassword = () => {
    setOpenModalPassword(!openModalPassword);
    setOpen(false);
    resetUpdatePassword({ password: '', newPassword: '', confirmPass: '' });
  };

  //funcion para validar todos los errores
  const validateAll = async (values: { newPassword: string }) => {
    try {
      await schemaUpdatePassword.validate(values, { abortEarly: false });
      return {}; // No hay errores
    } catch (error: any) {
      const errores: any = {};

      error.inner.forEach((e: { path: string | number; message: string }) => {
        if (!errores[e.path]) {
          errores[e.path] = [];
        }
        errores[e.path].push(e.message);
      });
      return errores; // Devuelve un objeto con todos los errores
    }
  };
  //funcion para cambiar la contraseña
  const newPassword = watchUpdatePassword('newPassword');
  useEffect(() => {
    validateAll({ newPassword: newPassword }).then((errores: { newPassword: string[] }) => {
      if (Object.keys(errores).length) {
        setValidationErrors(errores?.newPassword);
      }
    });
  }, [newPassword, watchUpdatePassword]);
  // extracción del token desde el almacenamiento local
  const token = localStorage.getItem('vs2-tk');

  /**
   * Función de validar contraseñas
   */
  const validatePassword = () => {
    setTimeout(() => {
      dispatch(
        userLoggedPasswordUpdatePage({
          password: watchUpdatePassword('password'),
          newPassword: watchUpdatePassword('confirmPass'),
          token: `${token}`,
        }) as TDispatch
      );
      setLoading(true);
    }, 500);
  };

  /**
   * UseEffect pendiente a nueva contraseña con limpieza de campos
   */
  useEffect(() => {
    if (loading)
      if (
        userLoggedUpdatePasswordDispatch?.userLoggedUpdatedPassword &&
        !userLoggedUpdatePasswordDispatch?.error
      ) {
        resetUpdatePassword({
          password: '',
          newPassword: '',
          confirmPass: '',
        });
        dispatch(clearUserLoggedUpdatePasswordListPage() as TDispatch);
        setOpenModalPassword(false);
        toast.success('Contraseña actualizada exitosamente', {
          hideProgressBar: true,
        });
      } else if (
        !userLoggedUpdatePasswordDispatch?.userLoggedUpdatedPassword &&
        userLoggedUpdatePasswordDispatch?.error
      ) {
        const message = userLoggedUpdatePasswordDispatch?.error?.response
          ? (userLoggedUpdatePasswordDispatch?.error?.response?.data as TodoSuccessResponse)
              ?.message
          : 'Ha ocurrido una incidencia';
        toast.error(`${message}`, {
          hideProgressBar: true,
        });
        dispatch(clearUserLoggedUpdatePasswordListPage() as TDispatch);
      }
  }, [
    userLoggedUpdatePasswordDispatch?.userLoggedUpdatedPassword,
    userLoggedUpdatePasswordDispatch?.error,
    dispatch,
    loading,
    resetUpdatePassword,
  ]);
  // MAPEANDO DATA
  useEffect(() => {
    if (!beneficiaryByBenefitCategoryDispatch.loading) {
      const data = beneficiaryByBenefitCategoryDispatch?.beneficiary?.benefitBeneficiaries?.map(
        (x: any) => {
          return {
            description: x?.benefit?.description,
            idBenefit: x?.benefit?.idBenefit,
            idCategory: x?.benefit?.idCategory,
            idThirdParty: x?.benefit?.idThirdParty,
            name: x?.benefit?.name,
            number: x?.benefit?.number,
            path: x?.benefit?.path,
            state: x?.benefit?.state,
            typeBeneficiary: x?.benefit?.typeBeneficiary,
          };
        }
      );
      const processedData = processBenefitsData(data || []);
      setBenefitsDataMapped(processedData);
    }
  }, [beneficiaryByBenefitCategoryDispatch]);

  /**
   * UseEffect para llamar documentTypes
   */
  useEffect(() => {
    setDataDocumentType(
      userDocumentTypeDispatch?.documentsTypes?.filter((item) => item?.idDocumentType !== 4) || []
    );
  }, [userDocumentTypeDispatch?.documentsTypes]);

  /**
   * UseEffect para llamar a Departments
   */
  useEffect(() => {
    setDataDepartments(departmentsDispatch?.departments || []);
  }, [departmentsDispatch?.departments]);

  /**
   * UseEffect para llamar a Cities
   */
  useEffect(() => {
    setDataCities(citiesDispatch?.cities || []);
  }, [citiesDispatch?.cities]);

  /**
   * UseEffect para llamar a StreetTypes
   */
  useEffect(() => {
    setDataStreetTypes(streetTypesDispatch?.streetTypes || []);
  }, [streetTypesDispatch?.streetTypes]);

  /**
   * UseEffect para llamar a Genders
   */
  useEffect(() => {
    setDataGender(genderDispatch?.gender || []);
  }, [genderDispatch?.gender]);
  /**
   * Muestra la información de los documentos según los parámetros de la URL
   */
  useEffect(() => {
    if (identificationNumber) {
      dispatch(getBeneficiaryByIdListPage({ identificationNumber }) as TDispatch);
    } else {
      reset({});
    }
  }, [identificationNumber, dispatch, reset]);

  /**
   * UseEffect pendiente al momento de actualizar un beneficiario jurídico existente
   */
  useEffect(() => {
    if (identificationNumber && loading) {
      if (updateBeneficiaryDispatch?.beneficiaryUpdated && !updateBeneficiaryDispatch?.error) {
        reset({
          idGender: undefined,
          idDocumentType: undefined,
          identificationNumber: undefined,
          thirdPartyBeneficiary: {
            idThirdParty: undefined,
          },
          firstName: '',
          middleName: '',
          firstSurname: '',
          secondSurname: '',
          address: {
            idCity: undefined,
            description: '',
            neighBorhood: '',
            thirdStreetNumber: '',
            firstStreetNumber: '',
            secondStreetNumber: '',
            idDepartment: undefined,
            idAddressFirstStreetType: undefined,
            idAddressSecondStreetType: undefined,
          },
          email: '',
          cellPhone: undefined,
          telephone: undefined,
        });
        dispatch(clearUpdateBeneficiaryPage() as TDispatch);
        dispatch(getBeneficiaryByIdListPage({ identificationNumber }) as TDispatch);
        toast.success('Beneficiario actualizado exitosamente', { hideProgressBar: true });
        goBack();
      } else if (
        !updateBeneficiaryDispatch?.beneficiaryUpdated &&
        updateBeneficiaryDispatch?.error
      ) {
        const message = updateBeneficiaryDispatch?.error?.response
          ? updateBeneficiaryDispatch?.error?.response?.data?.message
          : 'Ha ocurrido una incidencia';
        toast.error(`${message}`);
        dispatch(clearUpdateBeneficiaryPage() as TDispatch);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateBeneficiaryDispatch?.beneficiaryUpdated, updateBeneficiaryDispatch?.error]);

  /**
   * UseEffect que obtiene data encriptada en localStorage
   */
  useEffect(() => {
    if (!window.localStorage.getItem('vs2-unx') && !window.localStorage.getItem('vs2-tux')) {
      setUserCredentials({
        userName: '',
        userType: '',
      });
    } else {
      setUserCredentials({
        userName: decryptData(window.localStorage.getItem('vs2-unx') || '', '3573n035uN70K3n'),
        userType: decryptData(
          window.localStorage.getItem('vs2-tux') || '',
          '3573n035uN70K3n'
        )?.split(';')[0],
      });
    }
  }, []);

  /**
   * useEffect que se encarga de mostrar la información correspondiente en los campos mediante el ID
   */
  useEffect(() => {
    if (identificationNumber) {
      if (getBeneficiaryByIdDispatch?.beneficiary?.idBeneficiary) {
        const data = getBeneficiaryByIdDispatch?.beneficiary;
        reset({
          idGender: data?.idGender,
          idDocumentType: data?.idDocumentType,
          identificationNumber: data?.identificationNumber,
          thirdPartyBeneficiary: {
            ...data.thirdPartyBeneficiary,
            idThirdParty: data?.thirdPartyBeneficiary?.thirdParty?.idThirdParty,
          },
          firstName: data?.firstName,
          middleName: data?.middleName,
          firstSurname: data?.firstSurname,
          secondSurname: data?.secondSurname,
          address: {
            ...data.address,
            idCity: data?.address?.idCity,
            idDepartment: data?.address?.idDepartment,
            neighBorhood: data?.address?.neighBorhood,
            thirdStreetNumber: data?.address?.thirdStreetNumber,
            firstStreetNumber: data?.address?.firstStreetNumber,
            secondStreetNumber: data?.address?.secondStreetNumber,
            idAddressFirstStreetType: data?.address?.idAddressFirstStreetType,
            idAddressSecondStreetType: data?.address?.idAddressSecondStreetType,
            description: data?.address?.description ? data?.address?.description : '',
          },
          email: data?.email,
          cellPhone: data?.cellPhone !== 0 ? data?.cellPhone : undefined,
          telephone: data?.telephone !== 0 ? data?.telephone?.toString() : undefined,
        });
      }
    }
  }, [getBeneficiaryByIdDispatch, reset, identificationNumber]);

  /**
   * UseEffect para llamar todos los dispatch
   */
  useEffect(() => {
    dispatch(citiesListPage() as TDispatch);
    dispatch(genderListPage() as TDispatch);
    dispatch(departmentsListPage() as TDispatch);
    dispatch(streetTypesListPage() as TDispatch);
    dispatch(documentsTypesListPage() as TDispatch);
    dispatch(benefitCategoryListPage() as TDispatch);
    dispatch(clearUpdateBeneficiaryPage() as TDispatch);
    dispatch(clearBenefitsWithPaginationListPage() as TDispatch);
  }, [dispatch]);

  return (
    <PersonalInfoV
      control={control}
      schema={schema}
      open={open}
      handleMouseEnter={handleMouseEnter}
      handleMouseLeave={handleMouseLeave}
      stringAvatar={stringAvatar}
      anchorEl={anchorEl}
      handleSessionCouponsPage={handleSessionCouponsPage}
      goToLoginBeneficiary={goToLoginBeneficiary}
      goToPersonalInfo={goToPersonalInfo}
      userCredentials={userCredentials}
      handleUpdate={handleUpdate}
      idBeneficiary={identificationNumber}
      dataDepartments={dataDepartments}
      dataCities={dataCities}
      dataGender={dataGender}
      watchDepartment={watchDepartment}
      watchIdStreetTypeOne={watchIdStreetTypeOne}
      dataStreetTypes={dataStreetTypes}
      dataDocumentType={dataDocumentType}
      handleBenefitCategory={handleBenefitCategory}
      benefitsDataMapped={benefitsDataMapped}
      // prop de cambio de contraseña
      schemaUpdatePassword={schemaUpdatePassword}
      controlUpdatePassword={controlUpdatePassword}
      handleSubmitUpdatePassword={handleSubmitUpdatePassword}
      updateLoading={userLoggedUpdatePasswordDispatch?.loading}
      validatePassword={validatePassword}
      handleUpdatePassword={() => {}}
      validationErrors={validationErrors}
      sendChangePassword={sendChangePassword}
      openModalPassword={openModalPassword}
    />
  );
};
