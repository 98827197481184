import 'reflect-metadata';
import { AxiosResponse } from 'axios';
import { RequestResponsibilities } from '../../../domain/taxInformation/responsibilities/model/requestResponsibilities';
import { ResponseResponsibilities } from '../../../domain/taxInformation/responsibilities/model/responseResponsibilities';
import { IResponsibilitiesInfrastructure } from '../../../domain/interface/infrastructure/taxInformation/IResponsibilitiesInfrastructure';
import { processDataBackend } from '../../../../common/utils/functions';

export class ResponsibilitiesInfrastructure implements IResponsibilitiesInfrastructure {
  private a = '';

  public async getResponsibilitiesAPI(
    request: RequestResponsibilities
  ): Promise<ResponseResponsibilities[]> {
    this.a = 'aaa';

    const res: AxiosResponse = await processDataBackend(
      'GET',
      '/api/responsibilities/getAll',
      {},
      { request }
    );

    const responsibilities: ResponseResponsibilities[] = res.data;
    return Promise.resolve(responsibilities);
  }
}
