import { AxiosError } from 'axios';
import { ResponseBenefitBeneficiaries } from '../../core/domain/benefitBeneficiaries/model/responseBenefitBeneficiaries';

const actionsBenefitBeneficiaryCreate = {
  BENEFIT_BENEFICIARY_CREATE_PAGE_BEGIN: 'BENEFIT_BENEFICIARY_CREATE_PAGE_BEGIN',
  BENEFIT_BENEFICIARY_CREATE_PAGE_SUCCESS: 'BENEFIT_BENEFICIARY_CREATE_PAGE_SUCCESS',
  BENEFIT_BENEFICIARY_CREATE_PAGE_ERR: 'BENEFIT_BENEFICIARY_CREATE_PAGE_ERR',

  benefitBeneficiaryCreatePageBegin: (): { type: string } => {
    return {
      type: actionsBenefitBeneficiaryCreate.BENEFIT_BENEFICIARY_CREATE_PAGE_BEGIN,
    };
  },

  benefitBeneficiaryCreatePageSuccess: (
    data: ResponseBenefitBeneficiaries | undefined
  ): { type: string; data: ResponseBenefitBeneficiaries | undefined } => {
    return {
      type: actionsBenefitBeneficiaryCreate.BENEFIT_BENEFICIARY_CREATE_PAGE_SUCCESS,
      data,
    };
  },

  benefitBeneficiaryCreatePageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsBenefitBeneficiaryCreate.BENEFIT_BENEFICIARY_CREATE_PAGE_ERR,
      err,
    };
  },
};
const actionsBenefitBeneficiaryGetById = {
  BENEFIT_BENEFICIARY_GET_BY_ID_PAGE_BEGIN: 'BENEFIT_BENEFICIARY_GET_BY_ID_PAGE_BEGIN',
  BENEFIT_BENEFICIARY_GET_BY_ID_PAGE_SUCCESS: 'BENEFIT_BENEFICIARY_GET_BY_ID_PAGE_SUCCESS',
  BENEFIT_BENEFICIARY_GET_BY_ID_PAGE_ERR: 'BENEFIT_BENEFICIARY_GET_BY_ID_PAGE_ERR',

  benefitBeneficiaryGetByIdPageBegin: (): { type: string } => {
    return {
      type: actionsBenefitBeneficiaryGetById.BENEFIT_BENEFICIARY_GET_BY_ID_PAGE_BEGIN,
    };
  },

  benefitBeneficiaryGetByIdPageSuccess: (
    data: ResponseBenefitBeneficiaries[] | undefined
  ): { type: string; data: ResponseBenefitBeneficiaries[] | undefined } => {
    return {
      type: actionsBenefitBeneficiaryGetById.BENEFIT_BENEFICIARY_GET_BY_ID_PAGE_SUCCESS,
      data,
    };
  },

  benefitBeneficiaryGetByIdPageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsBenefitBeneficiaryGetById.BENEFIT_BENEFICIARY_GET_BY_ID_PAGE_ERR,
      err,
    };
  },
};
const actionsBenefitBeneficiaryValidateBenefit = {
  BENEFIT_BENEFICIARY_VALIDATE_BENEFIT_PAGE_BEGIN:
    'BENEFIT_BENEFICIARY_VALIDATE_BENEFIT_PAGE_BEGIN',
  BENEFIT_BENEFICIARY_VALIDATE_BENEFIT_PAGE_SUCCESS:
    'BENEFIT_BENEFICIARY_VALIDATE_BENEFIT_PAGE_SUCCESS',
  BENEFIT_BENEFICIARY_VALIDATE_BENEFIT_PAGE_ERR: 'BENEFIT_BENEFICIARY_VALIDATE_BENEFIT_PAGE_ERR',

  benefitBeneficiaryValidateBenefitPageBegin: (): { type: string } => {
    return {
      type: actionsBenefitBeneficiaryValidateBenefit.BENEFIT_BENEFICIARY_VALIDATE_BENEFIT_PAGE_BEGIN,
    };
  },

  benefitBeneficiaryValidateBenefitPageSuccess: (
    data: ResponseBenefitBeneficiaries | undefined
  ): { type: string; data: ResponseBenefitBeneficiaries | undefined } => {
    return {
      type: actionsBenefitBeneficiaryValidateBenefit.BENEFIT_BENEFICIARY_VALIDATE_BENEFIT_PAGE_SUCCESS,
      data,
    };
  },

  benefitBeneficiaryValidateBenefitPageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsBenefitBeneficiaryValidateBenefit.BENEFIT_BENEFICIARY_VALIDATE_BENEFIT_PAGE_ERR,
      err,
    };
  },
};
export {
  actionsBenefitBeneficiaryCreate,
  actionsBenefitBeneficiaryGetById,
  actionsBenefitBeneficiaryValidateBenefit,
};
