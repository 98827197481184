import { AxiosError } from 'axios';
import { actionsBenefitValidityCreate } from './actions';
import { TBenefitValidityCreateReducer } from './types.d';
import { ResponseBenefitValidity } from '../../../core/domain/benefits/benefitValidities/model/responseBenefitValidity';
import { BenefitValidityState } from '../../../core/domain/benefits/benefitValidities/state/benefitValiditiyState';

const {
  BENEFIT_VALIDITY_CREATE_PAGE_BEGIN,
  BENEFIT_VALIDITY_CREATE_PAGE_SUCCESS,
  BENEFIT_VALIDITY_CREATE_PAGE_ERR,
} = actionsBenefitValidityCreate;

const initialBenefit: BenefitValidityState = new BenefitValidityState();

export const BenefitValidityCreateReducer = (
  state = initialBenefit,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseBenefitValidity | undefined;
  }
): TBenefitValidityCreateReducer => {
  const { type, data, err } = action;
  switch (type) {
    case BENEFIT_VALIDITY_CREATE_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BENEFIT_VALIDITY_CREATE_PAGE_SUCCESS:
      return {
        ...state,
        benefitValidityCreated: data,
        loading: false,
      };
    case BENEFIT_VALIDITY_CREATE_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};
