import { container } from 'tsyringe';
import { IUserPermissionApplication } from '../../../domain/interface/application/userPermissions/IUserPermissionApplication';
import { IUserPermissionInfrastructure } from '../../../domain/interface/infrastructure/userPermissions/IUserPermissionInfrastructure';
import { RequestUserPermission } from '../../../domain/userPermissions/userPermission/model/requestUserPermission';
import { ResponseUserPermission } from '../../../domain/userPermissions/userPermission/model/responseUserPermission';

export class UserPermissionApplication implements IUserPermissionApplication {
  private conventionInfrastructure: IUserPermissionInfrastructure;

  public constructor() {
    this.conventionInfrastructure = container.resolve<IUserPermissionInfrastructure>(
      'IUserPermissionInfrastructure'
    );
  }

  public async createUserPermission(
    request: RequestUserPermission
  ): Promise<ResponseUserPermission> {
    const result = await this.conventionInfrastructure.createUserPermissionAPI(request);
    return result;
  }
}
