import 'reflect-metadata';
import { container } from 'tsyringe';
import { IBankAccountTypesApplication } from '../../../domain/interface/application/banks/IBankAccountTypes';
import { IBankAccountTypesInfrastructure } from '../../../domain/interface/infrastructure/banks/IBankAccountTypesInfrastructure';
import { RequestBankAccountTypes } from '../../../domain/banks/bankAccountTypes/model/requestBankAccountTypes';
import { ResponseBankAccountTypes } from '../../../domain/banks/bankAccountTypes/model/responseBankAccountTypes';

export class BankAccountTypesApplication implements IBankAccountTypesApplication {
  private bankAccountTypesInfrastructure: IBankAccountTypesInfrastructure;

  public constructor() {
    this.bankAccountTypesInfrastructure = container.resolve<IBankAccountTypesInfrastructure>(
      'IBankAccountTypesInfrastructure'
    );
  }

  public async getBankAccountTypes(
    request: RequestBankAccountTypes
  ): Promise<ResponseBankAccountTypes[]> {
    const result = await this.bankAccountTypesInfrastructure.getBankAccountTypesAPI(request);
    return result;
  }
}
