import { Box, Typography, Grid, useTheme, CircularProgress, Tooltip } from '@mui/material';
import React, { FC } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import { ButtonSubmit } from './styled';
import { InputField, NormalSelectField } from '../../../../../common/components/formAdapters';
import { TUserRegister } from './types.d';
import { onlyLetters, onlyNumbers } from '../../../../../common/utils/functions';
import { TitleComponent } from '../../../../../common/components/titles/styled';

const SellersRegisterV: FC<TUserRegister> = ({
  schema,
  control,
  createLoading,
  updateLoading,
  handleRegister,
  handleUpdate,
  dataDocumentType,
  dataGender,
  dataDepartments,
  dataCities,
  dataStreetTypes,
  watchDepartment,
  watchIdStreetTypeOne,
  dataMemberships,
  idSeller,
}) => {
  const theme = useTheme();
  const styles = {
    header: {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      fontSize: '1.8rem',
      textAlign: 'left',
      fontFamily: 'Poppins-Regular',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.5rem',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    buttonBox: {
      display: 'flex',
      justifyContent: 'flex-end',
      '@media screen and (max-width: 899px)': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
  };
  return (
    <Box
      style={{
        width: '100%',
      }}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          background: '#fff',
          borderRadius: '16px',
          boxShadow: '0px 3px 6px #00000029',
          paddingTop: '15px',
        }}>
        <Box
          sx={{
            height: '85%',
            padding: '0px 40px 20px 40px',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingBottom: '10px',
            }}>
            <TitleComponent>
              {!idSeller ? 'Registro de vendedores' : 'Editar vendedor'}
            </TitleComponent>
          </Box>
          <Typography variant="h5" color={theme.palette.primary.main} sx={{ pb: '15px' }}>
            Información básica
          </Typography>
          <Box sx={{ flexGrow: 1, marginBottom: '8px' }}>
            <Grid container spacing={{ xs: 2, sm: 2, md: 2 }}>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <NormalSelectField
                  name="idDocumentType"
                  disabled={!!idSeller}
                  label="Tipo de identificación"
                  key="idDocumentType"
                  schema={schema?.fields?.idDocumentType}
                  control={control}
                  options={
                    (dataDocumentType || [])?.map((x) => ({
                      value: x?.idDocumentType,
                      label: x?.name,
                    })) || []
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <InputField
                  label="Número de identificación"
                  name="identificationNumber"
                  disabled={!!idSeller}
                  schema={schema.fields?.identificationNumber}
                  control={control}
                  onKeyDown={onlyNumbers}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <NormalSelectField
                  name="idGender"
                  label="Género"
                  key="idGender"
                  schema={schema?.fields?.idGender}
                  control={control}
                  options={
                    (dataGender || [])?.map((x) => ({
                      value: x?.idGender,
                      label: x?.name,
                    })) || []
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <InputField
                  label="Primer nombre"
                  name="firstName"
                  schema={schema.fields?.firstName}
                  control={control}
                  onKeyDown={onlyLetters}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <InputField
                  label="Segundo nombre"
                  name="middleName"
                  schema={schema.fields?.middleName}
                  control={control}
                  onKeyDown={onlyLetters}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <InputField
                  label="Primer apellido"
                  name="firstSurname"
                  schema={schema.fields?.firstSurname}
                  control={control}
                  onKeyDown={onlyLetters}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <InputField
                  label="Segundo apellido"
                  name="secondSurname"
                  schema={schema.fields?.secondSurname}
                  control={control}
                  onKeyDown={onlyLetters}
                />
              </Grid>
            </Grid>
          </Box>

          <Typography variant="h5" color={theme.palette.primary.main} sx={{ pb: '15px' }}>
            Ubicación y contacto
          </Typography>
          <Box sx={{ flexGrow: 1, marginBottom: '8px' }}>
            <Grid container spacing={{ xs: 2, sm: 2, md: 2 }}>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <NormalSelectField
                  name="address.idDepartment"
                  label="Departamento"
                  key="address.idDepartment"
                  schema={schema?.fields?.address.idDepartment}
                  control={control}
                  options={
                    (dataDepartments || [])?.map((x) => ({
                      value: x?.idDepartment,
                      label: x?.name?.toUpperCase(),
                    })) || []
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <NormalSelectField
                  name="address.idCity"
                  label="Ciudad"
                  key="address.idCity"
                  schema={schema?.fields?.address?.idCity}
                  control={control}
                  options={
                    (dataCities || [])
                      ?.filter((x) => x?.idDepartment === Number(watchDepartment))
                      .map((x) => {
                        return {
                          value: x?.idCity,
                          label: x?.name?.toUpperCase(),
                        };
                      }) || []
                  }
                  disabled={!watchDepartment}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <InputField
                  label="Barrio"
                  name="address.neighBorhood"
                  schema={schema.fields?.address?.neighBorhood}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <NormalSelectField
                  name="address.idAddressFirstStreetType"
                  label="Tipo de dirección 1"
                  schema={schema?.fields?.address?.idAddressFirstStreetType}
                  control={control}
                  options={
                    (dataStreetTypes || [])?.map((x) => ({
                      value: x?.idStreetType,
                      label: x?.name,
                    })) || []
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <InputField
                  label="Número"
                  name="address.firstStreetNumber"
                  schema={schema.fields?.address.firstStreetNumber}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <NormalSelectField
                  name="address.idAddressSecondStreetType"
                  label="Tipo de dirección 2"
                  schema={schema?.fields?.address?.idAddressSecondStreetType}
                  control={control}
                  options={
                    (dataStreetTypes || [])
                      ?.filter((x) => x?.idStreetType !== Number(watchIdStreetTypeOne))
                      .map((x) => ({
                        value: x?.idStreetType,
                        label: x?.name,
                      })) || []
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <InputField
                  label="Número 2"
                  name="address.secondStreetNumber"
                  schema={schema.fields?.address?.secondStreetNumber}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <InputField
                  label="Número 3"
                  name="address.thirdStreetNumber"
                  schema={schema.fields?.address?.thirdStreetNumber}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <InputField
                  label="Detalle"
                  name="address.description"
                  schema={schema.fields?.address?.description}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <InputField
                  label="Celular"
                  name="cellPhone"
                  schema={schema.fields?.cellPhone}
                  control={control}
                  onKeyDown={onlyNumbers}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <InputField
                  label="Teléfono"
                  name="telephone"
                  schema={schema.fields?.telephone}
                  control={control}
                  onKeyDown={onlyNumbers}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <InputField
                  label="Correo electrónico"
                  name="email"
                  schema={schema.fields?.email}
                  control={control}
                />
              </Grid>
            </Grid>
          </Box>

          <Box sx={styles.buttonBox}>
            <Tooltip title={!idSeller ? 'Registrar vendedor' : 'Actualizar vendedor'}>
              <ButtonSubmit
                disabled={createLoading || updateLoading}
                type="button"
                backgroundColor={theme.palette.primary.main}
                hoverStyles={theme.palette.primary.dark}
                onClick={idSeller ? handleUpdate : handleRegister}>
                {createLoading || updateLoading ? (
                  <CircularProgress size={15} sx={{ color: 'white' }} />
                ) : (
                  <SaveIcon />
                )}
                <Typography fontSize="14px">{!idSeller ? 'CREAR' : 'ACTUALIZAR'}</Typography>
              </ButtonSubmit>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SellersRegisterV;
