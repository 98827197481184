import { ResponseKinships } from '../model/responseKinships';

export class KinshipsState {
  public constructor() {
    this.kinships = [new ResponseKinships()];
  }

  public name = '';

  public kinships: ResponseKinships[];
}
