import { AxiosError } from 'axios';
import { ResponseCountries } from '../../../core/domain/addresses/countries/model/responseCountries';
import { CountriesState } from '../../../core/domain/addresses/countries/state/countriesState';
import actions from './actions';
import { TCountriesReducer } from './types';

const { COUNTRIES_LIST_PAGE_BEGIN, COUNTRIES_LIST_PAGE_SUCCESS, COUNTRIES_LIST_PAGE_ERR } = actions;

const initialCountries: CountriesState = new CountriesState();

const CountriesReducer = (
  state = initialCountries,
  action: { type: string; err: AxiosError; data: ResponseCountries[] }
): TCountriesReducer => {
  const { type, data, err } = action;
  switch (type) {
    case COUNTRIES_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case COUNTRIES_LIST_PAGE_SUCCESS:
      return {
        ...state,
        countries: data,
        loading: false,
      };
    case COUNTRIES_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default CountriesReducer;
