import React from 'react';
import { Avatar, Box, Popper, Stack, Typography, useTheme } from '@mui/material';
// import { useTheme } from '@mui/material/styles';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import { TransitionsModal } from '../../../../common/components/modal';
import { ChangePasswordV } from './changePassword';
import { Link } from 'react-router-dom';
// import useWindowDimensions from '../../../../common/utils/hooks/useDimensions';

export const DropdownHeaderV: React.FC<any> = ({
  open,
  openModal,
  sendConfirm,
  handleMouseEnter,
  anchorEl,
  handleMouseLeave,
  stringAvatar,
  headerType,
  handleSession,
  control,
  schema,
  handleSubmit,
  handleUpdatePassword,
  updateLoading,
  validatePassword,
  validationErrors,
  userCredentials,
}) => {
  const theme = useTheme();

  // const { h } = useWindowDimensions();

  const style = {
    option: {
      display: 'flex',
      borderRadius: '5px',
      padding: '5px 15px',
      ':hover': {
        cursor: 'pointer',
        fontWeight: 'bold',
        backgroundColor: theme.palette.primary.light,
      },
    },
    icon: {
      fontSize: 'large',
      marginRight: '5px',
      color: 'black',
    },
    divBanner: {
      borderBottom: '1px solid #dedede',
      width: '90%',
      alignSelf: 'center',
      marginTop: '3px',
    },
    textOption: {
      color: 'black',
      fontSize: '0.8em',
    },
  };

  return (
    <div onMouseLeave={handleMouseLeave}>
      <TransitionsModal
        state={openModal}
        handleCloseModal={sendConfirm}
        width="30%"
        height="50%"
        minHeight="540px"
        minWidth="500px">
        <ChangePasswordV
          control={control}
          schema={schema}
          handleSubmit={handleSubmit}
          handleUpdatePassword={handleUpdatePassword}
          updateLoading={updateLoading}
          validatePassword={validatePassword}
          validationErrors={validationErrors}
        />
      </TransitionsModal>
      <Popper
        sx={{
          backgroundColor: '#fff',
          height: '250px',
          width: '200px',
          boxShadow: '0px 0px 5px 0px #dcdcdc',
          borderRadius: '5px',
          padding: '5px 10px',
          alignContent: 'center',
          top: '60px',
          zIndex: 160016009,
        }}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Stack direction="row" spacing={2} sx={{ padding: '10px' }}>
              <Avatar {...stringAvatar(userCredentials?.userName || '')} />
              <div>
                <Typography
                  sx={{
                    fontSize: '0.9em',
                    width: '100px',
                    marginTop: '12px',
                  }}>
                  {userCredentials?.userName || ''}
                </Typography>
                <Typography sx={{ fontSize: '0.9em' }}>{userCredentials?.userType}</Typography>
              </div>
            </Stack>
            <div style={style.divBanner} />
          </div>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {userCredentials?.userType !== 'Administrador' && (
              <Link to="/profileInformation" style={{ textDecoration: 'none' }}>
                <Box sx={style.option}>
                  <AccountCircleIcon sx={style.icon} />
                  <Typography sx={style.textOption}>Ver perfil</Typography>
                </Box>
              </Link>
            )}
            <Box sx={style.option} onClick={() => sendConfirm('changePassword')}>
              <VpnKeyIcon sx={style.icon} />
              <Typography sx={style.textOption}>Cambiar contraseña</Typography>
            </Box>
            <Box sx={style.option}>
              <HelpIcon sx={style.icon} />
              <Typography sx={style.textOption}>Soporte</Typography>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ ...style.divBanner, marginBottom: '5px', marginTop: '0' }} />
            <Box sx={style.option}>
              <SettingsIcon sx={style.icon} />
              <Typography sx={style.textOption}>Opciones</Typography>
            </Box>
            <Box sx={style.option} onClick={handleSession}>
              <LogoutIcon sx={style.icon} />
              <Typography sx={style.textOption}>Cerrar sesión</Typography>
            </Box>
          </Box>
        </div>
      </Popper>
      <IconButton
        color={headerType === 1 ? 'inherit' : 'primary'}
        onMouseEnter={(e) => handleMouseEnter(e)}>
        {/* <PowerSettingsNewIcon /> */}
        <Stack direction="row" spacing={1}>
          <Avatar {...stringAvatar(userCredentials?.userName || '', 'header')} />
        </Stack>
      </IconButton>
    </div>
  );
};
