import { AxiosError } from 'axios';
import { actionsBenefit, actionsBenefitByBeneficiaries, actionsBenefitCreate, actionsBenefitDelete, actionsBenefits, actionsBenefitsByMembership, actionsBenefitsForExcel, actionsBenefitsRandom, actionsBenefitsWithPagination, actionsBenefitUpdate, actionsBenefitByThirdParty } from './actions';
import {
  TBenefitByBeneficiariesReducer,
  TBenefitCreateReducer,
  TBenefitDeleteReducer,
  TBenefitReducer,
  TBenefitUpdateReducer,
  TBenefitsByMembershipReducer,
  TBenefitsForExcelReducer,
  TBenefitsRandomReducer,
  TBenefitsReducer,
  TBenefitsWithPaginationReducer,
  TBenefitByThirdPartyReducer
} from './types.d';
import { ResponseBenefit } from '../../../core/domain/benefits/benefits/model/responseBenefit';
import { BenefitState } from '../../../core/domain/benefits/benefits/state/benefitState';

const { BENEFIT_CREATE_PAGE_BEGIN, BENEFIT_CREATE_PAGE_SUCCESS, BENEFIT_CREATE_PAGE_ERR } =
  actionsBenefitCreate;
const { BENEFIT_LIST_PAGE_BEGIN, BENEFIT_LIST_PAGE_SUCCESS, BENEFIT_LIST_PAGE_ERR } =
  actionsBenefit;
const { BENEFIT_UPDATE_PAGE_BEGIN, BENEFIT_UPDATE_PAGE_SUCCESS, BENEFIT_UPDATE_PAGE_ERR } =
  actionsBenefitUpdate;
const { BENEFIT_DELETE_PAGE_BEGIN, BENEFIT_DELETE_PAGE_SUCCESS, BENEFIT_DELETE_PAGE_ERR } =
  actionsBenefitDelete;
const { BENEFITS_LIST_PAGE_BEGIN, BENEFITS_LIST_PAGE_SUCCESS, BENEFITS_LIST_PAGE_ERR } =
  actionsBenefits;
const {
  BENEFITS_FOR_EXCEL_LIST_PAGE_BEGIN,
  BENEFITS_FOR_EXCEL_LIST_PAGE_SUCCESS,
  BENEFITS_FOR_EXCEL_LIST_PAGE_ERR,
} = actionsBenefitsForExcel;
const {
  BENEFITS_RANDOM_LIST_PAGE_BEGIN,
  BENEFITS_RANDOM_LIST_PAGE_SUCCESS,
  BENEFITS_RANDOM_LIST_PAGE_ERR,
} = actionsBenefitsRandom;
const {
  BENEFITS_WITH_PAGINATION_LIST_PAGE_BEGIN,
  BENEFITS_WITH_PAGINATION_LIST_PAGE_SUCCESS,
  BENEFITS_WITH_PAGINATION_LIST_PAGE_ERR,
} = actionsBenefitsWithPagination;
const {
  BENEFIT_BY_MEMBERSHIP_PAGE_BEGIN,
  BENEFIT_BY_MEMBERSHIP_PAGE_SUCCESS,
  BENEFIT_BY_MEMBERSHIP_PAGE_ERR,
} = actionsBenefitsByMembership;
const {
  BENEFIT_BY_BENEFICIARIES_PAGE_BEGIN,
  BENEFIT_BY_BENEFICIARIES_PAGE_SUCCESS,
  BENEFIT_BY_BENEFICIARIES_PAGE_ERR,
} = actionsBenefitByBeneficiaries;
const { 
  BENEFIT_BY_THIRDPARTY_PAGE_BEGIN, 
  BENEFIT_BY_THIRDPARTY_PAGE_SUCESS,
  BENEFIT_BY_THIRDPARTY_PAGE_ERR,  
} = actionsBenefitByThirdParty

const initialBenefit: BenefitState = new BenefitState();

export const BenefitCreateReducer = (
  state = initialBenefit,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseBenefit | undefined;
  }
): TBenefitCreateReducer => {
  const { type, data, err } = action;
  switch (type) {
    case BENEFIT_CREATE_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BENEFIT_CREATE_PAGE_SUCCESS:
      return {
        ...state,
        benefitCreated: data,
        loading: false,
      };
    case BENEFIT_CREATE_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const BenefitReducer = (
  state = initialBenefit,
  action: {
    type: string;
    err: AxiosError | undefined;
    data?: ResponseBenefit | undefined;
  }
): TBenefitReducer => {
  const { type, data, err } = action;
  switch (type) {
    case BENEFIT_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BENEFIT_LIST_PAGE_SUCCESS:
      return {
        ...state,
        benefit: data,
        loading: false,
      };
    case BENEFIT_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const BenefitUpdateReducer = (
  state = initialBenefit,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseBenefit | undefined;
  }
): TBenefitUpdateReducer => {
  const { type, data, err } = action;
  switch (type) {
    case BENEFIT_UPDATE_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BENEFIT_UPDATE_PAGE_SUCCESS:
      return {
        ...state,
        benefitUpdated: data,
        loading: false,
      };
    case BENEFIT_UPDATE_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const BenefitDeleteReducer = (
  state = initialBenefit,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseBenefit | undefined;
  }
): TBenefitDeleteReducer => {
  const { type, data, err } = action;
  switch (type) {
    case BENEFIT_DELETE_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BENEFIT_DELETE_PAGE_SUCCESS:
      return {
        ...state,
        benefitDeleted: data,
        loading: false,
      };
    case BENEFIT_DELETE_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const BenefitsReducer = (
  state = initialBenefit,
  action: { type: string; err: AxiosError | undefined; data: ResponseBenefit[] }
): TBenefitsReducer => {
  const { type, data, err } = action;
  switch (type) {
    case BENEFITS_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BENEFITS_LIST_PAGE_SUCCESS:
      return {
        ...state,
        benefits: data,
        loading: false,
      };
    case BENEFITS_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const BenefitsForExcelReducer = (
  state = initialBenefit,
  action: { type: string; err: AxiosError | undefined; data: ResponseBenefit[] }
): TBenefitsForExcelReducer => {
  const { type, data, err } = action;
  switch (type) {
    case BENEFITS_FOR_EXCEL_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BENEFITS_FOR_EXCEL_LIST_PAGE_SUCCESS:
      return {
        ...state,
        benefitsForExcel: data,
        loading: false,
      };
    case BENEFITS_FOR_EXCEL_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const BenefitsRandomReducer = (
  state = initialBenefit,
  action: { type: string; err: AxiosError | undefined; data: ResponseBenefit[] }
): TBenefitsRandomReducer => {
  const { type, data, err } = action;
  switch (type) {
    case BENEFITS_RANDOM_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BENEFITS_RANDOM_LIST_PAGE_SUCCESS:
      return {
        ...state,
        benefitsRandom: data,
        loading: false,
      };
    case BENEFITS_RANDOM_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const BenefitsWithPaginationReducer = (
  state = initialBenefit,
  action: { type: string; err: AxiosError | undefined; data: ResponseBenefit[] }
): TBenefitsWithPaginationReducer => {
  const { type, data, err } = action;
  switch (type) {
    case BENEFITS_WITH_PAGINATION_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BENEFITS_WITH_PAGINATION_LIST_PAGE_SUCCESS:
      return {
        ...state,
        getBenefitsWithPagination: data,
        loading: false,
      };
    case BENEFITS_WITH_PAGINATION_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const BenefitsByMembershipReducer = (
  state = initialBenefit,
  action: { type: string; err: AxiosError | undefined; data: ResponseBenefit[] }
): TBenefitsByMembershipReducer => {
  const { type, data, err } = action;
  switch (type) {
    case BENEFIT_BY_MEMBERSHIP_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BENEFIT_BY_MEMBERSHIP_PAGE_SUCCESS:
      return {
        ...state,
        benefitsByMembership: data,
        loading: false,
      };
    case BENEFIT_BY_MEMBERSHIP_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const BenefitByBeneficiariesReducer = (
  state = initialBenefit,
  action: { type: string; err: AxiosError | undefined; data: ResponseBenefit[] }
): TBenefitByBeneficiariesReducer => {
  const { type, data, err } = action;
  switch (type) {
    case BENEFIT_BY_BENEFICIARIES_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BENEFIT_BY_BENEFICIARIES_PAGE_SUCCESS:
      return {
        ...state,
        benefitByBeneficiaries: data,
        loading: false,
      };
    case BENEFIT_BY_BENEFICIARIES_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const BenefitByThirdPartyReducer = (
  state = initialBenefit,
  action: { type: string; err: AxiosError | undefined; data: ResponseBenefit[] }
): TBenefitByThirdPartyReducer => {
  const { type, err, data } = action;
  switch (type) {
    case BENEFIT_BY_THIRDPARTY_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
      case BENEFIT_BY_THIRDPARTY_PAGE_SUCESS:
        return {
          benefitByThirdParty: data,
          loading: false
        };
      case BENEFIT_BY_THIRDPARTY_PAGE_ERR:
        return {
          ...state,
          error: err,
          loading: false
        }
      default:
        return state;
  }
}
