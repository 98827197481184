import { AxiosError } from 'axios';
import 'reflect-metadata';
import { container } from 'tsyringe';
import { AppDispatch } from '../store';
import { actionsLoginBeneficiary, actionsBeneficiaryLogout, actionsRefresh } from './actions';
import { RequestLogin } from '../../core/domain/security/model/request/requestLogin';
import { IRefreshApplication } from '../../core/domain/interface/application/security/IRefreshApplication';
import { ILoginBeneficiaryApplication } from '../../core/domain/interface/application/security/ILoginBeneficiaryApplication';
import { ILogoutBeneficiaryApplication } from '../../core/domain/interface/application/security/ILogoutBeneficiaryApplication';

const { loginPageBeneficiaryBegin, loginPageBeneficiarySuccess, loginPageBeneficiaryErr } =
  actionsLoginBeneficiary;
const { logoutBeneficiaryPageBegin, logoutBeneficiaryPageSuccess, logoutBeneficiaryPageErr } =
  actionsBeneficiaryLogout;
const { refreshPageBegin, refreshPageSuccess, refreshPageErr } = actionsRefresh;

export const LoginBeneficiary = (
  loginState: RequestLogin
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(loginPageBeneficiaryBegin());
      const loginApplication = container.resolve<ILoginBeneficiaryApplication>(
        'ILoginBeneficiaryApplication'
      );

      const resultLogin = await loginApplication.handle(loginState);
      dispatch(loginPageBeneficiarySuccess(resultLogin));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(loginPageBeneficiaryErr(error));
    }
  };
};

export const LogoutBeneficiary = (token: string): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(logoutBeneficiaryPageBegin());
      const logoutApplication = container.resolve<ILogoutBeneficiaryApplication>(
        'ILogoutBeneficiaryApplication'
      );

      const resultLogout = await logoutApplication.handle(token);
      dispatch(logoutBeneficiaryPageSuccess(resultLogout));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(logoutBeneficiaryPageErr(error));
    }
  };
};
export const RefreshUser = (
  loginState: RequestLogin
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(refreshPageBegin());
      const loginApplication = container.resolve<IRefreshApplication>('IRefreshApplication');

      const resultLogin = await loginApplication.handle(loginState);
      dispatch(refreshPageSuccess(resultLogin));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(refreshPageErr(error));
    }
  };
};

export const clearBeneficiaryLogin = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(loginPageBeneficiaryBegin());
    dispatch(loginPageBeneficiaryErr(undefined));
    dispatch(loginPageBeneficiarySuccess(undefined));
  };
};
export const clearBeneficiaryLogout = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(logoutBeneficiaryPageBegin());
    dispatch(logoutBeneficiaryPageErr(undefined));
    dispatch(logoutBeneficiaryPageSuccess(undefined));
  };
};
export const clearRefresh = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(refreshPageBegin());
    dispatch(refreshPageErr(undefined));
    dispatch(refreshPageSuccess(undefined));
  };
};
