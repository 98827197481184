export class ResponseAddress {
  public idAddress = 0;

  public idCity? = 0;

  public idDepartment? = 0;

  public idAddressFirstStreetType? = 0;

  public idAddressSecondStreetType? = 0;

  public firstStreetNumber? = '';

  public secondStreetNumber? = '';

  public thirdStreetNumber? = '';

  public stratum? = 0;

  public neighBorhood? = '';

  public description? = '';

  public state? = 0;
}
