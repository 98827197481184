import { AxiosError } from 'axios';
import { ResponseBranchsOffices } from '../../core/domain/branchsOffices/model/branchsOfficesResponse';

const actionsBranchsOffices = {
  BRANCHSOFFICES_LIST_PAGE_BEGIN: 'BRANCHSOFFICES_LIST_PAGE_BEGIN',
  BRANCHSOFFICES_LIST_PAGE_SUCCESS: 'BRANCHSOFFICES_LIST_PAGE_SUCCESS',
  BRANCHSOFFICES_LIST_PAGE_ERR: 'BRANCHSOFFICES_LIST_PAGE_ERR',

  branchsOfficesListPageBegin: (): { type: string } => {
    return {
      type: actionsBranchsOffices.BRANCHSOFFICES_LIST_PAGE_BEGIN,
    };
  },

  branchsOfficesListPageSuccess: (
    data: ResponseBranchsOffices[]
  ): { type: string; data: ResponseBranchsOffices[] | undefined } => {
    return {
      type: actionsBranchsOffices.BRANCHSOFFICES_LIST_PAGE_SUCCESS,
      data,
    };
  },

  branchsOfficesListPageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsBranchsOffices.BRANCHSOFFICES_LIST_PAGE_ERR,
      err,
    };
  },
};

const actionsBranchOffice = {
  BRANCHOFFICE_LIST_PAGE_BEGIN: 'BRANCHOFFICE_PAGE_BEGIN',
  BRANCHOFFICE_LIST_PAGE_SUCCESS: 'BRANCHOFFICE_PAGE_SUCCESS',
  BRANCHOFFICE_LIST_PAGE_ERR: 'BRANCHOFFICE_PAGE_ERR',

  branchOfficeListPageBegin: (): { type: string } => {
    return {
      type: actionsBranchOffice.BRANCHOFFICE_LIST_PAGE_BEGIN,
    };
  },

  branchOfficeListPageSuccess: (
    data: ResponseBranchsOffices | undefined
  ): { type: string; data: ResponseBranchsOffices | undefined } => {
    return {
      type: actionsBranchOffice.BRANCHOFFICE_LIST_PAGE_SUCCESS,
      data,
    };
  },

  branchOfficeListPageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsBranchOffice.BRANCHOFFICE_LIST_PAGE_ERR,
      err,
    };
  },
};

const actionsBranchsOfficesCreate = {
  BRANCHSOFFICES_CREATE_PAGE_BEGIN: 'BRANCHSOFFICES_CREATE_PAGE_BEGIN',
  BRANCHSOFFICES_CREATE_PAGE_SUCCESS: 'BRANCHSOFFICES_CREATE_PAGE_SUCCESS',
  BRANCHSOFFICES_CREATE_PAGE_ERR: 'BRANCHSOFFICES_CREATE_PAGE_ERR',

  branchsOfficesCreatePageBegin: (): { type: string } => {
    return {
      type: actionsBranchsOfficesCreate.BRANCHSOFFICES_CREATE_PAGE_BEGIN,
    };
  },

  branchsOfficesCreatePageSuccess: (
    data: ResponseBranchsOffices[] | undefined
  ): { type: string; data: ResponseBranchsOffices[] | undefined } => {
    return {
      type: actionsBranchsOfficesCreate.BRANCHSOFFICES_CREATE_PAGE_SUCCESS,
      data,
    };
  },

  branchsOfficesCreatePageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsBranchsOfficesCreate.BRANCHSOFFICES_CREATE_PAGE_ERR,
      err,
    };
  },
};

const actionsBranchsOfficesUpdate = {
  BRANCHSOFFICES_UPDATE_PAGE_BEGIN: 'BRANCHSOFFICES_UPDATE_PAGE_BEGIN',
  BRANCHSOFFICES_UPDATE_PAGE_SUCCESS: 'BRANCHSOFFICES_UPDATE_PAGE_SUCCESS',
  BRANCHSOFFICES_UPDATE_PAGE_ERR: 'BRANCHSOFFICES_UPDATE_PAGE_ERR',

  branchsOfficesUpdatePageBegin: (): { type: string } => {
    return {
      type: actionsBranchsOfficesUpdate.BRANCHSOFFICES_UPDATE_PAGE_BEGIN,
    };
  },

  branchsOfficesUpdatePageSuccess: (
    data: ResponseBranchsOffices | undefined
  ): { type: string; data: ResponseBranchsOffices | undefined } => {
    return {
      type: actionsBranchsOfficesUpdate.BRANCHSOFFICES_UPDATE_PAGE_SUCCESS,
      data,
    };
  },

  branchsOfficesUpdatePageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsBranchsOfficesUpdate.BRANCHSOFFICES_UPDATE_PAGE_ERR,
      err,
    };
  },
};

const actionsBranchsOfficesDelete = {
  BRANCHSOFFICES_DELETE_PAGE_BEGIN: 'BRANCHSOFFICES_DELETE_PAGE_BEGIN',
  BRANCHSOFFICES_DELETE_PAGE_SUCCESS: 'BRANCHSOFFICES_DELETE_PAGE_SUCCESS',
  BRANCHSOFFICES_DELETE_PAGE_ERR: 'BRANCHSOFFICES_DELETE_PAGE_ERR',
  branchsOfficesDeletePageBegin: (): { type: string } => {
    return {
      type: actionsBranchsOfficesDelete.BRANCHSOFFICES_DELETE_PAGE_BEGIN,
    };
  },

  branchsOfficesDeletePageSuccess: (
    data: ResponseBranchsOffices | undefined
  ): { type: string; data: ResponseBranchsOffices | undefined } => {
    return {
      type: actionsBranchsOfficesDelete.BRANCHSOFFICES_DELETE_PAGE_SUCCESS,
      data,
    };
  },

  branchsOfficesDeletePageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsBranchsOfficesDelete.BRANCHSOFFICES_DELETE_PAGE_ERR,
      err,
    };
  },
};

export {
  actionsBranchsOfficesCreate,
  actionsBranchsOfficesUpdate,
  actionsBranchsOfficesDelete,
  actionsBranchsOffices,
  actionsBranchOffice,
};
