import { AxiosError } from 'axios';
import 'reflect-metadata';
import { container } from 'tsyringe';
import { IUserSessionApplication } from '../../../core/domain/interface/application/userPermissions/IUserSessionApplication';
import { actionsDeleteUserSession, actionsValidateUserSession } from './actions';
import { AppDispatch } from '../../store';

const { validateUserSessionPageBegin, validateUserSessionPageSuccess, validateUserSessionPageErr } =
  actionsValidateUserSession;

const {
  deleteUserSessionPageBegin,
  deleteUserSessionPageSuccess,
  deleteUserSessionPageErr,
  deleteUserSessionPageClear,
} = actionsDeleteUserSession;

const validateUserSession = (token: string | null): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(validateUserSessionPageBegin());
      const userSessionApplication =
        container.resolve<IUserSessionApplication>('IUserSessionApplication');

      const resultCreate = await userSessionApplication.validateUserSession(token);

      dispatch(validateUserSessionPageSuccess(resultCreate));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(validateUserSessionPageErr(error));
    }
  };
};

const deleteUserSession = (token: string): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(deleteUserSessionPageBegin());
      const userSessionApplication =
        container.resolve<IUserSessionApplication>('IUserSessionApplication');

      const resultDelete = await userSessionApplication.deleteUserSession(token);

      dispatch(deleteUserSessionPageSuccess(resultDelete));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(deleteUserSessionPageErr(error));
    }
  };
};

const clearValidateUserSession = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(validateUserSessionPageBegin());
    dispatch(validateUserSessionPageSuccess(undefined));
    dispatch(validateUserSessionPageErr(undefined));
  };
};

const clearDeleteUserSession = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(deleteUserSessionPageClear());
  };
};

export { validateUserSession, deleteUserSession, clearValidateUserSession, clearDeleteUserSession };
