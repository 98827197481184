import { ResponseUser } from '../model/responseUser';

export class UserState {
  public constructor() {
    this.users = [new ResponseUser()];
    this.user = new ResponseUser();
    this.userCreated = new ResponseUser();
    this.userUpdated = new ResponseUser();
    this.userRecoveryPassword = new ResponseUser();
    this.userDeleteCode = new ResponseUser();
    this.userUpdatedPassword = new ResponseUser();
    this.userLoggedUpdatedPassword = new ResponseUser();
    this.userDeleted = new ResponseUser();
    this.updatedRoleToUser = new ResponseUser();
    this.userLogin = new ResponseUser();
    this.usersByRole = [new ResponseUser()];
  }

  public users?: ResponseUser[];

  public user?: ResponseUser;

  public userCreated?: ResponseUser;

  public userUpdated?: ResponseUser;

  public userRecoveryPassword?: ResponseUser;

  public userDeleteCode?: ResponseUser;

  public userUpdatedPassword?: ResponseUser;

  public userLoggedUpdatedPassword?: ResponseUser;

  public userDeleted?: ResponseUser;

  public updatedRoleToUser?: ResponseUser;

  public userLogin?: ResponseUser;

  public usersByRole?: ResponseUser[];
}
