import 'reflect-metadata';
import { container } from 'tsyringe';
import { ILoginApplication } from '../../domain/interface/application/security/ILoginApplication';
import { ILoginInfrastructure } from '../../domain/interface/infrastructure/security/ILoginInfrastructure';
import { RequestLogin } from '../../domain/security/model/request/requestLogin';
import { ResponseUser } from '../../domain/userPermissions/user/model/responseUser';

export class LoginApplication implements ILoginApplication {
  private LoginInfrastructure: ILoginInfrastructure;

  public constructor() {
    this.LoginInfrastructure = container.resolve<ILoginInfrastructure>('ILoginInfrastructure');
  }

  public async handle(request: RequestLogin): Promise<ResponseUser> {
    const result = await this.LoginInfrastructure.handleAPI(request);
    return result;
  }
}
