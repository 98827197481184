import React from 'react';
import { Avatar, Box, Popper, Stack, Typography, useTheme } from '@mui/material';
// import { useTheme } from '@mui/material/styles';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import IconButton from '@mui/material/IconButton';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { ChangePasswordV } from '../NewLayout/changePassword';
import { TransitionsModal } from '../../../../common/components/modal';

export const DropdownHeaderCouponsV: React.FC<any> = ({
  open,
  goToPersonalInfo,
  handleMouseEnter,
  anchorEl,
  handleMouseLeave,
  stringAvatar,
  headerType,
  handleSessionCouponsPage,
  userCredentials,
  goToLoginBeneficiary,
  // goToCouponsDashboard,
  sendChangePassword,
  openModalPassword,
  handleUpdatePassword,
  updateLoading,
  validatePassword,
  validationErrors,
  controlUpdatePassword,
  handleSubmitUpdatePassword,
  schemaUpdatePassword,
  handleBenefitCategory,
}) => {
  const theme = useTheme();
  // const { h } = useWindowDimensions();

  const style = {
    option: {
      display: 'flex',
      borderRadius: '5px',
      padding: '5px 15px',
      ':hover': {
        cursor: 'pointer',
        fontWeight: 'bold',
        backgroundColor: theme.palette.primary.light,
      },
    },
    icon: {
      fontSize: 'large',
      marginRight: '5px',
    },
    divBanner: {
      borderBottom: '1px solid #dedede',
      width: '90%',
      alignSelf: 'center',
      marginTop: '3px',
    },
    textOption: {
      fontSize: '0.8em',
    },
  };

  return (
    <div onMouseLeave={handleMouseLeave}>
      <TransitionsModal
        state={openModalPassword}
        overflow="auto"
        handleCloseModal={sendChangePassword}
        width="30%"
        height="50%"
        minHeight="540px"
        minWidth="500px">
        <ChangePasswordV
          // props recibidos pra el cambio de contraseña
          control={controlUpdatePassword}
          schema={schemaUpdatePassword}
          handleSubmit={handleSubmitUpdatePassword}
          handleUpdatePassword={handleUpdatePassword}
          updateLoading={updateLoading}
          validatePassword={validatePassword}
          validationErrors={validationErrors}
        />
      </TransitionsModal>
      <Popper
        sx={{
          backgroundColor: '#fff',
          height: 'auto',
          width: '200px',
          boxShadow: '0px 0px 5px 0px #dcdcdc',
          borderRadius: '5px',
          padding: '5px 10px',
          alignContent: 'center',
          top: '60px',
          zIndex: 160016009,
        }}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}>
          {userCredentials.userName ? (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                <Stack direction="row" spacing={2} sx={{ padding: '10px' }}>
                  <Avatar {...stringAvatar(userCredentials?.userName || '')} />
                  <div>
                    <Typography sx={{ fontSize: '0.9em', lineHeight: '0.7', marginTop: '12px' }}>
                      {userCredentials?.userName || ''}
                    </Typography>
                    <Typography sx={{ fontSize: '0.8em' }}>{userCredentials?.userType}</Typography>
                  </div>
                </Stack>
                <div style={style.divBanner} />
              </div>
              <Box sx={style.option} onClick={goToPersonalInfo}>
                <AccountCircleIcon sx={style.icon} />
                <Typography sx={style.textOption}>Ver perfil</Typography>
              </Box>
              <Box sx={style.option} onClick={() => handleBenefitCategory('Redimido')}>
                <LoyaltyIcon sx={style.icon} />
                <Typography sx={style.textOption}>Beneficios redimidos</Typography>
              </Box>
              <Box sx={style.option} onClick={sendChangePassword}>
                <VpnKeyIcon sx={style.icon} />
                <Typography sx={style.textOption}>Cambiar contraseña</Typography>
              </Box>
            </Box>
          ) : null}

          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {userCredentials.userName ? (
              <Box sx={style.option} onClick={handleSessionCouponsPage}>
                <LogoutIcon sx={style.icon} />
                <Typography sx={style.textOption}>Cerrar sesión</Typography>
              </Box>
            ) : (
              <Box sx={style.option} onClick={goToLoginBeneficiary}>
                <LoginIcon sx={style.icon} />
                <Typography sx={style.textOption}>Iniciar sesión</Typography>
              </Box>
            )}
          </Box>
        </div>
      </Popper>
      <IconButton
        color={headerType === 1 ? 'inherit' : 'primary'}
        onMouseEnter={(e) => handleMouseEnter(e)}>
        {userCredentials.userName ? (
          <Stack direction="row" spacing={1}>
            <Avatar {...stringAvatar(userCredentials?.userName || '', 'header')} />
          </Stack>
        ) : (
          <Stack direction="row" spacing={1}>
            <Avatar
              sx={{
                width: 35,
                height: 35,
                color: theme.palette.common.white,
                background: theme.palette.primary.main,
              }}
            />
          </Stack>
        )}
      </IconButton>
    </div>
  );
};
