import 'reflect-metadata';
import { container } from 'tsyringe';
import { IThirdPartiesApplication } from '../../domain/interface/application/thirdParties/IThirdPartiesApplication';
import { IThirdPartiesInfrastructure } from '../../domain/interface/infrastructure/thirdParties/IThirdPartiesInfrastructure';
import { RequestThirdParties } from '../../domain/thirdParties/thirdParties/model/thirdPartiesRequest';
import { ResponseThirdParties } from '../../domain/thirdParties/thirdParties/model/thirdPartiesResponse';

export class ThirdPartiesApplication implements IThirdPartiesApplication {
  private thirdPartiesInfrastructure: IThirdPartiesInfrastructure;

  public constructor() {
    this.thirdPartiesInfrastructure = container.resolve<IThirdPartiesInfrastructure>(
      'IThirdPartiesInfrastructure'
    );
  }
  // Providers

  public async getThirdPartyProviders(
    request: RequestThirdParties
  ): Promise<ResponseThirdParties[]> {
    const result = await this.thirdPartiesInfrastructure.getThirdPartyProvidersAPI(request);
    return result;
  }

  public async getThirdPartyProviderById(
    request: RequestThirdParties
  ): Promise<ResponseThirdParties> {
    const result = await this.thirdPartiesInfrastructure.getThirdPartyByIdProviderAPI(request);
    return result;
  }

  public async createThirdPartyProvider(
    request: RequestThirdParties
  ): Promise<ResponseThirdParties> {
    const result = await this.thirdPartiesInfrastructure.createThirdPartyProviderAPI(request);
    return result;
  }

  public async updateThirdPartyProvider(
    request: RequestThirdParties
  ): Promise<ResponseThirdParties> {
    const result = await this.thirdPartiesInfrastructure.updateThirdPartyProviderAPI(request);
    return result;
  }
  // Customers

  public async getThirdPartyCustomers(
    request: RequestThirdParties
  ): Promise<ResponseThirdParties[]> {
    const result = await this.thirdPartiesInfrastructure.getThirdPartyCustomersAPI(request);
    return result;
  }

  public async deleteThirdPartyCustomer(
    request: RequestThirdParties
  ): Promise<ResponseThirdParties> {
    const result = await this.thirdPartiesInfrastructure.deleteThirdPartyCustomerAPI(request);
    return result;
  }

  public async getThirdPartyCustomerById(
    request: RequestThirdParties
  ): Promise<ResponseThirdParties> {
    const result = await this.thirdPartiesInfrastructure.getThirdPartyByIdCustomerAPI(request);
    return result;
  }

  public async createThirdPartyCustomer(
    request: RequestThirdParties
  ): Promise<ResponseThirdParties> {
    const result = await this.thirdPartiesInfrastructure.createThirdPartyCustomerAPI(request);
    return result;
  }

  public async updateThirdPartyCustomer(
    request: RequestThirdParties
  ): Promise<ResponseThirdParties> {
    const result = await this.thirdPartiesInfrastructure.updateThirdPartyCustomerAPI(request);
    return result;
  }
}
