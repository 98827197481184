export class ResponseLogin {
  public message = '';

  public error = false;

  public token = '';

  public userType = '';

  public identificationNumber = 0;

  public userName = '';

  public idThirdParty = 0;

  public idUser = 0;
}
