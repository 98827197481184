import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { UserRecoverPassV } from '../../../components/user/recoverPassword';
import {
  clearUserUpdatePasswordListPage,
  getByIdUserListPage,
  userPasswordUpdatePage,
} from '../../../../../redux/userPermissions/user/actionCreator';
import { TDispatch, TodoSuccessResponse } from '../../../../../common/utils/types.d';
import { RootState } from '../../../../../redux/store';
import {
  TUpdateUserPasswordReducer,
  TUserReducer,
} from '../../../../../redux/userPermissions/user/types.d';

export const UserCreatePassC = () => {
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const navigate = useNavigate();

  const { idUser } = useParams();

  /**
   * Dispatch de getUserById
   */
  const getUserByIdDispatch: TUserReducer = useSelector((dataState: RootState) => dataState?.user);

  /**
   * Schema de validaciones de campos
   */
  const schema = yup
    .object({
      password: yup
        .string()
        .required('La contraseña es requerida')
        .min(8, '8 caracteres')
        .test('has-uppercase', 'Una mayúscula', (value) => /[A-Z]/.test(value))
        .test('has-number', 'Un número', (value) => /(?=.*\d)/.test(value))
        .matches(/^(?=.*[@$!%*_.?&])/, 'Un carácter especial'),
      confirmPass: yup
        .string()
        .required('La contraseña es requerida')
        .min(8, '8 caracteres')
        .test('has-uppercase', 'Una mayúscula', (value) => /[A-Z]/.test(value))
        .test('has-number', 'Un número', (value) => /(?=.*\d)/.test(value))
        .matches(/^(?=.*[@$!%*_.?&])/, 'Un carácter especial'),
    })
    .required();

  /*
    dispatch
    */
  const dispatch = useDispatch();

  /**
   * Dispatch de updatePassword
   */
  const userUpdatePasswordDispatch: TUpdateUserPasswordReducer = useSelector(
    (dataState: RootState) => dataState?.passwordUpdated
  );

  /**
   * useForm
   */
  const {
    register,
    handleSubmit,
    control,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  /**
   * Función de validar contraseñas
   */
  const handleRecoverPass = () => {
    if (watch('password') === watch('confirmPass')) {
      dispatch(userPasswordUpdatePage({ ...getValues(), idUser: Number(idUser) }) as TDispatch);
      setLoadingLogin(true);
    } else {
      toast('Las contraseñas no son iguales', {
        type: 'error',

        hideProgressBar: true,
      });
      setLoadingLogin(false);
    }
  };

  /**
   * Función con array de mensajes de validación de password
   */
  const validateAll = async (values: { password: string }) => {
    try {
      await schema.validate(values, { abortEarly: false });
      return {}; // No hay errores
    } catch (error: any) {
      const errores: any = {};

      error.inner.forEach((e: { path: string | number; message: string }) => {
        if (!errores[e.path]) {
          errores[e.path] = [];
        }
        errores[e.path].push(e.message);
      });
      return errores; // Devuelve un objeto con todos los errores
    }
  };

  /**
   * UseEffect pendiente para validar a password
   */
  const password = watch('password');
  const confirmPass = watch('confirmPass');
  useEffect(() => {
    validateAll({ password }).then((errores) => {
      if (Object.keys(errores).length) {
        setValidationErrors(errores?.password);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, confirmPass]);

  /**
   * UseEffect pendiente a nueva contraseña con limpieza de campos
   */
  useEffect(() => {
    if (loadingLogin)
      if (userUpdatePasswordDispatch?.userUpdatedPassword && !userUpdatePasswordDispatch?.error) {
        reset({
          password: '',
          confirmPass: '',
        });
        toast.success('Contraseña actualizada exitosamente', {
          hideProgressBar: true,
        });
        dispatch(clearUserUpdatePasswordListPage() as TDispatch);
        if (getUserByIdDispatch?.user?.userType === 'Beneficiario') {
          navigate('/loginBeneficiario');
        } else navigate('/admin');
      } else if (
        !userUpdatePasswordDispatch?.userUpdatedPassword &&
        userUpdatePasswordDispatch?.error
      ) {
        const message = userUpdatePasswordDispatch?.error?.response
          ? (userUpdatePasswordDispatch?.error?.response?.data as TodoSuccessResponse)?.message
          : 'Ha ocurrido una incidencia';
        toast.error(`${message}`, {
          hideProgressBar: true,
        });
        dispatch(clearUserUpdatePasswordListPage() as TDispatch);
      }
  }, [
    userUpdatePasswordDispatch?.userUpdatedPassword,
    userUpdatePasswordDispatch?.error,
    dispatch,
    reset,
    navigate,
    loadingLogin,
    getUserByIdDispatch,
  ]);

  /**
   * Muestra la información de los documentos según los parámetros de la URL
   */
  useEffect(() => {
    if (idUser) {
      dispatch(getByIdUserListPage({ idUser: Number(idUser) }) as TDispatch);
    } else {
      reset({});
    }
  }, [idUser, dispatch, reset]);

  /**
   * UseEffect para llamar todos los dispatch
   */
  useEffect(() => {
    dispatch(clearUserUpdatePasswordListPage() as TDispatch);
  }, [dispatch]);

  return (
    <UserRecoverPassV
      handleRecoverPass={handleRecoverPass}
      errors={errors}
      schema={schema}
      register={register}
      control={control}
      loading={loadingLogin}
      handleSubmit={handleSubmit}
      validationErrors={validationErrors}
    />
  );
};
