export class RequestUser {
  public name? = '';

  public lastName? = '';

  public email? = '';

  public password? = '';

  public newPassword? = '';

  public token? = '';

  public cellPhone? = '';

  public userType? = '';

  public idRole? = 0;

  public idUser? = 0;

  public idDocumentType?: number | string = '';

  public idThirdParty?: string | null = '' || null;

  public documentNumber? = 0;
}
