import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import { RootState } from '../../../../../redux/store';
import { thirdPartiesCustomersListPage } from '../../../../../redux/thirdParties/actionCreator';
import { TDispatch } from '../../../../../common/utils/types.d';
import { TThirdPartiesCustomersReducer } from '../../../../../redux/thirdParties/types.d';
import { Item, TDataBenefit } from './types.d';
import { exportToExcel } from '../../../../../common/components/excelGererate';
import { BeneficiariesReportsV } from '../../../components/beneficiaries/beneficiariesReports';
import { TBenefitByBeneficiariesReducer } from '../../../../../redux/benefits/benefits/types.d';
import { getAllBenefitByBeneficiariesListPage } from '../../../../../redux/benefits/benefits/actionCreator';
import { TLoginDispatch } from '../../login/types';

export const BeneficiariesReportsC = () => {
  /**
   * UseStates
   */
  const [benefitsDataMapped, setBenefitsDataMapped] = useState<TDataBenefit[]>([]);
  const [beneficiariesDataExcel, setBeneficiariesDataExcel] = useState<Item[]>([]);

  /**
   * Dispatch
   */
  const dispatch = useDispatch();

  /**
   * Dispatch de Obtener todos los comercios
   */
  const getAllThirdPartiesCustomers: TThirdPartiesCustomersReducer = useSelector(
    (dataState: RootState) => dataState?.thirdPartiesCustomers
  );

  /**
   * Dispatch de Login
   */
  const loginDispatch: TLoginDispatch = useSelector((dataState: RootState) => dataState?.loginData);
  /**
   * Dispatch de benefit_beneficiaries
   */
  const getAllBenefitByBeneficiariesDispatch: TBenefitByBeneficiariesReducer = useSelector(
    (dataState: RootState) => dataState?.benefitByBeneficiaries
  );

  const handleExportExcel = () => {
    exportToExcel(beneficiariesDataExcel, 'ReportesDeBeneficiarios');
    toast.success('Descargado correctamente', {
      hideProgressBar: true,
      autoClose: 1000,
    });
  };

  /**
   * UseEffect de verificación de la data a mapear
   */
  useEffect(() => {
    if (
      getAllBenefitByBeneficiariesDispatch.benefitByBeneficiaries instanceof Array &&
      (loginDispatch?.login?.userType === 'Administrador' ||
        loginDispatch?.login?.userType === 'Usuario')
    ) {
      const currentDate = `${moment().format('YYYY-MM-D')}`;

      const data = getAllBenefitByBeneficiariesDispatch.benefitByBeneficiaries?.map(
        (x: any, i: number) => {
          const ID = i + 1;
          return {
            ...x,
            statusBenefit: {
              value:
                (String(x?.endDate) <= currentDate && 3) ||
                (x?.condition === 3 && 2) ||
                (x?.condition === 1 && 1),
              label:
                (String(x?.endDate) <= currentDate && 'Vencido') ||
                (x?.condition === 3 && 'Reclamado') ||
                (x?.condition === 1 && 'Adquirido'),
            },
            available: Number(x?.usagePerson) - Number(x?.consumed),
            ID: !x ? null : ID,
          };
        }
      );
      setBenefitsDataMapped(data);
    } else if (
      getAllBenefitByBeneficiariesDispatch.benefitByBeneficiaries instanceof Array &&
      loginDispatch.login?.idThirdParty !== 0
    ) {
      const currentDate = `${moment().format('YYYY-MM-D')}`;
      const filterData = getAllBenefitByBeneficiariesDispatch?.benefitByBeneficiaries
        ?.filter((item) => item?.idThirdParty === loginDispatch?.login?.idThirdParty)
        .map((x: any, i) => {
          const ID = i + 1;
          return {
            ...x,
            statusBenefit: {
              value:
                (String(x?.endDate) <= currentDate && 3) ||
                (x?.condition === 3 && 2) ||
                (x?.condition === 1 && 1),
              label:
                (String(x?.endDate) <= currentDate && 'Vencido') ||
                (x?.condition === 3 && 'Reclamado') ||
                (x?.condition === 1 && 'Adquirido'),
            },
            available: Number(x?.usagePerson) - Number(x?.consumed),
            ID: !x ? null : ID,
          };
        });
      setBenefitsDataMapped(filterData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllBenefitByBeneficiariesDispatch.benefitByBeneficiaries]);

  /**
   * UseEffect que se encarga de mostrar la información en el archivo excel
   */
  useEffect(() => {
    if (
      getAllBenefitByBeneficiariesDispatch.benefitByBeneficiaries instanceof Array &&
      (loginDispatch?.login?.userType === 'Administrador' ||
        loginDispatch?.login?.userType === 'Usuario')
    ) {
      const currentDate = `${moment().format('YYYY-MM-D')}`;

      const data = getAllBenefitByBeneficiariesDispatch.benefitByBeneficiaries?.map(
        (x: any, i: number) => {
          const ID = i + 1;
          return {
            ITEM: ID,
            'NOMBRE DEL BENEFICIO': String(x?.benefit?.name),
            'STATUS DEL BENEFICIO':
              (String(x?.endDate) <= currentDate && 'Vencido') ||
              (x?.condition === 3 && 'Reclamado') ||
              (x?.condition === 1 && 'Adquirido'),

            'NÚMEROS DISPONIBLES': Number(x?.usagePerson) - Number(x?.consumed),
            'NÚMERO RECLAMADO': Number(x?.consumed),
            'FECHA DE VENCIMIENTO': x?.startDate,
          };
        }
      );
      setBeneficiariesDataExcel(data);
    } else if (
      getAllBenefitByBeneficiariesDispatch.benefitByBeneficiaries instanceof Array &&
      loginDispatch.login?.idThirdParty !== 0
    ) {
      const currentDate = `${moment().format('YYYY-MM-D')}`;
      const filterDataForExcel = getAllBenefitByBeneficiariesDispatch?.benefitByBeneficiaries
        ?.filter((item) => item?.idThirdParty === loginDispatch?.login?.idThirdParty)
        .map((x: any, i) => {
          const ID = i + 1;
          return {
            ITEM: ID,
            'NOMBRE DEL BENEFICIO': String(x?.benefit?.name),
            'STATUS DEL BENEFICIO':
              (String(x?.endDate) <= currentDate && 'Vencido') ||
              (x?.condition === 3 && 'Reclamado') ||
              (x?.condition === 1 && 'Adquirido'),

            'NÚMEROS DISPONIBLES': Number(x?.usagePerson) - Number(x?.consumed),
            'NÚMERO RECLAMADO': Number(x?.consumed),
            'FECHA DE VENCIMIENTO': String(x?.startDate),
          };
        });
      setBeneficiariesDataExcel(filterDataForExcel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllBenefitByBeneficiariesDispatch.benefitByBeneficiaries]);

  /**
   * UseEffect que se encarga de llamar a todos los dispatches
   */
  useEffect(() => {
    dispatch(thirdPartiesCustomersListPage() as TDispatch);
    dispatch(getAllBenefitByBeneficiariesListPage({ idBeneficiary: 0 }) as TDispatch);
  }, [dispatch]);

  return (
    <BeneficiariesReportsV
      data={benefitsDataMapped}
      handleExportExcel={handleExportExcel}
      loading={getAllThirdPartiesCustomers?.loading}
    />
  );
};
