import { AxiosError } from 'axios';
import { ResponseProfession } from '../../../core/domain/workInformation/profession/model/responseProfession';

const actions = {
  PROFESSION_LIST_PAGE_BEGIN: 'PROFESSION_LIST_PAGE_BEGIN',
  PROFESSION_LIST_PAGE_SUCCESS: 'PROFESSION_LIST_PAGE_SUCCESS',
  PROFESSION_LIST_PAGE_ERR: 'PROFESSION_LIST_PAGE_ERR',

  professionListPageBegin: (): { type: string } => {
    return {
      type: actions.PROFESSION_LIST_PAGE_BEGIN,
    };
  },

  professionListPageSuccess: (
    data: ResponseProfession[] | ResponseProfession
  ): { type: string; data: ResponseProfession[] | ResponseProfession } => {
    return {
      type: actions.PROFESSION_LIST_PAGE_SUCCESS,
      data,
    };
  },

  professionListPageErr: (err: AxiosError): { type: string; err: AxiosError } => {
    return {
      type: actions.PROFESSION_LIST_PAGE_ERR,
      err,
    };
  },
};

export default actions;
