import React from 'react';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
// import tramaFondoLogin from '../../../../../assets/img/trama4.svg';
import pkg from '../../../../../../package.json';
import { ButtonSubmit, ButtonToBack, Img } from './styled';
// import { InputField } from '../../../../../common/components/formAdapters';
import { IBeneficiaryLogin } from './types.d';
import { InputField } from '../../../../../common/components/formAdapters';
import image from '../../../../../common/components/images';

export const BeneficiaryRecoveryPasswordV: React.FC<IBeneficiaryLogin> = ({
  handleSubmit,
  handleRecoverPass,
  loading,
  control,
  schema,
  goBack,
  sentEmail,
  watchEmail,
}) => {
  const theme = useTheme();
  const LogoBonusColor = image('logoBonusColor');
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        background: 'lightgray',
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '425px',
          '@media screen and (max-width: 425px)': {
            minWidth: '320px',
          },
          background: 'white',
          boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);',
          overflow: 'auto',
        }}>
        <Box
          sx={{
            height: '85%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Img src={LogoBonusColor} alt="Bonus" />
          <Box
            sx={{
              height: '270px',
              width: '80%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              textAlign: 'center',
            }}>
            <Typography
              variant="h5"
              sx={{ fontFamily: 'Poppins-Regular', fontWeight: '600' }}
              color={theme.palette.primary.main}>
              ¿Olvidaste tu contraseña?
            </Typography>
            <InputField
              name="email"
              control={control}
              label="Correo electrónico"
              schema={schema.fields.email}
            />
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
              }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <ButtonSubmit
                  disabled={loading}
                  type="button"
                  style={{
                    color: '#fff',
                    fontSize: '16px',
                    backgroundColor: theme.palette.primary.main,
                  }}
                  onClick={handleSubmit(handleRecoverPass)}>
                  {!loading ? 'Enviar' : <CircularProgress size={25} sx={{ color: 'white' }} />}
                </ButtonSubmit>
                <ButtonToBack type="button" onClick={goBack}>
                  <Typography fontSize="15px" color={theme.palette.primary.main}>
                    Volver
                  </Typography>
                </ButtonToBack>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: '90%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: '15px',
              textAlign: 'center',
              opacity: 1,
            }}>
            <Typography fontSize="14px" color={theme.palette.primary.main}>
              Al hacer clic en iniciar, estás aceptando nuestros{' '}
              <span style={{ fontWeight: 'bold' }}>términos y condiciones de servicio</span> y estás
              aceptando nuestras{' '}
              <span style={{ fontWeight: 'bold' }}>políticas de privacidad de datos.</span>
            </Typography>
            <Typography
              color={theme.palette.primary.main}
              sx={{
                fontSize: '11px',
                padding: '5px',
                width: '100%',
                textAlign: 'center',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}>
              ® WOW Desarrollos Digitales - Bonus plus {pkg.version}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
