import React from 'react';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, CircularProgress, Grid, Tooltip, Typography, useTheme } from '@mui/material';
import { Logo, TramaFloating } from './style';
import { InputField, NormalSelectField } from '../../../../common/components/formAdapters';
import { TPersonalInfoRegister } from './types.d';
import image from '../../../../common/components/images';

export const OpenBeneficiaryRegisterFormV: React.FC<TPersonalInfoRegister> = ({
  schema,
  control,
  dataGender,
  dataCities,
  watchDepartment,
  dataDepartments,
  dataStreetTypes,
  dataDocumentType,
  watchIdStreetTypeOne,
  handleUpdate,
  callBeneficiary,
  loadingData,
  updateLoading,
}) => {
  const theme = useTheme();
  const LogoBonus = image('logoBonus');
  const tramaFondoLogin = image('tramaFondoLogin');

  const styles = {
    header: {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      fontSize: '1.8rem',
      textAlign: 'left',
      fontFamily: 'Poppins-Regular',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.5rem',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    formBox: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      background: '#fff',
      borderRadius: '16px',
      boxShadow: '0px 3px 6px #00000029',
    },
    buttonSubmit: {
      display: 'flex',
      alignContent: 'center',
      width: '190px',
      height: '35px',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      '@media screen and (max-width: 899px)': {
        width: '200px',
        marginBottom: '10px',
      },
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);',
    },
    bulkButtonSubmit: {
      display: 'flex',
      alignContent: 'center',
      width: '170px',
      height: '35px',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      '@media screen and (max-width: 899px)': {
        width: '200px',
      },
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);',
    },
    bulkButtonBox: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: '15px',
    },
    buttonBox: {
      display: 'flex',
      justifyContent: 'flex-end',
      '@media screen and (max-width: 899px)': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
      }}>
      <Box
        sx={{
          width: '40%',
          height: '100%',
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: theme.palette.primary.main,
          // border:"solid 1px red",
          '@media screen and (max-width: 750px)': {
            display: 'none',
          },
        }}>
        <Logo src={LogoBonus} />
        <TramaFloating src={tramaFondoLogin} />
      </Box>
      <Box
        sx={{
          width: '60%',
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          height: '100%',
          overflow: 'auto',
          '@media screen and (max-width: 750px)': {
            width: '100%',
          },
        }}>
        <Box
          sx={{
            width: '90%',
            display: 'flex',
            borderRadius: '5px',
            flexDirection: 'column',
            paddingBottom: '15px',
            height: '100%',
            '@media screen and (max-width: 750px)': {
              width: '100%',
            },
          }}>
          <Box sx={{ paddingLeft: '15px', paddingRight: '15px' }}>
            <Typography
              variant="h3"
              color={theme.palette.primary.main}
              sx={{ pb: '15px', pt: '15px' }}>
              Registro
            </Typography>
            <Typography
              variant="h5"
              color={theme.palette.primary.main}
              sx={{
                pt: '15px',
                borderBottom: `2px solid ${theme.palette.primary.main} `,
              }}>
              Información básica
            </Typography>
            <Box
              sx={{
                pb: '15px',
                pt: '15px',
                '@media screen and (max-width: 900px)': {
                  // paddingBottom: '0',
                },
              }}>
              <Grid
                container
                spacing={{ xs: 2, sm: 2, md: 2 }}
                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <NormalSelectField
                    name="idDocumentType"
                    label="Tipo de identificación"
                    key="idDocumentType"
                    schema={schema?.fields?.idDocumentType}
                    control={control}
                    options={
                      (dataDocumentType || [])?.map((x) => ({
                        value: x?.idDocumentType,
                        label: x?.name,
                      })) || []
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <InputField
                    label="Número de identificación"
                    name="identificationNumber"
                    onBlur={callBeneficiary}
                    autoComplete="identificationNumber"
                    inputProps={{ autofill: 'identificationNumber' }}
                    schema={schema}
                    control={control}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <InputField
                    disabled={loadingData}
                    iconType="showPasswordEye"
                    control={control}
                    label="Contraseña"
                    type="password"
                    name="password"
                    autoComplete="new-password"
                    inputProps={{ autofill: 'new-password' }}
                    schema={schema.fields.password}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <NormalSelectField
                    disabled={loadingData}
                    name="idGender"
                    label="Género"
                    key="idGender"
                    schema={schema?.fields?.idGender}
                    control={control}
                    options={
                      (dataGender || [])?.map((x) => ({
                        value: x?.idGender,
                        label: x?.name,
                      })) || []
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <InputField
                    disabled={loadingData}
                    label="Primer nombre"
                    name="firstName"
                    schema={schema}
                    control={control}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <InputField
                    disabled={loadingData}
                    label="Segundo nombre"
                    name="middleName"
                    schema={schema}
                    control={control}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <InputField
                    disabled={loadingData}
                    label="Primer apellido"
                    name="firstSurname"
                    schema={schema}
                    control={control}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <InputField
                    disabled={loadingData}
                    label="Segundo apellido"
                    name="secondSurname"
                    schema={schema}
                    control={control}
                  />
                </Grid>
              </Grid>
            </Box>

            <Typography
              variant="h5"
              color={theme.palette.primary.main}
              sx={{
                pt: '15px',
                borderBottom: `2px solid ${theme.palette.primary.main} `,
              }}>
              Información de residencia y contacto
            </Typography>
            <Box sx={{ pb: '15px', pt: '30px' }}>
              <Grid container spacing={{ xs: 2, sm: 2, md: 2 }}>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <NormalSelectField
                    disabled={loadingData}
                    name="address.idDepartment"
                    label="Departamento"
                    key="address.idDepartment"
                    schema={schema?.fields?.address.idDepartment}
                    control={control}
                    options={
                      (dataDepartments || [])?.map((x) => ({
                        value: x?.idDepartment,
                        label: x?.name?.toUpperCase(),
                      })) || []
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <NormalSelectField
                    name="address.idCity"
                    label="Ciudad"
                    key="address.idCity"
                    schema={schema?.fields?.address?.idCity}
                    control={control}
                    options={
                      (dataCities || [])
                        ?.filter((x) => x?.idDepartment === Number(watchDepartment))
                        .map((x) => {
                          return {
                            value: x?.idCity,
                            label: x?.name?.toUpperCase(),
                          };
                        }) || []
                    }
                    disabled={!watchDepartment || loadingData}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <InputField
                    disabled={loadingData}
                    label="Barrio"
                    name="address.neighBorhood"
                    schema={schema}
                    control={control}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <NormalSelectField
                    disabled={loadingData}
                    name="address.idAddressFirstStreetType"
                    label="Tipo de dirección 1"
                    schema={schema?.fields?.address?.idAddressFirstStreetType}
                    control={control}
                    options={
                      (dataStreetTypes || [])?.map((x) => ({
                        value: x?.idStreetType,
                        label: x?.name,
                      })) || []
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <InputField
                    disabled={loadingData}
                    label="Número"
                    name="address.firstStreetNumber"
                    schema={schema}
                    control={control}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <NormalSelectField
                    disabled={loadingData}
                    name="address.idAddressSecondStreetType"
                    label="Tipo de dirección 2"
                    schema={schema?.fields?.address?.idAddressSecondStreetType}
                    control={control}
                    options={
                      (dataStreetTypes || [])
                        ?.filter((x) => x?.idStreetType !== Number(watchIdStreetTypeOne))
                        .map((x) => ({
                          value: x?.idStreetType,
                          label: x?.name,
                        })) || []
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <InputField
                    disabled={loadingData}
                    label="Número 2"
                    name="address.secondStreetNumber"
                    schema={schema}
                    control={control}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <InputField
                    disabled={loadingData}
                    label="Número 3"
                    name="address.thirdStreetNumber"
                    schema={schema}
                    control={control}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <InputField
                    disabled={loadingData}
                    label="Detalle"
                    name="address.description"
                    schema={schema}
                    control={control}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <InputField
                    disabled={loadingData}
                    label="Celular"
                    name="cellPhone"
                    schema={schema}
                    control={control}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <InputField
                    disabled={loadingData}
                    label="Teléfono"
                    name="telephone"
                    schema={schema}
                    control={control}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <InputField
                    disabled={loadingData}
                    label="Correo electrónico"
                    name="email"
                    schema={schema}
                    control={control}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box sx={styles.buttonBox}>
              <Tooltip title="Guardar">
                <Button
                  disabled={loadingData}
                  sx={{ ...styles.buttonSubmit, color: '#fff' }}
                  type="button"
                  onClick={handleUpdate}
                  startIcon={
                    updateLoading ? null : <SaveIcon fontSize="small" sx={{ color: '#fff' }} />
                  }>
                  {updateLoading ? (
                    <CircularProgress size={15} sx={{ color: 'white' }} />
                  ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography fontSize="14px" color="#fff">
                        Guardar
                      </Typography>
                    </Box>
                  )}
                </Button>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
