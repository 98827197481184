import { AxiosError } from 'axios';
import { ResponseUser } from '../../../core/domain/userPermissions/user/model/responseUser';
import { UserState } from '../../../core/domain/userPermissions/user/state/userState';

import {
  TUpdateRoleToUserReducer,
  TUpdateUserLoggedPasswordReducer,
  TUpdateUserPasswordReducer,
  TUserCreateReducer,
  TUserDeleteReducer,
  TUserLoginReducer,
  TUserRecoveryPasswordReducer,
  TUserReducer,
  TUsersByRoleReducer,
  TUsersReducer,
  TUserUpdateReducer,
} from './types.d';
import {
  actionsUpdateRoleToUser,
  actionsUser,
  actionsUserCreate,
  actionsUserDelete,
  actionsUserLoggedUpdatePassword,
  actionsUserLogin,
  actionsUserRecoveryPassword,
  actionsUsers,
  actionsUsersByRole,
  actionsUserUpdate,
  actionsUserUpdatePassword,
} from './actions';

const { USERS_LIST_PAGE_BEGIN, USERS_LIST_PAGE_SUCCESS, USERS_LIST_PAGE_ERR } = actionsUsers;

const { USER_LIST_PAGE_BEGIN, USER_LIST_PAGE_SUCCESS, USER_LIST_PAGE_ERR } = actionsUser;

const { USER_LOGIN_PAGE_BEGIN, USER_LOGIN_PAGE_SUCCESS, USER_LOGIN_PAGE_ERR } = actionsUserLogin;

const { USER_CREATE_PAGE_BEGIN, USER_CREATE_PAGE_SUCCESS, USER_CREATE_PAGE_ERR } =
  actionsUserCreate;

const { USER_UPDATE_PAGE_BEGIN, USER_UPDATE_PAGE_SUCCESS, USER_UPDATE_PAGE_ERR } =
  actionsUserUpdate;

const { USER_DELETE_PAGE_BEGIN, USER_DELETE_PAGE_SUCCESS, USER_DELETE_PAGE_ERR } =
  actionsUserDelete;

const {
  USER_UPDATE_PASSWORD_PAGE_BEGIN,
  USER_UPDATE_PASSWORD_PAGE_SUCCESS,
  USER_UPDATE_PASSWORD_PAGE_ERR,
} = actionsUserUpdatePassword;

const {
  USER_LOGGED_UPDATE_PASSWORD_PAGE_BEGIN,
  USER_LOGGED_UPDATE_PASSWORD_PAGE_SUCCESS,
  USER_LOGGED_UPDATE_PASSWORD_PAGE_ERR,
} = actionsUserLoggedUpdatePassword;

const {
  UPDATE_ROLE_TO_USER_PAGE_BEGIN,
  UPDATE_ROLE_TO_USER_PAGE_SUCCESS,
  UPDATE_ROLE_TO_USER_PAGE_ERR,
} = actionsUpdateRoleToUser;

const { USERS_GET_BY_ROLE_BEGIN, USERS_GET_BY_ROLE_SUCCESS, USERS_GET_BY_ROLE_ERR } =
  actionsUsersByRole;

const {
  USER_RECOVERY_PASSWORD_PAGE_BEGIN,
  USER_RECOVERY_PASSWORD_PAGE_ERR,
  USER_RECOVERY_PASSWORD_PAGE_SUCCESS,
} = actionsUserRecoveryPassword;

const initialUser: UserState = new UserState();

export const UsersReducer = (
  state = initialUser,
  action: { type: string; err: AxiosError | undefined; data: ResponseUser[] }
): TUsersReducer => {
  const { type, data, err } = action;
  switch (type) {
    case USERS_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USERS_LIST_PAGE_SUCCESS:
      return {
        ...state,
        users: data,
        loading: false,
      };
    case USERS_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const UserLoginReducer = (
  state = initialUser,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseUser | undefined;
  }
): TUserLoginReducer => {
  const { type, data, err } = action;
  switch (type) {
    case USER_LOGIN_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USER_LOGIN_PAGE_SUCCESS:
      return {
        ...state,
        userLogin: data,
        loading: false,
      };
    case USER_LOGIN_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const UserReducer = (
  state = initialUser,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseUser | undefined;
  }
): TUserReducer => {
  const { type, data, err } = action;
  switch (type) {
    case USER_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USER_LIST_PAGE_SUCCESS:
      return {
        ...state,
        user: data,
        loading: false,
      };
    case USER_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const UserCreateReducer = (
  state = initialUser,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseUser | undefined;
  }
): TUserCreateReducer => {
  const { type, data, err } = action;
  switch (type) {
    case USER_CREATE_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USER_CREATE_PAGE_SUCCESS:
      return {
        ...state,
        userCreated: data,
        loading: false,
      };
    case USER_CREATE_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const UserUpdateReducer = (
  state = initialUser,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseUser | undefined;
  }
): TUserUpdateReducer => {
  const { type, data, err } = action;
  switch (type) {
    case USER_UPDATE_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USER_UPDATE_PAGE_SUCCESS:
      return {
        ...state,
        userUpdated: data,
        loading: false,
      };
    case USER_UPDATE_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const UserRecoveryPasswordReducer = (
  state = initialUser,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseUser | undefined;
  }
): TUserRecoveryPasswordReducer => {
  const { type, data, err } = action;
  switch (type) {
    case USER_RECOVERY_PASSWORD_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USER_RECOVERY_PASSWORD_PAGE_SUCCESS:
      return {
        ...state,
        userRecoveryPassword: data,
        loading: false,
      };
    case USER_RECOVERY_PASSWORD_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const UserDeleteReducer = (
  state = initialUser,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseUser | undefined;
  }
): TUserDeleteReducer => {
  const { type, data, err } = action;
  switch (type) {
    case USER_DELETE_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USER_DELETE_PAGE_SUCCESS:
      return {
        ...state,
        userDeleted: data,
        loading: false,
      };
    case USER_DELETE_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const UserUpdatePasswordReducer = (
  state = initialUser,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseUser | undefined;
  }
): TUpdateUserPasswordReducer => {
  const { type, data, err } = action;
  switch (type) {
    case USER_UPDATE_PASSWORD_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USER_UPDATE_PASSWORD_PAGE_SUCCESS:
      return {
        ...state,
        userUpdatedPassword: data,
        loading: false,
      };
    case USER_UPDATE_PASSWORD_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const UserLoggedUpdatePasswordReducer = (
  state = initialUser,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseUser | undefined;
  }
): TUpdateUserLoggedPasswordReducer => {
  const { type, data, err } = action;
  switch (type) {
    case USER_LOGGED_UPDATE_PASSWORD_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USER_LOGGED_UPDATE_PASSWORD_PAGE_SUCCESS:
      return {
        ...state,
        userLoggedUpdatedPassword: data,
        loading: false,
      };
    case USER_LOGGED_UPDATE_PASSWORD_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const UpdateRoleToUserReducer = (
  state = initialUser,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseUser | undefined;
  }
): TUpdateRoleToUserReducer => {
  const { type, data, err } = action;
  switch (type) {
    case UPDATE_ROLE_TO_USER_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ROLE_TO_USER_PAGE_SUCCESS:
      return {
        ...state,
        updatedRoleToUser: data,
        loading: false,
      };
    case UPDATE_ROLE_TO_USER_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const UsersByRoleReducer = (
  state = initialUser,
  action: { type: string; err: AxiosError | undefined; data: ResponseUser[] }
): TUsersByRoleReducer => {
  const { type, data, err } = action;
  switch (type) {
    case USERS_GET_BY_ROLE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USERS_GET_BY_ROLE_SUCCESS:
      return {
        ...state,
        usersByRole: data,
        loading: false,
      };
    case USERS_GET_BY_ROLE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};
