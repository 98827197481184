import 'reflect-metadata';
import { AxiosResponse } from 'axios';
import { ICountriesInfrastructure } from '../../../domain/interface/infrastructure/addresses/ICountriesInfrastructure';
import { RequestCountries } from '../../../domain/addresses/countries/model/requestCountries';
import { ResponseCountries } from '../../../domain/addresses/countries/model/responseCountries';
import { processDataBackend } from '../../../../common/utils/functions';

export class CountriesInfrastructure implements ICountriesInfrastructure {
  private a = '';

  public async getCountriesAPI(request: RequestCountries): Promise<ResponseCountries[]> {
    this.a = 'aaa';
    const res: AxiosResponse = await processDataBackend(
      'GET',
      '/api/countries/getAll',
      {},
      { request }
    );

    const countries: ResponseCountries[] = res.data;

    return Promise.resolve(countries);
  }
}
