import 'reflect-metadata';
import { AxiosResponse } from 'axios';
import { IStreetTypesInfrastructure } from '../../../domain/interface/infrastructure/addresses/IStreetTypesInfrastructure';
import { RequestStreetTypes } from '../../../domain/addresses/streetTypes/model/requestStreetTypes';
import { ResponseStreetTypes } from '../../../domain/addresses/streetTypes/model/responseStreetTypes';
import { processDataBackend } from '../../../../common/utils/functions';

export class StreetTypesInfrastructure implements IStreetTypesInfrastructure {
  private a = '';

  public async getStreetTypesAPI(request: RequestStreetTypes): Promise<ResponseStreetTypes[]> {
    this.a = 'aaa';
    const res: AxiosResponse = await processDataBackend(
      'GET',
      '/api/streetTypes/getAll',
      {},
      { request }
    );

    const streetTypes: ResponseStreetTypes[] = res.data;

    return Promise.resolve(streetTypes);
  }
}
