import 'reflect-metadata';
import { AxiosResponse } from 'axios';
import { RequestEconomicActivities } from '../../../domain/taxInformation/economicActivities/model/requestEconomicActivities';
import { ResponseEconomicActivities } from '../../../domain/taxInformation/economicActivities/model/responseEconomicActivities';
import { IEconomicActivitiesInfrastructure } from '../../../domain/interface/infrastructure/taxInformation/IeconomicActivitiesInfrastructure';
import { processDataBackend } from '../../../../common/utils/functions';

export class EconomicActivitiesInfrastructure implements IEconomicActivitiesInfrastructure {
  private a = '';

  public async getEconomicActivitiesAPI(
    request: RequestEconomicActivities
  ): Promise<ResponseEconomicActivities[]> {
    this.a = 'aaa';

    const res: AxiosResponse = await processDataBackend(
      'GET',
      '/api/economicActivity/getAll',
      {},
      { request }
    );

    const economicActivity: ResponseEconomicActivities[] = res.data;
    return Promise.resolve(economicActivity);
  }
}
