import { AxiosError } from 'axios';
import { ResponseBenefitCategory } from '../../../core/domain/benefits/benefitCategories/model/responseBenefitCategory';
import { actionsBenefitCategories } from './actions';
import { TBenefitCategoriesReducer } from './types.d';
import { BenefitCategoryState } from '../../../core/domain/benefits/benefitCategories/state/benefitCategoryState';

const {
  BENEFIT_CATEGORIES_LIST_PAGE_BEGIN,
  BENEFIT_CATEGORIES_LIST_PAGE_SUCCESS,
  BENEFIT_CATEGORIES_LIST_PAGE_ERR,
} = actionsBenefitCategories;

const initialBenefitCategory: BenefitCategoryState = new BenefitCategoryState();

const BenefitCategoriesReducer = (
  state = initialBenefitCategory,
  action: { type: string; err: AxiosError | undefined; data: ResponseBenefitCategory[] | undefined }
): TBenefitCategoriesReducer => {
  const { type, data, err } = action;
  switch (type) {
    case BENEFIT_CATEGORIES_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BENEFIT_CATEGORIES_LIST_PAGE_SUCCESS:
      return {
        ...state,
        benefitCategories: data,
        loading: false,
      };
    case BENEFIT_CATEGORIES_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { BenefitCategoriesReducer };
