import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { DataGrid, GridColDef, GridColumnHeaderParams, esES } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { TitleComponent } from '../../../../../common/components/titles/styled';
import { ToolbarList } from '../../../../../common/components/toolbar';
import { IMembershipsList } from './types.d';
import { numberFormat } from '../../../../../common/utils/functions';
import { calculateCheckDigit } from '../../../../../lib';
import { TransitionsModalAlert } from '../../../../../common/components/modalAlert';

export const MembershipsListV: React.FC<IMembershipsList> = ({
  data,
  loading,
  handleExportExcel,
  handleDelete,
  openDelete,
  handleClose,
  deleteLoading,
  buttonDelete,
  disabled,
}) => {
  const theme = useTheme();
  const styles = {
    header: {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      fontSize: '30px',
      textAlign: 'left',
      fontFamily: 'Poppins-Regular',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
    },
    headerName: {
      fontWeight: 'bold',
      fontSize: '14px',
      color: theme.palette.primary.main,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    icon: {
      display: 'flex',
      fontSize: '20px',
      color: '#fff',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        display: 'flex',
        color: '#fff',
      },
    },
    textButton: {
      fontSize: '13px',
      color: '#fff',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    button: {
      background: theme.palette.success.light,
      display: 'flex',
      height: '35px',
      justifyContent: 'center',
      gap: '10px',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        width: '30px',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:hover': {
        backgroundColor: theme.palette.success.main,
      },
      boxShadow: '0px 3px 5px 0px #dcdcdc',
    },
  };
  const columns: GridColDef[] = [
    {
      field: 'ID',
      headerName: 'ITEM',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>ITEM</Typography>
      ),
    },
    {
      field: 'idNature',
      headerName: 'NATURALEZA',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>NATURALEZA</Typography>
      ),
      renderCell: ({ row }) => {
        return (
          <Box sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
            {row?.idNature === 1 ? 'NATURAL' : 'JURÍDICO'}
          </Box>
        );
      },
    },
    {
      field: 'identificationNumber',
      headerName: 'N° IDENTIFICACIÓN',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>N° IDENTIFICACIÓN</Typography>
      ),
      renderCell: ({ row }) => {
        return (
          <Box sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
            {row?.idDocumentType === 4
              ? `${numberFormat(row?.identificationNumber)} - ${calculateCheckDigit(
                  row?.identificationNumber
                )}`
              : `${numberFormat(row?.identificationNumber)}`}
          </Box>
        );
      },
    },
    {
      field: 'names',
      headerName: 'NOMBRE O RAZÓN SOCIAL',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>NOMBRE O RAZÓN SOCIAL</Typography>
      ),
      renderCell: ({ row }) => {
        return (
          <Box sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
            {row?.names?.toUpperCase()}
          </Box>
        );
      },
    },
    {
      field: 'cellPhone',
      headerName: 'CELULAR',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>CELULAR</Typography>
      ),
      renderCell: ({ row }) => {
        return (
          <Box sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
            {row?.cellPhone}
          </Box>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'ACCIONES',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>ACCIONES</Typography>
      ),
      renderCell({ row }) {
        return (
          <>
            <Tooltip title="Editar información de la membresía">
              <Link
                to={`/membresias/editar/${row?.idThirdParty}?natureType=${
                  row?.idNature === 1 ? 1 : 0
                }`}>
                <IconButton>
                  <BorderColorIcon fontSize="small" color="primary" />
                </IconButton>
              </Link>
            </Tooltip>
            <Tooltip title="Eliminar membresía">
              <IconButton disabled={deleteLoading} onClick={() => buttonDelete(row?.idThirdParty)}>
                <HighlightOffIcon fontSize="small" color="secondary" />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];
  return (
    <Box
      style={{
        display: 'flex',
        width: '100%',
      }}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          background: '#fff',
          borderRadius: '16px',
          padding: '20px',
          boxShadow: '0px 3px 6px #00000029',
          minHeight: '400px',
        }}>
        <div style={{ width: '100%', height: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <TitleComponent>Lista de membresías</TitleComponent>
            <Tooltip title="Descargar excel">
              <Button
                onClick={handleExportExcel}
                disabled={loading}
                variant="contained"
                sx={styles.button}>
                {loading ? (
                  <CircularProgress size={16} sx={styles.icon} />
                ) : (
                  <SaveAltIcon sx={styles.icon} />
                )}
                <Typography sx={styles.textButton}>Descargar</Typography>
              </Button>
            </Tooltip>
          </Box>
          <div style={{ height: '85%' }}>
            <DataGrid
              rows={data || []}
              getRowId={(row) => row?.idThirdParty}
              columns={columns}
              rowsPerPageOptions={[25, 50, 100]}
              density="compact"
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              components={{ Toolbar: ToolbarList }}
              loading={!data?.length && loading}
            />
          </div>
        </div>
      </Box>
      <Box>
        <TransitionsModalAlert
          state={openDelete}
          handleCloseModal={handleClose}
          width="25%"
          height="25%"
          title="Confirmar acción"
          subTitle="Antes de continuar!"
          paragraph="¿Estás seguro de realizar esta acción?"
          nameAccept={!deleteLoading ? 'SI' : <CircularProgress size={15} />}
          disabledLoading={disabled}
          nameDecline="NO"
          onClickAccept={handleDelete}
        />
      </Box>
    </Box>
  );
};
