import { AxiosError } from 'axios';
import { IConsecutiveApplication } from '../../core/domain/interface/application/consecutives/IConsecutiveApplication';
import { AppDispatch } from '../store';
import { container } from 'tsyringe';
import { actionsConsecutive } from './actions';

const { consecutiveListPageBegin, consecutiveListPageSuccess, consecutiveListPageErr } =
  actionsConsecutive;

export const consecutiveListPage = (
  idCategory: number
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(consecutiveListPageBegin());
      const consecutiveApplication =
        container.resolve<IConsecutiveApplication>('IConsecutiveApplication');
      const result = await consecutiveApplication.getByIdConsecutive(idCategory);
      dispatch(consecutiveListPageSuccess(result));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(consecutiveListPageErr(error));
    }
  };
};

export const clearBenefitById = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(consecutiveListPageBegin());
    dispatch(consecutiveListPageErr(undefined));
    dispatch(consecutiveListPageSuccess(undefined));
  };
};
