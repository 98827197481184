export class ResponseCustomerValidities {
  public idCustomerValidity = 0;

  public idThirdParty? = 0;

  public idSeller? = 0;

  public acquiredBeneficiary? = 0;

  public consumedBeneficiary? = 0;

  public startDate? = '';

  public endDate? = '';

  public amountPaid? = 0;

  public detail? = '';

  public condition? = 0;

  public state? = 0;
}
