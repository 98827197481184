import { AxiosError } from 'axios';
import {
  actionsThirdParties,
  actionsThirdPartiesCustomers,
  actionsThirdParty,
  actionsThirdPartyCustomer,
  actionsThirdPartyCustomerCreate,
  actionsThirdPartyCustomerDelete,
  actionsThirdPartyCustomerUpdate,
  actionsThirdPartyProviderCreate,
  actionsThirdPartyProviderUpdate,
} from './actions';
import {
  TCreateThirdPartyProviderReducer,
  TThirdPartiesProvidersReducer,
  TUpdateThirdPartyProviderReducer,
  TThirdPartyProviderReducer,
  TThirdPartiesCustomersReducer,
  TThirdPartyCustomerReducer,
  TCreateThirdPartyCustomerReducer,
  TUpdateThirdPartyCustomerReducer,
  TDeleteThirdPartyCustomerReducer,
} from './types.d';
import { ResponseThirdParties } from '../../core/domain/thirdParties/thirdParties/model/thirdPartiesResponse';
import { ThirdPartiesState } from '../../core/domain/thirdParties/thirdParties/state/thirdParties';

// Providers
const { THIRDPARTY_CREATE_PAGE_BEGIN, THIRDPARTY_CREATE_PAGE_SUCCESS, THIRDPARTY_CREATE_PAGE_ERR } =
  actionsThirdPartyProviderCreate;

const { THIRDPARTIES_LIST_PAGE_BEGIN, THIRDPARTIES_LIST_PAGE_SUCCESS, THIRDPARTIES_LIST_PAGE_ERR } =
  actionsThirdParties;
const { THIRDPARTY_UPDATE_PAGE_BEGIN, THIRDPARTY_UPDATE_PAGE_SUCCESS, THIRDPARTY_UPDATE_PAGE_ERR } =
  actionsThirdPartyProviderUpdate;
const { THIRDPARTY_LIST_PAGE_BEGIN, THIRDPARTY_LIST_PAGE_SUCCESS, THIRDPARTY_LIST_PAGE_ERR } =
  actionsThirdParty;
// Customers
const {
  THIRDPARTIESCUSTOMERS_LIST_PAGE_BEGIN,
  THIRDPARTIESCUSTOMERS_LIST_PAGE_SUCCESS,
  THIRDPARTIESCUSTOMERS_LIST_PAGE_ERR,
} = actionsThirdPartiesCustomers;
const {
  THIRDPARTYCUSTOMER_LIST_PAGE_BEGIN,
  THIRDPARTYCUSTOMER_LIST_PAGE_SUCCESS,
  THIRDPARTYCUSTOMER_LIST_PAGE_ERR,
} = actionsThirdPartyCustomer;
const {
  THIRDPARTYCUSTOMER_CREATE_PAGE_BEGIN,
  THIRDPARTYCUSTOMER_CREATE_PAGE_SUCCESS,
  THIRDPARTYCUSTOMER_CREATE_PAGE_ERR,
} = actionsThirdPartyCustomerCreate;
const {
  THIRDPARTYCUSTOMER_UPDATE_PAGE_BEGIN,
  THIRDPARTYCUSTOMER_UPDATE_PAGE_SUCCESS,
  THIRDPARTYCUSTOMER_UPDATE_PAGE_ERR,
} = actionsThirdPartyCustomerUpdate;
const {
  THIRDPARTYCUSTOMER_DELETE_PAGE_BEGIN,
  THIRDPARTYCUSTOMER_DELETE_PAGE_ERR,
  THIRDPARTYCUSTOMER_DELETE_PAGE_SUCCESS,
} = actionsThirdPartyCustomerDelete;

const initialThirdParties: ThirdPartiesState = new ThirdPartiesState();
// Providers
export const thirdPartiesProvidersReducer = (
  state = initialThirdParties,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseThirdParties[];
  }
): TThirdPartiesProvidersReducer => {
  const { type, data, err } = action;
  switch (type) {
    case THIRDPARTIES_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case THIRDPARTIES_LIST_PAGE_SUCCESS:
      return {
        ...state,
        thirdPartiesProviders: data,
        loading: false,
      };
    case THIRDPARTIES_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const thirdPartyProviderReducer = (
  state = initialThirdParties,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseThirdParties | undefined;
  }
): TThirdPartyProviderReducer => {
  const { type, data, err } = action;
  switch (type) {
    case THIRDPARTY_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case THIRDPARTY_LIST_PAGE_SUCCESS:
      return {
        ...state,
        thirdPartyProvider: data,
        loading: false,
      };
    case THIRDPARTY_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const CreateThirdPartyProviderReducer = (
  state = initialThirdParties,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseThirdParties | undefined;
  }
): TCreateThirdPartyProviderReducer => {
  const { type, data, err } = action;
  switch (type) {
    case THIRDPARTY_CREATE_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case THIRDPARTY_CREATE_PAGE_SUCCESS:
      return {
        ...state,
        thirdPartyProviderCreated: data,
        loading: false,
      };
    case THIRDPARTY_CREATE_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const UpdateThirdPartyProviderReducer = (
  state = initialThirdParties,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseThirdParties | undefined;
  }
): TUpdateThirdPartyProviderReducer => {
  const { type, data, err } = action;
  switch (type) {
    case THIRDPARTY_UPDATE_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case THIRDPARTY_UPDATE_PAGE_SUCCESS:
      return {
        ...state,
        thirdPartyProviderUpdated: data,
        loading: false,
      };
    case THIRDPARTY_UPDATE_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};
// Customers
export const thirdPartiesCustomersReducer = (
  state = initialThirdParties,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseThirdParties[];
  }
): TThirdPartiesCustomersReducer => {
  const { type, data, err } = action;
  switch (type) {
    case THIRDPARTIESCUSTOMERS_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case THIRDPARTIESCUSTOMERS_LIST_PAGE_SUCCESS:
      return {
        ...state,
        thirdPartiesCustomers: data,
        loading: false,
      };
    case THIRDPARTIESCUSTOMERS_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const thirdPartyCustomerReducer = (
  state = initialThirdParties,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseThirdParties | undefined;
  }
): TThirdPartyCustomerReducer => {
  const { type, data, err } = action;
  switch (type) {
    case THIRDPARTYCUSTOMER_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case THIRDPARTYCUSTOMER_LIST_PAGE_SUCCESS:
      return {
        ...state,
        thirdPartyCustomer: data,
        loading: false,
      };
    case THIRDPARTYCUSTOMER_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const CreateThirdPartyCustomerReducer = (
  state = initialThirdParties,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseThirdParties | undefined;
  }
): TCreateThirdPartyCustomerReducer => {
  const { type, data, err } = action;
  switch (type) {
    case THIRDPARTYCUSTOMER_CREATE_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case THIRDPARTYCUSTOMER_CREATE_PAGE_SUCCESS:
      return {
        ...state,
        thirdPartyCustomerCreated: data,
        loading: false,
      };
    case THIRDPARTYCUSTOMER_CREATE_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const UpdateThirdPartyCustomerReducer = (
  state = initialThirdParties,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseThirdParties | undefined;
  }
): TUpdateThirdPartyCustomerReducer => {
  const { type, data, err } = action;
  switch (type) {
    case THIRDPARTYCUSTOMER_UPDATE_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case THIRDPARTYCUSTOMER_UPDATE_PAGE_SUCCESS:
      return {
        ...state,
        thirdPartyCustomerUpdated: data,
        loading: false,
      };
    case THIRDPARTYCUSTOMER_UPDATE_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const DeleteThirdPartyCustomerReducer = (
  state = initialThirdParties,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseThirdParties | undefined;
  }
): TDeleteThirdPartyCustomerReducer => {
  const { type, data, err } = action;
  switch (type) {
    case THIRDPARTYCUSTOMER_DELETE_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case THIRDPARTYCUSTOMER_DELETE_PAGE_SUCCESS:
      return {
        ...state,
        thirdPartyCustomerDeleted: data,
        loading: false,
      };
    case THIRDPARTYCUSTOMER_DELETE_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};
