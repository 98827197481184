import { AxiosError } from 'axios';
import { ResponseMaritalStatus } from '../../../core/domain/documentsPersons/maritalStatus/model/responseMaritalStatus';
import { MaritalStatusState } from '../../../core/domain/documentsPersons/maritalStatus/state/materialStatusState';
import actions from './actions';
import { TMaritalStatusReducer } from './types';

const {
  MARITAL_STATUS_LIST_PAGE_BEGIN,
  MARITAL_STATUS_LIST_PAGE_SUCCESS,
  MARITAL_STATUS_LIST_PAGE_ERR,
} = actions;

const initialMaritalStatus: MaritalStatusState = new MaritalStatusState();

const MaritalStatusReducer = (
  state = initialMaritalStatus,
  action: { type: string; err: AxiosError; data: ResponseMaritalStatus[] }
): TMaritalStatusReducer => {
  const { type, data, err } = action;
  switch (type) {
    case MARITAL_STATUS_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case MARITAL_STATUS_LIST_PAGE_SUCCESS:
      return {
        ...state,
        maritalStatus: data,
        loading: false,
      };
    case MARITAL_STATUS_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default MaritalStatusReducer;
