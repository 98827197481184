import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LegalProviderV } from '../../../components/providers/legalProvider/index';
import {
  clearCreateThirdPartyProviderPage,
  clearUpdateThirdPartyProviderPage,
  createThirdPartyProviderPage,
  getThirdPartyProviderByIdListPage,
  updateThirdPartyProviderPage,
} from '../../../../../redux/thirdParties/actionCreator';
import { TDispatch, TodoSuccessResponse } from '../../../../../common/utils/types.d';
import { TDepartmentsReducer } from '../../../../../redux/addresses/departments/types';
import { TCitiesReducer } from '../../../../../redux/addresses/cities/types';
import { TStreetTypesReducer } from '../../../../../redux/addresses/streetTypes/types';
import { RootState } from '../../../../../redux/store';
import { TCities, TDataDepartments, TStreetTypes } from './types.d';
import {
  TCreateThirdPartyProviderReducer,
  TUpdateThirdPartyProviderReducer,
  TThirdPartyProviderReducer,
} from '../../../../../redux/thirdParties/types.d';
import { departmentsListPage } from '../../../../../redux/addresses/departments/actionCreator';
import { citiesListPage } from '../../../../../redux/addresses/cities/actionCreator';
import { streetTypesListPage } from '../../../../../redux/addresses/streetTypes/actionCreator';
import { genderListPage } from '../../../../../redux/documentsPersons/gender/actionCreator';
import { calculateCheckDigit } from '../../../../../lib';
import { filterKeyObject } from '../../../../../common/utils/functions';

export const LegalProviderC = () => {
  /**
   * States
   */
  const [dataDepartments, setDataDepartments] = useState<TDataDepartments>([]);
  const [dataCities, setDataCities] = useState<TCities>([]);
  const [dataStreetTypes, setDataStreetTypes] = useState<TStreetTypes>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  /**
   * dispatch
   */
  const dispatch = useDispatch();

  /**
   * Dispatch de obtener comercio de terceros por ID
   */
  const getThirdPartyProviderByIdDispatch: TThirdPartyProviderReducer = useSelector(
    (dataState: RootState) => dataState?.thirdPartyProvider
  );

  /**
   * Dispatch de crear comercio de terceros
   */
  const createThirdPartyProviderDispatch: TCreateThirdPartyProviderReducer = useSelector(
    (dataState: RootState) => dataState?.thirdPartyProviderCreated
  );

  /**
   * Dispatch de actualizar comercio de terceros
   */
  const updateThirdPartyProviderDispatch: TUpdateThirdPartyProviderReducer = useSelector(
    (dataState: RootState) => dataState?.thirdPartyProviderUpdated
  );

  /**
   * Dispatch de departamentos
   */
  const departmentsDispatch: TDepartmentsReducer = useSelector(
    (dataState: RootState) => dataState?.departments
  );
  /**
   * Dispatch de ciudades
   */
  const citiesDispatch: TCitiesReducer = useSelector((dataState: RootState) => dataState?.cities);

  /**
   * Dispatch de tipo de calles
   */
  const streetTypesDispatch: TStreetTypesReducer = useSelector(
    (dataState: RootState) => dataState?.streetTypes
  );
  /**
   * Schema
   */
  const schema = yup
    .object({
      identificationNumber: yup
        .number()
        .required('Número de NIT es requerido')
        .typeError('Este campo sólo puede contener números y una longitud menor a 11')
        .test('is-between-8-and-11-digits', 'NIT debe tener entre 8 y 11 dígitos', (value) => {
          if (typeof value !== 'number') {
            return false;
          }
          const stringValue = String(Math.floor(value));
          const digitCount = stringValue.length;
          return digitCount >= 9 && digitCount <= 11;
        }),
      dv: yup.string(),
      businessName: yup.string().required('Campo requerido'),
      firstName: yup
        .string()
        .required('El primer nombre es requerido')
        .matches(/^[A-Za-zñáéíóúÁÉÍÓÚÑ\s]+$/, 'Sólo se permiten letras'),
      middleName: yup.string().matches(/^[A-Za-zñáéíóúÁÉÍÓÚÑ\s]*$/, 'Sólo se permiten letras'),
      firstSurname: yup
        .string()
        .required('El primer apellido es requerido')
        .matches(/^[A-Za-zñáéíóúÁÉÍÓÚÑ\s]+$/, 'Sólo se permiten letras'),
      secondSurname: yup
        .string()
        .required('Campo requerido')
        .matches(/^[A-Za-zñáéíóúÁÉÍÓÚÑ\s]*$/, 'Sólo se permiten letras'),
      address: yup.object({
        idDepartment: yup.number().required('Campo requerido'),
        idCity: yup.number().required('Campo requerido'),
        neighBorhood: yup
          .string()
          .required('Campo requerido')
          .matches(/^[A-Za-zñáéíóúÁÉÍÓÚÑ\s\d]+$/u, 'Sólo se permiten letras y números'),
        idAddressFirstStreetType: yup.number().required('Campo requerido'),
        idAddressSecondStreetType: yup.number().required('Campo requerido'),
        firstStreetNumber: yup.string().required('Campo requerido'),
        secondStreetNumber: yup.string().required('Campo requerido'),
        thirdStreetNumber: yup.string().required('Campo requerido'),
        description: yup.string().optional(),
      }),
      cellPhone: yup
        .number()
        .required()
        .test('is-between-10-and-10-digits', 'El celular debe tener 10 dígitos', (value) => {
          if (typeof value !== 'number') {
            return false;
          }
          const stringValue = String(Math.floor(value));
          const digitCount = stringValue.length;
          return digitCount >= 10 && digitCount <= 10;
        })
        .typeError('Este campo sólo puede contener números'),
      telephone: yup
        .number()
        .typeError('Este campo sólo puede contener números')
        .nullable()
        .transform((_, val) => (val !== '' ? Number(val) : null)),
      email: yup.string().required('Correo requerido').email('El correo electrónico no es válido'),
    })
    .required();
  /**
   * useForm
   */
  const { reset, setValue, getValues, handleSubmit, control, watch } = useForm({
    resolver: yupResolver(schema),
  });

  /**
   * useParams del id de tercero
   */
  const { id } = useParams();
  const idThirdParty: number = Number(id);
  const location = useLocation();

  /*
   * Función para retroceder.
   */
  const goBack = () => {
    setTimeout(() => {
      navigate('/comercios/lista');
    });
  };

  /**
   * Watch del departamento seleccionado
   */
  const watchDepartment = watch('address.idDepartment');

  /**
   * Watch del tipo de dirección seleccionado
   */
  const watchIdStreetTypeOne = watch('address.idAddressFirstStreetType');

  /**
   * Función que se encarga de crear un comercio jurídico
   */
  const handleRegister = handleSubmit(async () => {
    const values = getValues();
    dispatch(
      createThirdPartyProviderPage({
        ...values,
        idDocumentType: 4,
        idThirdPartyType: 1,
        idNature: 2,
        telephone: Number(values?.telephone),
      }) as TDispatch
    );
    setLoading(true);
  });
  /**
   * Función que se encarga de actualizar un comercio jurídico
   */
  const handleUpdate = handleSubmit(async () => {
    const values = getValues();
    dispatch(
      updateThirdPartyProviderPage({
        ...filterKeyObject(values, [
          'identificationNumber',
          'businessName',
          'firstName',
          'middleName',
          'firstSurname',
          'secondSurname',
        ]),
        idDocumentType: 4,
        idThirdPartyType: 1,
        idNature: 2,
        idThirdParty,
        telephone: Number(values?.telephone),
      }) as TDispatch
    );
    setLoading(true);
  });
  /**
   * UseEffect para llamar a Departments
   */
  useEffect(() => {
    setDataDepartments(departmentsDispatch?.departments || []);
  }, [departmentsDispatch?.departments]);

  /**
   * UseEffect para llamar a Cities
   */
  useEffect(() => {
    setDataCities(citiesDispatch?.cities || []);
  }, [citiesDispatch?.cities]);

  /**
   * UseEffect para llamar a StreetTypes
   */
  useEffect(() => {
    setDataStreetTypes(streetTypesDispatch?.streetTypes || []);
  }, [streetTypesDispatch?.streetTypes]);

  /**
   * Función que se encarga de mostrar el DV generado en el input
   */
  const onChangeInput = () => {
    setValue('dv', calculateCheckDigit(getValues().identificationNumber));
  };

  /**
   * UseEffect pendiente al momento de crear un comercio jurídico existente
   */
  useEffect(() => {
    if (loading) {
      if (
        createThirdPartyProviderDispatch?.thirdPartyProviderCreated &&
        !createThirdPartyProviderDispatch?.error
      ) {
        toast.success('Comercio jurídico registrado exitosamente', {
          hideProgressBar: true,
        });
        reset({
          dv: '',
          identificationNumber: undefined,
          businessName: '',
          firstName: '',
          middleName: '',
          firstSurname: '',
          secondSurname: '',
          address: {
            idDepartment: undefined,
            idCity: undefined,
            neighBorhood: '',
            firstStreetNumber: '',
            idAddressFirstStreetType: undefined,
            idAddressSecondStreetType: undefined,
            secondStreetNumber: '',
            thirdStreetNumber: '',
            description: '',
          },
          cellPhone: undefined,
          telephone: undefined,
          email: '',
        });
        dispatch(clearCreateThirdPartyProviderPage() as TDispatch);
        goBack();
      } else if (
        !createThirdPartyProviderDispatch?.thirdPartyProviderCreated &&
        createThirdPartyProviderDispatch?.error
      ) {
        const message = createThirdPartyProviderDispatch?.error?.response
          ? (createThirdPartyProviderDispatch?.error?.response?.data as TodoSuccessResponse)
              ?.message
          : 'Ha ocurrido una incidencia';
        toast.error(`${message}`, {
          hideProgressBar: true,
        });
        dispatch(clearCreateThirdPartyProviderPage() as TDispatch);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createThirdPartyProviderDispatch?.thirdPartyProviderCreated,
    createThirdPartyProviderDispatch?.error,
  ]);

  /**
   * Muestra la información de los documentos según los parámetros de la URL
   */
  useEffect(() => {
    if (idThirdParty) {
      dispatch(getThirdPartyProviderByIdListPage({ idThirdParty }) as TDispatch);
    } else {
      reset({});
    }
  }, [idThirdParty, dispatch, reset]);

  useEffect(() => {
    if (location?.pathname === '/comercios/registro') {
      reset({});
    }
  }, [location, reset]);

  /**
   * UseEffect pendiente al momento de actualizar un comercio jurídico existente
   */
  useEffect(() => {
    if (idThirdParty && loading) {
      if (
        updateThirdPartyProviderDispatch?.thirdPartyProviderUpdated &&
        !updateThirdPartyProviderDispatch?.error
      ) {
        reset({
          dv: '',
          identificationNumber: undefined,
          businessName: '',
          firstName: '',
          middleName: '',
          firstSurname: '',
          secondSurname: '',
          address: {
            idDepartment: undefined,
            idCity: undefined,
            neighBorhood: '',
            firstStreetNumber: '',
            idAddressFirstStreetType: undefined,
            idAddressSecondStreetType: undefined,
            secondStreetNumber: '',
            thirdStreetNumber: '',
            description: '',
          },
          cellPhone: undefined,
          telephone: undefined,
          email: '',
        });
        dispatch(clearUpdateThirdPartyProviderPage() as TDispatch);
        dispatch(getThirdPartyProviderByIdListPage({ idThirdParty }) as TDispatch);
        toast.success('Comercio jurídico actualizado exitosamente', { hideProgressBar: true });
        goBack();
      } else if (
        !updateThirdPartyProviderDispatch?.thirdPartyProviderUpdated &&
        updateThirdPartyProviderDispatch?.error
      ) {
        const message = updateThirdPartyProviderDispatch?.error?.response
          ? (updateThirdPartyProviderDispatch?.error?.response?.data as TodoSuccessResponse)
              ?.message
          : 'Ha ocurrido una incidencia';
        toast.error(`${message}`);
        dispatch(clearUpdateThirdPartyProviderPage() as TDispatch);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    updateThirdPartyProviderDispatch?.thirdPartyProviderUpdated,
    updateThirdPartyProviderDispatch?.error,
  ]);

  /**
   * useEffect que se encarga de mostrar la información correspondiente en los campos mediante el ID
   */
  useEffect(() => {
    if (idThirdParty) {
      if (getThirdPartyProviderByIdDispatch?.thirdPartyProvider?.idThirdParty) {
        const data = getThirdPartyProviderByIdDispatch?.thirdPartyProvider;
        if (data?.idNature === 2) {
          reset({
            dv: calculateCheckDigit(data?.identificationNumber),
            identificationNumber: data?.identificationNumber,
            businessName: data?.businessName,
            firstName: data?.firstName,
            middleName: data?.middleName,
            firstSurname: data?.firstSurname,
            secondSurname: data?.secondSurname,
            address: {
              ...data.address,
              idDepartment: data?.address?.idDepartment,
              idCity: data?.address?.idCity,
              neighBorhood: data?.address?.neighBorhood,
              firstStreetNumber: data?.address?.firstStreetNumber,
              idAddressFirstStreetType: data?.address?.idAddressFirstStreetType,
              idAddressSecondStreetType: data?.address?.idAddressSecondStreetType,
              secondStreetNumber: data?.address?.secondStreetNumber,
              thirdStreetNumber: data?.address?.thirdStreetNumber,
              description: data?.address?.description ? data?.address?.description : '',
            },
            cellPhone: data?.cellPhone,
            telephone: data?.telephone !== 0 ? data?.telephone : null,
            email: data?.email,
          });
        }
      }
    }
  }, [getThirdPartyProviderByIdDispatch, reset, idThirdParty]);

  /**
   * UseEffect para llamar a todos los dispatches
   */
  useEffect(() => {
    dispatch(clearCreateThirdPartyProviderPage() as TDispatch);
    dispatch(clearUpdateThirdPartyProviderPage() as TDispatch);
    dispatch(departmentsListPage() as TDispatch);
    dispatch(citiesListPage() as TDispatch);
    dispatch(streetTypesListPage() as TDispatch);
    dispatch(genderListPage() as TDispatch);
  }, [dispatch]);

  return (
    <LegalProviderV
      schema={schema}
      control={control}
      dataDepartments={dataDepartments}
      dataCities={dataCities}
      dataStreetTypes={dataStreetTypes}
      watchDepartment={watchDepartment}
      watchIdStreetTypeOne={watchIdStreetTypeOne}
      handleRegister={handleRegister}
      handleUpdate={handleUpdate}
      createLoading={createThirdPartyProviderDispatch?.loading}
      updateLoading={updateThirdPartyProviderDispatch?.loading}
      onChangeInput={onChangeInput}
      idThirdParty={idThirdParty}
      idNature={idThirdParty && getThirdPartyProviderByIdDispatch?.thirdPartyProvider?.idNature}
    />
  );
};
