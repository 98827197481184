export class ResponseCities {
  public idCity? = 0;

  public idDepartment? = 0;

  public code? = '';

  public name = '';

  public state? = 0;
}
