import 'reflect-metadata';
import { AxiosResponse } from 'axios';
import { IThirdPartiesInfrastructure } from '../../domain/interface/infrastructure/thirdParties/IThirdPartiesInfrastructure';
import { RequestThirdParties } from '../../domain/thirdParties/thirdParties/model/thirdPartiesRequest';
import { ResponseThirdParties } from '../../domain/thirdParties/thirdParties/model/thirdPartiesResponse';
import { processDataBackend } from '../../../common/utils/functions';

export class ThirdPartiesInfrastructure implements IThirdPartiesInfrastructure {
  private a = '';

  // Providers
  public async getThirdPartyProvidersAPI(
    request: RequestThirdParties
  ): Promise<ResponseThirdParties[]> {
    this.a = 'aaa';
    const res: AxiosResponse = await processDataBackend(
      'GET',
      '/api/thirdParties/getAllProviders',
      {},
      { request }
    );
    const thirdParties: ResponseThirdParties[] = res.data;
    return Promise.resolve(thirdParties);
  }

  public async getThirdPartyByIdProviderAPI(
    request: RequestThirdParties
  ): Promise<ResponseThirdParties> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to getById not found');
    const res: AxiosResponse = await processDataBackend(
      'POST',
      '/api/thirdParties/getProviderById',
      {},
      { request }
    );
    const result: ResponseThirdParties = res.data;

    return Promise.resolve(result);
  }

  public async createThirdPartyProviderAPI(
    request: RequestThirdParties
  ): Promise<ResponseThirdParties> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to create not found');
    const res: AxiosResponse = await processDataBackend(
      'POST',
      '/api/thirdParties/createProvider',
      {},
      { request }
    );
    const result: ResponseThirdParties = res.data;
    return Promise.resolve(result);
  }

  public async updateThirdPartyProviderAPI(
    request: RequestThirdParties
  ): Promise<ResponseThirdParties> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to update not found');
    const res: AxiosResponse = await processDataBackend(
      'PUT',
      '/api/thirdParties/updateProvider',
      {},
      { request }
    );
    const result: ResponseThirdParties = res.data;
    return Promise.resolve(result);
  }

  // Customers
  public async getThirdPartyCustomersAPI(
    request: RequestThirdParties
  ): Promise<ResponseThirdParties[]> {
    this.a = 'aaa';
    const res: AxiosResponse = await processDataBackend(
      'GET',
      '/api/thirdParties/getAllCustomers',
      {},
      { request }
    );
    const thirdParties: ResponseThirdParties[] = res.data;
    return Promise.resolve(thirdParties);
  }

  public async deleteThirdPartyCustomerAPI(
    request: RequestThirdParties
  ): Promise<ResponseThirdParties> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to delete not found');
    const res: AxiosResponse = await processDataBackend(
      'PUT',
      '/api/thirdParties/deleteCustomer',
      {},
      { request }
    );
    const result: ResponseThirdParties = res.data;
    return Promise.resolve(result);
  }

  public async getThirdPartyByIdCustomerAPI(
    request: RequestThirdParties
  ): Promise<ResponseThirdParties> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to getById not found');
    const res: AxiosResponse = await processDataBackend(
      'POST',
      '/api/thirdParties/getCustomerById',
      {},
      { request }
    );
    const result: ResponseThirdParties = res.data;

    return Promise.resolve(result);
  }

  public async createThirdPartyCustomerAPI(
    request: RequestThirdParties
  ): Promise<ResponseThirdParties> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to create not found');
    const res: AxiosResponse = await processDataBackend(
      'POST',
      '/api/thirdParties/createCustomer',
      {},
      { request }
    );
    const result: ResponseThirdParties = res.data;
    return Promise.resolve(result);
  }

  public async updateThirdPartyCustomerAPI(
    request: RequestThirdParties
  ): Promise<ResponseThirdParties> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to update not found');
    const res: AxiosResponse = await processDataBackend(
      'PUT',
      '/api/thirdParties/updateCustomer',
      {},
      { request }
    );
    const result: ResponseThirdParties = res.data;
    return Promise.resolve(result);
  }
}
