import { AxiosError } from 'axios';
import { ResponseTaxRegime } from '../../../core/domain/taxInformation/taxRegime/model/responseTaxRegime';

const actions = {
  TAX_REGIME_LIST_PAGE_BEGIN: 'TAX_REGIME_LIST_PAGE_BEGIN',
  TAX_REGIME_PAGE_SUCCESS: 'TAX_REGIME_LIST_PAGE_SUCCESS',
  TAX_REGIME_LIST_PAGE_ERR: 'TAX_REGIME_LIST_PAGE_ERR',

  taxRegimeListPageBegin: (): { type: string } => {
    return {
      type: actions.TAX_REGIME_LIST_PAGE_BEGIN,
    };
  },

  taxRegimeListPageSuccess: (
    data: ResponseTaxRegime[]
  ): { type: string; data: ResponseTaxRegime[] } => {
    return {
      type: actions.TAX_REGIME_PAGE_SUCCESS,
      data,
    };
  },

  taxRegimeListPageErr: (err: AxiosError): { type: string; err: AxiosError } => {
    return {
      type: actions.TAX_REGIME_LIST_PAGE_ERR,
      err,
    };
  },
};

export default actions;
