export const dataReset = {
  idGender: undefined,
  idDocumentType: undefined,
  identificationNumber: undefined,
  thirdPartyBeneficiary: {
    idThirdParty: undefined,
  },
  firstName: '',
  middleName: '',
  firstSurname: '',
  secondSurname: '',
  address: {
    idCity: undefined,
    description: '',
    neighBorhood: '',
    thirdStreetNumber: '',
    firstStreetNumber: '',
    secondStreetNumber: '',
    idDepartment: undefined,
    idAddressFirstStreetType: undefined,
    idAddressSecondStreetType: undefined,
  },
  email: '',
  cellPhone: undefined,
  telephone: undefined,
};
