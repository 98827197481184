import { Box, Button, CircularProgress, Tooltip, Typography, useTheme } from '@mui/material';
import React from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { TChangePassword } from './types.d';
import { InputField } from '../../../../common/components/formAdapters';

export const ChangePasswordV: React.FC<TChangePassword> = ({
  control,
  schema,
  handleSubmit,
  handleUpdatePassword,
  updateLoading,
  validatePassword,
  validationErrors,
}) => {
  const theme = useTheme();
  const styles = {
    header: {
      fontWeight: 'bold',
      fontSize: '1em',
      textAlign: 'left',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      borderBottom: `1px solid ${theme.palette.primary.dark}`,
      color: `${theme.palette.primary.dark}`,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    content: {
      gap: '20px',
      maxHeight: '90%',
      width: '100%',
      boxSizing: 'border-box',
      padding: '0 20px 20px 20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    button: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      background: theme.palette.primary.main,
      height: '40px',
      width: '120px',
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      boxShadow: '0px 3px 5px 0px #dcdcdc',
    },
    buttonText: {
      fontSize: '0.9em',
      fontWeight: 'bold',
      color: '#fff',
    },
  };
  return (
    <Box sx={styles.content}>
      <Typography
        variant="h5"
        sx={{ fontFamily: 'Poppins-Regular', fontWeight: '600', marginBottom: '20px' }}
        color={theme.palette.primary.main}>
        Cambiar contraseña
      </Typography>
      <InputField
        name="password"
        control={control}
        type="password"
        label="Contraseña actual *"
        schema={schema.fields.password}
        iconType="showPasswordEye"
        autoComplete="new-password"
      />
      <InputField
        control={control}
        label="Nueva contraseña *"
        name="newPassword"
        type="password"
        schema={schema.fields.newPassword}
        // hideHelperText
        iconType="showPasswordEye"
      />
      <InputField
        control={control}
        label="Confirmar nueva contraseña *"
        name="confirmPass"
        type="password"
        schema={schema.fields.confirmPass}
        iconType="showPasswordEye"
      />
      <Box
        sx={{
          width: '100%',
          minHeight: '100px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}>
        {validationErrors?.map((x: string) => {
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: '2px',
              }}
              key={x}>
              <HighlightOffIcon sx={{ color: '#F23520', marginRight: '8px', fontSize: '18px' }} />
              <Typography sx={{ color: '#707070' }} fontSize="12px">
                {x}
              </Typography>
            </Box>
          );
        })}
      </Box>
      <Tooltip title="Actualizar contraseña">
        <Button sx={styles.button} onClick={handleSubmit(validatePassword)}>
          <Typography sx={styles.buttonText}>
            {!updateLoading ? 'Actualizar' : <CircularProgress size={20} sx={{ color: 'white' }} />}
          </Typography>
        </Button>
      </Tooltip>
    </Box>
  );
};
