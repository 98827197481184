import { AxiosError } from 'axios';
import 'reflect-metadata';
import { container } from 'tsyringe';
import { AppDispatch } from '../store';
import { actionsSeller, actionsSellerCreate, actionsSellerDelete, actionsSellers, actionsSellerUpdate } from './actions';
import { ISellerApplication } from '../../core/domain/interface/application/sellers/ISellerApplication';
import { RequestSeller } from '../../core/domain/sellers/sellers/model/requestSeller';

const { sellerCreatePageBegin, sellerCreatePageSuccess, sellerCreatePageErr } =
  actionsSellerCreate;
const { sellerUpdatePageBegin, sellerUpdatePageSuccess, sellerUpdatePageErr } =
  actionsSellerUpdate;
const { sellerDeletePageBegin, sellerDeletePageSuccess, sellerDeletePageErr } =
  actionsSellerDelete;
const { sellerListPageBegin, sellerListPageSuccess, sellerListPageErr } = actionsSeller;
const { sellersListPageBegin, sellersListPageSuccess, sellersListPageErr } = actionsSellers;

export const sellerCreatePage = (listState: any): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(sellerCreatePageBegin());
      const sellerApplication = container.resolve<ISellerApplication>('ISellerApplication');

      const resultCreate = await sellerApplication.createSeller(listState);
      dispatch(sellerCreatePageSuccess(resultCreate));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(sellerCreatePageErr(error));
    }
  };
};

export const sellerUpdatePage = (
  listState: RequestSeller
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(sellerUpdatePageBegin());
      const sellerApplication = container.resolve<ISellerApplication>('ISellerApplication');

      const resultUpdate = await sellerApplication.updateSeller(listState);
      dispatch(sellerUpdatePageSuccess(resultUpdate));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(sellerUpdatePageErr(error));
    }
  };
};

export const sellerDeletePage = (
  listState: RequestSeller
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(sellerDeletePageBegin());
      const sellerApplication = container.resolve<ISellerApplication>('ISellerApplication');

      const resultDelete = await sellerApplication.deleteSeller(listState);
      dispatch(sellerDeletePageSuccess(resultDelete));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(sellerDeletePageErr(error));
    }
  };
};

export const clearSellerCreatePage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(sellerCreatePageBegin());
    dispatch(sellerCreatePageErr(undefined));
    dispatch(sellerCreatePageSuccess(undefined));
  };
};

export const getByIdSellerListPage = (
  listState: RequestSeller
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(sellerListPageBegin());
      const sellerApplication = container.resolve<ISellerApplication>('ISellerApplication');

      const resultGetById = await sellerApplication.getSellerById(listState);
      dispatch(sellerListPageSuccess(resultGetById));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(sellerListPageErr(error));
    }
  };
};

export const sellersListPage = (): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(sellersListPageBegin());
      const sellersApplication = container.resolve<ISellerApplication>('ISellerApplication');

      const request = new RequestSeller();
      const result = await sellersApplication.getSellers(request);

      dispatch(sellersListPageSuccess(result));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(sellersListPageErr(error));
    }
  };
};

export const clearSellerUpdateListPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(sellerUpdatePageBegin());
    dispatch(sellerUpdatePageErr(undefined));
    dispatch(sellerUpdatePageSuccess(undefined));
  };
};

export const clearSellersListPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(sellersListPageBegin());
    dispatch(sellersListPageErr(undefined));
    dispatch(sellersListPageSuccess([]));
  };
};

export const clearSellerDeleteListPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(sellerDeletePageBegin());
    dispatch(sellerDeletePageErr(undefined));
    dispatch(sellerDeletePageSuccess(undefined));
  };
};
