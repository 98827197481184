import { ResponseBeneficiaries } from '../model/responseBeneficiaries';

export class BeneficiariesState {
  public constructor() {
    this.beneficiaries = [new ResponseBeneficiaries()];
    this.beneficiariesCustomers = [new ResponseBeneficiaries()];
    this.beneficiaryList = [new ResponseBeneficiaries()];
    this.beneficiary = new ResponseBeneficiaries();
    this.beneficiaryCreated = new ResponseBeneficiaries();
    this.beneficiaryUpdated = new ResponseBeneficiaries();
    this.beneficiaryDeleted = new ResponseBeneficiaries();
  }

  public beneficiaries?: ResponseBeneficiaries[];

  public beneficiariesCustomers?: ResponseBeneficiaries[];

  public beneficiaryList?: ResponseBeneficiaries[];

  public beneficiary?: ResponseBeneficiaries;

  public beneficiaryCreated?: ResponseBeneficiaries;

  public beneficiaryUpdated?: ResponseBeneficiaries;

  public beneficiaryDeleted?: ResponseBeneficiaries;
}
