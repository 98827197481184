export class ResponseBenefitBeneficiaries {
  public idBenefitBeneficiary? = 0;

  public idBenefit? = 0;

  public idBeneficiary? = 0;

  public usagePerson? = 0;

  public consumed? = 0;

  public startDate? = '';

  public endDate? = '';

  public state? = 0;

  public idNature? = 0;

  public condition? = 0;

  public benefit? = {
    idBenefit: 0,
    idThirdParty: 0,
    idCategory: 0,
    number: 0,
    name: '',
    description: '',
    path: '',
    state: 0,
    createdAt: '',
    updatedAt: '',
    benefitValidity: {
      idBenefitValidity: 0,
      idBenefit: 0,
      usagePerson: 0,
      acquiredBenefit: null,
      consumedBenefit: 0,
      useLimit: 0,
      startDate: '',
      endDate: '',
      state: 0,
      createdAt: '',
      updatedAt: '',
    },
  };

  public beneficiary? = {
    idBeneficiary: 0,
    idDocumentType: 0,
    idGender: 1,
    identificationNumber: 0,
    firstName: '',
    middleName: '',
    firstSurname: '',
    secondSurname: '',
    email: '',
    cellPhone: 0,
    telephone: 0,
    token: null,
    qrCode: '',
    createdAt: '',
    updatedAt: '',
    thirdPartyBeneficiary: {
      idThirdPartyBeneficiary: 0,
      idThirdParty: 0,
      idBeneficiary: 0,
      state: 0,
      createdAt: '',
      updatedAt: '',
      thirdParty: {
        firstName: '',
        middleName: '',
        firstSurname: '',
        secondSurname: '',
        businessName: '',
        idThirdParty: 0,
        idNature: 0,
      },
    },
  };

  public message? = [
    {
      fieldName: '',
      status: '',
    },
    { fieldName: '', status: '' },
  ];
}
