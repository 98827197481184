import { AxiosResponse } from 'axios';
import { processDataBackend } from '../../../../common/utils/functions';
import { RequestBenefit } from '../../../domain/benefits/benefits/model/requestBenefit';
import { ResponseBenefit } from '../../../domain/benefits/benefits/model/responseBenefit';
import { IBenefitInfrastructure } from '../../../domain/interface/infrastructure/benefits/IBenefitInfrastructure';
import { TPagination } from '../../../../common/utils/types.d';

export class BenefitInfrastructure implements IBenefitInfrastructure {
  private a = '';

  public async getByIdBenefitAPI(request: RequestBenefit): Promise<ResponseBenefit> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to getById not found');
    const res: AxiosResponse = await processDataBackend(
      'POST',
      '/api/benefits/getById',
      {},
      { request }
    );
    const result: ResponseBenefit = res.data;
    return Promise.resolve(result);
  }

  public async createBenefitAPI(request: RequestBenefit): Promise<ResponseBenefit> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to create not found');
    const res: AxiosResponse = await processDataBackend(
      'POST',
      '/api/benefits/create',
      {},
      { request }
    );
    const result: ResponseBenefit = res.data;
    return Promise.resolve(result);
  }

  public async updateBenefitAPI(request: RequestBenefit): Promise<ResponseBenefit> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to update not found');
    const res: AxiosResponse = await processDataBackend(
      'PUT',
      '/api/benefits/update',
      {},
      { request }
    );
    const result: ResponseBenefit = res.data;
    return Promise.resolve(result);
  }

  public async deleteBenefitAPI(request: RequestBenefit): Promise<ResponseBenefit> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to delete not found');
    const res: AxiosResponse = await processDataBackend(
      'PUT',
      '/api/benefits/delete',
      {},
      { request }
    );
    const result: ResponseBenefit = res.data;
    return Promise.resolve(result);
  }

  public async getBenefitsAPI(request: RequestBenefit): Promise<ResponseBenefit[]> {
    this.a = 'aaa';
    const res: AxiosResponse = await processDataBackend(
      'GET',
      '/api/benefits/getAll',
      {},
      { request }
    );
    const result: ResponseBenefit[] = res?.data;
    return Promise.resolve(result);
  }

  public async getBenefitsForExcelAPI(request: RequestBenefit): Promise<ResponseBenefit[]> {
    this.a = 'aaa';
    const res: AxiosResponse = await processDataBackend(
      'GET',
      '/api/benefits/getAllForExcel',
      {},
      { request }
    );
    const result: ResponseBenefit[] = res?.data;
    return Promise.resolve(result);
  }

  public async getBenefitsRandomAPI(request: RequestBenefit): Promise<ResponseBenefit[]> {
    this.a = 'aaa';
    const res: AxiosResponse = await processDataBackend(
      'POST',
      '/api/benefits/getAllRandom',
      {},
      { request }
    );
    const result: ResponseBenefit[] = res?.data;
    return Promise.resolve(result);
  }

  public async getBenefitsWithPaginationAPI(payload: TPagination): Promise<ResponseBenefit[]> {
    this.a = 'aaa';
    const res: AxiosResponse = await processDataBackend(
      'GET',
      `/api/benefits/getAllWithPagination?page=${payload?.page}
      &pageSize=${payload?.pageSize}
      ${payload?.idCategory ? `&idCategory=${payload.idCategory} ` : ''}
      ${payload?.search ? `&search=${payload?.search}` : ''}`,
      {},
      { request: payload }
    );
    const result: ResponseBenefit[] = res?.data;
    return Promise.resolve(result);
  }

  public async getBenefitsbyIdThirdPartyAPI(idThirdParty: number): Promise<ResponseBenefit[]> {
    this.a = 'aaa'
    const res: AxiosResponse = await processDataBackend('GET',
      `/api/benefits/getAllByidThirdParty/${idThirdParty}`,
      {},
      {},
    );
    const result: ResponseBenefit[] = res?.data;
    return Promise.resolve(result);
  }

  public async getByMembershipAPI(request: RequestBenefit): Promise<ResponseBenefit[]> {
    this.a = 'aaa';
    const res: AxiosResponse = await processDataBackend(
      'POST',
      '/api/benefits/getAllByMembership',
      {},
      { request }
    );
    const result: ResponseBenefit[] = res?.data;
    return Promise.resolve(result);
  }

  public async getBenefitByBeneficiariesAPI(request: RequestBenefit): Promise<ResponseBenefit[]> {
    this.a = 'aaa';
    const res: AxiosResponse = await processDataBackend(
      'GET',
      `/api/benefits/getAllByBeneficiary?idBeneficiary=${request?.idBeneficiary}`,
      {},
      { request }
    );
    const result: ResponseBenefit[] = res?.data;
    return Promise.resolve(result);
  }
}
