import { RequestThirdParties } from '../../../thirdParties/thirdParties/model/thirdPartiesRequest';
import { RequestBenefitCity } from '../../benefitCity/model/requestBenefitCity';
import { RequestBenefitValidity } from '../../benefitValidities/model/requestBenefitValidity';

export class RequestBenefit {
  public idBenefit? = 0;

  public idThirdParty? = 0;

  public typeBeneficiary? = '';

  public idCategory? = 0 || null;

  public limit? = 0;

  public page? = 0;

  public pageSize? = 0;

  public startDate? = '';

  public visibility? = 0;

  public endDate? = '';

  public search? = '';

  public number? = '';

  public name? = '';

  public description? = '';

  public path? = '';

  public benefitValidity? = new RequestBenefitValidity();

  public benefitCity? = new RequestBenefitCity();

  public thirdParty? = new RequestThirdParties();

  public idBeneficiary? = 0;
}
