import { AxiosError } from 'axios';
import { ResponseModule } from '../../../core/domain/userPermissions/module/model/responseModule';
import { ModuleState } from '../../../core/domain/userPermissions/module/state/moduleState';
import actions from './actions';
import { TModulesReducer } from './types.d';

const { MODULE_LIST_PAGE_BEGIN, MODULE_LIST_PAGE_SUCCESS, MODULE_LIST_PAGE_ERR } = actions;

const initialModule: ModuleState = new ModuleState();

const ModuleReducer = (
  state = initialModule,
  action: { type: string; err: AxiosError; data: ResponseModule[] }
): TModulesReducer => {
  const { type, data, err } = action;
  switch (type) {
    case MODULE_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case MODULE_LIST_PAGE_SUCCESS:
      return {
        ...state,
        module: data,
        loading: false,
      };
    case MODULE_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default ModuleReducer;
