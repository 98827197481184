import React from 'react';
import { UserRegisterC } from '../../../containers/user/userRegister';
import LayoutC from '../../../containers/Layout';

const UserRegistererP = () => {
  return (
    <LayoutC>
      <UserRegisterC />
    </LayoutC>
  );
};

export default UserRegistererP;
