import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Tab, Tabs, useTheme } from '@mui/material';
// import { NaturalProviderC } from '../../containers/providers/naturalProvider';
import { LegalProviderC } from '../../containers/providers/legalProvider';

const ProvidersRegisterV: React.FC = () => {
  const theme = useTheme();
  const [URLSearchParams] = useSearchParams();
  const [edit, setEdit] = useState<boolean>(false);

  const [val, setValueTab] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
  };
  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  useEffect(() => {
    if (URLSearchParams?.get('natureType')) {
      setValueTab(Number(URLSearchParams?.get('natureType')));
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [URLSearchParams]);

  return (
    <div style={{ width: '100%' }}>
      <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Tabs
          value={val}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            borderBottom: 'none',
          }}>
          <Tab
            disabled={edit}
            label="Comercio Jurídico"
            {...a11yProps(1)}
            sx={{
              background:
                val === 0 ? `${theme.palette.primary.main}` : `${theme.palette.primary.light}`,
                color:
                val === 0 ? '': `${theme.palette.primary.main}`,
              fontWeight: 'bold',
              borderTopLeftRadius: '5px',
              borderTopRightRadius: '5px',
              '&.Mui-selected': {
                color: 'white',
              },
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          />
          {/* <Tab
            disabled={edit}
            label="Comercio Natural"
            {...a11yProps(2)}
            sx={{
              background:
                val === 1 ? `${theme.palette.primary.main}` : `${theme.palette.primary.light}`,
              color:
                val === 1 ? '': `${theme.palette.primary.main}`,
              fontWeight: 'bold',
              borderTopLeftRadius: '5px',
              borderTopRightRadius: '5px',
              '&.Mui-selected': {
                color: 'white',
              },
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          /> */}
        </Tabs>
      </Box>

      {/**
       * Vista del Tab Comercio Jurídico
       */}
      {val === 0 && <LegalProviderC />}

      {/**
       * Vista del Tab Comercio Natural
       */}
      {/* {val === 1 && <NaturalProviderC />} */}
    </div>
  );
};

export default ProvidersRegisterV;
