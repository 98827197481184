import 'reflect-metadata';
import { AxiosResponse } from 'axios';
import { processDataBackend } from '../../../common/utils/functions';
import { ResponseConsecutive } from '../../domain/consecutive/model/responseConsecutive';
import { IConsecutiveInfrastructure } from '../../domain/interface/infrastructure/consecutives/IConsecutiveInfrastructure';

export class ConsecutiveInfrastructure implements IConsecutiveInfrastructure {
  private a = '';

  public async getByIdConsecutiveAPI(idCategory: number): Promise<ResponseConsecutive> {
    this.a = 'aaa';
    const res: AxiosResponse = await processDataBackend(
      'GET',
      `/api/consecutive/getOne/${idCategory}`,
      {},
      {}
    );
    const result: ResponseConsecutive = res.data;

    return Promise.resolve(result);
  }
}
