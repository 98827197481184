import 'reflect-metadata';
import { AxiosResponse } from 'axios';
import { processDataBackend } from '../../../common/utils/functions';
import { RequestSeller } from '../../domain/sellers/sellers/model/requestSeller';
import { ResponseSeller } from '../../domain/sellers/sellers/model/responseSeller';
import { ISellerInfrastructure } from '../../domain/interface/infrastructure/sellers/ISellerInfrastructure';

export class SellerInfrastructure implements ISellerInfrastructure {
  private a = '';

  public async getSellersAPI(request: RequestSeller): Promise<ResponseSeller[]> {
    this.a = 'aaa';
    const res: AxiosResponse = await processDataBackend(
      'GET',
      '/api/sellers/getAll',
      {},
      { request }
    );
    const sellers: ResponseSeller[] = res.data;
    return Promise.resolve(sellers);
  }

  public async getSellerByIdAPI(request: RequestSeller): Promise<ResponseSeller> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to getById not found');
    const res: AxiosResponse = await processDataBackend(
      'POST',
      '/api/sellers/getById',
      {},
      { request }
    );
    const result: ResponseSeller = res.data;

    return Promise.resolve(result);
  }

  public async createSellerAPI(request: RequestSeller): Promise<ResponseSeller> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to create not found');
    const res: AxiosResponse = await processDataBackend(
      'POST',
      '/api/sellers/create',
      {},
      { request }
    );
    const result: ResponseSeller = res.data;
    return Promise.resolve(result);
  }

  public async updateSellerAPI(request: RequestSeller): Promise<ResponseSeller> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to update not found');
    const res: AxiosResponse = await processDataBackend(
      'PUT',
      '/api/sellers/update',
      {},
      { request }
    );
    const result: ResponseSeller = res.data;
    return Promise.resolve(result);
  }

  public async deleteSellerAPI(request: RequestSeller): Promise<ResponseSeller> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to delete not found');
    const res: AxiosResponse = await processDataBackend(
      'PUT',
      '/api/sellers/delete',
      {},
      { request }
    );
    const result: ResponseSeller = res.data;
    return Promise.resolve(result);
  }
}
