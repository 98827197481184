import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { IFormValues, TLoginBeneficiaryDispatch, TMessage, TBeneficiaryData } from './types.d';
import { TDispatch } from '../../../../../common/utils/types.d';
import { RootState } from '../../../../../redux/store';
import { BeneficiaryLoginV } from '../../../components/beneficiaries/login';
import {
  LoginBeneficiary,
  clearBeneficiaryLogin,
} from '../../../../../redux/loginBeneficiary/actionCreator';
import { encryptData } from '../../../../../common/utils/functions';

export const BeneficiaryLoginC = () => {
  /**
   * dispatch
   */
  const dispatch = useDispatch();

  /**
   * useNavigate
   */
  const navigate = useNavigate();

  /**
   * state
   */
  const [loadingLogin, setLoadingLogin] = useState(false);

  /**
   * useContext
   */
  /**
   * Schema
   */
  const schema = yup
    .object({
      password: yup.string().required('La contraseña es requerida'),
      email: yup.string().required('El correo es requerido').email('Ingrese un correo válido'),
    })
    .required();

  /**
   * useForm
   */
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  /**
   * Función que te dirige a solicitar cambiar clave
   */
  const redirectPassword = () => {
    navigate(`/restablecer`);
  };

  /**
   * Función que te dirige a solicitar cambiar clave
   */
  const redirectRegister = () => {
    navigate(`/registroBeneficiario`);
  };

  /**
   * Función que te redirige al portal de cupones
   */
  const redirectCouponsPage = () => {
    navigate(`/`);
  };

  /**
   * Función que permite al proveedor Ingresar
   */
  const login = async (data: IFormValues) => {
    setLoadingLogin(true);
    const beneficiaryRegister: TBeneficiaryData = {
      email: data?.email,
      password: data?.password,
    };
    dispatch(LoginBeneficiary(beneficiaryRegister) as TDispatch);
  };

  /**
   * Dispatch de Login
   */
  const loginBeneficiaryDispatch: TLoginBeneficiaryDispatch = useSelector(
    (dataState: RootState) => dataState?.beneficiaryLoggedIn
  );

  // useEffect para validar si el usuario existe e inicie sección
  useEffect(() => {
    if (loadingLogin)
      if (loginBeneficiaryDispatch?.login && !loginBeneficiaryDispatch?.error) {
        toast.success(loginBeneficiaryDispatch?.login?.message, {
          hideProgressBar: true,
        });
        localStorage.setItem('vs2-tk', loginBeneficiaryDispatch?.login?.token);
        // coloca idUser en localStorage
        localStorage.setItem(
          'vs2-iux',
          encryptData(String(loginBeneficiaryDispatch?.login?.idUser), '3573n035uN70K3n')
        );
        localStorage.setItem(
          'vs2-tux',
          encryptData(loginBeneficiaryDispatch?.login?.userType, '3573n035uN70K3n')
        );
        localStorage.setItem(
          'vs2-unx',
          encryptData(loginBeneficiaryDispatch?.login?.userName, '3573n035uN70K3n')
        );
        redirectCouponsPage();
        setLoadingLogin(false);
      } else if (!loginBeneficiaryDispatch?.login && loginBeneficiaryDispatch?.error) {
        const message = loginBeneficiaryDispatch?.error?.response
          ? (loginBeneficiaryDispatch?.error?.response?.data as TMessage)?.message
          : 'Ha ocurrido una incidencia.';
        setLoadingLogin(false);
        toast.error(`${message}`, {
          hideProgressBar: true,
        });
        dispatch(clearBeneficiaryLogin() as TDispatch);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginBeneficiaryDispatch?.login, loginBeneficiaryDispatch?.error]);

  /**
   * UseEffect para llamar a los dispatch
   */
  useEffect(() => {
    dispatch(clearBeneficiaryLogin() as TDispatch);
  }, [dispatch]);

  return (
    <BeneficiaryLoginV
      login={login}
      schema={schema}
      errors={errors}
      control={control}
      register={register}
      loading={loadingLogin}
      handleSubmit={handleSubmit}
      redirectPassword={redirectPassword}
      redirectRegister={redirectRegister}
    />
  );
};
