import React, { useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { Tooltip, IconButton } from '@mui/material';
import { TModal } from './types.d';

export const TransitionsModal: React.FC<TModal> = ({
  state = false,
  title = '',
  children,
  width,
  minWidth,
  minHeight,
  height,
  overflow,
  handleCloseModal,
}) => {
  const [open, setOpen] = useState(state);
  const [disabled, setDisabled] = useState(false);

  // const hide = useCallback(() => {
  //   setOpen(false);
  //   handleCloseModal(false);
  // }, [handleCloseModal]);

  useEffect(() => {
    setOpen(state);
  }, [state]);

  const style = {
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: width || 500,
      minWidth: minWidth || 500,
      minHeight: minHeight || 400,
      height: height || 400,
      bgcolor: 'background.paper',
      borderRadius: '5px',
      border: 'none',
      outline: 'none',
      '@media (max-width:  500px)': {
        height: '100%',
        width: '100%',
        minWidth: '100%',
        maxHeight: '100%',
        minHeight: '100%',
      },
    },
  };

  const handleModalClose = () => {
    if (!disabled) {
      setDisabled(true);
      setOpen(false);
      handleCloseModal(false);
    }
  };

  useEffect(() => {
    if (open) setDisabled(false);
  }, [open]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        // onClose={hide}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={open}>
          <Box sx={style.modal}>
            <IconButton
              sx={{ float: 'right', cursor: 'pointer' }}
              disabled={disabled}
              onClick={handleModalClose}>
              <Tooltip title="Cerrar">
                <CloseIcon />
              </Tooltip>
            </IconButton>
            <Typography sx={{ p: '5px' }} id="transition-modal-title" variant="h6" component="h2">
              {title}
            </Typography>
            <Box
              sx={{
                overflow,
                width: '100%',
                maxHeight: '100%',
              }}>
              {children}
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};
