import { AxiosError } from 'axios';
import { ResponseBenefitCategory } from '../../../core/domain/benefits/benefitCategories/model/responseBenefitCategory';

const actionsBenefitCategories = {
  BENEFIT_CATEGORIES_LIST_PAGE_BEGIN: 'BENEFIT_CATEGORIES_LIST_PAGE_BEGIN',
  BENEFIT_CATEGORIES_LIST_PAGE_SUCCESS: 'BENEFIT_CATEGORIES_LIST_PAGE_SUCCESS',
  BENEFIT_CATEGORIES_LIST_PAGE_ERR: 'BENEFIT_CATEGORIES_LIST_PAGE_ERR',

  benefitCategoryListPageBegin: (): { type: string } => {
    return {
      type: actionsBenefitCategories.BENEFIT_CATEGORIES_LIST_PAGE_BEGIN,
    };
  },

  benefitCategoryListPageSuccess: (
    data: ResponseBenefitCategory[] | undefined
  ): { type: string; data: ResponseBenefitCategory[] | undefined } => {
    return {
      type: actionsBenefitCategories.BENEFIT_CATEGORIES_LIST_PAGE_SUCCESS,
      data,
    };
  },

  benefitCategoryListPageErr: (
    err: AxiosError | undefined
  ): { type: string; err?: AxiosError | undefined } => {
    return {
      type: actionsBenefitCategories.BENEFIT_CATEGORIES_LIST_PAGE_ERR,
      err,
    };
  },
};
export { actionsBenefitCategories };
