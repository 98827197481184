import React from 'react';
import { BeneficiariesReportsC } from '../../../containers/beneficiaries/beneficiariesReport';
import LayoutC from '../../../containers/Layout';

const BeneficiariesReportP = () => {
  return (
    <LayoutC>
      <BeneficiariesReportsC />
    </LayoutC>
  );
};

export default BeneficiariesReportP;
