import { AxiosError } from 'axios';
import { actionsLogin, actionsRefresh, actionsLogout } from './actions';
import { SecurityState } from '../../core/domain/security/state/securityState';
import { ResponseLogin } from '../../core/domain/security/model/response/responseLogin';
import { TLoginReducer } from './types';

const { LOGIN_PAGE_BEGIN, LOGIN_PAGE_SUCCESS, LOGIN_PAGE_SUCCESS_DATA, LOGIN_PAGE_ERR } =
  actionsLogin;
const { LOGOUT_PAGE_BEGIN, LOGOUT_PAGE_SUCCESS, LOGOUT_PAGE_ERR } = actionsLogout;
const { REFRESH_PAGE_BEGIN, REFRESH_PAGE_SUCCESS, REFRESH_PAGE_ERR } = actionsRefresh;

const initialSecurity: SecurityState = new SecurityState();

export const LoginReducer = (
  state = initialSecurity,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseLogin | undefined;
  }
): TLoginReducer => {
  const { type, data, err } = action;
  switch (type) {
    case LOGIN_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_PAGE_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case LOGIN_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const LoginDataReducer = (
  state = initialSecurity,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseLogin | undefined;
  }
): TLoginReducer => {
  const { type, data, err } = action;
  switch (type) {
    case LOGIN_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_PAGE_SUCCESS_DATA:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case LOGIN_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const LogoutReducer = (
  state = initialSecurity,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseLogin | undefined;
  }
): TLoginReducer => {
  const { type, data, err } = action;
  switch (type) {
    case LOGOUT_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_PAGE_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case LOGOUT_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};
export const RefreshReducer = (
  state = initialSecurity,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseLogin | undefined;
  }
): TLoginReducer => {
  const { type, data, err } = action;
  switch (type) {
    case REFRESH_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case REFRESH_PAGE_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case REFRESH_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};
