import 'reflect-metadata';
import { container } from 'tsyringe';
import { IModuleInfrastructure } from '../../../domain/interface/infrastructure/userPermissions/IModuleInfrastructure';
import { IModuleApplication } from '../../../domain/interface/application/userPermissions/IModuleApplication';
import { RequestModule } from '../../../domain/userPermissions/module/model/requestModule';
import { ResponseModule } from '../../../domain/userPermissions/module/model/responseModule';

export class ModuleApplication implements IModuleApplication {
  private moduleInfrastructure: IModuleInfrastructure;

  public constructor() {
    this.moduleInfrastructure = container.resolve<IModuleInfrastructure>('IModuleInfrastructure');
  }

  public async getModule(request: RequestModule): Promise<ResponseModule[]> {
    const result = await this.moduleInfrastructure.getModuleAPI(request);
    return result;
  }
}
