import { container } from 'tsyringe';
import { IMaritalStatusApplication } from '../../../domain/interface/application/documentsPersons/IMaritalStatusApplication';
import { IMaritalStatusInfrastructure } from '../../../domain/interface/infrastructure/documentsPersons/IMaritalInfrastructure';
import { RequestMaritalStatus } from '../../../domain/documentsPersons/maritalStatus/model/requestMaritalStatus';
import { ResponseMaritalStatus } from '../../../domain/documentsPersons/maritalStatus/model/responseMaritalStatus';
import 'reflect-metadata';

export class MaritalStatusApplication implements IMaritalStatusApplication {
  private maritalStatusInfrastructure: IMaritalStatusInfrastructure;

  public constructor() {
    this.maritalStatusInfrastructure = container.resolve<IMaritalStatusInfrastructure>(
      'IMaritalStatusInfrastructure'
    );
  }

  public async getMaritalStatus(request: RequestMaritalStatus): Promise<ResponseMaritalStatus[]> {
    const result = await this.maritalStatusInfrastructure.getMaritalStatusAPI(request);
    return result;
  }
}
