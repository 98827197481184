import React from 'react';
import { Box, Button, CircularProgress, Typography, useTheme } from '@mui/material';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import {
  Span,
  SpanButton,
  SpanValidate,
  ValidateExcel,
  Message,
} from '../../../containers/beneficiaries/bulkLoad/styled';
import { TBulkLoadV } from '../../../containers/beneficiaries/bulkLoad/types.d';
import { ErrorDetailBoxV } from '../../../../../common/components/errorDetailBox';

export const BulkLoadV: React.FC<TBulkLoadV> = ({
  fileName,
  handleBulkSubmit,
  handleChangeFile,
  validateExcel,
  refInputFile,
  validated,
  bulkSuccess,
  bulkResponse,
  loadingBulk,
}) => {
  const theme = useTheme();
  const styles = {
    modalHeader: {
      display: 'flex',
      justifyContent: 'end',
    },
    title: {
      display: 'flex',
      alignItems: 'end',
      width: '100%',
      whiteSpace: 'nowrap',
      fontWeight: 600,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      color: `${theme.palette.primary.main}`,
    },
    clearIcon: {
      cursor: 'pointer',
      color: 'gray',
      ':hover': {
        color: `${theme.palette.secondary.main}`,
      },
    },
    buttonModal: {
      width: '202px',
      height: '35px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: 'none',
      textAlign: 'center',
      borderRadius: '5px',
      cursor: 'pointer',
      background: `${theme.palette.primary.main}`,
      color: 'white',
      textDecoration: 'none',
      margin: '10px 0px 20px 0px',
      padding: '5px',
      transition: '0.1s',
      '&:hover': {
        background: `${theme.palette.secondary.main}`,
      },
      '@media screen and (max-width: 899px)': {
        width: '350px',
      },
    },
    spanButton: {
      width: '100%',
      fontSize: '1.1em',
      fontWeight: 500,
      color: 'white',
      textAlign: 'center',
    },
    donwloadIcon: {
      marginLeft: '10px',
    },
    addDocument: {
      width: '100%',
      height: '35px',
      background: 'white',
      borderRadius: '3px',
      border: `1px solid ${theme.palette.primary.main}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        border: `1px solid ${theme.palette.secondary.main}`,
      },
    },
    plus: {
      color: `${theme.palette.primary.main}`,
      marginBottom: '3px',
      marginRight: '4px',
    },
    spanDocument: {
      fontSize: '1.1em',
      fontWeight: 500,
      color: '#7a7a7a',
    },
    validateExcel: {
      width: '100%',
      height: '4vh',
      marginTop: '10px',
      background: ' rgb(13, 210, 177)',
      borderRadius: '3px',
      border: '1px solid #f0f2f6',
      display: 'flex',
      gap: '3px',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonBox: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
      '@media screen and (max-width: 899px)': {
        display: 'flex',
        justifyContent: 'center',
      },
      mt: '15px',
    },
    buttonSubmit: {
      display: 'flex',
      alignContent: 'center',
      width: '135px',
      height: '35px',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
      transition: '0.1s',
      '@media screen and (max-width: 899px)': {
        width: '350px',
      },
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);',
    },
  };
  return (
    <>
      <Box sx={styles.modalHeader}>
        <Typography sx={styles.title}>CARGA MASIVA DE BENEFICIARIOS</Typography>
      </Box>
      <Button
        variant="contained"
        sx={styles.buttonModal}
        href="/excel/excelGuiaBeneficiarios.xlsx"
        download="Excel guia beneficiario.xlsx">
        <Button
          sx={styles.spanButton}
          startIcon={<DownloadIcon fontSize="small" sx={styles.donwloadIcon} />}>
          Excel de guía
        </Button>
      </Button>
      <Button type="button" sx={styles.addDocument} onClick={() => refInputFile.current?.click()}>
        <NoteAddIcon fontSize="small" sx={styles.plus} />
        <Typography sx={styles.spanDocument}>
          {fileName
            ? String(fileName).charAt(0).toUpperCase() +
              String(fileName).slice(1).toLocaleLowerCase()
            : 'Seleccione un documento'}
        </Typography>
      </Button>
      <ValidateExcel validated={validated} type="button" onClick={validateExcel}>
        <SpanValidate>
          {validated ? 'Archivo excel validado' : 'Validar archivo excel'}
        </SpanValidate>
        {validated && <TaskAltIcon fontSize="small" />}
      </ValidateExcel>
      <input
        style={{ display: 'none' }}
        type="file"
        name="file"
        id="file"
        ref={refInputFile}
        accept=".xlsx, .xls, .csv"
        onChange={handleChangeFile}
      />
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', mt: '5px' }}>
        <Span>Solo se permiten subir como máximo 200 beneficiarios</Span>
      </Box>
      <ErrorDetailBoxV bulkResponse={bulkResponse}>
        <Message>{bulkSuccess ? '¡Membresias registrados exitosamente!' : ''}</Message>
      </ErrorDetailBoxV>
      <Box sx={styles.buttonBox}>
        <Button
          sx={styles.buttonSubmit}
          type="button"
          variant="contained"
          onClick={handleBulkSubmit}
          disabled={loadingBulk}
          startIcon={
            loadingBulk ? (
              <CircularProgress size={14} color="inherit" />
            ) : (
              <UploadIcon fontSize="small" />
            )
          }>
          <SpanButton>Subir</SpanButton>
        </Button>
      </Box>
    </>
  );
};
