import 'reflect-metadata';
import { container } from 'tsyringe';
import { RequestBenefitCategory } from '../../../domain/benefits/benefitCategories/model/requestBenefitCategory';
import { ResponseBenefitCategory } from '../../../domain/benefits/benefitCategories/model/responseBenefitCategory';
import { IBenefitCategoryApplication } from '../../../domain/interface/application/benefits/IBenefitCategoryApplication';
import { IBenefitCategoryInfrastructure } from '../../../domain/interface/infrastructure/benefits/IBenefitCategoryInfrastructure';

export class BenefitCategoryApplication implements IBenefitCategoryApplication {
  private BenefitCategoryInfrastructure: IBenefitCategoryInfrastructure;

  public constructor() {
    this.BenefitCategoryInfrastructure = container.resolve<IBenefitCategoryInfrastructure>(
      'IBenefitCategoryInfrastructure'
    );
  }

  public async getBenefitCategory(
    request: RequestBenefitCategory
  ): Promise<ResponseBenefitCategory[]> {
    const result = await this.BenefitCategoryInfrastructure.getBenefitCategoryAPI(request);
    return result;
  }
}
