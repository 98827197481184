import BorderColorIcon from '@mui/icons-material/BorderColor';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { DataGrid, GridColDef, GridColumnHeaderParams, esES } from '@mui/x-data-grid';
import React from 'react';
import { TransitionsModalAlert } from '../../../../../common/components/modalAlert';
import { ToolbarList } from '../../../../../common/components/toolbar';
import { IBranches } from './types.d';
import { TitleComponent } from '../../../../../common/components/titles/styled';
import { TransitionsModal } from '../../../../../common/components/modal';
import { CreateBranch } from './createBranch';

export const BranchesV: React.FC<IBranches> = ({
  schema,
  control,
  handleAddInput,
  dataForm,
  handleRemoveInput,
  data,
  handleRegister,
  dataCities,
  dataDepartments,
  dataProviders,
  createLoading,
  updateLoading,
  deleteLoading,
  loading,
  idThirdPartyLogin,
  idBranchOffice,
  handleUpdate,
  buttonDelete,
  handleClose,
  handleDelete,
  openDelete,
  buttonUpdate,
  closeModal,
  active,
  state,
}) => {
  const theme = useTheme();
  const styles = {
    header: {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      fontSize: '25px',
      width: '100%',
      textAlign: 'left',
      fontFamily: 'Poppins-Regular',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    headerName: {
      fontWeight: 'bold',
      fontSize: '14px',
      color: theme.palette.primary.main,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    formBox: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      background: '#fff',
      borderRadius: '16px',
      padding: '20px',
      boxShadow: '0px 3px 6px #00000029',
      minHeight: '400px',
    },
    buttonSubmit: {
      height: '35px',
      width: '100%',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      justifyContent: 'center',
      justifyItems: 'center',
      display: 'flex',
      alignItems: 'center',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);',
    },
  };
  const columns: GridColDef[] = [
    {
      field: 'ID',
      headerName: 'ITEM',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>ITEM</Typography>
      ),
    },
    {
      field: 'commerceName',
      headerName: 'MEMBRESÍA',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>MEMBRESÍA</Typography>
      ),
    },
    {
      field: 'department',
      headerName: 'DEPARTAMENTO',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>DEPARTAMENTO</Typography>
      ),
    },
    {
      field: 'city',
      headerName: 'CIUDAD',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>CIUDAD</Typography>
      ),
    },
    {
      field: 'description',
      headerName: 'DIRECCIÓN',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>DIRECCIÓN</Typography>
      ),
    },
    {
      field: 'actions',
      headerName: 'ACCIONES',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>ACCIONES</Typography>
      ),
      renderCell({ row }) {
        return (
          <>
            <Tooltip title="Editar sucursal">
              <IconButton onClick={() => buttonUpdate(row?.idBranchOffice)}>
                <BorderColorIcon fontSize="small" color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Eliminar registro">
              <IconButton onClick={() => buttonDelete(row?.idBranchOffice)}>
                <HighlightOffIcon fontSize="small" color="secondary" />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <Box
      style={{
        width: '100%',
        display: 'flex',
      }}>
      <TransitionsModal
        state={state}
        handleCloseModal={closeModal}
        width="60%"
        height="40%"
        minHeight="500px"
        minWidth="500px">
        <CreateBranch
          dataForm={dataForm}
          schema={schema}
          control={control}
          idThirdPartyLogin={idThirdPartyLogin}
          dataDepartments={dataDepartments}
          idBranchOffice={idBranchOffice}
          handleAddInput={handleAddInput}
          dataCities={dataCities}
          handleRemoveInput={handleRemoveInput}
          createLoading={createLoading}
          updateLoading={updateLoading}
          handleUpdate={handleUpdate}
          handleRegister={handleRegister}
          dataProviders={dataProviders}
          styles={styles}
          theme={theme}
        />
      </TransitionsModal>
      <Box sx={{ ...styles.formBox }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '16px',
            minHeight: '85%',
            boxSizing: 'border-box',
            padding: '25px',
          }}>
          <div style={{ width: '100%', height: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <TitleComponent>Lista de sucursal</TitleComponent>
            </Box>
            <div style={{ height: '95%' }}>
              <DataGrid
                rows={data || []}
                getRowId={(row) => row?.ID}
                columns={columns}
                rowsPerPageOptions={[25, 50, 100]}
                density="compact"
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                components={{ Toolbar: ToolbarList }}
                loading={!data?.length && loading}
              />
            </div>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                // height: '15%',
                justifyItems: 'center',
                paddingTop: '25px',
              }}>
              <Button
                sx={{
                  ...styles.buttonSubmit,
                  width: '150px',
                  color: 'white',
                }}
                disabled={createLoading || updateLoading}
                type="button"
                onClick={active}>
                {createLoading || updateLoading ? (
                  <CircularProgress size={15} sx={{ color: 'white' }} />
                ) : (
                  <Typography fontSize="14px" sx={{ display: 'flex', alignItems: 'center' }}>
                    Crear sucursal
                  </Typography>
                )}
              </Button>
            </Box>
          </div>
        </Box>
        <Box>
          <TransitionsModalAlert
            state={openDelete}
            handleCloseModal={handleClose}
            width="25%"
            height="25%"
            title="Confirmar acción"
            subTitle="Antes de continuar!"
            paragraph="¿Estás seguro de realizar esta acción?"
            nameAccept={!deleteLoading ? 'SI' : <CircularProgress size={15} />}
            nameDecline="NO"
            onClickAccept={handleDelete}
          />
        </Box>
        {/* </Box> */}
      </Box>
    </Box>
  );
};
