import { AxiosError } from 'axios';
import { ResponseSeller } from '../../core/domain/sellers/sellers/model/responseSeller';

const actionsSellerCreate = {
  SELLER_CREATE_PAGE_BEGIN: 'SELLER_CREATE_PAGE_BEGIN',
  SELLER_CREATE_PAGE_SUCCESS: 'SELLER_CREATE_PAGE_SUCCESS',
  SELLER_CREATE_PAGE_ERR: 'SELLER_CREATE_PAGE_ERR',

  sellerCreatePageBegin: (): { type: string } => {
    return {
      type: actionsSellerCreate.SELLER_CREATE_PAGE_BEGIN,
    };
  },

  sellerCreatePageSuccess: (
    data: ResponseSeller | undefined
  ): { type: string; data: ResponseSeller | undefined } => {
    return {
      type: actionsSellerCreate.SELLER_CREATE_PAGE_SUCCESS,
      data,
    };
  },

  sellerCreatePageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsSellerCreate.SELLER_CREATE_PAGE_ERR,
      err,
    };
  },
};

const actionsSellerUpdate = {
  SELLER_UPDATE_PAGE_BEGIN: 'SELLER_UPDATE_PAGE_BEGIN',
  SELLER_UPDATE_PAGE_SUCCESS: 'SELLER_UPDATE_PAGE_SUCCESS',
  SELLER_UPDATE_PAGE_ERR: 'SELLER_UPDATE_PAGE_ERR',

  sellerUpdatePageBegin: (): { type: string } => {
    return {
      type: actionsSellerUpdate.SELLER_UPDATE_PAGE_BEGIN,
    };
  },

  sellerUpdatePageSuccess: (
    data: ResponseSeller | undefined
  ): { type: string; data: ResponseSeller | undefined } => {
    return {
      type: actionsSellerUpdate.SELLER_UPDATE_PAGE_SUCCESS,
      data,
    };
  },

  sellerUpdatePageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsSellerUpdate.SELLER_UPDATE_PAGE_ERR,
      err,
    };
  },
};

const actionsSellerDelete = {
  SELLER_DELETE_PAGE_BEGIN: 'SELLER_DELETE_PAGE_BEGIN',
  SELLER_DELETE_PAGE_SUCCESS: 'SELLER_DELETE_PAGE_SUCCESS',
  SELLER_DELETE_PAGE_ERR: 'SELLER_DELETE_PAGE_ERR',

  sellerDeletePageBegin: (): { type: string } => {
    return {
      type: actionsSellerDelete.SELLER_DELETE_PAGE_BEGIN,
    };
  },

  sellerDeletePageSuccess: (
    data: ResponseSeller | undefined
  ): { type: string; data: ResponseSeller | undefined } => {
    return {
      type: actionsSellerDelete.SELLER_DELETE_PAGE_SUCCESS,
      data,
    };
  },

  sellerDeletePageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsSellerDelete.SELLER_DELETE_PAGE_ERR,
      err,
    };
  },
};

const actionsSellers = {
  SELLERS_LIST_PAGE_BEGIN: 'SELLERS_LIST_PAGE_BEGIN',
  SELLERS_LIST_PAGE_SUCCESS: 'SELLERS_LIST_PAGE_SUCCESS',
  SELLERS_LIST_PAGE_ERR: 'SELLERS_LIST_PAGE_ERR',

  sellersListPageBegin: (): { type: string } => {
    return {
      type: actionsSellers.SELLERS_LIST_PAGE_BEGIN,
    };
  },

  sellersListPageSuccess: (data: ResponseSeller[]): { type: string; data: ResponseSeller[] } => {
    return {
      type: actionsSellers.SELLERS_LIST_PAGE_SUCCESS,
      data,
    };
  },

  sellersListPageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsSellers.SELLERS_LIST_PAGE_ERR,
      err,
    };
  },
};

const actionsSeller = {
  SELLER_LIST_PAGE_BEGIN: 'SELLER_LIST_PAGE_BEGIN',
  SELLER_LIST_PAGE_SUCCESS: 'SELLER_LIST_PAGE_SUCCESS',
  SELLER_LIST_PAGE_ERR: 'SELLER_LIST_PAGE_ERR',

  sellerListPageBegin: (): { type: string } => {
    return {
      type: actionsSeller.SELLER_LIST_PAGE_BEGIN,
    };
  },

  sellerListPageSuccess: (
    data: ResponseSeller
  ): { type: string; data: ResponseSeller | undefined } => {
    return {
      type: actionsSeller.SELLER_LIST_PAGE_SUCCESS,
      data,
    };
  },

  sellerListPageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsSeller.SELLER_LIST_PAGE_ERR,
      err,
    };
  },
};

export {
  actionsSellerCreate,
  actionsSellerUpdate,
  actionsSellerDelete,
  actionsSeller,
  actionsSellers,
};
