import { RequestAddress } from '../../../addresses/address/model/requestAddress';
import { RequestThirdPartySeller } from '../../thirdPartiesSellers/model/requestThirdPartySeller';

export class RequestSeller {
  public idSeller? = 0;

  public idDocumentType? = 0;

  public idGender? = 0;

  public identificationNumber? = 0;

  public firstName? = '';

  public middleName? = '';

  public firstSurname? = '';

  public secondSurname? = '';

  public email? = '';

  public cellPhone? = '';

  public telephone? = '';

  public idAddress? = 0;

  public address? = new RequestAddress();

  public thirdPartySeller? = new RequestThirdPartySeller();
}
