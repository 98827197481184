import { AxiosResponse } from 'axios';
import 'reflect-metadata';
import { ISubModuleInfrastructure } from '../../../domain/interface/infrastructure/userPermissions/ISubModuleInfrastructure';
import { RequestSubModule } from '../../../domain/userPermissions/subModule/model/requestSubModule';
import { ResponseSubModule } from '../../../domain/userPermissions/subModule/model/responseSubModule';
import { processDataBackend } from '../../../../common/utils/functions';

export class SubModuleInfrastructure implements ISubModuleInfrastructure {
  private a = '';

  public async getSubModuleAPI(request: RequestSubModule): Promise<ResponseSubModule[]> {
    this.a = 'aaa';

    const res: AxiosResponse = await processDataBackend(
      'GET',
      '/api/subModules/getAll',
      {},
      { request }
    );

    const subModule: ResponseSubModule[] = res.data;

    return Promise.resolve(subModule);
  }
}
