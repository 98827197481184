export class ResponseUser {
  public idUser = 0;

  public idDocumentType = 0;

  public userType = '';

  public identificationNumber = 0;

  public idThirdParty = 0;

  public name = '';

  public lastName = '';

  public email = '';

  public cellPhone? = '';

  public password = '';

  public newPassword = '';

  public token = '';

  public phone = '';

  public idRole = 0;

  public idConvention = 0;

  public documentNumber = 0;

  public idUserInfinito = 0;

  public state = 0;

  public roles = {
    description: '',
    idRole: 0,
    name: '',
    state: 0,
    userPermissions: [],
  };

  public userInfinito = {
    idRole: 0,
    name: '',
    password: '',
    state: 0,
  };
}
