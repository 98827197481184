import React from 'react';
import { BeneficiaryRegisterC } from '../../../containers/beneficiaries/beneficiaryRegister';
import LayoutC from '../../../containers/Layout';

const BeneficiaryRegisterP = () => {
  return (
    <LayoutC>
      <BeneficiaryRegisterC />
    </LayoutC>
  );
};

export default BeneficiaryRegisterP;
