import { ResponseCities } from '../../addresses/cities/model/responseCities';
import { ResponseDepartments } from '../../addresses/departments/model/responseDepartments';
import { ResponseThirdParties } from '../../thirdParties/thirdParties/model/thirdPartiesResponse';

export class ResponseBranchsOffices {
  public idBranchOffice?: 0;

  public idThirdParty?: 0;

  public idDepartment?: 0;

  public idCity?: 0;

  public description?: '';

  public state = 0;

  public thirdParty? = new ResponseThirdParties();

  public department? = new ResponseDepartments();

  public city? = new ResponseCities();
}
