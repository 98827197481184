import React from 'react';
import SaveIcon from '@mui/icons-material/Save';
import './styles.css';
import { Box, Button, Grid, Tooltip, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { Banner, Container, ContentLogo, Header, ImgBanner, ImgLogo } from './style';
import banner from '../../../../assets/img/Banner.jpg';
import { DropdownHeaderCouponsV } from './DropdownHeaderCoupons';
import { InputField, NormalSelectField } from '../../../../common/components/formAdapters';
import { TPersonalInfo } from './types.d';
import { onlyLetters, onlyNumbers } from '../../../../common/utils/functions';
import image from '../../../../common/components/images';

export const PersonalInfoV: React.FC<TPersonalInfo> = ({
  control,
  schema,
  open,
  handleMouseEnter,
  handleMouseLeave,
  stringAvatar,
  anchorEl,
  handleSessionCouponsPage,
  goToPersonalInfo,
  goToLoginBeneficiary,
  userCredentials,
  handleUpdate,
  idBeneficiary,
  dataDepartments,
  dataCities,
  watchDepartment,
  watchIdStreetTypeOne,
  dataStreetTypes,
  dataDocumentType,
  dataGender,
  handleBenefitCategory,
  benefitsDataMapped,
  schemaUpdatePassword,
  controlUpdatePassword,
  handleSubmitUpdatePassword,
  updateLoading,
  validatePassword,
  handleUpdatePassword,
  validationErrors,
  sendChangePassword,
  openModalPassword,
}) => {
  const theme = useTheme();
  const LogoBonusColor = image('logoBonusColor');

  const styles = {
    content: {
      width: '100%',
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflow: 'auto',
      marginTop: '30px',
      '@media screen and (max-width: 700px)': {
        marginTop: '10px',
      },
    },
    contentGrid: {
      width: '80%',
      display: 'flex',
      flexDirection: 'column',
    },
    buttonSubmit: {
      display: 'flex',
      alignContent: 'center',
      width: '190px',
      height: '35px',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      '@media screen and (max-width: 899px)': {
        width: '200px',
      },
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);',
    },
    buttonBox: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: '15px',
    },
  };

  return (
    <Container>
      <Header backgroundColor={theme.palette.common.white}>
        <ContentLogo>
          <Link to="/">
            <ImgLogo src={LogoBonusColor} />
          </Link>
        </ContentLogo>
        <DropdownHeaderCouponsV
          open={open}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          stringAvatar={stringAvatar}
          anchorEl={anchorEl}
          handleSessionCouponsPage={handleSessionCouponsPage}
          goToPersonalInfo={goToPersonalInfo}
          userCredentials={userCredentials}
          goToLoginBeneficiary={goToLoginBeneficiary}
          handleBenefitCategory={handleBenefitCategory}
          // cambio de contraseña
          sendChangePassword={sendChangePassword}
          control={control}
          handleSubmitUpdatePassword={handleSubmitUpdatePassword}
          schemaUpdatePassword={schemaUpdatePassword}
          controlUpdatePassword={controlUpdatePassword}
          handleUpdatePassword={handleUpdatePassword}
          updateLoading={updateLoading}
          validatePassword={validatePassword}
          validationErrors={validationErrors}
          openModalPassword={openModalPassword}
        />
      </Header>
      <Box className="hide-scrollbar" sx={styles.content}>
        <Banner>
          <ImgBanner src={banner} />
        </Banner>
        <Box sx={styles.contentGrid}>
          <Typography
            variant="h5"
            color={theme.palette.primary.main}
            sx={{ pb: '15px', pt: '15px' }}>
            Información básica
          </Typography>
          <Box>
            <Grid
              container
              spacing={{ xs: 2, sm: 2, md: 2 }}
              columns={{ xs: 12, sm: 8, md: 9, lg: 12 }}>
              <Grid item xs={12} sm={4} md={3} lg={3}>
                <NormalSelectField
                  name="idDocumentType"
                  label="Tipo de identificación"
                  key="idDocumentType"
                  schema={schema?.fields?.idDocumentType}
                  disabled={!!idBeneficiary}
                  control={control}
                  options={
                    (dataDocumentType || [])?.map((x) => ({
                      value: x?.idDocumentType,
                      label: x?.name,
                    })) || []
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3}>
                <InputField
                  onKeyDown={onlyNumbers}
                  label="Número de identificación"
                  name="identificationNumber"
                  schema={schema.fields?.identificationNumber}
                  control={control}
                  disabled={!!idBeneficiary}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3}>
                <InputField
                  label="Correo electrónico"
                  name="email"
                  schema={schema.fields?.email}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3}>
                <InputField
                  label="Primer nombre"
                  name="firstName"
                  schema={schema.fields?.firstName}
                  control={control}
                  onKeyDown={onlyLetters}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3}>
                <InputField
                  label="Segundo nombre"
                  name="middleName"
                  schema={schema.fields?.middleName}
                  control={control}
                  onKeyDown={onlyLetters}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3}>
                <InputField
                  label="Primer apellido"
                  name="firstSurname"
                  schema={schema.fields?.firstSurname}
                  control={control}
                  onKeyDown={onlyLetters}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3}>
                <InputField
                  label="Segundo apellido"
                  name="secondSurname"
                  schema={schema.fields?.secondSurname}
                  control={control}
                  onKeyDown={onlyLetters}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3}>
                <NormalSelectField
                  name="idGender"
                  label="Género"
                  key="idGender"
                  schema={schema?.fields?.idGender}
                  control={control}
                  options={
                    (dataGender || [])?.map((x) => ({
                      value: x?.idGender,
                      label: x?.name,
                    })) || []
                  }
                />
              </Grid>
            </Grid>
          </Box>

          <Typography
            variant="h5"
            color={theme.palette.primary.main}
            sx={{ pb: '15px', pt: '15px' }}>
            Información de residencia y contacto
          </Typography>
          <Box sx={{ flexGrow: 1, marginBottom: '8px' }}>
            <Grid
              container
              spacing={{ xs: 2, sm: 2, md: 2 }}
              columns={{ xs: 12, sm: 8, md: 9, lg: 12 }}>
              <Grid item xs={12} sm={4} md={3} lg={3}>
                <NormalSelectField
                  name="address.idDepartment"
                  label="Departamento"
                  key="address.idDepartment"
                  schema={schema?.fields?.address.idDepartment}
                  control={control}
                  options={
                    (dataDepartments || [])?.map((x) => ({
                      value: x?.idDepartment,
                      label: x?.name?.toUpperCase(),
                    })) || []
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3}>
                <NormalSelectField
                  name="address.idCity"
                  label="Ciudad"
                  key="address.idCity"
                  schema={schema?.fields?.address?.idCity}
                  control={control}
                  options={
                    (dataCities || [])
                      ?.filter((x) => x?.idDepartment === Number(watchDepartment))
                      .map((x) => {
                        return {
                          value: x?.idCity,
                          label: x?.name?.toUpperCase(),
                        };
                      }) || []
                  }
                  disabled={!watchDepartment}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3}>
                <InputField
                  label="Barrio"
                  name="address.neighBorhood"
                  schema={schema.fields?.address?.neighBorhood}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3}>
                <NormalSelectField
                  name="address.idAddressFirstStreetType"
                  label="Tipo de dirección 1"
                  schema={schema?.fields?.address?.idAddressFirstStreetType}
                  control={control}
                  options={
                    (dataStreetTypes || [])?.map((x) => ({
                      value: x?.idStreetType,
                      label: x?.name,
                    })) || []
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3}>
                <InputField
                  label="Número"
                  name="address.firstStreetNumber"
                  schema={schema.fields?.address.firstStreetNumber}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3}>
                <NormalSelectField
                  name="address.idAddressSecondStreetType"
                  label="Tipo de dirección 2"
                  schema={schema?.fields?.address?.idAddressSecondStreetType}
                  control={control}
                  options={
                    (dataStreetTypes || [])
                      ?.filter((x) => x?.idStreetType !== Number(watchIdStreetTypeOne))
                      .map((x) => ({
                        value: x?.idStreetType,
                        label: x?.name,
                      })) || []
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3}>
                <InputField
                  label="Número 2"
                  name="address.secondStreetNumber"
                  schema={schema.fields?.address?.secondStreetNumber}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3}>
                <InputField
                  label="Número 3"
                  name="address.thirdStreetNumber"
                  schema={schema.fields?.address?.thirdStreetNumber}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3}>
                <InputField
                  label="Detalle"
                  name="address.description"
                  schema={schema.fields?.address?.description}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3}>
                <InputField
                  label="Celular"
                  name="cellPhone"
                  schema={schema.fields?.cellPhone}
                  control={control}
                  onKeyDown={onlyNumbers}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3}>
                <InputField
                  label="Teléfono"
                  name="telephone"
                  schema={schema.fields?.telephone}
                  control={control}
                  onKeyDown={onlyNumbers}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3}>
                <InputField
                  label="Correo electrónico"
                  name="email"
                  schema={schema.fields?.email}
                  control={control}
                />
              </Grid>
            </Grid>
          </Box>

          <Box sx={styles.buttonBox}>
            <Tooltip title="Actualizar beneficiario">
              <Button
                sx={{ ...styles.buttonSubmit, color: '#fff' }}
                type="button"
                onClick={handleUpdate}
                startIcon={<SaveIcon fontSize="small" />}>
                <Typography fontSize="14px" sx={{ display: 'flex', alignItems: 'center' }}>
                  Guardar cambios
                </Typography>
              </Button>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
