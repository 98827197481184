import { AxiosError } from 'axios';
import { ResponseEconomicSectors } from '../../../core/domain/taxInformation/economicSectors/model/responseEconomicSectors';

const actions = {
  ECONOMIC_SECTORS_LIST_PAGE_BEGIN: 'ECONOMIC_SECTORS_LIST_PAGE_BEGIN',
  ECONOMIC_SECTORS_PAGE_SUCCESS: 'ECONOMIC_SECTORS_LIST_PAGE_SUCCESS',
  ECONOMIC_SECTORS_LIST_PAGE_ERR: 'ECONOMIC_SECTORS_LIST_PAGE_ERR',

  economicSectorsListPageBegin: (): { type: string } => {
    return {
      type: actions.ECONOMIC_SECTORS_LIST_PAGE_BEGIN,
    };
  },

  economicSectorsListPageSuccess: (
    data: ResponseEconomicSectors[]
  ): { type: string; data: ResponseEconomicSectors[] } => {
    return {
      type: actions.ECONOMIC_SECTORS_PAGE_SUCCESS,
      data,
    };
  },

  economicSectorsListPageErr: (err: AxiosError): { type: string; err: AxiosError } => {
    return {
      type: actions.ECONOMIC_SECTORS_LIST_PAGE_ERR,
      err,
    };
  },
};

export default actions;
