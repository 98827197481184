import { ResponseAddress } from '../../addresses/address/model/responseAddress';
import { RequestBenefitBeneficiaries } from '../../benefitBeneficiaries/model/requestBenefitBeneficiaries';
import { ResponseMassiveLoadError } from '../../common/massiveLoadError/model/responseMassiveLoadError';
import { ResponseThirdPartiesBeneficiaries } from '../../thirdParties/thirdPartiesBeneficiaries/model/thirdPartiesBeneficiariesResponse';

export class ResponseBeneficiaries {
  public idBeneficiary? = 0;

  public idDocumentType? = 0;

  public idGender? = 0;

  public identificationNumber? = 0;

  public firstName? = '';

  public middleName? = '';

  public firstSurname? = '';

  public secondSurname? = '';

  public email? = '';

  public cellPhone? = 0;

  public telephone? = 0;

  public token? = '';

  public qrCode? = '';

  public state? = 0;

  public thirdPartyBeneficiary? = new ResponseThirdPartiesBeneficiaries();

  public address? = new ResponseAddress();

  public errors?: ResponseMassiveLoadError[];

  public benefitBeneficiaries?: RequestBenefitBeneficiaries[];

  public error?: boolean;

  public rowIndex? = 0;
}
