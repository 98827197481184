import { AxiosError } from 'axios';
import { ResponseThirdParties } from '../../core/domain/thirdParties/thirdParties/model/thirdPartiesResponse';

// Providers
const actionsThirdParties = {
  THIRDPARTIES_LIST_PAGE_BEGIN: 'THIRDPARTIES_PAGE_BEGIN',
  THIRDPARTIES_LIST_PAGE_SUCCESS: 'THIRDPARTIES_PAGE_SUCCESS',
  THIRDPARTIES_LIST_PAGE_ERR: 'THIRDPARTIES_PAGE_ERR',

  thirdPartiesListPageBegin: (): { type: string } => {
    return {
      type: actionsThirdParties.THIRDPARTIES_LIST_PAGE_BEGIN,
    };
  },

  thirdPartiesListPageSuccess: (
    data: ResponseThirdParties[]
  ): { type: string; data: ResponseThirdParties[] | undefined } => {
    return {
      type: actionsThirdParties.THIRDPARTIES_LIST_PAGE_SUCCESS,
      data,
    };
  },

  thirdPartiesListPageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsThirdParties.THIRDPARTIES_LIST_PAGE_ERR,
      err,
    };
  },
};

const actionsThirdParty = {
  THIRDPARTY_LIST_PAGE_BEGIN: 'THIRDPARTY_PAGE_BEGIN',
  THIRDPARTY_LIST_PAGE_SUCCESS: 'THIRDPARTY_PAGE_SUCCESS',
  THIRDPARTY_LIST_PAGE_ERR: 'THIRDPARTY_PAGE_ERR',

  thirdPartyListPageBegin: (): { type: string } => {
    return {
      type: actionsThirdParty.THIRDPARTY_LIST_PAGE_BEGIN,
    };
  },

  thirdPartyListPageSuccess: (
    data: ResponseThirdParties | undefined
  ): { type: string; data: ResponseThirdParties | undefined } => {
    return {
      type: actionsThirdParty.THIRDPARTY_LIST_PAGE_SUCCESS,
      data,
    };
  },

  thirdPartyListPageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsThirdParty.THIRDPARTY_LIST_PAGE_ERR,
      err,
    };
  },
};

const actionsThirdPartyProviderCreate = {
  THIRDPARTY_CREATE_PAGE_BEGIN: 'THIRDPARTY_CREATE_PAGE_BEGIN',
  THIRDPARTY_CREATE_PAGE_SUCCESS: 'THIRDPARTY_CREATE_PAGE_SUCCESS',
  THIRDPARTY_CREATE_PAGE_ERR: 'THIRDPARTY_CREATE_PAGE_ERR',

  thirdPartyProviderCreatePageBegin: (): { type: string } => {
    return {
      type: actionsThirdPartyProviderCreate.THIRDPARTY_CREATE_PAGE_BEGIN,
    };
  },

  thirdPartyProviderCreatePageSuccess: (
    data: ResponseThirdParties | undefined
  ): { type: string; data: ResponseThirdParties | undefined } => {
    return {
      type: actionsThirdPartyProviderCreate.THIRDPARTY_CREATE_PAGE_SUCCESS,
      data,
    };
  },

  thirdPartyProviderCreatePageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsThirdPartyProviderCreate.THIRDPARTY_CREATE_PAGE_ERR,
      err,
    };
  },
};

const actionsThirdPartyProviderUpdate = {
  THIRDPARTY_UPDATE_PAGE_BEGIN: 'THIRDPARTY_UPDATE_PAGE_BEGIN',
  THIRDPARTY_UPDATE_PAGE_SUCCESS: 'THIRDPARTY_UPDATE_PAGE_SUCCESS',
  THIRDPARTY_UPDATE_PAGE_ERR: 'THIRDPARTY_UPDATE_PAGE_ERR',
  thirdPartyProviderUpdatePageBegin: (): { type: string } => {
    return {
      type: actionsThirdPartyProviderUpdate.THIRDPARTY_UPDATE_PAGE_BEGIN,
    };
  },

  thirdPartyProviderUpdatePageSuccess: (
    data: ResponseThirdParties | undefined
  ): { type: string; data: ResponseThirdParties | undefined } => {
    return {
      type: actionsThirdPartyProviderUpdate.THIRDPARTY_UPDATE_PAGE_SUCCESS,
      data,
    };
  },

  thirdPartyProviderUpdatePageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsThirdPartyProviderUpdate.THIRDPARTY_UPDATE_PAGE_ERR,
      err,
    };
  },
};

// Customers
const actionsThirdPartiesCustomers = {
  THIRDPARTIESCUSTOMERS_LIST_PAGE_BEGIN: 'THIRDPARTIESCUSTOMERS_PAGE_BEGIN',
  THIRDPARTIESCUSTOMERS_LIST_PAGE_SUCCESS: 'THIRDPARTIESCUSTOMERS_PAGE_SUCCESS',
  THIRDPARTIESCUSTOMERS_LIST_PAGE_ERR: 'THIRDPARTIESCUSTOMERS_PAGE_ERR',

  thirdPartiesCustomersListPageBegin: (): { type: string } => {
    return {
      type: actionsThirdPartiesCustomers.THIRDPARTIESCUSTOMERS_LIST_PAGE_BEGIN,
    };
  },

  thirdPartiesCustomersListPageSuccess: (
    data: ResponseThirdParties[]
  ): { type: string; data: ResponseThirdParties[] | undefined } => {
    return {
      type: actionsThirdPartiesCustomers.THIRDPARTIESCUSTOMERS_LIST_PAGE_SUCCESS,
      data,
    };
  },

  thirdPartiesCustomersListPageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsThirdPartiesCustomers.THIRDPARTIESCUSTOMERS_LIST_PAGE_ERR,
      err,
    };
  },
};

const actionsThirdPartyCustomer = {
  THIRDPARTYCUSTOMER_LIST_PAGE_BEGIN: 'THIRDPARTYCUSTOMER_PAGE_BEGIN',
  THIRDPARTYCUSTOMER_LIST_PAGE_SUCCESS: 'THIRDPARTYCUSTOMER_PAGE_SUCCESS',
  THIRDPARTYCUSTOMER_LIST_PAGE_ERR: 'THIRDPARTYCUSTOMER_PAGE_ERR',

  thirdPartyCustomerListPageBegin: (): { type: string } => {
    return {
      type: actionsThirdPartyCustomer.THIRDPARTYCUSTOMER_LIST_PAGE_BEGIN,
    };
  },

  thirdPartyCustomerListPageSuccess: (
    data: ResponseThirdParties | undefined
  ): { type: string; data: ResponseThirdParties | undefined } => {
    return {
      type: actionsThirdPartyCustomer.THIRDPARTYCUSTOMER_LIST_PAGE_SUCCESS,
      data,
    };
  },

  thirdPartyCustomerListPageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsThirdPartyCustomer.THIRDPARTYCUSTOMER_LIST_PAGE_ERR,
      err,
    };
  },
};

const actionsThirdPartyCustomerCreate = {
  THIRDPARTYCUSTOMER_CREATE_PAGE_BEGIN: 'THIRDPARTYCUSTOMER_CREATE_PAGE_BEGIN',
  THIRDPARTYCUSTOMER_CREATE_PAGE_SUCCESS: 'THIRDPARTYCUSTOMER_CREATE_PAGE_SUCCESS',
  THIRDPARTYCUSTOMER_CREATE_PAGE_ERR: 'THIRDPARTYCUSTOMER_CREATE_PAGE_ERR',

  thirdPartyCustomerCreatePageBegin: (): { type: string } => {
    return {
      type: actionsThirdPartyCustomerCreate.THIRDPARTYCUSTOMER_CREATE_PAGE_BEGIN,
    };
  },

  thirdPartyCustomerCreatePageSuccess: (
    data: ResponseThirdParties | undefined
  ): { type: string; data: ResponseThirdParties | undefined } => {
    return {
      type: actionsThirdPartyCustomerCreate.THIRDPARTYCUSTOMER_CREATE_PAGE_SUCCESS,
      data,
    };
  },

  thirdPartyCustomerCreatePageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsThirdPartyCustomerCreate.THIRDPARTYCUSTOMER_CREATE_PAGE_ERR,
      err,
    };
  },
};

const actionsThirdPartyCustomerUpdate = {
  THIRDPARTYCUSTOMER_UPDATE_PAGE_BEGIN: 'THIRDPARTYCUSTOMER_UPDATE_PAGE_BEGIN',
  THIRDPARTYCUSTOMER_UPDATE_PAGE_SUCCESS: 'THIRDPARTYCUSTOMER_UPDATE_PAGE_SUCCESS',
  THIRDPARTYCUSTOMER_UPDATE_PAGE_ERR: 'THIRDPARTYCUSTOMER_UPDATE_PAGE_ERR',
  thirdPartyCustomerUpdatePageBegin: (): { type: string } => {
    return {
      type: actionsThirdPartyCustomerUpdate.THIRDPARTYCUSTOMER_UPDATE_PAGE_BEGIN,
    };
  },

  thirdPartyCustomerUpdatePageSuccess: (
    data: ResponseThirdParties | undefined
  ): { type: string; data: ResponseThirdParties | undefined } => {
    return {
      type: actionsThirdPartyCustomerUpdate.THIRDPARTYCUSTOMER_UPDATE_PAGE_SUCCESS,
      data,
    };
  },

  thirdPartyCustomerUpdatePageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsThirdPartyCustomerUpdate.THIRDPARTYCUSTOMER_UPDATE_PAGE_ERR,
      err,
    };
  },
};

const actionsThirdPartyCustomerDelete = {
  THIRDPARTYCUSTOMER_DELETE_PAGE_BEGIN: 'THIRDPARTYCUSTOMER_DELETE_PAGE_BEGIN',
  THIRDPARTYCUSTOMER_DELETE_PAGE_SUCCESS: 'THIRDPARTYCUSTOMER_DELETE_PAGE_SUCCESS',
  THIRDPARTYCUSTOMER_DELETE_PAGE_ERR: 'THIRDPARTYCUSTOMER_DELETE_PAGE_ERR',
  thirdPartyCustomerDeletePageBegin: (): { type: string } => {
    return {
      type: actionsThirdPartyCustomerDelete.THIRDPARTYCUSTOMER_DELETE_PAGE_BEGIN,
    };
  },

  thirdPartyCustomerDeletePageSuccess: (
    data: ResponseThirdParties | undefined
  ): { type: string; data: ResponseThirdParties | undefined } => {
    return {
      type: actionsThirdPartyCustomerDelete.THIRDPARTYCUSTOMER_DELETE_PAGE_SUCCESS,
      data,
    };
  },

  thirdPartyCustomerDeletePageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsThirdPartyCustomerDelete.THIRDPARTYCUSTOMER_DELETE_PAGE_ERR,
      err,
    };
  },
};

export {
  actionsThirdParties,
  actionsThirdParty,
  actionsThirdPartyProviderCreate,
  actionsThirdPartyProviderUpdate,
  actionsThirdPartiesCustomers,
  actionsThirdPartyCustomer,
  actionsThirdPartyCustomerCreate,
  actionsThirdPartyCustomerUpdate,
  actionsThirdPartyCustomerDelete,
};
