import { createTheme } from '@mui/material';
import {
  // MiliColors,
  Colors,
} from './colors';
// import { selectClientName } from '../utils';

// const selectTheme = () => {
//   const selectedTheme = selectClientName();
//   switch (selectedTheme) {
//     case 'local':
//       return MiliColors2;
//     case 'give':
//       return MiliColors2;
//     default:
//       return MiliColors2;
//   }
// };

const options: any = {
  palette: Colors,
};

const theme = createTheme(options);

export default theme;
