import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { RootState } from '../../../../../redux/store';
import { thirdPartiesCustomersListPage } from '../../../../../redux/thirdParties/actionCreator';
import { TDispatch } from '../../../../../common/utils/types.d';
import {
  // capitalize,
  constructorName,
} from '../../../../../common/utils/functions';
import { IBeneficiariesCustomersMap, Item } from './types.d';
import { exportToExcel } from '../../../../../common/components/excelGererate';
import schema from './yupSchema';
import { MembershipsBeneficiariesReportV } from '../../../components/membership/membershipBeneficiariesReport';
import { TBeneficiariesCustomersReducer } from '../../../../../redux/beneficiaries/types.d';
import { beneficiariesCustomersListPage } from '../../../../../redux/beneficiaries/actionCreator';

export const MembershipsBeneficiariesReportsC = () => {
  /**
   * UseStates
   */
  const [beneficiariesCustomersData, setBeneficiariesCustomersData] = useState<any[]>([]);
  const [membershipDataExcel, setMembershipsDataExcel] = useState<Item[]>([]);

  /**
   * useForm
   */
  const { control } = useForm({
    resolver: yupResolver(schema),
  });

  /**
   * Dispatch
   */
  const dispatch = useDispatch();

  /**
   * Dispatch de Obtener todos los beneficiarios y sus membresías
   */
  const getAllBeneficiariesCustomers: TBeneficiariesCustomersReducer = useSelector(
    (dataState: RootState) => dataState?.beneficiariesCustomers
  );

  const handleExportExcel = () => {
    exportToExcel(membershipDataExcel, 'ReporteDeMembresias');
    toast.success('Descargado correctamente', {
      hideProgressBar: true,
      autoClose: 1000,
    });
  };

  /**
   * UseEffect que se encarga de mostrar la información en la Lista de comercios
   */
  useEffect(() => {
    if (getAllBeneficiariesCustomers?.beneficiariesCustomers instanceof Array) {
      const data = getAllBeneficiariesCustomers?.beneficiariesCustomers?.map(
        (x: IBeneficiariesCustomersMap, i: number) => {
          const ID = i + 1;
          const currentDate = `${moment().format('YYYY-MM-D')}`;
          return {
            ...x,
            names: constructorName([
              String(x?.firstName),
              String(x?.middleName),
              String(x?.firstSurname),
              String(x?.secondSurname),
            ]),
            validity: x?.thirdPartyBeneficiary?.thirdParty?.customerValidity?.endDate,
            state:
              String(x?.thirdPartyBeneficiary?.thirdParty?.customerValidity?.endDate) <= currentDate
                ? 'VENCIDA'
                : 'ACTIVA',
            thirdPartyBeneficiary: String(x?.thirdPartyBeneficiary?.thirdParty?.businessName),
            ID: !x ? null : ID,
          };
        }
      );

      setBeneficiariesCustomersData(data);
    }
  }, [getAllBeneficiariesCustomers?.beneficiariesCustomers]);

  /**
   * UseEffect que se encarga de mostrar la información en el archivo excel
   */
  useEffect(() => {
    if (getAllBeneficiariesCustomers?.beneficiariesCustomers instanceof Array) {
      const data = getAllBeneficiariesCustomers?.beneficiariesCustomers?.map((x, i: number) => {
        const ID = i + 1;
        return {
          Item: ID,
          'Nombre de la membresía': String(x?.thirdPartyBeneficiary?.thirdParty?.businessName),
          'Nombre del beneficiario': constructorName([
            String(x?.firstName),
            String(x?.middleName),
            String(x?.firstSurname),
            String(x?.secondSurname),
          ]),
        };
      });
      setMembershipsDataExcel(data);
    }
  }, [getAllBeneficiariesCustomers?.beneficiariesCustomers]);

  /**
   * UseEffect que se encarga de llamar a todos los dispatches
   */
  useEffect(() => {
    dispatch(thirdPartiesCustomersListPage() as TDispatch);
    dispatch(beneficiariesCustomersListPage() as TDispatch);
  }, [dispatch]);

  return (
    <MembershipsBeneficiariesReportV
      schema={schema}
      control={control}
      data={beneficiariesCustomersData}
      handleExportExcel={handleExportExcel}
      loading={getAllBeneficiariesCustomers?.loading}
    />
  );
};
