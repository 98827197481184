import 'reflect-metadata';
import { AxiosResponse } from 'axios';
import { IBanksInfrastructure } from '../../../domain/interface/infrastructure/banks/IBanksInfrastructure';
import { RequestBanks } from '../../../domain/banks/banks/model/requestBanks';
import { ResponseBanks } from '../../../domain/banks/banks/model/responseBanks';
import { processDataBackend } from '../../../../common/utils/functions';

export class BanksInfrastructure implements IBanksInfrastructure {
  private a = '';

  public async getBanksAPI(request: RequestBanks): Promise<ResponseBanks[]> {
    this.a = 'aaa';
    const res: AxiosResponse = await processDataBackend(
      'GET',
      '/api/banks/getAll',
      {},
      { request }
    );

    const banks: ResponseBanks[] = res.data;

    return Promise.resolve(banks);
  }
}
