import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from '../../../../../redux/store';
import {
  clearThirdPartyCustomerDeleteListPage,
  deleteThirdPartiesCustomerPage,
  thirdPartiesCustomersListPage,
} from '../../../../../redux/thirdParties/actionCreator';
import { TDispatch } from '../../../../../common/utils/types.d';
import {
  // capitalize,
  constructorName,
  numberFormat,
  phoneFormat,
} from '../../../../../common/utils/functions';
import {
  TDeleteThirdPartyCustomerReducer,
  TThirdPartiesCustomersReducer,
} from '../../../../../redux/thirdParties/types.d';
import { MembershipsListV } from '../../../components/membership/membershipsList/index';
import { IThirdPartiesCustomersMap, Item, TDataDocumentType } from './types.d';
import { exportToExcel } from '../../../../../common/components/excelGererate';

export const MembershipsListC = () => {
  /**
   * UseStates
   */
  const [thirdPartiesData, setThirdPartiesData] = useState<IThirdPartiesCustomersMap[]>([]);
  const [membershipDataExcel, setMembershipsDataExcel] = useState<Item[]>([]);
  const [dataId, setDataId] = useState<number>();
  const [openDelete, setOpenDelete] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  /**
   * Constante con array de objetos para asignar un label string a Membership con idDocumentType
   */
  const contentDocumentType: TDataDocumentType[] = [
    {
      idDocumentType: 1,
      name: 'CÉDULA DE CIUDADANÍA',
    },
    {
      idDocumentType: 2,
      name: 'TARJETA DE IDENTIDAD',
    },
    {
      idDocumentType: 3,
      name: 'REGISTRO CIVIL DE NACIMIENTO',
    },
    {
      idDocumentType: 4,
      name: 'NIT',
    },
    {
      idDocumentType: 5,
      name: 'CEDULA EXTRANJERÍA',
    },
    {
      idDocumentType: 6,
      name: 'TARJETA EXTRANJERÍA',
    },
    {
      idDocumentType: 7,
      name: 'PASAPORTE',
    },
    {
      idDocumentType: 8,
      name: 'TIPO DE DOCUMENTO EXTRANJERO',
    },
    {
      idDocumentType: 9,
      name: 'SIN USO DEFINIDO POR LA DIAN',
    },
  ];

  /**
   * Dispatch
   */
  const dispatch = useDispatch();

  /**
   * Dispatch de Obtener todos los comercios
   */
  const getAllThirdPartiesCustomers: TThirdPartiesCustomersReducer = useSelector(
    (dataState: RootState) => dataState?.thirdPartiesCustomers
  );

  /**
   * Dispatch de eliminar los comercios
   */
  const deleteThirdPartiesCustomerDispatch: TDeleteThirdPartyCustomerReducer = useSelector(
    (dataState: RootState) => dataState?.thirdPartyCustomerDeleted
  );

  /*
   * Función para asignar un label al idDocumentType.
   */
  const getItemByNumber = (x: number | undefined) => {
    return contentDocumentType.find((item) => item?.idDocumentType === x);
  };

  const handleExportExcel = () => {
    exportToExcel(membershipDataExcel, 'ListadoDeMembresias');
    toast.success('Descargado correctamente', {
      hideProgressBar: true,
      autoClose: 1000,
    });
  };

  /**
   * Función para eliminar un registro
   */
  const handleDelete = async () => {
    setDisabled(true);
    await dispatch(
      deleteThirdPartiesCustomerPage({
        idThirdParty: Number(dataId),
      }) as TDispatch
    );
    await dispatch(thirdPartiesCustomersListPage() as TDispatch);
    setOpenDelete(false);
    setLoading(true);
  };

  /**
   * Función que se encarga de cerrar el modal de confirmación
   */
  const handleClose = () => {
    setOpenDelete(false);
  };

  /**
   * Función que se encarga de abrir el modal de confirmación para poder eliminar un registro
   */
  const buttonDelete = async (datos: number) => {
    setDataId(datos);
    setOpenDelete(true);
  };

  /**
   * UseEffect pendiente al momento de eliminar un beneficio existente
   */
  useEffect(() => {
    if (dataId && loading) {
      if (
        deleteThirdPartiesCustomerDispatch?.thirdPartyCustomerDeleted &&
        !deleteThirdPartiesCustomerDispatch?.error
      ) {
        toast.success('membresía eliminada exitosamente', {
          hideProgressBar: true,
        });
        setTimeout(() => {
          setDisabled(false);
        }, 2000);
        dispatch(clearThirdPartyCustomerDeleteListPage() as TDispatch);
      } else if (
        !deleteThirdPartiesCustomerDispatch?.thirdPartyCustomerDeleted &&
        deleteThirdPartiesCustomerDispatch?.error
      ) {
        const message = deleteThirdPartiesCustomerDispatch?.error?.response
          ? deleteThirdPartiesCustomerDispatch?.error?.response?.data?.message
          : 'Ha ocurrido una incidencia';
        toast.error(`${message}`, {
          hideProgressBar: true,
        });
        dispatch(clearThirdPartyCustomerDeleteListPage() as TDispatch);
        setDisabled(false);
      }
    }
  }, [
    deleteThirdPartiesCustomerDispatch?.thirdPartyCustomerDeleted,
    deleteThirdPartiesCustomerDispatch?.error,
    loading,
    dispatch,
    dataId,
  ]);

  /**
   * UseEffect que se encarga de mostrar la información en la Lista de comercios
   */
  useEffect(() => {
    if (getAllThirdPartiesCustomers?.thirdPartiesCustomers instanceof Array) {
      const data = getAllThirdPartiesCustomers?.thirdPartiesCustomers?.map(
        (x: IThirdPartiesCustomersMap, i: number) => {
          const ID = i + 1;
          return {
            ...x,
            cellPhone: phoneFormat(String(x?.cellPhone)),
            names:
              x?.idNature === 1
                ? constructorName([x?.firstName, x?.middleName, x?.firstSurname, x?.secondSurname])
                : x?.businessName,
            ID: !x ? null : ID,
          };
        }
      );

      setThirdPartiesData(data);
    }
  }, [getAllThirdPartiesCustomers?.thirdPartiesCustomers]);

  /**
   * UseEffect que se encarga de mostrar la información en el archivo excel
   */
  useEffect(() => {
    if (getAllThirdPartiesCustomers?.thirdPartiesCustomers instanceof Array) {
      const data = getAllThirdPartiesCustomers?.thirdPartiesCustomers?.map((x, i: number) => {
        const ID = i + 1;
        return {
          Item: ID,
          Naturaleza: x?.idNature === 1 ? 'NATURAL' : 'JURÍDICO',
          'Tipo de identificación': String(getItemByNumber(x.idDocumentType)?.name),
          'Número de identificación': numberFormat(Number(x?.identificationNumber)),
          'Nombre o Razón social':
            x?.idNature === 1
              ? constructorName([x?.firstName, x?.middleName, x?.firstSurname, x?.secondSurname]) ||
                undefined
              : x?.businessName || undefined,
          Celular: x?.cellPhone || undefined,
        };
      });

      setMembershipsDataExcel(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllThirdPartiesCustomers?.thirdPartiesCustomers]);

  /**
   * UseEffect que se encarga de llamar a todos los dispatches
   */
  useEffect(() => {
    dispatch(thirdPartiesCustomersListPage() as TDispatch);
    dispatch(clearThirdPartyCustomerDeleteListPage() as TDispatch);
  }, [dispatch]);

  return (
    <MembershipsListV
      data={thirdPartiesData}
      loading={getAllThirdPartiesCustomers?.loading}
      handleExportExcel={handleExportExcel}
      handleDelete={handleDelete}
      handleClose={handleClose}
      deleteLoading={deleteThirdPartiesCustomerDispatch?.loading}
      buttonDelete={buttonDelete}
      disabled={disabled}
      openDelete={openDelete}
    />
  );
};
