export class ResponseConsecutive {
  public idConsecutive? = 0;

  public idCategory? = 0;

  public startBenefitsCode? = '';

  public benefitsCode? = '';

  public state? = 0;
}
