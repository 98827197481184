import 'reflect-metadata';
import { container } from 'tsyringe';
import { AxiosError } from 'axios';
import { RequestStreetTypes } from '../../../core/domain/addresses/streetTypes/model/requestStreetTypes';
import { IStreetTypesApplication } from '../../../core/domain/interface/application/addresses/IStreetTypesApplication';
import { AppDispatch } from '../../store';
import actions from './actions';

const { streetTypesListPageBegin, streetTypesListPageSuccess, streetTypesListPageErr } = actions;

const streetTypesListPage = (): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(streetTypesListPageBegin());
      const streetTypesApplication =
        container.resolve<IStreetTypesApplication>('IStreetTypesApplication');
      const request = new RequestStreetTypes();
      const result = await streetTypesApplication.getStreetTypes(request);

      dispatch(streetTypesListPageSuccess(result));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(streetTypesListPageErr(error));
    }
  };
};

export { streetTypesListPage };
