import { AxiosError } from 'axios';
import { ResponseBanks } from '../../../core/domain/banks/banks/model/responseBanks';

const actions = {
  BANKS_LIST_PAGE_BEGIN: 'BANKS_LIST_PAGE_BEGIN',
  BANKS_LIST_PAGE_SUCCESS: 'BANKS_LIST_PAGE_SUCCESS',
  BANKS_LIST_PAGE_ERR: 'BANKS_LIST_PAGE_ERR',

  banksListPageBegin: (): { type: string } => {
    return {
      type: actions.BANKS_LIST_PAGE_BEGIN,
    };
  },

  banksListPageSuccess: (
    data: ResponseBanks[] | ResponseBanks
  ): { type: string; data: ResponseBanks[] | ResponseBanks } => {
    return {
      type: actions.BANKS_LIST_PAGE_SUCCESS,
      data,
    };
  },

  banksListPageErr: (err: AxiosError): { type: string; err: AxiosError } => {
    return {
      type: actions.BANKS_LIST_PAGE_ERR,
      err,
    };
  },
};

export default actions;
