import 'reflect-metadata';
import { container } from 'tsyringe';
import { IUserTypeApplication } from '../../../domain/interface/application/userPermissions/IUserTypeApplication';
import { IUserTypeInfrastructure } from '../../../domain/interface/infrastructure/userPermissions/IUserTypeInfrastructure';
import { RequestUserType } from '../../../domain/userPermissions/userType/model/requestUserType';
import { ResponseUserType } from '../../../domain/userPermissions/userType/model/responseUserType';

export class UserTypeApplication implements IUserTypeApplication {
  private UserTypeInfrastructure: IUserTypeInfrastructure;

  public constructor() {
    this.UserTypeInfrastructure =
      container.resolve<IUserTypeInfrastructure>('IUserTypeInfrastructure');
  }

  public async getUserType(request: RequestUserType): Promise<ResponseUserType[]> {
    const result = await this.UserTypeInfrastructure.getUserTypeAPI(request);
    return result;
  }
}
