import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { IValidateCoupon } from '../../../containers/benefits/validateCoupon/types.d';
import { InputField } from '../../../../../common/components/formAdapters';
import { ButtonSubmit } from '../benefitRegister/styled';

const ValidateCouponV: React.FC<IValidateCoupon> = ({ control, schema, handleValidation }) => {
  const theme = useTheme();
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '300px',
          width: '425px',
          '@media screen and (max-width: 425px)': {
            minWidth: '320px',
          },
          background: 'white',
          boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);',
          overflow: 'auto',
          borderRadius: '16px',
        }}>
        <Box
          sx={{
            height: '85%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '70%',
          }}>
          <Box
            sx={{
              height: '270px',
              width: '80%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '10px',
            }}>
            <Typography
              variant="h5"
              sx={{ fontFamily: 'Poppins-Regular', fontWeight: '600' }}
              color={theme.palette.primary.main}>
              Validar Cupón
            </Typography>
            <InputField
              name="validationCode"
              control={control}
              label="Validación de cupón"
              schema={schema?.fields?.validationCode}
            />

            <ButtonSubmit  backgroundColor={theme.palette.primary.main} hoverStyles={theme.palette.primary.dark} type="submit" onClick={handleValidation}>
              Validar
            </ButtonSubmit>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ValidateCouponV;
