import 'reflect-metadata';
import { AxiosResponse } from 'axios';
import { IDocumentsTypesInfrastructure } from '../../../domain/interface/infrastructure/documentsPersons/IDocumentsTypesInfrastructure';
import { RequestDocumentsTypes } from '../../../domain/documentsPersons/documentTypes/model/requestDocumentsTypes';
import { ResponseDocumentsTypes } from '../../../domain/documentsPersons/documentTypes/model/responseDocumentsTypes';
import { processDataBackend } from '../../../../common/utils/functions';

export class DocumentsTypesInfrastructure implements IDocumentsTypesInfrastructure {
  private a = '';

  public async getDocumentsTypesAPI(
    request: RequestDocumentsTypes
  ): Promise<ResponseDocumentsTypes[]> {
    this.a = 'aaa';

    const res: AxiosResponse = await processDataBackend(
      'GET',
      '/api/documentTypes/getAll',
      {},
      { request }
    );

    const documentTypes: ResponseDocumentsTypes[] = res.data;

    return Promise.resolve(documentTypes);
  }
}
