import { ResponseUserPermission } from '../model/responseUserPermission';

export class UserPermissionState {
  public constructor() {
    this.userPermissions = [new ResponseUserPermission()];
  }

  public name = '';

  public userPermissions: ResponseUserPermission[];
}
