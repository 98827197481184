import { container } from 'tsyringe';
import { RequestAddress } from '../../../domain/addresses/address/model/requestAddress';
import { ResponseAddress } from '../../../domain/addresses/address/model/responseAddress';
import { IAddressesApplication } from '../../../domain/interface/application/addresses/IAddressesApplication';
import { IAddressesInfrastructure } from '../../../domain/interface/infrastructure/addresses/IAddressesInfrastructure';

export class AddressesApplication implements IAddressesApplication {
  private addressesInfrastructure: IAddressesInfrastructure;

  public constructor() {
    this.addressesInfrastructure = container.resolve<IAddressesInfrastructure>(
      'IAddressesInfrastructure'
    );
  }

  public async getAddresses(request: RequestAddress): Promise<ResponseAddress[]> {
    const result = await this.addressesInfrastructure.getAddressesAPI(request);
    return result;
  }
}
