import React, { useCallback, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { Button, CircularProgress, useTheme } from '@mui/material';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { TModalAlert } from './types.d';
import { styleSheet } from './styled';
import useWindowDimensions from '../../utils/hooks/useDimensions';

export const TransitionsModalAlert: React.FC<TModalAlert> = ({
  state = false,
  title,
  subTitle,
  paragraph,
  onClickAccept,
  nameAccept,
  nameDecline,
  icon,
  handleCloseModal,
  disabledLoading,
}) => {
  const theme = useTheme();
  const styles = styleSheet(theme);

  const { h } = useWindowDimensions();
  const [open, setOpen] = useState(state);
  const [modalDisabled, setModalDisabled] = useState(false);

  const hide = useCallback(() => {
    if (!modalDisabled) {
      setOpen(false);
      handleCloseModal(false);
      setModalDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, modalDisabled]);

  useEffect(() => {
    setOpen(state);
    setModalDisabled(false);
  }, [state]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={hide}
      closeAfterTransition>
      <Fade in={open}>
        <Box sx={{ ...styles.modal, maxHeight: h(50) }}>
          <Box sx={styles.boxIcon}>
            {icon || <WarningAmberRoundedIcon sx={{ fontSize: '120px', color: 'white' }} />}
          </Box>
          <Box sx={styles.boxInfo}>
            <Box sx={{ width: '100%' }}>
              <Typography sx={styles.title}>{title}</Typography>
              <Typography sx={styles.subTitle}>{subTitle}</Typography>
              <Typography sx={styles.subTitle}>{paragraph}</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20px',
              }}>
              <Button
                sx={styles.buttonOption}
                onClick={onClickAccept}
                disabled={disabledLoading}
                startIcon={
                  disabledLoading ? <CircularProgress size={16} color="inherit" /> : nameAccept
                }></Button>
              <Button
                sx={styles.buttonOption}
                onClick={hide}
                disabled={disabledLoading}
                startIcon={
                  disabledLoading ? <CircularProgress size={16} color="inherit" /> : nameDecline
                }></Button>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
