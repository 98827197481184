import 'reflect-metadata';
import { container } from 'tsyringe';
import { RequestProfession } from '../../../domain/workInformation/profession/model/requestProfession';
import { IProfessionInfrastructure } from '../../../domain/interface/infrastructure/workInformation/IprofessionInfrastructure';
import { IProfessionApplication } from '../../../domain/interface/application/workInformation/IprofessionApplication';
import { ResponseProfession } from '../../../domain/workInformation/profession/model/responseProfession';

export class ProfessionApplication implements IProfessionApplication {
  private professionInfrastructure: IProfessionInfrastructure;

  public constructor() {
    this.professionInfrastructure = container.resolve<IProfessionInfrastructure>(
      'IProfessionInfrastructure'
    );
  }

  public async getProfession(request: RequestProfession): Promise<ResponseProfession[]> {
    const result = await this.professionInfrastructure.getProfessionAPI(request);
    return result;
  }
}
