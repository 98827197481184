import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { filterKeyObject } from '../../../../../common/utils/functions';
import { TDispatch } from '../../../../../common/utils/types.d';
import { citiesListPage } from '../../../../../redux/addresses/cities/actionCreator';
import { TCitiesReducer } from '../../../../../redux/addresses/cities/types';
import { departmentsListPage } from '../../../../../redux/addresses/departments/actionCreator';
import { TDepartmentsReducer } from '../../../../../redux/addresses/departments/types';
import { streetTypesListPage } from '../../../../../redux/addresses/streetTypes/actionCreator';
import { TStreetTypesReducer } from '../../../../../redux/addresses/streetTypes/types';
import {
  clearCreateBeneficiaryPage,
  clearUpdateBeneficiaryPage,
  createBeneficiaryPage,
  getBeneficiaryByIdListPage,
  updateBeneficiaryPage,
} from '../../../../../redux/beneficiaries/actionCreator';
import {
  TBeneficiaryReducer,
  TCreateBeneficiaryReducer,
  TUpdateBeneficiaryReducer,
} from '../../../../../redux/beneficiaries/types.d';
import { documentsTypesListPage } from '../../../../../redux/documentsPersons/documentsTypes/actionCreator';
import { TDocumentTypesReducer } from '../../../../../redux/documentsPersons/documentsTypes/types.d';
import { genderListPage } from '../../../../../redux/documentsPersons/gender/actionCreator';
import { TGenderReducer } from '../../../../../redux/documentsPersons/gender/types';
import { RootState } from '../../../../../redux/store';
import { thirdPartiesCustomersListPage } from '../../../../../redux/thirdParties/actionCreator';
import { TThirdPartiesCustomersReducer } from '../../../../../redux/thirdParties/types.d';
import { BeneficiaryRegisterV } from '../../../components/beneficiaries/beneficiaryRegister';
import { TDataDocumentType } from '../../user/userRegister/types.d';
import {
  TCities,
  TDataDepartments,
  TGender,
  TLoginDispatch,
  TStreetTypes,
  TThirdPartiesCustomer,
} from './types.d';
import schema from './yupSchema';

export const BeneficiaryRegisterC: React.FC = () => {
  /**
   * navigate
   */
  const navigate = useNavigate();

  /**
   * dispatch
   */
  const dispatch = useDispatch();

  /**
   * useParams del id de tercero
   */
  const { id } = useParams();
  const idBeneficiary: number = Number(id);
  const location = useLocation();

  /**
   * States
   */
  const [loading, setLoading] = useState<boolean>(false);
  const [dataCities, setDataCities] = useState<TCities>([]);
  const [dataGender, setDataGender] = useState<TGender>([]);
  const [openModal, setOpenModal] = useState({ bulkLoad: false });
  const [dataStreetTypes, setDataStreetTypes] = useState<TStreetTypes>([]);
  const [dataDepartments, setDataDepartments] = useState<TDataDepartments>([]);
  const [dataDocumentType, setDataDocumentType] = useState<TDataDocumentType>([]);
  const [dataMembership, setDataMembership] = useState<TThirdPartiesCustomer>([]);

  /**
   * Dispatch de ciudades
   */
  const citiesDispatch: TCitiesReducer = useSelector((dataState: RootState) => dataState?.cities);

  /**
   * Dispatch de género
   */
  const genderDispatch: TGenderReducer = useSelector((dataState: RootState) => dataState?.gender);

  /**
   * Dispatch de Login
   */
  const loginDispatch: TLoginDispatch = useSelector((dataState: RootState) => dataState?.loginData);

  /**
   * Dispatch de tipo de calles
   */
  const thirdPartiesCustomerDispatch: TThirdPartiesCustomersReducer = useSelector(
    (dataState: RootState) => dataState?.thirdPartiesCustomers
  );

  /**
   * Dispatch de crear beneficiario
   */
  const createBeneficiaryDispatch: TCreateBeneficiaryReducer = useSelector(
    (dataState: RootState) => dataState?.beneficiaryCreated
  );

  /**
   * Dispatch de actualizar beneficiario
   */
  const updateBeneficiaryDispatch: TUpdateBeneficiaryReducer = useSelector(
    (dataState: RootState) => dataState?.beneficiaryUpdated
  );

  /**
   * Dispatch de tipos de documentos
   */
  const userDocumentTypeDispatch: TDocumentTypesReducer = useSelector(
    (dataState: RootState) => dataState?.documentsTypes
  );

  /**
   * Dispatch de beneficiario
   */
  const getBeneficiaryByIdDispatch: TBeneficiaryReducer = useSelector(
    (dataState: RootState) => dataState?.beneficiary
  );

  /**
   * Dispatch de departamentos
   */
  const departmentsDispatch: TDepartmentsReducer = useSelector(
    (dataState: RootState) => dataState?.departments
  );

  /**
   * Dispatch de tipo de calles
   */
  const streetTypesDispatch: TStreetTypesReducer = useSelector(
    (dataState: RootState) => dataState?.streetTypes
  );

  /**
   * Dispatch de Login
   */
  const login = loginDispatch?.login?.userType;

  /**
   * useForm
   */
  const { reset, getValues, handleSubmit, control, watch, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  /**
   * Watch del departamento seleccionado
   */
  const watchDepartment = watch('address.idDepartment');

  /**
   * Watch del tipo de dirección seleccionado
   */
  const watchIdStreetTypeOne = watch('address.idAddressFirstStreetType');

  /*
   * Función para retroceder.
   */
  const goBack = () => {
    setTimeout(() => {
      navigate('/beneficiarios/lista');
    });
  };

  /**
   * Función para abrir o cerrar el modal de carga masiva
   */
  const sendConfirm = (modal: string) => {
    switch (modal) {
      case 'bulkLoad':
        setOpenModal({ ...openModal, bulkLoad: !openModal?.bulkLoad });
        break;
      default:
        break;
    }
  };

  /**
   * Función para registrar un beneficiario
   */
  const handleRegister = handleSubmit(async () => {
    const values = getValues();
    dispatch(
      createBeneficiaryPage({
        ...values,
        cellPhone: values.cellPhone,
        telephone: watch('telephone') ? String(watch('telephone')) : null,
      }) as TDispatch
    );
    setLoading(true);
  });

  /**
   * Función que se encarga de actualizar un beneficiario
   */
  const handleUpdate = handleSubmit(async () => {
    dispatch(
      updateBeneficiaryPage({
        ...filterKeyObject(getValues(), [
          'idGender',
          'idDocumentType',
          'identificationNumber',
          'thirdPartyBeneficiary',
        ]),
        idBeneficiary,
        telephone: watch('telephone') ? String(watch('telephone')) : null,
      }) as TDispatch
    );
    setLoading(true);
  });

  /**
   * UseEffect para llamar documentTypes
   */
  useEffect(() => {
    setDataDocumentType(
      userDocumentTypeDispatch?.documentsTypes?.filter((item) => item?.idDocumentType !== 4) || []
    );
  }, [userDocumentTypeDispatch?.documentsTypes]);

  /**
   * UseEffect para llamar a Departments
   */
  useEffect(() => {
    setDataDepartments(departmentsDispatch?.departments || []);
  }, [departmentsDispatch?.departments]);

  /**
   * UseEffect para llamar a Cities
   */
  useEffect(() => {
    setDataCities(citiesDispatch?.cities || []);
  }, [citiesDispatch?.cities]);

  /**
   * UseEffect para llamar a StreetTypes
   */
  useEffect(() => {
    setDataStreetTypes(streetTypesDispatch?.streetTypes || []);
  }, [streetTypesDispatch?.streetTypes]);

  /**
   * UseEffect para llamar a Genders
   */
  useEffect(() => {
    setDataGender(genderDispatch?.gender || []);
  }, [genderDispatch?.gender]);

  /**
   * UseEffect para llamar a Third Parties Customers
   */
  useEffect(() => {
    setDataMembership(thirdPartiesCustomerDispatch?.thirdPartiesCustomers || []);
  }, [thirdPartiesCustomerDispatch?.thirdPartiesCustomers]);

  /**
   * UseEffect pendiente al momento de crear un beneficiario existente
   */
  useEffect(() => {
    if (loading) {
      if (createBeneficiaryDispatch?.beneficiaryCreated && !createBeneficiaryDispatch?.error) {
        toast.success('Beneficiario registrado exitosamente', {
          hideProgressBar: true,
        });
        reset({
          idGender: undefined,
          idDocumentType: undefined,
          identificationNumber: undefined,
          thirdPartyBeneficiary: {
            idThirdParty: undefined,
          },
          firstName: '',
          middleName: '',
          firstSurname: '',
          secondSurname: '',
          address: {
            description: '',
            neighBorhood: '',
            idCity: undefined,
            firstStreetNumber: '',
            thirdStreetNumber: '',
            secondStreetNumber: '',
            idDepartment: undefined,
            idAddressFirstStreetType: undefined,
            idAddressSecondStreetType: undefined,
          },
          email: '',
          cellPhone: undefined,
          telephone: undefined,
        });
        dispatch(clearCreateBeneficiaryPage() as TDispatch);
        goBack();
      } else if (
        !createBeneficiaryDispatch?.beneficiaryCreated &&
        createBeneficiaryDispatch?.error
      ) {
        const message = createBeneficiaryDispatch?.error?.response
          ? createBeneficiaryDispatch?.error?.response?.data?.message
          : 'Ha ocurrido una incidencia';
        toast.error(`${message}`, {
          hideProgressBar: true,
        });
        dispatch(clearCreateBeneficiaryPage() as TDispatch);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createBeneficiaryDispatch?.beneficiaryCreated, createBeneficiaryDispatch?.error]);

  /**
   * Muestra la información de los documentos según los parámetros de la URL
   */
  useEffect(() => {
    if (idBeneficiary) {
      dispatch(getBeneficiaryByIdListPage({ idBeneficiary }) as TDispatch);
    } else {
      reset({});
    }
  }, [idBeneficiary, dispatch, reset]);

  /**
   * useeEffect para limpiar todos los campos de esa ruta
   */
  useEffect(() => {
    if (location?.pathname === '/beneficiarios/registro') {
      reset({});
    }
  }, [location, reset]);

  /**
   * useeEffect para asignar la membresia
   */
  useEffect(() => {
    if (
      location?.pathname === '/beneficiarios/registro' &&
      loginDispatch.login?.userType === 'Membresia'
    ) {
      setValue('thirdPartyBeneficiary.idThirdParty', Number(loginDispatch?.login?.idThirdParty));
    }
  }, [location, setValue, loginDispatch]);

  /**
   * UseEffect pendiente al momento de actualizar un beneficiario jurídico existente
   */
  useEffect(() => {
    if (idBeneficiary && loading) {
      if (updateBeneficiaryDispatch?.beneficiaryUpdated && !updateBeneficiaryDispatch?.error) {
        reset({
          idGender: undefined,
          idDocumentType: undefined,
          identificationNumber: undefined,
          thirdPartyBeneficiary: {
            idThirdParty: undefined,
          },
          firstName: '',
          middleName: '',
          firstSurname: '',
          secondSurname: '',
          address: {
            idCity: undefined,
            description: '',
            neighBorhood: '',
            thirdStreetNumber: '',
            firstStreetNumber: '',
            secondStreetNumber: '',
            idDepartment: undefined,
            idAddressFirstStreetType: undefined,
            idAddressSecondStreetType: undefined,
          },
          email: '',
          cellPhone: undefined,
          telephone: undefined,
        });
        dispatch(clearUpdateBeneficiaryPage() as TDispatch);
        dispatch(getBeneficiaryByIdListPage({ idBeneficiary }) as TDispatch);
        toast.success('Beneficiario actualizado exitosamente', { hideProgressBar: true });
        goBack();
      } else if (
        !updateBeneficiaryDispatch?.beneficiaryUpdated &&
        updateBeneficiaryDispatch?.error
      ) {
        const message = updateBeneficiaryDispatch?.error?.response
          ? updateBeneficiaryDispatch?.error?.response?.data?.message
          : 'Ha ocurrido una incidencia';
        toast.error(`${message}`);
        dispatch(clearUpdateBeneficiaryPage() as TDispatch);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateBeneficiaryDispatch?.beneficiaryUpdated, updateBeneficiaryDispatch?.error]);

  /**
   * useEffect que se encarga de mostrar la información correspondiente en los campos mediante el ID
   */
  useEffect(() => {
    if (idBeneficiary) {
      if (getBeneficiaryByIdDispatch?.beneficiary?.idBeneficiary) {
        const data = getBeneficiaryByIdDispatch?.beneficiary;
        reset({
          idGender: data?.idGender,
          idDocumentType: data?.idDocumentType,
          identificationNumber: data?.identificationNumber,
          thirdPartyBeneficiary: {
            ...data.thirdPartyBeneficiary,
            idThirdParty: data?.thirdPartyBeneficiary?.thirdParty?.idThirdParty,
          },
          firstName: data?.firstName,
          middleName: data?.middleName,
          firstSurname: data?.firstSurname,
          secondSurname: data?.secondSurname,
          address: {
            ...data.address,
            idCity: data?.address?.idCity,
            idDepartment: data?.address?.idDepartment,
            neighBorhood: data?.address?.neighBorhood,
            thirdStreetNumber: data?.address?.thirdStreetNumber,
            firstStreetNumber: data?.address?.firstStreetNumber,
            secondStreetNumber: data?.address?.secondStreetNumber,
            idAddressFirstStreetType: data?.address?.idAddressFirstStreetType,
            idAddressSecondStreetType: data?.address?.idAddressSecondStreetType,
            description: data?.address?.description ? data?.address?.description : '',
          },
          email: data?.email,
          cellPhone: data?.cellPhone !== 0 ? data?.cellPhone?.toString() : undefined,
          telephone: data?.telephone !== 0 ? data?.telephone?.toString() : undefined,
        });
      }
    }
  }, [getBeneficiaryByIdDispatch, idBeneficiary, reset]);

  /**
   * UseEffect para llamar a todos los dispatches
   */
  useEffect(() => {
    dispatch(citiesListPage() as TDispatch);
    dispatch(genderListPage() as TDispatch);
    dispatch(departmentsListPage() as TDispatch);
    dispatch(streetTypesListPage() as TDispatch);
    dispatch(documentsTypesListPage() as TDispatch);
    dispatch(clearCreateBeneficiaryPage() as TDispatch);
    dispatch(clearUpdateBeneficiaryPage() as TDispatch);
    dispatch(thirdPartiesCustomersListPage() as TDispatch);
  }, [dispatch]);

  return (
    <BeneficiaryRegisterV
      login={login}
      schema={schema}
      control={control}
      openModal={openModal}
      dataCities={dataCities}
      dataGender={dataGender}
      sendConfirm={sendConfirm}
      handleUpdate={handleUpdate}
      idBeneficiary={idBeneficiary}
      dataMembership={dataMembership}
      handleRegister={handleRegister}
      dataDepartments={dataDepartments}
      dataStreetTypes={dataStreetTypes}
      watchDepartment={watchDepartment}
      dataDocumentType={dataDocumentType}
      watchIdStreetTypeOne={watchIdStreetTypeOne}
      createLoading={createBeneficiaryDispatch?.loading}
      updateLoading={updateBeneficiaryDispatch?.loading}
    />
  );
};
