import * as yup from 'yup';

const schema = yup
  .object({
    idCategory: yup.string().optional(),
    searchBenefits: yup.string().optional(),
    address: yup.object({
      idDepartment: yup.number().required('Campo requerido'),
      idCity: yup.number().required('Campo requerido'),
    }),
    email: yup.string().required('Correo requerido').email('El correo electrónico no es válido'),
  })
  .required();

export default schema;
