import 'reflect-metadata';
import { container } from 'tsyringe';
import { IUserApplication } from '../../../domain/interface/application/userPermissions/IUserApplication';
import { IUserInfrastructure } from '../../../domain/interface/infrastructure/userPermissions/IUserInfrastructure';
import { RequestUser } from '../../../domain/userPermissions/user/model/requestUser';
import { ResponseUser } from '../../../domain/userPermissions/user/model/responseUser';

export class UserApplication implements IUserApplication {
  private userInfrastructure: IUserInfrastructure;

  public constructor() {
    this.userInfrastructure = container.resolve<IUserInfrastructure>('IUserInfrastructure');
  }

  public async getUser(request: RequestUser): Promise<ResponseUser[]> {
    const result = await this.userInfrastructure.getUserAPI(request);
    return result;
  }

  public async createUser(request: RequestUser): Promise<ResponseUser> {
    const result = await this.userInfrastructure.createUserAPI(request);
    return result;
  }

  public async updateUser(request: RequestUser): Promise<ResponseUser> {
    const result = await this.userInfrastructure.updateUserAPI(request);
    return result;
  }

  public async RecoveryPassword(request: RequestUser): Promise<ResponseUser> {
    const result = await this.userInfrastructure.recoveryPasswordAPI(request);
    return result;
  }

  public async DeleteCodeValidation(request: RequestUser): Promise<ResponseUser> {
    const result = await this.userInfrastructure.updateUserAPI(request);
    return result;
  }

  public async updatePassword(request: RequestUser): Promise<ResponseUser> {
    const result = await this.userInfrastructure.updatePasswordAPI(request);
    return result;
  }

  public async loggedUpdatePassword(request: RequestUser): Promise<ResponseUser> {
    const result = await this.userInfrastructure.loggedUpdatePasswordAPI(request);
    return result;
  }

  public async updateRoleToUser(request: RequestUser): Promise<ResponseUser> {
    const result = await this.userInfrastructure.updateRoleToUserAPI(request);
    return result;
  }

  public async deleteUser(request: RequestUser): Promise<ResponseUser> {
    const result = await this.userInfrastructure.deleteUserAPI(request);
    return result;
  }

  public async getByIdUser(request: RequestUser): Promise<ResponseUser> {
    const result = await this.userInfrastructure.getByIdUserAPI(request);
    return result;
  }

  public async userLogin(request: RequestUser): Promise<ResponseUser> {
    const result = await this.userInfrastructure.userLoginAPI(request);
    return result;
  }

  public async getUsersByRole(request: RequestUser): Promise<ResponseUser[]> {
    const result = await this.userInfrastructure.getUsersByRoleAPI(request);
    return result;
  }
}
