import { RequestAddress } from '../../../addresses/address/model/requestAddress';
import { RequestCustomerValidities } from '../../../customerValidities/model/customerValiditiesRequest';

export class RequestThirdParties {
  public idThirdParty? = 0;

  public idThirdPartyType? = 0;

  public idNature? = 0;

  public idDocumentType? = 0;

  public idGender? = 0;

  public identificationNumber? = 0;

  public businessName? = '';

  public firstName? = '';

  public middleName? = '';

  public firstSurname? = '';

  public secondSurname? = '';

  public email? = '';

  public cellPhone? = 0;

  public telephone?: number = 0;

  public idAddress? = 0;

  public address? = new RequestAddress();

  public customerValidity? = new RequestCustomerValidities();
}
