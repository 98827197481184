import { AxiosError } from 'axios';
import { ResponseUser } from '../../core/domain/userPermissions/user/model/responseUser';

export const actionsLoginBeneficiary = {
  LOGIN_BENEFICIARY_PAGE_BEGIN: 'LOGIN_BENEFICIARY_PAGE_BEGIN',
  LOGIN_BENEFICIARY_PAGE_SUCCESS: 'LOGIN_BENEFICIARY_PAGE_SUCCESS',
  LOGIN_BENEFICIARY_PAGE_ERR: 'LOGIN_BENEFICIARY_PAGE_ERR',

  loginPageBeneficiaryBegin: (): { type: string } => {
    return {
      type: actionsLoginBeneficiary.LOGIN_BENEFICIARY_PAGE_BEGIN,
    };
  },

  loginPageBeneficiarySuccess: (
    data: ResponseUser | undefined
  ): { type: string; data: ResponseUser | undefined } => {
    return {
      type: actionsLoginBeneficiary.LOGIN_BENEFICIARY_PAGE_SUCCESS,
      data,
    };
  },

  loginPageBeneficiaryErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsLoginBeneficiary.LOGIN_BENEFICIARY_PAGE_ERR,
      err,
    };
  },
};

export const actionsBeneficiaryLogout = {
  LOGOUT_BENEFICIARY_PAGE_BEGIN: 'LOGOUT_BENEFICIARY_PAGE_BEGIN',
  LOGOUT_BENEFICIARY_PAGE_SUCCESS: 'LOGOUT_BENEFICIARY_PAGE_SUCCESS',
  LOGOUT_BENEFICIARY_PAGE_ERR: 'LOGOUT_BENEFICIARY_PAGE_ERR',

  logoutBeneficiaryPageBegin: (): { type: string } => {
    return {
      type: actionsBeneficiaryLogout.LOGOUT_BENEFICIARY_PAGE_BEGIN,
    };
  },

  logoutBeneficiaryPageSuccess: (
    data: ResponseUser | undefined
  ): { type: string; data: ResponseUser | undefined } => {
    return {
      type: actionsBeneficiaryLogout.LOGOUT_BENEFICIARY_PAGE_SUCCESS,
      data,
    };
  },

  logoutBeneficiaryPageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsBeneficiaryLogout.LOGOUT_BENEFICIARY_PAGE_ERR,
      err,
    };
  },
};

export const actionsRefresh = {
  REFRESH_PAGE_BEGIN: 'REFRESH_PAGE_BEGIN',
  REFRESH_PAGE_SUCCESS: 'REFRESH_PAGE_SUCCESS',
  REFRESH_PAGE_ERR: 'REFRESH_PAGE_ERR',

  refreshPageBegin: (): { type: string } => {
    return {
      type: actionsRefresh.REFRESH_PAGE_BEGIN,
    };
  },

  refreshPageSuccess: (
    data: ResponseUser | undefined
  ): { type: string; data: ResponseUser | undefined } => {
    return {
      type: actionsRefresh.REFRESH_PAGE_SUCCESS,
      data,
    };
  },

  refreshPageErr: (err: AxiosError | undefined): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsRefresh.REFRESH_PAGE_ERR,
      err,
    };
  },
};

export const actionsUserRecoveryPassword = {
  USER_RECOVERY_PASSWORD_PAGE_BEGIN: 'USER_RECOVERY_PASSWORD_PAGE_BEGIN',
  USER_RECOVERY_PASSWORD_PAGE_SUCCESS: 'USER_RECOVERY_PASSWORD_PAGE_SUCCESS',
  USER_RECOVERY_PASSWORD_PAGE_ERR: 'USER_RECOVERY_PASSWORD_PAGE_ERR',

  userRecoveryPasswordPageBegin: (): { type: string } => {
    return {
      type: actionsUserRecoveryPassword.USER_RECOVERY_PASSWORD_PAGE_BEGIN,
    };
  },

  userRecoveryPasswordPageSuccess: (
    data: ResponseUser | undefined
  ): { type: string; data: ResponseUser | undefined } => {
    return {
      type: actionsUserRecoveryPassword.USER_RECOVERY_PASSWORD_PAGE_SUCCESS,
      data,
    };
  },

  userRecoveryPasswordPageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsUserRecoveryPassword.USER_RECOVERY_PASSWORD_PAGE_ERR,
      err,
    };
  },
};
