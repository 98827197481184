import { AxiosError } from 'axios';
import { ResponseUserSession } from '../../../core/domain/userPermissions/userSession/model/responseUserSession';

const actionsValidateUserSession = {
  VALIDATE_USER_SESSION_BEGIN: 'VALIDATE_USER_SESSION_BEGIN',
  VALIDATE_USER_SESSION_SUCCESS: 'VALIDATE_USER_SESSION_SUCCESS',
  VALIDATE_USER_SESSION_ERR: 'VALIDATE_USER_SESSION_ERR',

  validateUserSessionPageBegin: (): { type: string } => {
    return {
      type: actionsValidateUserSession.VALIDATE_USER_SESSION_BEGIN,
    };
  },

  validateUserSessionPageSuccess: (
    data?: ResponseUserSession
  ): {
    type: string;
    data?: ResponseUserSession;
  } => {
    return {
      type: actionsValidateUserSession.VALIDATE_USER_SESSION_SUCCESS,
      data,
    };
  },

  validateUserSessionPageErr: (err?: AxiosError): { type: string; err?: AxiosError } => {
    return {
      type: actionsValidateUserSession.VALIDATE_USER_SESSION_ERR,
      err,
    };
  },
};

const actionsDeleteUserSession = {
  DELETE_USER_SESSION_BEGIN: 'DELETE_USER_SESSION_BEGIN',
  DELETE_USER_SESSION_SUCCESS: 'DELETE_USER_SESSION_SUCCESS',
  DELETE_USER_SESSION_ERR: 'DELETE_USER_SESSION_ERR',
  DELETE_USER_SESSION_CLEAR: 'DELETE_USER_SESSION_CLEAR',

  deleteUserSessionPageBegin: (): { type: string } => {
    return {
      type: actionsDeleteUserSession.DELETE_USER_SESSION_BEGIN,
    };
  },

  deleteUserSessionPageSuccess: (
    data?: ResponseUserSession
  ): {
    type: string;
    data?: ResponseUserSession;
  } => {
    return {
      type: actionsDeleteUserSession.DELETE_USER_SESSION_SUCCESS,
      data,
    };
  },

  deleteUserSessionPageErr: (err?: AxiosError): { type: string; err?: AxiosError } => {
    return {
      type: actionsDeleteUserSession.DELETE_USER_SESSION_ERR,
      err,
    };
  },

  deleteUserSessionPageClear: (): {
    type: string;
    err?: AxiosError;
    data?: ResponseUserSession;
  } => {
    return {
      type: actionsDeleteUserSession.DELETE_USER_SESSION_CLEAR,
      err: undefined,
      data: undefined,
    };
  },
};

export { actionsValidateUserSession, actionsDeleteUserSession };
