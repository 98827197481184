import 'reflect-metadata';
import { AxiosResponse } from 'axios';
import { IRoleInfrastructure } from '../../../domain/interface/infrastructure/userPermissions/IRoleInfrastructure';
import { RequestRole } from '../../../domain/userPermissions/role/model/requestRole';
import { ResponseRole } from '../../../domain/userPermissions/role/model/responseRole';
import { processDataBackend } from '../../../../common/utils/functions';

export class RolesInfrastructure implements IRoleInfrastructure {
  private a = '';

  public async getRoleAPI(request: RequestRole): Promise<ResponseRole[]> {
    this.a = 'aaa';
    const res: AxiosResponse = await processDataBackend('GET', '/api/role/getAll', {}, { request });
    const Role: ResponseRole[] = res.data;
    return Promise.resolve(Role);
  }

  public async createRoleAPI(request: RequestRole): Promise<ResponseRole> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to create not found');
    const res: AxiosResponse = await processDataBackend(
      'POST',
      '/api/role/create',
      {},
      { request }
    );
    const result: ResponseRole = res.data;

    return Promise.resolve(result);
  }
}
