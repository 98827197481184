import 'reflect-metadata';
import { container } from 'tsyringe';
import { AxiosError } from 'axios';
import { IDocumentsTypesApplication } from '../../../core/domain/interface/application/documentsPersons/IDocumentsTypesApplication';
import { RequestDocumentsTypes } from '../../../core/domain/documentsPersons/documentTypes/model/requestDocumentsTypes';
import { actionsDocumentTypes } from './actions';
import { AppDispatch } from '../../store';

const { documentsTypesListPageBegin, documentsTypesListPageSuccess, documentsTypesListPageErr } =
  actionsDocumentTypes;
const documentsTypesListPage = (): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(documentsTypesListPageBegin());
      const documentsTypesApplication = container.resolve<IDocumentsTypesApplication>(
        'IDocumentsTypesApplication'
      );
      const request = new RequestDocumentsTypes();
      const result = await documentsTypesApplication.getDocumentsTypes(request);
      dispatch(documentsTypesListPageSuccess(result));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(documentsTypesListPageErr(error));
    }
  };
};
export { documentsTypesListPage };
