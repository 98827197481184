import React from 'react';
import { MembershipRegisterV } from '../../../components/membership';
import LayoutC from '../../../containers/Layout';

const MembershipRegisterP = () => {
  return (
    <LayoutC>
      <MembershipRegisterV />
    </LayoutC>
  );
};

export default MembershipRegisterP;
