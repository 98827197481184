import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { SendEmailV } from '../../../components/user/recoverPassword/sendEmail';
import { TDispatch, TodoSuccessResponse } from '../../../../../common/utils/types.d';
import { RootState } from '../../../../../redux/store';
import {
  clearUserRecoveryPasswordPage,
  userRecoveryPasswordPage,
} from '../../../../../redux/userPermissions/user/actionCreator';
import { TUserRecoveryPasswordReducer } from '../../../../../redux/userPermissions/user/types.d';

export const SendEmailC = () => {
  const navigate = useNavigate();

  /**
   * states
   */
  const [sentEmail, setSentEmail] = useState(false);
  const [validateView, setValidateView] = useState(false);
  const [watchEmail, setWatchEmail] = useState('');
  /**
   * Schema de validaciones de campos
   */
  const schema = yup
    .object({
      email: yup.string().required('Correo requerido').email('El correo electrónico no es válido'),
    })
    .required();

  /*
    dispatch
    */
  const dispatch = useDispatch();

  /**
   * Dispatch de userCreate
   */
  const userRecoveryPasswordDispatch: TUserRecoveryPasswordReducer = useSelector(
    (dataState: RootState) => dataState?.userRecoveryPassword
  );

  /**
   * useForm
   */
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  /**
   * Función de validar contraseñas
   */
  const handleRecoverPass = () => {
    setWatchEmail(watch('email'));

    dispatch(userRecoveryPasswordPage({ email: watch('email') }) as TDispatch);
    setValidateView(true);
  };

  /*
   * Función para retroceder.
   */
  const goBack = () => {
    navigate('/login');
  };

  /**
   * UseEffect pendiente
   */
  useEffect(() => {
    if (validateView) {
      if (
        userRecoveryPasswordDispatch?.userRecoveryPassword &&
        !userRecoveryPasswordDispatch?.error
      ) {
        setSentEmail(true);
        dispatch(clearUserRecoveryPasswordPage() as TDispatch);
        setValidateView(false);
      } else if (
        !userRecoveryPasswordDispatch?.userRecoveryPassword &&
        userRecoveryPasswordDispatch?.error
      ) {
        const message = userRecoveryPasswordDispatch?.error?.response
          ? (userRecoveryPasswordDispatch?.error?.response?.data as TodoSuccessResponse)?.message
          : 'Ha ocurrido una incidencia';
        toast.error(`${message}`, {
          hideProgressBar: true,
        });
        dispatch(clearUserRecoveryPasswordPage() as TDispatch);
        setValidateView(false);
      }
    }
  }, [userRecoveryPasswordDispatch, validateView, dispatch]);

  /**
   * UseEffect para llamar todos los dispatch
   */
  useEffect(() => {
    dispatch(clearUserRecoveryPasswordPage() as TDispatch);
  }, [dispatch]);

  return (
    <SendEmailV
      handleRecoverPass={handleRecoverPass}
      errors={errors}
      schema={schema}
      register={register}
      control={control}
      loading={userRecoveryPasswordDispatch?.loading}
      handleSubmit={handleSubmit}
      goBack={goBack}
      sentEmail={sentEmail}
      watchEmail={watchEmail}
    />
  );
};
