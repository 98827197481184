import { AxiosError } from 'axios';
import { ResponseTaxInfo } from '../../../core/domain/taxInformation/taxInfo/model/responseTaxInfo';

const actions = {
  TAX_INFO_LIST_PAGE_BEGIN: 'TAX_INFO_LIST_PAGE_BEGIN',
  TAX_INFO_PAGE_SUCCESS: 'TAX_INFO_LIST_PAGE_SUCCESS',
  TAX_INFO_LIST_PAGE_ERR: 'TAX_INFO_LIST_PAGE_ERR',

  taxInfoListPageBegin: (): { type: string } => {
    return {
      type: actions.TAX_INFO_LIST_PAGE_BEGIN,
    };
  },

  taxInfoListPageSuccess: (data: ResponseTaxInfo[]): { type: string; data: ResponseTaxInfo[] } => {
    return {
      type: actions.TAX_INFO_PAGE_SUCCESS,
      data,
    };
  },

  taxInfoListPageErr: (err: AxiosError): { type: string; err: AxiosError } => {
    return {
      type: actions.TAX_INFO_LIST_PAGE_ERR,
      err,
    };
  },
};

export default actions;
