import React from 'react';
import { Box, useTheme } from '@mui/material';
import { styleSheet } from './styled';
import { TLegendProps } from './types.d';

export const Legend = ({ noBorder, color, label, labelNumber }: TLegendProps) => {
  const theme = useTheme();
  const styles = styleSheet(theme);
  return (
    <Box sx={{ ...styles.contLegend, borderBottom: noBorder ? 'none' : '1px solid lightgray' }}>
      <Box sx={styles.contentLegend}>
        <Box sx={{ ...styles.contTextLegend, backgroundColor: color }} />
        <Box sx={styles.textoLegend}>{label}</Box>
      </Box>
      <Box>
        <Box sx={styles.dataLegend}>{labelNumber}</Box>
      </Box>
    </Box>
  );
};
