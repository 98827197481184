import { AxiosError } from 'axios';
import 'reflect-metadata';
import { container } from 'tsyringe';
import {
  actionsBenefitBeneficiaryCreate,
  actionsBenefitBeneficiaryGetById,
  actionsBenefitBeneficiaryValidateBenefit,
} from './actions';
import { AppDispatch } from '../store';
import { IBenefitBeneficiariesApplication } from '../../core/domain/interface/application/benefitBeneficiaries/IBenefitBeneficiariesApplication';
import { RequestBenefitBeneficiaries } from '../../core/domain/benefitBeneficiaries/model/requestBenefitBeneficiaries';
import { IBenefitBeneficiariesGetByIdApplication } from '../../core/domain/interface/application/benefitBeneficiaries/IBenefitBeneficiariesGetByIdApplication';
import { IValidateBenefitApplication } from '../../core/domain/interface/application/benefitBeneficiaries/IValidateBenefitApplication';

const {
  benefitBeneficiaryCreatePageBegin,
  benefitBeneficiaryCreatePageSuccess,
  benefitBeneficiaryCreatePageErr,
} = actionsBenefitBeneficiaryCreate;

const {
  benefitBeneficiaryGetByIdPageBegin,
  benefitBeneficiaryGetByIdPageSuccess,
  benefitBeneficiaryGetByIdPageErr,
} = actionsBenefitBeneficiaryGetById;

const {
  benefitBeneficiaryValidateBenefitPageBegin,
  benefitBeneficiaryValidateBenefitPageErr,
  benefitBeneficiaryValidateBenefitPageSuccess,
} = actionsBenefitBeneficiaryValidateBenefit;

export const createBenefitBeneficiaryPage = (
  listState: RequestBenefitBeneficiaries
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(benefitBeneficiaryCreatePageBegin());
      const benefitBeneficiariesApplication = container.resolve<IBenefitBeneficiariesApplication>(
        'IBenefitBeneficiariesApplication'
      );

      const resultCreate = await benefitBeneficiariesApplication.createBenefitBeneficiary(
        listState
      );
      dispatch(benefitBeneficiaryCreatePageSuccess(resultCreate));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(benefitBeneficiaryCreatePageErr(error));
    }
  };
};
export const getByIdBenefitBeneficiaryPage = (listState: {
  idBeneficiary: number;
  idThirdParty?: number;
}): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(benefitBeneficiaryGetByIdPageBegin());
      const benefitBeneficiariesGetByIdApplication =
        container.resolve<IBenefitBeneficiariesGetByIdApplication>(
          'IBenefitBeneficiariesGetByIdApplication'
        );
      const resultCreate = await benefitBeneficiariesGetByIdApplication.getByIdBenefitBeneficiary(
        listState
      );
      dispatch(benefitBeneficiaryGetByIdPageSuccess(resultCreate));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(benefitBeneficiaryGetByIdPageErr(error));
    }
  };
};
export const validateBenefitBenefitBeneficiaryPage = (listState: {
  idBenefitBeneficiary: number;
}): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(benefitBeneficiaryValidateBenefitPageBegin());
      const validateBenefitApplication = container.resolve<IValidateBenefitApplication>(
        'IValidateBenefitApplication'
      );
      const resultCreate = await validateBenefitApplication.validateBenefit(listState);
      dispatch(benefitBeneficiaryValidateBenefitPageSuccess(resultCreate));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(benefitBeneficiaryValidateBenefitPageErr(error));
    }
  };
};

export const clearCreateBenefitBeneficiaryPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(benefitBeneficiaryCreatePageBegin());
    dispatch(benefitBeneficiaryCreatePageSuccess(undefined));
    dispatch(benefitBeneficiaryCreatePageErr(undefined));
  };
};

export const clearGetByIdBenefitBeneficiaryPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(benefitBeneficiaryGetByIdPageBegin());
    dispatch(benefitBeneficiaryGetByIdPageSuccess(undefined));
    dispatch(benefitBeneficiaryGetByIdPageErr(undefined));
  };
};

export const clearValidateBenefitBenefitBeneficiaryPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(benefitBeneficiaryValidateBenefitPageBegin());
    dispatch(benefitBeneficiaryValidateBenefitPageSuccess(undefined));
    dispatch(benefitBeneficiaryValidateBenefitPageErr(undefined));
  };
};
