import { AxiosError } from 'axios';
import 'reflect-metadata';
import { container } from 'tsyringe';
import { AppDispatch } from '../store';
import { actionsLoginProvider, actionsProviderLogout, actionsRefresh } from './actions';
import { RequestLogin } from '../../core/domain/security/model/request/requestLogin';
import { IRefreshApplication } from '../../core/domain/interface/application/security/IRefreshApplication';
import { ILoginProviderApplication } from '../../core/domain/interface/application/security/ILoginProviderApplication';
import { ILogoutProviderApplication } from '../../core/domain/interface/application/security/ILogoutProviderApplication';

const { loginPageProviderBegin, loginPageProviderSuccess, loginPageProviderErr } =
  actionsLoginProvider;
const { logoutProviderPageBegin, logoutProviderPageSuccess, logoutProviderPageErr } =
  actionsProviderLogout;
const { refreshPageBegin, refreshPageSuccess, refreshPageErr } = actionsRefresh;

export const LoginProvider = (
  loginState: RequestLogin
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(loginPageProviderBegin());
      const loginApplication = container.resolve<ILoginProviderApplication>(
        'ILoginProviderApplication'
      );

      const resultLogin = await loginApplication.handle(loginState);
      dispatch(loginPageProviderSuccess(resultLogin));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(loginPageProviderErr(error));
    }
  };
};

export const LogoutProvider = (token: string): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(logoutProviderPageBegin());
      const logoutApplication = container.resolve<ILogoutProviderApplication>(
        'ILogoutProviderApplication'
      );

      const resultLogout = await logoutApplication.handle(token);
      dispatch(logoutProviderPageSuccess(resultLogout));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(logoutProviderPageErr(error));
    }
  };
};
export const RefreshUser = (
  loginState: RequestLogin
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(refreshPageBegin());
      const loginApplication = container.resolve<IRefreshApplication>('IRefreshApplication');

      const resultLogin = await loginApplication.handle(loginState);
      dispatch(refreshPageSuccess(resultLogin));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(refreshPageErr(error));
    }
  };
};

export const clearProviderLogin = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(loginPageProviderBegin());
    dispatch(loginPageProviderErr(undefined));
    dispatch(loginPageProviderSuccess(undefined));
  };
};
export const clearProviderLogout = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(logoutProviderPageBegin());
    dispatch(logoutProviderPageErr(undefined));
    dispatch(logoutProviderPageSuccess(undefined));
  };
};
export const clearRefresh = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(refreshPageBegin());
    dispatch(refreshPageErr(undefined));
    dispatch(refreshPageSuccess(undefined));
  };
};
