import { AxiosError } from 'axios';
import { ResponseRole } from '../../../core/domain/userPermissions/role/model/responseRole';
import { RoleState } from '../../../core/domain/userPermissions/role/state/roleState';
import { actionsRoleCreate, actionsRoles } from './actions';
import { TRoleCreateReducer, TRolesReducer } from './types.d';

const { ROLES_LIST_PAGE_BEGIN, ROLES_LIST_PAGE_SUCCESS, ROLES_LIST_PAGE_ERR } =
  actionsRoles;

const { ROLE_CREATE_PAGE_BEGIN, ROLE_CREATE_PAGE_SUCCESS, ROLE_CREATE_PAGE_ERR } =
  actionsRoleCreate;

const initialRole: RoleState = new RoleState();

const RolesReducer = (
  state = initialRole,
  action: { type: string; err: AxiosError; data: ResponseRole[] }
): TRolesReducer => {
  const { type, data, err } = action;
  switch (type) {
    case ROLES_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ROLES_LIST_PAGE_SUCCESS:
      return {
        ...state,
        roles: data,
        loading: false,
      };
    case ROLES_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const RoleCreateReducer = (
  state = initialRole,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseRole | undefined;
  }
): TRoleCreateReducer => {
  const { type, data, err } = action;
  switch (type) {
    case ROLE_CREATE_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ROLE_CREATE_PAGE_SUCCESS:
      return {
        ...state,
        roleCreated: data,
        loading: false,
      };
    case ROLE_CREATE_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { RolesReducer };
export { RoleCreateReducer };
