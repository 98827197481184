import { ResponseBenefitValidity } from '../model/responseBenefitValidity';

export class BenefitValidityState {
  public constructor() {
    this.benefitValidities = [new ResponseBenefitValidity()];
    this.benefitValidityCreated = new ResponseBenefitValidity();
  }

  public benefitValidities: ResponseBenefitValidity[];

  public benefitValidityCreated: ResponseBenefitValidity;
}
