import 'reflect-metadata';
import { container } from 'tsyringe';
import { AxiosError } from 'axios';
import { IDepartmentsApplication } from '../../../core/domain/interface/application/addresses/IDepartmentsApplication';
import { RequestDepartments } from '../../../core/domain/addresses/departments/model/requestDepartments';
import { AppDispatch } from '../../store';
import actions from './actions';

const { departmentsListPageBegin, departmentsListPageSuccess, departmentsListPageErr } = actions;

const departmentsListPage = (): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(departmentsListPageBegin());
      const departmentsApplication =
        container.resolve<IDepartmentsApplication>('IDepartmentsApplication');

      const request = new RequestDepartments();
      const result = await departmentsApplication.getDepartments(request);

      dispatch(departmentsListPageSuccess(result));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(departmentsListPageErr(error));
    }
  };
};

export { departmentsListPage };
