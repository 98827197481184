import 'reflect-metadata';
import { container } from 'tsyringe';
import { RequestLogin } from '../../domain/security/model/request/requestLogin';
import { ResponseUser } from '../../domain/userPermissions/user/model/responseUser';
import { ILoginBeneficiaryApplication } from '../../domain/interface/application/security/ILoginBeneficiaryApplication';
import { ILoginBeneficiaryInfrastructure } from '../../domain/interface/infrastructure/security/ILoginBeneficiaryInfrastructure';
import { RequestUser } from '../../domain/userPermissions/user/model/requestUser';

export class LoginBeneficiaryApplication implements ILoginBeneficiaryApplication {
  private loginBeneficiaryInfrastructure: ILoginBeneficiaryInfrastructure;

  public constructor() {
    this.loginBeneficiaryInfrastructure = container.resolve<ILoginBeneficiaryInfrastructure>(
      'ILoginBeneficiaryInfrastructure'
    );
  }

  public async handle(request: RequestLogin): Promise<ResponseUser> {
    const result = await this.loginBeneficiaryInfrastructure.handleAPI(request);
    return result;
  }

  public async RecoveryPassword(request: RequestUser): Promise<ResponseUser> {
    const result = await this.loginBeneficiaryInfrastructure.recoveryPasswordBeneficiaryAPI(
      request
    );
    return result;
  }
}
