import 'reflect-metadata';
import { container } from 'tsyringe';
import { RequestBeneficiaries } from '../../domain/beneficiaries/model/requestBeneficiaries';
import { ResponseBeneficiaries } from '../../domain/beneficiaries/model/responseBeneficiaries';
import { IBeneficiariesInfrastructure } from '../../domain/interface/infrastructure/beneficiaries/IBeneficiariesInfrastructure';
import { IBeneficiariesApplication } from '../../domain/interface/application/beneficiaries/IBeneficiariesApplication';

export class BeneficiariesApplication implements IBeneficiariesApplication {
  private beneficiariesInfrastructure: IBeneficiariesInfrastructure;

  public constructor() {
    this.beneficiariesInfrastructure = container.resolve<IBeneficiariesInfrastructure>(
      'IBeneficiariesInfrastructure'
    );
  }

  public async getBeneficiaries(request: RequestBeneficiaries): Promise<ResponseBeneficiaries[]> {
    const result = await this.beneficiariesInfrastructure.getBeneficiariesAPI(request);
    return result;
  }

  public async getBeneficiariesCustomers(
    request: RequestBeneficiaries
  ): Promise<ResponseBeneficiaries[]> {
    const result = await this.beneficiariesInfrastructure.getBeneficiariesCustomersAPI(request);
    return result;
  }

  public async getBeneficiaryById(request: RequestBeneficiaries): Promise<ResponseBeneficiaries> {
    const result = await this.beneficiariesInfrastructure.getBeneficiaryByIdAPI(request);
    return result;
  }

  public async createBeneficiary(request: RequestBeneficiaries): Promise<ResponseBeneficiaries> {
    const result = await this.beneficiariesInfrastructure.createBeneficiaryAPI(request);
    return result;
  }

  public async updateBeneficiary(request: RequestBeneficiaries): Promise<ResponseBeneficiaries> {
    const result = await this.beneficiariesInfrastructure.updateBeneficiaryAPI(request);
    return result;
  }

  public async deleteBeneficiary(request: RequestBeneficiaries): Promise<ResponseBeneficiaries> {
    const result = await this.beneficiariesInfrastructure.deleteBeneficiaryAPI(request);
    return result;
  }

  public async bulkCreateBeneficiary(
    request: RequestBeneficiaries[]
  ): Promise<ResponseBeneficiaries[]> {
    const result = await this.beneficiariesInfrastructure.bulkCreateBeneficiaryAPI(request);
    return result;
  }

  public async getBeneficiaryByBenefitById(
    identificationNumber: number
  ): Promise<ResponseBeneficiaries> {
    const result = await this.beneficiariesInfrastructure.getBeneficiaryForBenefitAPI(
      identificationNumber
    );
    return result;
  }
}
