import { FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import {
  DateAdapter,
  InputField,
  NormalSelectField,
} from '../../../../common/components/formAdapters';
import { constructorName } from '../../../../common/utils/functions';
import { TValidity } from './type';

export const Validity: FC<TValidity> = ({ schema, control, theme, dataSellers }) => {
  return (
    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
      <Box
        sx={{
          height: 'auto',
          width: '99%',
          background: '#fff',
          borderRadius: '16px',
          padding: '5px',
          boxShadow: '0px 3px 5px 0px #dcdcdc',
          marginTop: '20px',
        }}>
        <Box sx={{ padding: '10px', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ marginBottom: '15px' }}>
            <Typography
              variant="h5"
              color={theme.palette.primary.main}
              sx={{
                borderBottom: `1px solid ${theme.palette.primary.dark}`,
              }}>
              Vigencia
            </Typography>
          </Box>
          <Grid container spacing={{ xs: 2, sm: 2, md: 2 }} sx={{ marginBottom: '10px' }}>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <InputField
                name="customerValidity.acquiredBeneficiary"
                label="Beneficio adquirido"
                schema={schema?.fields?.customerValidity?.acquiredBeneficiary}
                control={control}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <InputField
                label="Beneficiario registrado"
                name="customerValidity.consumedBeneficiary"
                schema={schema.fields?.address?.customerValidity?.consumedBeneficiary}
                control={control}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <DateAdapter
                label="Fecha inicial"
                name="customerValidity.startDate"
                schema={schema.fields?.address?.customerValidity?.startDate}
                control={control}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <DateAdapter
                label="Fecha final"
                name="customerValidity.endDate"
                schema={schema.fields?.address?.customerValidity?.endDate}
                control={control}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <InputField
                label="Monto"
                name="customerValidity.amountPaid"
                schema={schema.fields?.customerValidity?.amountPaid}
                control={control}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <InputField
                label="Detalle"
                name="customerValidity.detail"
                schema={schema.fields?.customerValidity?.detail}
                control={control}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <NormalSelectField
                label="Vendedor"
                name="customerValidity.idSeller"
                schema={schema.fields?.customerValidity?.idSeller}
                control={control}
                disabled
                options={
                  (dataSellers || [])?.map((x) => ({
                    value: x?.idSeller,
                    label: constructorName([x?.firstName, x?.firstSurname]),
                  })) || []
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
