import { ResponseBranchsOffices } from '../model/branchsOfficesResponse';

export class BranchsOfficesState {
  public constructor() {
    this.branchsOffices = [new ResponseBranchsOffices()];
    this.branchOffice = new ResponseBranchsOffices();
    this.branchsOfficesCreated = [new ResponseBranchsOffices()];
    this.branchsOfficesUpdated = new ResponseBranchsOffices();
    this.branchsOfficesDeleted = new ResponseBranchsOffices();
  }

  public branchsOffices?: ResponseBranchsOffices[];

  public branchsOfficesCreated?: ResponseBranchsOffices[];

  public branchsOfficesUpdated?: ResponseBranchsOffices;

  public branchOffice?: ResponseBranchsOffices;

  public branchsOfficesDeleted?: ResponseBranchsOffices;
}
