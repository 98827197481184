import { AxiosError } from 'axios';
import { ResponseRole } from '../../../core/domain/userPermissions/role/model/responseRole';

const actionsRoles = {
  ROLES_LIST_PAGE_BEGIN: 'ROLES_LIST_PAGE_BEGIN',
  ROLES_LIST_PAGE_SUCCESS: 'ROLES_LIST_PAGE_SUCCESS',
  ROLES_LIST_PAGE_ERR: 'ROLES_LIST_PAGE_ERR',

  roleListPageBegin: (): { type: string } => {
    return {
      type: actionsRoles.ROLES_LIST_PAGE_BEGIN,
    };
  },

  roleListPageSuccess: (data: ResponseRole[]): { type: string; data: ResponseRole[] } => {
    return {
      type: actionsRoles.ROLES_LIST_PAGE_SUCCESS,
      data,
    };
  },

  roleListPageErr: (err: AxiosError): { type: string; err: AxiosError } => {
    return {
      type: actionsRoles.ROLES_LIST_PAGE_ERR,
      err,
    };
  },
};

const actionsRoleCreate = {
  ROLE_CREATE_PAGE_BEGIN: 'ROLE_CREATE_PAGE_BEGIN',
  ROLE_CREATE_PAGE_SUCCESS: 'ROLE_CREATE_PAGE_SUCCESS',
  ROLE_CREATE_PAGE_ERR: 'ROLE_CREATE_PAGE_ERR',

  roleCreatePageBegin: (): { type: string } => {
    return {
      type: actionsRoleCreate.ROLE_CREATE_PAGE_BEGIN,
    };
  },

  roleCreatePageSuccess: (
    data: ResponseRole | undefined
  ): { type: string; data: ResponseRole | undefined } => {
    return {
      type: actionsRoleCreate.ROLE_CREATE_PAGE_SUCCESS,
      data,
    };
  },

  roleCreatePageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsRoleCreate.ROLE_CREATE_PAGE_ERR,
      err,
    };
  },
};

export { actionsRoles, actionsRoleCreate };
