import * as yup from 'yup';

const schema = yup
  .object({
    startDate: yup
      .string()
      .required('Este campo es requerido')
      .test('is-date', 'Fecha inválida', (value) => !Number.isNaN(Date.parse(value)))
      .test('is-today-or-future', 'La fecha debe ser igual o mayor a la fecha actual', (value) => {
        const partesFecha = value.split('-').map(Number);
        const [day, month, year] = partesFecha;
        const inputDate = new Date(day, month - 1, year);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return inputDate >= today;
      }),
    endDate: yup
      .string()
      .required('Este campo es requerido')
      .test('is-date', 'Fecha inválida', (value) => !Number.isNaN(Date.parse(value)))
      .test(
        'is-greater',
        'La fecha final no debe ser menor o igual a la fecha inicial',
        (value, context) => {
          const startDates = context.parent.startDate;
          return new Date(startDates) < new Date(value);
        }
      ),
  })
  .required();

export default schema;
