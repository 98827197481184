import 'reflect-metadata';
import { container } from 'tsyringe';
import { CitiesInfrastructure } from './addresses/cities/citiesInfrastructure';
import { CountriesInfrastructure } from './addresses/countries/countriesInfrastructure';
import { DepartmentsInfrastructure } from './addresses/departments/departmentsInfrastructure';
import { StreetTypesInfrastructure } from './addresses/streetTypes/streetTypesInfraStructure';
import { BanksInfrastructure } from './banks/banks/banksInfrastructure';
import { BankAccountTypesInfrastructure } from './banks/bankAccountTypes/bankAccountTypes';
import { AcademicLevelsInfrastructure } from './documentsPersons/academicLevels/academicLevelsInfrastructure';
import { DocumentsTypesInfrastructure } from './documentsPersons/documentsTypes/documentsTypesInfrastructure';
import { GenderInfrastructure } from './documentsPersons/gender/genderInfrastructure';
import { MaritalStatusInfrastructure } from './documentsPersons/maritalStatus/maritalStatusInfrastructure';
import { ProfessionInfrastructure } from './workInformation/profession/professionInfrastructure';
import { TaxRegimeInfrastructure } from './taxInformation/taxRegime/taxRegimeInfrastructure';
import { EconomicActivitiesInfrastructure } from './taxInformation/economicActivities/economicActivitiesInfrastructure';
import { ResponsibilitiesInfrastructure } from './taxInformation/responsibilities/responsibilitiesInfrastructure';
import { TaxInfoInfrastructure } from './taxInformation/TaxInfo/taxInfoInfrastructure';
import { EconomicSectorsInfrastructure } from './taxInformation/economicSectors/economicSectorsInfrastructure';
import { UserInfrastructure } from './userPermissions/user/userInfrastructure';
import { UserPermissionInfrastructure } from './userPermissions/userPermission/userPermissionInfrastructure';
import { RolesInfrastructure } from './userPermissions/role/roleInfrastructure';
import { ModuleInfrastructure } from './userPermissions/module/moduleInfrastructure';
import { SubModuleInfrastructure } from './userPermissions/subModule/subModuleInfrastructure';
import { KinshipsInfrastructure } from './contacts/kinships/kinshipsInfrastructure';
import { UserTypesInfrastructure } from './userPermissions/userType/userTypeInfrastructure';
import { LoginInfrastructure } from './security/loginInfrastructure';
import { LogoutInfrastructure } from './security/logoutInfrastructure';
import { RefreshInfrastructure } from './security/refreshInfrastructure';
import { ThirdPartiesInfrastructure } from './thirdParties/thirdPartiesInfrastructure';
import { BenefitCategoriesInfrastructure } from './benefits/benefitCategory/benefitCategoryInfrastructure';
import { BenefitInfrastructure } from './benefits/benefits/benefitInfrastructure';
import { BenefitValidityInfrastructure } from './benefits/benefitValidity/benefitValidityInfrastructure';
import { SellerInfrastructure } from './sellers/sellerInfrastructure';
import { BeneficiariesInfrastructure } from './beneficiaries/beneficiariesInfrastructure';
import { ThirdPartiesBeneficiariesInfrastructure } from './thirdParties/thirdPartiesBeneficiariesInfrastructure';
import { BenefitBeneficiariesInfrastructure } from './benefitBeneficiaries/benefitBeneficiariesInfrastructure';
import { LoginProviderInfrastructure } from './security/loginProviderInfrastructure';
import { LoginBeneficiaryInfrastructure } from './security/loginBeneficiaryInfrastructure';
import { BranchsOfficesInfrastructure } from './branchsOffices/branchsOfficesInfrastructure';
import { UserSessionInfrastructure } from './userPermissions/userSession/userSessionInfrastructure';
import { ConsecutiveInfrastructure } from './consecutive/consecutiveInfrastructure';

/**
 * @class DependencyInjectionInfrastructure
 */
export class DependencyInjectionInfrastructure {
  /**
   * @static DependencyInjectionInfrastructure
   */
  public static DependencyInjectionStartup(): void {
    container.register('ILoginInfrastructure', {
      useClass: LoginInfrastructure,
    });
    container.register('ILoginProviderInfrastructure', {
      useClass: LoginProviderInfrastructure,
    });
    container.register('ILoginBeneficiaryInfrastructure', {
      useClass: LoginBeneficiaryInfrastructure,
    });
    container.register('ILogoutInfrastructure', {
      useClass: LogoutInfrastructure,
    });
    container.register('IRefreshInfrastructure', {
      useClass: RefreshInfrastructure,
    });
    container.register('IProfessionInfrastructure', {
      useClass: ProfessionInfrastructure,
    });
    container.register('IEconomicSectorsInfrastructure', {
      useClass: EconomicSectorsInfrastructure,
    });
    container.register('ITaxRegimeInfrastructure', {
      useClass: TaxRegimeInfrastructure,
    });
    container.register('IEconomicActivitiesInfrastructure', {
      useClass: EconomicActivitiesInfrastructure,
    });
    container.register('IResponsibilitiesInfrastructure', {
      useClass: ResponsibilitiesInfrastructure,
    });
    container.register('ITaxInfoInfrastructure', {
      useClass: TaxInfoInfrastructure,
    });
    container.register('ICitiesInfrastructure', {
      useClass: CitiesInfrastructure,
    });
    container.register('ICountriesInfrastructure', {
      useClass: CountriesInfrastructure,
    });
    container.register('IDepartmentsInfrastructure', {
      useClass: DepartmentsInfrastructure,
    });
    container.register('IStreetTypesInfrastructure', {
      useClass: StreetTypesInfrastructure,
    });
    container.register('IBanksInfrastructure', {
      useClass: BanksInfrastructure,
    });
    container.register('IBankAccountTypesInfrastructure', {
      useClass: BankAccountTypesInfrastructure,
    });
    container.register('IKinshipsInfrastructure', {
      useClass: KinshipsInfrastructure,
    });
    container.register('IAcademicLevelsInfrastructure', {
      useClass: AcademicLevelsInfrastructure,
    });
    container.register('IDocumentsTypesInfrastructure', {
      useClass: DocumentsTypesInfrastructure,
    });
    container.register('IGenderInfrastructure', {
      useClass: GenderInfrastructure,
    });
    container.register('IMaritalStatusInfrastructure', {
      useClass: MaritalStatusInfrastructure,
    });
    container.register('IUserInfrastructure', {
      useClass: UserInfrastructure,
    });
    container.register('IRoleInfrastructure', {
      useClass: RolesInfrastructure,
    });
    container.register('IUserPermissionInfrastructure', {
      useClass: UserPermissionInfrastructure,
    });
    container.register('IModuleInfrastructure', {
      useClass: ModuleInfrastructure,
    });
    container.register('ISubModuleInfrastructure', {
      useClass: SubModuleInfrastructure,
    });
    container.register('IUserTypeInfrastructure', {
      useClass: UserTypesInfrastructure,
    });
    container.register('IThirdPartiesInfrastructure', {
      useClass: ThirdPartiesInfrastructure,
    });
    container.register('IBenefitCategoryInfrastructure', {
      useClass: BenefitCategoriesInfrastructure,
    });
    container.register('IBenefitInfrastructure', {
      useClass: BenefitInfrastructure,
    });
    container.register('IBenefitValidityInfrastructure', {
      useClass: BenefitValidityInfrastructure,
    });
    container.register('ISellerInfrastructure', {
      useClass: SellerInfrastructure,
    });
    container.register('IBeneficiariesInfrastructure', {
      useClass: BeneficiariesInfrastructure,
    });
    container.register('IThirdPartiesBeneficiariesInfrastructure', {
      useClass: ThirdPartiesBeneficiariesInfrastructure,
    });
    container.register('IBenefitBeneficiariesInfrastructure', {
      useClass: BenefitBeneficiariesInfrastructure,
    });
    container.register('IBranchsOfficesInfrastructure', {
      useClass: BranchsOfficesInfrastructure,
    });
    container.register('IUserSessionInfrastructure', {
      useClass: UserSessionInfrastructure,
    });
    container.register('IConsecutiveInfrastructure', {
      useClass: ConsecutiveInfrastructure,
    });
  }
}
