import { container } from 'tsyringe';
import { RequestBranchsOffices } from '../../domain/branchsOffices/model/branchsOfficesRequest';
import { ResponseBranchsOffices } from '../../domain/branchsOffices/model/branchsOfficesResponse';
import { IBranchsOfficesApplication } from '../../domain/interface/application/branchsOffices/IBranchsOfficesApplication';
import { IBranchsOfficesInfrastructure } from '../../domain/interface/infrastructure/branchsOffices/IBranchsOfficesInfrastructure';

export class BranchsOfficesApplication implements IBranchsOfficesApplication {
  private branchsOfficesInfrastructure: IBranchsOfficesInfrastructure;

  public constructor() {
    this.branchsOfficesInfrastructure = container.resolve<IBranchsOfficesInfrastructure>(
      'IBranchsOfficesInfrastructure'
    );
  }

  public async getBranchsOffices(
    request: RequestBranchsOffices
  ): Promise<ResponseBranchsOffices[]> {
    const result = await this.branchsOfficesInfrastructure.getBranchsOfficesAPI(request);
    return result;
  }

  public async getBranchOfficeById(
    request: RequestBranchsOffices
  ): Promise<ResponseBranchsOffices> {
    const result = await this.branchsOfficesInfrastructure.getBranchOfficeByIdAPI(request);
    return result;
  }

  public async createBranchsOffices(
    request: RequestBranchsOffices[]
  ): Promise<ResponseBranchsOffices[]> {
    const result = await this.branchsOfficesInfrastructure.createBranchsOfficesAPI(request);
    return result;
  }

  public async updateBranchsOffices(
    request: RequestBranchsOffices
  ): Promise<ResponseBranchsOffices> {
    const result = await this.branchsOfficesInfrastructure.updateBranchsOfficesAPI(request);
    return result;
  }

  public async deleteBranchsOffices(
    request: RequestBranchsOffices
  ): Promise<ResponseBranchsOffices> {
    const result = await this.branchsOfficesInfrastructure.deleteBranchsOfficesAPI(request);
    return result;
  }
}
