import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ProviderDashboardV } from '../../../components/providers/providerDashboard';
import { Context } from '../../../../../common/utils/userContext';
import { RootState } from '../../../../../redux/store';
import {
  TGetByIdBenefitBeneficiaryReducer,
  TValidateBenefitBeneficiaryReducer,
} from '../../../../../redux/benefitBeneficiaries/types.d';
import {
  clearGetByIdBenefitBeneficiaryPage,
  clearValidateBenefitBenefitBeneficiaryPage,
  getByIdBenefitBeneficiaryPage,
  validateBenefitBenefitBeneficiaryPage,
} from '../../../../../redux/benefitBeneficiaries/actionCreator';
import { TDispatch } from '../../../../../common/utils/types.d';
import { TLoginDispatch } from '../../login/types';
import { getFileUrlS3 } from '../../../../../lib';
import { IDataBenefit } from '../../../components/providers/providerDashboard/types.d';
import { TMessage } from '../login/types.d';

export const ProviderDashboardC = () => {
  const [dataBenefit, setDataBenefit] = useState<any>([]);
  const [detailsCouponsModal, setDetailsCouponsModal] = useState<any>();
  const [disabled, setDisabled] = useState<boolean>(false);
  const { handleSessionProviderPage } = useContext(Context);
  /**
   * capturar id del beneficiario
   */
  const { id } = useParams();

  const history = useNavigate();
  /*
    dispatch
    */
  const dispatch = useDispatch();
  /**
   * Dispatch de BenefitBeneficiariesGetById
   */
  const BenefitBeneficiariesGetByIdDispatch: TGetByIdBenefitBeneficiaryReducer = useSelector(
    (dataState: RootState) => dataState?.benefitBeneficiariesGetById
  );

  /**
   * Dispatch de BenefitBeneficiariesValidate
   */
  const BenefitBeneficiaryValidateDispatch: TValidateBenefitBeneficiaryReducer = useSelector(
    (dataState: RootState) => dataState?.benefitBeneficiaryValidate
  );

  /**
   * Dispatch de BenefitBeneficiariesGetById
   */
  const LoginDispatch: TLoginDispatch = useSelector(
    (dataState: RootState) => dataState?.providerLoggedIn
  );

  const benefit = BenefitBeneficiariesGetByIdDispatch?.benefitBeneficiaryById?.map((x) => {
    return {
      idBenefit: x?.benefit?.idBenefit,
      name: x?.benefit?.name,
      consumed: x?.consumed?.toString(),
      description: x?.benefit?.description,
      img: x?.benefit?.path,
      idBenefitBeneficiary: x.idBenefitBeneficiary,
    };
  });

  /*
   * Función para asignar imagen a la path por cada objeto.
   */
  const processBenefitsData = (benefits: any) => {
    return benefits.map((x: IDataBenefit) => {
      const img: string = x?.img || '';
      const result: string = getFileUrlS3(`benefits/${img}`);
      return { ...x, img: result };
    });
  };
  // /**
  //  * UseEffect de verificación de la data a mapear
  //  */

  const acceptedBenefit = (x: number) => {
    dispatch(validateBenefitBenefitBeneficiaryPage({ idBenefitBeneficiary: x }) as TDispatch);
    setDisabled(true);
  };

  useEffect(() => {
    if (
      !(BenefitBeneficiaryValidateDispatch?.error?.response?.data as TMessage)?.message &&
      BenefitBeneficiaryValidateDispatch?.benefitBeneficiaryValidate?.idBenefitBeneficiary
    ) {
      toast.success('Has adquirido el beneficio exitosamente', {
        hideProgressBar: true,
      });
      dispatch(clearValidateBenefitBenefitBeneficiaryPage() as TDispatch);
      dispatch(
        getByIdBenefitBeneficiaryPage({
          idBeneficiary: Number(id),
          idThirdParty:
            LoginDispatch?.login?.idThirdParty || Number(localStorage.getItem('vs3-id')),
        }) as TDispatch
      );
      setDisabled(false);
      history(`/comercio/user/dashboard/${id}`);
    } else if ((BenefitBeneficiaryValidateDispatch?.error?.response?.data as TMessage)?.message) {
      const message = BenefitBeneficiaryValidateDispatch?.error?.response
        ? (BenefitBeneficiaryValidateDispatch?.error?.response?.data as TMessage)?.message
        : 'Ha ocurrido una incidencia.';
      toast.error(`${message}`, {
        hideProgressBar: true,
      });
      dispatch(clearValidateBenefitBenefitBeneficiaryPage() as TDispatch);
      dispatch(
        getByIdBenefitBeneficiaryPage({
          idBeneficiary: Number(id),
          idThirdParty:
            LoginDispatch?.login?.idThirdParty || Number(localStorage.getItem('vs3-id')),
        }) as TDispatch
      );
      setDisabled(false);
      history(`/comercio/user/dashboard/${id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    BenefitBeneficiaryValidateDispatch?.benefitBeneficiaryValidate?.idBenefitBeneficiary,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    (BenefitBeneficiaryValidateDispatch?.error?.response?.data as TMessage)?.message,
  ]);

  /**
   * UseEffect de verificación de la data a mapear
   */
  useEffect(() => {
    if (
      BenefitBeneficiariesGetByIdDispatch?.benefitBeneficiaryById?.length &&
      !BenefitBeneficiariesGetByIdDispatch?.loading
    ) {
      const processedData = processBenefitsData(benefit);
      setDataBenefit(processedData);
    } else if (
      !BenefitBeneficiariesGetByIdDispatch?.benefitBeneficiaryById?.length &&
      !BenefitBeneficiariesGetByIdDispatch?.loading
    ) {
      setDataBenefit([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    BenefitBeneficiariesGetByIdDispatch?.benefitBeneficiaryById,
    BenefitBeneficiariesGetByIdDispatch?.loading,
  ]);

  /*
   * Función para buscar por idBenefit en dataRandom.
   */
  const getItemByNumber = (x: number | undefined) => {
    return benefit?.find((item) => item?.idBenefit === x);
  };

  /**
   * Función que captura el idBenefit y devuelve objeto a su respectivo modal detalle.
   */
  const handleImage = (x: number | undefined) => {
    if (x !== null) {
      setDetailsCouponsModal(getItemByNumber(x));
    }
  };

  /**
   * UseEffect para llamar todos los dispatch
   */
  useEffect(() => {
    dispatch(clearGetByIdBenefitBeneficiaryPage() as TDispatch);
    dispatch(
      getByIdBenefitBeneficiaryPage({
        idBeneficiary: Number(id),
        idThirdParty: LoginDispatch?.login?.idThirdParty || Number(localStorage.getItem('vs3-id')),
      }) as TDispatch
    );
  }, [id, dispatch, LoginDispatch]);
  return (
    <ProviderDashboardV
      id={id}
      dataBenefit={dataBenefit}
      handleImage={handleImage}
      detailsCouponsModal={detailsCouponsModal}
      handleSession={handleSessionProviderPage}
      acceptedBenefit={acceptedBenefit}
      disabled={disabled}
      dataDisabled={BenefitBeneficiariesGetByIdDispatch?.loading}
    />
  );
};
