import styled from 'styled-components';
import { TStyle } from './types.d';
import image from '../../../../../common/components/images';

const tramaFondoLogin = image('tramaFondoLogin');

export const styles: TStyle = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
    alignItems: 'center',
    backgroundColor: '#001f46',
    '&::before': {
      content: '""',
      backgroundImage: `url(${tramaFondoLogin})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity: 0.1,
      pointerEvents: 'none',
    },
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    texAlign: 'center',
  },
  boxesContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    height: '60%',
    width: '20%',
    minWidth: '425px',
    backgroundColor: '#fff',
    borderRadius: '5px',
    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);',
    '@media screen and (max-width: 425px)': {
      width: '425px',
      minWidth: '425px',
      height: '100%',
    },
    '@media screen and (max-width: 375px)': {
      width: '375px',
      minWidth: '375px',
      height: '100%',
    },
    '@media screen and (max-width: 320px)': {
      width: '320px',
      minWidth: '320px',
      height: '100%',
    },
  },
};

export const Img = styled.img`
  height: 80px;
  width: 200px;
`;

export const Logo = styled.img`
  width: 200px;
  height: 100px;
  opacity: 1;
  @media (max-width: 997px) {
    max-width: 330px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 375px;
  height: 100%;
  @media (max-height: 69%) {
    gap: 15px;
  }
  @media (max-width: 425px) {
    width: 375px;
  }
  @media (max-width: 375px) {
    width: 320px;
  }
  @media (max-width: 320px) {
    width: 280px;
  }
`;

export const TextFloating = styled.span`
  position: fixed;
  bottom: 10px;
  z-index: 1600;
  right: 5px;
  width: 300px;
  color: #fff;
  @media (max-width: 425px) {
    left: 50%;
    transform: translateX(-50%);
  }
`;
export const TramaFloating = styled.img`
  position: fixed;
  bottom: 0px;
  z-index: 1600;
  left: 0px;
  width: 100%;
  opacity: 0.06;
`;
export const ButtonSubmit = styled.button`
  width: 148px;
  height: 35px;
  background-color: #001f46;
  justify-content: center;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
`;
export const ButtonPassword = styled.button`
  width: 200px;
  /* height: 25px; */
  background-color: transparent;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;
