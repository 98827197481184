import { ResponseBenefitBeneficiaries } from '../model/responseBenefitBeneficiaries';

export class BenefitBeneficiariesState {
  public constructor() {
    this.benefitBeneficiaryCreated = new ResponseBenefitBeneficiaries();
    this.benefitBeneficiaryValidate = new ResponseBenefitBeneficiaries();
    this.benefitBeneficiaryById = [new ResponseBenefitBeneficiaries()];
  }

  public benefitBeneficiaryCreated?: ResponseBenefitBeneficiaries;

  public benefitBeneficiaryValidate?: ResponseBenefitBeneficiaries;

  public benefitBeneficiaryById?: ResponseBenefitBeneficiaries[];
}
