import { Box, CircularProgress, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import pkg from '../../../../../../package.json';
import { ButtonSubmit, Form, Logo, TextFloating, styles } from './styled';
import { InputField } from '../../../../../common/components/formAdapters';
import { IPropsLogin } from './types.d';
import image from '../../../../../common/components/images';

export const UserRecoverPassV: React.FC<IPropsLogin> = ({
  handleSubmit,
  handleRecoverPass,
  loading,
  control,
  schema,
  validationErrors,
}) => {
  const theme = useTheme();
  const LogoBonus = image('logoBonus');

  return (
    <Grid container sx={{...styles.container,backgroundColor: theme.palette.primary.main}}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
          alignItems: 'center',
          position: 'relative',
        }}>
        <Box sx={styles.logoContainer}>
          <Logo src={LogoBonus} />
        </Box>
        <Box sx={styles.formContainer}>
          <Form>
            <Typography
              variant="h4"
              sx={{ fontFamily: 'Poppins-Regular', fontWeight: '600' }}
              color={theme.palette.primary.main}>
              Crea nueva contraseña
            </Typography>
            <InputField
              name="password"
              control={control}
              label="Contraseña"
              type="password"
              schema={schema.fields.password}
              hideHelperText
              iconType="showPasswordEye"
            />
            <InputField
              control={control}
              label="Confirmar contraseña"
              name="confirmPass"
              type="password"
              schema={schema.fields.confirmPass}
              hideHelperText
              iconType="showPasswordEye"
            />
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
              }}>
              {validationErrors?.map((x: string) => {
                return (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: '2px',
                    }}
                    key={x}>
                    <HighlightOffIcon
                      sx={{ color: '#F23520', marginRight: '8px', fontSize: '18px' }}
                    />
                    <Typography sx={{ color: '#707070' }} fontSize="12px">
                      {x}
                    </Typography>
                  </Box>
                );
              })}
            </Box>

            <ButtonSubmit
              type="button"
              hoverStyles={theme.palette.primary.dark}
              backgroundColor={theme.palette.primary.main}
              onClick={handleSubmit(handleRecoverPass)}>
              {!loading ? 'Establecer' : <CircularProgress size={25} sx={{ color: 'white' }} />}
            </ButtonSubmit>
          </Form>
        </Box>
      </Box>
      <TextFloating>
        <Typography
          color={theme.palette.primary.main}
          sx={{
            fontSize: '11px',
            padding: '5px',
            width: '100%',
            textAlign: 'center',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            color: '#fff',
          }}>
          ® WOW Desarrollos Digitales - Bonus plus {pkg.version}
        </Typography>
      </TextFloating>
    </Grid>
  );
};
