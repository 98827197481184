import { AxiosError } from 'axios';
import { ResponseMaritalStatus } from '../../../core/domain/documentsPersons/maritalStatus/model/responseMaritalStatus';

const actions = {
  MARITAL_STATUS_LIST_PAGE_BEGIN: ' MARITAL_STATUS_LIST_PAGE_BEGIN',
  MARITAL_STATUS_LIST_PAGE_SUCCESS: ' MARITAL_STATUS_LIST_PAGE_SUCCESS',
  MARITAL_STATUS_LIST_PAGE_ERR: ' MARITAL_STATUS_LIST_PAGE_ERR',

  maritalStatusListPageBegin: (): { type: string } => {
    return {
      type: actions.MARITAL_STATUS_LIST_PAGE_BEGIN,
    };
  },

  maritalStatusListPageSuccess: (
    data: ResponseMaritalStatus[]
  ): { type: string; data: ResponseMaritalStatus[] } => {
    return {
      type: actions.MARITAL_STATUS_LIST_PAGE_SUCCESS,
      data,
    };
  },

  maritalStatusListPageErr: (err: AxiosError): { type: string; err: AxiosError } => {
    return {
      type: actions.MARITAL_STATUS_LIST_PAGE_ERR,
      err,
    };
  },
};

export default actions;
