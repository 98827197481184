import 'reflect-metadata';
import { container } from 'tsyringe';
import { IBenefitBeneficiariesInfrastructure } from '../../domain/interface/infrastructure/benefitBeneficiaries/IBenefitBeneficiariesInfrastructure';
import { IBenefitBeneficiariesGetByIdApplication } from '../../domain/interface/application/benefitBeneficiaries/IBenefitBeneficiariesGetByIdApplication';
import { ResponseBenefitBeneficiaries } from '../../domain/benefitBeneficiaries/model/responseBenefitBeneficiaries';
import { RequestBenefitBeneficiaries } from '../../domain/benefitBeneficiaries/model/requestBenefitBeneficiaries';

export class BenefitBeneficiariesGetByIdApplication
  implements IBenefitBeneficiariesGetByIdApplication
{
  private benefitBeneficiariesGetByIdInfrastructure: IBenefitBeneficiariesInfrastructure;

  public constructor() {
    this.benefitBeneficiariesGetByIdInfrastructure =
      container.resolve<IBenefitBeneficiariesInfrastructure>('IBenefitBeneficiariesInfrastructure');
  }

  public async getByIdBenefitBeneficiary(
    request: RequestBenefitBeneficiaries
  ): Promise<ResponseBenefitBeneficiaries[]> {
    const result =
      await this.benefitBeneficiariesGetByIdInfrastructure.getByIdBenefitBeneficiaryAPI(request);
    return result;
  }
}
