export const newDataMenus = [
  {
    id: 1,
    name: 'CONTABILIDAD',
    route: '/',
    subMenus: [
      {
        id: 11,
        name: 'Parámetros',
        route: '/',
        categories: [
          {
            id: 111,
            name: 'TABLAS GENERALES',
            options: [
              {
                id: 1111,
                name: 'Parámetros generales',
                route: '/',
              },
              {
                id: 1112,
                name: 'Centro de costos',
                route: '/',
              },
              {
                id: 1113,
                name: 'Consecutivos',
                route: '/',
              },
            ],
          },
          {
            id: 112,
            name: 'PLAN DE CUENTAS',
            options: [
              {
                id: 1121,
                name: 'Auxiliares contables',
                route: '/',
              },
              {
                id: 1122,
                name: 'Conceptos DIAN y medios magnéticos',
                route: '/',
              },
              {
                id: 1123,
                name: 'Formatos de la DIAN',
                route: '/',
              },
            ],
          },
        ],
      },
      {
        id: 12,
        name: 'Procesar',
        route: '/',
        categories: [
          {
            id: 121,
            name: 'MOVIMIENTOS',
            options: [
              {
                id: 1211,
                name: 'Documentos contables',
                route: '/',
              },
              {
                id: 1212,
                name: 'Cambios de Nits',
                route: '/',
              },
              {
                id: 1213,
                name: 'Cambios de Auxiliares',
                route: '/',
              },
            ],
          },
          {
            id: 122,
            name: 'PROCESOS AUTOMÁTICOS',
            options: [
              {
                id: 1221,
                name: 'Procesos automáticos 1',
                route: '/',
              },
              {
                id: 1222,
                name: 'Procesos automáticos 2',
                route: '/',
              },
              {
                id: 1223,
                name: 'Procesos automáticos 3',
                route: '/',
              },
            ],
          },
          {
            id: 122,
            name: 'PROCESOS AUTOMÁTICOS',
            options: [
              {
                id: 1221,
                name: 'Procesos automáticos 1',
                route: '/',
              },
              {
                id: 1222,
                name: 'Procesos automáticos 2',
                route: '/',
              },
              {
                id: 1223,
                name: 'Procesos automáticos 3',
                route: '/',
              },
            ],
          },
          {
            id: 122,
            name: 'PROCESOS AUTOMÁTICOS',
            options: [
              {
                id: 1221,
                name: 'Procesos automáticos 1',
                route: '/',
              },
              {
                id: 1222,
                name: 'Procesos automáticos 2',
                route: '/',
              },
              {
                id: 1223,
                name: 'Procesos automáticos 3',
                route: '/',
              },
            ],
          },
          {
            id: 122,
            name: 'PROCESOS AUTOMÁTICOS',
            options: [
              {
                id: 1221,
                name: 'Procesos automáticos 1',
                route: '/',
              },
              {
                id: 1222,
                name: 'Procesos automáticos 2',
                route: '/',
              },
              {
                id: 1223,
                name: 'Procesos automáticos 3',
                route: '/',
              },
            ],
          },
          {
            id: 122,
            name: 'PROCESOS AUTOMÁTICOS',
            options: [
              {
                id: 1221,
                name: 'Procesos automáticos 1',
                route: '/',
              },
              {
                id: 1222,
                name: 'Procesos automáticos 2',
                route: '/',
              },
              {
                id: 1223,
                name: 'Procesos automáticos 3',
                route: '/',
              },
            ],
          },
          {
            id: 122,
            name: 'PROCESOS AUTOMÁTICOS',
            options: [
              {
                id: 1221,
                name: 'Procesos automáticos 1',
                route: '/',
              },
              {
                id: 1222,
                name: 'Procesos automáticos 2',
                route: '/',
              },
              {
                id: 1223,
                name: 'Procesos automáticos 3',
                route: '/',
              },
            ],
          },
          {
            id: 122,
            name: 'PROCESOS AUTOMÁTICOS',
            options: [
              {
                id: 1221,
                name: 'Procesos automáticos 1',
                route: '/',
              },
              {
                id: 1222,
                name: 'Procesos automáticos 2',
                route: '/',
              },
              {
                id: 1223,
                name: 'Procesos automáticos 3',
                route: '/',
              },
            ],
          },
        ],
      },
      {
        id: 13,
        name: 'Informes',
        route: '/',
        categories: [
          {
            id: 131,
            name: 'MOVIMIENTOS',
            options: [
              {
                id: 1311,
                name: 'Documentos contables',
                route: '/',
              },
              {
                id: 1312,
                name: 'Cambios de Nits',
                route: '/',
              },
              {
                id: 1313,
                name: 'Cambios de Auxiliares',
                route: '/',
              },
            ],
          },
          {
            id: 132,
            name: 'PROCESOS AUTOMÁTICOS',
            options: [
              {
                id: 1321,
                name: 'Procesos automáticos 1',
                route: '/',
              },
              {
                id: 1322,
                name: 'Procesos automáticos 2',
                route: '/',
              },
              {
                id: 1323,
                name: 'Procesos automáticos 3',
                route: '/',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name: 'TALENTO HUMANO',
    route: '/',
    subMenus: [
      {
        id: 21,
        name: 'Parámetros',
        route: '/',
        categories: [
          {
            id: 211,
            name: 'Tablas generales',
            options: [
              {
                id: 2111,
                name: 'Parámetros generales',
                route: '/',
              },
              {
                id: 2112,
                name: 'Centro de costos',
                route: '/',
              },
              {
                id: 2113,
                name: 'Consecutivos',
                route: '/',
              },
            ],
          },
          {
            id: 212,
            name: 'Plan de cuentas',
            options: [
              {
                id: 2121,
                name: 'Auxiliares contables',
                route: '/',
              },
              {
                id: 2122,
                name: 'Conceptos DIAN y medios magnéticos',
                route: '/',
              },
              {
                id: 2123,
                name: 'Formatos de la DIAN',
                route: '/',
              },
            ],
          },
        ],
      },
      {
        id: 22,
        name: 'Procesar',
        route: '/',
        categories: [
          {
            id: 221,
            name: 'MOVIMIENTOS',
            options: [
              {
                id: 2211,
                name: 'Documentos contables',
                route: '/',
              },
              {
                id: 2212,
                name: 'Cambios de Nits',
                route: '/',
              },
              {
                id: 2213,
                name: 'Cambios de Auxiliares',
                route: '/',
              },
            ],
          },
          {
            id: 222,
            name: 'PROCESOS AUTOMÁTICOS',
            options: [
              {
                id: 2221,
                name: 'Procesos automáticos 1',
                route: '/',
              },
              {
                id: 2222,
                name: 'Procesos automáticos 2',
                route: '/',
              },
              {
                id: 2223,
                name: 'Procesos automáticos 3',
                route: '/',
              },
            ],
          },
        ],
      },
      {
        id: 23,
        name: 'Informes',
        route: '/',
        categories: [
          {
            id: 231,
            name: 'MOVIMIENTOS',
            options: [
              {
                id: 2311,
                name: 'Documentos contables',
                route: '/',
              },
              {
                id: 2312,
                name: 'Cambios de Nits',
                route: '/',
              },
              {
                id: 2313,
                name: 'Cambios de Auxiliares',
                route: '/',
              },
            ],
          },
          {
            id: 232,
            name: 'PROCESOS AUTOMÁTICOS',
            options: [
              {
                id: 2321,
                name: 'Procesos automáticos 1',
                route: '/',
              },
              {
                id: 2322,
                name: 'Procesos automáticos 2',
                route: '/',
              },
              {
                id: 2323,
                name: 'Procesos automáticos 3',
                route: '/',
              },
            ],
          },
        ],
      },
    ],
  },
];
