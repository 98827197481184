import React from 'react';
import { MembershipsReportsC } from '../../../../containers/membership/membershipReport';
import LayoutC from '../../../../containers/Layout';

const MembershipsReportP = () => {
  return (
    <LayoutC>
      <MembershipsReportsC />
    </LayoutC>
  );
};

export default MembershipsReportP;
