import React, { FC } from 'react';
import imgNoFound from './Security-amico.svg';
import { Logo, WelcomeArea } from './styled';

const NotFoundData: FC = () => (
  <div
    style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
    <Logo src={imgNoFound} />
    <WelcomeArea>NO TIENES ACCESO A ESTE RECURSO</WelcomeArea>
  </div>
);
export default NotFoundData;
