import React from 'react';
import { BenefitsByProvidersReportC } from '../../../containers/benefits/providersReport';
import LayoutC from '../../../containers/Layout';

const BenefitsByProvidersReportP = () => {
  return (
    <LayoutC>
      <BenefitsByProvidersReportC />
    </LayoutC>
  );
};

export default BenefitsByProvidersReportP;
