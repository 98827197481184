import { Theme } from '@mui/material';
import { TStyle } from '../../../../common/components/modalAlert/types.d';

export const styleSheet = (theme: Theme) => {
  const styles: TStyle = {
    mainBox: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      borderRadius: '16px',
      minWidth: '305px',
    },
    secondaryBox: {
      display: 'flex',
      width: '100%',
      height: '50%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      '@media screen and (max-width:1440px)': {
        gap: '15px',
        display: 'flex',
        flexDirection: 'row',
      },
      '@media screen and (max-width:1024px)': {
        gap: '15px',
        display: 'flex',
        height: '45%',
        flexDirection: 'row',
      },
      '@media screen and (max-width:920px)': {
        gap: '15px',
        display: 'flex',
        height: 'auto',
        flexDirection: 'column',
      },
    },
    legend: {
      width: '100%',
      height: '20%',
      margin: '20px auto 0',
    },
    contGraph: {
      width: '65%',
      backgroundColor: 'white',
      borderRadius: '5px',
      padding: '10px 0px',
      minWidth: '300px',
      boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      '@media screen and (max-width:1440px)': {
        width: '61%',
      },
      '@media screen and (max-width:920px)': {
        width: '100%',
        height: '45%',
      },
    },
    contGraphic: {
      width: '30%',
      borderRadius: '5px',
      backgroundColor: 'white',
      pt: '10px',
      padding: '10px 25px',
      minWidth: '300px',
      boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      '@media screen and (max-width:1440px)': {
        width: '38%',
        padding: '10px 0',
      },
      '@media screen and (max-width:920px)': {
        width: '100%',
        height: '50%',
      },
    },
    thirdBox: {
      display: 'flex',
      width: '100%',
      height: '45%',
      marginTop: '15px',
      paddingBottom: '40px',
      '@media screen and (max-width:1024px)': {
        width: '100%',
        height: '45%',
      },
      '@media screen and (max-width:920px)': {
        width: '100%',
        height: '35%',
      },
    },
    contReport: {
      width: '100%',
      backgroundColor: 'white',
      borderRadius: '5px',
      padding: '10px 0',
      boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      '@media screen and (max-width:1024px)': {
        height: '100%',
        padding: '10px 0',
      },
      '@media screen and (max-width:920px)': {
        height: '100%',
        padding: '10px 0',
      },
      minWidth: '300px',
    },
    graphicsArea: {
      width: '100%',
      height: '100%',
      display: 'flex',
      backgroundColor: 'white',
      borderRadius: '5px',
      padding: '10px 20px',
    },
    contGraphicArea: {
      width: '50%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
    contText: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '5px',
    },
    text: {
      fontWeight: 'bold',
    },
    textSize: {
      fontWeight: 'bold',
      '@media screen and (max-width: 700px)': {
        fontSize: '20px',
      },
    },
    textGraphic: {
      fontWeight: 'bold',
      '@media screen and (max-width: 700px)': {
        fontSize: '10px',
      },
    },
    graphicAreaSmooth: {
      width: '50%',
      height: '20%',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    growthAccount: {
      width: '60%',
      height: '30%',
      display: 'flex',
      justifyContent: 'space-between',
      '@media screen and (max-width: 700px)': {
        width: '100%',
        height: '30%',
        flexDirection: 'column',
      },
      '@media screen and (max-height: 700px)': {
        width: '100%',
        height: '30%',
      },
    },
    growthAccountText: {
      width: '45%',
      height: '100%',
      padding: '20px 10px',
      '@media screen and (max-width: 700px)': {
        width: '100%',
        height: '60%',
        padding: '5px 0px 0px 0px',
      },
    },
    graphVisitor: {
      width: '100%',
      height: '80%',
    },
    graphicGrowth: {
      width: '100%',
      height: '65%',
      '@media screen and (max-width: 700px)': {
        height: '60%',
      },
    },
    contLegend: {
      padding: '10px 25px',
      display: 'flex',
      justifyContent: 'space-between',
      height: '15%',
    },
    contentLegend: {
      display: 'flex',
      alignItems: 'center',
    },
    contTextLegend: {
      borderRadius: '25px',
      height: '12px',
      width: '12px',
    },
    textoLegend: {
      fontWeight: 400,
      pl: '5px',
      fontFamily: 'sans-serif',
    },
    dataLegend: {
      color: '#8B8B8B',
      fontFamily: 'sans-serif',
    },
  };
  return styles;
};
