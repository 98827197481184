export class ResponseUserPermission {
  // basicInformation
  public name = '';

  public description = '';

  public idSubModule = 0;

  public cancel = 0;

  public delete = 0;

  public edit = 0;

  public read = 0;

  public write = 0;
}
