import 'reflect-metadata';
import { AxiosResponse } from 'axios';
import { RequestTaxInfo } from '../../../domain/taxInformation/taxInfo/model/requestTaxInfo';
import { ResponseTaxInfo } from '../../../domain/taxInformation/taxInfo/model/responseTaxInfo';
import { ITaxInfoInfrastructure } from '../../../domain/interface/infrastructure/taxInformation/ITaxInfoInfrastructure';
import { processDataBackend } from '../../../../common/utils/functions';

export class TaxInfoInfrastructure implements ITaxInfoInfrastructure {
  private a = '';

  public async getTaxInfoAPI(request: RequestTaxInfo): Promise<ResponseTaxInfo[]> {
    this.a = 'aaa';

    const res: AxiosResponse = await processDataBackend(
      'GET',
      '/api/taxInfos/getAll',
      {},
      { request }
    );

    const TaxInfo: ResponseTaxInfo[] = res.data;
    return Promise.resolve(TaxInfo);
  }
}
