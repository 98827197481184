import { AxiosError } from 'axios';
import {
  actionsLoginBeneficiary,
  actionsRefresh,
  actionsBeneficiaryLogout,
  actionsUserRecoveryPassword,
} from './actions';
import { SecurityState } from '../../core/domain/security/state/securityState';
import { ResponseLogin } from '../../core/domain/security/model/response/responseLogin';
import { TLoginBeneficiaryReducer } from './types';
import { UserState } from '../../core/domain/userPermissions/user/state/userState';
import { ResponseUser } from '../../core/domain/userPermissions/user/model/responseUser';
import { TUserRecoveryPasswordReducer } from '../userPermissions/user/types.d';
import { TLoginReducer } from '../security/types';

const { LOGIN_BENEFICIARY_PAGE_BEGIN, LOGIN_BENEFICIARY_PAGE_SUCCESS, LOGIN_BENEFICIARY_PAGE_ERR } =
  actionsLoginBeneficiary;
const {
  LOGOUT_BENEFICIARY_PAGE_BEGIN,
  LOGOUT_BENEFICIARY_PAGE_SUCCESS,
  LOGOUT_BENEFICIARY_PAGE_ERR,
} = actionsBeneficiaryLogout;
const { REFRESH_PAGE_BEGIN, REFRESH_PAGE_SUCCESS, REFRESH_PAGE_ERR } = actionsRefresh;
const {
  USER_RECOVERY_PASSWORD_PAGE_BEGIN,
  USER_RECOVERY_PASSWORD_PAGE_ERR,
  USER_RECOVERY_PASSWORD_PAGE_SUCCESS,
} = actionsUserRecoveryPassword;

const initialSecurity: SecurityState = new SecurityState();

export const BeneficiaryLoginReducer = (
  state = initialSecurity,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseLogin | undefined;
  }
): TLoginReducer => {
  const { type, data, err } = action;
  switch (type) {
    case LOGIN_BENEFICIARY_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_BENEFICIARY_PAGE_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case LOGIN_BENEFICIARY_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const LoginBeneficiaryReducer = (
  state = initialSecurity,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseLogin | undefined;
  }
): TLoginBeneficiaryReducer => {
  const { type, data, err } = action;
  switch (type) {
    case LOGIN_BENEFICIARY_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_BENEFICIARY_PAGE_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case LOGIN_BENEFICIARY_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const LogoutBeneficiaryReducer = (
  state = initialSecurity,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseLogin | undefined;
  }
): TLoginBeneficiaryReducer => {
  const { type, data, err } = action;
  switch (type) {
    case LOGOUT_BENEFICIARY_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_BENEFICIARY_PAGE_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case LOGOUT_BENEFICIARY_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialUser: UserState = new UserState();

export const UserRecoveryPasswordReducer = (
  state = initialUser,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseUser | undefined;
  }
): TUserRecoveryPasswordReducer => {
  const { type, data, err } = action;
  switch (type) {
    case USER_RECOVERY_PASSWORD_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USER_RECOVERY_PASSWORD_PAGE_SUCCESS:
      return {
        ...state,
        userRecoveryPassword: data,
        loading: false,
      };
    case USER_RECOVERY_PASSWORD_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const RefreshReducer = (
  state = initialSecurity,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseLogin | undefined;
  }
): TLoginBeneficiaryReducer => {
  const { type, data, err } = action;
  switch (type) {
    case REFRESH_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case REFRESH_PAGE_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case REFRESH_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};
