import { AxiosError } from 'axios';
import { ResponseDepartments } from '../../../core/domain/addresses/departments/model/responseDepartments';
import { DepartmentsState } from '../../../core/domain/addresses/departments/state/departmentsState';
import actions from './actions';
import { TDepartmentsReducer } from './types';
import { dataSorted } from '../../../common/utils/functions';

const { DEPARTMENTS_LIST_PAGE_BEGIN, DEPARTMENTS_LIST_PAGE_SUCCESS, DEPARTMENTS_LIST_PAGE_ERR } =
  actions;

const initialDepartments: DepartmentsState = new DepartmentsState();

const DepartmentsReducer = (
  state = initialDepartments,
  action: { type: string; err: AxiosError; data: ResponseDepartments[] }
): TDepartmentsReducer => {
  const { type, data, err } = action;
  switch (type) {
    case DEPARTMENTS_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DEPARTMENTS_LIST_PAGE_SUCCESS:
      return {
        ...state,
        departments: dataSorted(data),
        loading: false,
      };
    case DEPARTMENTS_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default DepartmentsReducer;
