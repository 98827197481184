import { AxiosError } from 'axios';
import { ResponseAddress } from '../../../core/domain/addresses/address/model/responseAddress';

const actions = {
  ADDRESSES_LIST_PAGE_BEGIN: 'ADDRESSES_LIST_PAGE_BEGIN',
  ADDRESSES_LIST_PAGE_SUCCESS: 'ADDRESSES_LIST_PAGE_SUCCESS',
  ADDRESSES_LIST_PAGE_ERR: 'ADDRESSES_LIST_PAGE_ERR',

  addressesListPageBegin: (): { type: string } => {
    return {
      type: actions.ADDRESSES_LIST_PAGE_BEGIN,
    };
  },

  addressesListPageSuccess: (
    data: ResponseAddress[]
  ): { type: string; data: ResponseAddress[] | ResponseAddress } => {
    return {
      type: actions.ADDRESSES_LIST_PAGE_SUCCESS,
      data,
    };
  },

  addressesListPageErr: (err: AxiosError): { type: string; err: AxiosError } => {
    return {
      type: actions.ADDRESSES_LIST_PAGE_ERR,
      err,
    };
  },
};

export default actions;
