import { container } from 'tsyringe';
import { IUserSessionApplication } from '../../../domain/interface/application/userPermissions/IUserSessionApplication';
import { IUserSessionInfrastructure } from '../../../domain/interface/infrastructure/userPermissions/IUserSessionInfrastructure';
import { ResponseUserSession } from '../../../domain/userPermissions/userSession/model/responseUserSession';

export class UserSessionApplication implements IUserSessionApplication {
  private userSessionInfrastructure: IUserSessionInfrastructure;

  public constructor() {
    this.userSessionInfrastructure = container.resolve<IUserSessionInfrastructure>(
      'IUserSessionInfrastructure'
    );
  }

  public async validateUserSession(token: string): Promise<ResponseUserSession> {
    const result = await this.userSessionInfrastructure.validateUserSessionAPI(token);
    return result;
  }

  public async deleteUserSession(token: string): Promise<ResponseUserSession> {
    const result = await this.userSessionInfrastructure.deleteUserSessionAPI(token);
    return result;
  }
}
