import React from 'react';
import { ProvidersListC } from '../../../containers/providers/providersList';
import LayoutC from '../../../containers/Layout';

const ProvidersListP = () => {
  return (
    <LayoutC>
      <ProvidersListC />
    </LayoutC>
  );
};

export default ProvidersListP;
