import { AxiosError } from 'axios';
import { ResponseModule } from '../../../core/domain/userPermissions/module/model/responseModule';

const actions = {
  MODULE_LIST_PAGE_BEGIN: 'MODULE_LIST_PAGE_BEGIN',
  MODULE_LIST_PAGE_SUCCESS: 'MODULE_LIST_PAGE_SUCCESS',
  MODULE_LIST_PAGE_ERR: 'MODULE_LIST_PAGE_ERR',

  moduleListPageBegin: (): { type: string } => {
    return {
      type: actions.MODULE_LIST_PAGE_BEGIN,
    };
  },

  moduleListPageSuccess: (
    data: ResponseModule[] | ResponseModule
  ): { type: string; data: ResponseModule[] | ResponseModule } => {
    return {
      type: actions.MODULE_LIST_PAGE_SUCCESS,
      data,
    };
  },

  moduleListPageErr: (err: AxiosError): { type: string; err: AxiosError } => {
    return {
      type: actions.MODULE_LIST_PAGE_ERR,
      err,
    };
  },
};

export default actions;
