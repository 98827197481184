import { AxiosError } from 'axios';
import { ResponseTaxRegime } from '../../../core/domain/taxInformation/taxRegime/model/responseTaxRegime';
import { TaxRegimeState } from '../../../core/domain/taxInformation/taxRegime/state/TaxRegime';
import actions from './actions';
import { TTaxRegimeReducer } from './types';
import { dataSorted } from '../../../common/utils/functions';

const { TAX_REGIME_LIST_PAGE_BEGIN, TAX_REGIME_PAGE_SUCCESS, TAX_REGIME_LIST_PAGE_ERR } = actions;

const initialTaxRegime: TaxRegimeState = new TaxRegimeState();

const TaxRegimeReducer = (
  state = initialTaxRegime,
  action: { type: string; err: AxiosError; data: ResponseTaxRegime[] }
): TTaxRegimeReducer => {
  const { type, data, err } = action;
  switch (type) {
    case TAX_REGIME_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case TAX_REGIME_PAGE_SUCCESS:
      return {
        ...state,
        taxRegime: dataSorted(data),
        loading: false,
      };
    case TAX_REGIME_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default TaxRegimeReducer;
