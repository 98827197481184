import styled from 'styled-components';

export const SpanButton = styled.span`
  font-size: 1.1em;
  font-weight: 500;
`;
export const ButtonContent = styled.div`
  display: flex;
  align-content: center;
  width: 135px;
  height: 35px;
  &:hover {
    background-color: #ed6a58;
  }
  @media screen and (max-width: 899px) {
    width: 200px;
  }
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
`;

export const Span = styled.span`
  width: 100%;
  border: none;
  margin: 1px 0px;
  padding: 5.2px 0px;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  font-weight: 800;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #001f46;
`;

export const ValidateExcel = styled.button<{ validated?: boolean }>`
  width: 100%;
  height: 35px;
  margin-top: 10px;
  background: #001f46;
  background: ${({ validated }) => (validated ? '#001f46' : 'white')};
  color: ${({ validated }) => (validated ? 'white' : '#7a7a7a')};
  border-radius: 3px;
  border: 1px solid #f0f2f6;
  display: flex;
  gap: 3px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  &:hover {
    border: 1px solid #ed6a58;
  }
  .checkCircle {
    margin-left: 5px;
  }
`;
export const SpanValidate = styled.p`
  margin: 0;
  font-size: 1.1em;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  transition: 0.1s;
`;
export const SpanDocument = styled.p`
  font-size: 1.1em;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  color: #7a7a7a;
`;

export const Message = styled.li`
  color: #25b346;
  margin: 10px;
`;
