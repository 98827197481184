import { AxiosError } from 'axios';
import { ResponseSubModule } from '../../../core/domain/userPermissions/subModule/model/responseSubModule';

const actions = {
  SUBMODULE_LIST_PAGE_BEGIN: 'SUBMODULE_LIST_PAGE_BEGIN',
  SUBMODULE_LIST_PAGE_SUCCESS: 'SUBMODULE_LIST_PAGE_SUCCESS',
  SUBMODULE_LIST_PAGE_ERR: 'SUBMODULE_LIST_PAGE_ERR',

  subModuleListPageBegin: (): { type: string } => {
    return {
      type: actions.SUBMODULE_LIST_PAGE_BEGIN,
    };
  },

  subModuleListPageSuccess: (
    data: ResponseSubModule[] | ResponseSubModule
  ): { type: string; data: ResponseSubModule[] | ResponseSubModule } => {
    return {
      type: actions.SUBMODULE_LIST_PAGE_SUCCESS,
      data,
    };
  },

  subModuleListPageErr: (err: AxiosError): { type: string; err: AxiosError } => {
    return {
      type: actions.SUBMODULE_LIST_PAGE_ERR,
      err,
    };
  },
};

export default actions;
