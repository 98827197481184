import React from 'react';
import { UserListC } from '../../../containers/user/userList';
import LayoutC from '../../../containers/Layout';

const UserListP = () => {
  return (
    <LayoutC>
      <UserListC />
    </LayoutC>
  );
};

export default UserListP;
