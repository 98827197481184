import 'reflect-metadata';
import { AxiosResponse } from 'axios';
import { RequestKinships } from '../../../domain/contacts/kinships/model/requestKinships';
import { ResponseKinships } from '../../../domain/contacts/kinships/model/responseKinships';
import { IKinshipsInfrastructure } from '../../../domain/interface/infrastructure/documentsPersons/IKinshipsInfrastructure';
import { processDataBackend } from '../../../../common/utils/functions';
// import { IKinshipsInfrastructure } from "../../../domain/interface/infrastructure/documentsPersons/IKinshipsInfrastructure";

export class KinshipsInfrastructure implements IKinshipsInfrastructure {
  private a = '';

  public async getKinshipsAPI(request: RequestKinships): Promise<ResponseKinships[]> {
    this.a = 'aaa';

    const res: AxiosResponse = await processDataBackend(
      'GET',
      '/api/kinships/getAll',
      {},
      { request }
    );

    const kinships: ResponseKinships[] = res.data;

    return Promise.resolve(kinships);
  }
}
