import { AxiosError } from 'axios';
import 'reflect-metadata';
import { container } from 'tsyringe';
import { AppDispatch } from '../../store';
import { IBenefitApplication } from '../../../core/domain/interface/application/benefits/IBenefitApplication';
import { actionsBenefit, actionsBenefitByBeneficiaries, actionsBenefitByThirdParty, actionsBenefitCreate, actionsBenefitDelete, actionsBenefits, actionsBenefitsByMembership, actionsBenefitsForExcel, actionsBenefitsRandom, actionsBenefitsWithPagination, actionsBenefitUpdate } from './actions';
import { RequestBenefit } from '../../../core/domain/benefits/benefits/model/requestBenefit';
import { TPagination } from '../../../common/utils/types.d';

const { benefitCreatePageBegin, benefitCreatePageSuccess, benefitCreatePageErr } =
  actionsBenefitCreate;
const { benefitUpdatePageBegin, benefitUpdatePageSuccess, benefitUpdatePageErr } =
  actionsBenefitUpdate;
const { benefitDeletePageBegin, benefitDeletePageSuccess, benefitDeletePageErr } =
  actionsBenefitDelete;
const { benefitListPageBegin, benefitListPageSuccess, benefitListPageErr } = actionsBenefit;
const { benefitsListPageBegin, benefitsListPageSuccess, benefitsListPageErr } =
  actionsBenefits;
const {
  benefitsForExcelListPageBegin,
  benefitsForExcelListPageSuccess,
  benefitsForExcelListPageErr,
} = actionsBenefitsForExcel;
const { benefitsRandomListPageBegin, benefitsRandomListPageSuccess, benefitsRandomListPageErr } =
  actionsBenefitsRandom;
const {
  benefitsWithPaginationListPageBegin,
  benefitsWithPaginationListPageSuccess,
  benefitsWithPaginationListPageErr,
} = actionsBenefitsWithPagination;
const {
  benefitsByMembershipListPageBegin,
  benefitsByMembershipPageSuccess,
  benefitsByMembershipPageErr,
} = actionsBenefitsByMembership;
const {
  benefitByBeneficiariesListPageBegin,
  benefitByBeneficiariesPageSuccess,
  benefitByBeneficiariesPageErr,
} = actionsBenefitByBeneficiaries;

const {
  benfitByThirdPartyListPageBeguin,
  benefitByThirdPartyPageSuccess,
  benefitByThirdPartyErr
} = actionsBenefitByThirdParty

export const benefitCreatePage = (listState: any): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(benefitCreatePageBegin());
      const benefitApplication = container.resolve<IBenefitApplication>('IBenefitApplication');

      const resultCreate = await benefitApplication.createBenefit(listState);
      dispatch(benefitCreatePageSuccess(resultCreate));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(benefitCreatePageErr(error));
    }
  };
};

export const benefitUpdatePage = (
  listState: RequestBenefit
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(benefitUpdatePageBegin());
      const benefitApplication = container.resolve<IBenefitApplication>('IBenefitApplication');

      const resultUpdate = await benefitApplication.updateBenefit(listState);
      dispatch(benefitUpdatePageSuccess(resultUpdate));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(benefitUpdatePageErr(error));
    }
  };
};

export const benefitDeletePage = (
  listState: RequestBenefit
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(benefitDeletePageBegin());
      const benefitApplication = container.resolve<IBenefitApplication>('IBenefitApplication');

      const resultDelete = await benefitApplication.deleteBenefit(listState);
      dispatch(benefitDeletePageSuccess(resultDelete));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(benefitDeletePageErr(error));
    }
  };
};

export const clearBenefitCreatePage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(benefitCreatePageBegin());
    dispatch(benefitCreatePageErr(undefined));
    dispatch(benefitCreatePageSuccess(undefined));
  };
};

export const getByIdBenefitListPage = (
  listState: RequestBenefit
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(benefitListPageBegin());
      const benefitApplication = container.resolve<IBenefitApplication>('IBenefitApplication');

      const resultGetById = await benefitApplication.getByIdBenefit(listState);
      dispatch(benefitListPageSuccess(resultGetById));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(benefitListPageErr(error));
    }
  };
};

export const benefitsListPage = (): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(benefitsListPageBegin());
      const benefitsApplication = container.resolve<IBenefitApplication>('IBenefitApplication');

      const request = new RequestBenefit();
      const result = await benefitsApplication.getBenefits(request);

      dispatch(benefitsListPageSuccess(result));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(benefitsListPageErr(error));
    }
  };
};

export const benefitsForExcelListPage = (): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(benefitsForExcelListPageBegin());
      const benefitsApplication = container.resolve<IBenefitApplication>('IBenefitApplication');

      const request = new RequestBenefit();
      const result = await benefitsApplication.getBenefitsForExcel(request);

      dispatch(benefitsForExcelListPageSuccess(result));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(benefitsForExcelListPageErr(error));
    }
  };
};

export const benefitsRandomListPage = (
  listState: RequestBenefit
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(benefitsRandomListPageBegin());
      const benefitsApplication = container.resolve<IBenefitApplication>('IBenefitApplication');

      // const request = new RequestBenefit();
      const result = await benefitsApplication.getBenefitsRandom(listState);

      dispatch(benefitsRandomListPageSuccess(result));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(benefitsRandomListPageErr(error));
    }
  };
};

export const benefitsWithPaginationListPage = (
  listState: TPagination
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(benefitsWithPaginationListPageBegin());
      const benefitsWithPaginationApplication =
        container.resolve<IBenefitApplication>('IBenefitApplication');
      const result = await benefitsWithPaginationApplication.getBenefitsWithPagination(listState);

      dispatch(benefitsWithPaginationListPageSuccess(result));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(benefitsWithPaginationListPageErr(error));
    }
  };
};

export const benefitsWithThirdPartyListPage = (
  idThirdParty: number | undefined
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(benfitByThirdPartyListPageBeguin())
      const benefitsApplication = container.resolve<IBenefitApplication>('IBenefitApplication')
      const result = await benefitsApplication.getBenefitsByIdThirdParty(idThirdParty);

      dispatch(benefitByThirdPartyPageSuccess(result));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(benefitByThirdPartyErr(error));
    }
  }
}

export const getAllByMembershipListPage = (
  listState: RequestBenefit
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(benefitsByMembershipListPageBegin());
      const benefitsApplication = container.resolve<IBenefitApplication>('IBenefitApplication');
      const result = await benefitsApplication.getAllByMembership(listState);

      dispatch(benefitsByMembershipPageSuccess(result));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(benefitsByMembershipPageErr(error));
    }
  };
};
export const getAllBenefitByBeneficiariesListPage = (
  listState: RequestBenefit
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(benefitByBeneficiariesListPageBegin());
      const benefitByBeneficiaries = container.resolve<IBenefitApplication>('IBenefitApplication');
      const result = await benefitByBeneficiaries.getAllBenefitByBeneficiaries(listState);

      dispatch(benefitByBeneficiariesPageSuccess(result));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(benefitByBeneficiariesPageErr(error));
    }
  };
};

export const clearBenefitUpdateListPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(benefitUpdatePageBegin());
    dispatch(benefitUpdatePageErr(undefined));
    dispatch(benefitUpdatePageSuccess(undefined));
  };
};

export const clearBenefitById = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(benefitListPageBegin());
    dispatch(benefitListPageErr(undefined));
    dispatch(benefitListPageSuccess(undefined));
  };
};

export const clearBenefitsListPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(benefitsListPageBegin());
    dispatch(benefitsListPageErr(undefined));
    dispatch(benefitsListPageSuccess([]));
  };
};

export const clearBenefitsForExcelListPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(benefitsForExcelListPageBegin());
    dispatch(benefitsForExcelListPageErr(undefined));
    dispatch(benefitsForExcelListPageSuccess([]));
  };
};

export const clearBenefitsRandomListPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(benefitsRandomListPageBegin());
    dispatch(benefitsRandomListPageErr(undefined));
    dispatch(benefitsRandomListPageSuccess(undefined));
  };
};

export const clearBenefitsWithPaginationListPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(benefitsWithPaginationListPageBegin());
    dispatch(benefitsWithPaginationListPageErr(undefined));
    dispatch(benefitsWithPaginationListPageSuccess([]));
  };
};

export const clearBenefitByBeneficiariesListPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(benefitByBeneficiariesListPageBegin());
    dispatch(benefitByBeneficiariesPageErr(undefined));
    dispatch(benefitByBeneficiariesPageSuccess([]));
  };
};

export const clearBenefitDeleteListPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(benefitDeletePageBegin());
    dispatch(benefitDeletePageErr(undefined));
    dispatch(benefitDeletePageSuccess(undefined));
  };
};
