import React, { ChangeEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as xlsx from 'xlsx';
import { TBulkCreateBeneficiaryDispatch } from './types.d';
import { RequestBeneficiaries } from '../../../../domain/beneficiaries/model/requestBeneficiaries';
import { numberOrString } from '../../../../../common/utils/functions';

import { RootState } from '../../../../../redux/store';
import {
  bulkCreateBeneficiaryCreatePage,
  clearBulkCreateBeneficiaryCreatePage,
} from '../../../../../redux/beneficiaries/actionCreator';
import { TDispatch } from '../../../../../common/utils/types.d';
import { BulkLoadV } from '../../../components/beneficiaries/bulkLoad';

export const BulkLoad = () => {
  /**
   * dispatch
   */
  const dispatch = useDispatch();

  /**
   * Dispatch de bulkCreate beneficiary
   */
  const bulkCreateBeneficiaryDispatch = useSelector(
    (dataState: RootState): TBulkCreateBeneficiaryDispatch => dataState.beneficiaryList
  );

  /**
   * states
   */
  const [bulkSuccess, setBulkSuccess] = useState(false);
  const refInputFile = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState<string>('');
  const [dataExcel, setDataExcel] = useState<RequestBeneficiaries[]>([]);
  const [loadingBulk, setLoadingBulk] = useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(false);
  const [excelColumns, setExcelColumns] = useState<Array<string | number>>([]);

  const [columns] = useState<Array<string>>([
    'TIPO DE IDENTIFICACIÓN',
    'NÚMERO DE IDENTIFICACIÓN',
    'GÉNERO',
    'MEMBRESÍA',
    'PRIMER NOMBRE',
    'SEGUNDO NOMBRE',
    'PRIMER APELLIDO',
    'SEGUNDO APELLIDO',
    'CIUDAD',
    'BARRIO',
    'TIPO DE DIRECCIÓN 1',
    'NÚMERO',
    'TIPO DE DIRECCIÓN 2',
    'NÚMERO 2',
    'NÚMERO 3',
    'DETALLE',
    'CORREO ELECTRÓNICO',
    'CELULAR',
    'TELÉFONO',
  ]);

  /**
   * handleChangeFile
   */
  const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    setValidated(false);
    setBulkSuccess(false);
    setDataExcel([]);
    if (e.target.files?.length) {
      /** variables necesarias */
      const { files } = e.target;
      const reader = new FileReader();
      let item: Array<Array<string | number>> = [];
      setFileName(files[0]?.name);
      /** Almacena el archivo en modo buffer */
      reader.readAsArrayBuffer(files[0]);
      // lee el archivo
      reader.onloadend = (eR: ProgressEvent<FileReader>) => {
        /** lo convierte en un formato para luego ser leído en xlsx */
        const data = eR.target
          ? new Uint8Array(eR.target.result as ArrayBufferLike)
          : new Uint8Array();
        const arrayXLSX = xlsx?.read(data, {
          type: 'array',
          cellText: true,
          cellDates: true,
        });
        /** selecciona la primera hoja del excel */
        const arrayS = arrayXLSX?.Sheets[arrayXLSX.SheetNames[0]];
        const rows: Array<Array<string | number>> = xlsx?.utils.sheet_to_json(arrayS, {
          header: 1,
          dateNF: 'YYYY-MM-DD',
        });
        /** guardando las columnas del excel  */
        setExcelColumns(rows[0]);
        rows?.forEach((row: Array<string | number>, index: number) => {
          if (index > 0 && row?.length > 0) item = [...item, row];
        });
        /** almacena los resultado en formato json en un array */
        setDataExcel(
          item?.map((x: Array<string | number>, i: number) => {
            return {
              rowIndex: i + 2,
              idDocumentType: x[0] ? Number(x[0]) : undefined,
              identificationNumber: x[1] ? Number(x[1]) : undefined,
              idGender: x[2] ? Number(x[2]) : undefined,
              thirdPartyBeneficiary: {
                identificationNumber: x[3] ? Number(x[3]) : undefined,
              },
              firstName: x[4] ? String(x[4]) : undefined,
              middleName: x[5] ? String(x[5]) : undefined,
              firstSurname: x[6] ? String(x[6]) : undefined,
              secondSurname: x[7] ? String(x[7]) : undefined,
              address: {
                cityCode: numberOrString(x[8], 'text'),
                neighBorhood: x[9] ? String(x[9]) : undefined,
                idAddressFirstStreetType: x[10],
                firstStreetNumber: numberOrString(x[11], 'text'),
                idAddressSecondStreetType: x[12],
                secondStreetNumber: numberOrString(x[13], 'text'),
                thirdStreetNumber: numberOrString(x[14], 'text'),
                description: numberOrString(x[15], 'text'),
              },
              email: x[16] ? String(x[16]) : undefined,
              cellPhone: x[17] ? String(x[17]) : undefined,
              telephone: x[18] ? String(x[18]) : undefined,
            };
          })
        );
      };
    }
  };

  /**
   * HandleBulkSubmit
   */
  const handleBulkSubmit = (event: MouseEvent<HTMLElement>) => {
    event?.preventDefault();
    if (dataExcel?.length) {
      if (validated) {
        dispatch(bulkCreateBeneficiaryCreatePage(dataExcel) as TDispatch);
        dispatch(clearBulkCreateBeneficiaryCreatePage() as TDispatch);
        setBulkSuccess(false);
        setLoadingBulk(true);
      } else {
        setBulkSuccess(false);
        toast.warning('Por favor validar el excel', { autoClose: 2000, hideProgressBar: true });
      }
    } else {
      setBulkSuccess(false);
      toast.warning('Por favor agregar un documento.', { autoClose: 2000, hideProgressBar: true });
    }
  };

  /**
   * Función para limpiar la carga masiva
   */
  const cleanBulkUpload = () => {
    setValidated(false);
    setBulkSuccess(false);
    setLoadingBulk(false);
    setDataExcel([]);
    setExcelColumns([]);
    dispatch(clearBulkCreateBeneficiaryCreatePage() as TDispatch);
    (refInputFile.current as HTMLInputElement).value = '';
    setFileName('Seleccione un documento');
  };

  /**
   * Función para validar el excel
   */
  const validateExcel = () => {
    if (dataExcel.length) {
      if (
        columns?.length === excelColumns?.length &&
        columns?.sort()?.every((x, i) => x === excelColumns?.sort()[i])
      ) {
        setValidated(true);
      } else {
        setValidated(false);
        toast.warning('Archivo excel no valido', { autoClose: 2000, hideProgressBar: true });
      }
    } else {
      setBulkSuccess(false);
      toast.warning('Por favor agregar un documento', { autoClose: 2000, hideProgressBar: true });
    }
  };

  /**
   * UseEffect para verificar si se crean los beneficiarios
   */
  useEffect(() => {
    if (
      bulkCreateBeneficiaryDispatch?.beneficiaryList?.length === dataExcel?.length &&
      loadingBulk
    ) {
      if (
        bulkCreateBeneficiaryDispatch?.beneficiaryList?.find((x) => {
          return !!x?.errors?.length || !!x.error;
        })
      ) {
        toast.warning('Ha ocurrido una incidencia', { autoClose: 2000, hideProgressBar: true });
        setLoadingBulk(false);
        setBulkSuccess(false);
        // dispatch(clearBulkCreateBeneficiaryCreatePage() as TDispatch);
      } else {
        toast.success('Se ha registrado exitosamente', { autoClose: 2000, hideProgressBar: true });
        setLoadingBulk(false);
        setValidated(false);
        setBulkSuccess(true);
        setDataExcel([]);
        dispatch(clearBulkCreateBeneficiaryCreatePage() as TDispatch);
        (refInputFile.current as HTMLInputElement).value = '';
        setFileName('Seleccione un documento');
      }
    }
  }, [bulkCreateBeneficiaryDispatch?.beneficiaryList, dataExcel?.length, loadingBulk, dispatch]);

  useEffect(() => {
    dispatch(clearBulkCreateBeneficiaryCreatePage() as TDispatch);
  }, [dispatch]);

  return (
    <BulkLoadV
      bulkSuccess={bulkSuccess}
      validateExcel={validateExcel}
      cleanBulkUpload={cleanBulkUpload}
      handleBulkSubmit={handleBulkSubmit}
      handleChangeFile={handleChangeFile}
      bulkResponse={bulkCreateBeneficiaryDispatch?.beneficiaryList}
      fileName={fileName}
      refInputFile={refInputFile}
      validated={validated}
      loadingBulk={loadingBulk}
    />
  );
};
