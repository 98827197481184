import React from 'react';
import { MembershipsBeneficiariesReportsC } from '../../../../containers/membership/membershipBeneficiariesReport';
import LayoutC from '../../../../containers/Layout';

const MembershipsBeneficiariesReportP = () => {
  return (
    <LayoutC>
      <MembershipsBeneficiariesReportsC />;
    </LayoutC>
  );
};

export default MembershipsBeneficiariesReportP;
