import React, { useEffect, useState } from 'react';
// import jwt from 'jsonwebtoken';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { clearLogin } from '../../redux/security/actionCreator';
import { TDispatch, TUser, TValidateUserSessionDispatch } from './types.d';
import { RootState } from '../../redux/store';
import {
  clearValidateUserSession,
  deleteUserSession,
  validateUserSession,
} from '../../redux/userPermissions/userSession/actionCreator';
import { clearUserLoginListPage } from '../../redux/userPermissions/user/actionCreator';

interface IUserContext {
  user: TUser;
  userSession: ({ name, lastName, email, phone, token, idRole, roles, state }: TUser) => void;
  token?: string | null;
  isLogged: boolean;
  userInfoState?: string;
  sessionLoading: boolean;
  tokenType: string;
  setLogged: (value?: boolean) => void;
  handleSession?: () => void;
  handleSessionProviderPage?: () => void;
  handleTokenType: (tokenTypeFromApp: string) => void;
  handleSessionCouponsPage?: () => void;
}

const defaultState = {
  user: {
    name: '',
    token: '',
    email: '',
    phone: '',
    lastName: '',
    idRole: 0,
    state: 0,
  },
  userSession() {},
  setLogged() {},
  handleSession() {},
  handleSessionProviderPage() {},
  handleSessionCouponsPage() {},
  handleTokenType(tokenTypeFromApp: string) {},
  isLogged: false,
  tokenType: '',
  sessionLoading: true,
};

const Context = React.createContext<IUserContext>(defaultState);

const ContextProvider: any = ({ children }: any) => {
  const history = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [user, setUser] = React.useState(defaultState.user);
  // const token = localStorage.getItem('vs1-tk') || '';
  const [tokenType, setTokenType] = useState('');
  const [sessionLoading, setSessionLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  // const [token, setToken] = useState(getToken);

  /**
   * Dispatch de validateUserSession
   */
  const validateUserSessionDispatch: TValidateUserSessionDispatch = useSelector(
    (dataState: RootState) => dataState.sessionValidated
  );

  const [isLogged, setIsLogged] = useState(false);

  const userSession = (userData: TUser) => {
    setUser({ ...userData });
  };

  const setLogged = (value?: boolean) => {
    setIsLogged(value || true);
    setSessionLoading(false);
  };

  const handleTokenType = (tokenTypeFromApp: string): void => {
    setTokenType(tokenTypeFromApp);
  };

  const tokenGeneral = localStorage.getItem(`${tokenType}-tk`);

  useEffect(() => {
    // if (!validateUserSessionDispatch?.sessionValidated && !validateUserSessionDispatch?.error)
    if (tokenType) {
      if (tokenGeneral) {
        dispatch(validateUserSession(tokenGeneral) as TDispatch);
        setLoading(true);
      } else if (
        !tokenGeneral &&
        window.location.pathname !== '/login' &&
        window.location.pathname !== '/'
      ) {
        dispatch(clearUserLoginListPage() as TDispatch);
        setIsLogged(false);
        setSessionLoading(false);
        if (window.location.pathname !== '/recuperar')
          history(tokenType === 'vs1' ? '/login' : '/');
      }
    }
  }, [tokenGeneral, history, dispatch, tokenType, location]);

  // Limpiamos la data de la validación del token
  useEffect(() => {
    dispatch(clearValidateUserSession() as TDispatch);
  }, [dispatch, location]);

  useEffect(() => {
    if (localStorage.getItem('vs3-tk')) {
      history(`/comercio/user/dashboard/${location.pathname?.replace(/\D/g, '')}`);
    } else {
      localStorage.setItem('asdasd', location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenGeneral]);

  // Obtenemos la respuesta para sabe si es valido el token
  useEffect(() => {
    if (loading)
      if (validateUserSessionDispatch?.sessionValidated && !validateUserSessionDispatch?.error) {
        if (!isLogged) setIsLogged(true);
        setSessionLoading(false);
        dispatch(clearValidateUserSession() as TDispatch);
      } else if (
        !validateUserSessionDispatch?.sessionValidated &&
        validateUserSessionDispatch?.error
      ) {
        if (localStorage.getItem(`${tokenType}-tk`)) {
          localStorage.removeItem(`${tokenType}-tk`);
          localStorage.removeItem(`${tokenType}-iux`);
          localStorage.removeItem(`${tokenType}-tux`);
          localStorage.removeItem(`${tokenType}-unx`);
        }
        history(tokenType === 'vs1' ? '/login' : '/');
        setIsLogged(false);
        setSessionLoading(false);
        dispatch(clearValidateUserSession() as TDispatch);
      }
  }, [validateUserSessionDispatch, dispatch, history, tokenType, tokenGeneral, isLogged, loading]);

  const handleSession = () => {
    dispatch(deleteUserSession(localStorage.getItem('vs1-tk') || '') as any);
    dispatch(clearLogin() as any);
    const colors = JSON.parse(localStorage.getItem('colorsTheme') || '{}');
    localStorage.removeItem('vs1-tk');
    localStorage.removeItem('vs1-iux');
    localStorage.removeItem('vs1-tux');
    localStorage.removeItem('vs1-unx');
    localStorage.setItem('colorsTheme', JSON.stringify(colors));
    setLogged(false);
    history('/login');
  };

  const handleSessionProviderPage = () => {
    dispatch(deleteUserSession(localStorage.getItem('vs3-tk') || '') as any);
    dispatch(clearLogin() as any);
    const colors = JSON.parse(localStorage.getItem('colorsTheme') || '{}');
    localStorage.removeItem('vs3-tk');
    localStorage.removeItem('vs3-iux');
    localStorage.removeItem('vs3-tux');
    localStorage.removeItem('vs3-unx');
    localStorage.setItem('colorsTheme', JSON.stringify(colors));
    setLogged(false);
    history('/');
    window.location.reload();
  };

  const handleSessionCouponsPage = () => {
    dispatch(deleteUserSession(localStorage.getItem('vs2-tk') || '') as any);
    dispatch(clearLogin() as any);
    const colors = JSON.parse(localStorage.getItem('colorsTheme') || '{}');
    localStorage.removeItem('vs2-tk');
    localStorage.removeItem('vs2-iux');
    localStorage.removeItem('vs2-tux');
    localStorage.removeItem('vs2-unx');
    localStorage.setItem('colorsTheme', JSON.stringify(colors));
    setLogged(false);
    history('/');
    window.location.reload();
  };

  useEffect(() => {
    if (
      localStorage.getItem('vs1-tk') !== '' &&
      localStorage.getItem('vs1-tk') !== undefined &&
      window.location.pathname !== '/login'
    ) {
      setIsLogged(true);
      setSessionLoading(false);
    } else {
      setIsLogged(false);
      setSessionLoading(false);
    }
  }, [tokenGeneral]);

  return (
    <Context.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        handleSession,
        handleSessionCouponsPage,
        handleSessionProviderPage,
        user,
        userSession,
        token: localStorage.getItem(`${tokenType}-tk`),
        tokenType,
        handleTokenType,
        isLogged,
        sessionLoading,
        setLogged,
      }}>
      {children}
    </Context.Provider>
  );
};

export { Context, ContextProvider };
