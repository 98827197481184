import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { ContextProvider } from './common/utils/userContext';
import { DependencyInjectionApplication } from './core/application/dependencyInjectionApplication';
import { DependencyInjectionInfrastructure } from './core/infrastructure/DependencyInjectionInfrastructure';
import HomeP from './core/presentation/pages/Home';
import BeneficiariesListP from './core/presentation/pages/beneficiaries/beneficiariesList/index';
import BeneficiariesReportP from './core/presentation/pages/beneficiaries/beneficiariesReport';
import BeneficiaryLoginP from './core/presentation/pages/beneficiaries/beneficiaryLogin';
import BeneficiaryRecoveryPasswordP from './core/presentation/pages/beneficiaries/beneficiaryRecoveryPassword';
import BeneficiaryRegisterP from './core/presentation/pages/beneficiaries/beneficiaryRegister';
import BenefitsListP from './core/presentation/pages/benefits/benefitList';
import BenefitsRegisterP from './core/presentation/pages/benefits/benefitRegister';
import BenefitsByProvidersReportP from './core/presentation/pages/benefits/benefitsByProviderReports';
import ValidateCouponP from './core/presentation/pages/benefits/validateCoupon';
import CouponsP from './core/presentation/pages/couponsPage';
import OpenBeneficiaryRegisterFormP from './core/presentation/pages/couponsPage/OpenBeneficiaryRegisterForm';
// import BeneficiaryDashboardP from './core/presentation/pages/couponsPage/beneficiaryDashboard';
import PersonalInfoP from './core/presentation/pages/couponsPage/personalInfo';
import { GeneralPage } from './core/presentation/pages/generalPage';
import Login from './core/presentation/pages/login';
import MembershipRegisterP from './core/presentation/pages/membership/membershipRegister';
import MembershipsReportP from './core/presentation/pages/membership/membershipReports/reportMembership';
import MembershipsBeneficiariesReportP from './core/presentation/pages/membership/membershipReports/reportMembershipBeneficiaries';
import MembershipsListP from './core/presentation/pages/membership/membershipsList';
import BranchesP from './core/presentation/pages/providers/branches';
import ProviderDashboardP from './core/presentation/pages/providers/providerDashboard';
import ProviderLoginP from './core/presentation/pages/providers/providerLogin';
import ProvidersRegisterP from './core/presentation/pages/providers/providerRegister';
import ProvidersListP from './core/presentation/pages/providers/providersList';
import SellersListP from './core/presentation/pages/sellers/sellerList';
import SellersRegisterP from './core/presentation/pages/sellers/sellerRegister';
import UserCreatePassP from './core/presentation/pages/user/createPassword';
import UserRecoverPassP from './core/presentation/pages/user/recoverPassword';
import UserListP from './core/presentation/pages/user/userList';
import UserRegistererP from './core/presentation/pages/user/userRegister';
import RedeemedBenefitsP from './core/presentation/pages/benefits/reddemedBenefits';
import ProfileInformationP from './core/presentation/pages/profileInformation';

const App: React.FC = () => {
  DependencyInjectionInfrastructure.DependencyInjectionStartup();
  DependencyInjectionApplication.DependencyInjectionStartup();
  return (
    <ContextProvider>
      <Routes>
        {/* Authentication Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/recuperar" element={<UserRecoverPassP />} />
        <Route path="/crearContraseña/:idUser/:userValidate" element={<UserCreatePassP />} />
        <Route path="/recuperarContraseña/:idUser/:userValidate" element={<UserCreatePassP />} />
        <Route
          path="/admin"
          element={
            <GeneralPage typeVs="vs1" requirePermissions={['Administrador', ' ', 'Comercio']}>
              <HomeP />
            </GeneralPage>
          }
        />
        <Route
          path="/usuarios/registro/"
          element={
            <GeneralPage typeVs="vs1" requirePermissions={['Administrador']}>
              <UserRegistererP />
            </GeneralPage>
          }
        />
        <Route
          path="/usuarios/editar/:id"
          element={
            <GeneralPage typeVs="vs1" requirePermissions={['Administrador']}>
              <UserRegistererP />
            </GeneralPage>
          }
        />
        <Route
          path="/usuarios/lista"
          element={
            <GeneralPage typeVs="vs1" requirePermissions={['Administrador']}>
              <UserListP />
            </GeneralPage>
          }
        />
        <Route
          path="/comercios/registro"
          element={
            <GeneralPage typeVs="vs1" requirePermissions={['Administrador', 'Usuario']}>
              <ProvidersRegisterP />
            </GeneralPage>
          }
        />
        <Route
          path="/comercios/editar/:id"
          element={
            <GeneralPage typeVs="vs1" requirePermissions={['Administrador', 'Usuario']}>
              <ProvidersRegisterP />
            </GeneralPage>
          }
        />
        <Route
          path="/comercios/lista"
          element={
            <GeneralPage typeVs="vs1" requirePermissions={['Administrador', 'Usuario']}>
              <ProvidersListP />
            </GeneralPage>
          }
        />

        <Route
          path="/beneficios/registro"
          element={
            <GeneralPage typeVs="vs1" requirePermissions={['Administrador', 'Comercio', 'Usuario']}>
              <BenefitsRegisterP />
            </GeneralPage>
          }
        />
        <Route
          path="/beneficios/editar/:id"
          element={
            <GeneralPage typeVs="vs1" requirePermissions={['Administrador', 'Comercio', 'Usuario']}>
              <BenefitsRegisterP />
            </GeneralPage>
          }
        />
        <Route
          path="/beneficios/lista"
          element={
            <GeneralPage typeVs="vs1" requirePermissions={['Administrador', 'Comercio', 'Usuario']}>
              <BenefitsListP />
            </GeneralPage>
          }
        />
        <Route
          path="/beneficios/sucursal"
          element={
            <GeneralPage typeVs="vs1" requirePermissions={['Administrador', 'Comercio']}>
              <BranchesP />
            </GeneralPage>
          }
        />
        <Route
          path="/beneficios/sucursal/editar/:id"
          element={
            <GeneralPage typeVs="vs1" requirePermissions={['Administrador', 'Comercio']}>
              <BranchesP />
            </GeneralPage>
          }
        />
        <Route
          path="/beneficios/reporte"
          element={
            <GeneralPage typeVs="vs1" requirePermissions={['Administrador', 'Comercio']}>
              <BenefitsByProvidersReportP />
            </GeneralPage>
          }
        />
        <Route
          path="/beneficios/validarCupon"
          element={
            <GeneralPage typeVs="vs1" requirePermissions={['Administrador', 'Comercio']}>
              <ValidateCouponP />
            </GeneralPage>
          }
        />
        <Route
          path="/beneficios/benficiosRedimidos"
          element={
            <GeneralPage typeVs="vs1" requirePermissions={['Administrador', 'Comercio']}>
              <RedeemedBenefitsP />
            </GeneralPage>
          }
        />
        <Route
          path="/membresias/registro"
          element={
            <GeneralPage typeVs="vs1" requirePermissions={['Administrador', 'Usuario']}>
              <MembershipRegisterP />
            </GeneralPage>
          }
        />
        <Route
          path="/membresias/editar/:id"
          element={
            <GeneralPage typeVs="vs1" requirePermissions={['Administrador', 'Usuario']}>
              <MembershipRegisterP />
            </GeneralPage>
          }
        />
        <Route
          path="/membresias/lista"
          element={
            <GeneralPage typeVs="vs1" requirePermissions={['Administrador', 'Usuario']}>
              <MembershipsListP />
            </GeneralPage>
          }
        />
        <Route
          path="/membresias/reportes/reportesMembresias"
          element={
            <GeneralPage
              typeVs="vs1"
              requirePermissions={['Administrador', 'Membresia', 'Usuario']}>
              <MembershipsReportP />
            </GeneralPage>
          }
        />
        <Route
          path="/membresias/reportes/reportesMembresiasBeneficiarios"
          element={
            <GeneralPage
              typeVs="vs1"
              requirePermissions={['Administrador', 'Membresia', 'Usuario']}>
              <MembershipsBeneficiariesReportP />
            </GeneralPage>
          }
        />

        <Route
          path="/vendedores/registro"
          element={
            <GeneralPage typeVs="vs1" requirePermissions={['Administrador', 'Usuario']}>
              <SellersRegisterP />
            </GeneralPage>
          }
        />
        <Route
          path="/vendedores/editar/:id"
          element={
            <GeneralPage typeVs="vs1" requirePermissions={['Administrador', 'Usuario']}>
              <SellersRegisterP />
            </GeneralPage>
          }
        />

        <Route
          path="/vendedores/lista"
          element={
            <GeneralPage typeVs="vs1" requirePermissions={['Administrador', 'Usuario']}>
              <SellersListP />
            </GeneralPage>
          }
        />
        <Route
          path="/beneficiarios/registro"
          element={
            <GeneralPage
              typeVs="vs1"
              requirePermissions={['Administrador', 'Membresia', 'Usuario']}>
              <BeneficiaryRegisterP />
            </GeneralPage>
          }
        />
        <Route
          path="/beneficiarios/lista"
          element={
            <GeneralPage
              typeVs="vs1"
              requirePermissions={['Administrador', 'Membresia', 'Usuario']}>
              <BeneficiariesListP />
            </GeneralPage>
          }
        />
        <Route
          path="/beneficiarios/reportes"
          element={
            <GeneralPage
              typeVs="vs1"
              requirePermissions={['Administrador', 'Membresia', 'Usuario']}>
              <BeneficiariesReportP />
            </GeneralPage>
          }
        />

        <Route
          path="/beneficiarios/editar/:id"
          element={
            <GeneralPage
              typeVs="vs1"
              requirePermissions={['Administrador', 'Membresia', 'Usuario']}>
              <BeneficiaryRegisterP />
            </GeneralPage>
          }
        />
        <Route path="/comercio/login/:id" element={<ProviderLoginP />} />
        <Route
          path="/comercio/user/dashboard/:id"
          element={
            <GeneralPage
              typeVs="vs3"
              requirePermissions={['Administrador', 'Membresia', 'Usuario', 'Comercio']}>
              <ProviderDashboardP />
            </GeneralPage>
          }
        />
        <Route
          path="/profileInformation"
          element={
            <GeneralPage
              typeVs="vs1"
              requirePermissions={['Administrador', 'Membresia', 'Comercio']}>
              <ProfileInformationP />
            </GeneralPage>
          }
        />
        <Route path="/" element={<CouponsP />} />
        <Route
          path="/infoPersonal"
          element={
            <GeneralPage
              typeVs="vs2"
              requirePermissions={['Administrador', 'Membresia', 'Usuario', 'Beneficiario']}>
              <PersonalInfoP />
            </GeneralPage>
          }
        />
        {/* <Route
          path="/cuponesDashboard"
          element={
            <GeneralPage
              typeVs="vs2"
              requirePermissions={['Administrador', 'Membresia', 'Usuario', 'Beneficiario']}>
              <BeneficiaryDashboardP />
            </GeneralPage>
          }
        /> */}
        {/* aquí se filtraba los cupones
        <Route path="/solicitudCupones/search" element={<SearchBenefitsP />} /> */}
        <Route path="/loginBeneficiario" element={<BeneficiaryLoginP />} />
        <Route path="/restablecer" element={<BeneficiaryRecoveryPasswordP />} />
        <Route path="/registroBeneficiario" element={<OpenBeneficiaryRegisterFormP />} />
      </Routes>
    </ContextProvider>
  );
};

export default App;
