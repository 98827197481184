import { ResponseAddress } from '../../../addresses/address/model/responseAddress';
import { ResponseCustomerValidities } from '../../../customerValidities/model/customerValiditiesResponse';

export class ResponseThirdParties {
  public idThirdParty = 0;

  public idThirdPartyType = 0;

  public idNature = 0;

  public idDocumentType = 0;

  public idGender = 0;

  public identificationNumber = 0;

  public businessName = '';

  public firstName = '';

  public middleName = '';

  public firstSurname = '';

  public secondSurname = '';

  public email = '';

  public cellPhone = 0;

  public telephone = 0;

  public state = 0;

  /**
   * Residence and contact
   */

  public address = new ResponseAddress();

  public customerValidity = new ResponseCustomerValidities();
}
