import 'reflect-metadata';
import { AxiosResponse } from 'axios';
import { ICitiesInfrastructure } from '../../../domain/interface/infrastructure/addresses/ICitiesInfrastructure';
import { RequestCities } from '../../../domain/addresses/cities/model/requestCities';
import { ResponseCities } from '../../../domain/addresses/cities/model/responseCities';
import { processDataBackend } from '../../../../common/utils/functions';

export class CitiesInfrastructure implements ICitiesInfrastructure {
  private a = '';

  public async getCitiesAPI(request: RequestCities): Promise<ResponseCities[]> {
    this.a = 'aaa';

    const res: AxiosResponse = await processDataBackend(
      'GET',
      '/api/cities/getAll',
      {},
      { request }
    );

    const cities: ResponseCities[] = res.data;

    return Promise.resolve(cities);
  }
}
