import { AxiosError } from 'axios';
import { ResponseBenefit } from '../../../core/domain/benefits/benefits/model/responseBenefit';

const actionsBenefitCreate = {
  BENEFIT_CREATE_PAGE_BEGIN: 'BENEFIT_CREATE_PAGE_BEGIN',
  BENEFIT_CREATE_PAGE_SUCCESS: 'BENEFIT_CREATE_PAGE_SUCCESS',
  BENEFIT_CREATE_PAGE_ERR: 'BENEFIT_CREATE_PAGE_ERR',

  benefitCreatePageBegin: (): { type: string } => {
    return {
      type: actionsBenefitCreate.BENEFIT_CREATE_PAGE_BEGIN,
    };
  },

  benefitCreatePageSuccess: (
    data: ResponseBenefit | undefined
  ): { type: string; data: ResponseBenefit | undefined } => {
    return {
      type: actionsBenefitCreate.BENEFIT_CREATE_PAGE_SUCCESS,
      data,
    };
  },

  benefitCreatePageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsBenefitCreate.BENEFIT_CREATE_PAGE_ERR,
      err,
    };
  },
};

const actionsBenefitUpdate = {
  BENEFIT_UPDATE_PAGE_BEGIN: 'BENEFIT_UPDATE_PAGE_BEGIN',
  BENEFIT_UPDATE_PAGE_SUCCESS: 'BENEFIT_UPDATE_PAGE_SUCCESS',
  BENEFIT_UPDATE_PAGE_ERR: 'BENEFIT_UPDATE_PAGE_ERR',

  benefitUpdatePageBegin: (): { type: string } => {
    return {
      type: actionsBenefitUpdate.BENEFIT_UPDATE_PAGE_BEGIN,
    };
  },

  benefitUpdatePageSuccess: (
    data: ResponseBenefit | undefined
  ): { type: string; data: ResponseBenefit | undefined } => {
    return {
      type: actionsBenefitUpdate.BENEFIT_UPDATE_PAGE_SUCCESS,
      data,
    };
  },

  benefitUpdatePageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsBenefitUpdate.BENEFIT_UPDATE_PAGE_ERR,
      err,
    };
  },
};

const actionsBenefitDelete = {
  BENEFIT_DELETE_PAGE_BEGIN: 'BENEFIT_DELETE_PAGE_BEGIN',
  BENEFIT_DELETE_PAGE_SUCCESS: 'BENEFIT_DELETE_PAGE_SUCCESS',
  BENEFIT_DELETE_PAGE_ERR: 'BENEFIT_DELETE_PAGE_ERR',

  benefitDeletePageBegin: (): { type: string } => {
    return {
      type: actionsBenefitDelete.BENEFIT_DELETE_PAGE_BEGIN,
    };
  },

  benefitDeletePageSuccess: (
    data: ResponseBenefit | undefined
  ): { type: string; data: ResponseBenefit | undefined } => {
    return {
      type: actionsBenefitDelete.BENEFIT_DELETE_PAGE_SUCCESS,
      data,
    };
  },

  benefitDeletePageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsBenefitDelete.BENEFIT_DELETE_PAGE_ERR,
      err,
    };
  },
};

const actionsBenefits = {
  BENEFITS_LIST_PAGE_BEGIN: 'BENEFITS_LIST_PAGE_BEGIN',
  BENEFITS_LIST_PAGE_SUCCESS: 'BENEFITS_LIST_PAGE_SUCCESS',
  BENEFITS_LIST_PAGE_ERR: 'BENEFITS_LIST_PAGE_ERR',

  benefitsListPageBegin: (): { type: string } => {
    return {
      type: actionsBenefits.BENEFITS_LIST_PAGE_BEGIN,
    };
  },

  benefitsListPageSuccess: (data: ResponseBenefit[]): { type: string; data: ResponseBenefit[] } => {
    return {
      type: actionsBenefits.BENEFITS_LIST_PAGE_SUCCESS,
      data,
    };
  },

  benefitsListPageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsBenefits.BENEFITS_LIST_PAGE_ERR,
      err,
    };
  },
};

const actionsBenefitsForExcel = {
  BENEFITS_FOR_EXCEL_LIST_PAGE_BEGIN: 'BENEFITS_FOR_EXCEL_LIST_PAGE_BEGIN',
  BENEFITS_FOR_EXCEL_LIST_PAGE_SUCCESS: 'BENEFITS_FOR_EXCEL_LIST_PAGE_SUCCESS',
  BENEFITS_FOR_EXCEL_LIST_PAGE_ERR: 'BENEFITS_FOR_EXCEL_LIST_PAGE_ERR',

  benefitsForExcelListPageBegin: (): { type: string } => {
    return {
      type: actionsBenefitsForExcel.BENEFITS_FOR_EXCEL_LIST_PAGE_BEGIN,
    };
  },

  benefitsForExcelListPageSuccess: (
    data: ResponseBenefit[]
  ): { type: string; data: ResponseBenefit[] } => {
    return {
      type: actionsBenefitsForExcel.BENEFITS_FOR_EXCEL_LIST_PAGE_SUCCESS,
      data,
    };
  },

  benefitsForExcelListPageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsBenefitsForExcel.BENEFITS_FOR_EXCEL_LIST_PAGE_ERR,
      err,
    };
  },
};

const actionsBenefitsRandom = {
  BENEFITS_RANDOM_LIST_PAGE_BEGIN: 'BENEFITS_RANDOM_LIST_PAGE_BEGIN',
  BENEFITS_RANDOM_LIST_PAGE_SUCCESS: 'BENEFITS_RANDOM_LIST_PAGE_SUCCESS',
  BENEFITS_RANDOM_LIST_PAGE_ERR: 'BENEFITS_RANDOM_LIST_PAGE_ERR',

  benefitsRandomListPageBegin: (): { type: string } => {
    return {
      type: actionsBenefitsRandom.BENEFITS_RANDOM_LIST_PAGE_BEGIN,
    };
  },

  benefitsRandomListPageSuccess: (
    data?: ResponseBenefit[]
  ): { type: string; data?: ResponseBenefit[] } => {
    return {
      type: actionsBenefitsRandom.BENEFITS_RANDOM_LIST_PAGE_SUCCESS,
      data,
    };
  },

  benefitsRandomListPageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsBenefitsRandom.BENEFITS_RANDOM_LIST_PAGE_ERR,
      err,
    };
  },
};

const actionsBenefitsWithPagination = {
  BENEFITS_WITH_PAGINATION_LIST_PAGE_BEGIN: 'BENEFITS_WITH_PAGINATION_LIST_PAGE_BEGIN',
  BENEFITS_WITH_PAGINATION_LIST_PAGE_SUCCESS: 'BENEFITS_WITH_PAGINATION_LIST_PAGE_SUCCESS',
  BENEFITS_WITH_PAGINATION_LIST_PAGE_ERR: 'BENEFITS_WITH_PAGINATION_LIST_PAGE_ERR',

  benefitsWithPaginationListPageBegin: (): { type: string } => {
    return {
      type: actionsBenefitsWithPagination.BENEFITS_WITH_PAGINATION_LIST_PAGE_BEGIN,
    };
  },

  benefitsWithPaginationListPageSuccess: (
    data: ResponseBenefit[]
  ): { type: string; data: ResponseBenefit[] } => {
    return {
      type: actionsBenefitsWithPagination.BENEFITS_WITH_PAGINATION_LIST_PAGE_SUCCESS,
      data,
    };
  },

  benefitsWithPaginationListPageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsBenefitsWithPagination.BENEFITS_WITH_PAGINATION_LIST_PAGE_ERR,
      err,
    };
  },
};

const actionsBenefit = {
  BENEFIT_LIST_PAGE_BEGIN: 'BENEFIT_LIST_PAGE_BEGIN',
  BENEFIT_LIST_PAGE_SUCCESS: 'BENEFIT_LIST_PAGE_SUCCESS',
  BENEFIT_LIST_PAGE_ERR: 'BENEFIT_LIST_PAGE_ERR',

  benefitListPageBegin: (): { type: string } => {
    return {
      type: actionsBenefit.BENEFIT_LIST_PAGE_BEGIN,
    };
  },

  benefitListPageSuccess: (
    data: ResponseBenefit | undefined
  ): { type: string; data: ResponseBenefit | undefined } => {
    return {
      type: actionsBenefit.BENEFIT_LIST_PAGE_SUCCESS,
      data,
    };
  },

  benefitListPageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsBenefit.BENEFIT_LIST_PAGE_ERR,
      err,
    };
  },
};

const actionsBenefitsByMembership = {
  BENEFIT_BY_MEMBERSHIP_PAGE_BEGIN: 'BENEFIT_BY_MEMBERSHIP_PAGE_BEGIN',
  BENEFIT_BY_MEMBERSHIP_PAGE_SUCCESS: 'BENEFIT_BY_MEMBERSHIP_PAGE_SUCCESS',
  BENEFIT_BY_MEMBERSHIP_PAGE_ERR: 'BENEFIT_BY_MEMBERSHIP_PAGE_ERR',

  benefitsByMembershipListPageBegin: (): { type: string } => {
    return {
      type: actionsBenefitsByMembership.BENEFIT_BY_MEMBERSHIP_PAGE_BEGIN,
    };
  },

  benefitsByMembershipPageSuccess: (
    data: ResponseBenefit[] | undefined
  ): { type: string; data: ResponseBenefit[] | undefined } => {
    return {
      type: actionsBenefitsByMembership.BENEFIT_BY_MEMBERSHIP_PAGE_SUCCESS,
      data,
    };
  },

  benefitsByMembershipPageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsBenefitsByMembership.BENEFIT_BY_MEMBERSHIP_PAGE_ERR,
      err,
    };
  },
};

const actionsBenefitByBeneficiaries = {
  BENEFIT_BY_BENEFICIARIES_PAGE_BEGIN: 'BENEFIT_BY_BENEFICIARIES_PAGE_BEGIN',
  BENEFIT_BY_BENEFICIARIES_PAGE_SUCCESS: 'BENEFIT_BY_BENEFICIARIES_PAGE_SUCCESS',
  BENEFIT_BY_BENEFICIARIES_PAGE_ERR: 'BENEFIT_BY_BENEFICIARIES_PAGE_ERR',

  benefitByBeneficiariesListPageBegin: (): { type: string } => {
    return {
      type: actionsBenefitByBeneficiaries.BENEFIT_BY_BENEFICIARIES_PAGE_BEGIN,
    };
  },

  benefitByBeneficiariesPageSuccess: (
    data: ResponseBenefit[] | undefined
  ): { type: string; data: ResponseBenefit[] | undefined } => {
    return {
      type: actionsBenefitByBeneficiaries.BENEFIT_BY_BENEFICIARIES_PAGE_SUCCESS,
      data,
    };
  },

  benefitByBeneficiariesPageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsBenefitByBeneficiaries.BENEFIT_BY_BENEFICIARIES_PAGE_ERR,
      err,
    };
  },
};

const actionsBenefitByThirdParty = {
  BENEFIT_BY_THIRDPARTY_PAGE_BEGIN: 'BENEFIT_BY_THIRDPARTY_PAGE_BEGIN',
  BENEFIT_BY_THIRDPARTY_PAGE_SUCESS: 'BENEFIT_BY_THIRDPARTY_PAGE_SUCESS',
  BENEFIT_BY_THIRDPARTY_PAGE_ERR: 'BENEFIT_BY_THIRDPARTY_PAGE_ERR',

  benfitByThirdPartyListPageBeguin: (): { type: string } => {
    return {
      type: actionsBenefitByThirdParty.BENEFIT_BY_THIRDPARTY_PAGE_BEGIN
    }
  },

  benefitByThirdPartyPageSuccess: (
    data: ResponseBenefit[] | undefined
  ): { type: string; data: ResponseBenefit[] | undefined } => {
    return {
      type: actionsBenefitByThirdParty.BENEFIT_BY_THIRDPARTY_PAGE_SUCESS,
      data
    }
  },

  benefitByThirdPartyErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsBenefitByThirdParty.BENEFIT_BY_THIRDPARTY_PAGE_ERR,
      err
    }
  }
}

export {
  actionsBenefitCreate,
  actionsBenefitUpdate,
  actionsBenefitDelete,
  actionsBenefit,
  actionsBenefits,
  actionsBenefitsForExcel,
  actionsBenefitsWithPagination,
  actionsBenefitsRandom,
  actionsBenefitsByMembership,
  actionsBenefitByBeneficiaries,
  actionsBenefitByThirdParty
};
