import 'reflect-metadata';
import { container } from 'tsyringe';
import { IKinshipsApplication } from '../../../domain/interface/application/documentsPersons/IKinshipsApplication';
import { IKinshipsInfrastructure } from '../../../domain/interface/infrastructure/documentsPersons/IKinshipsInfrastructure';
import { ResponseKinships } from '../../../domain/contacts/kinships/model/responseKinships';
import { RequestKinships } from '../../../domain/contacts/kinships/model/requestKinships';

export class KinshipsApplication implements IKinshipsApplication {
  private kinshipsInfrastructure: IKinshipsInfrastructure;

  public constructor() {
    this.kinshipsInfrastructure =
      container.resolve<IKinshipsInfrastructure>('IKinshipsInfrastructure');
  }

  public async getKinships(request: RequestKinships): Promise<ResponseKinships[]> {
    const result = await this.kinshipsInfrastructure.getKinshipsAPI(request);
    return result;
  }
}
