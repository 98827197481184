import { ResponseThirdParties } from '../../thirdParties/model/thirdPartiesResponse';

export class ResponseThirdPartiesBeneficiaries {
  public idThirdPartyBeneficiary? = 0;

  public idThirdParty? = 0;

  public idBeneficiary? = 0;

  public state? = 0;

  public thirdParty? = new ResponseThirdParties();
}
