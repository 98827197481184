import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import {
  clearThirdPartyCustomerDeleteListPage,
  deleteThirdPartiesCustomerPage,
  thirdPartiesProvidersListPage,
} from '../../../../../redux/thirdParties/actionCreator';
import { TDispatch } from '../../../../../common/utils/types.d';
import { constructorName, phoneFormat } from '../../../../../common/utils/functions';
import ProvidersListV from '../../../components/providers/providersList';
import { IThirdPartiesMap } from './types.d';
import {
  TDeleteThirdPartyCustomerReducer,
  TThirdPartiesProvidersReducer,
} from '../../../../../redux/thirdParties/types.d';
import { toast } from 'react-toastify';

export const ProvidersListC = () => {
  /**
   * UseStates
   */
  const [thirdPartiesData, setThirdPartiesData] = useState<IThirdPartiesMap[]>([]);
  const [dataId, setDataId] = useState<number>();
  const [openDelete, setOpenDelete] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  /**
   * Dispatch
   */
  const dispatch = useDispatch();

  /**
   * Dispatch de Obtener todos los comercios
   */
  const getAllThirdPartiesProviders: TThirdPartiesProvidersReducer = useSelector(
    (dataState: RootState) => dataState?.thirdPartiesProviders
  );
  /**
   * Dispatch de eliminar los comercios
   */
  const deleteThirdPartiesCustomerDispatch: TDeleteThirdPartyCustomerReducer = useSelector(
    (dataState: RootState) => dataState?.thirdPartyCustomerDeleted
  );

  /**
   * Función para eliminar un registro
   */
  const handleDelete = async () => {
    setDisabled(true);
    await dispatch(
      deleteThirdPartiesCustomerPage({
        idThirdParty: Number(dataId),
      }) as TDispatch
    );
    await dispatch(thirdPartiesProvidersListPage() as TDispatch);
    setOpenDelete(false);
    setLoading(true);
  };

  /**
   * Función que se encarga de cerrar el modal de confirmación
   */
  const handleClose = () => {
    setOpenDelete(false);
  };

  /**
   * Función que se encarga de abrir el modal de confirmación para poder eliminar un registro
   */
  const buttonDelete = async (datos: number) => {
    setDataId(datos);
    setOpenDelete(true);
  };

  /**
   * UseEffect pendiente al momento de eliminar un beneficio existente
   */
  useEffect(() => {
    if (dataId && loading) {
      if (
        deleteThirdPartiesCustomerDispatch?.thirdPartyCustomerDeleted &&
        !deleteThirdPartiesCustomerDispatch?.error
      ) {
        toast.success('comercio eliminado exitosamente', {
          hideProgressBar: true,
        });
        dispatch(clearThirdPartyCustomerDeleteListPage() as TDispatch);
        setTimeout(() => {
          setDisabled(false);
        }, 2000);
      } else if (
        !deleteThirdPartiesCustomerDispatch?.thirdPartyCustomerDeleted &&
        deleteThirdPartiesCustomerDispatch?.error
      ) {
        const message = deleteThirdPartiesCustomerDispatch?.error?.response
          ? deleteThirdPartiesCustomerDispatch?.error?.response?.data?.message
          : 'Ha ocurrido una incidencia';
        toast.error(`${message}`, {
          hideProgressBar: true,
        });
        dispatch(clearThirdPartyCustomerDeleteListPage() as TDispatch);
        setDisabled(false);
      }
    }
  }, [
    deleteThirdPartiesCustomerDispatch?.thirdPartyCustomerDeleted,
    deleteThirdPartiesCustomerDispatch?.error,
    loading,
    dispatch,
    dataId,
  ]);

  /**
   * UseEffect que se encarga de mostrar la información en la Lista de comercios
   */
  useEffect(() => {
    if (getAllThirdPartiesProviders?.thirdPartiesProviders instanceof Array) {
      const data = getAllThirdPartiesProviders?.thirdPartiesProviders?.map(
        (x: IThirdPartiesMap, i: number) => {
          const ID = i + 1;
          return {
            ...x,
            cellPhone: phoneFormat(String(x?.cellPhone)),
            names:
              x?.idNature === 1
                ? constructorName([x?.firstName, x?.middleName, x?.firstSurname, x?.secondSurname])
                : x?.businessName,
            ID: !x ? null : ID,
          };
        }
      );
      setThirdPartiesData(data);
    }
  }, [getAllThirdPartiesProviders?.thirdPartiesProviders]);

  /**
   * UseEffect que se encarga de llamar a todos los dispatches
   */
  useEffect(() => {
    dispatch(thirdPartiesProvidersListPage() as TDispatch);
    dispatch(clearThirdPartyCustomerDeleteListPage() as TDispatch);
  }, [dispatch]);

  return (
    <ProvidersListV
      data={thirdPartiesData}
      loading={getAllThirdPartiesProviders?.loading}
      handleDelete={handleDelete}
      handleClose={handleClose}
      deleteLoading={deleteThirdPartiesCustomerDispatch?.loading}
      buttonDelete={buttonDelete}
      openDelete={openDelete}
      disabled={disabled}
    />
  );
};
