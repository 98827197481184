import { PaletteOptions } from '@mui/material';
import { /* useTheme */ } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    gradientBackground: {
      main: string;
    };
  }
  interface PaletteOptions {
    gradientBackground?: {
      main: string;
    };
  }
}

interface ExtendedPaletteOptions extends PaletteOptions {
  gradientBackground: {
    main: string;
  };
}

export const Colors: ExtendedPaletteOptions = {
  primary: {
    main: '#2F2AA5',
    dark: '#1a168a',
    light: '#0EB5E2',
    contrastText: '#D0D0D0',
  },
  secondary: {
    main:'#7E39AD',
    dark: '#501C95',
    light: '#E9DAFC',
    contrastText: '#3E5159',
  },
  warning: {
    main: '#EF5148',
    dark: '#DB3D34',
    contrastText: '#F6C5C2',
  },
  common: {
    white: '#ffff',
  },
  gradientBackground: {
    main: 'linear-gradient(87deg, rgba(80,28,149,1) 0%, rgba(14,181,226,1) 87%); 1!important',
  },
};
