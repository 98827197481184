import { AxiosError } from 'axios';
import { ResponseBankAccountTypes } from '../../../core/domain/banks/bankAccountTypes/model/responseBankAccountTypes';
import { BankAccountTypesState } from '../../../core/domain/banks/bankAccountTypes/state/bankAccountTypes';
import actions from './actions';
import { TBanksAccountTypesReducer } from './types.d';

const {
  BANK_ACCOUNT_TYPES_LIST_PAGE_BEGIN,
  BANK_ACCOUNT_TYPES_LIST_PAGE_SUCCESS,
  BANK_ACCOUNT_TYPES_LIST_PAGE_ERR,
} = actions;

const initialBankAccountTypes: BankAccountTypesState = new BankAccountTypesState();

const BankAccountTypesReducer = (
  state = initialBankAccountTypes,
  action: { type: string; err: AxiosError; data: ResponseBankAccountTypes[] }
): TBanksAccountTypesReducer => {
  const { type, data, err } = action;
  switch (type) {
    case BANK_ACCOUNT_TYPES_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BANK_ACCOUNT_TYPES_LIST_PAGE_SUCCESS:
      return {
        ...state,
        bankAccountTypes: data,
        loading: false,
      };
    case BANK_ACCOUNT_TYPES_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default BankAccountTypesReducer;
