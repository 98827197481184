import { AxiosResponse } from 'axios';
import { processDataBackend } from '../../../../common/utils/functions';
import { IBenefitValidityInfrastructure } from '../../../domain/interface/infrastructure/benefits/IBenefitValidityInfrastructure';
import { ResponseBenefitValidity } from '../../../domain/benefits/benefitValidities/model/responseBenefitValidity';
import { RequestBenefitValidity } from '../../../domain/benefits/benefitValidities/model/requestBenefitValidity';

export class BenefitValidityInfrastructure implements IBenefitValidityInfrastructure {
  private a = '';

  public async createBenefitValidityAPI(
    request: RequestBenefitValidity
  ): Promise<ResponseBenefitValidity> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to create not found');
    const res: AxiosResponse = await processDataBackend(
      'POST',
      '/api/benefitValidity/create',
      {},
      { request }
    );
    const result: ResponseBenefitValidity = res.data;
    return Promise.resolve(result);
  }
}
