import 'reflect-metadata';
import { container } from 'tsyringe';
import { ResponseBeneficiaries } from '../../domain/beneficiaries/model/responseBeneficiaries';
import { IBenefitBeneficiariesInfrastructure } from '../../domain/interface/infrastructure/benefitBeneficiaries/IBenefitBeneficiariesInfrastructure';
import { IValidateBenefitApplication } from '../../domain/interface/application/benefitBeneficiaries/IValidateBenefitApplication';

export class ValidateBenefitApplication implements IValidateBenefitApplication {
  private benefitBeneficiariesInfrastructure: IBenefitBeneficiariesInfrastructure;

  public constructor() {
    this.benefitBeneficiariesInfrastructure =
      container.resolve<IBenefitBeneficiariesInfrastructure>('IBenefitBeneficiariesInfrastructure');
  }

  public async validateBenefit(request: {
    idBenefitBeneficiary: number;
  }): Promise<ResponseBeneficiaries> {
    const result = await this.benefitBeneficiariesInfrastructure.validateBenefitAPI(request);
    return result;
  }
}
