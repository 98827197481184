import { AxiosError } from 'axios';
import { ResponseGender } from '../../../core/domain/documentsPersons/gender/model/responseGender';
import { GenderState } from '../../../core/domain/documentsPersons/gender/state/genderState';
import actions from './actions';
import { TGenderReducer } from './types';

const { GENDER_LIST_PAGE_BEGIN, GENDER_LIST_PAGE_SUCCESS, GENDER_LIST_PAGE_ERR } = actions;

const initialGender: GenderState = new GenderState();

const GenderReducer = (
  state = initialGender,
  action: { type: string; err: AxiosError; data: ResponseGender[] }
): TGenderReducer => {
  const { type, data, err } = action;
  switch (type) {
    case GENDER_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GENDER_LIST_PAGE_SUCCESS:
      return {
        ...state,
        gender: data,
        loading: false,
      };
    case GENDER_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default GenderReducer;
