import 'reflect-metadata';
import { AxiosResponse } from 'axios';
import { processDataBackend } from '../../../common/utils/functions';
import { IBeneficiariesInfrastructure } from '../../domain/interface/infrastructure/beneficiaries/IBeneficiariesInfrastructure';
import { RequestBeneficiaries } from '../../domain/beneficiaries/model/requestBeneficiaries';
import { ResponseBeneficiaries } from '../../domain/beneficiaries/model/responseBeneficiaries';

export class BeneficiariesInfrastructure implements IBeneficiariesInfrastructure {
  private a = '';

  public async getBeneficiariesAPI(
    request: RequestBeneficiaries
  ): Promise<ResponseBeneficiaries[]> {
    this.a = 'aaa';
    const res: AxiosResponse = await processDataBackend(
      'GET',
      '/api/beneficiaries/getAll',
      {},
      { request }
    );
    const beneficiaries: ResponseBeneficiaries[] = res.data;
    return Promise.resolve(beneficiaries);
  }

  public async getBeneficiariesCustomersAPI(
    request: RequestBeneficiaries
  ): Promise<ResponseBeneficiaries[]> {
    this.a = 'aaa';
    const res: AxiosResponse = await processDataBackend(
      'GET',
      '/api/beneficiaries/getAllBeneficiariesCustomers',
      {},
      { request }
    );
    const beneficiaries: ResponseBeneficiaries[] = res.data;
    return Promise.resolve(beneficiaries);
  }

  public async getBeneficiaryByIdAPI(
    request: RequestBeneficiaries
  ): Promise<ResponseBeneficiaries> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to getById not found');
    const res: AxiosResponse = await processDataBackend(
      'POST',
      '/api/beneficiaries/getById',
      {},
      { request }
    );
    const result: ResponseBeneficiaries = res.data;

    return Promise.resolve(result);
  }

  public async createBeneficiaryAPI(request: RequestBeneficiaries): Promise<ResponseBeneficiaries> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to create not found');
    const res: AxiosResponse = await processDataBackend(
      'POST',
      '/api/beneficiaries/create',
      {},
      { request }
    );
    const result: ResponseBeneficiaries = res.data;
    return Promise.resolve(result);
  }

  public async updateBeneficiaryAPI(request: RequestBeneficiaries): Promise<ResponseBeneficiaries> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to update not found');
    const res: AxiosResponse = await processDataBackend(
      'PUT',
      '/api/beneficiaries/update',
      {},
      { request }
    );
    const result: ResponseBeneficiaries = res.data;
    return Promise.resolve(result);
  }

  public async deleteBeneficiaryAPI(request: RequestBeneficiaries): Promise<ResponseBeneficiaries> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to delete not found');
    const res: AxiosResponse = await processDataBackend(
      'PUT',
      '/api/beneficiaries/delete',
      {},
      { request }
    );
    const result: ResponseBeneficiaries = res.data;
    return Promise.resolve(result);
  }

  public async bulkCreateBeneficiaryAPI(
    request: RequestBeneficiaries[]
  ): Promise<ResponseBeneficiaries[]> {
    this.a = 'aaa';
    const res: AxiosResponse = await processDataBackend(
      'POST',
      '/api/beneficiaries/massiveLoader',
      {},
      { request }
    );
    const beneficiary: ResponseBeneficiaries[] = res.data;
    return Promise.resolve(beneficiary);
  }

  public async getBeneficiaryForBenefitAPI(
    identificationNumber: number
  ): Promise<ResponseBeneficiaries> {
    this.a = 'aaa';
    const res: AxiosResponse = await processDataBackend(
      'GET',
      `/api/beneficiaries/getByBeneficiaryBenefits/${identificationNumber}`,
      {},
      {}
    );
    const beneficiary: ResponseBeneficiaries = res.data;
    return Promise.resolve(beneficiary);
  }

}
