import React from 'react';
import './styles.css';
import {
  Box,
  ButtonBase,
  Grid,
  Typography,
  useTheme,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Button,
} from '@mui/material';
import {
  SpaOutlined,
  RestaurantOutlined,
  SportsEsportsOutlined,
  SchoolOutlined,
  FlightTakeoffOutlined,
  LocalOfferOutlined,
  HomeOutlined,
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { SearchOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { Categories } from './style';
import { Container, Header, ContentLogo, ImgLogo, ImgBonus, ButtonBonus } from './style';
import { InputFieldSearch, NormalSelectField } from '../../../../common/components/formAdapters';
import { DetailsCouponsV } from './detailsDataModal';
import { TBenefitsShow } from './types.d';
import { TransitionsModalBenefits } from '../../../../common/components/modalBenefits';
import { DropdownHeaderCouponsV } from './DropdownHeaderCoupons';
import image from '../../../../common/components/images';
import { capitalizeTheFirstLetter } from '../../../../common/utils/functions';

export const CouponsV: React.FC<TBenefitsShow> = ({
  control,
  schema,
  dataDepartments,
  handleClearSelections,
  openModal,
  inputState,
  dataCities,
  isLargeScreen,
  handleInputBlur,
  handleInputFocus,
  watchDepartment,
  sendConfirm,
  benefitsDataMapped,
  benefitCategoryData,
  HandleSearchItemByCategory,
  handleImage,
  detailsCouponsModal,
  handleCategory,
  redeemBenefit,
  loadingCreate,
  open,
  handleMouseEnter,
  handleMouseLeave,
  stringAvatar,
  anchorEl,
  handleSessionCouponsPage,
  goToPersonalInfo,
  userCredentials,
  goToLoginBeneficiary,
  goToCouponsDashboard,
  handleSubmitUpdatePassword,
  controlUpdatePassword,
  schemaUpdatePassword,
  updateLoading,
  validatePassword,
  handleUpdatePassword,
  validationErrors,
  sendChangePassword,
  openModalPassword,
  headerType,
  handleBenefitCategory,
  isAuthenticated,
  disableBenefit,
  qrOpen,
  qrCode,
  closeQrCode,
  showQrCode,
  openCategories,
  handleCloseCategories,
  handleClickCategories,
  anchorElCategories,
}) => {
  const theme = useTheme();
  const LogoBonusColor = image('logoBonusColor');

  // estilos
  const style = {
    contentBonus: {
      height: '313px',
      maxWidth: '306px',
      gap: '5px',
      backgroundColor: 'white',
      boxShadow: '0px 5px 10px #00000029',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderRadius: '10px',
      '@media (max-width:  600px)': {
        height: '232px',
        gap: '0px',
      },
    },
    contentImg: {
      height: '50%',
      minHeight: '180px',
      width: '100%',
      display: 'flex',
      position: 'relative',
      '@media (max-width:  600px)': {
        height: '133px',
        minHeight: '133px',
      },
    },
    contentTex: {
      padding: '0',
      width: '90%',
      height: '70px',
      maxHeight: '30%',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      '@media (max-width:  600px)': {
        height: '55px',
      },
    },
    description: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '18px',
      '@media (max-width: 600px)': { fontSize: '15px' },
    },
    title: {
      margin: '0',
      fontSize: '16px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: '#474c4fe3',
      fontWeight: 550,
      '@media (max-width: 600px)': { fontSize: '15px' },
    },
    boxButtonBaseCategories: {
      display: 'flex',
      justifyContent: 'left',
      height: '40px',
      gap: '10px',
      color: `${theme.palette.primary.light}`,
    },
    contentInput: {
      width: '40%',
      minWidth: '350px',
      maxWidth: '775px',
      marginRight: '4%',
      '@media (max-width:  900px)': {
        marginRight: '0px',
      },
      '@media (max-width:  700px)': {
        width: '80%',
        minWidth: '80%',
      },
    },
    input: {
      width: '100%',
    },
    boxCommon: {
      gap: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    categoriesContainer: {
      width: '250px',
      minWidth: '250px',
      display: 'flex',
      alignItems: 'left',
      justifyContent: 'top',
      padding: '20px 0 0 20px',
      flexDirection: 'column',
      gap: '50px',
      '@media (max-width: 700px)': { display: 'none' },
    },
    boxContentScrollable: {
      width: '100%',
      height: '100%',
      display: 'flex',
      overflowY: 'auto',
    },
    boxContentBenefit: {
      boxSizing: 'border-box',
      height: 'auto',
      width: '80%',
      maxWidth: '1288px',
      padding: '20px',
      '@media (max-width:  900px)': { width: '100%', maxWidth: '550px' },
    },
    gridContainer: {
      container: true,
      columnSpacing: 2,
      rowSpacing: 2,
      columns: { xs: 12, sm: 8, md: 9, lg: 12 },
    },
    cardContent: {
      padding: '0',
      width: '90%',
      maxHeight: '30%',
      overflow: 'hidden',
    },
    categoryMenuButton: {
      display: 'none',
      '@media (max-width:  700px)': { display: 'flex' },
    },
    menuItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '10px',
    },
    buttomClean: {
      background: theme.palette.primary.light,
      width: '100%',
      height: '30px',
      fontSize: '12px',
      color: 'white',
      '&:hover': {
        background: theme.palette.primary.main,
      },
    },
  };

  return (
    <Container>
      <Header
        backgroundColor={theme.palette.common.white}
        style={inputState ? style.boxCommon : {}}>
        <Box sx={{ display: 'flex' }}>
          <IconButton
            sx={style.categoryMenuButton}
            aria-haspopup="true"
            onClick={handleClickCategories}>
            <MenuIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorElCategories}
            keepMounted
            open={openCategories}
            onClose={handleCloseCategories}
            PaperProps={{
              style: {
                maxHeight: '80%',
                width: '200px',
              },
            }}>
            {(benefitCategoryData || []).map((category) => (
              <MenuItem
                key={category.idCategory}
                onClick={() => {
                  handleCategory(Number(category.idCategory));
                  handleCloseCategories();
                }}>
                <Typography
                  sx={{
                    color: category.active
                      ? theme.palette.primary.light
                      : theme.palette.secondary.contrastText,
                  }}>
                  {category.name}
                </Typography>
              </MenuItem>
            ))}
            <Divider />
            <MenuItem sx={style.menuItem}>
              <NormalSelectField
                name="address.idDepartment"
                label="Departamento"
                key="address.idDepartment"
                schema={schema?.fields?.address?.idDepartment}
                control={control}
                options={
                  (dataDepartments || [])?.map((x) => ({
                    value: x?.idDepartment,
                    label: x?.name?.toUpperCase(),
                  })) || []
                }
              />
            </MenuItem>
            <MenuItem sx={style.menuItem}>
              <NormalSelectField
                name="address.idCity"
                label="Ciudad"
                key="address.idCity"
                schema={schema?.fields?.address?.idCity}
                control={control}
                options={
                  (dataCities || [])
                    ?.filter((x) => x?.idDepartment === Number(watchDepartment))
                    .map((x) => {
                      return {
                        value: x?.idCity,
                        label: x?.name?.toUpperCase(),
                      };
                    }) || []
                }
                disabled={!watchDepartment}
              />
            </MenuItem>
            <MenuItem>
              <Button sx={style.buttomClean} onClick={handleClearSelections}>
                Limpiar
              </Button>
            </MenuItem>
          </Menu>

          {!inputState && (
            <ContentLogo>
              <Link to="/" onClick={() => handleCategory(1)}>
                <ImgLogo src={LogoBonusColor} />
              </Link>
            </ContentLogo>
          )}
        </Box>
        <Box sx={inputState || isLargeScreen ? style.contentInput : {}}>
          {(inputState || isLargeScreen) && (
            <InputFieldSearch
              onFocus={isLargeScreen ? undefined : handleInputFocus}
              onBlur={isLargeScreen ? undefined : handleInputBlur}
              autoFocus={inputState}
              control={control}
              color="secondary"
              placeholder="Buscar"
              variant="outlined"
              type="text"
              name="searchBenefits"
              iconType="search"
              functionIcon={HandleSearchItemByCategory}
              schema={schema.fields.searchBenefits}
            />
          )}
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {!inputState && !isLargeScreen && (
            <SearchOutlined
              style={{ color: theme.palette.primary.main }}
              onClick={handleInputFocus}
            />
          )}
          <DropdownHeaderCouponsV
            open={open}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            stringAvatar={stringAvatar}
            anchorEl={anchorEl}
            handleSessionCouponsPage={handleSessionCouponsPage}
            goToPersonalInfo={goToPersonalInfo}
            goToLoginBeneficiary={goToLoginBeneficiary}
            goToCouponsDashboard={goToCouponsDashboard}
            userCredentials={userCredentials}
            handleBenefitCategory={handleBenefitCategory}
            sendChangePassword={sendChangePassword}
            headerType={headerType}
            control={control}
            handleSubmitUpdatePassword={handleSubmitUpdatePassword}
            schemaUpdatePassword={schemaUpdatePassword}
            controlUpdatePassword={controlUpdatePassword}
            handleUpdatePassword={handleUpdatePassword}
            updateLoading={updateLoading}
            validatePassword={validatePassword}
            validationErrors={validationErrors}
            openModalPassword={openModalPassword}
          />
        </Box>
      </Header>

      <Box sx={style.boxContentScrollable}>
        <Box sx={style.categoriesContainer}>
          <Categories>
            <Typography
              variant="h6"
              sx={{ marginBottom: '10px', color: theme.palette.text.primary }}>
              Categorías
            </Typography>
            {(benefitCategoryData || []).map((x) => (
              <ButtonBase
                key={x.idCategory}
                component="button"
                onClick={() => handleCategory(Number(x?.idCategory))}
                sx={style.boxButtonBaseCategories}>
                {(x?.idCategory === 1 && (
                  <HomeOutlined
                    sx={{
                      color: `${x?.active ? '' : theme.palette.secondary.contrastText}`,
                    }}
                  />
                )) ||
                  (x?.idCategory === 2 && (
                    <RestaurantOutlined
                      sx={{
                        color: `${x?.active ? '' : theme.palette.secondary.contrastText}`,
                      }}
                    />
                  )) ||
                  (x?.idCategory === 3 && (
                    <SpaOutlined
                      sx={{
                        color: `${x?.active ? '' : theme.palette.secondary.contrastText}`,
                      }}
                    />
                  )) ||
                  (x?.idCategory === 4 && (
                    <SportsEsportsOutlined
                      sx={{
                        color: `${x?.active ? '' : theme.palette.secondary.contrastText}`,
                      }}
                    />
                  )) ||
                  (x?.idCategory === 5 && (
                    <SchoolOutlined
                      sx={{
                        color: `${x?.active ? '' : theme.palette.secondary.contrastText}`,
                      }}
                    />
                  )) ||
                  (x?.idCategory === 6 && (
                    <FlightTakeoffOutlined
                      sx={{
                        color: `${x?.active ? '' : theme.palette.secondary.contrastText}`,
                      }}
                    />
                  )) ||
                  (x?.idCategory === 7 && (
                    <LocalOfferOutlined
                      sx={{
                        color: `${x?.active ? '' : theme.palette.secondary.contrastText}`,
                      }}
                    />
                  ))}
                <Typography
                  fontSize="16px"
                  sx={{
                    whiteSpace: 'nowrap',
                    color: `${x?.active ? '' : theme.palette.secondary.contrastText}`,
                  }}>
                  {x.name}
                </Typography>
              </ButtonBase>
            ))}
          </Categories>
          <Box
            sx={{
              width: '70%',
              display: 'flex',
              flexDirection: 'column',
              gap: '15px',
              alignItems: 'left',
            }}>
            <Typography
              variant="h6"
              sx={{ marginBottom: '10px', color: theme.palette.text.primary }}>
              Ubicación
            </Typography>
            <NormalSelectField
              name="address.idDepartment"
              label="Departamento"
              key="address.idDepartment"
              schema={schema?.fields?.address?.idDepartment}
              control={control}
              options={
                (dataDepartments || [])?.map((x) => ({
                  value: x?.idDepartment,
                  label: x?.name?.toUpperCase(),
                })) || []
              }
            />
            <NormalSelectField
              name="address.idCity"
              label="Ciudad"
              key="address.idCity"
              schema={schema?.fields?.address?.idCity}
              control={control}
              options={
                (dataCities || [])
                  ?.filter((x) => x?.idDepartment === Number(watchDepartment))
                  .map((x) => {
                    return {
                      value: x?.idCity,
                      label: x?.name?.toUpperCase(),
                    };
                  }) || []
              }
              disabled={!watchDepartment}
            />
            <Button sx={style.buttomClean} onClick={handleClearSelections}>
              Limpiar
            </Button>
          </Box>
        </Box>
        <Box sx={style.boxContentBenefit}>
          <Grid {...style.gridContainer}>
            {(benefitsDataMapped || []).map((x: any) => (
              <Grid key={x?.idBenefit} item xs={6} sm={4} md={3} lg={3}>
                <Box sx={style.contentBonus}>
                  <Box sx={style.contentImg}>
                    <ImgBonus src={x?.path}></ImgBonus>
                  </Box>
                  <CardContent sx={style.contentTex}>
                    <Typography sx={style.title} gutterBottom variant="h6" component="h2">
                      {capitalizeTheFirstLetter(x?.name)}
                    </Typography>
                    <Typography
                      className="hide-scrollbar"
                      sx={style.description}
                      variant="body2"
                      color="textSecondary"
                      component="p">
                      {capitalizeTheFirstLetter(x?.trade)}
                    </Typography>
                  </CardContent>
                  <ButtonBonus
                    backgroundColor={theme.palette.gradientBackground.main}
                    onClick={() => sendConfirm(handleImage(x?.idBenefit))}>
                    Redimir
                  </ButtonBonus>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      <TransitionsModalBenefits
        state={openModal}
        overflow="auto"
        handleCloseModal={() => sendConfirm()}
        closeQrCode={closeQrCode}>
        <DetailsCouponsV
          loadingCreate={loadingCreate}
          redeemBenefit={redeemBenefit}
          detailsCouponsModal={detailsCouponsModal}
          isAuthenticated={isAuthenticated}
          disableBenefit={disableBenefit}
          showQrCode={showQrCode}
          qrOpen={qrOpen}
          qrCode={qrCode}
        />
      </TransitionsModalBenefits>
    </Container>
  );
};
