export class RequestCustomerValidities {
  public idCustomerValidity? = 0;

  public idThirdParty? = 0;

  public idSeller: number | null = 0;

  public acquiredBeneficiary? = 0;

  public consumedBeneficiary? = 0;

  public startDate? = '';

  public endDate? = '';

  public amountPaid? = 0;

  public detail? = '';

  public condition? = 0;
}
