import 'reflect-metadata';
import { AxiosResponse } from 'axios';
import { IUserSessionInfrastructure } from '../../../domain/interface/infrastructure/userPermissions/IUserSessionInfrastructure';
import { ResponseUserSession } from '../../../domain/userPermissions/userSession/model/responseUserSession';
import { processDataBackend } from '../../../../common/utils/functions';

export class UserSessionInfrastructure implements IUserSessionInfrastructure {
  private a = '';

  public async validateUserSessionAPI(token: string): Promise<ResponseUserSession> {
    this.a = 'aaa';

    const res: AxiosResponse = await processDataBackend(
      'POST',
      '/api/userSession/validateSession',
      {},
      { request: { token } }
    );
    const result: ResponseUserSession = res.data;

    return Promise.resolve(result);
  }

  public async deleteUserSessionAPI(token: string): Promise<ResponseUserSession> {
    this.a = 'aaa';

    const res: AxiosResponse = await processDataBackend(
      'POST',
      '/api/userSession/deleteSession',
      {},
      { request: { token } }
    );
    const result: ResponseUserSession = res.data;

    return Promise.resolve(result);
  }
}
