import { ResponseResponsibilities } from '../model/responseResponsibilities';

export class ResponsibilitiesState {
  public constructor() {
    this.responsibilities = [new ResponseResponsibilities()];
  }

  public name = '';

  public responsibilities: ResponseResponsibilities[];
}
