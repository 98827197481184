import React, { ReactNode, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Loading from '../../../common/components/loading';
import { Context } from '../../../common/utils/userContext';
import { decryptData } from '../../../common/utils/functions';
import LayoutC from '../containers/Layout';
import NotFoundData from '../../../common/utils/notFound';

interface GeneralPageProps {
  children: ReactNode;
  typeVs: string;
  requirePermissions?: string[];
}

export const GeneralPage: React.FC<GeneralPageProps> = ({
  children,
  typeVs,
  requirePermissions,
}) => {
  const location = useLocation();
  const { sessionLoading, handleTokenType, isLogged, tokenType } = useContext(Context);
  let hasPermission;

  if (isLogged) {
    const lsPermissions = decryptData(
      window.localStorage.getItem(`${tokenType}-tux`) || '',
      '3573n035uN70K3n'
    );

    const permissions = lsPermissions.split(';');

    hasPermission = requirePermissions?.some((permission: string) =>
      permissions.includes(permission)
    );
  }

  useEffect(() => {
    handleTokenType(typeVs);
  }, [location, handleTokenType, typeVs]);

  return sessionLoading ? (
    <Loading />
  ) : (
    <>
      {isLogged && hasPermission ? (
        children
      ) : (
        <LayoutC>
          <NotFoundData />
        </LayoutC>
      )}
      {!isLogged && <h1>Not found</h1>}
    </>
  );
};
