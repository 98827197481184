import { AxiosError } from 'axios';
import { container } from 'tsyringe';
import { IBranchsOfficesApplication } from '../../core/domain/interface/application/branchsOffices/IBranchsOfficesApplication';
import {
  actionsBranchOffice,
  actionsBranchsOffices,
  actionsBranchsOfficesCreate,
  actionsBranchsOfficesDelete,
  actionsBranchsOfficesUpdate,
} from './actions';
import { RequestBranchsOffices } from '../../core/domain/branchsOffices/model/branchsOfficesRequest';
import { AppDispatch } from '../store';

const {
  branchsOfficesCreatePageBegin,
  branchsOfficesCreatePageSuccess,
  branchsOfficesCreatePageErr,
} = actionsBranchsOfficesCreate;
const {
  branchsOfficesDeletePageBegin,
  branchsOfficesDeletePageSuccess,
  branchsOfficesDeletePageErr,
} = actionsBranchsOfficesDelete;
const { branchsOfficesListPageBegin, branchsOfficesListPageSuccess, branchsOfficesListPageErr } =
  actionsBranchsOffices;
const {
  branchsOfficesUpdatePageBegin,
  branchsOfficesUpdatePageSuccess,
  branchsOfficesUpdatePageErr,
} = actionsBranchsOfficesUpdate;
const { branchOfficeListPageBegin, branchOfficeListPageSuccess, branchOfficeListPageErr } =
  actionsBranchOffice;

export const branchsOfficesListPage = (): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(branchsOfficesListPageBegin());
      const branchsOfficesApplication = container.resolve<IBranchsOfficesApplication>(
        'IBranchsOfficesApplication'
      );

      const request = new RequestBranchsOffices();
      const result = await branchsOfficesApplication.getBranchsOffices(request);

      dispatch(branchsOfficesListPageSuccess(result));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(branchsOfficesListPageErr(error));
    }
  };
};

export const getBranchOfficeByIdListPage = (
  listState: RequestBranchsOffices
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(branchOfficeListPageBegin());
      const branchOfficeApplication = container.resolve<IBranchsOfficesApplication>(
        'IBranchsOfficesApplication'
      );

      const resultGetById = await branchOfficeApplication.getBranchOfficeById(listState);
      dispatch(branchOfficeListPageSuccess(resultGetById));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(branchOfficeListPageErr(error));
    }
  };
};

export const createBranchOfficePage = (
  listState: any
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(branchsOfficesCreatePageBegin());
      const branchsOfficesApplication = container.resolve<IBranchsOfficesApplication>(
        'IBranchsOfficesApplication'
      );

      const resultCreate = await branchsOfficesApplication.createBranchsOffices(listState);
      dispatch(branchsOfficesCreatePageSuccess(resultCreate));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(branchsOfficesCreatePageErr(error));
    }
  };
};

export const updateBranchOfficePage = (
  listState: RequestBranchsOffices
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(branchsOfficesUpdatePageBegin());
      const branchsOfficesApplication = container.resolve<IBranchsOfficesApplication>(
        'IBranchsOfficesApplication'
      );

      const resultUpdate = await branchsOfficesApplication.updateBranchsOffices(listState);
      dispatch(branchsOfficesUpdatePageSuccess(resultUpdate));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(branchsOfficesUpdatePageErr(error));
    }
  };
};

export const deleteBranchOfficePage = (
  listState: RequestBranchsOffices
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(branchsOfficesDeletePageBegin());
      const branchsOfficesApplication = container.resolve<IBranchsOfficesApplication>(
        'IBranchsOfficesApplication'
      );

      const resultDelete = await branchsOfficesApplication.deleteBranchsOffices(listState);
      dispatch(branchsOfficesDeletePageSuccess(resultDelete));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(branchsOfficesDeletePageErr(error));
    }
  };
};

export const clearCreateBranchOfficePage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(branchsOfficesCreatePageBegin());
    dispatch(branchsOfficesCreatePageSuccess(undefined));
    dispatch(branchsOfficesCreatePageErr(undefined));
  };
};

export const clearUpdateBranchOfficePage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(branchsOfficesUpdatePageBegin());
    dispatch(branchsOfficesUpdatePageErr(undefined));
    dispatch(branchsOfficesUpdatePageSuccess(undefined));
  };
};

export const clearDeleteBranchOfficePage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(branchsOfficesDeletePageBegin());
    dispatch(branchsOfficesDeletePageErr(undefined));
    dispatch(branchsOfficesDeletePageSuccess(undefined));
    dispatch(branchsOfficesListPageSuccess([]));
  };
};
