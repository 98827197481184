import { AxiosError } from 'axios';
import { ResponseUser } from '../../../core/domain/userPermissions/user/model/responseUser';

const actionsUsers = {
  USERS_LIST_PAGE_BEGIN: 'USERS_LIST_PAGE_BEGIN',
  USERS_LIST_PAGE_SUCCESS: 'USERS_LIST_PAGE_SUCCESS',
  USERS_LIST_PAGE_ERR: 'USERS_LIST_PAGE_ERR',

  usersListPageBegin: (): { type: string } => {
    return {
      type: actionsUsers.USERS_LIST_PAGE_BEGIN,
    };
  },

  usersListPageSuccess: (data: ResponseUser[]): { type: string; data: ResponseUser[] } => {
    return {
      type: actionsUsers.USERS_LIST_PAGE_SUCCESS,
      data,
    };
  },

  usersListPageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsUsers.USERS_LIST_PAGE_ERR,
      err,
    };
  },
};

const actionsUser = {
  USER_LIST_PAGE_BEGIN: 'USER_LIST_PAGE_BEGIN',
  USER_LIST_PAGE_SUCCESS: 'USER_LIST_PAGE_SUCCESS',
  USER_LIST_PAGE_ERR: 'USER_LIST_PAGE_ERR',

  userListPageBegin: (): { type: string } => {
    return {
      type: actionsUser.USER_LIST_PAGE_BEGIN,
    };
  },

  userListPageSuccess: (
    data: ResponseUser | undefined
  ): { type: string; data: ResponseUser | undefined } => {
    return {
      type: actionsUser.USER_LIST_PAGE_SUCCESS,
      data,
    };
  },

  userListPageErr: (err: AxiosError | undefined): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsUser.USER_LIST_PAGE_ERR,
      err,
    };
  },
};

const actionsUserLogin = {
  USER_LOGIN_PAGE_BEGIN: 'USER_LOGIN_PAGE_BEGIN',
  USER_LOGIN_PAGE_SUCCESS: 'USER_LOGIN_PAGE_SUCCESS',
  USER_LOGIN_PAGE_ERR: 'USER_LOGIN_PAGE_ERR',

  userLoginListPageBegin: (): { type: string } => {
    return {
      type: actionsUserLogin.USER_LOGIN_PAGE_BEGIN,
    };
  },

  userLoginListPageSuccess: (
    data: ResponseUser | undefined
  ): { type: string; data: ResponseUser | undefined } => {
    return {
      type: actionsUserLogin.USER_LOGIN_PAGE_SUCCESS,
      data,
    };
  },

  userLoginListPageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsUserLogin.USER_LOGIN_PAGE_ERR,
      err,
    };
  },
};

const actionsUserCreate = {
  USER_CREATE_PAGE_BEGIN: 'USER_CREATE_PAGE_BEGIN',
  USER_CREATE_PAGE_SUCCESS: 'USER_CREATE_PAGE_SUCCESS',
  USER_CREATE_PAGE_ERR: 'USER_CREATE_PAGE_ERR',

  userCreatePageBegin: (): { type: string } => {
    return {
      type: actionsUserCreate.USER_CREATE_PAGE_BEGIN,
    };
  },

  userCreatePageSuccess: (
    data: ResponseUser | undefined
  ): { type: string; data: ResponseUser | undefined } => {
    return {
      type: actionsUserCreate.USER_CREATE_PAGE_SUCCESS,
      data,
    };
  },

  userCreatePageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsUserCreate.USER_CREATE_PAGE_ERR,
      err,
    };
  },
};

const actionsUserUpdate = {
  USER_UPDATE_PAGE_BEGIN: 'USER_UPDATE_PAGE_BEGIN',
  USER_UPDATE_PAGE_SUCCESS: 'USER_UPDATE_PAGE_SUCCESS',
  USER_UPDATE_PAGE_ERR: 'USER_UPDATE_PAGE_ERR',

  userUpdatePageBegin: (): { type: string } => {
    return {
      type: actionsUserUpdate.USER_UPDATE_PAGE_BEGIN,
    };
  },

  userUpdatePageSuccess: (
    data: ResponseUser | undefined
  ): { type: string; data: ResponseUser | undefined } => {
    return {
      type: actionsUserUpdate.USER_UPDATE_PAGE_SUCCESS,
      data,
    };
  },

  userUpdatePageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsUserUpdate.USER_UPDATE_PAGE_ERR,
      err,
    };
  },
};

const actionsUserRecoveryPassword = {
  USER_RECOVERY_PASSWORD_PAGE_BEGIN: 'USER_RECOVERY_PASSWORD_PAGE_BEGIN',
  USER_RECOVERY_PASSWORD_PAGE_SUCCESS: 'USER_RECOVERY_PASSWORD_PAGE_SUCCESS',
  USER_RECOVERY_PASSWORD_PAGE_ERR: 'USER_RECOVERY_PASSWORD_PAGE_ERR',

  userRecoveryPasswordPageBegin: (): { type: string } => {
    return {
      type: actionsUserRecoveryPassword.USER_RECOVERY_PASSWORD_PAGE_BEGIN,
    };
  },

  userRecoveryPasswordPageSuccess: (
    data: ResponseUser | undefined
  ): { type: string; data: ResponseUser | undefined } => {
    return {
      type: actionsUserRecoveryPassword.USER_RECOVERY_PASSWORD_PAGE_SUCCESS,
      data,
    };
  },

  userRecoveryPasswordPageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsUserRecoveryPassword.USER_RECOVERY_PASSWORD_PAGE_ERR,
      err,
    };
  },
};

const actionsUserUpdatePassword = {
  USER_UPDATE_PASSWORD_PAGE_BEGIN: 'USER_UPDATE_PASSWORD_PAGE_BEGIN',
  USER_UPDATE_PASSWORD_PAGE_SUCCESS: 'USER_UPDATE_PASSWORD_PAGE_SUCCESS',
  USER_UPDATE_PASSWORD_PAGE_ERR: 'USER_UPDATE_PASSWORD_PAGE_ERR',

  userUpdatePasswordPageBegin: (): { type: string } => {
    return {
      type: actionsUserUpdatePassword.USER_UPDATE_PASSWORD_PAGE_BEGIN,
    };
  },

  userUpdatePasswordPageSuccess: (
    data: ResponseUser | undefined
  ): { type: string; data: ResponseUser | undefined } => {
    return {
      type: actionsUserUpdatePassword.USER_UPDATE_PASSWORD_PAGE_SUCCESS,
      data,
    };
  },

  userUpdatePasswordPageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsUserUpdatePassword.USER_UPDATE_PASSWORD_PAGE_ERR,
      err,
    };
  },
};
const actionsUserLoggedUpdatePassword = {
  USER_LOGGED_UPDATE_PASSWORD_PAGE_BEGIN: 'USER_LOGGED_UPDATE_PASSWORD_PAGE_BEGIN',
  USER_LOGGED_UPDATE_PASSWORD_PAGE_SUCCESS: 'USER_LOGGED_UPDATE_PASSWORD_PAGE_SUCCESS',
  USER_LOGGED_UPDATE_PASSWORD_PAGE_ERR: 'USER_LOGGED_UPDATE_PASSWORD_PAGE_ERR',

  userLoggedUpdatePasswordPageBegin: (): { type: string } => {
    return {
      type: actionsUserLoggedUpdatePassword.USER_LOGGED_UPDATE_PASSWORD_PAGE_BEGIN,
    };
  },

  userLoggedUpdatePasswordPageSuccess: (
    data: ResponseUser | undefined
  ): { type: string; data: ResponseUser | undefined } => {
    return {
      type: actionsUserLoggedUpdatePassword.USER_LOGGED_UPDATE_PASSWORD_PAGE_SUCCESS,
      data,
    };
  },

  userLoggedUpdatePasswordPageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsUserLoggedUpdatePassword.USER_LOGGED_UPDATE_PASSWORD_PAGE_ERR,
      err,
    };
  },
};

const actionsUserDelete = {
  USER_DELETE_PAGE_BEGIN: 'USER_DELETE_PAGE_BEGIN',
  USER_DELETE_PAGE_SUCCESS: 'USER_DELETE_PAGE_SUCCESS',
  USER_DELETE_PAGE_ERR: 'USER_DELETE_PAGE_ERR',

  userDeletePageBegin: (): { type: string } => {
    return {
      type: actionsUserDelete.USER_DELETE_PAGE_BEGIN,
    };
  },

  userDeletePageSuccess: (
    data: ResponseUser | undefined
  ): { type: string; data: ResponseUser | undefined } => {
    return {
      type: actionsUserDelete.USER_DELETE_PAGE_SUCCESS,
      data,
    };
  },

  userDeletePageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsUserDelete.USER_DELETE_PAGE_ERR,
      err,
    };
  },
};

const actionsUpdateRoleToUser = {
  UPDATE_ROLE_TO_USER_PAGE_BEGIN: 'UPDATE_ROLE_TO_USER_PAGE_BEGIN',
  UPDATE_ROLE_TO_USER_PAGE_SUCCESS: 'UPDATE_ROLE_TO_USER_PAGE_SUCCESS',
  UPDATE_ROLE_TO_USER_PAGE_ERR: 'UPDATE_ROLE_TO_USER_PAGE_ERR',

  updateRoleToUserPageBegin: (): { type: string } => {
    return {
      type: actionsUpdateRoleToUser.UPDATE_ROLE_TO_USER_PAGE_BEGIN,
    };
  },

  updateRoleToUserPageSuccess: (
    data: ResponseUser | undefined
  ): { type: string; data: ResponseUser | undefined } => {
    return {
      type: actionsUpdateRoleToUser.UPDATE_ROLE_TO_USER_PAGE_SUCCESS,
      data,
    };
  },

  updateRoleToUserPageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsUpdateRoleToUser.UPDATE_ROLE_TO_USER_PAGE_ERR,
      err,
    };
  },
};

const actionsUsersByRole = {
  USERS_GET_BY_ROLE_BEGIN: 'USERS_GET_BY_ROLE_BEGIN',
  USERS_GET_BY_ROLE_SUCCESS: 'USERS_GET_BY_ROLE_SUCCESS',
  USERS_GET_BY_ROLE_ERR: 'USERS_GET_BY_ROLE_ERR',

  usersGetByRoleBegin: (): { type: string } => {
    return {
      type: actionsUsersByRole.USERS_GET_BY_ROLE_BEGIN,
    };
  },

  usersGetByRoleSuccess: (data: ResponseUser[]): { type: string; data: ResponseUser[] } => {
    return {
      type: actionsUsersByRole.USERS_GET_BY_ROLE_SUCCESS,
      data,
    };
  },

  usersGetByRoleErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsUsersByRole.USERS_GET_BY_ROLE_ERR,
      err,
    };
  },
};

export {
  actionsUsers,
  actionsUser,
  actionsUserLogin,
  actionsUserCreate,
  actionsUserUpdate,
  actionsUserUpdatePassword,
  actionsUserLoggedUpdatePassword,
  actionsUserDelete,
  actionsUpdateRoleToUser,
  actionsUsersByRole,
  actionsUserRecoveryPassword,
};
