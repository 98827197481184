import { AxiosError } from 'axios';
import { actionsLoginProvider, actionsRefresh, actionsProviderLogout } from './actions';
import { SecurityState } from '../../core/domain/security/state/securityState';
import { ResponseLogin } from '../../core/domain/security/model/response/responseLogin';
import { TLoginProviderReducer } from './types';

const { LOGIN_PROVIDER_PAGE_BEGIN, LOGIN_PROVIDER_PAGE_SUCCESS, LOGIN_PROVIDER_PAGE_ERR } =
  actionsLoginProvider;
const { LOGOUT_PROVIDER_PAGE_BEGIN, LOGOUT_PROVIDER_PAGE_SUCCESS, LOGOUT_PROVIDER_PAGE_ERR } =
  actionsProviderLogout;
const { REFRESH_PAGE_BEGIN, REFRESH_PAGE_SUCCESS, REFRESH_PAGE_ERR } = actionsRefresh;

const initialSecurity: SecurityState = new SecurityState();

export const LoginProviderReducer = (
  state = initialSecurity,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseLogin | undefined;
  }
): TLoginProviderReducer => {
  const { type, data, err } = action;
  switch (type) {
    case LOGIN_PROVIDER_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_PROVIDER_PAGE_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case LOGIN_PROVIDER_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export const LogoutProviderReducer = (
  state = initialSecurity,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseLogin | undefined;
  }
): TLoginProviderReducer => {
  const { type, data, err } = action;
  switch (type) {
    case LOGOUT_PROVIDER_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_PROVIDER_PAGE_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case LOGOUT_PROVIDER_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};
export const RefreshReducer = (
  state = initialSecurity,
  action: {
    type: string;
    err: AxiosError | undefined;
    data: ResponseLogin | undefined;
  }
): TLoginProviderReducer => {
  const { type, data, err } = action;
  switch (type) {
    case REFRESH_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case REFRESH_PAGE_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case REFRESH_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};
