import { container } from 'tsyringe';
import { RequestThirdPartiesBeneficiaries } from '../../domain/thirdParties/thirdPartiesBeneficiaries/model/thirdPartiesBeneficiariesRequest';
import { ResponseThirdPartiesBeneficiaries } from '../../domain/thirdParties/thirdPartiesBeneficiaries/model/thirdPartiesBeneficiariesResponse';
import { IThirdPartiesBeneficiariesInfrastructure } from '../../domain/interface/infrastructure/thirdParties/IThirdPartiesBeneficiariesInfrastructure';
import { IThirdPartiesBeneficiariesApplication } from '../../domain/interface/application/thirdParties/IThirdPartiesBeneficiariesApplication';

export class ThirdPartiesBeneficiariesApplication implements IThirdPartiesBeneficiariesApplication {
  private thirdPartiesBeneficiariesInfrastructure: IThirdPartiesBeneficiariesInfrastructure;

  public constructor() {
    this.thirdPartiesBeneficiariesInfrastructure =
      container.resolve<IThirdPartiesBeneficiariesInfrastructure>(
        'IThirdPartiesBeneficiariesInfrastructure'
      );
  }

  public async getThirdPartiesBeneficiaries(
    request: RequestThirdPartiesBeneficiaries
  ): Promise<ResponseThirdPartiesBeneficiaries[]> {
    const result =
      await this.thirdPartiesBeneficiariesInfrastructure.getThirdPartiesBeneficiariesAPI(request);
    return result;
  }
}
