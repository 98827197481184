export class RequestBranchsOffices {
  public idBranchOffice = 0;

  public idThirdParty? = 0;

  public idDepartment? = 0;

  public idCity? = 0;

  public description? = '';
}
