import { AxiosError } from 'axios';
import { ResponseUserType } from '../../../core/domain/userPermissions/userType/model/responseUserType';

const actionsUserTypes = {
  USER_TYPES_LIST_PAGE_BEGIN: 'USER_TYPES_LIST_PAGE_BEGIN',
  USER_TYPES_LIST_PAGE_SUCCESS: 'USER_TYPES_LIST_PAGE_SUCCESS',
  USER_TYPES_LIST_PAGE_ERR: 'USER_TYPES_LIST_PAGE_ERR',

  userTypeListPageBegin: (): { type: string } => {
    return {
      type: actionsUserTypes.USER_TYPES_LIST_PAGE_BEGIN,
    };
  },

  userTypeListPageSuccess: (
    data: ResponseUserType[] | undefined
  ): { type: string; data: ResponseUserType[] | undefined } => {
    return {
      type: actionsUserTypes.USER_TYPES_LIST_PAGE_SUCCESS,
      data,
    };
  },

  userTypeListPageErr: (
    err: AxiosError | undefined
  ): { type: string; err?: AxiosError | undefined } => {
    return {
      type: actionsUserTypes.USER_TYPES_LIST_PAGE_ERR,
      err,
    };
  },
};
export { actionsUserTypes };
