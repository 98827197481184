import { Box, Button, CircularProgress, Grid, Tooltip, Typography } from '@mui/material';
import React, { FC } from 'react';
import { InputField, NormalSelectField } from '../../../../../common/components/formAdapters';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { constructorName } from '../../../../../common/utils/functions';

export const CreateBranch: FC<any> = ({
  dataForm,
  schema,
  control,
  idThirdPartyLogin,
  dataDepartments,
  idBranchOffice,
  handleAddInput,
  dataCities,
  handleRemoveInput,
  createLoading,
  updateLoading,
  handleUpdate,
  handleRegister,
  dataProviders,
  styles,
  theme,
}) => {
  return (
    <Box
      sx={{
        height: '100%',
        padding: '10px 10px 10px 10px',
      }}>
      <Box
        sx={{
          minHeight: '420px',
          height: '80%',
        }}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            color: theme.palette.primary.main,
            borderBottom: `1px solid ${theme.palette.primary.main}`,
            fontWeight: 'bold',
            justifyContent: 'space-between',
            alignItems: 'baseline',
          }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: '20px' }}>
            {idBranchOffice ? 'Actualizar' : 'Crear'} sucursal
          </Typography>
          {!idBranchOffice && (
            <Grid item xs={4} sm={5} md={3.5} lg={3.5} xl={3}>
              <Tooltip title="Agregar más sucursales">
                <Button
                  sx={styles.buttonSubmit}
                  type="button"
                  style={{ color: '#fff' }}
                  onClick={handleAddInput}>
                  <AddIcon fontSize="small" />
                </Button>
              </Tooltip>
            </Grid>
          )}
        </Box>
        <Box sx={{ overflow: 'auto', maxHeight: '350px', height: '30%', width: '100%' }}>
          {dataForm?.branches
            ?.filter((input: any) => input?.state === 1)
            ?.map((input: any, i: number) => {
              const key = `${i}`;
              return (
                <Box sx={{ mt: '20px' }} key={key}>
                  <Grid container spacing={{ xs: 2, sm: 2, md: 1 }}>
                    <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                      <NormalSelectField
                        label="Comercio"
                        name={`branches.${i}.idThirdParty`}
                        schema={schema.fields?.branches[i]?.idThirdParty}
                        control={control}
                        options={
                          (dataProviders || [])?.map((x: any) => ({
                            value: x?.idThirdParty,
                            label:
                              x?.businessName?.toUpperCase() ||
                              constructorName([
                                x?.firstName,
                                x?.middleName,
                                x?.firstSurname,
                                x?.secondSurname,
                              ]),
                          })) || []
                        }
                        disabled={idThirdPartyLogin}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                      <NormalSelectField
                        label="Departamento"
                        name={`branches.${i}.idDepartment`}
                        schema={schema.fields?.branches[i]?.idDepartment}
                        control={control}
                        options={
                          (dataDepartments || [])?.map((x: any) => ({
                            value: x?.idDepartment,
                            label: x?.name?.toUpperCase(),
                          })) || []
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                      <NormalSelectField
                        label="Ciudad"
                        name={`branches.${i}.idCity`}
                        schema={schema.fields?.branches[i]?.idCity}
                        control={control}
                        disabled={!input?.idDepartment}
                        options={
                          (dataCities || [])
                            ?.filter((x: any) => x?.idDepartment === Number(input?.idDepartment))
                            .map((x: any) => {
                              return {
                                value: x?.idCity,
                                label: x?.name?.toUpperCase(),
                              };
                            }) || []
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={3}
                      xl={3}
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between">
                      <Grid item xs={7} sm={6.5} md={8} lg={8} xl={8.5}>
                        <InputField
                          label="Dirección"
                          name={`branches.${i}.description`}
                          schema={schema.fields?.branches[i]?.description}
                          control={control}
                        />
                      </Grid>

                      {/* {i === 0 && !idBranchOffice && (
                    <Grid item xs={4} sm={5} md={3.5} lg={3.5} xl={3}>
                      <Tooltip title="Agregar más sucursales">
                        <Button
                          sx={styles.buttonSubmit}
                          type="button"
                          style={{ color: '#fff' }}
                          onClick={handleAddInput}>
                          <AddIcon fontSize="small" />
                        </Button>
                      </Tooltip>
                    </Grid>
                  )} */}
                      {(dataForm?.branches || [])?.length > 1 && i !== 0 && (
                        <Grid item xs={4} sm={5} md={3.5} lg={3.5} xl={3}>
                          <Tooltip title="Eliminar sucursal">
                            <Button
                              sx={styles.buttonSubmit}
                              type="button"
                              style={{ color: '#fff' }}
                              onClick={() => handleRemoveInput(i)}>
                              <DeleteIcon fontSize="small" />
                            </Button>
                          </Tooltip>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '20%',
          justifyContent: 'flex-end',
        }}>
        <Button
          sx={{
            ...styles.buttonSubmit,
            width: idBranchOffice ? '135px' : '95.63px',
            color: 'white',
          }}
          disabled={createLoading || updateLoading}
          type="button"
          onClick={idBranchOffice ? handleUpdate : handleRegister}
          startIcon={createLoading || updateLoading ? null : <SaveIcon fontSize="small" />}>
          {createLoading || updateLoading ? (
            <CircularProgress size={15} sx={{ color: 'white' }} />
          ) : (
            <Typography fontSize="14px" sx={{ display: 'flex', alignItems: 'center' }}>
              {idBranchOffice ? 'Actualizar' : 'Crear'}
            </Typography>
          )}
        </Button>
      </Box>
    </Box>
  );
};
