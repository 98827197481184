import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { DataGrid, GridColDef, GridColumnHeaderParams, esES } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { ToolbarList } from '../../../../../common/components/toolbar';
import { IBenefitsList } from '../../../containers/benefits/benefitList/types.d';
import { ButtonSubmit } from '../benefitRegister/styled';
import { DateAdapter } from '../../../../../common/components/formAdapters';
import { TransitionsModalAlert } from '../../../../../common/components/modalAlert';
import { TitleComponent } from '../../../../../common/components/titles/styled';
import { formatDate } from '../../../../../lib';

const BenefitsListV: React.FC<IBenefitsList> = ({
  benefitsData,
  schema,
  control,
  handleUpdateValidation,
  loading,
  handleDelete,
  openDelete,
  handleClose,
  buttonDelete,
  deleteLoading,
  updateLoading,
  getItemByCheckbox,
  selectionModel,
  setSelectionModel,
  handleExportExcel,
}) => {
  const theme = useTheme();

  const styles = {
    header: {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      fontSize: '30px',
      textAlign: 'left',
      fontFamily: 'Poppins-Regular',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
    },
    headerName: {
      fontWeight: 'bold',
      fontSize: '14px',
      color: theme.palette.primary.main,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    icon: {
      display: 'flex',
      fontSize: '20px',
      color: '#fff',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        display: 'flex',
        color: '#fff',
      },
    },
    textButton: {
      fontSize: '13px',
      color: '#fff',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    button: {
      background: theme.palette.success.light,
      display: 'flex',
      height: '35px',
      justifyContent: 'center',
      gap: '10px',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        width: '30px',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:hover': {
        backgroundColor: theme.palette.success.main,
      },
      boxShadow: '0px 3px 5px 0px #dcdcdc',
    },
  };
  const columns: GridColDef[] = [
    {
      field: 'number',
      headerName: 'CÓDIGO',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>CÓDIGO</Typography>
      ),
    },
    {
      field: 'name',
      headerName: 'NOMBRE',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>NOMBRE</Typography>
      ),
    },
    {
      field: 'description',
      headerName: 'DESCRIPCIÓN',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>DESCRIPCIÓN</Typography>
      ),
    },
    {
      field: 'idCategory',
      headerName: 'CATEGORÍAS',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>CATEGORÍAS</Typography>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'FECHA CREACIÓN',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>FECHA CREACIÓN</Typography>
      ),
      valueFormatter: (params) => formatDate(params.value, 'DD/MM/YYYY'),
    },
    {
      field: 'isActive',
      headerName: 'ESTADO',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>ESTADO</Typography>
      ),
    },
    {
      field: 'actions',
      headerName: 'ACCIONES',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>ACCIONES</Typography>
      ),
      renderCell({ row }) {
        return (
          <Box>
            <Tooltip title="Editar información del beneficio">
              <Link to={`/beneficios/editar/${row?.idBenefit}`}>
                <IconButton>
                  <BorderColorIcon fontSize="small" color="primary" />
                </IconButton>
              </Link>
            </Tooltip>
            <Tooltip title="Eliminar información del beneficio">
              <IconButton onClick={() => buttonDelete(row?.idBenefit)}>
                <HighlightOffIcon fontSize="small" color="secondary" />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}>
      <Box
        sx={{
          height: 'auto',
          width: '98%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          background: '#fff',
          borderRadius: '16px',
          padding: '20px',
          mb: '20px',
          boxShadow: '0px 3px 6px #00000029',
        }}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '10px',
          }}>
          <TitleComponent>Actualización de vigencia</TitleComponent>
          <Tooltip title="Descargar excel">
            <Button
              onClick={handleExportExcel}
              disabled={loading}
              variant="contained"
              sx={styles.button}>
              {loading ? (
                <CircularProgress size={16} sx={styles.icon} />
              ) : (
                <SaveAltIcon sx={styles.icon} />
              )}
              <Typography sx={styles.textButton}>Descargar</Typography>
            </Button>
          </Tooltip>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={5} lg={4}>
            <DateAdapter
              label="Fecha de inicio"
              name="startDate"
              type="date"
              schema={schema.fields.startDate}
              control={control}
            />
          </Grid>
          <Grid item xs={5} lg={4}>
            <DateAdapter
              label="Fecha de final"
              name="endDate"
              type="date"
              schema={schema.fields.endDate}
              control={control}
            />
          </Grid>
          <Grid item>
            <Tooltip title="Actualizar cupón">
              <ButtonSubmit
                backgroundColor={theme.palette.primary.main}
                hoverStyles={theme.palette.primary.dark}
                disabled={updateLoading}
                type="button"
                style={{ color: '#fff', maxWidth: '120px' }}
                onClick={handleUpdateValidation}>
                {updateLoading && <CircularProgress size={15} sx={{ color: 'white' }} />}
                <Typography fontSize="14px">Actualizar</Typography>
              </ButtonSubmit>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          height: 'auto',
          width: '98%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          background: '#fff',
          borderRadius: '16px',
          padding: '20px',
          pb: '30px',
          boxShadow: '0px 3px 6px #00000029',
          minHeight: '400px',
        }}>
        <div style={{ width: '100%', height: '80vh' }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}>
            <TitleComponent>Lista de beneficios</TitleComponent>
          </Box>
          <div style={{ background: '#fff', height: '82%' }}>
            <DataGrid
              rows={benefitsData || []}
              getRowId={(row) => row?.idBenefit}
              columns={columns}
              density="compact"
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              components={{ Toolbar: ToolbarList }}
              checkboxSelection
              selectionModel={selectionModel}
              onSelectionModelChange={(item) => {
                getItemByCheckbox(item);
                setSelectionModel(item);
              }}
              rowsPerPageOptions={[25, 50, 100]}
              loading={!benefitsData?.length && loading}
            />
          </div>
        </div>
      </Box>
      <Box>
        <TransitionsModalAlert
          state={openDelete}
          handleCloseModal={handleClose}
          width="25%"
          height="25%"
          title="Confirmar acción"
          subTitle="Antes de continuar!"
          paragraph="¿Estás seguro de realizar esta acción?"
          nameAccept={!deleteLoading ? 'SI' : <CircularProgress size={15} />}
          nameDecline="NO"
          onClickAccept={handleDelete}
        />
      </Box>
    </Box>
  );
};

export default BenefitsListV;
