import { container } from 'tsyringe';
import { CitiesApplication } from './addresses/cities/citiesApplication';
import { CountriesApplication } from './addresses/countries/countriesApplication';
import { DepartmentsApplication } from './addresses/countries/departments/departmentsApplication';
import { StreetTypesApplication } from './addresses/streetTypes/streetTypes.Application';
import { BankAccountTypesApplication } from './banks/bankAccountTypes/bankAccountTypesApplication';
import { BanksApplication } from './banks/banks/banksApplication';
import { KinshipsApplication } from './contacts/Kinships/kinshipsApplication';
import { AcademicLevelsApplication } from './documentsPersons/academicLevels/academicLevelsApplication';
import { DocumentsTypesApplication } from './documentsPersons/documentsTypes/documentsTypesApplication';
import { GenderApplication } from './documentsPersons/gender/genderApplication';
import { MaritalStatusApplication } from './documentsPersons/maritalStatus/maritalStatusApplication';
import { ProfessionApplication } from './workInformation/profession/professionApplication';
import { UserApplication } from './userPermissions/user/userApplication';
import { RoleApplication } from './userPermissions/role/roleApplication';
import { UserPermissionApplication } from './userPermissions/userPermission/userPermissionApplication';
import { ModuleApplication } from './userPermissions/module/moduleApplication';
import { SubModuleApplication } from './userPermissions/subModule/subModuleApplication';
import { UserTypeApplication } from './userPermissions/userType/userTypeApplication';
import { LoginApplication } from './security/loginApplication';
import { LogoutApplication } from './security/logoutApplication';
import { RefreshApplication } from './security/refreshApplication';
import { ThirdPartiesApplication } from './thirdParties/thirdPartiesApplication';
import { BenefitCategoryApplication } from './benefits/benefitCategories/benefitCategoryApplication';
import { AddressesApplication } from './addresses/addresses/addressesApplication';
import { BenefitApplication } from './benefits/benefits/benefitApplication';
import { BenefitValidityApplication } from './benefits/benefitValidities/benefitValidityApplication';
import { SellerApplication } from './sellers/sellerApplication';
import { BeneficiariesApplication } from './beneficiaries/beneficiariesApplication';
import { ThirdPartiesBeneficiariesApplication } from './thirdParties/thirdPartiesBeneficiariesApplication';
import { ThirdPartySellerApplication } from './sellers/thirdPartySellerApplication';
import { BenefitBeneficiariesApplication } from './benefitBeneficiaries/benefitBeneficiariesApplication';
import { LoginProviderApplication } from './security/loginProviderApplication';
import { BenefitBeneficiariesGetByIdApplication } from './benefitBeneficiaries/benefitBeneficiariesGetByIdApplication';
import { ValidateBenefitApplication } from './benefitBeneficiaries/validateBenefitApplicationHandler';
import { LoginBeneficiaryApplication } from './security/loginBeneficiaryApplication';
import { BranchsOfficesApplication } from './branchsOffices/branchsOfficesApplication';
import { UserSessionApplication } from './userPermissions/userSession/userSessionApplication';
import { ConsecutiveApplication } from './consecutives/consecutiveApplication';

/**
 * @class DependencyInjectionApplication
 */
export class DependencyInjectionApplication {
  /**
   * @static DependencyInjectionApplication
   */
  public static DependencyInjectionStartup(): void {
    container.register('ILoginApplication', {
      useClass: LoginApplication,
    });
    container.register('ILoginProviderApplication', {
      useClass: LoginProviderApplication,
    });
    container.register('ILoginBeneficiaryApplication', {
      useClass: LoginBeneficiaryApplication,
    });
    container.register('ILogoutApplication', {
      useClass: LogoutApplication,
    });
    container.register('IRefreshApplication', {
      useClass: RefreshApplication,
    });
    container.register('IProfessionApplication', {
      useClass: ProfessionApplication,
    });
    container.register('ICitiesApplication', {
      useClass: CitiesApplication,
    });
    container.register('ICountriesApplication', {
      useClass: CountriesApplication,
    });
    container.register('IDepartmentsApplication', {
      useClass: DepartmentsApplication,
    });
    container.register('IStreetTypesApplication', {
      useClass: StreetTypesApplication,
    });
    container.register('IBankAccountTypesApplication', {
      useClass: BankAccountTypesApplication,
    });
    container.register('IBanksApplication', {
      useClass: BanksApplication,
    });
    container.register('IKinshipsApplication', {
      useClass: KinshipsApplication,
    });
    container.register('IAcademicLevelsApplication', {
      useClass: AcademicLevelsApplication,
    });
    container.register('IAddressesApplication', {
      useClass: AddressesApplication,
    });
    container.register('IDocumentsTypesApplication', {
      useClass: DocumentsTypesApplication,
    });
    container.register('IGenderApplication', {
      useClass: GenderApplication,
    });
    container.register('IMaritalStatusApplication', {
      useClass: MaritalStatusApplication,
    });
    container.register('IUserApplication', {
      useClass: UserApplication,
    });
    container.register('IRoleApplication', {
      useClass: RoleApplication,
    });
    container.register('IUserPermissionApplication', {
      useClass: UserPermissionApplication,
    });
    container.register('IModuleApplication', {
      useClass: ModuleApplication,
    });
    container.register('ISubModuleApplication', {
      useClass: SubModuleApplication,
    });
    container.register('IUserTypeApplication', {
      useClass: UserTypeApplication,
    });
    container.register('IThirdPartiesApplication', {
      useClass: ThirdPartiesApplication,
    });
    container.register('IBenefitCategoryApplication', {
      useClass: BenefitCategoryApplication,
    });
    container.register('IBenefitApplication', {
      useClass: BenefitApplication,
    });
    container.register('IBenefitValidityApplication', {
      useClass: BenefitValidityApplication,
    });
    container.register('ISellerApplication', {
      useClass: SellerApplication,
    });
    container.register('IBeneficiariesApplication', {
      useClass: BeneficiariesApplication,
    });
    container.register('IThirdPartiesBeneficiariesApplication', {
      useClass: ThirdPartiesBeneficiariesApplication,
    });
    container.register('IThirdPartySellerApplication', {
      useClass: ThirdPartySellerApplication,
    });
    container.register('IBenefitBeneficiariesApplication', {
      useClass: BenefitBeneficiariesApplication,
    });
    container.register('IBenefitBeneficiariesGetByIdApplication', {
      useClass: BenefitBeneficiariesGetByIdApplication,
    });
    container.register('IValidateBenefitApplication', {
      useClass: ValidateBenefitApplication,
    });
    container.register('IBranchsOfficesApplication', {
      useClass: BranchsOfficesApplication,
    });
    container.register('IUserSessionApplication', {
      useClass: UserSessionApplication,
    });
    container.register('IConsecutiveApplication', {
      useClass: ConsecutiveApplication,
    });
  }
}
