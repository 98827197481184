import { AxiosError } from 'axios';
import { ResponseSubModule } from '../../../core/domain/userPermissions/subModule/model/responseSubModule';
import { SubModuleState } from '../../../core/domain/userPermissions/subModule/state/subModuleState';
import actions from './actions';
import { TModuleReducer } from './types.d';

const { SUBMODULE_LIST_PAGE_BEGIN, SUBMODULE_LIST_PAGE_SUCCESS, SUBMODULE_LIST_PAGE_ERR } = actions;

const initialSubModule: SubModuleState = new SubModuleState();

const SubModuleReducer = (
  state = initialSubModule,
  action: { type: string; err: AxiosError; data: ResponseSubModule[] }
): TModuleReducer => {
  const { type, data, err } = action;
  switch (type) {
    case SUBMODULE_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case SUBMODULE_LIST_PAGE_SUCCESS:
      return {
        ...state,
        subModule: data,
        loading: false,
      };
    case SUBMODULE_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default SubModuleReducer;
