import { FC } from 'react';
import { Box, Button, CircularProgress, Grid, Typography, useTheme } from '@mui/material';
import { InputField } from '../../../../common/components/formAdapters';
import { LocationContact } from './locationContact';
import SaveIcon from '@mui/icons-material/Save';
import { Validity } from './validity';
import { TProfileInfo } from './type';

export const ProfileInformationV: FC<TProfileInfo> = ({
  schema,
  control,
  updateLoading,
  handleUpdate,
  dataDepartments,
  dataCities,
  dataStreetTypes,
  watchDepartment,
  watchIdStreetTypeOne,
  dataSellers,
  type,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
      }}>
      <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
          }}>
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: '1.1em',
              marginBottom: '10px',
              color: theme.palette.primary.dark,
            }}>
            Información de perfil
          </Typography>
          <Button
            sx={{
              width: '135px',
              height: '35px',
              backgroundColor: theme.palette.primary.main,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
              borderRadius: '5px',
              cursor: 'pointer',
              boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);',
            }}
            disabled={updateLoading}
            type="button"
            onClick={handleUpdate}
            style={{ color: '#fff' }}
            startIcon={<SaveIcon fontSize="small" />}>
            {updateLoading ? (
              <CircularProgress size={15} sx={{ color: 'white' }} />
            ) : (
              <Typography fontSize="14px" sx={{ display: 'flex', alignItems: 'center' }}>
                ACTUALIZAR
              </Typography>
            )}
          </Button>
        </Box>
        <Box
          sx={{
            height: 'auto',
            width: '99%',
            background: '#fff',
            borderRadius: '16px',
            padding: '5px',
            boxShadow: '0px 3px 5px 0px #dcdcdc',
          }}>
          <Box sx={{ padding: '10px', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ marginBottom: '15px' }}>
              <Typography
                variant="h5"
                color={theme.palette.primary.main}
                sx={{
                  borderBottom: `1px solid ${theme.palette.primary.dark}`,
                }}>
                {type === 'Membresia' ? 'Información de la membresia' : 'Información del comercio'}
              </Typography>
            </Box>
            <Grid container spacing={{ xs: 2, sm: 2, md: 2 }} sx={{ marginBottom: '10px' }}>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <InputField
                  label="NIT"
                  name="identificationNumber"
                  schema={schema.fields?.identificationNumber}
                  control={control}
                  disabled
                />
              </Grid>
              <Grid item xs={5} sm={3} md={2} lg={1} xl={1} sx={{ width: '100%' }}>
                <InputField
                  disabled
                  label="DV"
                  name="dv"
                  schema={schema.fields?.dv}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <InputField
                  label="Razón social"
                  name="businessName"
                  schema={schema.fields?.businessName}
                  control={control}
                  disabled
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          height: 'auto',
          width: '99%',
          background: '#fff',
          borderRadius: '16px',
          padding: '5px',
          boxShadow: '0px 3px 5px 0px #dcdcdc',
          marginTop: '20px',
        }}>
        <Box sx={{ padding: '10px', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ marginBottom: '15px' }}>
            <Typography
              variant="h5"
              color={theme.palette.primary.main}
              sx={{
                borderBottom: `1px solid ${theme.palette.primary.dark}`,
              }}>
              Representante legal
            </Typography>
          </Box>
          <Grid container spacing={{ xs: 2, sm: 2, md: 2 }} sx={{ marginBottom: '10px' }}>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <InputField
                label="Primer nombre"
                name="firstName"
                schema={schema.fields?.firstName}
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <InputField
                label="Segundo nombre"
                name="middleName"
                schema={schema.fields?.middleName}
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <InputField
                label="Primer apellido"
                name="firstSurname"
                schema={schema.fields?.firstSurname}
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <InputField
                label="Segundo apellido"
                name="secondSurname"
                schema={schema.fields?.secondSurname}
                control={control}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <LocationContact
        schema={schema}
        control={control}
        theme={theme}
        dataDepartments={dataDepartments}
        dataCities={dataCities}
        dataStreetTypes={dataStreetTypes}
        watchDepartment={watchDepartment}
        watchIdStreetTypeOne={watchIdStreetTypeOne}
      />
      {type === 'Membresia' && (
        <Validity schema={schema} control={control} theme={theme} dataSellers={dataSellers} />
      )}
    </Box>
  );
};
