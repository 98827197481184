import 'reflect-metadata';
import { IConsecutiveInfrastructure } from '../../domain/interface/infrastructure/consecutives/IConsecutiveInfrastructure';
import { IConsecutiveApplication } from '../../domain/interface/application/consecutives/IConsecutiveApplication';
import { container } from 'tsyringe';
import { ResponseConsecutive } from '../../domain/consecutive/model/responseConsecutive';

export class ConsecutiveApplication implements IConsecutiveApplication {
  private consecutiveInfrastructure: IConsecutiveInfrastructure;

  public constructor() {
    this.consecutiveInfrastructure = container.resolve<IConsecutiveInfrastructure>(
      'IConsecutiveInfrastructure'
    );
  }

  public async getByIdConsecutive(idCategory: number): Promise<ResponseConsecutive> {
    const result = await this.consecutiveInfrastructure.getByIdConsecutiveAPI(idCategory);
    return result;
  }
}
