import {
  Box,
  Typography,
  Grid,
  useTheme,
  CircularProgress,
  Tooltip,
  Button,
  IconButton,
  Checkbox,
} from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { FC } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { ButtonSubmit } from './styled';
import {
  DateAdapter,
  InputField,
  NormalSelectField,
  SelectFieldMultiple,
  TextAreaField,
} from '../../../../../common/components/formAdapters';
import { TBenefitsRegister } from './types.d';
import { onlyNumbers } from '../../../../../common/utils/functions';
import { TitleComponent } from '../../../../../common/components/titles/styled';

const BenefitsRegisterV: FC<TBenefitsRegister> = ({
  schema,
  control,
  createLoading,
  isVisible,
  updateLoading,
  selectedImage,
  handleVisibilityChange,
  getLogo,
  handleLogo,
  handleChange,
  idBenefit,
  dataBenefitCategory,
  handleRegister,
  cityGroups,
  dataCities,
  dataDepartments,
  handleUpdate,
  thirdPartyProvider,
  handleAddCity,
  handleRemoveCityGroup,
  idThirdPartyLogin,
  watchDepartment,
}) => {
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const theme = useTheme();
  const styles = {
    header: {
      fontWeight: 'bold',
      margin: '10px',
      color: theme.palette.primary.main,
      fontSize: '30px',
      textAlign: 'left',
      fontFamily: 'Poppins-Regular',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    iconLogo: {
      width: '100%',
      color: `${selectedImage ? 'none' : 'gray'}`,
      '&:hover': {
        color: '#606060',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '120px',
        width: '100%',
      },
      [theme.breakpoints.up('xs')]: {
        fontSize: '96px',
        width: '100%',
      },
    },
    buttonLogo: {
      width: '100%',
      display: 'flex',
      padding: '0px',
      '@media screen and (max-width: 1400px) and (min-width:1100px)': {
        width: '90%',
        height: '190px',
      },
      [theme.breakpoints.up('lg')]: {
        width: '98%',
        height: '190px',
      },
    },
    buttonTextLogo: {
      width: '100%',
      fontSize: '1em',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      color: theme.palette.primary.dark,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    buttonBox: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '15px',
      '@media screen and (max-width: 899px)': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    addButton: {
      display: 'flex',
      alignItems: 'center',
      width: '30px',
      height: '30px',
      justifyContent: 'center',
      padding: '5px',
      marginLeft: '10px',
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    deleteButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '5px',
      width: '30px',
      height: '30px',
      marginLeft: '10px',
      borderRadius: '50%',
      backgroundColor: 'red',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#ff3535',
      },
    },
  };
  const newDepartments =
    dataDepartments instanceof Array
      ? dataDepartments.map((x) => ({
          value: x?.idDepartment,
          label: x?.name?.toUpperCase(),
        }))
      : [];

  return (
    <Box
      style={{
        width: '100%',
        margin: '0 20px',
      }}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          background: '#fff',
          borderRadius: '16px',
          boxShadow: '0px 3px 6px #00000029',
        }}>
        {/* <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}></Box> */}
        <Box
          sx={{
            height: '85%',
            overflow: 'auto',
            padding: '20px 40px 20px 40px',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <TitleComponent>
            {!idBenefit ? 'Registro de beneficios' : 'Editar beneficios'}
          </TitleComponent>
          <Typography variant="h5" color={theme.palette.primary.main} sx={{ pb: '15px' }}>
            Datos del beneficio
          </Typography>
          <Box>
            <Grid container spacing={2} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
              <Grid item xs={12} sm={12} md={4} lg={3} container justifyContent="center">
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '5px',
                  }}>
                  <Tooltip
                    title={`${selectedImage || getLogo ? 'Cambiar imagen' : 'Cargar imagen'}`}
                    placement="right">
                    <Button sx={styles.buttonLogo} onClick={() => handleLogo('logo')}>
                      <Box
                        sx={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                          border: '1px dashed gray',
                          borderRadius: '5px',
                          overflow: 'hidden',
                          padding: '2px',
                          background: '#D6D6D6 0% 0% no-repeat padding-box',
                          aspectRatio: ' 1 / 1',
                        }}>
                        <Box
                          sx={{
                            width: '100%',
                            objectFit: 'contain',
                          }}>
                          {!!selectedImage?.file && !getLogo && (
                            <img
                              src={URL.createObjectURL(new Blob([selectedImage?.file]))}
                              alt=""
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                              }}
                            />
                          )}
                          {!selectedImage?.file && !!getLogo && (
                            <img src={getLogo?.file} alt="" style={styles.iconLogo} />
                          )}
                          {!selectedImage?.file && !getLogo?.file && (
                            <AddPhotoAlternateIcon sx={styles.iconLogo} />
                          )}
                          <input
                            type="file"
                            name="logo"
                            id="logo"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleChange}
                            required
                          />
                          <Typography sx={styles.buttonTextLogo}>
                            {!getLogo?.logo && !selectedImage ? 'Adjuntar imagen' : null}
                          </Typography>
                        </Box>
                      </Box>
                    </Button>
                  </Tooltip>
                  <Typography variant="caption" color={theme.palette.primary?.dark}>
                    Resolución mínima recomendada: 700 x 470
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={9}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <NormalSelectField
                      name="idThirdParty"
                      key="idThirdParty"
                      label="Comercio"
                      schema={schema?.fields?.idThirdParty}
                      control={control}
                      options={
                        (thirdPartyProvider || [])?.map((x) => ({
                          value: Number(x?.idThirdParty),
                          label:
                            x?.businessName?.toUpperCase() ||
                            `${x?.firstName?.toUpperCase()} ${x?.firstSurname?.toUpperCase()}`,
                        })) || []
                      }
                      disabled={idThirdPartyLogin}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <NormalSelectField
                      name="typeBeneficiary"
                      key="typeBeneficiary"
                      value="typeBeneficiary"
                      label="Tipo de beneficiario"
                      disabled
                      schema={schema?.fields?.typeBeneficiary}
                      control={control}
                      options={[
                        {
                          value: 1,
                          label: 'EMPRESA',
                        },
                        {
                          value: 2,
                          label: 'PERSONA',
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={4}>
                    <InputField
                      label="Título"
                      name="name"
                      schema={schema.fields?.name}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={4}>
                    <Grid container xs={13} sm={13} md={13} lg={13} spacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={12}>
                        <InputField
                          label="Código"
                          name="number"
                          schema={schema.fields?.number}
                          control={control}
                          disabled
                          // rows={4}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={12}>
                        <NormalSelectField
                          name="idCategory"
                          label="Categorías"
                          key="idCategory"
                          schema={schema?.fields?.idCategory}
                          control={control}
                          options={
                            (dataBenefitCategory || []).slice(1).map((x) => ({
                              value: x?.idCategory,
                              label: x?.name?.toUpperCase(),
                            })) || []
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={8}>
                    <TextAreaField
                      label="Descripción del beneficio"
                      name="description"
                      schema={schema.fields?.description}
                      control={control}
                      rows={3}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={8} display={'flex'} alignItems={'center'}>
                    <Typography variant="h6" color={theme.palette.primary.main}>
                      Beneficio visible
                    </Typography>
                    <Checkbox {...label} checked={isVisible} onChange={handleVisibilityChange} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ marginTop: '15px' }}>
            <Typography variant="h5" color={theme.palette.primary.main} sx={{ pb: '15px' }}>
              Vigencias del beneficio
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <InputField
                  onKeyDown={onlyNumbers}
                  label="Uso por cupón"
                  name="benefitValidity.usagePerson"
                  schema={schema.fields?.usagePerson}
                  control={control}
                  disabled={!!idBenefit}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <NormalSelectField
                  key="name"
                  label="Periodicidad"
                  name="benefitValidity.periodicity"
                  schema={schema.fields?.periodicity}
                  control={control}
                  disabled={!!idBenefit}
                  options={[
                    {
                      label: 'DIA',
                      value: 1,
                    },
                    {
                      label: 'SEMANA',
                      value: 7,
                    },
                    {
                      label: 'QUINCENA',
                      value: 15,
                    },
                    {
                      label: 'MES',
                      value: 30,
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <InputField
                  onKeyDown={onlyNumbers}
                  label="Total de beneficios"
                  name="benefitValidity.useLimit"
                  schema={schema.fields?.useLimit}
                  control={control}
                  disabled={!!idBenefit}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <DateAdapter
                  label="Fecha de inicio"
                  sx={{ width: '100%' }}
                  name="benefitValidity.startDate"
                  type="date"
                  key="benefitValidity.startDate"
                  schema={schema?.fields?.startDate}
                  control={control}
                  disabled={!!idBenefit}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <DateAdapter
                  label="Fecha final"
                  sx={{ width: '100%' }}
                  name="benefitValidity.endDate"
                  type="date"
                  schema={schema?.fields?.endDate}
                  control={control}
                  disabled={!!idBenefit}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ marginTop: '15px' }}>
            <Typography variant="h5" color={theme.palette.primary.main} sx={{ pb: '15px' }}>
              Ciudad
            </Typography>
            {cityGroups?.map((x: any, index: number) => (
              <Grid container spacing={2} key={index} sx={{ mb: 1 }}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Box display="flex" alignItems="center">
                    <NormalSelectField
                      name={`benefitCity.${index}.idDepartment`}
                      label="Departamento"
                      key={`benefitCity.${index}.idDepartment`}
                      schema={schema?.fields?.benefitCity[index]?.idDepartment}
                      control={control}
                      options={newDepartments}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <SelectFieldMultiple
                    name={`benefitCity.${index}.idCities`}
                    label="Ciudad"
                    key={`benefitCity.${index}.idCities`}
                    control={control}
                    disabled={!Number(watchDepartment?.[index]?.idDepartment)}
                    options={
                      (dataCities || [])
                        .filter(
                          (x) => x?.idDepartment === Number(watchDepartment[index]?.idDepartment)
                        )
                        .map((x) => ({
                          value: x?.idCity,
                          label: x?.name?.toUpperCase(),
                        })) || []
                    }
                  />
                </Grid>
                {cityGroups?.length - 1 === index && (
                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="center"
                      height="35px">
                      <IconButton sx={styles.addButton} onClick={handleAddCity}>
                        <AddIcon />
                      </IconButton>
                      {cityGroups.length > 1 && (
                        <IconButton
                          sx={styles.deleteButton}
                          onClick={() => handleRemoveCityGroup(index)}>
                          <ClearIcon />
                        </IconButton>
                      )}
                    </Box>
                  </Grid>
                )}
              </Grid>
            ))}
          </Box>
          <Box sx={styles.buttonBox}>
            <Tooltip title="Registrar beneficio">
              <ButtonSubmit
                disabled={createLoading || updateLoading}
                type="button"
                backgroundColor={theme.palette.primary.main}
                hoverStyles={theme.palette.primary.dark}
                onClick={idBenefit ? handleUpdate : handleRegister}>
                {createLoading || updateLoading ? (
                  <CircularProgress size={15} sx={{ color: 'white' }} />
                ) : (
                  <SaveIcon />
                )}
                <Typography fontSize="14px">{!idBenefit ? 'CREAR' : 'ACTUALIZAR'}</Typography>
              </ButtonSubmit>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BenefitsRegisterV;
