import { ResponseThirdParties } from '../../../thirdParties/thirdParties/model/thirdPartiesResponse';

export class ResponseThirdPartySeller {
  public idThirdPartySeller = 0;

  public idThirdParty = 0;

  public idSeller = 0;

  public thirdParty = new ResponseThirdParties();
}
