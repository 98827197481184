import { AxiosError } from 'axios';
import { ResponseProfession } from '../../../core/domain/workInformation/profession/model/responseProfession';
import { ProfessionState } from '../../../core/domain/workInformation/profession/state/professionState';
import actions from './actions';
import { TProfessionsReducer } from './types';

const { PROFESSION_LIST_PAGE_BEGIN, PROFESSION_LIST_PAGE_SUCCESS, PROFESSION_LIST_PAGE_ERR } =
  actions;

const initialProfession: ProfessionState = new ProfessionState();

const ProfessionReducer = (
  state = initialProfession,
  action: { type: string; err: AxiosError; data: ResponseProfession[] }
): TProfessionsReducer => {
  const { type, data, err } = action;
  switch (type) {
    case PROFESSION_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case PROFESSION_LIST_PAGE_SUCCESS:
      return {
        ...state,
        professions: data,
        loading: false,
      };
    case PROFESSION_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default ProfessionReducer;
