import { AxiosError } from 'axios';
import 'reflect-metadata';
import { container } from 'tsyringe';
import {
  actionsBeneficiaryValidityBenefits,
  actionsBeneficiaries,
  actionsBeneficiariesCustomers,
  actionsBeneficiary,
  actionsBeneficiaryCreate,
  actionsBeneficiaryDelete,
  actionsBeneficiaryForBenefits,
  actionsBeneficiaryUpdate,
  actionsCreateBulkBeneficiaryList,
} from './actions';
import { AppDispatch } from '../store';
import { IBeneficiariesApplication } from '../../core/domain/interface/application/beneficiaries/IBeneficiariesApplication';
import { RequestBeneficiaries } from '../../core/domain/beneficiaries/model/requestBeneficiaries';

const { beneficiariesListPageBegin, beneficiariesListPageSuccess, beneficiariesListPageErr } =
  actionsBeneficiaries;
const {
  beneficiariesCustomersListPageBegin,
  beneficiariesCustomersListPageSuccess,
  beneficiariesCustomersListPageErr,
} = actionsBeneficiariesCustomers;
const { beneficiaryListPageBegin, beneficiaryListPageSuccess, beneficiaryListPageErr } =
  actionsBeneficiary;
const { beneficiaryCreatePageBegin, beneficiaryCreatePageSuccess, beneficiaryCreatePageErr } =
  actionsBeneficiaryCreate;
const { beneficiaryUpdatePageSuccess, beneficiaryUpdatePageBegin, beneficiaryUpdatePageErr } =
  actionsBeneficiaryUpdate;
const { beneficiaryDeletePageSuccess, beneficiaryDeletePageBegin, beneficiaryDeletePageErr } =
  actionsBeneficiaryDelete;
const {
  createBulkBeneficiaryListPageBegin,
  createBulkBeneficiaryListPageSuccess,
  createBulkBeneficiaryListPageErr,
} = actionsCreateBulkBeneficiaryList;
const {
  beneficiaryForBenefitsPageBegin,
  beneficiaryForBenefitsPageSuccess,
  beneficiaryForBenefitsPageErr,
} = actionsBeneficiaryForBenefits;
const { beneficiaryValidityBegin, beneficiaryValiditySuccess, beneficiaryValidityErr } =
  actionsBeneficiaryValidityBenefits;

export const beneficiariesByBenefitsPage = (
  identificationNumber: number
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(beneficiaryForBenefitsPageBegin());
      dispatch(beneficiaryValidityBegin());
      const beneficiariesApplication = container.resolve<IBeneficiariesApplication>(
        'IBeneficiariesApplication'
      );

      // const request = new RequestBeneficiaries();
      const result = await beneficiariesApplication.getBeneficiaryByBenefitById(
        identificationNumber
      );
      dispatch(beneficiaryForBenefitsPageSuccess(result));
      dispatch(beneficiaryValiditySuccess(result));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(beneficiaryForBenefitsPageErr(error));
      dispatch(beneficiaryValidityErr(error));
      dispatch(beneficiaryValiditySuccess({}));
    }
  };
};

// export const beneficiariesByBenefitsListPage = (): ((dispatch: AppDispatch) => Promise<void>) => {
//   return async (dispatch: AppDispatch) => {
//     try {
//       dispatch(beneficiaryForBenefitsListPageBegin());
//       const beneficiariesApplication = container.resolve<IBeneficiariesApplication>(
//         'IBeneficiariesApplication'
//       );

//       const request = new RequestBeneficiaries();
//       const result = await beneficiariesApplication.getBeneficiaryById(request);

//       dispatch(beneficiaryForBenefitsListPageSuccess(result));
//     } catch (ex) {
//       const error = ex as AxiosError;
//       dispatch(beneficiaryForBenefitsListPageErr(error));
//     }
//   };
// };

export const beneficiariesListPage = (): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(beneficiariesListPageBegin());
      const beneficiariesApplication = container.resolve<IBeneficiariesApplication>(
        'IBeneficiariesApplication'
      );

      const request = new RequestBeneficiaries();
      const result = await beneficiariesApplication.getBeneficiaries(request);

      dispatch(beneficiariesListPageSuccess(result));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(beneficiariesListPageErr(error));
    }
  };
};

export const beneficiariesCustomersListPage = (): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(beneficiariesCustomersListPageBegin());
      const beneficiariesCustomersApplication = container.resolve<IBeneficiariesApplication>(
        'IBeneficiariesApplication'
      );

      const request = new RequestBeneficiaries();
      const result = await beneficiariesCustomersApplication.getBeneficiariesCustomers(request);

      dispatch(beneficiariesCustomersListPageSuccess(result));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(beneficiariesCustomersListPageErr(error));
    }
  };
};

export const getBeneficiaryByIdListPage = (
  listState: RequestBeneficiaries
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(beneficiaryListPageBegin());
      const beneficiariesApplication = container.resolve<IBeneficiariesApplication>(
        'IBeneficiariesApplication'
      );

      const resultGetById = await beneficiariesApplication.getBeneficiaryById(listState);
      dispatch(beneficiaryListPageSuccess(resultGetById));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(beneficiaryListPageErr(error));
    }
  };
};

export const createBeneficiaryPage = (
  listState: RequestBeneficiaries
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(beneficiaryCreatePageBegin());
      const beneficiariesApplication = container.resolve<IBeneficiariesApplication>(
        'IBeneficiariesApplication'
      );

      const resultCreate = await beneficiariesApplication.createBeneficiary(listState);
      dispatch(beneficiaryCreatePageSuccess(resultCreate));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(beneficiaryCreatePageErr(error));
    }
  };
};

export const updateBeneficiaryPage = (
  listState: RequestBeneficiaries
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(beneficiaryUpdatePageBegin());
      const beneficiariesApplication = container.resolve<IBeneficiariesApplication>(
        'IBeneficiariesApplication'
      );

      const resultUpdate = await beneficiariesApplication.updateBeneficiary(listState);
      dispatch(beneficiaryUpdatePageSuccess(resultUpdate));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(beneficiaryUpdatePageErr(error));
    }
  };
};

export const deleteBeneficiaryPage = (
  listState: RequestBeneficiaries
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(beneficiaryDeletePageBegin());
      const beneficiariesApplication = container.resolve<IBeneficiariesApplication>(
        'IBeneficiariesApplication'
      );

      const resultDelete = await beneficiariesApplication.deleteBeneficiary(listState);
      dispatch(beneficiaryDeletePageSuccess(resultDelete));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(beneficiaryDeletePageErr(error));
    }
  };
};

export const bulkCreateBeneficiaryCreatePage = (
  listState: RequestBeneficiaries[]
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(createBulkBeneficiaryListPageBegin());
      const beneficiariesApplication = container.resolve<IBeneficiariesApplication>(
        'IBeneficiariesApplication'
      );

      const result = await beneficiariesApplication.bulkCreateBeneficiary(listState);
      dispatch(createBulkBeneficiaryListPageSuccess(result));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(createBulkBeneficiaryListPageErr(error));
    }
  };
};

export const clearBeneficiariesListPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(beneficiariesListPageBegin());
    dispatch(beneficiariesListPageSuccess([]));
    dispatch(beneficiariesListPageErr(undefined));
  };
};

export const clearBeneficiariesCustomersListPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(beneficiariesCustomersListPageBegin());
    dispatch(beneficiariesCustomersListPageSuccess([]));
    dispatch(beneficiariesCustomersListPageErr(undefined));
  };
};

export const clearCreateBeneficiaryPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(beneficiaryCreatePageBegin());
    dispatch(beneficiaryCreatePageSuccess(undefined));
    dispatch(beneficiaryCreatePageErr(undefined));
  };
};
export const clearBeneficiaryPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(beneficiaryListPageBegin());
    dispatch(beneficiaryListPageSuccess(undefined));
    dispatch(beneficiaryListPageErr(undefined));
  };
};

export const clearUpdateBeneficiaryPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(beneficiaryUpdatePageBegin());
    dispatch(beneficiaryUpdatePageErr(undefined));
    dispatch(beneficiaryUpdatePageSuccess(undefined));
  };
};

export const clearDeleteBeneficiaryPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(beneficiaryDeletePageBegin());
    dispatch(beneficiaryDeletePageErr(undefined));
    dispatch(beneficiaryDeletePageSuccess(undefined));
  };
};

export const clearBulkCreateBeneficiaryCreatePage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(createBulkBeneficiaryListPageBegin());
    dispatch(createBulkBeneficiaryListPageErr(undefined));
    dispatch(createBulkBeneficiaryListPageSuccess(undefined));
  };
};
