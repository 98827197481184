import { combineReducers } from '@reduxjs/toolkit';
import ProfessionReducer from './workInformation/profession/reducers';
import TaxRegimeReducer from './taxInformation/taxRegime/reducers';
import TaxInfoReducer from './taxInformation/taxInfo/reducers';
import ResponsibilitiesReducer from './taxInformation/responsibilities/reducers';
import EconomicActivitiesReducer from './taxInformation/economicActivities/reducers';
import EconomicSectorsReducer from './taxInformation/economicSectors/reducers';
import DepartmentsReducer from './addresses/departments/reducers';
import CitiesReducer from './addresses/cities/reducers';
import CountriesReducer from './addresses/countries/reducers';
import StreetTypesReducer from './addresses/streetTypes/reducers';
import BanksReducer from './banks/banks/reducers';
import KinshipsReducer from './contacts/kinships/reducers';
import AcademicLevelsReducer from './documentsPersons/academicLevels/reducers';
import { DocumentsTypesReducer } from './documentsPersons/documentsTypes/reducers';
import GenderReducer from './documentsPersons/gender/reducers';
import MaritalStatusReducer from './documentsPersons/maritalStatus/reducers';
import BankAccountTypesReducer from './banks/banksAccountTypes/reducers';
import {
  UserReducer,
  UserCreateReducer,
  UserLoginReducer,
  UsersReducer,
  UserUpdateReducer,
  UsersByRoleReducer,
  UpdateRoleToUserReducer,
  UserUpdatePasswordReducer,
  UserDeleteReducer,
  UserLoggedUpdatePasswordReducer,
  UserRecoveryPasswordReducer,
} from './userPermissions/user/reducer';
import UserPermissionsReducer from './userPermissions/userPermission/reducers';
import ModuleReducer from './userPermissions/module/reducers';
import SubModuleReducer from './userPermissions/subModule/reducers';
import { RolesReducer, RoleCreateReducer } from './userPermissions/role/reducer';
import { UserTypesReducer } from './userPermissions/userType/reducer';
import { LoginDataReducer, LoginReducer, LogoutReducer, RefreshReducer } from './security/reducer';
import {
  CreateThirdPartyCustomerReducer,
  CreateThirdPartyProviderReducer,
  DeleteThirdPartyCustomerReducer,
  UpdateThirdPartyCustomerReducer,
  UpdateThirdPartyProviderReducer,
  thirdPartiesCustomersReducer,
  thirdPartiesProvidersReducer,
  thirdPartyCustomerReducer,
  thirdPartyProviderReducer,
} from './thirdParties/reducer';
import AddressesReducer from './addresses/addresses/reducers';
import { BenefitCategoriesReducer } from './benefits/benefitCategory/reducer';
import {
  BenefitsReducer,
  BenefitCreateReducer,
  BenefitUpdateReducer,
  BenefitDeleteReducer,
  BenefitReducer,
  BenefitsWithPaginationReducer,
  BenefitsRandomReducer,
  BenefitsForExcelReducer,
  BenefitsByMembershipReducer,
  BenefitByBeneficiariesReducer,
  BenefitByThirdPartyReducer,
} from './benefits/benefits/reducer';
import { BenefitValidityCreateReducer } from './benefits/benefitValidity/reducer';
import {
  SellerCreateReducer,
  SellerDeleteReducer,
  SellerReducer,
  SellerUpdateReducer,
  SellersReducer,
} from './sellers/reducer';
import {
  BeneficiariesReducer,
  BeneficiariesCustomersReducer,
  BeneficiaryReducer,
  CreateBeneficiaryReducer,
  CreateBulkBeneficiaryListReducer,
  DeleteBeneficiaryReducer,
  UpdateBeneficiaryReducer,
  BeneficiariesByBenefitsListReducer,
  BeneficiaryValidityListReducer,
} from './beneficiaries/reducer';
import {
  CreateBenefitBeneficiaryReducer,
  GetByIdBenefitBeneficiaryReducer,
  ValidateBenefitBeneficiaryReducer,
} from './benefitBeneficiaries/reducer';
import { LoginProviderReducer, LogoutProviderReducer } from './loginProvider/reducer';
import {
  BeneficiaryLoginReducer,
  LoginBeneficiaryReducer,
  LogoutBeneficiaryReducer,
} from './loginBeneficiary/reducer';
import {
  BranchOfficeReducer,
  BranchsOfficesReducer,
  CreateBranchOfficeReducer,
  DeleteBranchOfficeReducer,
  UpdateBranchOfficeReducer,
} from './branchsOffices/reducer';
import {
  DeleteUserSessionReducer,
  UserSessionsReducer,
} from './userPermissions/userSession/reducers';
import { ConsecutiveReducer } from './consecutives/reducer';

export const rootReducer = combineReducers({
  login: LoginReducer,
  loginData: LoginDataReducer,
  loginBeneficiary: BeneficiaryLoginReducer,
  logout: LogoutReducer,
  refresh: RefreshReducer,
  professions: ProfessionReducer,
  TaxRegime: TaxRegimeReducer,
  TaxInfo: TaxInfoReducer,
  Responsibilities: ResponsibilitiesReducer,
  economicSectors: EconomicSectorsReducer,
  EconomicActivities: EconomicActivitiesReducer,
  addresses: AddressesReducer,
  cities: CitiesReducer,
  countries: CountriesReducer,
  departments: DepartmentsReducer,
  streetTypes: StreetTypesReducer,
  banks: BanksReducer,
  bankAccountTypes: BankAccountTypesReducer,
  kinships: KinshipsReducer,
  academicLevels: AcademicLevelsReducer,
  documentsTypes: DocumentsTypesReducer,
  gender: GenderReducer,
  maritalStatus: MaritalStatusReducer,
  user: UserReducer,
  users: UsersReducer,
  userCreated: UserCreateReducer,
  userUpdated: UserUpdateReducer,
  passwordUpdated: UserUpdatePasswordReducer,
  loggedPasswordUpdated: UserLoggedUpdatePasswordReducer,
  userDeleted: UserDeleteReducer,
  userLogin: UserLoginReducer,
  updatedRoleToUser: UpdateRoleToUserReducer,
  userPermissions: UserPermissionsReducer,
  modules: ModuleReducer,
  subModules: SubModuleReducer,
  roles: RolesReducer,
  roleCreated: RoleCreateReducer,
  usersByRole: UsersByRoleReducer,
  userTypes: UserTypesReducer,
  thirdPartiesProviders: thirdPartiesProvidersReducer,
  thirdPartyProvider: thirdPartyProviderReducer,
  thirdPartyProviderCreated: CreateThirdPartyProviderReducer,
  thirdPartyProviderUpdated: UpdateThirdPartyProviderReducer,
  thirdPartiesCustomers: thirdPartiesCustomersReducer,
  thirdPartyCustomerDeleted: DeleteThirdPartyCustomerReducer,
  thirdPartyCustomer: thirdPartyCustomerReducer,
  thirdPartyCustomerCreated: CreateThirdPartyCustomerReducer,
  thirdPartyCustomerUpdated: UpdateThirdPartyCustomerReducer,
  userRecoveryPassword: UserRecoveryPasswordReducer,
  benefits: BenefitsReducer,
  benefitsForExcel: BenefitsForExcelReducer,
  benefitsRandom: BenefitsRandomReducer,
  benefitsWithPagination: BenefitsWithPaginationReducer,
  benefitCategories: BenefitCategoriesReducer,
  benefitValidityCreated: BenefitValidityCreateReducer,
  BenefitByThirdPartyReducer,
  benefit: BenefitReducer,
  benefitCreated: BenefitCreateReducer,
  benefitUpdated: BenefitUpdateReducer,
  benefitDeleted: BenefitDeleteReducer,
  benefitsByMembership: BenefitsByMembershipReducer,
  benefitByBeneficiaries: BenefitByBeneficiariesReducer,
  seller: SellerReducer,
  sellers: SellersReducer,
  sellerCreated: SellerCreateReducer,
  sellerUpdated: SellerUpdateReducer,
  sellerDeleted: SellerDeleteReducer,
  beneficiaries: BeneficiariesReducer,
  beneficiariesCustomers: BeneficiariesCustomersReducer,
  beneficiary: BeneficiaryReducer,
  beneficiaryCreated: CreateBeneficiaryReducer,
  beneficiaryUpdated: UpdateBeneficiaryReducer,
  beneficiaryDeleted: DeleteBeneficiaryReducer,
  beneficiaryList: CreateBulkBeneficiaryListReducer,
  benefitBeneficiariesCreated: CreateBenefitBeneficiaryReducer,
  benefitBeneficiariesGetById: GetByIdBenefitBeneficiaryReducer,
  benefitBeneficiaryValidate: ValidateBenefitBeneficiaryReducer,
  providerLoggedIn: LoginProviderReducer,
  providerLoggedOut: LogoutProviderReducer,
  beneficiaryLoggedIn: LoginBeneficiaryReducer,
  beneficiaryLoggedOut: LogoutBeneficiaryReducer,
  branchsOffices: BranchsOfficesReducer,
  branchOfficeCreated: CreateBranchOfficeReducer,
  branchsOfficesDeleted: DeleteBranchOfficeReducer,
  branchsOfficesUpdated: UpdateBranchOfficeReducer,
  branchOffice: BranchOfficeReducer,
  sessionDeleted: DeleteUserSessionReducer,
  sessionValidated: UserSessionsReducer,
  beneficiaryByBenefits: BeneficiariesByBenefitsListReducer,
  consecutive: ConsecutiveReducer,
  validityBeneficiary: BeneficiaryValidityListReducer,
});
