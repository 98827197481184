import { AxiosError } from 'axios';
import { ResponseEconomicActivities } from '../../../core/domain/taxInformation/economicActivities/model/responseEconomicActivities';

const actions = {
  ECONOMIC_ACTIVITIES_LIST_PAGE_BEGIN: 'ECONOMIC_ACTIVITIES_LIST_PAGE_BEGIN',
  ECONOMIC_ACTIVITIES_PAGE_SUCCESS: 'ECONOMIC_ACTIVITIES_LIST_PAGE_SUCCESS',
  ECONOMIC_ACTIVITIES_LIST_PAGE_ERR: 'ECONOMIC_ACTIVITIES_LIST_PAGE_ERR',

  economicActivitiesListPageBegin: (): { type: string } => {
    return {
      type: actions.ECONOMIC_ACTIVITIES_LIST_PAGE_BEGIN,
    };
  },

  economicActivitiesListPageSuccess: (
    data: ResponseEconomicActivities[]
  ): { type: string; data: ResponseEconomicActivities[] } => {
    return {
      type: actions.ECONOMIC_ACTIVITIES_PAGE_SUCCESS,
      data,
    };
  },

  economicActivitiesListPageErr: (err: AxiosError): { type: string; err: AxiosError } => {
    return {
      type: actions.ECONOMIC_ACTIVITIES_LIST_PAGE_ERR,
      err,
    };
  },
};

export default actions;
