import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SellersRegisterV from '../../../components/sellers/sellerRegister';
import {
  TCities,
  TDataDepartments,
  TDataDocumentType,
  TGender,
  TMemberships,
  TStreetTypes,
} from './type.d';
import { RootState } from '../../../../../redux/store';
import { TDepartmentsReducer } from '../../../../../redux/addresses/departments/types';
import { TCitiesReducer } from '../../../../../redux/addresses/cities/types';
import { TStreetTypesReducer } from '../../../../../redux/addresses/streetTypes/types';
import { TDocumentTypesReducer } from '../../../../../redux/documentsPersons/documentsTypes/types.d';
import { TGenderReducer } from '../../../../../redux/documentsPersons/gender/types';
import { TThirdPartiesCustomersReducer } from '../../../../../redux/thirdParties/types.d';
import {
  TSellerCreateReducer,
  TSellerReducer,
  TSellerUpdateReducer,
} from '../../../../../redux/sellers/types.d';
import { TDispatch } from '../../../../../common/utils/types.d';
import {
  clearSellerCreatePage,
  clearSellerUpdateListPage,
  getByIdSellerListPage,
  sellerCreatePage,
  sellerUpdatePage,
} from '../../../../../redux/sellers/actionCreator';
import { documentsTypesListPage } from '../../../../../redux/documentsPersons/documentsTypes/actionCreator';
import { departmentsListPage } from '../../../../../redux/addresses/departments/actionCreator';
import { citiesListPage } from '../../../../../redux/addresses/cities/actionCreator';
import { streetTypesListPage } from '../../../../../redux/addresses/streetTypes/actionCreator';
import { genderListPage } from '../../../../../redux/documentsPersons/gender/actionCreator';
import { thirdPartiesCustomersListPage } from '../../../../../redux/thirdParties/actionCreator';
import { filterKeyObject } from '../../../../../common/utils/functions';

export const SellersRegisterC = () => {
  /**
   * States
   */
  const [dataDocumentType, setDataDocumentType] = useState<TDataDocumentType>([]);
  const [dataGender, setDataGender] = useState<TGender>([]);
  const [dataDepartments, setDataDepartments] = useState<TDataDepartments>([]);
  const [dataCities, setDataCities] = useState<TCities>([]);
  const [dataStreetTypes, setDataStreetTypes] = useState<TStreetTypes>([]);
  const [dataMemberships, setDataMemberships] = useState<TMemberships>([]);
  const [loading, setLoading] = useState<boolean>(false);

  /**
   * dispatch
   */
  const dispatch = useDispatch();

  const navigate = useNavigate();

  /**
   * Dispatch de crear vendedor
   */
  const createSellerDispatch: TSellerCreateReducer = useSelector(
    (dataState: RootState) => dataState?.sellerCreated
  );

  /**
   * Dispatch de actualizar vendedor
   */
  const updateSellerDispatch: TSellerUpdateReducer = useSelector(
    (dataState: RootState) => dataState?.sellerUpdated
  );

  /**
   * Dispatch de getSellerById
   */
  const getSellerByIdDispatch: TSellerReducer = useSelector(
    (dataState: RootState) => dataState?.seller
  );

  /**
   * Dispatch de tipos de documentos
   */
  const MembershipsDispatch: TThirdPartiesCustomersReducer = useSelector(
    (dataState: RootState) => dataState?.thirdPartiesCustomers
  );

  /**
   * Dispatch de tipos de documentos
   */
  const userDocumentTypeDispatch: TDocumentTypesReducer = useSelector(
    (dataState: RootState) => dataState?.documentsTypes
  );

  /**
   * Dispatch de género
   */
  const genderDispatch: TGenderReducer = useSelector((dataState: RootState) => dataState?.gender);

  /**
   * Dispatch de departamentos
   */
  const departmentsDispatch: TDepartmentsReducer = useSelector(
    (dataState: RootState) => dataState?.departments
  );
  /**
   * Dispatch de ciudades
   */
  const citiesDispatch: TCitiesReducer = useSelector((dataState: RootState) => dataState?.cities);

  /**
   * Dispatch de tipo de calles
   */
  const streetTypesDispatch: TStreetTypesReducer = useSelector(
    (dataState: RootState) => dataState?.streetTypes
  );

  /**
   * Schema de validaciones de campos
   */
  const schema = yup
    .object({
      idDocumentType: yup.number().required('Campo requerido'),
      identificationNumber: yup
        .number()
        .required('Número de identificación es requerido')
        .typeError('Este campo sólo puede contener números y una longitud menor a 12')
        .test(
          'is-between-9-and-11-digits',
          'Número de identificación debe tener entre 9 y 11 dígitos',
          (value) => {
            if (typeof value !== 'number') {
              return false;
            }
            const stringValue = String(Math.floor(value));
            const digitCount = stringValue.length;
            return digitCount >= 9 && digitCount <= 11;
          }
        ),
      idGender: yup.number().required('Campo requerido'),
      firstName: yup
        .string()
        .required('El primer nombre es requerido')
        .matches(/^[A-Za-zñáéíóúÁÉÍÓÚÑ\s]+$/, 'Sólo se permiten letras'),
      middleName: yup.string().matches(/^[A-Za-zñáéíóúÁÉÍÓÚÑ\s]*$/, 'Sólo se permiten letras'),
      firstSurname: yup
        .string()
        .required('El primer apellido es requerido')
        .matches(/^[A-Za-zñáéíóúÁÉÍÓÚÑ\s]+$/, 'Sólo se permiten letras'),
      secondSurname: yup
        .string()
        .required('El segundo apellido es requerido')
        .matches(/^[A-Za-zñáéíóúÁÉÍÓÚÑ\s]*$/, 'Sólo se permiten letras'),
      address: yup.object({
        idDepartment: yup.number().required('Campo requerido'),
        idCity: yup.number().required('Campo requerido'),
        neighBorhood: yup
          .string()
          .required('El barrio es requerido')
          .matches(/^[A-Za-zñáéíóúÁÉÍÓÚÑ\s\d]+$/u, 'Sólo se permiten letras y números'),
        idAddressFirstStreetType: yup.number().required('Campo requerido'),
        idAddressSecondStreetType: yup.number().required('Campo requerido'),
        firstStreetNumber: yup.string().required('El numero es requerido'),
        secondStreetNumber: yup.string().required('El numero 2 es requerido'),
        thirdStreetNumber: yup.string().required('El numero 3 es requerido'),
        description: yup.string(),
      }),
      cellPhone: yup
        .string()
        .length(10, 'El número de celular debe tener exactamente 10 caracteres')
        .required('El número de celular es requerido'),
      telephone: yup
        .string()
        .matches(/^$|^\d{8}$/, 'El número de teléfono debe tener exactamente 8 caracteres')
        .nullable(),
      email: yup.string().required('Correo requerido').email('El correo electrónico no es válido'),
    })
    .required();

  /**
   * useForm
   */
  const {
    // register,
    handleSubmit,
    control,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  /**
   * Watch del departamento seleccionado
   */
  const watchDepartment = watch('address.idDepartment');

  /**
   * Watch del departamento seleccionado
   */
  const watchIdStreetTypeOne = watch('address.idAddressFirstStreetType');

  /**
   * Obteniendo el ID por los parámetros
   */
  const { id } = useParams();
  const idSeller: number = Number(id);
  const location = useLocation();

  /*
   * Función para retroceder.
   */
  const goBack = () => {
    setTimeout(() => {
      navigate('/vendedores/lista');
    });
  };

  /**
   * Función que se encarga de crear un vendedor
   */
  const handleRegister = handleSubmit(async () => {
    dispatch(
      sellerCreatePage({
        ...getValues(),
      }) as TDispatch
    );
    setLoading(true);
  });

  /**
   * Función para editar un vendedor
   */
  const handleUpdate = handleSubmit(async () => {
    dispatch(
      sellerUpdatePage({
        ...filterKeyObject(getValues(), ['idDocumentType', 'identificationNumber']),
        idSeller,
      }) as TDispatch
    );
    setLoading(true);
  });

  /**
   * UseEffect para llamar documentTypes
   */
  useEffect(() => {
    setDataDocumentType(
      userDocumentTypeDispatch?.documentsTypes?.filter((item) => item?.idDocumentType !== 4) || []
    );
  }, [userDocumentTypeDispatch?.documentsTypes]);

  /**
   * UseEffect para llamar a Genders
   */
  useEffect(() => {
    setDataGender(genderDispatch?.gender || []);
  }, [genderDispatch?.gender]);

  /**
   * UseEffect para llamar a Departments
   */
  useEffect(() => {
    setDataDepartments(departmentsDispatch?.departments || []);
  }, [departmentsDispatch?.departments]);

  /**
   * UseEffect para llamar a Cities
   */
  useEffect(() => {
    setDataCities(citiesDispatch?.cities || []);
  }, [citiesDispatch?.cities]);

  /**
   * UseEffect para llamar a StreetTypes
   */
  useEffect(() => {
    setDataStreetTypes(streetTypesDispatch?.streetTypes || []);
  }, [streetTypesDispatch?.streetTypes]);

  /**
   * UseEffect para llamar a Memberships
   */
  useEffect(() => {
    setDataMemberships(MembershipsDispatch?.thirdPartiesCustomers || []);
  }, [MembershipsDispatch?.thirdPartiesCustomers]);

  /**
   * UseEffect pendiente al momento de crear un vendedor existente
   */
  useEffect(() => {
    if (loading) {
      if (createSellerDispatch?.sellerCreated && !createSellerDispatch?.error) {
        toast.success('Vendedor registrado exitosamente', {
          hideProgressBar: true,
        });
        reset({
          idDocumentType: undefined,
          identificationNumber: undefined,
          idGender: undefined,
          firstName: '',
          middleName: '',
          firstSurname: '',
          secondSurname: '',
          address: {
            idDepartment: undefined,
            idCity: undefined,
            neighBorhood: '',
            firstStreetNumber: '',
            idAddressFirstStreetType: undefined,
            idAddressSecondStreetType: undefined,
            secondStreetNumber: '',
            thirdStreetNumber: '',
            description: '',
          },
          cellPhone: '',
          telephone: '',
          email: '',
        });
        dispatch(clearSellerCreatePage() as TDispatch);
        goBack();
      } else if (!createSellerDispatch?.sellerCreated && createSellerDispatch?.error) {
        const message = createSellerDispatch?.error?.response
          ? createSellerDispatch?.error?.response?.data?.message
          : 'Ha ocurrido una incidencia';
        toast.error(`${message}`, {
          hideProgressBar: true,
        });
        dispatch(clearSellerCreatePage() as TDispatch);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSellerDispatch?.sellerCreated, createSellerDispatch?.error, dispatch, reset]);

  /**
   * UseEffect pendiente al momento de actualizar un vendedor existente
   */
  useEffect(() => {
    if (idSeller && loading) {
      if (updateSellerDispatch?.sellerUpdated && !updateSellerDispatch?.error) {
        toast.success('Vendedor actualizado exitosamente', {
          hideProgressBar: true,
        });
        reset({
          idDocumentType: undefined,
          identificationNumber: undefined,
          idGender: undefined,
          firstName: '',
          middleName: '',
          firstSurname: '',
          secondSurname: '',
          address: {
            idDepartment: undefined,
            idCity: undefined,
            neighBorhood: '',
            firstStreetNumber: '',
            idAddressFirstStreetType: undefined,
            idAddressSecondStreetType: undefined,
            secondStreetNumber: '',
            thirdStreetNumber: '',
            description: '',
          },
          cellPhone: '',
          telephone: '',
          email: '',
        });
        dispatch(clearSellerUpdateListPage() as TDispatch);
        dispatch(getByIdSellerListPage({ idSeller }) as TDispatch);
        goBack();
      } else if (!updateSellerDispatch?.sellerUpdated && updateSellerDispatch?.error) {
        const message = updateSellerDispatch?.error?.response
          ? updateSellerDispatch?.error?.response?.data?.message
          : 'Ha ocurrido una incidencia';
        toast.error(`${message}`, {
          hideProgressBar: true,
        });
        dispatch(clearSellerUpdateListPage() as TDispatch);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSellerDispatch?.sellerUpdated, updateSellerDispatch?.error]);

  /**
   * Muestra la información según los parámetros de la URL
   */
  useEffect(() => {
    if (idSeller) {
      dispatch(
        getByIdSellerListPage({
          idSeller,
        }) as TDispatch
      );
    } else {
      reset({
        idDocumentType: undefined,
        identificationNumber: undefined,
        idGender: undefined,
        firstName: '',
        middleName: '',
        firstSurname: '',
        secondSurname: '',
        address: {
          idDepartment: undefined,
          idCity: undefined,
          neighBorhood: '',
          firstStreetNumber: '',
          idAddressFirstStreetType: undefined,
          idAddressSecondStreetType: undefined,
          secondStreetNumber: '',
          thirdStreetNumber: '',
          description: '',
        },
        cellPhone: '',
        telephone: '',
        email: '',
      });
    }
  }, [idSeller, dispatch, reset]);

  useEffect(() => {
    if (location?.pathname === '/vendedores/registro') {
      reset({
        idDocumentType: undefined,
        identificationNumber: undefined,
        idGender: undefined,
        firstName: '',
        middleName: '',
        firstSurname: '',
        secondSurname: '',
        address: {
          idDepartment: undefined,
          idCity: undefined,
          neighBorhood: '',
          firstStreetNumber: '',
          idAddressFirstStreetType: undefined,
          idAddressSecondStreetType: undefined,
          secondStreetNumber: '',
          thirdStreetNumber: '',
          description: '',
        },
        cellPhone: '',
        telephone: '',
        email: '',
      });
    }
  }, [location, reset]);

  /**
   * useEffect que se encarga de mostrar la información correspondiente en los campos mediante el ID
   */
  useEffect(() => {
    if (idSeller) {
      if (getSellerByIdDispatch?.seller?.idSeller) {
        const data = getSellerByIdDispatch?.seller;
        reset({
          idDocumentType: data?.idDocumentType,
          identificationNumber: data?.identificationNumber,
          idGender: data?.idGender,
          firstName: data?.firstName,
          middleName: data?.middleName,
          firstSurname: data?.firstSurname,
          secondSurname: data?.secondSurname,
          address: {
            ...data.address,
            idDepartment: data?.address?.idDepartment,
            idCity: data?.address?.idCity,
            neighBorhood: data?.address?.neighBorhood,
            firstStreetNumber: data?.address?.firstStreetNumber,
            idAddressFirstStreetType: data?.address?.idAddressFirstStreetType,
            idAddressSecondStreetType: data?.address?.idAddressSecondStreetType,
            secondStreetNumber: data?.address?.secondStreetNumber,
            thirdStreetNumber: data?.address?.thirdStreetNumber,
            description: data?.address?.description,
          },
          cellPhone: data?.cellPhone,
          telephone: data?.telephone,
          email: data?.email,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSellerByIdDispatch?.seller?.idSeller, reset]);

  /**
   * UseEffect para llamar a todos los dispatches
   */
  useEffect(() => {
    dispatch(clearSellerCreatePage() as TDispatch);
    dispatch(clearSellerUpdateListPage() as TDispatch);
    dispatch(documentsTypesListPage() as TDispatch);
    dispatch(departmentsListPage() as TDispatch);
    dispatch(citiesListPage() as TDispatch);
    dispatch(streetTypesListPage() as TDispatch);
    dispatch(genderListPage() as TDispatch);
    dispatch(thirdPartiesCustomersListPage() as TDispatch);
  }, [dispatch]);

  return (
    <SellersRegisterV
      schema={schema}
      control={control}
      errors={errors}
      createLoading={createSellerDispatch?.loading}
      updateLoading={updateSellerDispatch?.loading}
      handleRegister={handleRegister}
      handleUpdate={handleUpdate}
      dataDocumentType={dataDocumentType}
      watchDepartment={watchDepartment}
      watchIdStreetTypeOne={watchIdStreetTypeOne}
      dataDepartments={dataDepartments}
      dataCities={dataCities}
      dataStreetTypes={dataStreetTypes}
      dataGender={dataGender}
      dataMemberships={dataMemberships}
      idSeller={idSeller}
    />
  );
};
