import 'reflect-metadata';
import { AxiosResponse } from 'axios';
import { IUserInfrastructure } from '../../../domain/interface/infrastructure/userPermissions/IUserInfrastructure';
import { RequestUser } from '../../../domain/userPermissions/user/model/requestUser';
import { ResponseUser } from '../../../domain/userPermissions/user/model/responseUser';
import { processDataBackend } from '../../../../common/utils/functions';

export class UserInfrastructure implements IUserInfrastructure {
  private a = '';

  public async getUserAPI(request: RequestUser): Promise<ResponseUser[]> {
    this.a = 'aaa';
    const res: AxiosResponse = await processDataBackend('GET', '/api/user/getAll', {}, { request });
    const user: ResponseUser[] = res.data;
    return Promise.resolve(user);
  }

  public async createUserAPI(request: RequestUser): Promise<ResponseUser> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to create not found');
    const res: AxiosResponse = await processDataBackend(
      'POST',
      '/api/user/create',
      {},
      { request }
    );
    const result: ResponseUser = res.data;

    return Promise.resolve(result);
  }

  public async recoveryPasswordAPI(request: RequestUser): Promise<ResponseUser> {
    this.a = 'aaa';
    if (!request) throw new Error('error al recuperar contraseña');
    const res: AxiosResponse = await processDataBackend(
      'POST',
      '/api/user/RecoveryPassword',
      {},
      { request }
    );
    const result: ResponseUser = res.data;

    return Promise.resolve(result);
  }

  public async updateUserAPI(request: RequestUser): Promise<ResponseUser> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to update not found');
    const res: AxiosResponse = await processDataBackend('PUT', '/api/user/update', {}, { request });
    const result: ResponseUser = res.data;
    return Promise.resolve(result);
  }

  public async DeleteCodeValidationAPI(request: RequestUser): Promise<ResponseUser> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to delete not found');
    const res: AxiosResponse = await processDataBackend(
      'PUT',
      '/api/user/DeleteCodeValidation',
      {},
      { request }
    );
    const result: ResponseUser = res.data;
    return Promise.resolve(result);
  }

  public async updatePasswordAPI(request: RequestUser): Promise<ResponseUser> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to update not found');
    const res: AxiosResponse = await processDataBackend(
      'PUT',
      '/api/user/updatePassword',
      {},
      { request }
    );
    const result: ResponseUser = res.data;
    return Promise.resolve(result);
  }

  public async loggedUpdatePasswordAPI(request: RequestUser): Promise<ResponseUser> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to update not found');
    const res: AxiosResponse = await processDataBackend(
      'PUT',
      '/api/user/loggedUpdatePassword',
      {},
      { request }
    );
    const result: ResponseUser = res.data;
    return Promise.resolve(result);
  }

  public async updateRoleToUserAPI(request: RequestUser): Promise<ResponseUser> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to update not found');
    const res: AxiosResponse = await processDataBackend(
      'PUT',
      '/api/user/updateRoleToUser',
      {},
      { request }
    );
    const result: ResponseUser = res.data;
    return Promise.resolve(result);
  }

  public async deleteUserAPI(request: RequestUser): Promise<ResponseUser> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to delete not found');
    const res: AxiosResponse = await processDataBackend('PUT', '/api/user/delete', {}, { request });
    const result: ResponseUser = res.data;

    return Promise.resolve(result);
  }

  public async getByIdUserAPI(request: RequestUser): Promise<ResponseUser> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to getById not found');
    const res: AxiosResponse = await processDataBackend(
      'POST',
      '/api/user/getById',
      {},
      { request }
    );
    const result: ResponseUser = res.data;

    return Promise.resolve(result);
  }

  public async userLoginAPI(request: RequestUser): Promise<ResponseUser> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to login not found');
    const res: AxiosResponse = await processDataBackend(
      'POST',
      '/api/user/userLogin',
      {},
      { request }
    );

    if (!res.data) throw new Error('Sending fail');

    const userInfo: ResponseUser = res.data;

    return Promise.resolve(userInfo);
  }

  public async getUsersByRoleAPI(request: RequestUser): Promise<ResponseUser[]> {
    this.a = 'aaa';
    const res: AxiosResponse = await processDataBackend(
      'GET',
      '/api/user/getUsersByRole',
      {},
      {},
      { data: request }
    );
    const user: ResponseUser[] = res.data;
    return Promise.resolve(user);
  }
}
