import 'reflect-metadata';
import { container } from 'tsyringe';
import { ISubModuleInfrastructure } from '../../../domain/interface/infrastructure/userPermissions/ISubModuleInfrastructure';
import { ISubModuleApplication } from '../../../domain/interface/application/userPermissions/ISubModuleApplication';
import { RequestSubModule } from '../../../domain/userPermissions/subModule/model/requestSubModule';
import { ResponseSubModule } from '../../../domain/userPermissions/subModule/model/responseSubModule';

export class SubModuleApplication implements ISubModuleApplication {
  private subModuleInfrastructure: ISubModuleInfrastructure;

  public constructor() {
    this.subModuleInfrastructure = container.resolve<ISubModuleInfrastructure>(
      'ISubModuleInfrastructure'
    );
  }

  public async getSubModule(request: RequestSubModule): Promise<ResponseSubModule[]> {
    const result = await this.subModuleInfrastructure.getSubModuleAPI(request);
    return result;
  }
}
