import 'reflect-metadata';
import { AxiosResponse } from 'axios';
import { IUserPermissionInfrastructure } from '../../../domain/interface/infrastructure/userPermissions/IUserPermissionInfrastructure';
import { RequestUserPermission } from '../../../domain/userPermissions/userPermission/model/requestUserPermission';
import { ResponseUserPermission } from '../../../domain/userPermissions/userPermission/model/responseUserPermission';
import { processDataBackend } from '../../../../common/utils/functions';

export class UserPermissionInfrastructure implements IUserPermissionInfrastructure {
  private a = '';

  public async createUserPermissionAPI(
    request: RequestUserPermission
  ): Promise<ResponseUserPermission> {
    this.a = 'aaa';
    if (!request) throw new Error('Object to create not found');
    const res: AxiosResponse = await processDataBackend(
      'POST',
      '/api/userPermission/create',
      {},
      { request }
    );
    const result = new ResponseUserPermission();
    if (!res.data) throw new Error('Sending fail');

    return Promise.resolve(result);
  }
}
