import 'reflect-metadata';
import { AxiosResponse } from 'axios';
import { ILogoutInfrastructure } from '../../domain/interface/infrastructure/security/ILogoutInfrastructure';
import { processDataBackend } from '../../../common/utils/functions';
import { ResponseUser } from '../../domain/userPermissions/user/model/responseUser';

export class LogoutInfrastructure implements ILogoutInfrastructure {
  private a = '';

  public async handleAPI(token: string): Promise<ResponseUser> {
    this.a = 'aaa';
    const res: AxiosResponse = await processDataBackend(
      'POST',
      '/api/security/logout',
      { authorization: token },
      {}
    );
    const Login: ResponseUser = res.data;
    return Promise.resolve(Login);
  }
}
