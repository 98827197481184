import { AxiosError } from 'axios';
import { ResponseConsecutive } from '../../core/domain/consecutive/model/responseConsecutive';

const actionsConsecutive = {
  CONSECUTIVE_LIST_PAGE_BEGIN: 'CONSECUTIVE_LIST_PAGE_BEGIN',
  CONSECUTIVE_LIST_PAGE_SUCCESS: 'CONSECUTIVE_LIST_PAGE_SUCCESS',
  CONSECUTIVE_LIST_PAGE_ERR: 'CONSECUTIVE_LIST_PAGE_ERR',

  consecutiveListPageBegin: (): { type: string } => {
    return {
      type: actionsConsecutive.CONSECUTIVE_LIST_PAGE_BEGIN,
    };
  },

  consecutiveListPageSuccess: (
    data: ResponseConsecutive | undefined
  ): { type: string; data: ResponseConsecutive | undefined } => {
    return {
      type: actionsConsecutive.CONSECUTIVE_LIST_PAGE_SUCCESS,
      data,
    };
  },

  consecutiveListPageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsConsecutive.CONSECUTIVE_LIST_PAGE_ERR,
      err,
    };
  },
};

export { actionsConsecutive };
