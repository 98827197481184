import React from 'react';
import { Box, Button, CircularProgress, Tooltip, Typography, useTheme } from '@mui/material';
import { DataGrid, GridColDef, GridColumnHeaderParams, esES } from '@mui/x-data-grid';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { ToolbarList } from '../../../../../common/components/toolbar';
import { IMembershipsBeneficiariesReport } from './types.d';
import { TitleComponent } from '../../../../../common/components/titles/styled';

export const MembershipsBeneficiariesReportV: React.FC<IMembershipsBeneficiariesReport> = ({
  data,
  loading,
  handleExportExcel,
}) => {
  const theme = useTheme();
  const styles = {
    header: {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      fontSize: '30px',
      textAlign: 'left',
      fontFamily: 'Poppins-Regular',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
    },
    headerName: {
      fontWeight: 'bold',
      fontSize: '14px',
      color: theme.palette.primary.main,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    icon: {
      display: 'flex',
      fontSize: '20px',
      color: '#fff',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        display: 'flex',
        color: '#fff',
      },
    },
    textButton: {
      fontSize: '13px',
      color: '#fff',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    button: {
      background: theme.palette.success.light,
      display: 'flex',
      height: '35px',
      justifyContent: 'center',
      gap: '10px',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        width: '30px',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:hover': {
        backgroundColor: theme.palette.success.main,
      },
      boxShadow: '0px 3px 5px 0px #dcdcdc',
    },
  };
  const columns: GridColDef[] = [
    {
      field: 'ID',
      headerName: 'ITEM',
      headerAlign: 'center',
      align: 'center',
      flex: 0.3,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>ITEM</Typography>
      ),
    },
    {
      field: 'thirdPartyBeneficiary',
      headerName: 'NOMBRE DE MEMBRESÍA',
      headerAlign: 'center',
      align: 'left',
      flex: 1,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>NOMBRE DE MEMBRESÍA</Typography>
      ),
      renderCell: ({ row }) => {
        return (
          <Box sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
            {row?.thirdPartyBeneficiary.toUpperCase()}
          </Box>
        );
      },
    },
    {
      field: 'names',
      headerName: 'NOMBRE DEL BENEFICIARIO',
      headerAlign: 'center',
      align: 'left',
      flex: 1,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>NOMBRE DEL BENEFICIARIO</Typography>
      ),
      renderCell: ({ row }) => {
        return (
          <Box sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
            {row?.names?.toUpperCase()}
          </Box>
        );
      },
    },
    {
      field: 'validity',
      headerName: 'VIGENCIA',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>VIGENCIA</Typography>
      ),
    },
    {
      field: 'state',
      headerName: 'ESTADO',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      cellClassName: 'cellRow',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography sx={styles.headerName}>ESTADO</Typography>
      ),
    },
  ];
  return (
    <Box sx={{ width: '100%' }}>
      <Box
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
        }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            background: '#fff',
            borderRadius: '16px',
            boxShadow: '0px 3px 6px #00000029',
            minHeight: '400px',
            padding: '20px',
          }}>
          <div style={{ height: '100%', width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '10px',
              }}>
              <TitleComponent>Reporte de membresía y sus beneficiarios</TitleComponent>
              <Tooltip title="Descargar excel">
                <Button
                  onClick={handleExportExcel}
                  disabled={loading}
                  variant="contained"
                  sx={styles.button}>
                  {loading ? (
                    <CircularProgress size={16} sx={styles.icon} />
                  ) : (
                    <SaveAltIcon sx={styles.icon} />
                  )}
                  <Typography sx={styles.textButton}>Descargar</Typography>
                </Button>
              </Tooltip>
            </Box>

            <div style={{ background: '#fff', height: '85%' }}>
              <DataGrid
                rows={data || []}
                getRowId={(row) => row?.idBeneficiary}
                columns={columns}
                rowsPerPageOptions={[25, 50, 100]}
                density="compact"
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                components={{ Toolbar: ToolbarList }}
                loading={!data?.length && loading}
              />
            </div>
          </div>
        </Box>
      </Box>
    </Box>
  );
};
