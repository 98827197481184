import { AxiosError } from 'axios';
import { ResponseKinships } from '../../../core/domain/contacts/kinships/model/responseKinships';

const actions = {
  KINSHIPS_LIST_PAGE_BEGIN: 'KINSHIPS_LIST_PAGE_BEGIN',
  KINSHIPS_LIST_PAGE_SUCCESS: 'KINSHIPS_LIST_PAGE_SUCCESS',
  KINSHIPS_LIST_PAGE_ERR: 'KINSHIPS_LIST_PAGE_ERR',

  kinshipsListPageBegin: (): { type: string } => {
    return {
      type: actions.KINSHIPS_LIST_PAGE_BEGIN,
    };
  },

  kinshipsListPageSuccess: (
    data: ResponseKinships[]
  ): { type: string; data: ResponseKinships[] } => {
    return {
      type: actions.KINSHIPS_LIST_PAGE_SUCCESS,
      data,
    };
  },

  kinshipsListPageErr: (err: AxiosError): { type: string; err: AxiosError } => {
    return {
      type: actions.KINSHIPS_LIST_PAGE_ERR,
      err,
    };
  },
};

export default actions;
