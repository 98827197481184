import React, { ChangeEvent, useState } from 'react';
import { SelectChangeEvent, TextField, TextFieldProps, Tooltip, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Yup from 'yup';

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputLabel-root': {
    fontSize: '0.9em',
  },
  '& .MuiInputBase-input': {
    fontSize: '0.9em',
    padding: '0  0  0 10px',
    height: '30px',
    borderRight: '1px solid gray',
    width: '100%',
    '@media (max-width:  700px)': { borderRight: 'none' },
  },
  '& .MuiInputBase-root': {
    height: '35px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    Padding: 'none',
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'white',
    border: 'solid 1px #D0D0D0',
    padding: '0px',
    height: '40px',
    '@media (max-width:  700px)': { height: '35px' },
  },
}));

export const InputAdapterSearch = (
  props: TextFieldProps & {
    name: string;
    control: Control;
    schema: Yup.AnySchema;
    error?: string;
    onBlur?: any;
    customOnChange?: any;
    hideHelperText?: boolean;
    showPasswordEye?: boolean;
    iconType?: string;
    hideToolType?: string;
    type?: string;
    mb?: string | number;
    functionIcon?: () => void;
  }
) => {
  const {
    name,
    control,
    schema,
    disabled,
    defaultValue,
    hideHelperText,
    customOnChange,
    iconType,
    onBlur,
    functionIcon,
    type,
    mb,
    ...rest
  } = props;
  const { t } = useTranslation();
  const {
    field: { onChange, value, ref },
    fieldState: { invalid, error: err },
  } = useController({
    name,
    control,
    rules: {
      validate: (val) => schema.validate(val).catch((error) => error.message),
    },
    defaultValue: '',
  });

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<any>
  ) => {
    onChange(e);
    if (customOnChange) customOnChange(e);
  };
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const iconSelector = [
    {
      type: 'showPasswordEye',
      render: (
        <Tooltip
          title={showPassword ? 'ocultar contraseña' : 'mostrar contraseña'}
          arrow
          placement="right">
          <IconButton
            color={err ? 'error' : 'default'}
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}>
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </Tooltip>
      ),
    },
    {
      type: 'search',
      render: (
        <Tooltip title="Buscar beneficio">
          <IconButton onClick={functionIcon} sx={{margin:"0px", padding: '0px',borderRadius:"2px", height:"100%",width:"40px"}}>
            <SearchIcon fontSize="medium" sx={{ color: 'grey' }} />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return (
    <CustomTextField
      {...rest}
      name={name}
      inputRef={ref}
      InputLabelProps={{
        shrink: true,
      }}
      disabled={disabled}
      value={defaultValue || value}
      onChange={(e) => (customOnChange ? handleChange(e) : !disabled && onChange(e))}
      onBlur={onBlur}
      size="small"
      // multiline
      error={invalid}
      type={showPassword ? 'text' : type}
      helperText={!hideHelperText && t(err?.message || '')}
      sx={{ width: '100%' }}
      rows={1}
      InputProps={{
        endAdornment: iconSelector?.find((x) => x?.type === iconType)?.render,
      }}
    />
  );
};
