import { AxiosResponse } from 'axios';
import { IUserTypeInfrastructure } from '../../../domain/interface/infrastructure/userPermissions/IUserTypeInfrastructure';
import { ResponseUserType } from '../../../domain/userPermissions/userType/model/responseUserType';
import { RequestUserType } from '../../../domain/userPermissions/userType/model/requestUserType';
import { processDataBackend } from '../../../../common/utils/functions';

export class UserTypesInfrastructure implements IUserTypeInfrastructure {
  private a = '';

  public async getUserTypeAPI(request: RequestUserType): Promise<ResponseUserType[]> {
    this.a = 'aaa';
    const res: AxiosResponse = await processDataBackend(
      'GET',
      '/api/userType/getAll',
      {},
      { request }
    );
    const UserType: ResponseUserType[] = res.data;
    return Promise.resolve(UserType);
  }
}
