import { ResponseBeneficiaries } from '../../../beneficiaries/model/responseBeneficiaries';
import { ResponseBenefitBeneficiaries } from '../../../benefitBeneficiaries/model/responseBenefitBeneficiaries';
import { ResponseThirdParties } from '../../../thirdParties/thirdParties/model/thirdPartiesResponse';
import { ResponseBenefitCategory } from '../../benefitCategories/model/responseBenefitCategory';
import { ResponseBenefitCity } from '../../benefitCity/model/responseBenefitCity';
import { ResponseBenefitValidity } from '../../benefitValidities/model/responseBenefitValidity';

export class ResponseBenefit {
  public idBenefit = 0;

  public idThirdParty = 0;

  public typeBeneficiary = '';

  public idCategory? = 0;

  public page? = 0;

  public pageSize? = 0;

  public startDate? = '';

  public endDate? = '';

  public limit? = 0;

  public search? = '';

  public number = '';

  public name = '';

  public description = '';

  public path = '';

  public state? = 0;

  public visibility? = 0;

  public benefitValidity? = new ResponseBenefitValidity();

  public thirdParty? = new ResponseThirdParties();

  public benefitBeneficiaries: ResponseBenefitBeneficiaries[] = new Array(
    new ResponseBenefitBeneficiaries()
  );

  public benefitsValidities: ResponseBenefitValidity[] = new Array(new ResponseBenefitValidity());

  public benefitCity?: ResponseBenefitCity[] = new Array(new ResponseBenefitCity());

  public benefitCategory? = new ResponseBenefitCategory();

  public beneficiary? = new ResponseBeneficiaries();

  public createdAt? = '';

  public updatedAt? = '';
}
