import { Box, CircularProgress, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import pkg from '../../../../../../package.json';
import { ButtonSubmit, ButtonToBack, FormSendEmail, Logo, TextFloating, styles } from './styled';
import { InputField } from '../../../../../common/components/formAdapters';
import { ISendEmail } from './types.d';
import image from '../../../../../common/components/images';
import { Link } from 'react-router-dom';

export const SendEmailV: React.FC<ISendEmail> = ({
  handleSubmit,
  handleRecoverPass,
  loading,
  control,
  schema,
  goBack,
  sentEmail,
  watchEmail,
}) => {
  const theme = useTheme();
  const LogoBonus = image('logoBonus');
  return (
    <Grid container sx={{ ...styles.container, backgroundColor: theme.palette.primary.main }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
          alignItems: 'center',
          position: 'relative',
        }}>
        <Box sx={styles.logoSendEmail}>
          <Logo src={LogoBonus} />
        </Box>
        <Box sx={styles.formSendEmail}>
          {!sentEmail ? (
            <FormSendEmail>
              <Box sx={{ textAlign: 'center' }}>
                <Typography
                  variant="h4"
                  sx={{ fontFamily: 'Poppins-Regular', fontWeight: '600' }}
                  color={theme.palette.primary.main}>
                  ¿Olvidaste tu contraseña?
                </Typography>
                <Typography
                  sx={{ fontFamily: 'Poppins-Regular', fontSize: '12px' }}
                  color={theme.palette.primary.main}>
                  Se le enviará la información necesaria para recuperar su cuenta.
                </Typography>
              </Box>
              <InputField
                name="email"
                control={control}
                label="Correo electrónico"
                type="email"
                schema={schema.fields.email}
              />

              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <ButtonSubmit
                  disabled={loading}
                  type="button"
                  style={{
                    color: '#fff',
                    fontSize: '16px',
                    backgroundColor: theme.palette.primary.main,
                  }}
                  onClick={handleSubmit(handleRecoverPass)}>
                  {!loading ? 'Enviar' : <CircularProgress size={25} sx={{ color: 'white' }} />}
                </ButtonSubmit>
                <ButtonToBack type="button" onClick={goBack}>
                  <Typography fontSize="15px" color={theme.palette.primary.main}>
                    Volver
                  </Typography>
                </ButtonToBack>
              </Box>
            </FormSendEmail>
          ) : (
            <FormSendEmail>
              <Box sx={{ textAlign: 'center' }}>
                <Typography
                  variant="h4"
                  sx={{ fontFamily: 'Poppins-Regular', fontWeight: '600' }}
                  color={theme.palette.primary.main}>
                  Instrucciones enviadas
                </Typography>
              </Box>
              <Typography
                variant="inherit"
                sx={{ fontFamily: 'Poppins-Regular', textAlign: 'center' }}
                color={theme.palette.primary.main}>
                Hemos enviado instrucciones para cambiar su contraseña al correo
                <span style={{ fontWeight: 'bold' }}> {watchEmail}</span> Revise su bandeja de
                entrada y la carpeta de spam
              </Typography>

              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {/* <ButtonSubmit
                  disabled={loading}
                  type="button"
                  style={{
                    color: '#fff',
                    fontSize: '16px',
                    backgroundColor: theme.palette.primary.main
                  }}
                  onClick={goBack}> */}
                <Link to="/login">
                  <Typography fontSize="16px">Volver</Typography>
                  {/* </ButtonSubmit> */}
                </Link>
              </Box>
            </FormSendEmail>
          )}
        </Box>
      </Box>
      <TextFloating>
        <Typography
          color={theme.palette.primary.main}
          sx={{
            fontSize: '11px',
            padding: '5px',
            width: '100%',
            textAlign: 'center',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            color: '#fff',
          }}>
          ® WOW Desarrollos Digitales - Give plus {pkg.version}
        </Typography>
      </TextFloating>
    </Grid>
  );
};
