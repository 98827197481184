import { ResponseAddress } from '../../../addresses/address/model/responseAddress';
import { ResponseThirdPartySeller } from '../../thirdPartiesSellers/model/responseThirdPartySeller';

export class ResponseSeller {
  public idSeller = 0;

  public idDocumentType = 0;

  public idGender = 0;

  public identificationNumber = 0;

  public firstName = '';

  public middleName = '';

  public firstSurname = '';

  public secondSurname = '';

  public email = '';

  public cellPhone = '';

  public telephone = '';

  public state = 0;

  public thirdPartySeller = new ResponseThirdPartySeller();

  public address = new ResponseAddress();
}
