import * as yup from 'yup';

const schemaUpdatePassword = yup
  .object({
    password: yup.string().required('La contraseña es requerida'),
    newPassword: yup
      .string()
      .required('La nueva contraseña es requerida')
      .min(8, 'Mínimo 8 caracteres')
      .test('has-uppercase', 'Una mayúscula', (value) => /[A-Z]/.test(value))
      .test('has-number', 'Un número', (value) => /(?=.*\d)/.test(value))
      .matches(/^(?=.*[@$!%*_.?&])/, 'Un carácter especial'),
    confirmPass: yup
      .string()
      .oneOf([yup.ref('newPassword')], 'Las contraseñas deben coincidir')
      .required('La confirmación de la contraseña es requerida'),
  })
  .required();

export default schemaUpdatePassword;
