import 'reflect-metadata';
import { AxiosResponse } from 'axios';
import { RequestLogin } from '../../domain/security/model/request/requestLogin';
import { processDataBackend } from '../../../common/utils/functions';
import { ResponseUser } from '../../domain/userPermissions/user/model/responseUser';
import { ILoginBeneficiaryInfrastructure } from '../../domain/interface/infrastructure/security/ILoginBeneficiaryInfrastructure';
import { RequestUser } from '../../domain/userPermissions/user/model/requestUser';

export class LoginBeneficiaryInfrastructure implements ILoginBeneficiaryInfrastructure {
  private a = '';

  public async handleAPI(request: RequestLogin): Promise<ResponseUser> {
    this.a = 'aaa';
    const res: AxiosResponse = await processDataBackend(
      'POST',
      '/api/security/loginBeneficiary',
      {},
      { request }
    );
    const Login: ResponseUser = res.data;
    return Promise.resolve(Login);
  }

  public async recoveryPasswordBeneficiaryAPI(request: RequestUser): Promise<ResponseUser> {
    this.a = 'aaa';
    if (!request) throw new Error('error al recuperar contraseña');
    const res: AxiosResponse = await processDataBackend(
      'POST',
      '/api/user/RecoveryPasswordBeneficiary',
      {},
      { request }
    );
    const result: ResponseUser = res.data;

    return Promise.resolve(result);
  }
}
