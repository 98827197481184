import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { UserRegisterV } from '../../../components/user/userRegister';
import { userTypeListPage } from '../../../../../redux/userPermissions/userType/actionCreator';
import { RootState } from '../../../../../redux/store';
import {
  TDataDocumentType,
  TDataUserSelected,
  TDataUserType,
  TUserType,
  TDocumentsTypes,
  TThirdPartiesProvidersReducer,
  TDataThirdPartyProvider,
  TMemberships,
} from './types.d';
import { documentsTypesListPage } from '../../../../../redux/documentsPersons/documentsTypes/actionCreator';
import {
  clearUserCreateListPage,
  clearUserUpdateListPage,
  getByIdUserListPage,
  userCreatePage,
  userUpdatePage,
} from '../../../../../redux/userPermissions/user/actionCreator';
import {
  TUserCreateReducer,
  TUserReducer,
  TUserUpdateReducer,
} from '../../../../../redux/userPermissions/user/types.d';
import { TDispatch, TodoSuccessResponse } from '../../../../../common/utils/types.d';
import {
  thirdPartiesCustomersListPage,
  thirdPartiesProvidersListPage,
} from '../../../../../redux/thirdParties/actionCreator';
import { filterKeyObject } from '../../../../../common/utils/functions';
import { TThirdPartiesCustomersReducer } from '../../../../../redux/thirdParties/types.d';

export const UserRegisterC = () => {
  /**
   * States
   */
  const [dataUserType, setDataUserType] = useState<TDataUserType>();
  const [dataDocumentType, setDataDocumentType] = useState<TDataDocumentType>([]);
  const [thirdPartyProvider, setThirdPartyProvider] = useState<TDataThirdPartyProvider>([]);
  const [membership, setMembership] = useState<TMemberships>([]);
  const [valueSelectUserType, setValueSelectUserType] = useState<number>();
  const [stringResult, setStringResult] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [typeUserState, setTypeUserState] = useState<boolean>(false);
  const navigate = useNavigate();

  /**
   * Constante con array de objetos para asignar un userType string a userType con idUserType
   */
  const contentUserType: TDataUserSelected[] = [
    {
      idUserTypeSelect: 1,
      labelUserType: 'Administrador',
    },
    {
      idUserTypeSelect: 2,
      labelUserType: 'Membresia',
    },
    {
      idUserTypeSelect: 3,
      labelUserType: 'Comercio',
    },
    {
      idUserTypeSelect: 4,
      labelUserType: 'Usuario',
    },
    {
      idUserTypeSelect: 5,
      labelUserType: 'Beneficiario',
    },
  ];

  /*
    dispatch
    */
  const dispatch = useDispatch();

  /**
   * Dispatch de userType
   */
  const userTypeDispatch: TUserType = useSelector((dataState: RootState) => dataState?.userTypes);
  /**
   * Dispatch de documentType
   */
  const userDocumentTypeDispatch: TDocumentsTypes = useSelector(
    (dataState: RootState) => dataState?.documentsTypes
  );

  /**
   * Dispatch de Obtener todos los comercios
   */
  const getAllThirdPartiesProviders: TThirdPartiesProvidersReducer = useSelector(
    (dataState: RootState) => dataState?.thirdPartiesProviders
  );

  /**
   * Dispatch de userCreate
   */
  const userCreateDispatch: TUserCreateReducer = useSelector(
    (dataState: RootState) => dataState?.userCreated
  );
  /**
   * Dispatch de userUpdate
   */
  const userUpdateDispatch: TUserUpdateReducer = useSelector(
    (dataState: RootState) => dataState?.userUpdated
  );

  /**
   * Dispatch de getUserById
   */
  const getUserByIdDispatch: TUserReducer = useSelector((dataState: RootState) => dataState?.user);

  /**
   * Dispatch de tipos de membresías
   */
  const MembershipsDispatch: TThirdPartiesCustomersReducer = useSelector(
    (dataState: RootState) => dataState?.thirdPartiesCustomers
  );

  /**
   * Schema de validaciones de campos
   */
  const schema = yup
    .object({
      idDocumentType: yup.string().required('Este campo es requerido'),
      userType: yup.string().required('Este campo es requerido'),
      idThirdParty: yup.string().when('userType', {
        is: (value: string) => value === '3' || value === '2',
        then: () => yup.string().required(),
        otherwise: () => yup.string().nullable(),
      }),
      identificationNumber: yup
        .string()
        .required('Número de identificación es requerido')
        .matches(/^\d{1,20}$/, 'Este campo solo puede contener números y una longitud menor a 20'),

      name: yup
        .string()
        .required('Primer nombre es requerido')
        .matches(/^[A-Za-zñáéíóúÁÉÍÓÚÑ\s]+$/, 'Solo se permiten letras'),

      lastName: yup
        .string()
        .required('Primer apellido es requerido')
        .matches(/^[A-Za-zñáéíóúÁÉÍÓÚÑ\s]+$/, 'Solo se permiten letras'),

      email: yup
        .string()
        .required('Correo es requerido')
        .min(6, 'El correo electrónico debe tener al menos 6 caracteres')
        .email('El correo electrónico no es válido'),
      cellPhone: yup
        .string()
        .required('Este campo es requerido')
        .max(10, 'Este campo solo permite 10 números')
        .matches(/^[0-9\s]*$/, 'Este campo solo puede contener números'),
    })
    .required();

  /**
   * useForm
   */
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  /**
   * Obteniendo el ID por los parámetros
   */
  const { id } = useParams();
  const idUser: number = Number(id);
  const location = useLocation();

  /*
   * Función para crear un usuario
   */
  const handleRegister = handleSubmit(async () => {
    dispatch(
      userCreatePage({
        ...getValues(),
        userType: stringResult,
        idThirdParty: watch('idThirdParty') ? String(watch('idThirdParty')) : null,
      }) as TDispatch
    );
    setLoading(true);
  });

  /**
   * Función para editar un usuario
   */
  const handleUpdate = handleSubmit(async () => {
    dispatch(
      userUpdatePage({
        ...filterKeyObject(getValues(), ['idDocumentType', 'identificationNumber', 'password']),
        idUser,
        idThirdParty: watch('idThirdParty') ? String(watch('idThirdParty')) : null,
        userType: stringResult,
      }) as TDispatch
    );
    setLoading(true);
  });

  /*
   * Función para retroceder.
   */
  const goBack = () => {
    setTimeout(() => {
      navigate('/usuarios/lista');
    });
  };

  /*
   * Función pendiente a select userType
   */
  const handleShowSelected = () => {
    setTypeUserState(true);
  };

  /**
   * UseEffect que limpia select comercio o membresía
   */
  const userType = watch('userType');
  useEffect(() => {
    if (typeUserState) {
      setValue('idThirdParty', undefined);
    }
  }, [userType, typeUserState, setValue]);

  /*
   * Función para asignar un label al idUserType.
   */
  const getItemByNumber = (x: number) => {
    return contentUserType.find((item) => item?.idUserTypeSelect === x);
  };

  /**
   * UseEffect para llamar userTypes
   */
  useEffect(() => {
    setDataUserType(userTypeDispatch?.userTypes || []);
  }, [userTypeDispatch?.userTypes]);

  /**
   * UseEffect para llamar membresías
   */
  useEffect(() => {
    setMembership(MembershipsDispatch?.thirdPartiesCustomers || []);
  }, [MembershipsDispatch?.thirdPartiesCustomers]);

  /**
   * UseEffect para asignar userType
   */
  useEffect(() => {
    const stringUserType: string | undefined = getItemByNumber(Number(userType))?.labelUserType;
    setValueSelectUserType(Number(userType));
    setStringResult(stringUserType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType]);

  /**
   * UseEffect para llamar documentTypes
   */
  useEffect(() => {
    setDataDocumentType(
      userDocumentTypeDispatch?.documentsTypes?.filter((item) => item?.idDocumentType !== 4) || []
    );
  }, [userDocumentTypeDispatch?.documentsTypes]);

  /**
   * UseEffect para llamar documentTypes
   */
  useEffect(() => {
    setThirdPartyProvider(getAllThirdPartiesProviders?.thirdPartiesProviders || []);
  }, [getAllThirdPartiesProviders?.thirdPartiesProviders]);

  /**
   * UseEffect pendiente al momento de crear un usuario existente
   */
  useEffect(() => {
    if (loading) {
      if (userCreateDispatch?.userCreated && !userCreateDispatch?.error) {
        goBack();
        toast.success('Usuario registrado exitosamente', {
          hideProgressBar: true,
        });
        reset({
          idDocumentType: '',
          identificationNumber: '',
          name: '',
          lastName: '',
          email: '',
          cellPhone: '',
          userType: '',
          idThirdParty: undefined,
        });
        dispatch(clearUserCreateListPage() as TDispatch);
      } else if (!userCreateDispatch?.userCreated && userCreateDispatch?.error) {
        const message = userCreateDispatch?.error?.response
          ? (userCreateDispatch?.error?.response?.data as TodoSuccessResponse)?.message
          : 'Ha ocurrido una incidencia';
        toast.error(`${message}`, {
          hideProgressBar: true,
        });
        dispatch(clearUserCreateListPage() as TDispatch);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCreateDispatch?.userCreated, userCreateDispatch?.error]);

  /**
   * UseEffect pendiente al momento de actualizar un usuario existente
   */
  useEffect(() => {
    if (idUser && loading) {
      if (userUpdateDispatch?.userUpdated && !userUpdateDispatch?.error) {
        goBack();
        toast.success('Usuario actualizado exitosamente', {
          hideProgressBar: true,
        });
        reset({
          idDocumentType: '',
          identificationNumber: '',
          name: '',
          lastName: '',
          email: '',
          cellPhone: '',
          userType: '',
          idThirdParty: undefined,
        });
        // setValueArray([]);
        dispatch(clearUserUpdateListPage() as TDispatch);
        dispatch(getByIdUserListPage({ idUser }) as TDispatch);
      } else if (!userUpdateDispatch?.userUpdated && userUpdateDispatch?.error) {
        const message = userUpdateDispatch?.error?.response
          ? (userUpdateDispatch?.error?.response?.data as TodoSuccessResponse)?.message
          : 'Ha ocurrido una incidencia';
        toast.error(`${message}`, {
          hideProgressBar: true,
        });
        dispatch(clearUserUpdateListPage() as TDispatch);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userUpdateDispatch?.userUpdated, userUpdateDispatch?.error]);

  /**
   * Muestra la información de los documentos según los parámetros de la URL
   */
  useEffect(() => {
    if (idUser) {
      dispatch(getByIdUserListPage({ idUser }) as TDispatch);
    } else {
      reset({});
    }
  }, [idUser, dispatch, reset]);

  useEffect(() => {
    if (location?.pathname === '/usuarios/registro') {
      // setValueArray([]);
      reset({
        idDocumentType: '',
        identificationNumber: '',
        name: '',
        lastName: '',
        email: '',
        cellPhone: '',
        userType: '',
        idThirdParty: undefined,
      });
    }
  }, [location, reset]);

  /**
   * useEffect que se encarga de mostrar la información correspondiente en los campos mediante el ID
   */
  useEffect(() => {
    if (idUser) {
      if (getUserByIdDispatch?.user?.idUser) {
        const data = getUserByIdDispatch?.user;
        const newValueArray = data?.userType;
        const newUserType = contentUserType?.find(
          (x) => x?.labelUserType === newValueArray
        )?.idUserTypeSelect;
        reset({
          email: data?.email,
          idDocumentType: String(data?.idDocumentType),
          identificationNumber: String(data?.identificationNumber),
          lastName: data?.lastName,
          name: data?.name,
          userType: newUserType?.toString(),
          cellPhone: data?.cellPhone ? data?.cellPhone : '',
          idThirdParty: data?.idThirdParty !== 0 ? data?.idThirdParty?.toString() : undefined,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserByIdDispatch?.user?.idUser]);
  /**
   * UseEffect para llamar todos los dispatch
   */
  useEffect(() => {
    dispatch(clearUserUpdateListPage() as TDispatch);
    dispatch(clearUserCreateListPage() as TDispatch);
    dispatch(userTypeListPage() as TDispatch);
    dispatch(documentsTypesListPage() as TDispatch);
    dispatch(thirdPartiesProvidersListPage() as TDispatch);
    dispatch(thirdPartiesCustomersListPage() as TDispatch);
  }, [dispatch]);

  return (
    <UserRegisterV
      dataUserTypes={dataUserType}
      membership={membership}
      dataDocumentTypes={dataDocumentType}
      thirdPartyProvider={thirdPartyProvider}
      handleRegister={handleRegister}
      handleUpdate={handleUpdate}
      goBack={goBack}
      valueSelectUserType={valueSelectUserType}
      handleShowSelected={handleShowSelected}
      control={control}
      errors={errors}
      schema={schema}
      register={register}
      createLoading={userCreateDispatch?.loading}
      updateLoading={userUpdateDispatch?.loading}
      idUser={idUser}
      idThirdParty={getUserByIdDispatch?.user?.idThirdParty}
    />
  );
};
