import React from 'react';
import { SellersRegisterC } from '../../../containers/sellers/sellerRegister';
import LayoutC from '../../../containers/Layout';

const SellersRegisterP = () => {
  return (
    <LayoutC>
      <SellersRegisterC />
    </LayoutC>
  );
};

export default SellersRegisterP;
