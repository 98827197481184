import { AxiosError } from 'axios';
import { ResponseUser } from '../../core/domain/userPermissions/user/model/responseUser';

export const actionsLoginProvider = {
  LOGIN_PROVIDER_PAGE_BEGIN: 'LOGIN_PROVIDER_PAGE_BEGIN',
  LOGIN_PROVIDER_PAGE_SUCCESS: 'LOGIN_PROVIDER_PAGE_SUCCESS',
  LOGIN_PROVIDER_PAGE_ERR: 'LOGIN_PROVIDER_PAGE_ERR',

  loginPageProviderBegin: (): { type: string } => {
    return {
      type: actionsLoginProvider.LOGIN_PROVIDER_PAGE_BEGIN,
    };
  },

  loginPageProviderSuccess: (
    data: ResponseUser | undefined
  ): { type: string; data: ResponseUser | undefined } => {
    return {
      type: actionsLoginProvider.LOGIN_PROVIDER_PAGE_SUCCESS,
      data,
    };
  },

  loginPageProviderErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsLoginProvider.LOGIN_PROVIDER_PAGE_ERR,
      err,
    };
  },
};

export const actionsProviderLogout = {
  LOGOUT_PROVIDER_PAGE_BEGIN: 'LOGOUT_PROVIDER_PAGE_BEGIN',
  LOGOUT_PROVIDER_PAGE_SUCCESS: 'LOGOUT_PROVIDER_PAGE_SUCCESS',
  LOGOUT_PROVIDER_PAGE_ERR: 'LOGOUT_PROVIDER_PAGE_ERR',

  logoutProviderPageBegin: (): { type: string } => {
    return {
      type: actionsProviderLogout.LOGOUT_PROVIDER_PAGE_BEGIN,
    };
  },

  logoutProviderPageSuccess: (
    data: ResponseUser | undefined
  ): { type: string; data: ResponseUser | undefined } => {
    return {
      type: actionsProviderLogout.LOGOUT_PROVIDER_PAGE_SUCCESS,
      data,
    };
  },

  logoutProviderPageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsProviderLogout.LOGOUT_PROVIDER_PAGE_ERR,
      err,
    };
  },
};

export const actionsRefresh = {
  REFRESH_PAGE_BEGIN: 'REFRESH_PAGE_BEGIN',
  REFRESH_PAGE_SUCCESS: 'REFRESH_PAGE_SUCCESS',
  REFRESH_PAGE_ERR: 'REFRESH_PAGE_ERR',

  refreshPageBegin: (): { type: string } => {
    return {
      type: actionsRefresh.REFRESH_PAGE_BEGIN,
    };
  },

  refreshPageSuccess: (
    data: ResponseUser | undefined
  ): { type: string; data: ResponseUser | undefined } => {
    return {
      type: actionsRefresh.REFRESH_PAGE_SUCCESS,
      data,
    };
  },

  refreshPageErr: (err: AxiosError | undefined): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsRefresh.REFRESH_PAGE_ERR,
      err,
    };
  },
};
