import { AxiosError } from 'axios';
import { ResponseAcademicLevels } from '../../../core/domain/documentsPersons/academicLevels/model/responseAcademicLevels';

const actions = {
  ACADEMIC_LEVELS_LIST_PAGE_BEGIN: 'ACADEMIC_LEVELS_LIST_PAGE_BEGIN',
  ACADEMIC_LEVELS_LIST_PAGE_SUCCESS: 'ACADEMIC_LEVELS_LIST_PAGE_SUCCESS',
  ACADEMIC_LEVELS_LIST_PAGE_ERR: 'ACADEMIC_LEVELS_LIST_PAGE_ERR',

  academicLevelsListPageBegin: (): { type: string } => {
    return {
      type: actions.ACADEMIC_LEVELS_LIST_PAGE_BEGIN,
    };
  },

  academicLevelsListPageSuccess: (
    data: ResponseAcademicLevels[]
  ): { type: string; data: ResponseAcademicLevels[] } => {
    return {
      type: actions.ACADEMIC_LEVELS_LIST_PAGE_SUCCESS,
      data,
    };
  },

  academicLevelsListPageErr: (err: AxiosError): { type: string; err: AxiosError } => {
    return {
      type: actions.ACADEMIC_LEVELS_LIST_PAGE_ERR,
      err,
    };
  },
};

export default actions;
