import React from 'react';
import { BenefitsListC } from '../../../containers/benefits/benefitList';
import LayoutC from '../../../containers/Layout';

const BenefitsListP = () => {
  return (
    <LayoutC>
      <BenefitsListC />
    </LayoutC>
  );
};

export default BenefitsListP;
