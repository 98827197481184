import { AxiosError } from 'axios';
import { ResponseBankAccountTypes } from '../../../core/domain/banks/bankAccountTypes/model/responseBankAccountTypes';

const actions = {
  BANK_ACCOUNT_TYPES_LIST_PAGE_BEGIN: 'BANK_ACCOUNT_TYPES_LIST_PAGE_BEGIN',
  BANK_ACCOUNT_TYPES_LIST_PAGE_SUCCESS: 'BANK_ACCOUNT_TYPES_LIST_PAGE_SUCCESS',
  BANK_ACCOUNT_TYPES_LIST_PAGE_ERR: 'BANK_ACCOUNT_TYPES_LIST_PAGE_ERR',

  bankAccountTypesListPageBegin: (): { type: string } => {
    return {
      type: actions.BANK_ACCOUNT_TYPES_LIST_PAGE_BEGIN,
    };
  },

  bankAccountTypesListPageSuccess: (
    data: ResponseBankAccountTypes[] | ResponseBankAccountTypes
  ): {
    type: string;
    data: ResponseBankAccountTypes[] | ResponseBankAccountTypes;
  } => {
    return {
      type: actions.BANK_ACCOUNT_TYPES_LIST_PAGE_SUCCESS,
      data,
    };
  },

  bankAccountTypesListPageErr: (err: AxiosError): { type: string; err: AxiosError } => {
    return {
      type: actions.BANK_ACCOUNT_TYPES_LIST_PAGE_ERR,
      err,
    };
  },
};

export default actions;
