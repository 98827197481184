import React from 'react';
import { MembershipsListC } from '../../../containers/membership/membershipsList';
import LayoutC from '../../../containers/Layout';

const MembershipsListP = () => {
  return (
    <LayoutC>
      <MembershipsListC />
    </LayoutC>
  );
};

export default MembershipsListP;
