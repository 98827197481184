import 'reflect-metadata';
import { container } from 'tsyringe';
import { RequestBenefitBeneficiaries } from '../../domain/benefitBeneficiaries/model/requestBenefitBeneficiaries';
import { ResponseBeneficiaries } from '../../domain/beneficiaries/model/responseBeneficiaries';
import { IBenefitBeneficiariesInfrastructure } from '../../domain/interface/infrastructure/benefitBeneficiaries/IBenefitBeneficiariesInfrastructure';
import { IBenefitBeneficiariesApplication } from '../../domain/interface/application/benefitBeneficiaries/IBenefitBeneficiariesApplication';

export class BenefitBeneficiariesApplication implements IBenefitBeneficiariesApplication {
  private benefitBeneficiariesInfrastructure: IBenefitBeneficiariesInfrastructure;

  public constructor() {
    this.benefitBeneficiariesInfrastructure =
      container.resolve<IBenefitBeneficiariesInfrastructure>('IBenefitBeneficiariesInfrastructure');
  }

  public async createBenefitBeneficiary(
    request: RequestBenefitBeneficiaries
  ): Promise<ResponseBeneficiaries> {
    const result = await this.benefitBeneficiariesInfrastructure.createBenefitBeneficiaryAPI(
      request
    );
    return result;
  }
}
