import { AxiosError } from 'axios';
import { ResponseStreetTypes } from '../../../core/domain/addresses/streetTypes/model/responseStreetTypes';
import { StreetTypesState } from '../../../core/domain/addresses/streetTypes/state/streetTypesState';
import actions from './actions';
import { TStreetTypesReducer } from './types';
import { dataSorted } from '../../../common/utils/functions';

const { STREET_TYPES_LIST_PAGE_BEGIN, STREET_TYPES_LIST_PAGE_SUCCESS, STREET_TYPES_LIST_PAGE_ERR } =
  actions;

const initialStreetTypes: StreetTypesState = new StreetTypesState();

const StreetTypesReducer = (
  state = initialStreetTypes,
  action: { type: string; err: AxiosError; data: ResponseStreetTypes[] }
): TStreetTypesReducer => {
  const { type, data, err } = action;
  switch (type) {
    case STREET_TYPES_LIST_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case STREET_TYPES_LIST_PAGE_SUCCESS:
      return {
        ...state,
        streetTypes: dataSorted(data),
        loading: false,
      };
    case STREET_TYPES_LIST_PAGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default StreetTypesReducer;
