import { KeyboardEvent } from 'react';
import parsePhoneNumber from 'libphonenumber-js';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import AWS from 'aws-sdk';
import { useLocation } from 'react-router-dom';
import axios, { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { TAxiosBody, TAxiosParams } from './types.d';
import { brandUrls } from '../../lib/vars';
// import giveplusPdfImg from './index';

moment.locale('es');

export const constructorName = (values: string[]) => values?.filter((x) => !!x)?.join(' ');
/**
 * Transforma una cantidad de números en formato dinero
 * @version 0.0.1
 * @param {numeric} value valor en números
 * @return {boolean} true o false
 */
export const numberFormat = (value: string | number) => {
  const newValue = typeof value === 'number' ? value.toString() : value;
  if (parseInt(newValue, 10))
    return new Intl.NumberFormat('de-DE').format(parseFloat(`${value}`.replace(/\./g, '')));
  return 0;
};
/**
 * Validación de Email
 * @version 0.0.1
 */
export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
/**
 * Validación de solo Recibir letras
 * @version 0.0.1
 */
export const validateLetters = (value: string): boolean => {
  const lettersRegex = /^[A-Za-zñáéíóúÁÉÍÓÚÑ\s]+$/;
  return lettersRegex.test(value);
};

/**
 * DataSelects en orden alfabético
 */
export const dataSorted = (
  data: Array<{ name?: string }>
): Array<{ name: string; [key: string]: string | number }> => {
  const result = data?.length
    ? data
        .filter((item) => item.name !== undefined) // Filtrar elementos con 'name' definido
        .map((item) => ({ ...item, name: item.name || '' })) // Convertir a formato con 'name' definido
        .sort((x, y) => x.name.localeCompare(y.name))
    : [];

  return result;
};

/**
 *
 * @param {Object} data objeto a filtrar
 * @param {Array} filters array a comparar o claves del objeto a excluir
 * @return {Object} devuelve un objeto con los datos filtrados
 */
export const filterKeyObject = (data: any, filters: Array<string>) => {
  let values = {};

  Object.keys(data)?.forEach((elem: string) => {
    let coincidence = true;
    if (filters.find((x) => x === elem)) coincidence = false;
    if (coincidence) values = { ...values, [elem]: data[elem] };
  });

  return values;
};

export const selectClientName = (): string => {
  const client = window.location.origin;
  if (client.includes('localhost')) {
    return 'local';
  }
  if (client.includes('qa')) {
    return 'stage';
  }
  if (client.includes('bonusmas')) {
    return 'prod';
  }
  return 'local';
};

export const selectBackendEnvironment = (): string => {
  // console.log({
  //   consoleKeys: {
  //     aka: process.env.REACT_APP_ACCESS_KEY_AWS,
  //     ska: process.env.REACT_APP_SECRET_KEY_AWS,
  //     bh: process.env.REACT_APP_BACKEND_HOST,
  //   },
  // });
  const environment = selectClientName();
  if (environment === 'local') return 'http://localhost:3001';
  if (environment === 'stage') return 'https://qaback.giveplus.com.co/';
  if (environment === 'prod') return 'https://bk.bonusmas.com/';
  return 'http://localhost:3001';
};

/**
 * guarda un documento en S3
 * @param {Any} method Método de búsqueda
 * @param {String} url Array de buffer del documento
 * @param {String} body Array de buffer del documento
 * @return {String} respuesta del servidor
 */
export const processDataBackend = async (
  method: string,
  url: string,
  header: AxiosRequestHeaders | undefined | {},
  body: TAxiosBody,
  params?: TAxiosParams
): Promise<AxiosResponse> => {
  const requestBody = body.request;
  const res: AxiosResponse = await axios({
    method,
    baseURL: `${selectBackendEnvironment()}${url}`,
    headers: header,
    params: params?.data,
    data: requestBody,
  });
  return res;
};

// ESTO NO VA NO SIRVEEE

// /**
//  * Calcula el dígito de verificación
//  * @version 0.0.1
//  * @param {string} value valor en números
//  * @return {numeric} el dígito de verificación
//  */
// export const calculateCheckDigit = (value: string | number) => {
//   // variables necesarias
//   let nit = `${value}`;
//   const vpri = [3, 7, 13, 17, 19, 23, 29, 37, 41, 43, 47, 53, 59, 67, 71];

//   // Se limpia el Nit
//   nit = nit.replace(/\s/g, ''); // Espacios
//   nit = nit.replace(/,/g, ''); // Comas
//   nit = nit.replace(/\./g, ''); // Puntos
//   nit = nit.replace(/-/g, ''); // Guiones

//   // Se valida el nit
//   if (!Number(nit)) return '';

//   // Procedimiento
//   let x = '0';
//   let y = '0';
//   let i = 0;
//   const z = nit.length;

//   for (i = 0; i < z; i++) {
//     y = nit.substring(i, 1);

//     x += Number(y) * vpri[z - i];
//   }

//   y = (Number(x) % 11).toString();

//   return Number(y) > 1 ? 11 - Number(y) : Number(y);
// };

/*
 * Transforma un número en formato de teléfono
 * @version 0.0.1
 * @param {string} value valor en números
 * @return {string} nuevo formato en teléfono
 */
export const phoneFormat = (value: string) =>
  !!value && parsePhoneNumber(`${value}`, 'US')?.formatNational();

/**
 * Transforma una cadena de caracteres a todos mayúscula
 * @version 0.0.1
 * @param {string} value valor en números
 * @return {string} nuevo formato en teléfono
 */
export const upperCase = (value: string) => `${value || ''}`.toUpperCase();

/**
 * Transforma una fecha en ingles a formato español
 * @version 0.0.1
 * @param {string} value valor en números
 * @return {string} nuevo formato de fecha
 */
export const dateFormat = (value: string) => value && moment(value)?.format('DD-MM-YYYY');

/** busca los parámetros en la ruta
 * @return {Function} para poder llamar los parámetros
 */
export const useQueryParam = () => new URLSearchParams(useLocation().search);

/** busca los parámetros en la ruta
 * @return {Function} para poder llamar los parámetros
 */
export const getCreditStage = (state: number) => {
  let stage = '';
  switch (Number(state)) {
    case 0:
      stage = 'Rechazado';
      break;
    case 1:
      stage = 'Finalizado';
      break;
    case 2:
      stage = 'Solicitud';
      break;
    case 3:
      stage = 'Aprobación';
      break;
    case 4:
      stage = 'Documentos';
      break;
    case 5:
      stage = 'Espera de Desembolso';
      break;
    case 6:
      stage = 'Desembolsado';
      break;
    case 7:
      stage = 'Espera de Pagaduría';
      break;
    case 8:
      stage = 'Comité';
      break;
    case 9:
      stage = 'Supervisión';
      break;
    case 10:
      stage = 'Información adicional';
      break;
    default:
      break;
  }
  return stage;
};
/**
 * function debounce
 * @param { function } func funciona que ejecuta el debounce
 * @return { function } función que limpia el intervalo
 */
export const debounce = (func: any) => {
  const interval = setTimeout(() => {
    func();
  }, 500);
  return interval && clearInterval(interval);
};

/**
 * Capitalizes first letters of words in string.
 * @param {string} str String to be modified
 * @param {boolean} lower Whether all other letters should be lowercase
 * @returns {string} retorna string
 */
export const capitalize = (str: string, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());

/**
 * capitalize the first letter
 * @param {string} str String to be modified
 * @param {boolean} lower Whether all other letters should be lowercase
 * @returns {string} retorna string
 */
export const capitalizeTheFirstLetter = (str: string, lower = false) =>
  str && str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

/**
 * Transforma un Tipo File a Base64 modo Async
 * @version 0.0.1
 * @async
 * @param {object} file tipo file
 * @return {string} base64
 */
export const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    // reader.readAsDataURL(file);
    if (file instanceof Blob) {
      reader.readAsDataURL(file);
    }
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

/**
 * transforma todo de base64 a arraybuffer
 * @version 0.0.1
 * @param {string} base64 valor
 * @return {array} array en bytes
 */
export const base64ToArrayBuffer = (base64: any) => {
  if (!base64) return false;
  const binaryString = window.atob(base64.substring(base64.indexOf('base64,') + 7, base64.length));
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString?.charCodeAt(i);
  }
  return bytes;
};

/**
 * Busca la extension del archivo
 * @version 0.0.1
 * @param {string} filename nombre del archivo con la extension
 * @return {string} nombre de la extension
 */
export const extFile = (filename: string) => {
  return /[.]/.exec(filename) ? /[^.]+$/?.exec(filename)?.[0] : undefined;
};

/**
 * Se conecta a Aws3
 * @version 0.0.1
 * @return {boolean} la conexión
 */
export const AWS3 = () =>
  new AWS.S3({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_AWS,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY_AWS,
  });

/**
 * Busca un documento en S3
 * @param {String} Key variable de búsqueda
 * @return {String} base64 del documento
 */
export const getFileUrlS3 = async (Key: string) => {
  const client = brandUrls();
  const S3 = AWS3();
  const params = { Bucket: client.bt || 'pruebawow', Prefix: Key };
  try {
    const response = await S3.listObjectsV2(params).promise();

    const objectKeys = response?.Contents?.map((obj: AWS.S3.Object) => obj.Key);
    const exists = objectKeys?.includes(Key) ?? false;
    if (!exists) return null;
    const res = await S3.getSignedUrlPromise('getObject', {
      Bucket: client.bt || 'pruebawow',
      Key,
    });
    return res;
  } catch (error) {
    return undefined;
  }
};

/**
 * guarda un documento en S3
 * @param {String} Key variable de búsqueda
 * @param {Array} Body Array de buffer del documento
 * @return {String} respuesta del servidor
 */
export const putFileS3 = async (
  Key: string,
  Body: undefined | boolean | Uint8Array | ArrayBuffer
) => {
  const client = brandUrls();
  const S3 = AWS3();
  const res = await S3.putObject({
    Bucket: client.bt || 'pruebawow',
    Key,
    Body,
  })
    .promise()
    .catch(() => undefined);
  return res;
};

export const constructorMoneyValue = (value: string) => {
  return `$ ${numberFormat(value)}`;
};
export const diffInDays = (to: moment.Moment, from: moment.Moment) => {
  // Convertimos las fechas a objetos Date
  const dataTo = to.toDate();
  const dateFrom = from.toDate();

  // Convertimos las fechas a días
  const daysTo = dataTo.getFullYear() * 360 + dataTo.getMonth() * 30 + dataTo.getDate();
  const daysFrom = dateFrom.getFullYear() * 360 + dateFrom.getMonth() * 30 + dateFrom.getDate();

  // Calculamos la diferencia en días
  const difference = Math.abs(daysFrom - daysTo);

  return difference;
};

export const numberFormatM = (param: number) => {
  let money = 0;
  let num: number | string = 0;
  let value = 0;
  let val = param;
  if (val >= 1000000000) {
    num = `${val}`.charAt(0);
    value = parseFloat(`${num}000`);
    money += value;
    val -= parseFloat(`${num}000000000`);
  }

  if (val >= 100000000) {
    num = `${val}`.charAt(0);
    value = parseFloat(`${num}00`);
    money += value;
    val -= parseFloat(`${num}00000000`);
  }

  if (val >= 10000000) {
    num = `${val}`.charAt(0);
    value = parseFloat(`${num}0`);
    money += value;
    val -= parseFloat(`${num}0000000`);
  }

  if (val >= 1000000) {
    num = `${val}`.charAt(0);
    value = parseFloat(`${num}`);
    money += value;
    val -= parseFloat(`${num}000000`);
  }
  if (val >= 100000) {
    num = `${val}`.charAt(0);
    value = parseFloat(`0.${num}`);
    money += value;
    val -= parseFloat(`${num}00000`);
  }
  if (val >= 10000) {
    num = `${val}`.charAt(0);
    value = parseFloat(`0.0${num}`);
    money += value;
    val -= parseFloat(`${num}0000`);
  }
  if (val >= 1000) {
    num = `${val}`.charAt(0);
    value = parseFloat(`0.00${num}`);
    money += value;
    val -= parseFloat(`${num}000`);
  }
  if (val >= 100) {
    num = `${val}`.charAt(0);
    value = parseFloat(`0.000${num}`);
    money += value;
    val -= parseFloat(`${num}00`);
  }
  if (val >= 10) {
    num = `${val}`.charAt(0);
    value = parseFloat(`0.0000${num}`);
    money += value;
    val -= parseFloat(`${num}0`);
  }
  if (val >= 1) {
    num = `${val}`.charAt(0);
    value = parseFloat(`0.00000${num}`);
    money += value;
    val -= parseFloat(`${num}`);
  }

  return money.toFixed(2);
};

/**
 * Función para consultar los datos de una api
 * @param {Any} method Método de búsqueda
 * @param {String} url Array de buffer del documento
 * @param {String} body Array de buffer del documento
 * @return {String} respuesta del servidor
 */
export const fetcher = async (
  method: string,
  url: string,
  header: AxiosRequestHeaders | Record<string, string | number | boolean>,
  body?: TAxiosBody,
  params?: TAxiosParams
): Promise<AxiosResponse> => {
  const requestBody = body?.request;
  const res: AxiosResponse = await axios({
    method,
    baseURL: `https://report.mili.com.co${url}`,
    headers: header,
    params: params?.data,
    data: requestBody,
  });
  return res;
};

export const encryptData = (data: string, key: string) => {
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key);
  return encrypted.toString();
};

// Función para desencriptar
export const decryptData = (encryptedData: string, key: string) => {
  const decrypted = CryptoJS.AES.decrypt(encryptedData, key);
  const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);
  const decryptedDataJson = JSON.parse(decryptedData || '{}');
  return decryptedDataJson.toString();
};

/**
 * Función para formatear a number o string
 */
export const numberOrString = (
  value: string | number,
  type: 'number' | 'text'
): string | number | undefined => {
  let newValue;
  if (value || value === 0) {
    newValue = type === 'number' ? Number(value) : String(value);
  } else {
    newValue = undefined;
  }
  return newValue;
};

// type TImageName = 'logo' | 'image';

// export const pdfImages = (imageName: TImageName) => {
//   const client = 'giveplus';

//   if (client === 'giveplus') return giveplusPdfImg.logo;
//   return giveplusPdfImg.logo;
// };

export const onlyNumbers = (event: KeyboardEvent<HTMLDivElement>) => {
  const { key } = event;
  if (
    !(
      key === 'Backspace' ||
      key === 'Tab' ||
      key === ' ' ||
      key === 'ArrowLeft' ||
      key === 'ArrowRight' ||
      key === 'ArrowUp' ||
      key === 'ArrowDown' ||
      key.match(/^[0-9]$/)
    )
  )
    event.preventDefault();
};

export const onlyLetters = (event: KeyboardEvent<HTMLDivElement>) => {
  const { key } = event;
  if (
    !(
      key === 'Backspace' ||
      key === 'Tab' ||
      key === ' ' ||
      key === 'ArrowLeft' ||
      key === 'ArrowRight' ||
      key === 'ArrowUp' ||
      key === 'ArrowDown' ||
      key.match(/^[a-zA-ZÁÉÍÓÚáéíóúÑñÜü]+$/)
    )
  ) {
    event.preventDefault();
  }
};

// export const setupLogoutOnTabClose = (inactivityTimeout: number = 1 * 60 * 1000) => {
//   let inactivityTimer: any;

//   function cleanAndLogout() {
//     localStorage.clear();
//     window.location.href = '/login';
//   }

//   function resetInactivityTimer() {
//     clearTimeout(inactivityTimer);
//     inactivityTimer = setTimeout(cleanAndLogout, inactivityTimeout);
//   }

//   function handleBeforeUnload(event: BeforeUnloadEvent) {
//     // Verificar si el tiempo de inactividad ha expirado antes de ejecutar cleanAndLogout
//     const now = new Date().getTime();
//     const lastActivityTime = localStorage.getItem('lastActivityTime');

//     if (lastActivityTime && now - parseInt(lastActivityTime, 10) < inactivityTimeout) {
//       // Restablecer la última actividad antes de cerrar la pestaña
//       localStorage.setItem('lastActivityTime', now.toString());
//     } else {
//       // Limpiar la sesión solo si el tiempo de inactividad ha expirado
//       cleanAndLogout();
//     }
//   }

//   window.addEventListener('beforeunload', handleBeforeUnload);
//   window.addEventListener('mousemove', resetInactivityTimer);
//   window.addEventListener('keydown', resetInactivityTimer);

//   // Inicializar la última actividad cuando la página se carga por primera vez
//   localStorage.setItem('lastActivityTime', new Date().getTime().toString());
// };

export const setupLogoutOnTabClose = (inactivityTimeout: number = 1 * 60 * 1000) => {
  let inactivityTimer: any;

  function cleanAndLogout() {
    localStorage.clear();
    window.location.href = '/login';
  }

  function resetInactivityTimer() {
    clearTimeout(inactivityTimer);
    inactivityTimer = setTimeout(cleanAndLogout, inactivityTimeout);
  }

  function checkSessionExpiration() {
    const now = new Date().getTime();
    const lastActivityTime = localStorage.getItem('lastActivityTime');

    if (lastActivityTime && now - parseInt(lastActivityTime, 10) >= inactivityTimeout) {
      // Limpiar la sesión si el tiempo de inactividad ha expirado
      cleanAndLogout();
    }
  }

  function initializeLastActivityTime() {
    // Inicializar la última actividad cuando la página se carga por primera vez
    localStorage.setItem('lastActivityTime', new Date().getTime().toString());
  }

  function handleBeforeUnload(event: BeforeUnloadEvent) {
    // Verificar si la página se está recargando
    const navigationType =
      event.currentTarget instanceof PerformanceNavigation ? event.currentTarget.type : null;

    if (navigationType !== PerformanceNavigation.TYPE_RELOAD) {
      // Verificar si el tiempo de inactividad ha expirado antes de ejecutar cleanAndLogout
      const now = new Date().getTime();
      const lastActivityTime = localStorage.getItem('lastActivityTime');

      if (lastActivityTime && now - parseInt(lastActivityTime, 10) < inactivityTimeout) {
        // Restablecer la última actividad antes de cerrar la pestaña
        localStorage.setItem('lastActivityTime', now.toString());
      } else {
        // Limpiar la sesión solo si el tiempo de inactividad ha expirado
        cleanAndLogout();
      }
    }
  }

  window.addEventListener('beforeunload', handleBeforeUnload);
  window.addEventListener('mousemove', resetInactivityTimer);
  window.addEventListener('keydown', resetInactivityTimer);

  // Inicializar la última actividad cuando la página se carga por primera vez
  initializeLastActivityTime();

  // Verificar la expiración de la sesión en intervalos regulares
  setInterval(checkSessionExpiration, 1000); // Puedes ajustar el intervalo según tus necesidades
};
