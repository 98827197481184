import { AxiosError } from 'axios';
import 'reflect-metadata';
import { container } from 'tsyringe';
import {
  actionsThirdParties,
  actionsThirdPartiesCustomers,
  actionsThirdParty,
  actionsThirdPartyCustomer,
  actionsThirdPartyCustomerCreate,
  actionsThirdPartyCustomerDelete,
  actionsThirdPartyCustomerUpdate,
  actionsThirdPartyProviderCreate,
  actionsThirdPartyProviderUpdate,
} from './actions';
import { RequestThirdParties } from '../../core/domain/thirdParties/thirdParties/model/thirdPartiesRequest';
import { IThirdPartiesApplication } from '../../core/domain/interface/application/thirdParties/IThirdPartiesApplication';
import { AppDispatch } from '../store';

// Providers
const {
  thirdPartyProviderCreatePageBegin,
  thirdPartyProviderCreatePageSuccess,
  thirdPartyProviderCreatePageErr,
} = actionsThirdPartyProviderCreate;
const { thirdPartiesListPageBegin, thirdPartiesListPageSuccess, thirdPartiesListPageErr } =
  actionsThirdParties;
const {
  thirdPartyProviderUpdatePageBegin,
  thirdPartyProviderUpdatePageSuccess,
  thirdPartyProviderUpdatePageErr,
} = actionsThirdPartyProviderUpdate;
const { thirdPartyListPageBegin, thirdPartyListPageSuccess, thirdPartyListPageErr } =
  actionsThirdParty;

// Customers
const {
  thirdPartiesCustomersListPageBegin,
  thirdPartiesCustomersListPageSuccess,
  thirdPartiesCustomersListPageErr,
} = actionsThirdPartiesCustomers;
const {
  thirdPartyCustomerListPageBegin,
  thirdPartyCustomerListPageSuccess,
  thirdPartyCustomerListPageErr,
} = actionsThirdPartyCustomer;
const {
  thirdPartyCustomerCreatePageBegin,
  thirdPartyCustomerCreatePageSuccess,
  thirdPartyCustomerCreatePageErr,
} = actionsThirdPartyCustomerCreate;
const {
  thirdPartyCustomerUpdatePageBegin,
  thirdPartyCustomerUpdatePageSuccess,
  thirdPartyCustomerUpdatePageErr,
} = actionsThirdPartyCustomerUpdate;

const {
  thirdPartyCustomerDeletePageSuccess,
  thirdPartyCustomerDeletePageBegin,
  thirdPartyCustomerDeletePageErr,
} = actionsThirdPartyCustomerDelete;

// Providers
export const thirdPartiesProvidersListPage = (): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(thirdPartiesListPageBegin());
      const thirdPartiesApplication = container.resolve<IThirdPartiesApplication>(
        'IThirdPartiesApplication'
      );

      const request = new RequestThirdParties();
      const result = await thirdPartiesApplication.getThirdPartyProviders(request);

      dispatch(thirdPartiesListPageSuccess(result));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(thirdPartiesListPageErr(error));
    }
  };
};

export const getThirdPartyProviderByIdListPage = (
  listState: RequestThirdParties
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(thirdPartyListPageBegin());
      const thirdPartiesApplication = container.resolve<IThirdPartiesApplication>(
        'IThirdPartiesApplication'
      );

      const resultGetById = await thirdPartiesApplication.getThirdPartyProviderById(listState);
      dispatch(thirdPartyListPageSuccess(resultGetById));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(thirdPartyListPageErr(error));
    }
  };
};

export const createThirdPartyProviderPage = (
  listState: RequestThirdParties
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(thirdPartyProviderCreatePageBegin());
      const thirdPartiesApplication = container.resolve<IThirdPartiesApplication>(
        'IThirdPartiesApplication'
      );

      const resultCreate = await thirdPartiesApplication.createThirdPartyProvider(listState);
      dispatch(thirdPartyProviderCreatePageSuccess(resultCreate));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(thirdPartyProviderCreatePageErr(error));
    }
  };
};

export const updateThirdPartyProviderPage = (
  listState: RequestThirdParties
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(thirdPartyProviderUpdatePageBegin());
      const thirdPartiesApplication = container.resolve<IThirdPartiesApplication>(
        'IThirdPartiesApplication'
      );

      const resultUpdate = await thirdPartiesApplication.updateThirdPartyProvider(listState);
      dispatch(thirdPartyProviderUpdatePageSuccess(resultUpdate));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(thirdPartyProviderUpdatePageErr(error));
    }
  };
};

// Customers
export const thirdPartiesCustomersListPage = (): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(thirdPartiesCustomersListPageBegin());
      const thirdPartiesApplication = container.resolve<IThirdPartiesApplication>(
        'IThirdPartiesApplication'
      );

      const request = new RequestThirdParties();
      const result = await thirdPartiesApplication.getThirdPartyCustomers(request);

      dispatch(thirdPartiesCustomersListPageSuccess(result));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(thirdPartiesCustomersListPageErr(error));
    }
  };
};

export const deleteThirdPartiesCustomerPage = (
  listState: RequestThirdParties
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(thirdPartyCustomerDeletePageBegin());
      const beneficiariesApplication = container.resolve<IThirdPartiesApplication>(
        'IThirdPartiesApplication'
      );

      const resultDelete = await beneficiariesApplication.deleteThirdPartyCustomer(listState);
      dispatch(thirdPartyCustomerDeletePageSuccess(resultDelete));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(thirdPartyCustomerDeletePageErr(error));
    }
  };
};
export const getThirdPartyCustomerByIdListPage = (
  listState: RequestThirdParties
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(thirdPartyCustomerListPageBegin());
      const thirdPartiesApplication = container.resolve<IThirdPartiesApplication>(
        'IThirdPartiesApplication'
      );

      const resultGetById = await thirdPartiesApplication.getThirdPartyCustomerById(listState);
      dispatch(thirdPartyCustomerListPageSuccess(resultGetById));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(thirdPartyCustomerListPageErr(error));
    }
  };
};

export const createThirdPartyCustomerPage = (
  listState: RequestThirdParties
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(thirdPartyCustomerCreatePageBegin());
      const thirdPartiesApplication = container.resolve<IThirdPartiesApplication>(
        'IThirdPartiesApplication'
      );

      const resultCreate = await thirdPartiesApplication.createThirdPartyCustomer(listState);
      dispatch(thirdPartyCustomerCreatePageSuccess(resultCreate));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(thirdPartyCustomerCreatePageErr(error));
    }
  };
};

export const updateThirdPartyCustomerPage = (
  listState: RequestThirdParties
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(thirdPartyCustomerUpdatePageBegin());
      const thirdPartiesApplication = container.resolve<IThirdPartiesApplication>(
        'IThirdPartiesApplication'
      );

      const resultUpdate = await thirdPartiesApplication.updateThirdPartyCustomer(listState);
      dispatch(thirdPartyCustomerUpdatePageSuccess(resultUpdate));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(thirdPartyCustomerUpdatePageErr(error));
    }
  };
};

// Providers
export const clearThirdPartiesProviderListPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(thirdPartiesListPageBegin());
    dispatch(thirdPartiesListPageSuccess([]));
    dispatch(thirdPartiesListPageErr(undefined));
  };
};

export const clearCreateThirdPartyProviderPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(thirdPartyProviderCreatePageBegin());
    dispatch(thirdPartyProviderCreatePageSuccess(undefined));
    dispatch(thirdPartyProviderCreatePageErr(undefined));
  };
};

export const clearUpdateThirdPartyProviderPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(thirdPartyProviderUpdatePageBegin());
    dispatch(thirdPartyProviderUpdatePageErr(undefined));
    dispatch(thirdPartyProviderUpdatePageSuccess(undefined));
  };
};
// Customers
export const clearThirdPartiesCustomersListProviderPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(thirdPartiesCustomersListPageBegin());
    dispatch(thirdPartiesCustomersListPageSuccess([]));
    dispatch(thirdPartiesCustomersListPageErr(undefined));
  };
};

export const clearCreateThirdPartyCustomerPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(thirdPartyCustomerCreatePageBegin());
    dispatch(thirdPartyCustomerCreatePageSuccess(undefined));
    dispatch(thirdPartyCustomerCreatePageErr(undefined));
  };
};

export const clearUpdateThirdPartyCustomerPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(thirdPartyCustomerUpdatePageBegin());
    dispatch(thirdPartyCustomerUpdatePageErr(undefined));
    dispatch(thirdPartyCustomerUpdatePageSuccess(undefined));
  };
};

export const clearThirdPartyCustomerDeleteListPage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(thirdPartyCustomerDeletePageBegin());
    dispatch(thirdPartyCustomerDeletePageErr(undefined));
    dispatch(thirdPartyCustomerDeletePageSuccess(undefined));
  };
};
