import 'reflect-metadata';
import { AxiosResponse } from 'axios';
import { RequestProfession } from '../../../domain/workInformation/profession/model/requestProfession';
import { IProfessionInfrastructure } from '../../../domain/interface/infrastructure/workInformation/IprofessionInfrastructure';
import { ResponseProfession } from '../../../domain/workInformation/profession/model/responseProfession';
import { processDataBackend } from '../../../../common/utils/functions';

export class ProfessionInfrastructure implements IProfessionInfrastructure {
  private a = '';

  public async getProfessionAPI(request: RequestProfession): Promise<ResponseProfession[]> {
    this.a = 'aaa';

    const res: AxiosResponse = await processDataBackend(
      'GET',
      '/api/professions/getAll',
      {},
      { request }
    );

    const profession: ResponseProfession[] = res?.data;
    return Promise.resolve(profession);
  }
}
