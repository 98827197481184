import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ReactApexChart from 'react-apexcharts';
import 'reflect-metadata';
import { data } from './data';
import { styleSheet } from './styled';
import { Legend } from './legend';

export const HomeView = () => {
  const theme = useTheme();
  const styles = styleSheet(theme);

  return (
    <div style={{ width: '100%' }}>
      <Box sx={styles.mainBox}>
        <Box sx={styles.secondaryBox}>
          <Box sx={styles.contGraph}>
            <ReactApexChart
              options={data.salesTotals.options}
              series={data.salesTotals.series}
              type="line"
              width="100%"
              height={370}
            />
          </Box>
          <Box sx={styles.contGraphic}>
            <ReactApexChart
              options={data.breakdownSalesByProduct.options}
              series={data.breakdownSalesByProduct.series}
              type="donut"
              width="100%"
              height="45%"
            />

            <Box sx={styles.legend}>
              <Legend color="#008FFB" label="Comida" labelNumber="447" />
              <Legend color="#FEB019" label="Mascotas" labelNumber="251" />
              <Legend color="#775DD0" label="Vehiculos" labelNumber="158" />
              <Legend color="#00E396" label="Ropa" labelNumber="83" />
              <Legend color="#FF4560" label="Vacaciones" labelNumber="29" />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.thirdBox}>
        <Box sx={styles.contReport}>
          <ReactApexChart
            options={data.salesReport.options}
            series={data.salesReport.series}
            type="bar"
            width="100%"
            height="100%"
          />
        </Box>
      </Box>
    </div>
  );
};
