import { ResponseUserSession } from '../model/responseUserSession';

export class UserSessionState {
  public constructor() {
    this.sessionValidated = new ResponseUserSession();
    this.sessionDeleted = new ResponseUserSession();
  }

  public sessionValidated?: ResponseUserSession;

  public sessionDeleted?: ResponseUserSession;
}
