import React from 'react';
import { ProfileInformationC } from '../../containers/profileInformation';
import LayoutC from '../../containers/Layout';

const ProfileInformationP = () => {
  return (
    <LayoutC>
      <ProfileInformationC />
    </LayoutC>
  );
};
export default ProfileInformationP;
