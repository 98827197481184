import { AxiosError } from 'axios';
import 'reflect-metadata';
import { container } from 'tsyringe';
import { AppDispatch } from '../../store';
import { actionsBenefitValidityCreate } from './actions';
import { IBenefitValidityApplication } from '../../../core/domain/interface/application/benefits/IBenefitValidityApplication';

const {
  benefitValidityCreatePageBegin,
  benefitValidityCreatePageSuccess,
  benefitValidityCreatePageErr,
} = actionsBenefitValidityCreate;

export const benefitValidityCreatePage = (
  listState: any
): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(benefitValidityCreatePageBegin());
      const benefitValidityApplication = container.resolve<IBenefitValidityApplication>(
        'IBenefitValidityApplication'
      );

      const resultCreate = await benefitValidityApplication.createBenefitValidity(listState);
      dispatch(benefitValidityCreatePageSuccess(resultCreate));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(benefitValidityCreatePageErr(error));
    }
  };
};

export const clearBenefitValidityCreatePage = (): ((dispatch: AppDispatch) => void) => {
  return (dispatch: AppDispatch) => {
    dispatch(benefitValidityCreatePageBegin());
    dispatch(benefitValidityCreatePageErr(undefined));
    dispatch(benefitValidityCreatePageSuccess(undefined));
  };
};
