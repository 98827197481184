import 'reflect-metadata';
import { container } from 'tsyringe';
import { RequestThirdPartySeller } from '../../domain/sellers/thirdPartiesSellers/model/requestThirdPartySeller';
import { ResponseThirdPartySeller } from '../../domain/sellers/thirdPartiesSellers/model/responseThirdPartySeller';
import { IThirdPartySellerApplication } from '../../domain/interface/application/sellers/IThirdPartySellerApplication';
import { IThirdPartySellerInfrastructure } from '../../domain/interface/infrastructure/sellers/IThirdPartySellernfrastructure';

export class ThirdPartySellerApplication implements IThirdPartySellerApplication {
  private sellerInfrastructure: IThirdPartySellerInfrastructure;

  public constructor() {
    this.sellerInfrastructure = container.resolve<IThirdPartySellerInfrastructure>(
      'IThirdPartySellerInfrastructure'
    );
  }

  public async createThirdPartySeller(
    request: RequestThirdPartySeller
  ): Promise<ResponseThirdPartySeller> {
    const result = await this.sellerInfrastructure.createThirdPartySellerAPI(request);
    return result;
  }
}
