import { AxiosError } from 'axios';
import { ResponseBenefitValidity } from '../../../core/domain/benefits/benefitValidities/model/responseBenefitValidity';

const actionsBenefitValidityCreate = {
  BENEFIT_VALIDITY_CREATE_PAGE_BEGIN: 'BENEFIT_VALIDITY_CREATE_PAGE_BEGIN',
  BENEFIT_VALIDITY_CREATE_PAGE_SUCCESS: 'BENEFIT_VALIDITY_CREATE_PAGE_SUCCESS',
  BENEFIT_VALIDITY_CREATE_PAGE_ERR: 'BENEFIT_VALIDITY_CREATE_PAGE_ERR',

  benefitValidityCreatePageBegin: (): { type: string } => {
    return {
      type: actionsBenefitValidityCreate.BENEFIT_VALIDITY_CREATE_PAGE_BEGIN,
    };
  },

  benefitValidityCreatePageSuccess: (
    data: ResponseBenefitValidity | undefined
  ): { type: string; data: ResponseBenefitValidity | undefined } => {
    return {
      type: actionsBenefitValidityCreate.BENEFIT_VALIDITY_CREATE_PAGE_SUCCESS,
      data,
    };
  },

  benefitValidityCreatePageErr: (
    err: AxiosError | undefined
  ): { type: string; err: AxiosError | undefined } => {
    return {
      type: actionsBenefitValidityCreate.BENEFIT_VALIDITY_CREATE_PAGE_ERR,
      err,
    };
  },
};

export { actionsBenefitValidityCreate };
