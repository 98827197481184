import 'reflect-metadata';
import { container } from 'tsyringe';
import { AxiosError } from 'axios';
import { AppDispatch } from '../../store';
import { actionsBenefitCategories } from './actions';
import { IBenefitCategoryApplication } from '../../../core/domain/interface/application/benefits/IBenefitCategoryApplication';
import { RequestBenefitCategory } from '../../../core/domain/benefits/benefitCategories/model/requestBenefitCategory';

const { benefitCategoryListPageBegin, benefitCategoryListPageSuccess, benefitCategoryListPageErr } =
  actionsBenefitCategories;
const benefitCategoryListPage = (): ((dispatch: AppDispatch) => Promise<void>) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(benefitCategoryListPageBegin());
      const benefitCategoryApplication = container.resolve<IBenefitCategoryApplication>(
        'IBenefitCategoryApplication'
      );

      const request = new RequestBenefitCategory();
      const result = await benefitCategoryApplication.getBenefitCategory(request);

      dispatch(benefitCategoryListPageSuccess(result));
    } catch (ex) {
      const error = ex as AxiosError;
      dispatch(benefitCategoryListPageErr(error));
    }
  };
};

export { benefitCategoryListPage };
