import React, { ReactNode, useContext } from 'react';
// import { LayoutV } from '../../components/Layout';
// import { NewLayoutV } from '../../components/Layout/NewLayout';
import { Context } from '../../../../common/utils/userContext';
import { NewLayoutComponent } from '../../components/NewLayout';

const LayoutC = ({ children }: { children: ReactNode }) => {
  // const history = useNavigate();

  const { handleSession } = useContext(Context);
  /**
   * Dispatch
   */
  // const dispatch = useDispatch();
  const localMenu = localStorage.getItem('menu');
  const menu = localMenu ? JSON.parse(localMenu) : [];
  // const { setLogged } = useContext(Context);
  // const handleSession = () => {
  //   dispatch(clearLogin() as any);
  //   const colors = JSON.parse(localStorage.getItem('colorsTheme') || '{}');
  //   localStorage.removeItem('vs1-tk');
  //   localStorage.removeItem('vs1-iux');
  //   localStorage.removeItem('vs1-tux');
  //   localStorage.removeItem('vs1-unx');
  //   localStorage.setItem('colorsTheme', JSON.stringify(colors));
  //   setLogged(false);
  //   history('/login');
  // };
  return (
    <NewLayoutComponent handleSession={handleSession} data={menu}>
      {children}
    </NewLayoutComponent>
  );
};

export default LayoutC;
