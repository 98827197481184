import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from '../../../../../redux/store';
import { TDispatch } from '../../../../../common/utils/types.d';
// import { TThirdPartiesCustomersReducer } from '../../../../../redux/thirdParties/types.d';
import { SellersListV } from '../../../components/sellers/sellersList';
import { TSellerDeleteReducer, TSellersReducer } from '../../../../../redux/sellers/types.d';
import {
  clearSellerDeleteListPage,
  sellerDeletePage,
  sellersListPage,
} from '../../../../../redux/sellers/actionCreator';
import { capitalize, constructorName, numberFormat } from '../../../../../common/utils/functions';
import { ISellersMap, Item, TDataDocumentType } from './types.d';
import { exportToExcel } from '../../../../../common/components/excelGererate';

export const SellersListC = () => {
  /**
   * UseStates
   */
  const [sellersData, setSellersData] = useState<ISellersMap[]>([]);
  const [sellersDataExcel, setSellersDataExcel] = useState<Item[]>([]);
  const [dataId, setDataId] = useState<number>();
  const [openDelete, setOpenDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  /**
   * Constante con array de objetos para asignar un label string a seller con idDocumentType
   */
  const contentDocumentType: TDataDocumentType[] = [
    {
      idDocumentType: 1,
      name: 'CÉDULA DE CIUDADANÍA',
    },
    {
      idDocumentType: 2,
      name: 'TARJETA DE IDENTIDAD',
    },
    {
      idDocumentType: 3,
      name: 'REGISTRO CIVIL DE NACIMIENTO',
    },
    {
      idDocumentType: 4,
      name: 'NIT',
    },
    {
      idDocumentType: 5,
      name: 'CEDULA EXTRANJERÍA',
    },
    {
      idDocumentType: 6,
      name: 'TARJETA EXTRANJERÍA',
    },
    {
      idDocumentType: 7,
      name: 'PASAPORTE',
    },
    {
      idDocumentType: 8,
      name: 'TIPO DE DOCUMENTO EXTRANJERO',
    },
    {
      idDocumentType: 9,
      name: 'SIN USO DEFINIDO POR LA DIAN',
    },
  ];

  /**
   * Dispatch
   */
  const dispatch = useDispatch();

  /**
   * Dispatch de Obtener todos los vendedores
   */
  const getAllSellers: TSellersReducer = useSelector((dataState: RootState) => dataState?.sellers);

  /**
   * Dispatch de deleteSeller
   */
  const deleteSellerDispatch: TSellerDeleteReducer = useSelector(
    (dataState: RootState) => dataState?.sellerDeleted
  );

  /**
   * Función para eliminar un registro
   */
  const handleDelete = async () => {
    dispatch(sellerDeletePage({ idSeller: Number(dataId) }) as TDispatch);
    setOpenDelete(false);
    setLoading(true);
  };

  /**
   * Función que se encarga de cerrar el modal de confirmación
   */
  const handleClose = () => {
    setOpenDelete(false);
  };
  /**
   * Función que se encarga de abrir el modal de confirmación para poder eliminar un registro
   */
  const buttonDelete = async (datos: number) => {
    setDataId(datos);
    setOpenDelete(true);
  };

  /*
   * Función para asignar un label al idDocumentType.
   */
  const getItemByNumber = (x: number | undefined) => {
    return contentDocumentType.find((item) => item?.idDocumentType === x);
  };

  const handleExportExcel = () => {
    exportToExcel(sellersDataExcel, 'ListadoDeVendedores');
    toast.success('Descargado correctamente', {
      hideProgressBar: true,
      autoClose: 1000,
    });
  };

  /**
   * UseEffect que se encarga de mostrar la información en la Lista de Vendedores
   */
  useEffect(() => {
    if (getAllSellers?.sellers instanceof Array) {
      const data = getAllSellers?.sellers?.map((x: ISellersMap, i: number) => {
        const ID = i + 1;
        return {
          ...x,
          names:
            x?.thirdPartySeller?.thirdParty?.idNature === 1
              ? constructorName([
                  x?.thirdPartySeller?.thirdParty?.firstName,
                  x?.thirdPartySeller?.thirdParty?.middleName,
                  x?.thirdPartySeller?.thirdParty?.firstSurname,
                  x?.thirdPartySeller?.thirdParty?.secondSurname,
                ])
              : x?.thirdPartySeller?.thirdParty?.businessName,
          ID: !x ? null : ID,
        };
      });

      setSellersData(data);
    }
  }, [getAllSellers?.sellers]);

  /**
   * UseEffect que se encarga de mostrar la información en el archivo excel
   */
  useEffect(() => {
    if (getAllSellers?.sellers instanceof Array) {
      const data = getAllSellers?.sellers?.map((x, i: number) => {
        const ID = i + 1;
        return {
          Item: ID,
          'Tipo de identificación': String(getItemByNumber(x.idDocumentType)?.name),
          'Número de identificación': numberFormat(Number(x?.identificationNumber)),
          Nombre: capitalize(x.firstName, true),
          Apellido: capitalize(x.firstSurname, true),
          Correo: x?.email?.toLowerCase(),
        };
      });
      setSellersDataExcel(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllSellers?.sellers]);

  /**
   * UseEffect pendiente al momento de eliminar un vendedor existente
   */
  useEffect(() => {
    if (dataId && loading) {
      if (deleteSellerDispatch?.sellerDeleted && !deleteSellerDispatch?.error) {
        toast.success('Vendedor eliminado exitosamente', {
          hideProgressBar: true,
        });
        dispatch(clearSellerDeleteListPage() as TDispatch);
        dispatch(sellersListPage() as TDispatch);
      } else if (!deleteSellerDispatch?.sellerDeleted && deleteSellerDispatch?.error) {
        const message = deleteSellerDispatch?.error?.response
          ? deleteSellerDispatch?.error?.response?.data?.message
          : 'Ha ocurrido una incidencia';
        toast.error(`${message}`, {
          hideProgressBar: true,
        });
        dispatch(clearSellerDeleteListPage() as TDispatch);
      }
    }
  }, [deleteSellerDispatch?.sellerDeleted, deleteSellerDispatch?.error, dataId, loading, dispatch]);

  /**
   * UseEffect que se encarga de llamar a todos los dispatches
   */
  useEffect(() => {
    dispatch(sellersListPage() as TDispatch);
  }, [dispatch]);

  return (
    <SellersListV
      data={sellersData}
      loading={getAllSellers?.loading}
      handleDelete={handleDelete}
      openDelete={openDelete}
      handleClose={handleClose}
      buttonDelete={buttonDelete}
      deleteLoading={deleteSellerDispatch?.loading}
      handleExportExcel={handleExportExcel}
    />
  );
};
