import styled from 'styled-components';
import { TStyle } from './types.d';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

interface BackgroundProps {
  backgroundColor?: string;
  hoverStyles?: string;
  color?: string;
}

export const Header = styled.div<BackgroundProps>`
  width: 100%;
  height: 80px;
  background-color: ${(props) => props.backgroundColor || '#243EBD'};
  border-bottom: 5px solid;
  border-image: linear-gradient(87deg, rgba(80, 28, 149, 1) 0%, rgba(14, 181, 226, 1) 87%) 1 !important;
  box-sizing: border-box;
  padding: 0 50px 0 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 700px) {
    padding: 0 30px 0 30px;
    border-bottom: 2px solid;
  }
`;

// Definir estilos para el contenedor del input y el icono
export const InputContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 90%;
`;

export const Input = styled.input`
  width: 90%;
  height: 30px;
  padding-left: 35px;
  background-color: white;
  border: none;
  border-radius: 5px;
  outline: none;
  @media (max-width: 330px) {
    width: 80%;
  }
`;

export const SearchIcon = styled(SearchOutlinedIcon)`
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  color: #7d7d7d;
`;

export const ImgLogo = styled.img`
  width: 100%;
  height: 100%;
  min-width: 60px;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  overflow: hidden;
`;

export const ContentLogo = styled.div`
  width: 144px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 700px) {
    width: 110px;
  }
`;

export const Banner = styled.div`
  width: 100%;
  box-shadow: 0px 3px 6px #00000029;
  margin-bottom: 15px;
  @media (max-width: 720px) {
    margin-bottom: 5px;
  }
`;

export const ContentTitle = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
`;

export const Title = styled.text`
  margin-top: 10px;
  font-size: 26px;
  font-family: 'Poppins-Regular';
  font-weight: 600;
  @media (min-width: 1920px) {
    font-size: 35px;
  }
`;

export const Main = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 1921px) {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
  }
  @media (max-width: 1920px) {
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
  }
  @media (max-width: 1439px) {
    width: 100%;
    gap: 15px;
    flex-direction: row;
    align-items: center;
  }
  @media (max-width: 1023px) {
    width: 100%;
    gap: 15px;
    flex-direction: column;
  }
`;

export const BoxContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: '#32455';
`;

export const BoxContentBenefits = styled.div`
  display: flex;
  max-width: 1400px;
  flex-direction: column;
  gap: 20px;
  /* @media (min-width: 1921px) {
    display: flex;
    max-width: 1420px;
  }
  @media (max-width: 1920px) {
    display: flex;
    max-width: 1420px;
  }
  @media (max-width: 1439px) {
    display: flex;
    max-width: 1070px;
  }
  @media (max-width: 1080px) {
    display: flex;
    max-width: 715px;
  }
  @media (max-width: 720px) {
    max-width: 715px;
    margin-left: 20px;
    margin-right: 20px;
  } */
`;
export const BoxCarrusel = styled.div`
  display: flex;
  height: 100%;
  width: 50%;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  @media (max-width: 1439px) {
    width: 640px;
  }
  @media (max-width: 1023px) {
    width: 100%;
    max-height: 470px;
  }
`;

export const BoxVoucher = styled.div`
  display: flex;
  width: 48%;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
  @media (max-width: 1920px) {
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 1439px) {
    display: flex;
    width: 320px;
    height: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  @media (max-width: 1023px) {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  @media (max-width: 720px) {
    display: none;
    width: 100%;
    justify-content: center;
    gap: 15px;
  }
`;

export const ButtonSubmit = styled.button`
  width: 120px;
  height: 40px;
  justify-content: center;
  justify-items: center;
  gap: 5px;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 5px;
  opacity: 1;
  cursor: pointer;
`;

// export const Categories = styled.div`
//   width: 100%;
//   height: 70px;
//   gap: 20px;
//   position: fixed;
//   /* z-index: 1; */
//   display: flex;
//   align-items: center;
//   overflow-x: auto;
//   background-color: aqua;
//   box-sizing: border-box;
//   padding: 0 10px 0 10px;
// `;

export const Bonus = styled.div`
  height: 200px;
  /* background-color:  purple; */
  box-shadow: 0px 3px 6px #00000029;
`;
export const ImgBonus = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 10px 10px 0 0;
  object-fit: cover;
`;
export const ButtonBonus = styled.button<BackgroundProps>`
  height: 41px;
  width: 90%;
  max-width: 277px;
  background: ${(props) => props.backgroundColor || '#243EBD'};
  color: white;
  border: none;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (max-width: 600px) {
    height: 33px;
    border-radius: 10px;
  }
`;

export const Categories = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 10px 0 10px;

  /* Ocultar la barra de desplazamiento para Chrome, Safari y Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Ocultar la barra de desplazamiento para IE, Edge y Firefox */
  -ms-overflow-style: none; /* IE y Edge */
  scrollbar-width: none; /* Firefox */
`;

export const Img = styled.img`
  width: 320px;
  height: 226px;
  background-size: 100% 100%;
  border-radius: 5px;
  opacity: 1;
  box-shadow: 0px 3px 6px #00000029;
`;
export const ImgCategory = styled.img`
  width: 40px;
  height: 40px;
  filter: hue-rotate(20deg);
`;

export const ImgCarrusel = styled.img`
  border-radius: 5px;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const ImgBanner = styled.img`
  width: 100%;
  height: 100%;
`;

export const style: TStyle = {
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'row',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#001f46',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f5f4f4',
    texAlign: 'center',
  },
};

export const Logo = styled.img`
  opacity: 1;
  height: 20%;
  width: 50%;
  max-height: 150px;
  min-height: 50px;
`;

export const TramaFloating = styled.img`
  /* position: fixed;
  bottom: 0px;
  z-index: -1;
  left: 0px;
  width: auto;
  opacity: 0.06; */
  position: fixed;
  bottom: 0px;
  z-index: 1600;
  left: 0px;
  width: 40%;
  opacity: 0.06;
  /* background-color: red; */
`;
