import { ResponseTaxRegime } from '../model/responseTaxRegime';

export class TaxRegimeState {
  public constructor() {
    this.taxRegime = [new ResponseTaxRegime()];
  }

  public name = '';

  public taxRegime: ResponseTaxRegime[];
}
